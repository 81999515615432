import React from 'react'

import {
  ShiftPillContainer,
  InvisibleDragPreview,
} from 'pages/plan/dailyViewModal/schedule/styles'
import { ShiftLabel } from 'pages/plan/dailyViewModal/schedule/ShiftLabel'
import { TimelineHelpers } from 'pages/plan/dailyViewModal/schedule/getGraphTimelineHelpers'
import { ShiftSegment } from 'pages/plan/dailyViewModal/schedule/getShiftSegments'
import { mergeRefs } from 'utils/mergeRefs'
import { useShiftPillDrag } from 'pages/plan/dailyViewModal/schedule/shiftPillDragDrop'

interface Props {
  shiftSegment: ShiftSegment
  pills: HTMLElement[]
  appendPillRef: (ref: HTMLElement | null) => any
  timelineHelpers: TimelineHelpers
  isEditable: boolean
  draggingShiftId?: string
  onClick: () => void
}

export const ShiftPill = ({
  shiftSegment,
  pills,
  appendPillRef,
  timelineHelpers: { getPercentFromLeft, getPercentFromRight },
  isEditable,
  draggingShiftId,
  onClick,
}: Props) => {
  const {
    shift,
    startAt,
    endAt,
    labels: [label1, label2],
    other,
    conflict,
  } = shiftSegment

  const percentFromLeft = getPercentFromLeft(startAt)
  const percentFromRight = getPercentFromRight(endAt)

  const type = conflict ? 'conflict' : other ? 'other' : 'shift'
  const testId = `pill-${type}-${percentFromLeft}-${percentFromRight}`

  const [, dragRefMove, previewRefMove] = useShiftPillDrag(
    shiftSegment,
    isEditable,
    'move'
  )

  const [, dragRefStart, previewRefStart] = useShiftPillDrag(
    shiftSegment,
    isEditable,
    'changeStart'
  )

  const [, dragRefEnd, previewRefEnd] = useShiftPillDrag(
    shiftSegment,
    isEditable,
    'changeEnd'
  )

  // It would be great if we could use the `isDragging` state from react-dnd to decide when to transition
  // between the actual pill and the drag preview. Sadly this creates a nasty flicker. Instead we explicitly
  // show/hide the 'real' pill once the dragging shift is known to be visible.
  const hidePill =
    !!draggingShiftId &&
    (shiftSegment.conflict || draggingShiftId === shift?.id)

  return (
    <>
      {/* 
        We force the drag previews to be an invisible div here. This hides the default 
        HTML5 DnD preview and allows us to create custom rendering during the drag based 
        on the hover state over the drop target. See EmployeeSchedule.
      */}
      <InvisibleDragPreview
        ref={mergeRefs(previewRefMove, previewRefStart, previewRefEnd)}
      />
      {hidePill || (
        <ShiftPillContainer
          data-testid={testId}
          left={percentFromLeft}
          right={percentFromRight}
          other={!!other}
          conflict={!!conflict}
          ref={mergeRefs(dragRefMove, appendPillRef)}
          isEditable={isEditable}
          onClick={onClick}
        >
          <ShiftLabel
            label={label1}
            pills={pills}
            position="start"
            dragRef={dragRefStart}
            shiftSegment={shiftSegment}
            isEditable={isEditable}
          />
          <ShiftLabel
            label={label2}
            pills={pills}
            position="end"
            dragRef={dragRefEnd}
            shiftSegment={shiftSegment}
            isEditable={isEditable}
          />
        </ShiftPillContainer>
      )}
    </>
  )
}
