import styled from 'styled-components'

import {
  TableLabelText,
  LargeBodyText,
  RegularBodyText,
  RegularBoldBodyText,
  TableLabelBoldText,
} from 'components/Text'
import { tableLabelTextStyle } from 'config/styles'
import Button from 'components/Button'

export const DetailsContainer = styled.div({
  marginTop: 17,
  paddingTop: 12,
  borderTop: '1px solid #D9D9D9',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  textAlign: 'center',
})

export const Field = styled.div({
  marginBottom: 9,
})

interface ColorProps {
  color: string
}

export const YesNoLabel = styled.label(({ color }: ColorProps) => ({
  height: 30,
  width: 89,
  border: `1px solid ${color}`,
  borderRadius: 40,
  marginLeft: 6,
  marginRight: 6,
  marginBottom: 0,

  '& input': {
    display: 'none',
  },
}))

export const YesNoText = styled.p(({ color }: ColorProps) => ({
  ...tableLabelTextStyle,
  fontWeight: 800,
  lineHeight: '30px',
  textAlign: 'center' as 'center',
  color: color,
}))

export const Icon = styled.img({
  display: 'block',
  margin: '0 auto',
  width: 40,
})

export const Title = styled(LargeBodyText)({
  marginTop: 12,
  fontWeight: 800,
})

export const BodyText = styled(RegularBodyText)({
  marginTop: 7,
  lineHeight: '18px',
})

export const Disclosure = styled(TableLabelText)({
  textAlign: 'center',
  marginTop: 9,
  fontWeight: 700,
  color: 'var(--gray4)',
})

const Box = styled.div<{ blue: boolean }>(({ blue }) => ({
  backgroundColor: 'var(--white)',
  borderTop: `5px solid ${blue ? 'var(--blue)' : 'var(--gray5)'}`,
  padding: '1rem 1rem 1.25rem',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 24,
  paddingRight: 24,
}))

export const EditorBox = styled(Box)({
  top: 0,
  marginTop: 275,
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  zIndex: 1,
})

export const Header = styled.div({
  flexGrow: 0,
  position: 'relative',
  textAlign: 'center',
  marginBottom: 13,
})

export const SubmitFeedback = styled(Button)({
  display: 'block',
  width: '100%',
  marginTop: 13,
})

export const WasUnusual = styled.div({
  display: 'flex',
  justifyContent: 'center',
  '& button': {
    width: 89,
    marginLeft: 6,
    marginRight: 6,
  },
})

export const SavedBox = styled(Box)({
  marginBottom: 35,
  paddingBottom: 8,
  alignItems: 'flex-start',
})

export const SavedHeader = styled(RegularBoldBodyText)({
  marginBottom: 6,
})

export const DetailsButton = styled.button<{ active: boolean }>(
  ({ active }) => ({
    ...tableLabelTextStyle,
    color: active ? 'var(--gray2)' : 'var(--gray4)',
    fontSize: active ? 12 : 9,
    fontWeight: active ? 800 : 900,
    lineHeight: 1.25,
    textTransform: active ? 'none' : 'uppercase',
    textAlign: 'left',
    backgroundColor: 'transparent',
    outline: 'none !important',
    border: 'none',
    marginTop: 12,
    marginBottom: 7,
    padding: 0,
  })
)

export const SectionHeader = styled(TableLabelBoldText)({
  color: 'var(--gray4)',
  marginTop: 12,
  marginBottom: 3,
})

export const EditButton = styled.button({
  ...tableLabelTextStyle,
  height: 20,
  backgroundColor: 'var(--gray5)',
  border: 'none',
  borderRadius: 40,
  outline: 'none',
  fontSize: 10,
  fontWeight: 800,
  lineHeight: '12px',
  color: 'var(--gray2)',
  marginTop: 17,
  marginBottom: 10,
  paddingLeft: 10,
  paddingRight: 10,
})

export const Backdrop = styled.div({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 1,
  backgroundColor: '#FFF8',
  paddingLeft: 40,
  paddingRight: 40,
})
