import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DashboardModal } from 'pages/covid/dashboard/footer/DashboardModal'
import SaveThisViewIcon from 'assets/images/save-this-view.svg'
import { StringInputGroup } from 'components/Form'
import {
  SaveThisViewFormWrapper,
  EmailMyDashboardButton,
} from 'pages/covid/dashboard/footer/styles'
import { isEmailValid } from 'utils/validations'

interface Props {
  show: boolean
  onHide: () => void
  saveNewDashboard: (name: string, email: string) => void
}

export const SaveThisViewModal: FC<Props> = ({
  show,
  onHide,
  saveNewDashboard,
}) => {
  const { t } = useTranslation()

  const [email, setEmail] = useState<string>('')
  const [name, setName] = useState<string>('')

  const onClickSave = async () => {
    saveNewDashboard(name, email)
    onHide()
  }

  const isFormValid = isEmailValid(email) && name

  return (
    <DashboardModal
      show={show}
      onHide={onHide}
      icon={SaveThisViewIcon}
      titleTransKey="covidDashboard.saveThisViewTitle"
      detailTransKey="covidDashboard.saveThisViewDetail"
    >
      <SaveThisViewFormWrapper>
        <StringInputGroup
          controlProps={{
            placeholder: t('covidDashboard.emailPlaceholder'),
            value: email,
            onChange: ({ target }) => setEmail(target.value.toLowerCase()),
          }}
          labelProps={{
            label: t('covidDashboard.emailLabel'),
          }}
        />
        <StringInputGroup
          controlProps={{
            placeholder: t('covidDashboard.dashboardTitlePlaceholder'),
            value: name,
            onChange: ({ target }) => setName(target.value),
          }}
          labelProps={{
            label: t('covidDashboard.dashboardTitleLabel'),
          }}
        />
        <EmailMyDashboardButton disabled={!isFormValid} onClick={onClickSave}>
          {t('covidDashboard.emailMyDashboardButton')}
        </EmailMyDashboardButton>
      </SaveThisViewFormWrapper>
    </DashboardModal>
  )
}
