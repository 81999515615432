import { DateTime } from 'luxon'
import { flow } from 'lodash/fp'
import * as Apollo from '@apollo/client'
import { OperationVariables } from '@apollo/client'
import { isDocumentNode } from '@apollo/client/utilities'

type Ignore = DateTime

type TransformValue<Keys, Output, K, V> = K extends Keys
  ? Output
  : V extends Ignore
  ? V
  : V extends Array<any>
  ? Array<RecursivelyTransform<Keys, Output, V[number]>>
  : V extends object
  ? RecursivelyTransform<Keys, Output, V>
  : V

type RecursivelyTransform<Keys, Output, T> = {
  [K in keyof T]: TransformValue<Keys, Output, K, T[K]>
}

const isObject = (value: any) =>
  Object.getPrototypeOf(value) === Object.prototype
const isArray = (value: any) => Object.getPrototypeOf(value) === Array.prototype
const isPrimitive = (value: any) => typeof value !== 'object'

const recursivelyTransform = <
  Keys extends ReadonlyArray<string>,
  Input extends any,
  Output extends any
>(
  keys: Keys,
  transform: (input: Input) => Output
) => <T extends any>(
  value: T
): RecursivelyTransform<Keys[number], Output, T> => {
  const pairs = Object.keys(value || {}).map(key => {
    const currentValue = (value as any)[key]
    const newValue = (() => {
      if (currentValue === null || currentValue === undefined) {
        return currentValue
      } else if (keys.includes(key)) {
        return transform(currentValue)
      } else if (isObject(currentValue)) {
        return recursivelyTransform(keys, transform)(currentValue)
      } else if (isArray(currentValue)) {
        return (currentValue as any[]).map(v =>
          isPrimitive(v) ? v : recursivelyTransform(keys, transform)(v)
        )
      } else {
        return currentValue
      }
    })()
    return [key, newValue]
  })
  return Object.fromEntries(pairs)
}

const dateKeys = [
  'startOn',
  'endOn',
  'startDate',
  'endDate',
  'date',
  'startOnLastWeek',
  'endOnLastWeek',
] as const

const dateTimeKeys = [
  'startAt',
  'endAt',
  'lastUpdatedAt',
  'openAt',
  'closeAt',
  'completedAt',
  'dueAt',
  'submittedAt',
] as const

export const deserializeDates = recursivelyTransform(
  dateKeys,
  (input: string) => DateTime.fromISO(input)
)

export const deserializeDateTimes = recursivelyTransform(
  dateTimeKeys,
  (input: string) => DateTime.fromISO(input)
)

export const deserializeISOStrings = flow(
  deserializeDates,
  deserializeDateTimes
)

export const serializeDates = recursivelyTransform(
  dateKeys,
  (input: DateTime | string) => {
    return typeof input === 'string' ? input : input.toISODate()
  }
)

export const serializeDateTimes = recursivelyTransform(
  dateTimeKeys,
  (input: DateTime | string) => {
    return typeof input === 'string' ? input : input.toISO()
  }
)

export const serializeISOStrings = flow(serializeDateTimes, serializeDates)

export type DeserializeISOStrings<T> = RecursivelyTransform<
  typeof dateTimeKeys[number],
  DateTime,
  RecursivelyTransform<typeof dateKeys[number], DateTime, T>
>

export type SerializeISOStrings<T> = RecursivelyTransform<
  typeof dateTimeKeys[number],
  string,
  RecursivelyTransform<typeof dateKeys[number], string, T>
>

export const wrapQuery = <
  Query extends any,
  Variables extends OperationVariables
>(
  hook: (
    baseOptions: Apollo.QueryHookOptions<Query, Variables>
  ) => Apollo.QueryResult<Query, Variables>
) => (
  baseOptions: Apollo.QueryHookOptions<Query, DeserializeISOStrings<Variables>>
) => {
  const transformedOptions = transformQueryOptions(baseOptions)
  const result = hook(transformedOptions)
  const data = result.data && deserializeISOStrings(result.data)

  return { ...result, data }
}

const transformQueryOptions = <
  Query extends any,
  Variables extends OperationVariables
>(
  baseOptions: Apollo.QueryHookOptions<Query, Variables>
) => {
  const variables =
    baseOptions.variables && serializeISOStrings(baseOptions.variables)
  return { ...baseOptions, variables }
}

export const wrapMutation = <
  Mutation extends any,
  Variables extends OperationVariables
>(
  hook: (
    baseOptions?: Apollo.MutationHookOptions<Mutation, Variables>
  ) => Apollo.MutationTuple<Mutation, Variables>
) => (
  baseOptions?: Apollo.MutationHookOptions<
    Mutation,
    DeserializeISOStrings<Variables>
  >
) => {
  const transformedOptions =
    baseOptions && transformMutationHookOptions(baseOptions)

  const [promise, result] = hook(transformedOptions)

  const transformedPromise = transformMutationPromise(promise)
  const data = result.data && deserializeISOStrings(result.data)

  return [transformedPromise, { ...result, data }] as const
}

const transformMutationHookOptions = <
  Mutation extends any,
  Variables extends any
>(
  baseOptions: Apollo.MutationHookOptions<Mutation, Variables>
) => {
  const variables =
    baseOptions.variables && serializeISOStrings(baseOptions.variables)

  const refetchQueries = (() => {
    if (!baseOptions.refetchQueries) {
      return {}
    }
    if (typeof baseOptions.refetchQueries === 'function') {
      return { refetchQueries: baseOptions.refetchQueries }
    }

    if (Array.isArray(baseOptions.refetchQueries)) {
      return {
        refetchQueries: baseOptions.refetchQueries.map(query =>
          typeof query === 'string'
            ? query
            : {
                ...query,
                variables: isDocumentNode(query)
                  ? {}
                  : serializeISOStrings(query.variables || {}),
              }
        ),
      }
    }
  })()

  return { ...baseOptions, ...refetchQueries, variables }
}

const transformMutationPromise = <
  Mutation extends any,
  Variables extends OperationVariables
>(
  promise: (
    baseOptions?: Apollo.MutationFunctionOptions<Mutation, Variables>
  ) => Promise<Apollo.FetchResult<Mutation>>
) => (
  baseOptions?: Apollo.MutationFunctionOptions<
    Mutation,
    DeserializeISOStrings<Variables>
  >
) => {
  const transformedOptions =
    baseOptions && transformMutationFunctionOptions(baseOptions)
  return promise(transformedOptions).then(result => {
    const data = result?.data && deserializeISOStrings(result.data)
    return { ...result, data }
  })
}

const transformMutationFunctionOptions = <
  Mutation extends any,
  Variables extends any
>(
  baseOptions: Apollo.MutationFunctionOptions<Mutation, Variables>
) => {
  const variables =
    baseOptions.variables && serializeISOStrings(baseOptions.variables)
  return { ...baseOptions, variables }
}
