import {
  ActiveLocationShiftRoleFragment,
  EmployeeFragment,
  ShiftFragment,
  ShiftRoleFragment,
} from 'config/graphqlTypes'
import _, { partition } from 'lodash'
import { Interval } from 'luxon'
import { CollapsableCategoryName } from 'pages/plan/CollapsableCategoryName'
import { TimelineHelpers } from 'pages/plan/dailyViewModal/schedule/getGraphTimelineHelpers'
import { DailyTimelineAllCategoriesRow } from 'pages/plan/dailyViewModal/styles'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeeScheduleRow } from './EmployeeScheduleRow'

interface Props {
  shifts: ShiftFragment[]
  shiftRoles: ShiftRoleFragment[]
  VerticalLines: JSX.Element
  timelineHelpers: TimelineHelpers
  isEditable: boolean
  day: Interval
  days: Interval[]
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

const shiftsForEmployee = (
  employee: EmployeeFragment,
  shifts: ShiftFragment[]
) => shifts.filter(({ employee: { id } }) => id === employee.id)

const getEmployeeRoles = (
  shifts: ShiftFragment[],
  shiftRoles: ShiftRoleFragment[]
) =>
  _(
    shiftRoles.flatMap(shiftRole => {
      const [roleShifts, otherShifts] = partition(
        shifts,
        ({ shiftRole: givenShiftRole }) => givenShiftRole?.id === shiftRole.id
      )
      return _(roleShifts)
        .map(({ employee, startAt }) => ({
          id: employee.id,
          name: employee.name,
          startAt: startAt,
          employee: employee,
          shiftRole: shiftRole,
          roleShifts: roleShifts,
          otherShifts: otherShifts,
        }))
        .uniqBy('id')
        .value()
    })
  )
    .sortBy(['startAt', 'name'])
    .value()

export const DailyViewAllShiftsDisplay: FC<Props> = ({
  day,
  days,
  shifts,
  shiftRoles,
  VerticalLines,
  timelineHelpers,
  isEditable,
  activeLocationShiftRoles,
}) => {
  const { t } = useTranslation()

  const [collapsed, setCollapsed] = useState(false)
  const toggleCollapsed = () => setCollapsed(!collapsed)

  const employeeRoles = getEmployeeRoles(shifts, shiftRoles)

  return (
    <>
      <DailyTimelineAllCategoriesRow onClick={toggleCollapsed}>
        <CollapsableCategoryName
          name={t('planning.dailyViewModal.allShifts')}
          collapsed={collapsed}
        />
      </DailyTimelineAllCategoriesRow>

      {collapsed ||
        employeeRoles.map(
          ({ employee, shiftRole, roleShifts, otherShifts }) => (
            <EmployeeScheduleRow
              key={employee.id}
              role={shiftRole}
              employee={employee}
              roleShifts={shiftsForEmployee(employee, roleShifts)}
              otherShifts={shiftsForEmployee(employee, otherShifts)}
              VerticalLines={VerticalLines}
              timelineHelpers={timelineHelpers}
              isEditable={isEditable}
              day={day}
              days={days}
              activeLocationShiftRoles={activeLocationShiftRoles}
            />
          )
        )}
    </>
  )
}
