import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { largeBodyBoldTextStyle } from 'config/styles'
import { isUnassignedName } from 'utils/isShiftUnassigned'

const avatarStyles = {
  borderRadius: '50%',
  backgroundColor: 'var(--blue)',
}

const AvatarImg = styled.img<{ size: number }>(({ size }) => ({
  ...avatarStyles,
  height: size,
  width: size,
}))

export const AvatarDiv = styled.div<{
  size: number
  fontSize?: string
}>(({ size, fontSize }) => ({
  ...avatarStyles,
  ...largeBodyBoldTextStyle,
  textTransform: 'uppercase',
  height: size,
  width: size,
  fontSize: fontSize ?? '',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  color: 'var(--gray4)',
  backgroundColor: 'var(--gray6)',

  '@media print': {
    display: 'none',
  },
}))

interface Props {
  name: string
  avatarUrl?: string | null
  size?: number
  fontSize?: string
}

const unassignedAvatar = '?'

export const Avatar: FC<Props> = ({ name, avatarUrl, size = 30, fontSize }) => {
  const { t } = useTranslation()

  const avatarLetter = isUnassignedName(name) ? unassignedAvatar : name[0]

  return avatarUrl ? (
    <AvatarImg
      src={avatarUrl}
      alt={t('navbar.avatarAlt', { name })}
      size={size}
      className="avatar"
    />
  ) : (
    <AvatarDiv size={size} fontSize={fontSize} className="avatar">
      {avatarLetter}
    </AvatarDiv>
  )
}
