import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import { LargeHeaderText, RegularBoldBodyText } from 'components/Text'
import { FlexCol, standardSidePadding, gutterSize } from 'pages/covid/styles'
import { IS_COLUMN_LAYOUT } from 'pages/covid/dashboard/useIsColumnLayout'

export const DashboardContainer = styled(Container)({
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 130,
})

export const HeaderRowStyled = styled(Row)({
  alignItems: 'center',
  backgroundColor: 'var(--gray7)',
  paddingRight: standardSidePadding,
  [IS_COLUMN_LAYOUT]: {
    display: 'none',
  },
})

export const HeaderCol = styled(Col)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: 'var(--gray7)',
  paddingTop: 25,
  paddingBottom: 25,
  paddingRight: `${gutterSize}px !important`,
  '&:first-child': {
    alignItems: 'flex-start',
    textAlign: 'left',
    paddingLeft: `${standardSidePadding} !important`,
    [IS_COLUMN_LAYOUT]: {
      alignItems: 'center',
      textAlign: 'center',
      paddingLeft: `${gutterSize}px !important`,
    },
  },
})

export const HeaderTitle = styled(RegularBoldBodyText)({
  color: 'var(--gray1)',
  marginBottom: 6,
  maxWidth: 250,
})

export const HeaderSubTitle = styled.p({
  color: 'var(--gray4)',
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '15px',
  marginBottom: 0,
  maxWidth: 250,
  height: 30,
})

export const DashboardCenterRow = styled(Row)({
  justifyContent: 'center',
})

export const DashboardCenterCol = styled(FlexCol)({
  textAlign: 'center',
  alignItems: 'center',
  maxWidth: 780,
  padding: `0 ${standardSidePadding} !important`,
  height: 'calc(100vh - 225px)',
  [IS_COLUMN_LAYOUT]: {
    height: 'calc(100vh - 110px)',
  },
})

export const NoLocationsAddedIcon = styled.img({
  height: 127,
  width: 127,
  marginBottom: 25,
})

export const NoLocationsAddedTitle = styled(LargeHeaderText)({
  color: 'var(--gray4)',
  marginBottom: 12,
})

export const NoLocationsAddedDetail = styled.p({
  color: 'var(--gray3)',
  fontSize: 26,
  lineHeight: '31px',
  fontWeight: 400,
})
