import React from 'react'
import { Interval } from 'luxon'
import { sum } from 'lodash'
import { useTranslation } from 'react-i18next'

import {
  IntervalOrderSumFragment,
  IntervalPredictionSumFragment,
  PlanSummaryWithDailySummariesFragment,
  SalesDayPartFragment,
} from 'config/graphqlTypes'
import { CurrentSummary, PreviousSummary } from 'pages/tracking/Summary'
import { Breakdown, PreviousBreakdown } from 'pages/tracking/breakdown/index'
import { Insight } from 'pages/tracking/insight/index'
import SalesChart from 'pages/tracking/SalesChart'
import {
  CurrentCol,
  CurrentSalesChart,
  PreviousCol,
  PreviousSalesChart,
} from 'pages/tracking/styles'

interface ColumnProps {
  isWeekView: boolean
  setIsWeekView: (isDay: boolean) => void
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  latestPredictedSales: number
  interval: Interval
  plan: PlanSummaryWithDailySummariesFragment
  submittedPlannedSales: number | null
  laborActual: number | null
  scheduledLabor: number | null
  laborPredicted: number | null
  laborOptimal: number | null
  optimalSalesForActualLabor: number | null
  actualSales: number | null
  salesType: string
  onClickLaborActualPrevious: () => void
  onClickLaborActualCurrent: () => void
  locationSalesDayParts: SalesDayPartFragment[]
}

export const CurrentColumn = ({
  isWeekView,
  setIsWeekView,
  interval,
  submittedPlannedSales,
  orderSums,
  actualSales,
  laborActual,
  predictionSums,
  latestPredictedSales,
  scheduledLabor,
  laborPredicted,
  plan,
  salesType,
  onClickLaborActualCurrent,
  locationSalesDayParts,
}: ColumnProps) => (
  <CurrentCol sm={12} lg={8}>
    <CurrentSummary
      laborActual={laborActual}
      predictionSums={predictionSums}
      orderSums={orderSums}
      latestPredictedSales={latestPredictedSales}
      setIsWeekView={setIsWeekView}
      isWeekView={isWeekView}
      scheduledLabor={scheduledLabor}
      laborPredicted={laborPredicted}
      interval={interval}
      submittedAt={plan?.submittedAt}
      salesType={salesType}
      onClickLaborActual={onClickLaborActualCurrent}
    />
    <CurrentSalesChart>
      <SalesChart
        interval={interval}
        actualLaborHours={scheduledLabor}
        actualLaborTotal={submittedPlannedSales}
        highlightMostRecentActual
        isWeekView={isWeekView}
        targetLaborHours={laborPredicted}
        orderSums={orderSums}
        predictionSums={predictionSums}
        targetLaborTotal={sum(predictionSums.map(p => p.reportedSales))}
        prefix="current"
        salesType={salesType}
      />
    </CurrentSalesChart>
    <Breakdown
      salesType={salesType}
      interval={interval}
      isWeekView={isWeekView}
      orderSums={orderSums}
      predictionSums={predictionSums}
      plan={plan}
      locationSalesDayParts={locationSalesDayParts}
    />
  </CurrentCol>
)

export const PreviousColumn = ({
  isWeekView,
  interval,
  orderSums,
  predictionSums,
  latestPredictedSales,
  laborActual,
  scheduledLabor,
  laborPredicted,
  laborOptimal,
  optimalSalesForActualLabor,
  actualSales,
  plan,
  salesType,
  onClickLaborActualPrevious,
}: ColumnProps) => {
  const { t } = useTranslation()

  return (
    <PreviousCol
      sm={12}
      lg={4}
      data-testid={t('tracking.yesterdaysRecap.yesterdaysRecap')}
    >
      <PreviousSummary
        orderSums={orderSums}
        interval={interval}
        latestPredictedSales={latestPredictedSales}
        isWeekView={isWeekView}
        laborActual={laborActual}
        scheduledLabor={scheduledLabor}
        laborPredicted={laborPredicted}
        laborOptimal={laborOptimal}
        submittedAt={plan?.submittedAt}
        salesType={salesType}
        onClickLaborActual={onClickLaborActualPrevious}
      />
      <PreviousSalesChart>
        <SalesChart
          constrained
          interval={interval}
          isWeekView={isWeekView}
          actualLaborHours={laborActual}
          actualLaborTotal={optimalSalesForActualLabor}
          targetLaborHours={laborOptimal}
          targetLaborTotal={actualSales || 0}
          orderSums={orderSums}
          predictionSums={predictionSums}
          prefix="previous"
          salesType={salesType}
        />
        {!isWeekView && (
          <Insight
            date={interval.start}
            orderSums={orderSums}
            predictionSums={predictionSums}
            salesType={salesType}
          />
        )}
      </PreviousSalesChart>
      <PreviousBreakdown
        isWeekView={isWeekView}
        orderSums={orderSums}
        predictionSums={predictionSums}
        salesType={salesType}
      />
    </PreviousCol>
  )
}
