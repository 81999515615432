import {
  useTryCurrentLocation,
  useCurrentLocationId,
} from 'queries/useCurrentLocation'
import { Interval, DateTime } from 'luxon'

const LOCATION_LEARNING_LENGTH_WEEKS = 5
export const USER_LEARNING_NOTIFICATIONS_KEY =
  'LINEUP_USER_LEARNING_NOTIFICATIONS'

// We store an array of locationIds for each location in which the user
// has already seen and dismissed the active learning notification

const getSeenNotifications = () => {
  const userSeenNotifictionsRaw = window.localStorage.getItem(
    USER_LEARNING_NOTIFICATIONS_KEY
  )

  return (userSeenNotifictionsRaw
    ? JSON.parse(userSeenNotifictionsRaw)
    : []) as string[]
}

const dismissActiveLearningNotice = (locationId: string) => () => {
  const seenNotifications = getSeenNotifications()

  if (!seenNotifications.includes(locationId)) {
    const newSeenNotifications = [...seenNotifications, locationId]
    window.localStorage.setItem(
      USER_LEARNING_NOTIFICATIONS_KEY,
      JSON.stringify(newSeenNotifications)
    )
  }
}

export const useDismissActiveLearningNotice = () => {
  const locationId = useCurrentLocationId()
  return [dismissActiveLearningNotice(locationId)]
}

export const useActiveLearningNotice = () => {
  const location = useTryCurrentLocation()
  if (!location) return false

  const locationIsLearning =
    Interval.fromDateTimes(
      DateTime.fromISO(location.createdAt),
      DateTime.local()
    ).length('weeks') < LOCATION_LEARNING_LENGTH_WEEKS

  if (!locationIsLearning) return false

  const userSeenNotifictions = getSeenNotifications()
  const showNotification = !userSeenNotifictions.includes(location.id)

  return showNotification
}
