import React from 'react'
import { useTranslation } from 'react-i18next'
import { round } from 'lodash'

import { asDollars, asDollarsOrEmDash } from 'utils/currency'
import { MetricProps, Metric } from './Metric'
import { formattedNumberWithFallback } from 'utils/formattedNumberWithFallback'

interface MetricListProps {
  metrics: MetricProps[]
}

export const MetricList = ({ metrics }: MetricListProps) => (
  <>
    {metrics.map(metric => (
      <Metric key={metric.id} {...metric} />
    ))}
  </>
)

interface SalesMetricListProps {
  reportedSales: number | null
  latestPredictedSales: number
  laborActual: number | null
  scheduledLabor: number | null
  laborPredicted: number | null
  laborOptimal?: number | null
  column: 'current' | 'previous'
  salesType: string
  onClickLaborActual: () => void
}

export const SalesMetricList = ({
  column,
  laborActual,
  scheduledLabor,
  laborPredicted,
  laborOptimal,
  reportedSales,
  latestPredictedSales,
  onClickLaborActual,
}: SalesMetricListProps) => {
  const { t } = useTranslation()
  const previous = column === 'previous'
  const salesPredicted = latestPredictedSales
  const isShort = true
  const isSmall = previous
  const translationNamespace = previous ? 'yesterdaysRecap' : 'todaysProgress'

  const ReportedPosTooltip = () => (
    <div>
      <p>{t(`tracking.${translationNamespace}.reportedPOS`)}</p>
      <a
        href="https://intercom.help/lineuplearning/en/articles/8312284-pos-order-data"
        target="_blank"
        rel="noreferrer"
      >
        {t(`tracking.${translationNamespace}.reportedPOSLink`)}
      </a>
    </div>
  )

  const salesMetrics = [
    {
      id: 'sales-predicted',
      isSmall,
      isShort,
      title: t(`tracking.${translationNamespace}.sales`),
      lineColor: '--blue-light',
      name: t(`tracking.${translationNamespace}.predicted`),
      value: asDollars(salesPredicted),
      valueColor: '--gray4',
    },
    {
      id: 'sales-actual',
      isSmall,
      isShort,
      lineColor: '--blue',
      name: t(`tracking.${translationNamespace}.salesActual`),
      value: asDollarsOrEmDash(reportedSales),
      tooltip: <ReportedPosTooltip />,
    },
  ]
  const currentLabor = [
    {
      id: 'labor-planned',
      isSmall,
      isShort,
      title: t(`tracking.${translationNamespace}.labor`),
      lineColor: '--teal',
      name: t(`tracking.${translationNamespace}.planned`),
      value: formattedNumberWithFallback(scheduledLabor, num =>
        t(`misc.hourAbbreviationWithCount`, {
          count: round(num),
        })
      ),
    },
    {
      id: 'labor-actual',
      isSmall,
      isShort,
      lineColor: '--teal',
      underlined: true,
      cursor: 'pointer',
      name: t(`tracking.${translationNamespace}.laborActual`),
      value: formattedNumberWithFallback(laborActual || 0, num =>
        t(`misc.hourAbbreviationWithCount`, {
          count: round(num),
        })
      ),
      onClick: onClickLaborActual,
    },
    {
      id: 'labor-suggested',
      isSmall,
      isShort,
      lineColor: '--teal-light',
      name: t(`tracking.${translationNamespace}.suggested`),
      value: formattedNumberWithFallback(laborPredicted, num =>
        t(`misc.hourAbbreviationWithCount`, {
          count: round(num),
        })
      ),
      valueColor: '--gray4',
      tooltip: t(`tracking.${translationNamespace}.tooltips.suggested`),
    },
  ]
  const previousLabor = [
    {
      id: 'labor-actual',
      isSmall,
      isShort,
      underlined: true,
      cursor: 'pointer',
      title: t(`tracking.${translationNamespace}.labor`),
      lineColor: '--teal',
      name: t(`tracking.${translationNamespace}.laborActual`),
      value: formattedNumberWithFallback(laborActual || 0, num =>
        t(`misc.hourAbbreviationWithCount`, {
          count: round(num),
        })
      ),
      onClick: onClickLaborActual,
    },
    {
      id: 'labor-optimal',
      isSmall,
      isShort,
      lineColor: '--teal-light',
      name: t(`tracking.${translationNamespace}.optimal`),
      value: formattedNumberWithFallback(laborOptimal || null, num =>
        t(`misc.hourAbbreviationWithCount`, {
          count: round(num),
        })
      ),
      valueColor: '--gray4',
      tooltip: t(`tracking.${translationNamespace}.tooltips.optimal`),
    },
  ]
  const metrics = [
    ...salesMetrics,
    ...(previous ? previousLabor : currentLabor),
  ]
  return <MetricList metrics={metrics} />
}
