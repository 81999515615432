import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { regularBodyTextStyle, regularBodyBoldTextStyle } from 'config/styles'
import {
  LargeBodyBoldText,
  RegularBoldBodyText,
  GraphLabelText,
  RegularBodyText,
  H3Text,
  TableLabelText,
} from 'components/Text'
import Button, { TextButton } from 'components/Button'
import { FlexCol, standardSidePadding, gutterSize } from 'pages/covid/styles'
import { IS_COLUMN_LAYOUT } from 'pages/covid/dashboard/useIsColumnLayout'

export const MapWrapper = styled.div({
  height: 130,
  width: 130,
  [IS_COLUMN_LAYOUT]: {
    width: '100%',
    marginBottom: 20,
  },
  // Disable "Use 2 fingers to move map" message
  '.gm-style-pbc': {
    display: 'none !important',
  },
})

export const MapPin = styled.img({
  position: 'relative',
  right: 12,
  bottom: 20,
})

export const AddressTextCol = styled(Col)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

export const AddressSecondLine = styled(RegularBoldBodyText)({
  marginTop: 5,
  color: 'var(--gray4)',
})

export const BenchmarkWrapper = styled.div<{ blur?: boolean }>(({ blur }) => ({
  marginTop: 20,
  filter: blur ? 'blur(8px)' : 'none',
  WebkitFilter: blur ? 'blur(8px)' : 'none',
}))

export const BenchmarkMetric = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const BenchmarkTimeWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  height: 30,
})

export const BenchmarkTimeFrame = styled(GraphLabelText)({
  marginBottom: 0,
  color: 'var(--gray2)',
})

export const DateDisplay = styled.span({
  fontSize: 10,
  fontWeight: 500,
  color: 'var(--gray4)',
  fontStyle: 'italic',
})

export const BenchmarkDivider = styled.div({
  marginTop: 10,
  borderBottom: '1px solid var(--gray5)',
  marginBottom: 10,
  marginLeft: `-${gutterSize}px`,
  [IS_COLUMN_LAYOUT]: {
    marginLeft: 0,
  },
})

export const StyledAddressRow = styled(Row)({
  paddingRight: standardSidePadding,
  borderBottom: 'solid 1px var(--gray5)',
  [IS_COLUMN_LAYOUT]: {
    paddingRight: 0,
  },
})

export const MainMetricsRow = styled(Row)({
  alignItems: 'center',
})

export const AddressColContentWrapper = styled.div<{
  isFirst?: boolean
}>(({ isFirst }) => ({
  paddingLeft: isFirst ? standardSidePadding : 0,
  paddingRight: 15,
  paddingTop: 22,
  paddingBottom: 22,
  textAlign: isFirst ? 'left' : 'center',
  [IS_COLUMN_LAYOUT]: {
    paddingLeft: standardSidePadding,
    paddingRight: standardSidePadding,
  },
}))

export const NormalWeightSpan = styled.span({
  fontWeight: 'normal',
})

export const LineChartWrapper = styled.div({
  marginTop: 25,
  position: 'relative',
  height: 58,
})

export const TopNeighborhoodBusinessesWrapper = styled.div({
  marginTop: 35,
})

export const TopBusinessesHeader = styled(LargeBodyBoldText)({
  color: 'var(--gray2)',
  marginBottom: 15,
})

export const TopBusinessesList = styled.ol<{ blur?: boolean }>(({ blur }) => ({
  listStylePosition: 'inside',
  paddingLeft: 0,
  filter: blur ? 'blur(8px)' : 'none',
  WebkitFilter: blur ? 'blur(8px)' : 'none',
}))

export const TopBusinessListing = styled.li({
  ...regularBodyTextStyle,
  color: 'var(--gray1)',
  marginBottom: 9,
})

export const TopBusinessListingName = styled.span({
  marginLeft: 5,
})

export const CovidCardContentWrapper = styled.div({
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 22,
  [IS_COLUMN_LAYOUT]: {
    paddingLeft: standardSidePadding,
    paddingRight: standardSidePadding,
  },
})

export const CovidCardDivider = styled.div({
  borderTop: '2px solid var(--gray5)',
  marginBottom: 25,
})

export const CovidSourceText = styled(RegularBodyText)({
  marginTop: 5,
  marginBottom: 35,
})

export const CovidMetricCol = styled(Col)<{ blur?: boolean }>(({ blur }) => ({
  paddingRight: '15px !important',
  paddingBottom: 15,
  [IS_COLUMN_LAYOUT]: {
    paddingRight: `${standardSidePadding}px !important`,
  },
  filter: blur ? 'blur(8px)' : 'none',
  WebkitFilter: blur ? 'blur(8px)' : 'none',
}))

export const CovidGraphHeader = styled(RegularBodyText)({
  fontWeight: 700,
  color: 'var(--gray4)',
  marginBottom: 5,
})

export const CovidLatestValue = styled(H3Text)({
  marginBottom: 15,
})

export const CovidBarChartWrapper = styled.div({
  height: 100,
})

export const CovidDataDisclaimer = styled(TableLabelText)({
  color: 'var(--gray4)',
})

export const DeleteLocationButtonWrapper = styled.div({
  position: 'absolute',
  top: 71,
  right: -40,
  [IS_COLUMN_LAYOUT]: {
    position: 'static',
    inset: 'auto',
    marginTop: 50,
    marginBottom: 50,
    display: 'flex',
    justifyContent: 'center',
  },
})

export const EmailDataButton = styled(Button)({
  width: '20%',
  position: 'absolute',
  top: '40%',
  left: '50%',
})

export const DeleteLocationButton = styled.img({
  cursor: 'pointer',
  [IS_COLUMN_LAYOUT]: {
    height: 30,
  },
})

export const ErrorLoadingCol = styled(FlexCol)({
  ...regularBodyBoldTextStyle,
  height: 180,
  textAlign: 'center',
  color: 'var(--gray4)',
  alignItems: 'center',
})

export const TryAgainButton = styled(Button)({
  width: 200,
  marginTop: 15,
})

export const Subtitle = styled.div({
  display: 'flex',
  color: 'var(--gray4)',
  fontWeight: 800,
  justifyContent: 'center',
  fontSize: 12,
  height: 21,
  img: {
    height: 21,
    marginRight: 5,
  },
})

export const GraphTimeFrameWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

export const GraphTimeframe = styled.span<{ color: string }>(({ color }) => ({
  color: color,
  fontSize: 10,
  fontWeight: 800,
}))

export const ExpandRowButton = styled(TextButton)({
  position: 'absolute',
  left: '1vw',
  top: 67,
  [IS_COLUMN_LAYOUT]: {
    display: 'none',
  },
})

export const ExpandRowChevron = styled.img<{ isExpanded: boolean }>(
  ({ isExpanded }) =>
    isExpanded
      ? {}
      : {
          transform: 'rotate(-0.5turn) translateY(4px)',
        }
)
