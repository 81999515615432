import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import logo from 'assets/images/loading.gif'
import autoSchedulingAnimation from 'assets/images/auto-scheduling-animation.gif'

const Wrapper = styled.div<{ height: string }>(({ height }) => ({
  display: 'flex',
  flexGrow: 1,
  height,
  alignItems: 'center',
  justifyContent: 'center',
}))

const AutoScheduleImageBottomPadding = styled.div({
  textAlign: 'center',
  paddingBottom: '20px',
})

const StyledImageDefault = styled.img({})

export const LoadingImage = ({ StyledImage = StyledImageDefault }) => {
  const { t } = useTranslation()

  return <StyledImage src={logo} alt={t('misc.loading')} />
}

export const LoadingAutoSchedulingAnimation = ({
  StyledImage = StyledImageDefault,
}) => {
  const { t } = useTranslation()

  return (
    <AutoScheduleImageBottomPadding>
      <StyledImage
        width="62%"
        src={autoSchedulingAnimation}
        alt={t('misc.loading')}
      />
    </AutoScheduleImageBottomPadding>
  )
}

export const Loading = ({
  height = '100vh',
  StyledImage = StyledImageDefault,
}) => (
  <Wrapper height={height}>
    <LoadingImage StyledImage={StyledImage} />
  </Wrapper>
)
