import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'

import { useUpsertEmployeeAvailabilityMutation } from 'config/graphqlTypes'
import 'react-phone-number-input/style.css'
import { Form, Check } from 'components/Form'
import Button from 'components/Button'
import { EmployeeForEditFragment } from 'config/graphqlTypes'
import { routeMap, useLocationNavigation } from 'pages/Routes'
import { toast } from 'react-toastify'
import {
  FormWrapper,
  ButtonsWrapper,
  CancelButton,
  AvailabilityInputWrapper,
  AvailabilityCheckWrapper,
  DayWrapper,
  Divider,
  DailyLabel,
  ManageTime,
} from '../styles'
import {
  DaysOfWeek,
  dayOfWeekToNumber,
  getDefaultShifts,
  sortDaysAvailable,
  DaysAvailable,
  TimeSlot,
} from 'utils/availability'
import plusIcon from 'assets/images/plus-grey.svg'
import trashIcon from 'assets/images/trash.svg'

import {
  Time,
  TimeInput,
  getTimeFromString,
  getStringFromTime,
} from 'components/TimeInput'
import { DateTime } from 'luxon'

interface Props {
  submitText: string
  employee: EmployeeForEditFragment
  currentPage: number | undefined
}

export const EmployeeAvailabilityForm = ({
  submitText,
  employee,
  currentPage,
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { locationPath } = useLocationNavigation()

  const goToEmployees = () =>
    history.push(
      locationPath +
        routeMap.location.management.employees +
        `?currentPage=${currentPage}&employeeId=${employee.id}`
    )

  const [
    updateEmployeeAvailability,
    { loading, error },
  ] = useUpsertEmployeeAvailabilityMutation()

  const toastNotify = (msg: string) =>
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_LEFT,
    })

  const handleSubmitAvailability = async () => {
    const filteredDays = availability.daysAvailable.map(day => {
      day.timeSlots = day.timeSlots.filter(
        slot => slot.startTime && slot.endTime
      )
      return day
    })
    const newAvailability = { ...availability, daysAvailable: filteredDays }
    try {
      await updateEmployeeAvailability({
        variables: {
          input: { ...newAvailability },
        },
      })
      if (error) {
        toastNotify(t('management.availability.errorSubmit'))
      } else {
        toastNotify(t('management.availability.successSubmit'))
        goToEmployees()
      }
    } catch (error) {
      toastNotify(t('management.availability.errorSubmit'))
    }
  }

  const defaultAvailability = (employee: EmployeeForEditFragment) => ({
    daysAvailable: getDefaultShifts(employee.locations[0]),
    employeeId: employee.id,
    state: 'approved',
    employeeNote: null,
    managerNote: null,
  })

  const availabilityFromEmployee = (employee: EmployeeForEditFragment) => {
    const request = employee.availability
    if (!request) {
      return null
    }

    return {
      daysAvailable: sortDaysAvailable(
        [...(request.daysAvailable || [])] as DaysAvailable[],
        employee.locations[0]
      ),
      employeeId: employee.id,
      state: 'approved',
      employeeNote: request.employeeNote,
      managerNote: request.managerNote,
    }
  }

  const [availability, setAvailability] = useState(
    availabilityFromEmployee(employee) || defaultAvailability(employee)
  )

  const onChangeStartAt = (
    startAt: Time | null,
    dayOfWeek: DaysOfWeek,
    index: number
  ) => {
    if (!startAt) return
    const newSlot =
      availability.daysAvailable[dayOfWeekToNumber(dayOfWeek)].timeSlots[index]

    newSlot.startTime = getStringFromTime(DateTime.fromObject(startAt), startAt)
    updateTimeSlot(newSlot, dayOfWeek, index)
  }

  const onChangeEndAt = (
    endAt: Time | null,
    dayOfWeek: DaysOfWeek,
    index: number
  ) => {
    if (!endAt) return
    const newSlot =
      availability.daysAvailable[dayOfWeekToNumber(dayOfWeek)].timeSlots[index]

    newSlot.endTime = getStringFromTime(DateTime.fromObject(endAt), endAt)
    updateTimeSlot(newSlot, dayOfWeek, index)
  }

  const onChangeUnavailable = (dayOfWeek: DaysOfWeek) => {
    const dayAvailable = availability.daysAvailable[
      dayOfWeekToNumber(dayOfWeek)
    ] as DaysAvailable
    dayAvailable.unavailable = !dayAvailable.unavailable
    updateDayAvailable(dayAvailable)
  }

  const updateTimeSlot = (
    slot: TimeSlot,
    dayOfWeek: DaysOfWeek,
    index: number
  ) => {
    const newAvailability = { ...availability }
    newAvailability.daysAvailable = [...availability.daysAvailable]
    newAvailability.daysAvailable[dayOfWeekToNumber(dayOfWeek)].timeSlots[
      index
    ] = slot
    setAvailability(newAvailability)
  }

  const [showAddButtons, setShowAddButtons] = useState(() =>
    availability.daysAvailable.map(d => d.unavailable)
  )

  const updateDayAvailable = (day: DaysAvailable) => {
    const newAvailability = { ...availability }
    newAvailability.daysAvailable = [...availability.daysAvailable]
    newAvailability.daysAvailable[dayOfWeekToNumber(day.dayOfWeek)] = day
    setAvailability(newAvailability)
  }

  const addTimeSlot = (day: DaysAvailable) => {
    if (day.timeSlots.length >= 3) return

    const newAvailability = { ...availability }
    newAvailability.daysAvailable = [...availability.daysAvailable]
    newAvailability.daysAvailable[
      dayOfWeekToNumber(day.dayOfWeek)
    ].timeSlots = [...day.timeSlots, { id: '', startTime: '', endTime: '' }]
    setAvailability(newAvailability)
  }

  const removeTimeSlot = (day: DaysAvailable, index: number) => {
    const newAvailability = { ...availability }
    newAvailability.daysAvailable = [...availability.daysAvailable]
    newAvailability.daysAvailable[
      dayOfWeekToNumber(day.dayOfWeek)
    ].timeSlots = day.timeSlots.filter((_, i) => i !== index)
    setAvailability(newAvailability)
  }

  return (
    <FormWrapper>
      <Form>
        {availability.daysAvailable.map((dayAvailable, index) => {
          return (
            <div key={`${dayAvailable.dayOfWeek}_${index}`}>
              <DailyLabel>{capitalize(dayAvailable.dayOfWeek)}</DailyLabel>
              {dayAvailable.timeSlots.map((timeSlot: TimeSlot, i: number) => (
                <DayWrapper key={`${timeSlot.id}`}>
                  <AvailabilityInputWrapper>
                    <TimeInput
                      controlId="start"
                      after={null}
                      onChange={event =>
                        onChangeStartAt(event, dayAvailable.dayOfWeek, i)
                      }
                      time={getTimeFromString(timeSlot?.startTime)}
                      disabled={dayAvailable.unavailable}
                      dateTime={null}
                    />
                  </AvailabilityInputWrapper>
                  <Divider>——</Divider>
                  <AvailabilityInputWrapper>
                    <TimeInput
                      controlId="end"
                      after={getTimeFromString(timeSlot?.startTime)}
                      onChange={event =>
                        onChangeEndAt(event, dayAvailable.dayOfWeek, i)
                      }
                      time={getTimeFromString(timeSlot?.endTime)}
                      disabled={dayAvailable.unavailable}
                      dateTime={null}
                    />
                  </AvailabilityInputWrapper>
                  {!showAddButtons[index] &&
                    (i === 0 ? (
                      <ManageTime onClick={() => addTimeSlot(dayAvailable)}>
                        <img src={plusIcon} alt="" />
                        {t('management.availability.addTime')}
                      </ManageTime>
                    ) : (
                      <ManageTime
                        onClick={() => removeTimeSlot(dayAvailable, i)}
                      >
                        <img src={trashIcon} alt="" />
                        {t('management.availability.removeTime')}
                      </ManageTime>
                    ))}
                </DayWrapper>
              ))}
              <AvailabilityCheckWrapper className={'mb-4'}>
                <Check
                  id={`unavailable-${index}`}
                  label={t('management.availability.notAvailableCheck')}
                  inputProps={{
                    checked: dayAvailable.unavailable,
                    onChange: () => {
                      setShowAddButtons(
                        showAddButtons.map((s, i) => (i === index ? !s : s))
                      )
                      onChangeUnavailable(dayAvailable.dayOfWeek)
                    },
                  }}
                />
              </AvailabilityCheckWrapper>
            </div>
          )
        })}
      </Form>
      <ButtonsWrapper>
        <Button
          onClick={goToEmployees}
          block
          variant="secondary"
          as={CancelButton}
        >
          {t('settings.employeeSettings.employeeForm.cancel')}
        </Button>
        <Button
          onClick={handleSubmitAvailability}
          block
          disabled={loading}
          type="submit"
          variant="blue"
        >
          {submitText}
        </Button>
      </ButtonsWrapper>
    </FormWrapper>
  )
}
