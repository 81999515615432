import React from 'react'
import { DateTime, Interval } from 'luxon'
import { DailyDetailsPlanRow } from './PlanRow'
import { useTranslation } from 'react-i18next'
import { useGetReservationDetails } from 'queries/useGetReservationDetails'

interface Props {
  days: Interval[]
  endDate: DateTime
  startDate: DateTime
}

export const ReservationsRow = ({ days, endDate, startDate }: Props) => {
  const { t } = useTranslation()
  const reservations = useGetReservationDetails(startDate, endDate)

  const getReservationDetails = (day: Interval) =>
    reservations.filter(r => r.fiscalDate === day.start.toISODate())

  return (
    <DailyDetailsPlanRow
      isReservation
      days={days}
      title={t('planning.reservations.rowTitle')}
      getDailyDetails={getReservationDetails}
      noDetailsMsg={t('events.unavailable')}
    />
  )
}
