import React, { FC, useEffect, useState } from 'react'
import { DateTime, Interval } from 'luxon'
import Modal from 'react-bootstrap/Modal'
import { first, last } from 'lodash'

import {
  ShiftFragment,
  EmployeeFragment,
  ShiftRoleFragment,
  ActiveLocationShiftRoleFragment,
} from 'config/graphqlTypes'
import Button from 'components/Button'
import StyledModalTitle from 'components/StyledModalTitle'
import { useGetOperatingHoursData } from 'queries/useGetOperatingHours'
import { isSameDate } from 'utils/daysAndTimes'
import { isRowEntityUnavilability } from 'pages/plan/scheduling/RowEntity'
import { ShiftModalViolations } from 'pages/plan/scheduling/ShiftModalViolations'
import { getTimeFromDateTime, Time } from 'components/TimeInput'
import {
  ModalFooter,
  ModalButtonWrapper,
  ShiftEditButton,
} from 'pages/plan/scheduling/styles'
import { SchedulingForm } from './SchedulingForm'
import { ShiftSwapInProgressMessage } from './ShiftSwapInProgressMessage'
import { getStartTimeEndTime } from '../../../utils/getStartTimeEndTime'
import { useShiftMutations } from './useShiftMutations'
import { isFormValid } from 'pages/plan/scheduling/isFormValid'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { ConfirmDeleteModal } from 'pages/plan/scheduling/ConfirmDeleteModal'
import { useCurrentLocationActiveEmployeesAndRoles } from 'pages/plan/shiftRoleCategoryHelpers'
import { useGetEmployeesShiftsForWeek } from 'queries/useGetEmployeeShiftsForWeek'
import { usePlanId } from '../planQueryVariablesContext'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { useIsEmployeeUnavailable } from './SchedulingFormHelpers'
import { Spinner } from 'react-bootstrap'
import { Loading } from '../../../components/Loading'

const roleSelectInitialValue = (
  shift: ShiftFragment | null,
  selectedEmployee: EmployeeFragment | null,
  selectedShiftRole: ShiftRoleFragment | null
) => {
  if (shift) {
    const { shiftRole } = shift
    return shiftRole?.id ?? null
  }

  if (selectedEmployee) {
    return null
  }

  return selectedShiftRole?.id ?? null
}

const makeRoleSelectOptions = (
  activeShiftRoles: Array<Pick<ShiftRoleFragment, 'id' | 'name'>>,
  t: TFunction
) => {
  const roleSelectOptions = []

  roleSelectOptions.push(['', ''])
  roleSelectOptions.push(...activeShiftRoles.map(r => [r.id, r.name]))

  return roleSelectOptions
}

const BusySpinner = ({ hidden }: { hidden: boolean }) => (
  <Spinner animation={'border'} size={'sm'} hidden={hidden} />
)

interface shiftBreak {
  durationMins: number
}

interface Props {
  show: boolean
  onHide: () => void
  selectedDate: DateTime
  selectedShift?: ShiftFragment | null
  selectedEmployee?: EmployeeFragment | null
  selectedShiftRole?: ShiftRoleFragment | null
  days: Interval[]
  hideDateSelect?: boolean
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export const ShiftModal: FC<Props> = ({
  show,
  onHide,
  selectedDate,
  selectedShift,
  selectedEmployee,
  selectedShiftRole,
  days,
  hideDateSelect,
  activeLocationShiftRoles,
}) => {
  const { t } = useTranslation()
  const planId = usePlanId()
  const { data: employeeShiftsForWeek } = useGetEmployeesShiftsForWeek(
    planId!,
    !show
  )
  const locationId = useCurrentLocationId()
  const {
    activeEmployeesAndRolesLoading,
    employees,
    shiftRoles,
  } = useCurrentLocationActiveEmployeesAndRoles(show, activeLocationShiftRoles)
  const {
    data: operatingHoursData,
    loading: operatingHoursLoading,
  } = useGetOperatingHoursData({
    startOn: first(days)!.start,
    endOn: last(days)!.start,
  })

  const employeeUnavailable = useIsEmployeeUnavailable({
    selectedShift: !!selectedShift ? selectedShift : null,
    locationId,
    employeeShifts: employeeShiftsForWeek,
  })

  const defaultShiftRoleId = shiftRoles[0]?.id ?? null

  const [employeeId, setEmployeeId] = useState(() =>
    selectedShift ? selectedShift.employee?.id : selectedEmployee?.id ?? null
  )
  const [startTimeInput, setStartTimeInput] = useState(() =>
    selectedShift ? getTimeFromDateTime(selectedShift.startAt) : null
  )
  const [endTimeInput, setEndTimeInput] = useState(() =>
    selectedShift ? getTimeFromDateTime(selectedShift.endAt) : null
  )
  const [roleSelectValue, setRoleSelectValue] = useState(
    () =>
      roleSelectInitialValue(
        selectedShift ?? null,
        selectedEmployee ?? null,
        selectedShiftRole ?? null
      ) ?? defaultShiftRoleId
  )
  const [isUnavailability, setIsUnavailability] = useState(
    () =>
      selectedShift?.isUnavailability ??
      isRowEntityUnavilability(selectedShiftRole ?? null) ??
      false
  )
  const [dateInput, setDateInput] = useState(
    selectedShift?.startAt ?? selectedDate
  )
  const [showAllEmployees, setShowAllEmployees] = useState(
    selectedShift ? employeeUnavailable : false
  )
  const [shiftBreaks, setShiftBreaks] = useState(() =>
    (selectedShift?.shiftBreaks || []).map((b: shiftBreak) => b.durationMins)
  )
  const [shiftNotes, setShiftNotes] = useState(
    selectedShift ? selectedShift.notes : null
  )

  // Initialize with an effect here since these can be modified both by the props changing
  // and by the controls on this component changing the state.
  useEffect(() => {
    setIsUnavailability(
      selectedShift?.isUnavailability ??
        isRowEntityUnavilability(selectedShiftRole ?? null) ??
        false
    )
  }, [selectedShift?.isUnavailability, selectedShiftRole])

  const onEmployeeChange = ({ target: { value } }: any) =>
    setEmployeeId(value === '' ? null : value)

  const onStartTimeChange = (value: Time | null) => {
    setStartTimeInput(value)
  }

  const onEndTimeChange = (value: Time | null) => {
    setEndTimeInput(value)
  }

  const onRoleSelectChange = ({ target: { value } }: any) => {
    setRoleSelectValue(value)
  }

  const onDateChange = ({ target: { value } }: any) =>
    setDateInput(value && DateTime.fromISO(value))

  if (!selectedShift) {
    const dayOperatingHours = operatingHoursData?.operatingHoursForDate?.find(
      ({ openAt }) => isSameDate(dateInput, openAt)
    )

    if (dayOperatingHours) {
      const startTime = getTimeFromDateTime(dayOperatingHours.openAt)
      if (!startTimeInput) {
        onStartTimeChange(startTime)
      }
    }
  }

  const { startTime, endTime } = getStartTimeEndTime(
    dateInput,
    startTimeInput,
    endTimeInput
  )

  const shiftRoleId = roleSelectValue

  const {
    mutateCreateShift,
    mutateUpdateShift,
    mutateDeleteShift,
    createShiftIsBusy,
    updateShiftIsBusy,
    deleteShiftIsBusy,
  } = useShiftMutations(
    locationId,
    startTime,
    endTime,
    employeeId,
    shiftRoleId,
    selectedShift ?? null,
    isUnavailability,
    shiftBreaks,
    shiftNotes
  )

  const isEditMode = !!selectedShift

  const onSubmitShiftHandler = async () => {
    const mutation = isEditMode ? mutateUpdateShift : mutateCreateShift
    try {
      await mutation()
      onHide()
    } catch {
      window.alert(
        t('schedule.createOrUpdateShiftError', {
          action: isEditMode ? 'update' : 'create',
        })
      )
    }
  }

  const submitButtonLabel = isEditMode
    ? t('schedule.shiftModal.updateButtonLabel')
    : t('schedule.shiftModal.addButtonLabel')
  const deleteButtonLabel = t('schedule.shiftModal.deleteButtonLabel')
  const title = isEditMode
    ? t('schedule.shiftModal.titleUpdate')
    : t('schedule.shiftModal.titleCreate')

  const isSubmitButtonDisabled = !isFormValid({
    startTimeInput,
    endTimeInput,
    roleSelectValue,
    dateInput,
  })

  const [
    isShowingDeleteConfirmationDialog,
    setIsShowingDeleteConfirmationDialog,
  ] = useState(false)

  const onDeleteClick = () => setIsShowingDeleteConfirmationDialog(true)

  const onHideDeleteConfirmationDialog = () =>
    setIsShowingDeleteConfirmationDialog(false)

  const onConfirmDelete = async () => {
    try {
      await mutateDeleteShift()
      onHideDeleteConfirmationDialog()
      onHide()
    } catch {
      window.alert(t('schedule.confirmDeleteShiftDialog.failedToDelete'))
    }
  }

  const employee = employees.find(e => e.id === employeeId) ?? null

  const hasPendingShiftSwap =
    selectedShift?.shiftSwapRequest?.state === 'swap_pending'

  const isBusy = activeEmployeesAndRolesLoading || operatingHoursLoading

  return (
    <>
      <Modal show={show && !isShowingDeleteConfirmationDialog} onHide={onHide}>
        <Modal.Header closeButton>
          <StyledModalTitle>{title}</StyledModalTitle>
        </Modal.Header>
        <Modal.Body>
          {isBusy ? (
            <Loading height="30rem" />
          ) : (
            <>
              {hasPendingShiftSwap && <ShiftSwapInProgressMessage />}
              <SchedulingForm
                roleSelectValue={roleSelectValue}
                onRoleSelectChange={onRoleSelectChange}
                roleSelectOptions={makeRoleSelectOptions(shiftRoles, t)}
                employeeId={employeeId}
                onEmployeeChange={onEmployeeChange}
                employees={employees}
                dateInput={dateInput}
                onDateChange={onDateChange}
                days={days}
                startTimeInput={startTimeInput}
                onStartTimeChange={onStartTimeChange}
                endTimeInput={endTimeInput}
                onEndTimeChange={onEndTimeChange}
                hideDateSelect={hideDateSelect}
                locationId={locationId}
                employeeShiftsForWeek={employeeShiftsForWeek}
                showAllEmployees={showAllEmployees}
                setShowAllEmployees={setShowAllEmployees}
                hasPendingShiftSwap={hasPendingShiftSwap}
                shiftBreaks={shiftBreaks}
                setShiftBreaks={setShiftBreaks}
                shiftNotes={shiftNotes}
                setShiftNotes={setShiftNotes}
              />
            </>
          )}
        </Modal.Body>
        <ModalFooter>
          <ShiftModalViolations
            locationId={locationId}
            startTime={startTime}
            endTime={endTime}
            employee={employee}
            shiftRoleId={shiftRoleId}
            shiftBreaks={shiftBreaks}
            roleSelectValue={roleSelectValue}
            dateInput={dateInput}
            selectedShift={selectedShift ?? null}
          />
          <ModalButtonWrapper>
            {isEditMode && (
              <ShiftEditButton
                data-testid="request-delete-shift"
                variant="secondary"
                size="lg"
                onClick={onDeleteClick}
                disabled={hasPendingShiftSwap || isBusy}
              >
                {deleteButtonLabel}
              </ShiftEditButton>
            )}
            <Button
              variant="blue"
              disabled={isSubmitButtonDisabled || hasPendingShiftSwap || isBusy}
              size="lg"
              onClick={onSubmitShiftHandler}
              data-testid="submit-button"
              waitOnPromise
            >
              <BusySpinner hidden={!createShiftIsBusy && !updateShiftIsBusy} />{' '}
              {submitButtonLabel}
            </Button>
          </ModalButtonWrapper>
        </ModalFooter>
      </Modal>
      <ConfirmDeleteModal
        show={isShowingDeleteConfirmationDialog}
        onHide={onHideDeleteConfirmationDialog}
        onConfirmDelete={onConfirmDelete}
        showSpinner={deleteShiftIsBusy}
      />
    </>
  )
}
