import React, { FC } from 'react'

import GrayChevron from 'assets/images/gray-chevron-up.svg'
import {
  ExpandRowButton,
  ExpandRowChevron,
} from 'pages/covid/dashboard/addressRow/styles'
interface Props {
  isExpanded: boolean
  onToggle: () => void
}

export const ExpandDashboardRowButton: FC<Props> = ({
  isExpanded,
  onToggle,
}) => (
  <ExpandRowButton onClick={onToggle}>
    <ExpandRowChevron
      isExpanded={isExpanded}
      src={GrayChevron}
      alt="expand/collapse row"
    />
  </ExpandRowButton>
)
