import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ExpandChevronIcon,
  PredictionTitleCell,
  ReviewPredictionTitle,
  StyledPredictionCell,
} from 'pages/plan/rows/styles'

import { PlanningTableRow } from 'pages/plan/styles'
import { Prediction } from 'pages/plan/Prediction'
import { PredictionsTotal } from 'pages/plan/rows/PredictionsTotal'
import { PredictionSubTitleWrapper } from './PredictionSubTitleWrapper'
import { usePlanPageContext } from '../planPageContext'
import { useCurrentLocationCategories } from 'queries/useCurrentLocation'
import { StyledDownChevron, StyledUpChevron } from '../scheduling/styles'

interface Props {
  isShowingSchedule: boolean
  disableEditPrediction: boolean
}

export const PredictionsRow: FC<Props> = ({
  isShowingSchedule,
  disableEditPrediction,
}) => {
  const { t } = useTranslation()
  const { plan } = usePlanPageContext()
  const { dailySalesAndLaborSummaries, weekSalesAndLaborSummary } = plan
  const { categories, categoryNames } = useCurrentLocationCategories()
  const [expandCategories, setExpandCategories] = useState(false)
  return (
    <PlanningTableRow>
      <PredictionTitleCell isShowingSchedule={isShowingSchedule}>
        <ReviewPredictionTitle>
          {t('planning.predictions')}
        </ReviewPredictionTitle>
        <div className={'d-print-none'} style={{ marginTop: 'auto' }}>
          <PredictionSubTitleWrapper
            categories={categoryNames}
            expand={expandCategories}
          />
          {categoryNames.length > 3 && (
            <ExpandChevronIcon
              onClick={() => setExpandCategories(!expandCategories)}
            >
              {expandCategories ? <StyledUpChevron /> : <StyledDownChevron />}
            </ExpandChevronIcon>
          )}
        </div>
        <div className={'d-none d-print-block mt-5 p-3'}>
          <PredictionSubTitleWrapper
            categories={categoryNames}
            expand={expandCategories}
          ></PredictionSubTitleWrapper>
        </div>
      </PredictionTitleCell>
      {dailySalesAndLaborSummaries.map(dailySalesAndLaborSummary => (
        <StyledPredictionCell
          key={dailySalesAndLaborSummary.id}
          isShowingSchedule={isShowingSchedule}
        >
          <Prediction
            key={dailySalesAndLaborSummary.id}
            salesAndLaborSummary={dailySalesAndLaborSummary}
            showCategories={true}
            categories={categoryNames.map(
              (category: string) => categories[category]
            )}
            expand={expandCategories}
            disableEdit={disableEditPrediction}
          />
        </StyledPredictionCell>
      ))}
      <PredictionsTotal
        weekSalesAndLaborSummary={weekSalesAndLaborSummary}
        showCategories={true}
        isShowingSchedule={isShowingSchedule}
        categories={categoryNames.map(
          (category: string) => categories[category]
        )}
        expand={expandCategories}
      />
    </PlanningTableRow>
  )
}
