import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const RedirectToMobileApp: FC = () => {
  const { screen_name } = useParams<{ screen_name: string }>()

  useEffect(() => {
    let deepLinkToScreen

    switch (screen_name) {
      case 'availability-screen':
        deepLinkToScreen = 'lineupai://availability_status_screen'
        break
      case 'time-off-request-screen':
        deepLinkToScreen = 'lineupai://time_off_request_screen'
        break
      case 'available-shifts-screen':
        deepLinkToScreen = 'lineupai://available_swap_shifts_screen'
        break
      case 'shift-swap-pending-screen':
        deepLinkToScreen = 'lineupai://pending_swap_shifts_screen'
        break
    }

    deepLinkToScreen && window.location.replace(deepLinkToScreen)
  }, [screen_name])

  return null
}

export default RedirectToMobileApp
