import React from 'react'

import {
  BannerWrapper,
  StatusIcon,
  BannerText,
} from 'pages/tracking/metrics/styles'

interface BannerProps {
  bgColor: string
  text: string
  icon: string
}

export const Banner: React.FC<BannerProps> = ({ bgColor, text, icon }) => (
  <BannerWrapper bgColor={bgColor}>
    <StatusIcon alt={text} src={icon} />
    <BannerText>{text}</BannerText>
  </BannerWrapper>
)
