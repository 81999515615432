import React from 'react'
import styled from 'styled-components'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { InfoTooltip } from 'components/InfoTooltip'
import { WeatherDisplayProps } from 'queries/useWeather'
import { MediumBodyBoldText } from 'components/Text'
import { WeatherIcon } from 'pages/tracking/metrics/styles'

const Weather = styled.div({
  color: 'var(--gray2)',
  maxWidth: 125,
  margin: '0px auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: '100%',
})

const IconTemp = styled(MediumBodyBoldText)<{ align: 'left' | 'center' }>(
  ({ align }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '1.375rem',
  })
)

type SummaryTextSize = 'medium' | 'regular'

interface Props {
  textSize?: SummaryTextSize
  weather: WeatherDisplayProps
  align?: 'left' | 'center'
}

export const WeatherDisplay: React.FC<Props> = ({
  weather: { summary, icon, temperature },
  align = 'left',
}) => (
  <Weather>
    <IconTemp align={align}>
      <OverlayTrigger
        placement="top"
        delay={{ show: 150, hide: 500 }}
        overlay={<InfoTooltip id={1}>{summary}</InfoTooltip>}
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 20],
              },
            },
          ],
        }}
      >
        <WeatherIcon src={icon} alt={summary} />
      </OverlayTrigger>
      {temperature}°
    </IconTemp>
  </Weather>
)
