import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { AffectedChannel, Reason, WillHappenAgain } from 'config/graphqlTypes'
import {
  Option,
  PopoverSelect,
  PopoverMultiSelect,
} from 'components/PopoverSelect'
import { DetailsContainer } from 'pages/tracking/insight/styles'
import { FormGroup, StringInputGroup } from 'components/Form'

const makeReasonText = (reasons: Reason[] | null, t: TFunction) =>
  reasons
    ? reasons.map(r => t(`tracking.insight.reasons.${r}`)).join(', ')
    : null

const makeReasonOptions = (t: TFunction) =>
  Object.values(Reason).map(value => ({
    text: t(`tracking.insight.reasons.${value}`),
    value,
  }))

const makeChannelAffectedText = (
  channelsAffected: AffectedChannel[] | null,
  t: TFunction
) =>
  channelsAffected
    ? channelsAffected
        .map(channel => t(`tracking.insight.channelsAffected.${channel}`))
        .join(', ')
    : null

const makeChannelAffectedOptions = (t: TFunction) =>
  Object.values(AffectedChannel).map(value => ({
    text: t(`tracking.insight.channelsAffected.${value}`),
    value,
  }))

const makeWillHappenAgainText = (
  willHappenAgain: WillHappenAgain | null,
  t: TFunction
) =>
  willHappenAgain
    ? t(`tracking.insight.willHappenAgain.${willHappenAgain}`)
    : null

const makeWillHappenAgainOptions = (
  t: TFunction
): Array<Option<WillHappenAgain>> =>
  Object.values(WillHappenAgain).map(value => ({
    text: t(`tracking.insight.willHappenAgain.${value}`),
    value,
  }))

interface Props {
  reasons: Reason[] | null
  onReasonsChange: (reasons: Reason[] | null) => void

  channelsAffected: AffectedChannel[] | null
  onChannelsAffectedChange: (channelsAffected: AffectedChannel[] | null) => void

  other: string | null
  onOtherChange: (other: string | null) => void

  willHappenAgain: WillHappenAgain | null
  onWillHappenAgainChange: (willHappenAgain: WillHappenAgain | null) => void
}

export const Details = ({
  reasons,
  onReasonsChange,
  channelsAffected,
  onChannelsAffectedChange,
  other,
  onOtherChange,
  willHappenAgain,
  onWillHappenAgainChange,
}: Props) => {
  const { t } = useTranslation()

  const reasonText = makeReasonText(reasons, t)
  const reasonOptions = makeReasonOptions(t)

  const channelAffectedText = makeChannelAffectedText(channelsAffected, t)
  const channelAffectedOptions = makeChannelAffectedOptions(t)

  const willHappenAgainText = makeWillHappenAgainText(willHappenAgain, t)
  const willHappenAgainOptions = makeWillHappenAgainOptions(t)

  const showOther = !!reasons?.includes(Reason.Other)

  return (
    <DetailsContainer>
      <FormGroup
        testId={t('tracking.insight.reasons.label')}
        labelProps={{
          label: t('tracking.insight.reasons.label'),
        }}
      >
        <PopoverMultiSelect
          text={reasonText}
          placeholder={t('tracking.insight.reasons.placeholder')}
          options={reasonOptions}
          selected={reasons ?? []}
          onChange={onReasonsChange}
        />
      </FormGroup>
      {showOther && (
        <StringInputGroup
          labelProps={{
            label: t('tracking.insight.other.label'),
          }}
          controlProps={{
            placeholder: t('tracking.insight.other.placeholder'),
            value: other ?? '',
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              onOtherChange(e.target.value)
            },
          }}
        />
      )}
      <FormGroup
        testId={t('tracking.insight.channelsAffected.label')}
        labelProps={{
          label: t('tracking.insight.channelsAffected.label'),
        }}
      >
        <PopoverMultiSelect
          text={channelAffectedText}
          placeholder={t('tracking.insight.channelsAffected.placeholder')}
          options={channelAffectedOptions}
          selected={channelsAffected ?? []}
          onChange={onChannelsAffectedChange}
        />
      </FormGroup>
      <FormGroup
        testId={t('tracking.insight.willHappenAgain.label')}
        labelProps={{
          label: t('tracking.insight.willHappenAgain.label'),
        }}
      >
        <PopoverSelect
          text={willHappenAgainText}
          placeholder={t('tracking.insight.willHappenAgain.placeholder')}
          options={willHappenAgainOptions}
          onSelect={onWillHappenAgainChange}
        />
      </FormGroup>
    </DetailsContainer>
  )
}
