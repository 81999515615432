import React from 'react'
import { DateTime, Interval, Duration } from 'luxon'

import { ScheduleVerticalLine } from 'pages/plan/dailyViewModal/schedule/styles'

interface Props {
  graphTimeline: DateTime[]
  // Currently only supports painting a line at every hour
  xAxisScale: 'every hour'
  getPercentFromLeft: (startAt: DateTime) => number
}

export const VerticalLines = ({ graphTimeline, getPercentFromLeft }: Props) => {
  const firstWindowPoint = graphTimeline[0]
  const lastWindowPoint = graphTimeline[graphTimeline.length - 1]

  const windowInterval = Interval.fromDateTimes(
    firstWindowPoint,
    lastWindowPoint
  )

  const percentFromLefts = windowInterval
    .splitBy(Duration.fromObject({ hours: 1 }))
    .map(interval => getPercentFromLeft(interval.start))

  const testId = 'shift-grid-line'

  return (
    <>
      {percentFromLefts.map(percentFromLeft => (
        <ScheduleVerticalLine
          key={percentFromLeft}
          data-testid={testId}
          left={percentFromLeft}
        />
      ))}
      <ScheduleVerticalLine left={100} data-testid={testId} />
    </>
  )
}
