import { ShiftFragment, ViolationFragment } from 'config/graphqlTypes'
import { find } from 'lodash'

const FALLBACK_WAGE_CENTS = 1200 as const
const OVERTIME_MULTIPLE = 1.5 as const

type MissingPayRateEmployee = { id: string; name: string }

export const calculateLaborPercentageDay = (
  dailyPredictedSales: number,
  overHoursWeekViolations: ViolationFragment[],
  shifts: ShiftFragment[],
  locationId: string
): {
  laborPercentage: number
  employeesMissingPayRate: MissingPayRateEmployee[]
  laborDollarsScheduled: number
} => {
  let laborDollarsScheduled = 0
  const employeesMissingPayRate: MissingPayRateEmployee[] = []

  shifts.forEach(shift => {
    if (
      shift.employee.employeeLocations?.find(el => el.locationId === locationId)
        ?.excludeFromLabor
    ) {
      return
    }

    const violation = find(overHoursWeekViolations, {
      shifts: [{ id: shift.id }],
    })
    // actual should be the same as shift.hours, limit is the number of hours
    // the employee is allowed to work before going into overtime hours.  The
    // difference is the number of overtime hours.
    const overtimeHours = violation ? violation.actual - violation.limit : 0

    const shiftRole = shift.employee.shiftRoles?.find(
      shiftRole => shiftRole.name === shift.shiftRole?.name
    )

    const employeePayRateCents = shift.employee.wage
      ? parseInt(shift.employee.wage)
      : null
    const employeeLocation = shift.employee.employeeLocations?.find(
      ({ locationId: employeeLocationId }) => locationId === employeeLocationId
    )

    const wageOverrideRateCents: number | null =
      parseInt(
        employeeLocation?.integrationSettings?.wage?.find(
          (wage: { job_id: string }) =>
            wage?.['job_id'] === shiftRole?.externalId
        )?.rate
      ) || null
    const shiftRolePayRateCents = parseInt(shiftRole?.payRate as any) || null

    if (
      !(employeePayRateCents || wageOverrideRateCents || shiftRolePayRateCents)
    ) {
      const existingEmpr = employeesMissingPayRate.find(
        empr => empr.id === shift.id
      )
      if (!existingEmpr) {
        employeesMissingPayRate.push({
          id: shift.id,
          name: shift.employee.name,
        })
      }
    }

    const payRate: number = shift.wage || FALLBACK_WAGE_CENTS

    const shiftHours = shift.hours || 0
    const nonOvertimeHours = shiftHours - overtimeHours

    laborDollarsScheduled +=
      payRate * (OVERTIME_MULTIPLE * overtimeHours + nonOvertimeHours)
  })

  const laborPercentage = dailyPredictedSales
    ? (laborDollarsScheduled / dailyPredictedSales) * 100.0
    : 0

  return {
    laborPercentage,
    employeesMissingPayRate,
    laborDollarsScheduled,
  }
}
