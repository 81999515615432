import { DateTime } from 'luxon'

import { useCurrentLocation } from 'queries/useCurrentLocation'
import {
  useGetWeeklyPlanSummariesWithDailySummariesQuery,
  GetWeeklyPlanSummariesWithDailySummariesQuery,
} from 'config/graphqlTypes'
import {
  makeDataLoadingState,
  wrapDataLoadingHelperThrowColdErrors,
} from 'utils/dataLoading'

const useGetWeeklyPlanSummariesWithDailySummariesDataLoadingWithColdError = (
  startOn: DateTime,
  endOn: DateTime
) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  return makeDataLoadingState<GetWeeklyPlanSummariesWithDailySummariesQuery>(
    useGetWeeklyPlanSummariesWithDailySummariesQuery({
      variables: {
        endOn,
        locationId,
        startOn,
      },
    })
  )
}

export const useGetWeeklyPlanWithDailySummariesDataLoading = wrapDataLoadingHelperThrowColdErrors(
  useGetWeeklyPlanSummariesWithDailySummariesDataLoadingWithColdError
)
