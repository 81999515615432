import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar'
import Dropdown from 'react-bootstrap/Dropdown'
import { NavLink } from 'react-router-dom'

import { sectionBorder, MOBILE_BREAKPOINT_WIDTH } from 'config/styles'
import { RegularBodyTextButton, TableLabelTextButton } from 'components/Text'

export const StyledNavbar = styled(Navbar)({
  borderBottom: sectionBorder,
  paddingTop: 6,
  paddingLeft: 40,
})

export const StyledCurrentLocationName = styled.span({
  fontSize: '1rem',
})

export const NavbarLink = styled(NavLink)({
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  marginRight: 28,
  fontWeight: 'normal',
  color: 'var(--gray1) !important',

  '&.active': {
    fontWeight: 'bold',
  },

  '&:hover': {
    textDecoration: 'underline',
  },

  '&:last-of-type': {
    marginRight: 24,
  },
})

export const Logo = styled.img({
  height: 30,
  marginRight: 20,
})

export const DropdownIcon = styled.img({
  width: 15,
  marginRight: 10,
})

export const DropdownButton = styled(RegularBodyTextButton)({
  color: 'var(--gray2)',
})

export const StyledDropdownButton = styled(DropdownButton)({
  '&::after': {
    marginLeft: '0.5em',
  },
})

export const StyledDropdown = styled(Dropdown)({
  marginLeft: '-0.125rem',
})

export const DropdownItemButton = styled(TableLabelTextButton)({
  color: 'var(--gray1)',
})

export const OrganizationName = styled.span({
  fontWeight: 700,
})

export const LeftContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

export const RightContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

export const Divider = styled.div({
  height: 24,
  backgroundColor: 'var(--gray5)',
  width: 2,
  margin: '0 22px 0 10px',
  display: 'none',
  [`@media only screen and (min-width: ${MOBILE_BREAKPOINT_WIDTH}px)`]: {
    display: 'block',
  },
})
