import React from 'react'
import {
  PendingRequestsCounterBadge,
  PendingRequestsCounterWrapper,
} from 'pages/management/styles'
import { useGetManagementRequestsCount } from 'pages/management/useGetManagementRequests'

export const AvailabilityRequestsCounter = () => {
  const count = useGetManagementRequestsCount()

  return (
    <>
      {count > 0 && (
        <div>
          <PendingRequestsCounterBadge
            data-testid="requests-counter-badge"
            pill
            className="badge bg-danger rounded-circle"
          >
            <PendingRequestsCounterWrapper>
              {count > 5 ? '5+' : count}
            </PendingRequestsCounterWrapper>
          </PendingRequestsCounterBadge>
        </div>
      )}
    </>
  )
}
