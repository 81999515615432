import React, { FC } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Trans } from 'react-i18next'

import { StyledDivContainer } from '../scheduling/styles'
import styled from 'styled-components'

import { AvatarDiv } from 'components/Avatar'

interface Props {
  id: string | undefined
  employeeName: string
}

export const LaborPercentagePopover: FC<Props> = ({ id, employeeName }) => {
  const popover = (props: any) => (
    <Popover {...props} id={id}>
      <Popover.Content>
        <Trans
          i18nKey="planning.laborPercentagePopover"
          values={{ employeeName }}
          components={[<strong></strong>]}
        />
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 100, hide: 0 }}
      overlay={popover}
    >
      <StyledDivContainer>
        <ViolationIcon size={12} fontSize="10px">
          !
        </ViolationIcon>
      </StyledDivContainer>
    </OverlayTrigger>
  )
}

const ViolationIcon = styled(AvatarDiv)({
  backgroundColor: 'var(--gray4)',
  color: 'var(--white)',
})
