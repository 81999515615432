import React, { Fragment } from 'react'
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit'
import { SettingsTableHeader } from '../SettingsTableHeader'
import DataTable from '../../../../components/DataTable/DataTable'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { DATA_TABLE_PAGE_SIZE_OPTIONS } from '../../../../components/DataTable/dataTableUtils'
import { Loading } from '../../../../components/Loading'
import {
  useGetUsersForOrganizationQuery,
  UserForOrganizationSettingsFragment,
} from '../../../../config/graphqlTypes'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface UserLocationNames {
  role: string
  locations: string[]
}

interface UserNameBlock {
  id: string
  name: string
  email: string
  initials: string
}

interface Props {
  organizationId: string
}

function UsersDataTable({ organizationId }: Props) {
  const { t } = useTranslation()

  const { loading, data } = useGetUsersForOrganizationQuery({
    variables: { organizationId },
  })

  const formattedUsers =
    data?.users.map((user: UserForOrganizationSettingsFragment) => {
      const { id, name, email, locationNames } = user
      return {
        id,
        nameBlock: {
          id,
          name,
          email,
          initials: name
            ?.match(/(^\S\S?|\b\S)?/g)
            ?.join('')
            ?.match(/(^\S|\S$)?/g)
            ?.join('')
            ?.toUpperCase(),
        },
        roles: locationNames.map(locationName => locationName.role),
        locationNames,
      }
    }) ?? []

  const userNamesFormatter = (nameBlock: UserNameBlock) => {
    return (
      <EditOrganizationNameContainer key={nameBlock.id}>
        <EditOrganizationNameBlock>
          {nameBlock.initials}
        </EditOrganizationNameBlock>
        <div>
          <div>{nameBlock.name}</div>
          <div>{nameBlock.email}</div>
        </div>
      </EditOrganizationNameContainer>
    )
  }

  const rolesFormatter = (roles: string[]) =>
    roles.map((role, index) => {
      return (
        <Fragment key={index}>
          <span>{role}</span>
          <br />
        </Fragment>
      )
    })

  const userLocationNamesFormatter = (locationNames: UserLocationNames[]) =>
    locationNames.map((locationName: UserLocationNames) =>
      locationName.locations.map((location, i) => {
        return (
          <Fragment key={`${i}_${location}`}>
            <span>{location}</span>
            <br />
          </Fragment>
        )
      })
    )

  const userColumns = [
    {
      dataField: 'nameBlock',
      text: t('management.settings.organizations.name'),
      headerStyle: () => {
        return { minWidth: 150, maxWidth: 150, width: '33%' }
      },
      formatter: userNamesFormatter,
      sort: true,
      sortValue: (cell: UserNameBlock) => cell.name,
      filterValue: (cell: UserNameBlock) => cell.name,
    },
    {
      dataField: 'roles',
      text: t('management.settings.organizations.edit.role'),
      headerStyle: () => {
        return { minWidth: 150, maxWidth: 150, width: '33%' }
      },
      formatter: rolesFormatter,
      sort: true,
      sortValue: (cell: string[]) => cell[0],
      filterValue: (cell: string[]) => cell[0],
    },
    {
      dataField: 'locationNames',
      text: t('management.settings.organizations.edit.locations'),
      headerStyle: () => {
        return { minWidth: 150, maxWidth: 150, width: '33%' }
      },
      formatter: userLocationNamesFormatter,
      sort: true,
      sortValue: (cell: UserLocationNames[]) => cell[0]?.locations[0],
      filterValue: (cell: UserLocationNames[]) => cell[0]?.locations[0],
    },
  ]

  if (loading) return <Loading />

  return (
    <ToolkitProvider
      keyField="id"
      data={formattedUsers}
      columns={userColumns}
      search
    >
      {(props: ToolkitContextType) => (
        <>
          <SettingsTableHeader searchProps={props.searchProps} />
          <DataTable
            {...props.baseProps}
            keyField="id"
            striped
            hover
            minHeight={'600px'}
            rowHeight={'10px'}
            filter={filterFactory()}
            pagination={paginationFactory({
              sizePerPageList: DATA_TABLE_PAGE_SIZE_OPTIONS,
            })}
          />
        </>
      )}
    </ToolkitProvider>
  )
}

const EditOrganizationNameBlock = styled.div`
  font-size: 1.75em;
  font-weight: bold;
  color: var(--blue);
  padding: 20px;
  margin-right: 20px;
  background-color: var(--gray7);
  border-radius: 50%;
`

const EditOrganizationNameContainer = styled.div`
  display: flex;
  align-items: center;
`

export default UsersDataTable
