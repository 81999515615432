import React, { useRef } from 'react'
import Select, {
  CSSObjectWithLabel,
  GroupBase,
  Props,
  Theme,
  components,
  OptionProps,
  MultiValueProps,
  ActionMeta,
  PropsValue,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
  ControlProps,
} from 'react-select'
import { some } from 'lodash'
import makeAnimated from 'react-select/animated'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export type SelectDropdownOption = {
  label: string
  value: string
}

export type SingleValueOption = SingleValue<SelectDropdownOption>

const StyledLabel = styled.label({
  paddingLeft: 6,
})

const CustomOption = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: OptionProps<Option, IsMulti, Group>
) => (
  <components.Option {...props}>
    <input type="checkbox" checked={props.isSelected} onChange={() => null} />
    <StyledLabel>{props.label}</StyledLabel>
  </components.Option>
)

const CustomMultiValue = <
  Option extends any,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: MultiValueProps<Option, IsMulti, Group>
) => {
  const { label } = props.data as OptionProps
  return (
    <components.MultiValue {...props}>
      <span>{label}</span>
    </components.MultiValue>
  )
}

const multiSelectComponents = makeAnimated({
  Option: CustomOption,
  MultiValue: CustomMultiValue,
})

const animatedComponentsSingleSelect = makeAnimated()

interface CustomProps {
  allowSelectAll?: boolean
  selectAllOptionLabel?: string
  selectAllOptionTagLabel?: string
  controlStyles?: CSSObjectWithLabel
  menuStyles?: CSSObjectWithLabel
  optionStyles?: CSSObjectWithLabel
  singleValueStyles?: CSSObjectWithLabel
  customTheme?: Theme
  hideIndicatorSeparator?: boolean
  hideClearIndicator?: boolean
}

function SelectDropdown<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  allowSelectAll,
  selectAllOptionLabel,
  selectAllOptionTagLabel,
  controlStyles,
  menuStyles,
  optionStyles,
  singleValueStyles,
  customTheme,
  hideIndicatorSeparator,
  hideClearIndicator,
  ...props
}: Props<Option, IsMulti, Group> & CustomProps) {
  const { value, options, isMulti } = props
  const { t } = useTranslation()

  const valueRef = useRef<Option | MultiValue<Option> | null | undefined>(value)
  valueRef.current = value

  const selectAllOptionTag = {
    value: '<SELECT_ALL>',
    label: selectAllOptionTagLabel ?? t('selectDropdown.selectAll'),
  }

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: selectAllOptionLabel ?? t('selectDropdown.selectAll'),
  }

  const isSelectAllSelected = () =>
    (valueRef.current as MultiValue<Option>)?.length === options?.length

  const isOptionSelected = (option: Option) =>
    some(valueRef.current as MultiValue<Option>, option!) ||
    isSelectAllSelected()

  const getOptions = () =>
    [selectAllOption, ...(options ?? [])] as OptionsOrGroups<Option, Group>

  const getValue = () =>
    (isSelectAllSelected() ? [selectAllOptionTag] : value) as MultiValue<Option>

  const onChange = (
    selectedOptions: PropsValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    const { action, option, removedValue } = actionMeta
    const options = ((props.options as unknown) as SelectDropdownOption[]) ?? []
    const actionOption = (option as unknown) as SelectDropdownOption
    const actionRemovedValue = (removedValue as unknown) as SelectDropdownOption
    const onChange = (onChangeOptions: any[]) =>
      // @ts-ignore
      props.onChange?.(onChangeOptions, actionMeta)

    if (
      action === 'select-option' &&
      actionOption?.value === selectAllOption.value
    ) {
      return onChange(options)
    } else if (
      (action === 'deselect-option' &&
        actionOption?.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        actionRemovedValue?.value === selectAllOption.value)
    ) {
      return onChange([])
    } else if (
      actionMeta.action === 'deselect-option' &&
      isSelectAllSelected()
    ) {
      const filteredOptions = options.filter(
        ({ value }) => value !== actionOption?.value
      )
      return onChange(filteredOptions)
    } else {
      return onChange((selectedOptions as Option[]) ?? [])
    }
  }

  const theme = (theme: Theme) => ({ ...theme, ...customTheme })

  const styles = {
    control: (
      baseStyles: CSSObjectWithLabel,
      state: ControlProps<Option, IsMulti, Group>
    ) => {
      const borderColor = controlStyles?.borderColor || baseStyles.borderColor
      const boxShadow = controlStyles?.boxShadow || baseStyles.boxShadow
      const border =
        controlStyles?.border === 'none' ? boxShadow : 'var(--gray5)'

      return controlStyles
        ? {
            ...baseStyles,
            ...controlStyles,
            border: state.isFocused
              ? `1px solid ${borderColor}`
              : `1px solid ${border}`,
            boxShadow: state.isFocused ? boxShadow : border,
            '&:hover': {
              border: `1px solid ${state.isFocused ? borderColor : border}`,
            },
          }
        : {
            ...baseStyles,
          }
    },
    menu: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      zIndex: 999,
      ...menuStyles,
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: OptionProps<Option, boolean, Group>
    ) => ({
      ...baseStyles,
      backgroundColor: state.isFocused
        ? 'var(--gray-light)'
        : state.isSelected
        ? 'none'
        : baseStyles.backgroundColor,
      '&:active': {
        backgroundColor: 'var(--gray-light)',
      },
      color: state.isSelected ? 'none' : baseStyles.color,
      cursor: state.isFocused ? 'pointer' : baseStyles.cursor,
      ...optionStyles,
    }),
    singleValue: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      ...singleValueStyles,
    }),
  }

  const componentsSingleSelect = hideIndicatorSeparator
    ? {
        ...animatedComponentsSingleSelect,
        IndicatorSeparator: () => null,
      }
    : animatedComponentsSingleSelect

  const singleSelectComponents = hideClearIndicator
    ? {
        ...componentsSingleSelect,
        ClearIndicator: () => null,
      }
    : componentsSingleSelect

  return allowSelectAll && isMulti ? (
    <Select
      {...props}
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      components={multiSelectComponents}
      onChange={onChange}
      styles={styles}
      theme={theme}
    />
  ) : (
    <Select
      {...props}
      components={singleSelectComponents}
      styles={styles}
      theme={theme}
    />
  )
}

export default SelectDropdown
