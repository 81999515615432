import React, { ReactNode, FC } from 'react'
import { Trans } from 'react-i18next'

import { EM_DASH } from 'utils/formattedNumberWithFallback'
import {
  StyledReportingDataCell,
  StyledDataLabel,
  StyledBlueText,
  StyledDash,
} from 'pages/reporting/styles'

interface Props {
  labelTranslationKey?: string
  className?: string
  showEmpty?: boolean
  additionalChildren?: ReactNode
  children?: ReactNode
}

export const ReportingDataCell: FC<Props> = ({
  labelTranslationKey,
  className,
  children,
  showEmpty,
  additionalChildren,
}) => (
  <StyledReportingDataCell className={className}>
    <StyledDataLabel>
      {labelTranslationKey && (
        <Trans i18nKey={labelTranslationKey}>
          <StyledBlueText />
        </Trans>
      )}
    </StyledDataLabel>
    {showEmpty ? <StyledDash>{EM_DASH}</StyledDash> : children}
    {additionalChildren}
  </StyledReportingDataCell>
)
