import React, { ReactNode, useState } from 'react'
import {
  CustomLayer,
  PointTooltip,
  ResponsiveLine,
  Serie,
  DatumValue,
  PointTooltipProps,
  Point,
} from '@nivo/line'
import { TimeScale, Scale } from '@nivo/scales'
import { AxisProps, GridValues } from '@nivo/axes'

import AxisLabel from 'components/Chart/AxisLabel'
import { LineLayer } from 'components/Chart/LineLayer'
import { Points } from 'components/Chart/Points'
import { StaticLineLayer } from 'components/Chart/StaticLineLayer'

export interface ChartYAxisConfig {
  axisLeft: AxisProps | null
  yScale?: Scale
  gridYValues?: GridValues<DatumValue>
}

interface ChartProps {
  animate?: boolean
  data: Serie[]
  layers: ReactNode[]
  margin: {
    bottom: number
    left: number
    right: number
    top: number
  }
  tooltip: PointTooltip
  xAxisFormat: string
  width: number
  xAxisScale: 'every day' | 'every hour'
  precision: TimeScale['precision']
  stepSize: number
  getXAxisLabel: (formattedTimestamp: string) => string
  yAxisConfig?: ChartYAxisConfig
  highlightMostRecentActual: boolean
  overridePointColor?: (point: Point) => string | undefined
}

const Chart: React.FC<ChartProps> = ({
  animate = true,
  data,
  layers,
  margin,
  xAxisFormat,
  xAxisScale,
  precision,
  stepSize,
  getXAxisLabel,
  highlightMostRecentActual,
  overridePointColor,
  width,
  tooltip: Tooltip,
  yAxisConfig = { axisLeft: null },
}) => {
  // Triggering tooltips in E2E tests is hard because of the way the graph
  // library works. Therefore, for E2E tests, manually render the tooltip when
  // hovering over a chart dot.
  const [pointData, setPointDataState] = useState<PointTooltipProps | null>(
    null
  )
  const setPointData =
    process.env.REACT_APP_IS_FOR_E2E_TEST === 'true'
      ? setPointDataState
      : () => {}

  return (
    <>
      {!data.length || width === 0 ? null : (
        <ResponsiveLine
          {...yAxisConfig}
          animate={animate}
          axisBottom={{
            format: xAxisFormat,
            tickPadding: 10,
            tickSize: 0,
            tickValues: xAxisScale,
            renderTick: (tickData: any) =>
              tickData.tickIndex % stepSize !== 0 || (
                <AxisLabel
                  color="gray3"
                  position={{ x: tickData.x, y: tickData.y }}
                  textPosition={{ x: tickData.textX, y: tickData.textY }}
                  value={getXAxisLabel(tickData.format(tickData.value))}
                  isXAxis
                />
              ),
          }}
          data={data}
          colors={data => data.color}
          curve="monotoneX"
          layers={[
            ...((layers as unknown) as CustomLayer[]),
            animate ? LineLayer : StaticLineLayer,
            Points(highlightMostRecentActual, setPointData, overridePointColor),
            'axes',
            'mesh',
          ]}
          lineWidth={3}
          margin={margin}
          tooltip={Tooltip}
          useMesh
          xScale={{
            type: 'time',
            format: xAxisFormat,
            precision: precision,
            useUTC: false,
          }}
        />
      )}
      {pointData && <Tooltip {...pointData} />}
    </>
  )
}

export default Chart
