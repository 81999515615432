import React, { FC } from 'react'
import {
  StyledThead,
  StyledTableRow,
  StyledTableHeadCell,
  StyledTableHeadTotalsCell,
} from 'pages/reporting/styles'
import { useTranslation } from 'react-i18next'
import { Interval } from 'luxon'
import { formatDayOfWeek } from 'utils/daysAndTimes'

interface Props {
  days: Interval[]
}

export const TableHead: FC<Props> = ({ days }) => {
  const { t } = useTranslation()

  return (
    <StyledThead>
      <StyledTableRow>
        <StyledTableHeadCell />
        <StyledTableHeadCell />
        {days.map(day => (
          <StyledTableHeadCell key={day.start.toISO()}>
            {formatDayOfWeek(day.start)}
          </StyledTableHeadCell>
        ))}
        <StyledTableHeadTotalsCell>
          {t('reporting.total')}
        </StyledTableHeadTotalsCell>
      </StyledTableRow>
    </StyledThead>
  )
}
