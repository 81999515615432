import React, { useState } from 'react'
import { Interval, DateTime } from 'luxon'
import {
  EmployeeShiftsFragment,
  GetLocationForScheduleQuery,
  useGetLocationShiftRolesActiveQuery,
} from 'config/graphqlTypes'
import { PredictionsRow } from 'pages/plan/rows/PredictionsRow'
import { BenchmarksRow } from 'pages/plan/rows/BenchmarksRow'
import { ExistingOrdersRow } from 'pages/plan/rows/ExistingOrdersRow'
import { ExternalEventsRow } from 'pages/plan/rows/ExternalEventsRow'
import { WeatherRow } from 'pages/plan/rows/WeatherRow'
import {
  PrintingStyle,
  HorizontalScrollableOnLargeScreensContainer,
  StyledTfoot,
  StyledRow,
  StyledTable,
  StyledVerticallyScrollableContainer,
  PrintAllContent,
  UnprintablePlanningTableRow,
} from 'pages/plan/styles'
import { Tbody } from 'pages/plan/Tbody'
import ScheduleRows from 'pages/plan/scheduling/ScheduleRows'
import { BlankScheduleRow } from 'pages/plan/scheduling/BlankScheduleRow'
import { FooterRow } from './rows/FooterRow'
import { useGetViolationsPlan } from '../../queries/useGetViolationsPlan'
import { usePlanPageContext } from './planPageContext'
import {
  ActiveLocationShiftRoleFragment,
  ReservationsImporter,
} from 'config/graphqlTypesRaw'
import { InternalEventsRow } from './rows/InternalEventsRow'
import { ReservationsRow } from './rows/ReservationsRow'
import { CollapsableCategoryName } from './CollapsableCategoryName'
import { useTranslation } from 'react-i18next'
import { StyledCategoryRowCell } from './scheduling/styles'

interface Props {
  startDate: DateTime
  endDate: DateTime
  days: Interval[]
  isShowingSchedule: boolean
  hideSchedule: () => void
  location: GetLocationForScheduleQuery['location']
  employeeShiftsForWeek: EmployeeShiftsFragment[]
  disableEditPrediction: boolean
}

export const Table = ({
  startDate,
  endDate,
  days,
  isShowingSchedule,
  hideSchedule,
  location,
  employeeShiftsForWeek,
  disableEditPrediction,
}: Props) => {
  const {
    plan,
    isScheduleByRole,
    locationClosedDaysIndexes,
  } = usePlanPageContext()
  const { id, databaseId, startOn, endOn } = plan

  const { data: violations } = useGetViolationsPlan(id ?? databaseId)
  const activeLocationShiftRoles = useGetLocationShiftRolesActiveQuery({
    variables: {
      locationId: location.id,
    },
  })?.data?.locationShiftRoles as ActiveLocationShiftRoleFragment[]

  const [showDailyViewIndex, setShowDailyViewIndex] = useState<number | null>(
    null
  )

  const [collapsed, setCollapsed] = useState(false)
  const toggleCollapsed = () => setCollapsed(!collapsed)
  const { t } = useTranslation()

  const numTableRows = isShowingSchedule
    ? isScheduleByRole
      ? (location.shiftRoles.length ?? 0) + 4
      : (location.employees.length ?? 0) + 3
    : 7

  const day = showDailyViewIndex === null ? null : days[showDailyViewIndex]

  const dailyViewOnPrev =
    showDailyViewIndex === 0
      ? undefined
      : () => {
          if (showDailyViewIndex !== null) {
            setShowDailyViewIndex(showDailyViewIndex - 1)
          }
        }

  // This will return one less than you'd expect because it's not inclusive of the end days.
  // e.g. doing a diff of Mon - Sun gives 6 days
  const planDays = endOn.diff(startOn, 'days').days

  const dailyViewOnNext =
    showDailyViewIndex === planDays
      ? undefined
      : () => {
          if (showDailyViewIndex !== null) {
            setShowDailyViewIndex(showDailyViewIndex + 1)
          }
        }

  const tripleSeatEnabled: boolean = location.tripleseatEnabled
  const reservationsEnabled: boolean =
    location.reservationsImporterType !== ReservationsImporter.NoImporter

  const eventsReservationsCollapsbleCategoryName: string = reservationsEnabled
    ? t('planning.eventsAndReservations')
    : t('planning.reservations.event')

  return (
    <>
      <StyledRow noGutters>
        <PrintingStyle />
        <HorizontalScrollableOnLargeScreensContainer>
          <StyledVerticallyScrollableContainer disabled={true}>
            <StyledTable numTableRows={numTableRows}>
              <Tbody>
                <PredictionsRow
                  isShowingSchedule={isShowingSchedule}
                  disableEditPrediction={disableEditPrediction}
                />
                <BenchmarksRow />
                <ExistingOrdersRow
                  startDate={startDate}
                  endDate={endDate}
                  days={days}
                  salesType={location.salesType}
                />
                <WeatherRow
                  days={days}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Tbody>
            </StyledTable>
            <table>
              <tbody>
                <UnprintablePlanningTableRow
                  onClick={toggleCollapsed}
                  nthChildren={locationClosedDaysIndexes}
                >
                  <StyledCategoryRowCell>
                    <CollapsableCategoryName
                      name={eventsReservationsCollapsbleCategoryName}
                      collapsed={collapsed}
                    />
                  </StyledCategoryRowCell>
                </UnprintablePlanningTableRow>
              </tbody>
            </table>
            <StyledTable numTableRows={numTableRows}>
              <Tbody>
                {!collapsed && (
                  <>
                    <ExternalEventsRow
                      days={days}
                      endDate={endDate}
                      startDate={startDate}
                    />
                    {tripleSeatEnabled && (
                      <InternalEventsRow
                        days={days}
                        endDate={endDate}
                        startDate={startDate}
                      />
                    )}
                    {reservationsEnabled && (
                      <ReservationsRow
                        days={days}
                        endDate={endDate}
                        startDate={startDate}
                      />
                    )}
                  </>
                )}
              </Tbody>
            </StyledTable>
            <StyledTable numTableRows={numTableRows}>
              <Tbody>
                <ScheduleRows
                  days={days}
                  day={day}
                  isShowingSchedule={isShowingSchedule}
                  startDate={startDate}
                  violations={violations}
                  employeeShiftsForWeek={employeeShiftsForWeek}
                  hideSchedule={hideSchedule}
                  location={location}
                  dailyViewOnPrev={dailyViewOnPrev}
                  dailyViewOnNext={dailyViewOnNext}
                  setShowDailyViewIndex={setShowDailyViewIndex}
                  disableEditPrediction={disableEditPrediction}
                  activeLocationShiftRoles={activeLocationShiftRoles}
                />
                <BlankScheduleRow
                  numTableRows={numTableRows}
                  isShowingSchedule={isShowingSchedule}
                />
              </Tbody>
              <StyledTfoot>
                <FooterRow
                  days={days}
                  violations={violations}
                  onClickCell={setShowDailyViewIndex}
                  locationId={location.id}
                />
              </StyledTfoot>
              <tbody>
                <PrintAllContent isScheduleByRole={isScheduleByRole} />
              </tbody>
            </StyledTable>
          </StyledVerticallyScrollableContainer>
        </HorizontalScrollableOnLargeScreensContainer>
      </StyledRow>
    </>
  )
}
