import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** Represents untyped JSON */
  JSON: any;
  /** A valid Time Zone, transported as a TZ Database string */
  TimeZone: string;
};

export enum AffectedChannel {
  DineIn = 'dine_in',
  Online = 'online',
  Catering = 'catering'
}

/** Autogenerated input type of ApproveManagementRequestMutation */
export type ApproveManagementRequestMutationInput = {
  requestId: Scalars['ID'];
  requestType: Scalars['String'];
  approved: Scalars['Boolean'];
  managerNote: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ApproveManagementRequestMutation */
export type ApproveManagementRequestMutationPayload = {
  __typename?: 'ApproveManagementRequestMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CancelTimeOffRequestMutation */
export type CancelTimeOffRequestMutationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of CancelTimeOffRequestMutation */
export type CancelTimeOffRequestMutationPayload = {
  __typename?: 'CancelTimeOffRequestMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ClearPlanShiftsMutation */
export type ClearPlanShiftsMutationInput = {
  planId: Scalars['ID'];
  days: Array<Scalars['ISO8601Date']>;
  clearAll: Scalars['Boolean'];
};

/** Autogenerated return type of ClearPlanShiftsMutation */
export type ClearPlanShiftsMutationPayload = {
  __typename?: 'ClearPlanShiftsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CopyLastWeekShiftsMutation */
export type CopyLastWeekShiftsMutationInput = {
  locationId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};

/** Autogenerated input type of CopyShiftsFromPlanTemplateMutation */
export type CopyShiftsFromPlanTemplateMutationInput = {
  locationId: Scalars['ID'];
  planTemplateId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
};

/** A covid dashboard submissioin */
export type CovidDashboard = {
  __typename?: 'CovidDashboard';
  /** The saved addresses */
  addresses: Array<Scalars['String']>;
  /** The user's email */
  email: Scalars['String'];
  /** The unique dashboard id */
  id: Scalars['ID'];
  /** The dashboard's name */
  name: Scalars['String'];
  /** The dashboard's URL */
  url: Scalars['String'];
};

/** Autogenerated input type of CreateAutoScheduleMutation */
export type CreateAutoScheduleMutationInput = {
  locationId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};

/** Autogenerated input type of CreateCovidDashboardMutation */
export type CreateCovidDashboardMutationInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  addresses: Array<Scalars['String']>;
};

/** Autogenerated input type of CreateEmployeeMutation */
export type CreateEmployeeMutationInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  wage: Maybe<Scalars['String']>;
  employeeLocations: Array<Scalars['JSON']>;
};

/** Autogenerated return type of CreateEmployeeMutation */
export type CreateEmployeeMutationPayload = {
  __typename?: 'CreateEmployeeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  employee: Maybe<Employee>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateMassNotificationMutation */
export type CreateMassNotificationMutationInput = {
  locationId: Scalars['ID'];
  messageText: Scalars['String'];
  shiftRoleCategoryIds: Maybe<Array<Scalars['Int']>>;
  shiftRoleIds: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of CreateMassNotificationMutation */
export type CreateMassNotificationMutationPayload = {
  __typename?: 'CreateMassNotificationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  massNotification: Maybe<MassNotification>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreatePlanTemplateMutation */
export type CreatePlanTemplateMutationInput = {
  name: Scalars['String'];
  startOn: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
};

/** Autogenerated return type of CreatePlanTemplateMutation */
export type CreatePlanTemplateMutationPayload = {
  __typename?: 'CreatePlanTemplateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  planTemplate: Maybe<PlanTemplate>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateShiftMutation */
export type CreateShiftMutationInput = {
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  isUnavailability: Scalars['Boolean'];
  shiftBreaks: Array<Scalars['Int']>;
  employeeId: Maybe<Scalars['ID']>;
  shiftRoleId: Maybe<Scalars['ID']>;
  notes: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateShiftSwapRequestMutation */
export type CreateShiftSwapRequestMutationInput = {
  shiftId: Scalars['ID'];
  employeeNote: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateShiftSwapRequestMutation */
export type CreateShiftSwapRequestMutationPayload = {
  __typename?: 'CreateShiftSwapRequestMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  shiftSwapRequest: Maybe<ShiftSwapRequest>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateWeeklyPlanExportMutation */
export type CreateWeeklyPlanExportMutationInput = {
  planId: Scalars['ID'];
  shiftRoles: Maybe<Array<Scalars['ID']>>;
  shiftRoleCategories: Maybe<Array<Scalars['ID']>>;
  employees: Maybe<Array<Scalars['ID']>>;
  type: Maybe<Scalars['String']>;
  orientation: Maybe<Scalars['String']>;
  pageSize: Maybe<Scalars['String']>;
  exportType: Maybe<Scalars['String']>;
  contentOptions: Maybe<PdfContentOptions>;
};

/** Autogenerated return type of CreateWeeklyPlanExportMutation */
export type CreateWeeklyPlanExportMutationPayload = {
  __typename?: 'CreateWeeklyPlanExportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

/** A Day's sales benchmark */
export type DailySalesBenchmark = {
  __typename?: 'DailySalesBenchmark';
  /** The day of the week */
  date: Scalars['ISO8601Date'];
  /** The sales benchmark */
  reportedSales: Scalars['Int'];
};

/** A Day's availability for an employee */
export type DaysAvailable = Record & {
  __typename?: 'DaysAvailable';
  createdAt: Scalars['ISO8601DateTime'];
  /** The day of the week */
  dayOfWeek: Scalars['String'];
  /** The unique, employee availability id */
  employeeAvailabilityId: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** Time slots for a given day */
  timeSlots: Maybe<Array<TimeSlot>>;
  /** Unavailable for the day */
  unavailable: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of DeleteEmployeeLocationsMutation */
export type DeleteEmployeeLocationsMutationInput = {
  employeeId: Scalars['ID'];
  locationIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteEmployeeLocationsMutation */
export type DeleteEmployeeLocationsMutationPayload = {
  __typename?: 'DeleteEmployeeLocationsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  employeeLocations: Maybe<Array<EmployeeLocation>>;
  errors: Array<Scalars['String']>;
  success: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of DeleteForecastMutation */
export type DeleteForecastMutationInput = {
  locationId: Scalars['ID'];
  date: Scalars['ISO8601Date'];
};

/** Autogenerated return type of DeleteForecastMutation */
export type DeleteForecastMutationPayload = {
  __typename?: 'DeleteForecastMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of DeleteSalesDayPartsMutation */
export type DeleteSalesDayPartsMutationInput = {
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteSalesDayPartsMutation */
export type DeleteSalesDayPartsMutationPayload = {
  __typename?: 'DeleteSalesDayPartsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteShiftMutation */
export type DeleteShiftMutationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteShiftMutation */
export type DeleteShiftMutationPayload = {
  __typename?: 'DeleteShiftMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteShiftSwapRequestMutation */
export type DeleteShiftSwapRequestMutationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteShiftSwapRequestMutation */
export type DeleteShiftSwapRequestMutationPayload = {
  __typename?: 'DeleteShiftSwapRequestMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteTimeOffBlockedDateMutation */
export type DeleteTimeOffBlockedDateMutationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteTimeOffBlockedDateMutation */
export type DeleteTimeOffBlockedDateMutationPayload = {
  __typename?: 'DeleteTimeOffBlockedDateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** An employee */
export type Employee = Record & {
  __typename?: 'Employee';
  /** The availability of an employee */
  availability: Maybe<EmployeeAvailability>;
  /** Mobile Notifications for availability requests */
  availabilityRequestNotifications: Maybe<Scalars['String']>;
  /** The employee's avatar url */
  avatarUrl: Maybe<Scalars['String']>;
  /** Employee confirmed min/max hours submitted at */
  confirmedHoursSubmittedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Employee confirmed min/max hours submitted by */
  confirmedHoursSubmittedBy: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  /** Employee email */
  email: Scalars['String'];
  /** The employee location */
  employeeLocations: Maybe<Array<EmployeeLocation>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The selected language for the mobile app */
  language: Maybe<Scalars['String']>;
  /** The associated locations for an employee */
  locations: Array<Location>;
  /** Employee maximum hours allowed to work */
  maxHours: Maybe<Scalars['Float']>;
  /** Employee minimum hours allowed to work */
  minHours: Maybe<Scalars['Float']>;
  /** Employee name */
  name: Scalars['String'];
  /** Employee E.164 phone */
  phone: Maybe<Scalars['String']>;
  /** Mobile Notifications for schedule changes */
  scheduleNotifications: Maybe<Scalars['String']>;
  /** The associated shift role */
  shiftRoles: Maybe<Array<ShiftRole>>;
  /** Mobile Notifications for shift swap requests */
  shiftSwapNotifications: Maybe<Scalars['String']>;
  /** Employee shifts */
  shifts: Maybe<Array<Shift>>;
  /** The associated time off requests */
  timeOffRequests: Array<TimeOffRequest>;
  /** Mobile App Timezone */
  timeZone: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  /** Employee wage (this overrides any wage data coming from the POS) */
  wage: Maybe<Scalars['String']>;
};


/** An employee */
export type EmployeeTimeOffRequestsArgs = {
  currentTimeOffRequests: Maybe<Scalars['Boolean']>;
};

/** An employee availability */
export type EmployeeAvailability = Record & {
  __typename?: 'EmployeeAvailability';
  createdAt: Scalars['ISO8601DateTime'];
  daysAvailable: Maybe<Array<DaysAvailable>>;
  employee: Employee;
  /** The unique, employee id */
  employeeId: Scalars['String'];
  /** The note the employee can leave for the manager */
  employeeNote: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The note the manager can leave for the employee */
  managerNote: Maybe<Scalars['String']>;
  /** The organization id */
  organizationId: Scalars['String'];
  /** Pending availability */
  pendingDaysAvailable: Maybe<Array<Scalars['JSON']>>;
  /** The time the request was reviewed */
  reviewedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** The availability state (submitted, accepted, changes_requested */
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  /** The GM who reviewed the request */
  user: Maybe<User>;
};

/** An Employee Location */
export type EmployeeLocation = Record & {
  __typename?: 'EmployeeLocation';
  /** Employee Location active */
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  /** Employee Location discarded at */
  discardedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** The employee */
  employee: Employee;
  /** The unique, employee id */
  employeeId: Scalars['String'];
  /** Employee Location exclude from labor */
  excludeFromLabor: Scalars['Boolean'];
  /** The external employee id */
  externalId: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** Employee Integration Settings */
  integrationSettings: Scalars['JSON'];
  /** The location id */
  locationId: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of EmployeeLoginMutation */
export type EmployeeLoginMutationInput = {
  /** The code sent to the email/phone number */
  otp: Scalars['String'];
  /** The contact address */
  contactAddress: Scalars['String'];
};

/** Autogenerated return type of EmployeeLoginMutation */
export type EmployeeLoginMutationPayload = {
  __typename?: 'EmployeeLoginMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  employee: Maybe<Employee>;
  errorCode: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of EmployeeSendCodeMutation */
export type EmployeeSendCodeMutationInput = {
  /** The user email/phone number */
  contact: Scalars['String'];
  /** The contact type */
  type: Scalars['String'];
};

/** Autogenerated return type of EmployeeSendCodeMutation */
export type EmployeeSendCodeMutationPayload = {
  __typename?: 'EmployeeSendCodeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errorCode: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Feature */
export type Feature = {
  __typename?: 'Feature';
  /** Status of the feature */
  active: Scalars['Boolean'];
  /** Name of the feature */
  name: Scalars['String'];
};

/** Features */
export type Features = {
  __typename?: 'Features';
  data: Array<Feature>;
  shiftSwap: Maybe<Scalars['Boolean']>;
};

/** A forecast */
export type Forecast = Record & {
  __typename?: 'Forecast';
  createdAt: Scalars['ISO8601DateTime'];
  /** The date for the forecast */
  date: Scalars['ISO8601Date'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The forecasted hours of labor for the date */
  laborHours: Scalars['Float'];
  /** The total sales forecasted for the date */
  totalSales: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ForecastedItemsValueBy {
  Dollars = 'dollars',
  Items = 'items'
}



/** An import event */
export type ImportEvent = {
  __typename?: 'ImportEvent';
  /** The import event timestamp */
  completedAt: Scalars['ISO8601DateTime'];
  /** The ID for the Import Event */
  id: Scalars['ID'];
};

/** Insight information for a location on a specific date */
export type Insight = Record & {
  __typename?: 'Insight';
  affectedChannels: Maybe<Array<AffectedChannel>>;
  createdAt: Scalars['ISO8601DateTime'];
  date: Scalars['ISO8601Date'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** Location that the insight is for */
  location: Location;
  otherReasons: Maybe<Scalars['String']>;
  reasons: Maybe<Array<Reason>>;
  updatedAt: Scalars['ISO8601DateTime'];
  wasUnusual: Scalars['Boolean'];
  willHappenAgain: Maybe<WillHappenAgain>;
};

/** An internal event */
export type InternalEvent = Record & {
  __typename?: 'InternalEvent';
  createdAt: Scalars['ISO8601DateTime'];
  /** The event end time with teardown time */
  endAtTimeWithTeardownTime: Scalars['String'];
  /** The event end time formatted */
  eventEndTime: Scalars['String'];
  /** The event starting date */
  eventStartDate: Scalars['ISO8601Date'];
  /** The event starting time formatted */
  eventStartTime: Scalars['String'];
  /** The unique id */
  externalId: Scalars['ID'];
  /** The unique id */
  externalLocationId: Scalars['ID'];
  /** The event food and beverage minimum as dollars */
  foodAndBeverageMinDollars: Scalars['String'];
  /** The event guest count */
  guestCount: Scalars['Int'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The rooms at a location where an event is held */
  rooms: Scalars['String'];
  /** The event start time with setup time */
  startAtTimeWithSetupTime: Scalars['String'];
  /** The event status */
  status: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Aggregated order data for a given time interval */
export type IntervalOrderSum = {
  __typename?: 'IntervalOrderSum';
  /** The category of the orders */
  category: Scalars['String'];
  /** The end time of the interval of the order data */
  endAt: Scalars['ISO8601DateTime'];
  /** The sum of net sales(cents) for requested-but-unfulfilled orders */
  futureReportedSales: Maybe<Scalars['Int']>;
  /** The id of the aggregated order data */
  id: Scalars['ID'];
  /** The sum of order net sales in cents */
  reportedSales: Maybe<Scalars['Int']>;
  /** The start time of the interval of the order data */
  startAt: Scalars['ISO8601DateTime'];
};

/** A sales prediction for a given time interval */
export type IntervalPredictionSum = {
  __typename?: 'IntervalPredictionSum';
  /** The category of the orders */
  category: Scalars['String'];
  /** Date when the prediction was generated */
  createdOn: Maybe<Scalars['ISO8601Date']>;
  /** The end time of the interval of the prediction */
  endAt: Scalars['ISO8601DateTime'];
  /** The id of the prediction */
  id: Scalars['ID'];
  /** The predicted labor hours required */
  laborHours: Scalars['Float'];
  /** The name of the model used to generate the prediction */
  modelName: Maybe<Scalars['String']>;
  /** The number of orders in the interval */
  orderCount: Maybe<Scalars['Float']>;
  /** The predicted net sales in cents */
  reportedSales: Maybe<Scalars['Int']>;
  /** The start time of the interval of the prediction */
  startAt: Scalars['ISO8601DateTime'];
};

/** Item forecast on a given day */
export type ItemForecastDay = {
  __typename?: 'ItemForecastDay';
  category: Scalars['String'];
  date: Scalars['ISO8601Date'];
  name: Scalars['String'];
  value: Scalars['Float'];
};

/** Item Level Forecast data */
export type ItemsForecast = {
  __typename?: 'ItemsForecast';
  items: Array<ItemForecastDay>;
  /** When the forecast was last updated */
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  valueBy: ForecastedItemsValueBy;
};


/** Available labor calculators */
export enum LaborCalculation {
  Default = 'default',
  Roti = 'roti',
  MeltShop = 'melt_shop',
  ArmadilloWillys = 'armadillo_willys',
  Nations = 'nations'
}

/** Labor Dollars */
export type LaborDollars = {
  __typename?: 'LaborDollars';
  /** Labor Dollars computed per day */
  daily: Array<LaborDollarsDaily>;
  /** Computed Labor Dollars value in cents */
  totalCents: Scalars['Int'];
};

/** Labor Dollars per day */
export type LaborDollarsDaily = {
  __typename?: 'LaborDollarsDaily';
  /** Computend Labor Dollars value in cents */
  cents: Scalars['Int'];
  /** Day for which Labor Dollars are esimated */
  day: Scalars['ISO8601Date'];
};

/** An employees name and hours worked for a day */
export type LaborHourEmployee = {
  __typename?: 'LaborHourEmployee';
  employee: Employee;
  /** The number of hours worked by the employee */
  hours: Scalars['Float'];
  /** The number of hours planned for the employee to work */
  plannedHours: Scalars['Float'];
  shiftRoles: Array<ShiftRole>;
  shifts: Array<Shift>;
};

/** A breakdown of the daily hours worked by employees for a day, given an location */
export type LaborHoursDaily = {
  __typename?: 'LaborHoursDaily';
  excludeFromLabor: Array<LaborHourEmployee>;
  laborHourEmployees: Array<LaborHourEmployee>;
  totalLaborHours: Scalars['Float'];
  totalLaborHoursExcluded: Scalars['Float'];
  totalPlannedHours: Scalars['Float'];
  totalPlannedHoursExcluded: Scalars['Float'];
};

/** A date associated with a labor daily breakdown */
export type LaborHoursDateRange = {
  __typename?: 'LaborHoursDateRange';
  dailyBreakDowns: Array<LaborHoursDateRangeDay>;
  totalWeeklyHours: Scalars['Float'];
  totalWeeklyHoursExcluded: Scalars['Float'];
  totalWeeklyPlannedHours: Scalars['Float'];
  totalWeeklyPlannedHoursExcluded: Scalars['Float'];
};

/** A day associated with a labor daily breakdown */
export type LaborHoursDateRangeDay = {
  __typename?: 'LaborHoursDateRangeDay';
  dailyBreakDown: LaborHoursDaily;
  day: Scalars['ISO8601Date'];
};

/** A location */
export type Location = Record & {
  __typename?: 'Location';
  /** Location address */
  address: Scalars['String'];
  /** Whether or not auto scheduling is enabled */
  autoSchedulingEnabled: Scalars['Boolean'];
  /** Average hourly wage for the location */
  averageWage: Scalars['Int'];
  /** The list of category mappings associated with the location */
  categories: Scalars['JSON'];
  createdAt: Scalars['ISO8601DateTime'];
  /** Whether or not a location is a demo location */
  demoLocation: Scalars['Boolean'];
  /** The location's employees */
  employees: Array<Employee>;
  /** Location external identifier */
  externalId: Scalars['String'];
  /** Fiscal week start offset from Monday */
  fiscalWeekOffsetFromMonday: Maybe<Scalars['Int']>;
  /** Whether or not to display the something went wrong pop up */
  hideInsightsPopUp: Scalars['Boolean'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** Whether or item level forecast is enabled */
  itemLevelForecastEnabled: Scalars['Boolean'];
  /** Type of calculator (default shows location settings) */
  laborCalculationType: LaborCalculation;
  /** Target percent of revenue for labor */
  laborPercentOfRevenue: Scalars['Float'];
  /** Metrogroup name */
  metrogroup: Scalars['String'];
  /** The Minimum Labor Baseline needed for work */
  minimumLaborBaselines: Maybe<Array<MinimumLaborBaseline>>;
  /** Location name */
  name: Scalars['String'];
  /** Operating hours for the location */
  operatingHours: Array<OperatingHours>;
  /** The location's organization */
  organization: Organization;
  /** Organization id */
  organizationId: Scalars['ID'];
  /** Whether or not plan forecast is enabled */
  planForecastEnabled: Scalars['Boolean'];
  /** Location Point of Sale */
  pos: Scalars['String'];
  /** If the location prefers using the Entrees Per Labor Hour metric over the Sales Per Labor Hour metric */
  prefersEntreesPerLaborHourMetric: Scalars['Boolean'];
  /** Type of reservation integration for a location (if enabled) */
  reservationsImporterType: ReservationsImporter;
  /** The sales day parts for a location */
  salesDayParts: Array<SalesDayPart>;
  /** Sales Type - gross or net */
  salesType: Scalars['String'];
  /** Employee schedule offset in hours */
  scheduleOffsetInHours: Maybe<Scalars['String']>;
  /** Whether or not a location has 7Shifts integration enabled */
  sevenShiftEnabled: Scalars['Boolean'];
  /** The location's shift roles */
  shiftRoles: Array<ShiftRole>;
  /** The shift swap requests for a location */
  shiftSwapRequests: Array<ShiftSwapRequest>;
  /** The location's shifts */
  shifts: Array<Shift>;
  /** The status of the location e.g. active, disabled etc */
  status: Maybe<Scalars['String']>;
  /** The location's timezone */
  timeZone: Scalars['TimeZone'];
  /** Whether or not a location has Tripleseat integration enabled */
  tripleseatEnabled: Scalars['Boolean'];
  /** Location universal identifier */
  universalId: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  /** The number of users associated with the location */
  userCount: Scalars['Int'];
};


/** A location */
export type LocationShiftsArgs = {
  startOn: Maybe<Scalars['ISO8601Date']>;
  endOn: Maybe<Scalars['ISO8601Date']>;
};

/** joins shift role to location */
export type LocationShiftRoles = Record & {
  __typename?: 'LocationShiftRoles';
  /** Whether the location shift role is still active */
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  /** Whether the location shift role is excluded from labor */
  excludeFromLabor: Maybe<Scalars['Boolean']>;
  /** The Location Shift Role Id */
  id: Scalars['ID'];
  /** The Location Id */
  locationId: Scalars['String'];
  /** The Shift Role Id */
  shiftRoleId: Scalars['String'];
  /** The status */
  status: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Location time off blocked dates */
export type LocationTimeOffBlockedDate = {
  __typename?: 'LocationTimeOffBlockedDate';
  /** The date and time when the location time off blocked dates was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** The unique ID of the location time off blocked dates */
  id: Scalars['ID'];
  /** The location id */
  locationId: Scalars['String'];
  /** The time off blocked date */
  timeOffBlockedDate: TimeOffBlockedDate;
  /** The time off blocked date id */
  timeOffBlockedDateId: Scalars['String'];
  /** The date and time when the location time off blocked dates was last updated */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of LoginMutation */
export type LoginMutationInput = {
  /** The user email */
  email: Scalars['String'];
  /** The user password */
  password: Scalars['String'];
};

/** Autogenerated return type of LoginMutation */
export type LoginMutationPayload = {
  __typename?: 'LoginMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errorCode: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user: Maybe<User>;
};

/** Autogenerated input type of LogoutMutation */
export type LogoutMutationInput = {
  /** Fake argument. Only supplied because input objects must have at least one argument to be considered valid. */
  ignore: Maybe<Scalars['String']>;
};

/** Autogenerated return type of LogoutMutation */
export type LogoutMutationPayload = {
  __typename?: 'LogoutMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  success: Maybe<Scalars['Boolean']>;
};

/** A Mass Notification */
export type MassNotification = Record & {
  __typename?: 'MassNotification';
  createdAt: Scalars['ISO8601DateTime'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The location id */
  locationId: Scalars['ID'];
  /** The text of the mass notification */
  messageText: Scalars['String'];
  /** List of shift role categories (departments) for the message */
  shiftRoleCategories: Array<ShiftRoleCategory>;
  /** List of shift roles of the message */
  shiftRoles: Array<ShiftRole>;
  updatedAt: Scalars['ISO8601DateTime'];
  /** The id of the user who sent the message */
  userId: Maybe<Scalars['ID']>;
  /** Name of the user(sender) */
  userName: Scalars['String'];
};

export type MinimumLaborBaseline = Record & {
  __typename?: 'MinimumLaborBaseline';
  createdAt: Scalars['ISO8601DateTime'];
  dayOfWeek: Maybe<Scalars['String']>;
  endAt: Maybe<Scalars['ISO8601DateTime']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  laborCount: Maybe<Scalars['Int']>;
  locationId: Scalars['Int'];
  startAt: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  approveManagementRequest: ApproveManagementRequestMutationPayload;
  cancelTimeOffRequest: CancelTimeOffRequestMutationPayload;
  clearPlanShifts: ClearPlanShiftsMutationPayload;
  copyLastWeekShifts: Location;
  copyShiftsFromPlanTemplate: Location;
  createAutoSchedule: Location;
  createCovidDashboard: CovidDashboard;
  createEmployee: CreateEmployeeMutationPayload;
  createMassNotification: Maybe<CreateMassNotificationMutationPayload>;
  createPlanTemplate: CreatePlanTemplateMutationPayload;
  createShift: Shift;
  createShiftSwapRequest: CreateShiftSwapRequestMutationPayload;
  createWeeklyPlanExportMutation: Maybe<CreateWeeklyPlanExportMutationPayload>;
  deleteEmployeeLocations: DeleteEmployeeLocationsMutationPayload;
  deleteForecast: DeleteForecastMutationPayload;
  deleteSalesDayParts: Maybe<DeleteSalesDayPartsMutationPayload>;
  deleteShift: DeleteShiftMutationPayload;
  deleteShiftSwapRequest: DeleteShiftSwapRequestMutationPayload;
  deleteTimeOffBlockedDate: DeleteTimeOffBlockedDateMutationPayload;
  employeeLogin: EmployeeLoginMutationPayload;
  employeeSendCode: EmployeeSendCodeMutationPayload;
  login: LoginMutationPayload;
  logout: LogoutMutationPayload;
  onboardingOauth: OnboardingOauthMutationPayload;
  requestPasswordReset: RequestPasswordResetMutationPayload;
  resetDemoLocation: ResetDemoLocationMutationPayload;
  resetPassword: ResetPasswordMutationPayload;
  resetPlanState: ResetPlanStateMutationPayload;
  signup: SignupMutationPayload;
  squareHistoricImport: SquareHistoricImportMutationPayload;
  squareRevokeAuthorization: SquareRevokeAuthorizationMutationPayload;
  updateCovidDashboard: UpdateCovidDashboardMutationPayload;
  updateEmployee: UpdateEmployeeMutationPayload;
  updateEmployeeLocation: UpdateEmployeeLocationsMutationPayload;
  updateEmployeeMinMaxHours: UpdateEmployeeMinMaxHoursPayload;
  updateLocation: UpdateLocationMutationPayload;
  updateLocationShiftRoles: UpdateLocationShiftRolesMutationPayload;
  updateOperatingHours: UpdateOperatingHoursMutationPayload;
  updateOrganization: UpdateOrganizationMutationPayload;
  updatePlan: UpdatePlanMutationPayload;
  updateShift: Shift;
  updateShiftRole: UpdateShiftRoleMutationPayload;
  updateShiftSwapRequest: UpdateShiftSwapRequestMutationPayload;
  updateUser: UpdateUserMutationPayload;
  upsertEmployeeAvailability: EmployeeAvailability;
  upsertForecast: UpsertForecastMutationPayload;
  upsertInsight: UpsertInsightMutationPayload;
  upsertLocationTimeOffBlockedDates: UpsertLocationTimeOffBlockedDatesMutationPayload;
  upsertSalesDayParts: Maybe<UpsertSalesDayPartsMutationPayload>;
  upsertShiftRoleCategory: UpsertShiftRoleCategoryMutationPayload;
  upsertTimeOffBlockedDate: UpsertTimeOffBlockedDateMutationPayload;
  upsertTimeOffRequest: TimeOffRequest;
};


export type MutationApproveManagementRequestArgs = {
  input: ApproveManagementRequestMutationInput;
};


export type MutationCancelTimeOffRequestArgs = {
  input: CancelTimeOffRequestMutationInput;
};


export type MutationClearPlanShiftsArgs = {
  input: ClearPlanShiftsMutationInput;
};


export type MutationCopyLastWeekShiftsArgs = {
  input: CopyLastWeekShiftsMutationInput;
};


export type MutationCopyShiftsFromPlanTemplateArgs = {
  input: CopyShiftsFromPlanTemplateMutationInput;
};


export type MutationCreateAutoScheduleArgs = {
  input: CreateAutoScheduleMutationInput;
};


export type MutationCreateCovidDashboardArgs = {
  input: CreateCovidDashboardMutationInput;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeMutationInput;
};


export type MutationCreateMassNotificationArgs = {
  input: CreateMassNotificationMutationInput;
};


export type MutationCreatePlanTemplateArgs = {
  input: CreatePlanTemplateMutationInput;
};


export type MutationCreateShiftArgs = {
  input: CreateShiftMutationInput;
};


export type MutationCreateShiftSwapRequestArgs = {
  input: CreateShiftSwapRequestMutationInput;
};


export type MutationCreateWeeklyPlanExportMutationArgs = {
  input: CreateWeeklyPlanExportMutationInput;
};


export type MutationDeleteEmployeeLocationsArgs = {
  input: DeleteEmployeeLocationsMutationInput;
};


export type MutationDeleteForecastArgs = {
  input: DeleteForecastMutationInput;
};


export type MutationDeleteSalesDayPartsArgs = {
  input: DeleteSalesDayPartsMutationInput;
};


export type MutationDeleteShiftArgs = {
  input: DeleteShiftMutationInput;
};


export type MutationDeleteShiftSwapRequestArgs = {
  input: DeleteShiftSwapRequestMutationInput;
};


export type MutationDeleteTimeOffBlockedDateArgs = {
  input: DeleteTimeOffBlockedDateMutationInput;
};


export type MutationEmployeeLoginArgs = {
  input: EmployeeLoginMutationInput;
};


export type MutationEmployeeSendCodeArgs = {
  input: EmployeeSendCodeMutationInput;
};


export type MutationLoginArgs = {
  input: LoginMutationInput;
};


export type MutationLogoutArgs = {
  input: LogoutMutationInput;
};


export type MutationOnboardingOauthArgs = {
  input: OnboardingOauthMutationInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetMutationInput;
};


export type MutationResetDemoLocationArgs = {
  input: ResetDemoLocationMutationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordMutationInput;
};


export type MutationResetPlanStateArgs = {
  input: ResetPlanStateMutationInput;
};


export type MutationSignupArgs = {
  input: SignupMutationInput;
};


export type MutationSquareHistoricImportArgs = {
  input: SquareHistoricImportMutationInput;
};


export type MutationSquareRevokeAuthorizationArgs = {
  input: SquareRevokeAuthorizationMutationInput;
};


export type MutationUpdateCovidDashboardArgs = {
  input: UpdateCovidDashboardMutationInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeMutationInput;
};


export type MutationUpdateEmployeeLocationArgs = {
  input: UpdateEmployeeLocationsMutationInput;
};


export type MutationUpdateEmployeeMinMaxHoursArgs = {
  input: UpdateEmployeeMinMaxHoursInput;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationMutationInput;
};


export type MutationUpdateLocationShiftRolesArgs = {
  input: UpdateLocationShiftRolesMutationInput;
};


export type MutationUpdateOperatingHoursArgs = {
  input: UpdateOperatingHoursMutationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationMutationInput;
};


export type MutationUpdatePlanArgs = {
  input: UpdatePlanMutationInput;
};


export type MutationUpdateShiftArgs = {
  input: UpdateShiftMutationInput;
};


export type MutationUpdateShiftRoleArgs = {
  input: UpdateShiftRoleMutationInput;
};


export type MutationUpdateShiftSwapRequestArgs = {
  input: UpdateShiftSwapRequestMutationInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserMutationInput;
};


export type MutationUpsertEmployeeAvailabilityArgs = {
  input: UpsertEmployeeAvailabilityMutationInput;
};


export type MutationUpsertForecastArgs = {
  input: UpsertForecastMutationInput;
};


export type MutationUpsertInsightArgs = {
  input: UpsertInsightMutationInput;
};


export type MutationUpsertLocationTimeOffBlockedDatesArgs = {
  input: UpsertLocationTimeOffBlockedDatesMutationInput;
};


export type MutationUpsertSalesDayPartsArgs = {
  input: UpsertSalesDayPartsMutationInput;
};


export type MutationUpsertShiftRoleCategoryArgs = {
  input: UpsertShiftRoleCategoryMutationInput;
};


export type MutationUpsertTimeOffBlockedDateArgs = {
  input: UpsertTimeOffBlockedDateMutationInput;
};


export type MutationUpsertTimeOffRequestArgs = {
  input: UpsertTimeOffRequestMutationInput;
};

/** An OAuth Authorization Object */
export type OauthAuthorization = {
  __typename?: 'OauthAuthorization';
  /** The OAuth Authorization URL */
  url: Scalars['String'];
};

/** Autogenerated input type of OnboardingOauthMutation */
export type OnboardingOauthMutationInput = {
  /** The type of POS */
  posType: Scalars['String'];
  /** The code to retrieve access token */
  code: Maybe<Scalars['String']>;
  /** The clover merchant ID */
  merchantId: Maybe<Scalars['String']>;
  /** The response type */
  responseType: Maybe<Scalars['String']>;
  /** The CSRF state token */
  state: Scalars['String'];
  /** The OAuth denial error */
  error: Maybe<Scalars['String']>;
  /** The OAuth denial error description */
  errorDescription: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OnboardingOauthMutation */
export type OnboardingOauthMutationPayload = {
  __typename?: 'OnboardingOauthMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  description: Scalars['String'];
  errorCode: Maybe<Scalars['String']>;
  locations: Maybe<Array<Location>>;
  success: Scalars['Boolean'];
};

/** Operating Hour Attributes */
export type OperatingHourAttributes = {
  /** The unique id */
  id: Scalars['ID'];
  /** The unique id of the location */
  locationId: Scalars['ID'];
  /** The day of the week as an integer, e.g. 0, 1, 2, 3 etc. */
  dayOfWeek: Scalars['Int'];
  /** The location close at time in ISO8601 format */
  closeAtIso8601: Scalars['String'];
  /** The location open at time in ISO8601 format */
  openAtIso8601: Scalars['String'];
  /** The day of the week as a string, e.g. monday, tuesday etc. */
  day: Maybe<Scalars['String']>;
  /** A boolean to show if the location is closed for the day */
  closed: Maybe<Scalars['Boolean']>;
};

/** Hours of operation window */
export type OperatingHours = {
  __typename?: 'OperatingHours';
  /** ISO8601 DateTime for end of the window */
  closeAt: Scalars['ISO8601DateTime'];
  /** ISO8601 time string representing the start of the window */
  closeAtIso8601: Scalars['String'];
  /** Whether the location is closed for the day */
  closed: Maybe<Scalars['Boolean']>;
  /** Day of the week */
  day: Scalars['String'];
  /** Integer day of the week (Sunday is 0) */
  dayOfWeek: Scalars['Int'];
  /** The internal ID of the operating hours */
  id: Scalars['ID'];
  /** ISO8601 DateTime for start of the window */
  openAt: Scalars['ISO8601DateTime'];
  /** ISO8601 time string representing the start of the window */
  openAtIso8601: Scalars['String'];
  /** ISO8601 DateTime for start of employee scheduling */
  scheduleBegin: Maybe<Scalars['ISO8601DateTime']>;
  /** ISO8601 time string representing the start of the employee scheduling window */
  scheduleBeginIso8601: Maybe<Scalars['String']>;
  /** ISO8601 DateTime for end of employee scheduling */
  scheduleEnd: Maybe<Scalars['ISO8601DateTime']>;
  /** ISO8601 time string representing the end of the employee scheduling window */
  scheduleEndIso8601: Maybe<Scalars['String']>;
};

/** An organization */
export type Organization = Record & {
  __typename?: 'Organization';
  /** Whether or not auto scheduling is enabled */
  autoSchedulingEnabled: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  /** The employee availabilities for a location */
  employeeAvailabilities: Array<EmployeeAvailability>;
  /** The type of fiscal period. Currently, only 454 or 444 is supported */
  fiscalBreakdownType: Scalars['String'];
  /** The number of fiscal periods per year. Currently, only 12 or 13 is supported */
  fiscalPeriodsPerYear: Scalars['Int'];
  /** Fiscal week start offset from Monday */
  fiscalWeekOffsetFromMonday: Maybe<Scalars['Int']>;
  /** Whether or not to display the something went wrong pop up */
  hideInsightsPopUp: Maybe<Scalars['Boolean']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** Integration Settings */
  integrationSettings: Scalars['JSON'];
  /** Whether or item level forecast is enabled */
  itemLevelForecastEnabled: Maybe<Scalars['Boolean']>;
  /** The labor percent of revenue */
  laborPercentOfRevenue: Scalars['Float'];
  /** The number of locations in the organization */
  locationsCount: Scalars['Int'];
  /** The organization's name */
  name: Scalars['String'];
  /** Whether or not to email when overtime hours are reached */
  overtimeHoursEmailOn: Scalars['Boolean'];
  /** The number of overtime hours before an email is sent */
  overtimeHoursThreshold: Scalars['Int'];
  /** Sales Type - gross or net */
  salesType: Scalars['String'];
  /** Shift role categories (departments) for the organization */
  shiftRoleCategories: Array<ShiftRoleCategory>;
  /** The start of the first fiscal year */
  startOfInitialFiscalYear: Scalars['ISO8601Date'];
  /** The time off requests for a location */
  timeOffRequests: Array<TimeOffRequest>;
  /** The universal organization id */
  universalId: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  /** The day of the week that fiscal weeks start on. Sunday is 0 */
  weekDayStart: Scalars['Int'];
};

/** Scope of a given pdf export */
export type PdfContentOptions = {
  /** Indicates if the export should contain toal hours column */
  totalHours: Scalars['Boolean'];
  /** Indicates if the export should display employee roles */
  roles: Scalars['Boolean'];
  /** Indicates if the export should include sales data */
  salesData: Scalars['Boolean'];
  /** Indicates if the export should include external events */
  externalEvents: Scalars['Boolean'];
  /** Indicates if the export should include internal events */
  internalEvents: Scalars['Boolean'];
  /** Indicates if the export should include reservations */
  reservations: Scalars['Boolean'];
  /** Indicates if the export should include weather */
  weather: Scalars['Boolean'];
};

/** A user permission */
export type Permission = {
  __typename?: 'Permission';
  /** The permission name */
  permission: PermissionName;
  /** The scope of the permission */
  scope: PermissionScope;
};

export enum PermissionName {
  Base = 'base',
  ViewReporting = 'view_reporting',
  ManageEmployees = 'manage_employees',
  EditLaborAllowance = 'edit_labor_allowance',
  ManageOrganizations = 'manage_organizations',
  ManagePredictions = 'manage_predictions',
  UpdateUsers = 'update_users',
  UpdateSalesDayParts = 'update_sales_day_parts',
  ResetDemoLocations = 'reset_demo_locations'
}

/** Scope of a given permission */
export type PermissionScope = Location | Organization;

/** The data needed to plan */
export type Plan = Record & {
  __typename?: 'Plan';
  /** Is the plan auto generated */
  autoGenerated: Scalars['Boolean'];
  /** The average in cents assumed for the plan */
  averageWage: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  dailySalesAndLaborSummaries: Array<SalesAndLaborSummary>;
  /** The ID of the plan */
  databaseId: Maybe<Scalars['ID']>;
  /** When the plan is/was due. */
  dueAt: Scalars['ISO8601DateTime'];
  /** Plan end date */
  endOn: Scalars['ISO8601Date'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The percentage of revenue for labor assued for the plan */
  laborPercentOfRevenue: Scalars['Float'];
  lastMonthSameDayReportedSales: Array<DailySalesBenchmark>;
  /** When the plan was last updated */
  lastUpdatedAt: Maybe<Scalars['ISO8601DateTime']>;
  lastWeekSameDayReportedSales: Array<DailySalesBenchmark>;
  lastYearSameDayReportedSales: Array<DailySalesBenchmark>;
  /** Blocked dates for the plan */
  locationClosedDates: Array<TimeOffBlockedDate>;
  /** Should plan have simple/complex submission process */
  needsConfirmation: Scalars['Boolean'];
  /** The plan explanation */
  planExplanation: Maybe<Scalars['String']>;
  /** The plan template */
  planTemplate: Maybe<PlanTemplate>;
  /** Shifts in the plan */
  shifts: Array<Shift>;
  /** Plan start date */
  startOn: Scalars['ISO8601Date'];
  /** The plan state */
  state: Scalars['String'];
  /** When the plan was submitted. Null if not submitted. */
  submittedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** The plan submitter */
  submitter: Maybe<User>;
  updatedAt: Scalars['ISO8601DateTime'];
  /** The user who last updated the plan */
  updatedBy: Maybe<Scalars['String']>;
  weekSalesAndLaborSummary: SalesAndLaborSummary;
};

/** The data needed for a Plan Template */
export type PlanTemplate = {
  __typename?: 'PlanTemplate';
  /** ID of the plan template */
  id: Scalars['ID'];
  /** The name of the plan template */
  name: Scalars['String'];
  /** ID of the selected plan */
  planId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  /** Get covid dashboard */
  covidDashboard: Maybe<CovidDashboard>;
  /** Daily historical predictions for a location and date range */
  dailyHistoricalPredictions: Array<IntervalPredictionSum>;
  /** Accessible employee */
  employee: Maybe<Employee>;
  /** Availability for a given employee */
  employeeAvailability: EmployeeAvailability;
  /** Accessible employee location */
  employeeLocation: Maybe<EmployeeLocation>;
  /** Accessible employee locations */
  employeeLocations: Array<EmployeeLocation>;
  /** Accessible employees */
  employees: Array<Employee>;
  /** Features Flags */
  features: Maybe<Features>;
  /** Get Features for employee/user by employee ID */
  featuresByEmployeeId: Maybe<Features>;
  /** The item level forecast for the given location and dates */
  forecastedItems: ItemsForecast;
  /** Latest import event for location that succeeded */
  importEvent: Maybe<ImportEvent>;
  /** Insight for a location and date */
  insight: Maybe<Insight>;
  /** Get all internal events for a given location */
  internalEvents: Maybe<Array<InternalEvent>>;
  /** Order sums for a location and date range, split up by the specified interval */
  intervalOrderSums: Array<IntervalOrderSum>;
  /** Prediction sums for a location and date range */
  intervalPredictionSums: Array<IntervalPredictionSum>;
  /** Retrieve labor dollars computed for the given plan */
  laborDollars: Maybe<LaborDollars>;
  /** Returns breakdown of employee hours worked for a given day */
  laborHoursDailyBreakdown: LaborHoursDaily;
  /** Returns breakdown of employee hours worked for a given date range */
  laborHoursDateRangeBreakdown: LaborHoursDateRange;
  /** Location details */
  location: Location;
  /** Location Shift role */
  locationShiftRole: LocationShiftRoles;
  /** Location Shift roles */
  locationShiftRoles: Array<LocationShiftRoles>;
  /** Location time off blocked dates by time off blocked date id */
  locationTimeOffBlockedDate: Array<LocationTimeOffBlockedDate>;
  /** Location time off blocked dates by location id */
  locationTimeOffBlockedDates: Array<LocationTimeOffBlockedDate>;
  /** Accessible locations */
  locations: Array<Location>;
  /** Accessible locations for C-Suite reporting */
  locationsForReporting: Array<Location>;
  /** Mass Notifications */
  massNotifications: Array<MassNotification>;
  /** Information about the current user */
  me: Maybe<User>;
  /** Generate OAuth Authorization Object given a user */
  oauthAuthorization: OauthAuthorization;
  /** Operating hours for a location */
  operatingHours: Maybe<Array<OperatingHours>>;
  /** Operating hours for a location on a date */
  operatingHoursForDate: Maybe<Array<OperatingHours>>;
  /** The submitted or unsubmitted Plan for the given dates */
  plan: Plan;
  /** Plan Templates from location */
  planTemplates: Array<PlanTemplate>;
  /** Preview violations for an existing or potential shift */
  previewViolations: Array<Violation>;
  /** Filter shifts for plan */
  relevantShiftsForPlan: Array<Employee>;
  /** Reservations for a location over a date range */
  reservations: Array<Reservation>;
  /** A sales day part */
  salesDayParts: Array<SalesDayPart>;
  /** A shift and all overlapping shifts */
  shift: Shift;
  /** Shifts from employee */
  shifts: Array<Shift>;
  /** Shifts with a Shift Swap Request state of released */
  shiftsAvailableForSwap: Maybe<Array<Shift>>;
  /** Shifts per location */
  shiftsPerLocation: Array<Shift>;
  /** Shifts per locations */
  shiftsPerLocations: Array<Shift>;
  /** Shifts with pending Swap Requests for current employee (shifts they released or requested, or denied) */
  shiftsWithPendingSwaps: Maybe<Array<Shift>>;
  /** Time off blocked date for id */
  timeOffBlockedDate: Maybe<TimeOffBlockedDate>;
  /** Time off blocked dates from organization */
  timeOffBlockedDates: Array<TimeOffBlockedDate>;
  /** Time off requests for an employee */
  timeOffRequests: Maybe<Array<TimeOffRequest>>;
  /** The top events near a location for a given date range */
  topEvents: Array<TopEvent>;
  /** Toucan Auth Token */
  toucanAuth: Maybe<ToucanAuth>;
  /** Accessible users for an organization */
  users: Array<User>;
  /** Accessible users for a location */
  usersForLocation: Array<User>;
  /** The violantions for a plan */
  violationsPlan: Array<Violation>;
  /** Current Weather for a location */
  weatherCurrent: Maybe<WeatherCurrent>;
  /** Daily Weather for a location */
  weatherDaily: Maybe<Array<WeatherDaily>>;
  /** The submitted or unsubmitted Plans for the given dates per week */
  weeklyPlans: Array<Plan>;
};


export type QueryCovidDashboardArgs = {
  id: Scalars['ID'];
};


export type QueryDailyHistoricalPredictionsArgs = {
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  ageInDays: Maybe<Scalars['Int']>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeeAvailabilityArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeeLocationArgs = {
  locationId: Scalars['ID'];
  employeeId: Scalars['ID'];
};


export type QueryEmployeeLocationsArgs = {
  locationId: Scalars['ID'];
};


export type QueryEmployeesArgs = {
  locationId: Scalars['ID'];
};


export type QueryFeaturesArgs = {
  actorId: Maybe<Scalars['ID']>;
  actorType: Maybe<Scalars['String']>;
};


export type QueryFeaturesByEmployeeIdArgs = {
  id: Scalars['ID'];
};


export type QueryForecastedItemsArgs = {
  locationId: Scalars['ID'];
  valueBy: ForecastedItemsValueBy;
  startOn: Scalars['ISO8601Date'];
};


export type QueryImportEventArgs = {
  locationId: Scalars['ID'];
};


export type QueryInsightArgs = {
  date: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
};


export type QueryInternalEventsArgs = {
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
};


export type QueryIntervalOrderSumsArgs = {
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  intervalMinutes: Scalars['Int'];
};


export type QueryIntervalPredictionSumsArgs = {
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  intervalMinutes: Scalars['Int'];
};


export type QueryLaborDollarsArgs = {
  locationId: Scalars['ID'];
  planId: Scalars['ID'];
};


export type QueryLaborHoursDailyBreakdownArgs = {
  locationId: Scalars['ID'];
  day: Scalars['ISO8601Date'];
};


export type QueryLaborHoursDateRangeBreakdownArgs = {
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601Date'];
  endAt: Scalars['ISO8601Date'];
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLocationShiftRoleArgs = {
  locationId: Scalars['ID'];
  shiftRoleId: Scalars['ID'];
};


export type QueryLocationShiftRolesArgs = {
  locationId: Scalars['ID'];
};


export type QueryLocationTimeOffBlockedDateArgs = {
  timeOffBlockedDateId: Scalars['ID'];
};


export type QueryLocationTimeOffBlockedDatesArgs = {
  locationId: Scalars['ID'];
};


export type QueryMassNotificationsArgs = {
  locationId: Scalars['ID'];
};


export type QueryOauthAuthorizationArgs = {
  posType: Scalars['String'];
};


export type QueryOperatingHoursArgs = {
  locationId: Scalars['ID'];
};


export type QueryOperatingHoursForDateArgs = {
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  endOn: Maybe<Scalars['ISO8601Date']>;
};


export type QueryPlanArgs = {
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  endOn: Maybe<Scalars['ISO8601Date']>;
};


export type QueryPlanTemplatesArgs = {
  locationId: Scalars['ID'];
};


export type QueryPreviewViolationsArgs = {
  id: Maybe<Scalars['ID']>;
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  employeeId: Scalars['ID'];
  shiftRoleId: Maybe<Scalars['ID']>;
  shiftBreaks: Maybe<Array<Scalars['Int']>>;
  isUnavailability: Scalars['Boolean'];
};


export type QueryRelevantShiftsForPlanArgs = {
  planId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type QueryReservationsArgs = {
  locationId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
};


export type QuerySalesDayPartsArgs = {
  locationId: Scalars['ID'];
};


export type QueryShiftArgs = {
  shiftId: Scalars['ID'];
  employeeId: Maybe<Scalars['ID']>;
};


export type QueryShiftsArgs = {
  employeeId: Scalars['ID'];
  startAt: Maybe<Scalars['ISO8601DateTime']>;
  endAt: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryShiftsAvailableForSwapArgs = {
  employeeId: Scalars['ID'];
};


export type QueryShiftsPerLocationArgs = {
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryShiftsPerLocationsArgs = {
  locationIds: Array<Scalars['ID']>;
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
};


export type QueryShiftsWithPendingSwapsArgs = {
  employeeId: Scalars['ID'];
};


export type QueryTimeOffBlockedDateArgs = {
  id: Scalars['ID'];
};


export type QueryTimeOffBlockedDatesArgs = {
  organizationId: Scalars['ID'];
};


export type QueryTimeOffRequestsArgs = {
  id: Scalars['ID'];
};


export type QueryTopEventsArgs = {
  endOn: Maybe<Scalars['ISO8601Date']>;
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
};


export type QueryToucanAuthArgs = {
  startDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  locationIds: Array<Scalars['ID']>;
};


export type QueryUsersArgs = {
  organizationId: Scalars['ID'];
};


export type QueryUsersForLocationArgs = {
  locationId: Scalars['ID'];
};


export type QueryViolationsPlanArgs = {
  planId: Maybe<Scalars['ID']>;
  locationId: Scalars['ID'];
};


export type QueryWeatherCurrentArgs = {
  locationId: Scalars['ID'];
};


export type QueryWeatherDailyArgs = {
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
};


export type QueryWeeklyPlansArgs = {
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
};

export enum Reason {
  ConstructionOrRoadClosure = 'construction_or_road_closure',
  Event = 'event',
  Weather = 'weather',
  ShortenedOrExtendedStoreHours = 'shortened_or_extended_store_hours',
  Holidays = 'holidays',
  LargeOrders = 'large_orders',
  RanPromotion = 'ran_promotion',
  Other = 'other'
}

export type Record = {
  createdAt: Scalars['ISO8601DateTime'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of RequestPasswordResetMutation */
export type RequestPasswordResetMutationInput = {
  /** The user's email address */
  email: Scalars['String'];
};

/** Autogenerated return type of RequestPasswordResetMutation */
export type RequestPasswordResetMutationPayload = {
  __typename?: 'RequestPasswordResetMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** A reservation */
export type Reservation = Record & {
  __typename?: 'Reservation';
  createdAt: Scalars['ISO8601DateTime'];
  /** The fiscal date for the reservation */
  fiscalDate: Scalars['ISO8601Date'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The event is a large party */
  largeParty: Scalars['Boolean'];
  /** The event guest count */
  partySize: Scalars['Int'];
  /** Url to access reservation (openTables/tock) info */
  reservationUrl: Scalars['String'];
  /** The scheduled time in UTC for the reservation */
  scheduledTime: Scalars['ISO8601DateTime'];
  /** The scheduled time in Location time zone for the reservation in H:m am/pm */
  scheduledTimeLocalFormatted: Scalars['String'];
  /** The state of the reservation */
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ReservationsImporter {
  OpenTable = 'open_table',
  Tock = 'tock',
  NoImporter = 'no_importer'
}

/** Autogenerated input type of ResetDemoLocationMutation */
export type ResetDemoLocationMutationInput = {
  locationId: Scalars['ID'];
};

/** Autogenerated return type of ResetDemoLocationMutation */
export type ResetDemoLocationMutationPayload = {
  __typename?: 'ResetDemoLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ResetPasswordMutation */
export type ResetPasswordMutationInput = {
  /** The password reset token from the server */
  resetPasswordToken: Scalars['String'];
  /** The new password for the User */
  newPassword: Scalars['String'];
};

/** Autogenerated return type of ResetPasswordMutation */
export type ResetPasswordMutationPayload = {
  __typename?: 'ResetPasswordMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errorCode: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ResetPlanStateMutation */
export type ResetPlanStateMutationInput = {
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
};

/** Autogenerated return type of ResetPlanStateMutation */
export type ResetPlanStateMutationPayload = {
  __typename?: 'ResetPlanStateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

/** One of the Organization's Roles */
export type Role = {
  __typename?: 'Role';
  /** The name of the role */
  name: Scalars['String'];
};

/** Key summary data for sales and labor */
export type SalesAndLaborSummary = {
  __typename?: 'SalesAndLaborSummary';
  /** The actual sales per actual labor hours divided by average entree price. The number of entrees you did serve per labor hour. */
  actualEntreesPerActualLaborHour: Maybe<Scalars['Float']>;
  /** The actual labor in hours */
  actualLabor: Maybe<Scalars['Float']>;
  /** The actual labor dollars in cents */
  actualLaborDollars: Maybe<Scalars['Float']>;
  /** The actual sales in cents */
  actualSales: Maybe<Scalars['Int']>;
  /** The actual sales divided by the actual labor hours */
  actualSalesPerActualLaborHour: Maybe<Scalars['Float']>;
  /** A unique summary ID */
  id: Scalars['ID'];
  /** The sales sum from 7 days prior */
  lastWeekBenchmark: Scalars['Float'];
  /** The latest predicted sales split by category */
  latestPredictedCategorySales: Scalars['JSON'];
  /** The latest predicted catering sales */
  latestPredictedCateringSales: Scalars['Int'];
  /** The latest predicted dine-in sales */
  latestPredictedDineInSales: Scalars['Int'];
  /** The latest predicted online sales */
  latestPredictedOnlineSales: Scalars['Int'];
  /** The latest predicted sales */
  latestPredictedSales: Scalars['Int'];
  /** If the summary is just for the start_on, then 1. If for a week, then 7 */
  numOfDays: Scalars['Int'];
  /** The optimal labor for the actual sales in hours  */
  optimalLaborForActualSales: Maybe<Scalars['Float']>;
  /** The optimal labor for the latest predicted sales */
  optimalLaborForLatestPredictedSales: Scalars['Float'];
  /** The optimal labor for the original_predicted_sales */
  optimalLaborForOriginallyPredictedSales: Scalars['Float'];
  /** The optimal labor for the planned sales */
  optimalLaborForPlannedSales: Scalars['Float'];
  /** The optimal labor for the submitted_planned_sales */
  optimalLaborForSubmittedPlannedSales: Maybe<Scalars['Float']>;
  /** The optimal sales for actual labor in in cents */
  optimalSalesForActualLabor: Maybe<Scalars['Int']>;
  /** Either the predicted sales at the time of submission or the latest predicted sales if not yet submitted */
  originallyPredictedSales: Scalars['Int'];
  /** The planned_sales_per_scheduled_labor_hour divided by average entree price. The number of entrees you expect to serve per labor hour. Null if planned_sales_per_scheduled_labor_hour is null or if missing average entree price */
  plannedEntreesPerScheduledLaborHour: Maybe<Scalars['Float']>;
  /** For submitted plans, the planned_sales. For unsubmitted plans, either the user override or the latest prediction */
  plannedSales: Scalars['Int'];
  /** For submitted plans, the planned sales divided by the scheduled labor hours. Null for unsubmitted plans or for no scheduled labor. */
  plannedSalesPerScheduledLaborHour: Maybe<Scalars['Float']>;
  /** The total number of hours for added shifts for ALL employees */
  preScheduledDisplayLabor: Scalars['Float'];
  /** The total number of hours for added shifts */
  preScheduledLabor: Scalars['Float'];
  /** The scheduled labor in hours for a submitted plan */
  scheduledLabor: Maybe<Scalars['Float']>;
  /** The date summarized */
  startOn: Scalars['ISO8601Date'];
  /** Planned sales for submitted plans */
  submittedPlannedSales: Maybe<Scalars['Int']>;
};

/** A sales day part */
export type SalesDayPart = Record & {
  __typename?: 'SalesDayPart';
  createdAt: Scalars['ISO8601DateTime'];
  /** The name of the sales day part */
  dayPartTitle: Scalars['String'];
  /** The end time of the sales day part in ISO8601 format */
  endAt: Scalars['String'];
  /** The id of the sales day part */
  id: Scalars['ID'];
  /** The id of the corresponding location */
  locationId: Scalars['ID'];
  /** The start time of the sales day part in ISO8601 format */
  startAt: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A sales day part */
export type SalesDayPartArgument = {
  /** The id of the sales day part */
  id: Maybe<Scalars['ID']>;
  /** The name of the sales day part */
  dayPartTitle: Scalars['String'];
  /** The start time of the sales day part in ISO8601 format */
  startAt: Scalars['String'];
  /** The end time of the sales day part in ISO8601 format */
  endAt: Scalars['String'];
};

/** A shift */
export type Shift = Record & {
  __typename?: 'Shift';
  createdAt: Scalars['ISO8601DateTime'];
  /** The number of labor hours for shift. Excludes unavailable shifts. */
  displayHours: Maybe<Scalars['Float']>;
  /** The shift's employee */
  employee: Employee;
  /** The shift's end time */
  endAt: Scalars['ISO8601DateTime'];
  /** The number of labor hours this shift represents. Excludes unavailable shifts and employees excluded from labor calculations */
  hours: Maybe<Scalars['Float']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** For representing unavailability periods */
  isUnavailability: Scalars['Boolean'];
  /** The shift's Location */
  location: Location;
  /** The shift notes */
  notes: Maybe<Scalars['String']>;
  /** All other shifts that overlap with the shift */
  overlappingShifts: Maybe<Array<Shift>>;
  /** Breaks in a shift */
  shiftBreaks: Maybe<Array<ShiftBreak>>;
  /** The shift's role */
  shiftRole: Maybe<ShiftRole>;
  /** The shift's role id */
  shiftRoleId: Maybe<Scalars['ID']>;
  /** The latest shift swap request for the shift */
  shiftSwapRequest: Maybe<ShiftSwapRequest>;
  /** The shift's forecast id */
  shiftUniversalId: Maybe<Scalars['ID']>;
  /** The shift's start time */
  startAt: Scalars['ISO8601DateTime'];
  /** The shift state */
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  /** The shift wage */
  wage: Maybe<Scalars['Int']>;
};


/** A shift */
export type ShiftEmployeeArgs = {
  includeUnassigned: Maybe<Scalars['Boolean']>;
};


/** A shift */
export type ShiftShiftSwapRequestArgs = {
  employeeId: Maybe<Scalars['ID']>;
};

/** A break for a shift */
export type ShiftBreak = Record & {
  __typename?: 'ShiftBreak';
  createdAt: Scalars['ISO8601DateTime'];
  /** The duration of the break in minutes */
  durationMins: Scalars['Int'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The id of the corresponding shift */
  shiftId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A shift role */
export type ShiftRole = Record & {
  __typename?: 'ShiftRole';
  createdAt: Scalars['ISO8601DateTime'];
  /** The External provided id */
  externalId: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The integration settings */
  integrationSettings: Maybe<Scalars['JSON']>;
  /** The Locations it belongs as Location Shift Roles */
  locationShiftRoles: Maybe<Array<LocationShiftRoles>>;
  /** The meta data */
  metaData: Maybe<Scalars['JSON']>;
  /** Shift role name */
  name: Scalars['String'];
  /** The Organization Id */
  organizationId: Maybe<Scalars['String']>;
  /** The Pay Rate */
  payRate: Maybe<Scalars['Int']>;
  /** The Pay Type */
  payType: Maybe<Scalars['Int']>;
  /** The Shift Role Category */
  shiftRoleCategory: Maybe<ShiftRoleCategory>;
  /** The Shift Role Category ID */
  shiftRoleCategoryId: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The category for a shift role */
export type ShiftRoleCategory = Record & {
  __typename?: 'ShiftRoleCategory';
  createdAt: Scalars['ISO8601DateTime'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The name for the category */
  name: Scalars['String'];
  /** The organization id */
  organizationId: Scalars['ID'];
  /** Shift roles belonging to that category */
  shiftRoles: Array<ShiftRole>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A Shift Swap Request */
export type ShiftSwapRequest = Record & {
  __typename?: 'ShiftSwapRequest';
  createdAt: Scalars['ISO8601DateTime'];
  employee: Employee;
  /** The unique, employee id of the current shift owner */
  employeeId: Scalars['String'];
  /** Employee Note for the shift swap */
  employeeNote: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** Manager Note for the shift swap */
  managerNote: Maybe<Scalars['String']>;
  newEmployee: Maybe<Employee>;
  /** The unique employee id of the new owner */
  newEmployeeId: Maybe<Scalars['String']>;
  /** The time the request was reviewed */
  reviewedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** The shift to be swapped */
  shift: Shift;
  /** The unique id of the shift */
  shiftId: Scalars['ID'];
  /** The shift swap request state (released, swap_pending, swap_approved etc.) */
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  /** The GM who reviewed the request */
  user: Maybe<User>;
};

/** Autogenerated input type of SignupMutation */
export type SignupMutationInput = {
  /** The user name */
  name: Scalars['String'];
  /** The user organization */
  organization: Scalars['String'];
  /** The user email */
  email: Scalars['String'];
  /** The user password */
  password: Scalars['String'];
};

/** Autogenerated return type of SignupMutation */
export type SignupMutationPayload = {
  __typename?: 'SignupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errorCode: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user: Maybe<User>;
};

/** Autogenerated input type of SquareHistoricImportMutation */
export type SquareHistoricImportMutationInput = {
  locationId: Scalars['ID'];
};

/** Autogenerated return type of SquareHistoricImportMutation */
export type SquareHistoricImportMutationPayload = {
  __typename?: 'SquareHistoricImportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  location: Location;
};

/** Autogenerated input type of SquareRevokeAuthorizationMutation */
export type SquareRevokeAuthorizationMutationInput = {
  /** Fake argument. Only supplied because input objects must have at least one argument to be considered valid. */
  ignore: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SquareRevokeAuthorizationMutation */
export type SquareRevokeAuthorizationMutationPayload = {
  __typename?: 'SquareRevokeAuthorizationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  locations: Maybe<Array<Location>>;
  success: Scalars['Boolean'];
};

/** Time off blocked dates */
export type TimeOffBlockedDate = {
  __typename?: 'TimeOffBlockedDate';
  /** The date and time when the time off blocked dates was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** The end date of the time off blocked dates */
  endAt: Scalars['ISO8601Date'];
  /** The unique ID of the time off blocked dates */
  id: Scalars['ID'];
  /** If the location is closed */
  locationClosed: Scalars['Boolean'];
  /** The location time off blocked dates */
  locationTimeOffBlockedDates: Array<LocationTimeOffBlockedDate>;
  /** The locations */
  locations: Array<Location>;
  /** The name of the time off blocked dates */
  name: Scalars['String'];
  /** The unique ID of the organization */
  organizationId: Scalars['ID'];
  /** The start date of the time off blocked dates */
  startAt: Scalars['ISO8601Date'];
  /** If time off is blocked */
  timeOffBlocked: Scalars['Boolean'];
  /** The date and time when the time off blocked dates was last updated */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** An employee time off request */
export type TimeOffRequest = Record & {
  __typename?: 'TimeOffRequest';
  createdAt: Scalars['ISO8601DateTime'];
  employee: Employee;
  /** The unique, employee id */
  employeeId: Scalars['String'];
  /** The note the employee can leave for the manager */
  employeeNote: Maybe<Scalars['String']>;
  endAt: Scalars['ISO8601DateTime'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** If the request is for an entire day of Time Off */
  isAllDay: Scalars['Boolean'];
  /** The note the manager can leave for the employee */
  managerNote: Maybe<Scalars['String']>;
  /** The organization id */
  organizationId: Scalars['String'];
  /** The time the request was reviewed */
  reviewedAt: Maybe<Scalars['ISO8601DateTime']>;
  startAt: Scalars['ISO8601DateTime'];
  /** The request state (submitted, accepted, changes_requested) */
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  /** The GM who reviewed the request */
  user: Maybe<User>;
};

/** A time slot for a given employee availability day */
export type TimeSlot = Record & {
  __typename?: 'TimeSlot';
  createdAt: Scalars['ISO8601DateTime'];
  /** The unique days available id */
  daysAvailableId: Scalars['String'];
  /** End Time */
  endTime: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** Start time */
  startTime: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};


/** An event ocurring near a location */
export type TopEvent = {
  __typename?: 'TopEvent';
  /** The unique id */
  id: Scalars['ID'];
  /** Whether or not the event is a public holiday */
  isHoliday: Scalars['Boolean'];
  /** The event name */
  name: Scalars['String'];
  /** The event starting time */
  startAt: Scalars['ISO8601DateTime'];
};

/** Toucan Auth Token */
export type ToucanAuth = {
  __typename?: 'ToucanAuth';
  /** Toucan Auth Token */
  token: Scalars['String'];
};

/** Autogenerated input type of UpdateCovidDashboardMutation */
export type UpdateCovidDashboardMutationInput = {
  id: Scalars['ID'];
  addresses: Array<Scalars['String']>;
};

/** Autogenerated return type of UpdateCovidDashboardMutation */
export type UpdateCovidDashboardMutationPayload = {
  __typename?: 'UpdateCovidDashboardMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  covidDashboard: Maybe<CovidDashboard>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateEmployeeLocationsMutation */
export type UpdateEmployeeLocationsMutationInput = {
  employeeId: Scalars['ID'];
  employeeLocations: Array<Scalars['JSON']>;
};

/** Autogenerated return type of UpdateEmployeeLocationsMutation */
export type UpdateEmployeeLocationsMutationPayload = {
  __typename?: 'UpdateEmployeeLocationsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateEmployeeMinMaxHours */
export type UpdateEmployeeMinMaxHoursInput = {
  id: Scalars['ID'];
  minHours: Scalars['Float'];
  maxHours: Scalars['Float'];
};

/** Autogenerated return type of UpdateEmployeeMinMaxHours */
export type UpdateEmployeeMinMaxHoursPayload = {
  __typename?: 'UpdateEmployeeMinMaxHoursPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  employee: Maybe<Employee>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateEmployeeMutation */
export type UpdateEmployeeMutationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  availabilityRequestNotifications: Maybe<Scalars['String']>;
  scheduleNotifications: Maybe<Scalars['String']>;
  shiftSwapNotifications: Maybe<Scalars['String']>;
  timeZone: Maybe<Scalars['String']>;
  wage: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateEmployeeMutation */
export type UpdateEmployeeMutationPayload = {
  __typename?: 'UpdateEmployeeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  employee: Maybe<Employee>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateLocationMutation */
export type UpdateLocationMutationInput = {
  locationId: Scalars['ID'];
  laborPercentOfRevenue: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of UpdateLocationMutation */
export type UpdateLocationMutationPayload = {
  __typename?: 'UpdateLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  location: Location;
};

/** Autogenerated input type of UpdateLocationShiftRolesMutation */
export type UpdateLocationShiftRolesMutationInput = {
  ids: Array<Scalars['ID']>;
  active: Maybe<Scalars['Boolean']>;
  excludeFromLabor: Maybe<Scalars['Boolean']>;
  status: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateLocationShiftRolesMutation */
export type UpdateLocationShiftRolesMutationPayload = {
  __typename?: 'UpdateLocationShiftRolesMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateOperatingHoursMutation */
export type UpdateOperatingHoursMutationInput = {
  operatingHours: Array<OperatingHourAttributes>;
};

/** Autogenerated return type of UpdateOperatingHoursMutation */
export type UpdateOperatingHoursMutationPayload = {
  __typename?: 'UpdateOperatingHoursMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateOrganizationMutation */
export type UpdateOrganizationMutationInput = {
  id: Scalars['ID'];
  laborPercentOfRevenue: Maybe<Scalars['Float']>;
  overtimeHoursEmailOn: Maybe<Scalars['Boolean']>;
  overtimeHoursThreshold: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateOrganizationMutation */
export type UpdateOrganizationMutationPayload = {
  __typename?: 'UpdateOrganizationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  organization: Organization;
};

/** Autogenerated input type of UpdatePlanMutation */
export type UpdatePlanMutationInput = {
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  averageWage: Maybe<Scalars['Int']>;
  laborPercentOfRevenue: Maybe<Scalars['Float']>;
  notifyOnlyImpactedEmployees: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdatePlanMutation */
export type UpdatePlanMutationPayload = {
  __typename?: 'UpdatePlanMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

/** Autogenerated input type of UpdateShiftMutation */
export type UpdateShiftMutationInput = {
  id: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  isUnavailability: Scalars['Boolean'];
  shiftBreaks: Array<Scalars['Int']>;
  employeeId: Maybe<Scalars['ID']>;
  shiftRoleId: Maybe<Scalars['ID']>;
  notes: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateShiftRoleMutation */
export type UpdateShiftRoleMutationInput = {
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  organizationId: Maybe<Scalars['ID']>;
  shiftRoleCategoryId: Maybe<Scalars['ID']>;
  payRate: Maybe<Scalars['Float']>;
  payType: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateShiftRoleMutation */
export type UpdateShiftRoleMutationPayload = {
  __typename?: 'UpdateShiftRoleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  shiftRole: Maybe<ShiftRole>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateShiftSwapRequestMutation */
export type UpdateShiftSwapRequestMutationInput = {
  id: Scalars['ID'];
  transition: Scalars['String'];
  managerNote: Maybe<Scalars['String']>;
  newEmployeeId: Maybe<Scalars['ID']>;
  employeeNote: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateShiftSwapRequestMutation */
export type UpdateShiftSwapRequestMutationPayload = {
  __typename?: 'UpdateShiftSwapRequestMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  shiftSwapRequest: Maybe<ShiftSwapRequest>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateUserMutation */
export type UpdateUserMutationInput = {
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  organizationId: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateUserMutation */
export type UpdateUserMutationPayload = {
  __typename?: 'UpdateUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated input type of UpsertEmployeeAvailabilityMutation */
export type UpsertEmployeeAvailabilityMutationInput = {
  employeeId: Scalars['ID'];
  daysAvailable: Maybe<Scalars['JSON']>;
  employeeNote: Maybe<Scalars['String']>;
  managerNote: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

/** Autogenerated input type of UpsertForecastMutation */
export type UpsertForecastMutationInput = {
  locationId: Scalars['ID'];
  date: Scalars['ISO8601Date'];
  totalSales: Scalars['Int'];
};

/** Autogenerated return type of UpsertForecastMutation */
export type UpsertForecastMutationPayload = {
  __typename?: 'UpsertForecastMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  forecast: Forecast;
};

/** Autogenerated input type of UpsertInsightMutation */
export type UpsertInsightMutationInput = {
  locationId: Scalars['ID'];
  date: Scalars['ISO8601Date'];
  otherReasons: Maybe<Scalars['String']>;
  wasUnusual: Scalars['Boolean'];
  reasons: Maybe<Array<Scalars['String']>>;
  willHappenAgain: Maybe<WillHappenAgain>;
  affectedChannels: Maybe<Array<AffectedChannel>>;
};

/** Autogenerated return type of UpsertInsightMutation */
export type UpsertInsightMutationPayload = {
  __typename?: 'UpsertInsightMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  insight: Maybe<Insight>;
};

/** Autogenerated input type of UpsertLocationTimeOffBlockedDatesMutation */
export type UpsertLocationTimeOffBlockedDatesMutationInput = {
  timeOffBlockedDateId: Scalars['ID'];
  locationIdsToAdd: Maybe<Array<Scalars['ID']>>;
  locationIdsToRemove: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpsertLocationTimeOffBlockedDatesMutation */
export type UpsertLocationTimeOffBlockedDatesMutationPayload = {
  __typename?: 'UpsertLocationTimeOffBlockedDatesMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpsertSalesDayPartsMutation */
export type UpsertSalesDayPartsMutationInput = {
  locationId: Scalars['ID'];
  salesDayParts: Array<SalesDayPartArgument>;
};

/** Autogenerated return type of UpsertSalesDayPartsMutation */
export type UpsertSalesDayPartsMutationPayload = {
  __typename?: 'UpsertSalesDayPartsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpsertShiftRoleCategoryMutation */
export type UpsertShiftRoleCategoryMutationInput = {
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  organizationId: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpsertShiftRoleCategoryMutation */
export type UpsertShiftRoleCategoryMutationPayload = {
  __typename?: 'UpsertShiftRoleCategoryMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  shiftRoleCategory: Maybe<ShiftRoleCategory>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpsertTimeOffBlockedDateMutation */
export type UpsertTimeOffBlockedDateMutationInput = {
  id: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  startAt: Scalars['ISO8601Date'];
  endAt: Scalars['ISO8601Date'];
  timeOffBlocked: Scalars['Boolean'];
  locationClosed: Scalars['Boolean'];
  organizationId: Scalars['ID'];
};

/** Autogenerated return type of UpsertTimeOffBlockedDateMutation */
export type UpsertTimeOffBlockedDateMutationPayload = {
  __typename?: 'UpsertTimeOffBlockedDateMutationPayload';
  blockedDate: Maybe<TimeOffBlockedDate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  success: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpsertTimeOffRequestMutation */
export type UpsertTimeOffRequestMutationInput = {
  employeeId: Scalars['ID'];
  state: Scalars['String'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  id: Maybe<Scalars['ID']>;
  employeeNote: Maybe<Scalars['String']>;
  managerNote: Maybe<Scalars['String']>;
  isAllDay: Maybe<Scalars['Boolean']>;
};

/** A user */
export type User = Record & {
  __typename?: 'User';
  /** The organizations the user has access to */
  authorizedOrganizations: Array<Organization>;
  /** The user's avatar url */
  avatarUrl: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  /** The user's email */
  email: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The user's location names */
  locationNames: Array<Scalars['JSON']>;
  /** The user's Locations */
  locations: Array<Location>;
  /** The user's name */
  name: Scalars['String'];
  /** Indicates if a user is an observer */
  observer: Scalars['Boolean'];
  /** The user's organization */
  organization: Organization;
  /** The user's resolved permissions */
  permissions: Array<Permission>;
  /** The user's roles */
  roles: Array<Role>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A shift violation */
export type Violation = {
  __typename?: 'Violation';
  /** The actual for the current violation */
  actual: Scalars['Float'];
  /** The employee availabilities */
  availability: Maybe<EmployeeAvailability>;
  /** The ID of the violation */
  id: Scalars['ID'];
  /** The limit for the current violation */
  limit: Scalars['Float'];
  /** The name of the violation */
  name: ViolationName;
  /** The shifts in violation */
  shifts: Array<Shift>;
  /** The time off */
  timeOffRequests: Array<TimeOffRequest>;
};

export enum ViolationName {
  OverDaysWeek = 'overDaysWeek',
  UnderHoursBetweenShifts = 'underHoursBetweenShifts',
  OverHoursWeek = 'overHoursWeek',
  OverHoursDay = 'overHoursDay',
  OverlappingShift = 'overlappingShift',
  OverlappingUnavailability = 'overlappingUnavailability',
  ShiftDuringUnavailableHours = 'shiftDuringUnavailableHours',
  NeedsAvailabilitySubmitted = 'needsAvailabilitySubmitted',
  ShiftDuringUnavailableDay = 'shiftDuringUnavailableDay',
  ShiftDuringTimeOff = 'shiftDuringTimeOff',
  NoRestDays = 'noRestDays'
}

/** The current weather forecast at a location */
export type WeatherCurrent = {
  __typename?: 'WeatherCurrent';
  /** Weather condition code */
  conditionCode: Scalars['Int'];
  /** Weather condition name */
  conditionName: Scalars['String'];
  /** Weather icon code */
  iconCode: Scalars['String'];
  /** The location of the weather */
  location: Location;
  /** Temperature in Fahrenheit */
  temperatureFahrenheit: Scalars['Float'];
};

/** The daily weather (forecast or actual) for a location */
export type WeatherDaily = Record & {
  __typename?: 'WeatherDaily';
  /** Weather condition code */
  conditionCode: Scalars['Int'];
  /** Weather condition name */
  conditionName: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  /** The date of the weather */
  date: Scalars['ISO8601Date'];
  /** Weather icon code */
  iconCode: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  /** The location of the weather */
  location: Location;
  /** Temperature in Fahrenheit */
  temperatureFahrenheit: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum WillHappenAgain {
  YesThisTimeInTheNextFewWeeks = 'yes_this_time_in_the_next_few_weeks',
  YesThisTimeNextMonth = 'yes_this_time_next_month',
  YesThisTimeNextYear = 'yes_this_time_next_year',
  NoThisIsUnlikelyToHappenRegularly = 'no_this_is_unlikely_to_happen_regularly',
  NoThisIsUnlikelyToHappenAgain = 'no_this_is_unlikely_to_happen_again'
}

export type GetLocationMessagesQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetLocationMessagesQuery = (
  { __typename?: 'Query' }
  & { massNotifications: Array<(
    { __typename?: 'MassNotification' }
    & MassNotificationsFragment
  )> }
);

export type GetTimeOffBlockedDateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTimeOffBlockedDateQuery = (
  { __typename?: 'Query' }
  & { timeOffBlockedDate: Maybe<(
    { __typename?: 'TimeOffBlockedDate' }
    & TimeOffBlockedDateForFormFragment
  )> }
);

export type GetTimeOffBlockedDatesQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetTimeOffBlockedDatesQuery = (
  { __typename?: 'Query' }
  & { timeOffBlockedDates: Array<(
    { __typename?: 'TimeOffBlockedDate' }
    & TimeOffBlockedDateFragment
  )> }
);

export type GetEmployeeLocationsListingQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetEmployeeLocationsListingQuery = (
  { __typename?: 'Query' }
  & { employeeLocations: Array<(
    { __typename?: 'EmployeeLocation' }
    & EmployeeWithLocationStatusFragment
  )> }
);

export type GetEmployeeLocationQueryVariables = Exact<{
  employeeId: Scalars['ID'];
  locationId: Scalars['ID'];
}>;


export type GetEmployeeLocationQuery = (
  { __typename?: 'Query' }
  & { employeeLocation: Maybe<(
    { __typename?: 'EmployeeLocation' }
    & EmployeeLocationFragment
  )> }
);

export type GetEmployeeForEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEmployeeForEditQuery = (
  { __typename?: 'Query' }
  & { employee: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeForEditFragment
  )> }
);

export type GetFeatureTogglesQueryVariables = Exact<{
  actorType: Maybe<Scalars['String']>;
  actorId: Maybe<Scalars['ID']>;
}>;


export type GetFeatureTogglesQuery = (
  { __typename?: 'Query' }
  & { features: Maybe<(
    { __typename?: 'Features' }
    & { data: Array<(
      { __typename?: 'Feature' }
      & Pick<Feature, 'name' | 'active'>
    )> }
  )> }
);

export type GetLatestSuccessfulImportEventQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetLatestSuccessfulImportEventQuery = (
  { __typename?: 'Query' }
  & { importEvent: Maybe<(
    { __typename?: 'ImportEvent' }
    & ImportEventFragment
  )> }
);

export type GetInsightQueryVariables = Exact<{
  locationId: Scalars['ID'];
  date: Scalars['ISO8601Date'];
}>;


export type GetInsightQuery = (
  { __typename?: 'Query' }
  & { insight: Maybe<(
    { __typename?: 'Insight' }
    & InsightFragment
  )> }
);

export type GetIntervalOrderSumsQueryVariables = Exact<{
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  intervalMinutes: Scalars['Int'];
}>;


export type GetIntervalOrderSumsQuery = (
  { __typename?: 'Query' }
  & { intervalOrderSums: Array<(
    { __typename?: 'IntervalOrderSum' }
    & IntervalOrderSumFragment
  )> }
);

export type GetIntervalPredictionSumsQueryVariables = Exact<{
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  intervalMinutes: Scalars['Int'];
}>;


export type GetIntervalPredictionSumsQuery = (
  { __typename?: 'Query' }
  & { intervalPredictionSums: Array<(
    { __typename?: 'IntervalPredictionSum' }
    & IntervalPredictionSumFragment
  )> }
);

export type GetDailyHistoricalPredictionsQueryVariables = Exact<{
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
}>;


export type GetDailyHistoricalPredictionsQuery = (
  { __typename?: 'Query' }
  & { dailyHistoricalPredictions0: Array<(
    { __typename?: 'IntervalPredictionSum' }
    & IntervalPredictionSumFragment
  )>, dailyHistoricalPredictions7: Array<(
    { __typename?: 'IntervalPredictionSum' }
    & IntervalPredictionSumFragment
  )>, dailyHistoricalPredictions14: Array<(
    { __typename?: 'IntervalPredictionSum' }
    & IntervalPredictionSumFragment
  )> }
);

export type GetLocationForScheduleQueryVariables = Exact<{
  id: Scalars['ID'];
  startOnLastWeek: Scalars['ISO8601Date'];
  endOnLastWeek: Scalars['ISO8601Date'];
}>;


export type GetLocationForScheduleQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'categories' | 'name' | 'salesType' | 'fiscalWeekOffsetFromMonday' | 'planForecastEnabled' | 'tripleseatEnabled' | 'autoSchedulingEnabled' | 'reservationsImporterType'>
    & { shiftRoles: Array<(
      { __typename?: 'ShiftRole' }
      & ShiftRoleFragment
    )>, employees: Array<(
      { __typename?: 'Employee' }
      & EmployeeForScheduleFragment
    )>, shiftsLastWeek: Array<(
      { __typename?: 'Shift' }
      & Pick<Shift, 'id'>
    )>, minimumLaborBaselines: Maybe<Array<(
      { __typename?: 'MinimumLaborBaseline' }
      & MinimumLaborBaselineFragment
    )>> }
  ) }
);

export type GetLocationForModifyShiftQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLocationForModifyShiftQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'Location' }
    & LocationForModifyShiftFragment
  ) }
);

export type GetLocationManagementRequestsQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetLocationManagementRequestsQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'Location' }
    & LocationManagementRequestsFragment
  ) }
);

export type GetLocationShiftRolesQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetLocationShiftRolesQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'Location' }
    & LocationShiftRolesFragment
  ) }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & CurrentUserInfoFragment
  )> }
);

export type GetUsersForLocationQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetUsersForLocationQuery = (
  { __typename?: 'Query' }
  & { usersForLocation: Array<(
    { __typename?: 'User' }
    & LocationUserSummaryFragment
  )> }
);

export type GetOperatingHoursQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetOperatingHoursQuery = (
  { __typename?: 'Query' }
  & { operatingHours: Maybe<Array<(
    { __typename?: 'OperatingHours' }
    & OperatingHoursFragment
  )>> }
);

export type GetOperatingHoursForDateQueryVariables = Exact<{
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  endOn: Maybe<Scalars['ISO8601Date']>;
}>;


export type GetOperatingHoursForDateQuery = (
  { __typename?: 'Query' }
  & { operatingHoursForDate: Maybe<Array<(
    { __typename?: 'OperatingHours' }
    & OperatingHoursForDateFragment
  )>> }
);

export type GetLocationShiftRoleActiveQueryVariables = Exact<{
  locationId: Scalars['ID'];
  shiftRoleId: Scalars['ID'];
}>;


export type GetLocationShiftRoleActiveQuery = (
  { __typename?: 'Query' }
  & { locationShiftRole: (
    { __typename?: 'LocationShiftRoles' }
    & ActiveLocationShiftRoleFragment
  ) }
);

export type GetLocationShiftRolesActiveQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetLocationShiftRolesActiveQuery = (
  { __typename?: 'Query' }
  & { locationShiftRoles: Array<(
    { __typename?: 'LocationShiftRoles' }
    & ActiveLocationShiftRoleFragment
  )> }
);

export type GetToucanAuthQueryVariables = Exact<{
  startDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  locationIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetToucanAuthQuery = (
  { __typename?: 'Query' }
  & { toucanAuth: Maybe<(
    { __typename?: 'ToucanAuth' }
    & ToucanAuthFragment
  )> }
);

export type GetPlanQueryVariables = Exact<{
  endOn: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
}>;


export type GetPlanQuery = (
  { __typename?: 'Query' }
  & { plan: (
    { __typename?: 'Plan' }
    & PlanFragment
  ) }
);

export type GetPlanForReportingQueryVariables = Exact<{
  endOn: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
}>;


export type GetPlanForReportingQuery = (
  { __typename?: 'Query' }
  & { plan: (
    { __typename?: 'Plan' }
    & PlanForReportingFragment
  ) }
);

export type GetLaborDollarsQueryVariables = Exact<{
  planId: Scalars['ID'];
  locationId: Scalars['ID'];
}>;


export type GetLaborDollarsQuery = (
  { __typename?: 'Query' }
  & { laborDollars: Maybe<(
    { __typename?: 'LaborDollars' }
    & Pick<LaborDollars, 'totalCents'>
    & { daily: Array<(
      { __typename?: 'LaborDollarsDaily' }
      & LaborDollarsDailyFragment
    )> }
  )> }
);

export type GetTopEventsQueryVariables = Exact<{
  endOn: Maybe<Scalars['ISO8601Date']>;
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
}>;


export type GetTopEventsQuery = (
  { __typename?: 'Query' }
  & { topEvents: Array<(
    { __typename?: 'TopEvent' }
    & TopEventFragment
  )> }
);

export type GetWeeklyPlansQueryVariables = Exact<{
  endOn: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
}>;


export type GetWeeklyPlansQuery = (
  { __typename?: 'Query' }
  & { weeklyPlans: Array<(
    { __typename?: 'Plan' }
    & PlanFragment
  )> }
);

export type GetForecastedItemsQueryVariables = Exact<{
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  valueBy: ForecastedItemsValueBy;
}>;


export type GetForecastedItemsQuery = (
  { __typename?: 'Query' }
  & { forecastedItems: (
    { __typename?: 'ItemsForecast' }
    & ItemsForecastFragment
  ) }
);

export type GetWeeklyPlanSummariesQueryVariables = Exact<{
  endOn: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
}>;


export type GetWeeklyPlanSummariesQuery = (
  { __typename?: 'Query' }
  & { weeklyPlans: Array<(
    { __typename?: 'Plan' }
    & PlanSummaryLightFragment
  )> }
);

export type GetWeeklyPlanSummariesWithDailySummariesQueryVariables = Exact<{
  endOn: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
}>;


export type GetWeeklyPlanSummariesWithDailySummariesQuery = (
  { __typename?: 'Query' }
  & { weeklyPlans: Array<(
    { __typename?: 'Plan' }
    & PlanSummaryWithDailySummariesFragment
  )> }
);

export type GetWeatherCurrentQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetWeatherCurrentQuery = (
  { __typename?: 'Query' }
  & { weatherCurrent: Maybe<(
    { __typename?: 'WeatherCurrent' }
    & Pick<WeatherCurrent, 'temperatureFahrenheit' | 'conditionCode' | 'conditionName' | 'iconCode'>
  )> }
);

export type GetWeatherDailyQueryVariables = Exact<{
  locationId: Scalars['ID'];
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
}>;


export type GetWeatherDailyQuery = (
  { __typename?: 'Query' }
  & { weatherDaily: Maybe<Array<(
    { __typename?: 'WeatherDaily' }
    & Pick<WeatherDaily, 'id' | 'temperatureFahrenheit' | 'conditionCode' | 'conditionName' | 'date' | 'iconCode'>
  )>> }
);

export type GetLocationsForReportingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsForReportingQuery = (
  { __typename?: 'Query' }
  & { locationsForReporting: Array<(
    { __typename?: 'Location' }
    & LocationForReportingFragment
  )> }
);

export type PreviewViolationsQueryVariables = Exact<{
  id: Maybe<Scalars['ID']>;
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  employeeId: Scalars['ID'];
  shiftRoleId: Maybe<Scalars['ID']>;
  shiftBreaks: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  isUnavailability: Scalars['Boolean'];
}>;


export type PreviewViolationsQuery = (
  { __typename?: 'Query' }
  & { previewViolations: Array<(
    { __typename?: 'Violation' }
    & ViolationFragment
  )> }
);

export type GetViolationsPlanQueryVariables = Exact<{
  planId: Scalars['ID'];
  locationId: Scalars['ID'];
}>;


export type GetViolationsPlanQuery = (
  { __typename?: 'Query' }
  & { violationsPlan: Array<(
    { __typename?: 'Violation' }
    & ViolationFragment
  )> }
);

export type GetCovidDashboardQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCovidDashboardQuery = (
  { __typename?: 'Query' }
  & { covidDashboard: Maybe<(
    { __typename?: 'CovidDashboard' }
    & CovidDashboardFragment
  )> }
);

export type GetPlanTemplatesQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetPlanTemplatesQuery = (
  { __typename?: 'Query' }
  & { planTemplates: Array<(
    { __typename?: 'PlanTemplate' }
    & PlanTemplateFragment
  )> }
);

export type GetOAuthQueryVariables = Exact<{
  posType: Scalars['String'];
}>;


export type GetOAuthQuery = (
  { __typename?: 'Query' }
  & { oauthAuthorization: (
    { __typename?: 'OauthAuthorization' }
    & Pick<OauthAuthorization, 'url'>
  ) }
);

export type GetDailyLaborHoursBreakDownQueryVariables = Exact<{
  locationId: Scalars['ID'];
  day: Scalars['ISO8601Date'];
}>;


export type GetDailyLaborHoursBreakDownQuery = (
  { __typename?: 'Query' }
  & { laborHoursDailyBreakdown: (
    { __typename?: 'LaborHoursDaily' }
    & Pick<LaborHoursDaily, 'totalLaborHoursExcluded' | 'totalLaborHours' | 'totalPlannedHours' | 'totalPlannedHoursExcluded'>
    & { laborHourEmployees: Array<(
      { __typename?: 'LaborHourEmployee' }
      & Pick<LaborHourEmployee, 'hours' | 'plannedHours'>
      & { shifts: Array<(
        { __typename?: 'Shift' }
        & Pick<Shift, 'id' | 'startAt' | 'endAt'>
      )>, employee: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      ), shiftRoles: Array<(
        { __typename?: 'ShiftRole' }
        & Pick<ShiftRole, 'id' | 'name'>
      )> }
    )>, excludeFromLabor: Array<(
      { __typename?: 'LaborHourEmployee' }
      & Pick<LaborHourEmployee, 'hours' | 'plannedHours'>
      & { shifts: Array<(
        { __typename?: 'Shift' }
        & Pick<Shift, 'id' | 'startAt' | 'endAt'>
      )>, employee: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      ), shiftRoles: Array<(
        { __typename?: 'ShiftRole' }
        & Pick<ShiftRole, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type GetHoursDateRangeBreakDownQueryVariables = Exact<{
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601Date'];
  endAt: Scalars['ISO8601Date'];
}>;


export type GetHoursDateRangeBreakDownQuery = (
  { __typename?: 'Query' }
  & { laborHoursDateRangeBreakdown: (
    { __typename?: 'LaborHoursDateRange' }
    & Pick<LaborHoursDateRange, 'totalWeeklyPlannedHours' | 'totalWeeklyHours' | 'totalWeeklyHoursExcluded' | 'totalWeeklyPlannedHoursExcluded'>
    & { dailyBreakDowns: Array<(
      { __typename?: 'LaborHoursDateRangeDay' }
      & Pick<LaborHoursDateRangeDay, 'day'>
      & { dailyBreakDown: (
        { __typename?: 'LaborHoursDaily' }
        & Pick<LaborHoursDaily, 'totalLaborHoursExcluded' | 'totalLaborHours' | 'totalPlannedHours' | 'totalPlannedHoursExcluded'>
        & { excludeFromLabor: Array<(
          { __typename?: 'LaborHourEmployee' }
          & Pick<LaborHourEmployee, 'plannedHours' | 'hours'>
          & { shifts: Array<(
            { __typename?: 'Shift' }
            & Pick<Shift, 'id' | 'startAt' | 'endAt'>
          )>, shiftRoles: Array<(
            { __typename?: 'ShiftRole' }
            & Pick<ShiftRole, 'id' | 'name'>
          )>, employee: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'id' | 'name'>
          ) }
        )>, laborHourEmployees: Array<(
          { __typename?: 'LaborHourEmployee' }
          & Pick<LaborHourEmployee, 'plannedHours' | 'hours'>
          & { shifts: Array<(
            { __typename?: 'Shift' }
            & Pick<Shift, 'id' | 'startAt' | 'endAt'>
          )>, shiftRoles: Array<(
            { __typename?: 'ShiftRole' }
            & Pick<ShiftRole, 'id' | 'name'>
          )>, employee: (
            { __typename?: 'Employee' }
            & Pick<Employee, 'id' | 'name'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type GetRelevantShiftsEmployeeQueryVariables = Exact<{
  planId: Scalars['ID'];
  locationId: Scalars['ID'];
}>;


export type GetRelevantShiftsEmployeeQuery = (
  { __typename?: 'Query' }
  & { relevantShiftsForPlan: Array<(
    { __typename?: 'Employee' }
    & EmployeeShiftsFragment
  )> }
);

export type GetInternalEventsQueryVariables = Exact<{
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
}>;


export type GetInternalEventsQuery = (
  { __typename?: 'Query' }
  & { internalEvents: Maybe<Array<(
    { __typename?: 'InternalEvent' }
    & Pick<InternalEvent, 'id' | 'eventStartDate' | 'eventStartTime' | 'eventEndTime' | 'guestCount' | 'foodAndBeverageMinDollars' | 'rooms' | 'status' | 'externalId' | 'startAtTimeWithSetupTime' | 'endAtTimeWithTeardownTime'>
  )>> }
);

export type GetSalesDayPartsQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetSalesDayPartsQuery = (
  { __typename?: 'Query' }
  & { salesDayParts: Array<(
    { __typename?: 'SalesDayPart' }
    & SalesDayPartFragment
  )> }
);

export type GetReservationsQueryVariables = Exact<{
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  locationId: Scalars['ID'];
}>;


export type GetReservationsQuery = (
  { __typename?: 'Query' }
  & { reservations: Array<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'fiscalDate' | 'largeParty' | 'scheduledTimeLocalFormatted' | 'partySize' | 'scheduledTime' | 'state' | 'reservationUrl'>
  )> }
);

export type GetShiftsPerLocationsQueryVariables = Exact<{
  locationIds: Array<Scalars['ID']> | Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
}>;


export type GetShiftsPerLocationsQuery = (
  { __typename?: 'Query' }
  & { shiftsPerLocations: Array<(
    { __typename?: 'Shift' }
    & Pick<Shift, 'id'>
  )> }
);

export type UpsertTimeOffBlockedDateMutationVariables = Exact<{
  input: UpsertTimeOffBlockedDateMutationInput;
}>;


export type UpsertTimeOffBlockedDateMutation = (
  { __typename?: 'Mutation' }
  & { upsertTimeOffBlockedDate: (
    { __typename?: 'UpsertTimeOffBlockedDateMutationPayload' }
    & Pick<UpsertTimeOffBlockedDateMutationPayload, 'success'>
    & { blockedDate: Maybe<(
      { __typename?: 'TimeOffBlockedDate' }
      & TimeOffBlockedDateFragment
    )> }
  ) }
);

export type DeleteTimeOffBlockedDateMutationVariables = Exact<{
  input: DeleteTimeOffBlockedDateMutationInput;
}>;


export type DeleteTimeOffBlockedDateMutation = (
  { __typename?: 'Mutation' }
  & { deleteTimeOffBlockedDate: (
    { __typename?: 'DeleteTimeOffBlockedDateMutationPayload' }
    & Pick<DeleteTimeOffBlockedDateMutationPayload, 'success'>
  ) }
);

export type UpsertLocationTimeOffBlockedDatesMutationVariables = Exact<{
  input: UpsertLocationTimeOffBlockedDatesMutationInput;
}>;


export type UpsertLocationTimeOffBlockedDatesMutation = (
  { __typename?: 'Mutation' }
  & { upsertLocationTimeOffBlockedDates: (
    { __typename?: 'UpsertLocationTimeOffBlockedDatesMutationPayload' }
    & Pick<UpsertLocationTimeOffBlockedDatesMutationPayload, 'success'>
  ) }
);

export type UpsertSalesDayPartsMutationVariables = Exact<{
  input: UpsertSalesDayPartsMutationInput;
}>;


export type UpsertSalesDayPartsMutation = (
  { __typename?: 'Mutation' }
  & { upsertSalesDayParts: Maybe<(
    { __typename?: 'UpsertSalesDayPartsMutationPayload' }
    & Pick<UpsertSalesDayPartsMutationPayload, 'success'>
  )> }
);

export type DeleteSalesDayPartsMutationVariables = Exact<{
  input: DeleteSalesDayPartsMutationInput;
}>;


export type DeleteSalesDayPartsMutation = (
  { __typename?: 'Mutation' }
  & { deleteSalesDayParts: Maybe<(
    { __typename?: 'DeleteSalesDayPartsMutationPayload' }
    & Pick<DeleteSalesDayPartsMutationPayload, 'success'>
  )> }
);

export type CreateEmployeeMutationVariables = Exact<{
  input: CreateEmployeeMutationInput;
}>;


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createEmployee: (
    { __typename?: 'CreateEmployeeMutationPayload' }
    & Pick<CreateEmployeeMutationPayload, 'success' | 'errors'>
    & { employee: Maybe<(
      { __typename?: 'Employee' }
      & EmployeeFragment
    )> }
  ) }
);

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeMutationInput;
}>;


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee: (
    { __typename?: 'UpdateEmployeeMutationPayload' }
    & Pick<UpdateEmployeeMutationPayload, 'success' | 'errors'>
    & { employee: Maybe<(
      { __typename?: 'Employee' }
      & EmployeeFragment
    )> }
  ) }
);

export type UpdateEmployeeMinMaxHoursMutationVariables = Exact<{
  input: UpdateEmployeeMinMaxHoursInput;
}>;


export type UpdateEmployeeMinMaxHoursMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployeeMinMaxHours: (
    { __typename?: 'UpdateEmployeeMinMaxHoursPayload' }
    & Pick<UpdateEmployeeMinMaxHoursPayload, 'success' | 'errors'>
    & { employee: Maybe<(
      { __typename?: 'Employee' }
      & EmployeeFragment
    )> }
  ) }
);

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationMutationInput;
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization: (
    { __typename?: 'UpdateOrganizationMutationPayload' }
    & { organization: (
      { __typename?: 'Organization' }
      & OrganizationSettingsFragment
    ) }
  ) }
);

export type UpdateLocationMutationVariables = Exact<{
  input: UpdateLocationMutationInput;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation: (
    { __typename?: 'UpdateLocationMutationPayload' }
    & { location: (
      { __typename?: 'Location' }
      & LocationSummaryFragment
    ) }
  ) }
);

export type UpdateEmployeeLocationsMutationVariables = Exact<{
  input: UpdateEmployeeLocationsMutationInput;
}>;


export type UpdateEmployeeLocationsMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployeeLocation: (
    { __typename?: 'UpdateEmployeeLocationsMutationPayload' }
    & Pick<UpdateEmployeeLocationsMutationPayload, 'success' | 'errors'>
  ) }
);

export type DeleteEmployeeLocationsMutationVariables = Exact<{
  input: DeleteEmployeeLocationsMutationInput;
}>;


export type DeleteEmployeeLocationsMutation = (
  { __typename?: 'Mutation' }
  & { deleteEmployeeLocations: (
    { __typename?: 'DeleteEmployeeLocationsMutationPayload' }
    & Pick<DeleteEmployeeLocationsMutationPayload, 'success' | 'errors'>
    & { employeeLocations: Maybe<Array<(
      { __typename?: 'EmployeeLocation' }
      & EmployeeLocationFragment
    )>> }
  ) }
);

export type SquareRevokeAuthorizationMutationVariables = Exact<{ [key: string]: never; }>;


export type SquareRevokeAuthorizationMutation = (
  { __typename?: 'Mutation' }
  & { squareRevokeAuthorization: (
    { __typename?: 'SquareRevokeAuthorizationMutationPayload' }
    & Pick<SquareRevokeAuthorizationMutationPayload, 'success'>
  ) }
);

export type SquareHistoricImportMutationVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type SquareHistoricImportMutation = (
  { __typename?: 'Mutation' }
  & { squareHistoricImport: (
    { __typename?: 'SquareHistoricImportMutationPayload' }
    & { location: (
      { __typename?: 'Location' }
      & LocationFragment
    ) }
  ) }
);

export type CreateSessionMutationVariables = Exact<{
  input: LoginMutationInput;
}>;


export type CreateSessionMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginMutationPayload' }
    & Pick<LoginMutationPayload, 'success' | 'errorCode'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & CurrentUserInfoFragment
    )> }
  ) }
);

export type DeleteForecastMutationVariables = Exact<{
  input: DeleteForecastMutationInput;
}>;


export type DeleteForecastMutation = (
  { __typename?: 'Mutation' }
  & { deleteForecast: (
    { __typename?: 'DeleteForecastMutationPayload' }
    & Pick<DeleteForecastMutationPayload, 'success'>
  ) }
);

export type DeleteSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteSessionMutation = (
  { __typename?: 'Mutation' }
  & { logout: (
    { __typename?: 'LogoutMutationPayload' }
    & Pick<LogoutMutationPayload, 'success'>
  ) }
);

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { requestPasswordReset: (
    { __typename?: 'RequestPasswordResetMutationPayload' }
    & Pick<RequestPasswordResetMutationPayload, 'success'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordToken: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ResetPasswordMutationPayload' }
    & Pick<ResetPasswordMutationPayload, 'success' | 'errorCode'>
  ) }
);

export type UpsertForecastMutationVariables = Exact<{
  input: UpsertForecastMutationInput;
}>;


export type UpsertForecastMutation = (
  { __typename?: 'Mutation' }
  & { upsertForecast: (
    { __typename?: 'UpsertForecastMutationPayload' }
    & { forecast: (
      { __typename?: 'Forecast' }
      & ForecastFragment
    ) }
  ) }
);

export type UpsertInsightMutationVariables = Exact<{
  input: UpsertInsightMutationInput;
}>;


export type UpsertInsightMutation = (
  { __typename?: 'Mutation' }
  & { upsertInsight: (
    { __typename?: 'UpsertInsightMutationPayload' }
    & { insight: Maybe<(
      { __typename?: 'Insight' }
      & InsightFragment
    )> }
  ) }
);

export type UpdatePlanMutationVariables = Exact<{
  input: UpdatePlanMutationInput;
}>;


export type UpdatePlanMutation = (
  { __typename?: 'Mutation' }
  & { updatePlan: (
    { __typename?: 'UpdatePlanMutationPayload' }
    & Pick<UpdatePlanMutationPayload, 'state'>
  ) }
);

export type ClearPlanShiftsMutationVariables = Exact<{
  input: ClearPlanShiftsMutationInput;
}>;


export type ClearPlanShiftsMutation = (
  { __typename?: 'Mutation' }
  & { clearPlanShifts: (
    { __typename?: 'ClearPlanShiftsMutationPayload' }
    & Pick<ClearPlanShiftsMutationPayload, 'success'>
  ) }
);

export type ResetPlanStateMutationVariables = Exact<{
  input: ResetPlanStateMutationInput;
}>;


export type ResetPlanStateMutation = (
  { __typename?: 'Mutation' }
  & { resetPlanState: (
    { __typename?: 'ResetPlanStateMutationPayload' }
    & Pick<ResetPlanStateMutationPayload, 'state'>
  ) }
);

export type CreateShiftMutationVariables = Exact<{
  input: CreateShiftMutationInput;
}>;


export type CreateShiftMutation = (
  { __typename?: 'Mutation' }
  & { createShift: (
    { __typename?: 'Shift' }
    & ShiftFragment
  ) }
);

export type UpdateShiftMutationVariables = Exact<{
  input: UpdateShiftMutationInput;
}>;


export type UpdateShiftMutation = (
  { __typename?: 'Mutation' }
  & { updateShift: (
    { __typename?: 'Shift' }
    & ShiftFragment
  ) }
);

export type DeleteShiftMutationVariables = Exact<{
  input: DeleteShiftMutationInput;
}>;


export type DeleteShiftMutation = (
  { __typename?: 'Mutation' }
  & { deleteShift: (
    { __typename?: 'DeleteShiftMutationPayload' }
    & Pick<DeleteShiftMutationPayload, 'success'>
  ) }
);

export type CopyLastWeekShiftsMutationVariables = Exact<{
  input: CopyLastWeekShiftsMutationInput;
}>;


export type CopyLastWeekShiftsMutation = (
  { __typename?: 'Mutation' }
  & { copyLastWeekShifts: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) }
);

export type CreateAutoScheduleMutationVariables = Exact<{
  input: CreateAutoScheduleMutationInput;
}>;


export type CreateAutoScheduleMutation = (
  { __typename?: 'Mutation' }
  & { createAutoSchedule: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) }
);

export type CreateCovidDashboardMutationVariables = Exact<{
  input: CreateCovidDashboardMutationInput;
}>;


export type CreateCovidDashboardMutation = (
  { __typename?: 'Mutation' }
  & { createCovidDashboard: (
    { __typename?: 'CovidDashboard' }
    & CovidDashboardFragment
  ) }
);

export type UpdateCovidDashboardMutationVariables = Exact<{
  input: UpdateCovidDashboardMutationInput;
}>;


export type UpdateCovidDashboardMutation = (
  { __typename?: 'Mutation' }
  & { updateCovidDashboard: (
    { __typename?: 'UpdateCovidDashboardMutationPayload' }
    & Pick<UpdateCovidDashboardMutationPayload, 'success' | 'errors'>
    & { covidDashboard: Maybe<(
      { __typename?: 'CovidDashboard' }
      & CovidDashboardFragment
    )> }
  ) }
);

export type CreatePlanTemplateMutationVariables = Exact<{
  input: CreatePlanTemplateMutationInput;
}>;


export type CreatePlanTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createPlanTemplate: (
    { __typename?: 'CreatePlanTemplateMutationPayload' }
    & Pick<CreatePlanTemplateMutationPayload, 'success' | 'errors'>
    & { planTemplate: Maybe<(
      { __typename?: 'PlanTemplate' }
      & PlanTemplateFragment
    )> }
  ) }
);

export type CreateWeeklyPlanExportMutationVariables = Exact<{
  input: CreateWeeklyPlanExportMutationInput;
}>;


export type CreateWeeklyPlanExportMutation = (
  { __typename?: 'Mutation' }
  & { createWeeklyPlanExportMutation: Maybe<(
    { __typename?: 'CreateWeeklyPlanExportMutationPayload' }
    & Pick<CreateWeeklyPlanExportMutationPayload, 'url'>
  )> }
);

export type OnboardingOAuthMutationVariables = Exact<{
  input: OnboardingOauthMutationInput;
}>;


export type OnboardingOAuthMutation = (
  { __typename?: 'Mutation' }
  & { onboardingOauth: (
    { __typename?: 'OnboardingOauthMutationPayload' }
    & Pick<OnboardingOauthMutationPayload, 'success'>
    & { locations: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'address' | 'name' | 'status'>
    )>> }
  ) }
);

export type CopyShiftsFromPlanTemplateMutationVariables = Exact<{
  input: CopyShiftsFromPlanTemplateMutationInput;
}>;


export type CopyShiftsFromPlanTemplateMutation = (
  { __typename?: 'Mutation' }
  & { copyShiftsFromPlanTemplate: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) }
);

export type ApproveManagementRequestMutationVariables = Exact<{
  input: ApproveManagementRequestMutationInput;
}>;


export type ApproveManagementRequestMutation = (
  { __typename?: 'Mutation' }
  & { approveManagementRequest: (
    { __typename?: 'ApproveManagementRequestMutationPayload' }
    & Pick<ApproveManagementRequestMutationPayload, 'success'>
  ) }
);

export type SignupMutationVariables = Exact<{
  input: SignupMutationInput;
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'SignupMutationPayload' }
    & Pick<SignupMutationPayload, 'success' | 'errorCode'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & CurrentUserInfoFragment
    )> }
  ) }
);

export type CreateMassNotificationMutationVariables = Exact<{
  input: CreateMassNotificationMutationInput;
}>;


export type CreateMassNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createMassNotification: Maybe<(
    { __typename?: 'CreateMassNotificationMutationPayload' }
    & Pick<CreateMassNotificationMutationPayload, 'success' | 'errors'>
    & { massNotification: Maybe<(
      { __typename?: 'MassNotification' }
      & MassNotificationsFragment
    )> }
  )> }
);

export type UpsertEmployeeAvailabilityMutationVariables = Exact<{
  input: UpsertEmployeeAvailabilityMutationInput;
}>;


export type UpsertEmployeeAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { upsertEmployeeAvailability: (
    { __typename?: 'EmployeeAvailability' }
    & Pick<EmployeeAvailability, 'state' | 'employeeId' | 'employeeNote' | 'managerNote' | 'organizationId'>
    & { daysAvailable: Maybe<Array<(
      { __typename?: 'DaysAvailable' }
      & DaysAvailableFragment
    )>> }
  ) }
);

export type UpdateOperatingHoursMutationVariables = Exact<{
  input: UpdateOperatingHoursMutationInput;
}>;


export type UpdateOperatingHoursMutation = (
  { __typename?: 'Mutation' }
  & { updateOperatingHours: (
    { __typename?: 'UpdateOperatingHoursMutationPayload' }
    & Pick<UpdateOperatingHoursMutationPayload, 'success' | 'errors'>
  ) }
);

export type ResetDemoLocationMutationVariables = Exact<{
  input: ResetDemoLocationMutationInput;
}>;


export type ResetDemoLocationMutation = (
  { __typename?: 'Mutation' }
  & { resetDemoLocation: (
    { __typename?: 'ResetDemoLocationMutationPayload' }
    & Pick<ResetDemoLocationMutationPayload, 'success'>
  ) }
);

export type ShiftBreakFragment = (
  { __typename?: 'ShiftBreak' }
  & Pick<ShiftBreak, 'id' | 'shiftId' | 'durationMins'>
);

export type EmployeeLocationFragment = (
  { __typename?: 'EmployeeLocation' }
  & Pick<EmployeeLocation, 'id' | 'locationId' | 'employeeId' | 'externalId' | 'integrationSettings' | 'active' | 'discardedAt' | 'excludeFromLabor'>
);

export type EmployeeForEditFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'email' | 'id' | 'name' | 'phone' | 'wage' | 'minHours' | 'maxHours' | 'confirmedHoursSubmittedAt' | 'confirmedHoursSubmittedBy'>
  & { locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'autoSchedulingEnabled'>
    & { operatingHours: Array<(
      { __typename?: 'OperatingHours' }
      & Pick<OperatingHours, 'scheduleBeginIso8601' | 'scheduleEndIso8601' | 'day'>
    )> }
  )>, employeeLocations: Maybe<Array<(
    { __typename?: 'EmployeeLocation' }
    & Pick<EmployeeLocation, 'id' | 'active' | 'excludeFromLabor' | 'locationId' | 'discardedAt'>
  )>>, availability: Maybe<(
    { __typename?: 'EmployeeAvailability' }
    & EmployeeAvailabilityFragment
  )> }
);

export type EmployeeForScheduleFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name' | 'wage'>
  & { shiftRoles: Maybe<Array<(
    { __typename?: 'ShiftRole' }
    & Pick<ShiftRole, 'id' | 'shiftRoleCategoryId' | 'name' | 'externalId' | 'payRate'>
  )>>, employeeLocations: Maybe<Array<(
    { __typename?: 'EmployeeLocation' }
    & Pick<EmployeeLocation, 'id' | 'active' | 'locationId' | 'excludeFromLabor' | 'integrationSettings'>
  )>>, timeOffRequests: Array<(
    { __typename?: 'TimeOffRequest' }
    & TimeOffRequestSummaryFragment
  )>, availability: Maybe<(
    { __typename?: 'EmployeeAvailability' }
    & EmployeeAvailabilityFragment
  )> }
);

export type EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'avatarUrl' | 'email' | 'id' | 'name' | 'phone' | 'wage'>
  & { shiftRoles: Maybe<Array<(
    { __typename?: 'ShiftRole' }
    & ShiftRoleFragment
  )>>, availability: Maybe<(
    { __typename?: 'EmployeeAvailability' }
    & EmployeeAvailabilityFragment
  )>, timeOffRequests: Array<(
    { __typename?: 'TimeOffRequest' }
    & TimeOffRequestFragment
  )>, employeeLocations: Maybe<Array<(
    { __typename?: 'EmployeeLocation' }
    & Pick<EmployeeLocation, 'id' | 'employeeId' | 'locationId' | 'integrationSettings' | 'active' | 'excludeFromLabor'>
  )>>, locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'status'>
  )> }
);

export type EmployeeWithLocationStatusFragment = (
  { __typename?: 'EmployeeLocation' }
  & Pick<EmployeeLocation, 'id' | 'active' | 'excludeFromLabor'>
  & { employee: (
    { __typename?: 'Employee' }
    & EmployeeListingFragment
  ) }
);

export type EmployeeShiftsFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'email' | 'id' | 'name' | 'phone'>
  & { shifts: Maybe<Array<(
    { __typename?: 'Shift' }
    & { shiftRole: Maybe<(
      { __typename?: 'ShiftRole' }
      & Pick<ShiftRole, 'id' | 'name'>
    )> }
    & ShiftSummaryFragment
  )>> }
);

export type EmployeeListingFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name' | 'email' | 'phone'>
  & { locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )>, employeeLocations: Maybe<Array<(
    { __typename?: 'EmployeeLocation' }
    & Pick<EmployeeLocation, 'id' | 'active' | 'excludeFromLabor' | 'locationId'>
  )>>, availability: Maybe<(
    { __typename?: 'EmployeeAvailability' }
    & EmployeeAvailabilitySummaryFragment
  )> }
);

export type ForecastFragment = (
  { __typename?: 'Forecast' }
  & Pick<Forecast, 'date' | 'laborHours' | 'id' | 'totalSales'>
);

export type ItemForecastDayFragment = (
  { __typename?: 'ItemForecastDay' }
  & Pick<ItemForecastDay, 'category' | 'name' | 'date' | 'value'>
);

export type ItemsForecastFragment = (
  { __typename?: 'ItemsForecast' }
  & Pick<ItemsForecast, 'valueBy' | 'updatedAt'>
  & { items: Array<(
    { __typename?: 'ItemForecastDay' }
    & ItemForecastDayFragment
  )> }
);

export type LaborDollarsDailyFragment = (
  { __typename?: 'LaborDollarsDaily' }
  & Pick<LaborDollarsDaily, 'cents' | 'day'>
);

export type ImportEventFragment = (
  { __typename?: 'ImportEvent' }
  & Pick<ImportEvent, 'id' | 'completedAt'>
);

export type InsightFragment = (
  { __typename?: 'Insight' }
  & Pick<Insight, 'date' | 'id' | 'otherReasons' | 'wasUnusual' | 'reasons' | 'affectedChannels' | 'willHappenAgain'>
);

export type IntervalOrderSumFragment = (
  { __typename?: 'IntervalOrderSum' }
  & Pick<IntervalOrderSum, 'category' | 'endAt' | 'futureReportedSales' | 'id' | 'reportedSales' | 'startAt'>
);

export type IntervalPredictionSumFragment = (
  { __typename?: 'IntervalPredictionSum' }
  & Pick<IntervalPredictionSum, 'category' | 'createdOn' | 'endAt' | 'id' | 'modelName' | 'reportedSales' | 'startAt' | 'orderCount'>
);

export type LocationManagementRequestsFragment = (
  { __typename?: 'Location' }
  & { shiftSwapRequests: Array<(
    { __typename?: 'ShiftSwapRequest' }
    & ShiftSwapRequestFragment
  )>, employees: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )>, organization: (
    { __typename?: 'Organization' }
    & OrganizationManagementRequestsFragment
  ) }
);

export type LocationShiftRolesFragment = (
  { __typename?: 'Location' }
  & { shiftRoles: Array<(
    { __typename?: 'ShiftRole' }
    & ShiftRoleFragment
  )>, organization: (
    { __typename?: 'Organization' }
    & { shiftRoleCategories: Array<(
      { __typename?: 'ShiftRoleCategory' }
      & ShiftRoleCategoryFragment
    )> }
  ) }
);

export type LocationSummaryFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'address' | 'categories' | 'createdAt' | 'fiscalWeekOffsetFromMonday' | 'hideInsightsPopUp' | 'itemLevelForecastEnabled' | 'laborCalculationType' | 'laborPercentOfRevenue' | 'pos' | 'prefersEntreesPerLaborHourMetric' | 'salesType' | 'status' | 'timeZone' | 'userCount' | 'universalId' | 'planForecastEnabled' | 'tripleseatEnabled' | 'reservationsImporterType' | 'sevenShiftEnabled' | 'autoSchedulingEnabled' | 'demoLocation'>
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { shiftRoleCategories: Array<(
      { __typename?: 'ShiftRoleCategory' }
      & ShiftRoleCategoryFragment
    )> }
  ), salesDayParts: Array<(
    { __typename?: 'SalesDayPart' }
    & Pick<SalesDayPart, 'dayPartTitle'>
  )> }
);

export type LocationUserSummaryFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'name'>
  )> }
);

export type LocationForReportingFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'metrogroup' | 'timeZone' | 'fiscalWeekOffsetFromMonday'>
);

export type LocationForModifyShiftFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name'>
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'universalId'>
  ), employees: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
    & { employeeLocations: Maybe<Array<(
      { __typename?: 'EmployeeLocation' }
      & Pick<EmployeeLocation, 'id' | 'locationId' | 'active'>
    )>>, shiftRoles: Maybe<Array<(
      { __typename?: 'ShiftRole' }
      & Pick<ShiftRole, 'id'>
    )>>, availability: Maybe<(
      { __typename?: 'EmployeeAvailability' }
      & EmployeeAvailabilitySummaryFragment
    )>, timeOffRequests: Array<(
      { __typename?: 'TimeOffRequest' }
      & TimeOffRequestSummaryFragment
    )> }
  )>, shiftRoles: Array<(
    { __typename?: 'ShiftRole' }
    & Pick<ShiftRole, 'id' | 'name'>
    & { shiftRoleCategory: Maybe<(
      { __typename?: 'ShiftRoleCategory' }
      & ShiftRoleCategoryFragment
    )> }
  )> }
);

export type LocationFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'averageWage' | 'address' | 'createdAt' | 'id' | 'categories' | 'laborCalculationType' | 'laborPercentOfRevenue' | 'externalId' | 'name' | 'organizationId' | 'pos' | 'timeZone' | 'metrogroup' | 'salesType' | 'hideInsightsPopUp' | 'itemLevelForecastEnabled' | 'fiscalWeekOffsetFromMonday' | 'status' | 'prefersEntreesPerLaborHourMetric' | 'tripleseatEnabled' | 'autoSchedulingEnabled' | 'planForecastEnabled' | 'universalId' | 'userCount' | 'reservationsImporterType' | 'sevenShiftEnabled' | 'demoLocation'>
  & { shiftRoles: Array<(
    { __typename?: 'ShiftRole' }
    & ShiftRoleFragment
  )>, organization: (
    { __typename?: 'Organization' }
    & OrganizationFragment
  ), salesDayParts: Array<(
    { __typename?: 'SalesDayPart' }
    & Pick<SalesDayPart, 'dayPartTitle'>
  )> }
);

export type MassNotificationsFragment = (
  { __typename?: 'MassNotification' }
  & Pick<MassNotification, 'id' | 'userName' | 'messageText' | 'createdAt'>
  & { shiftRoleCategories: Array<(
    { __typename?: 'ShiftRoleCategory' }
    & ShiftRoleCategoryFragment
  )>, shiftRoles: Array<(
    { __typename?: 'ShiftRole' }
    & ShiftRoleFragment
  )> }
);

export type OperatingHoursFragment = (
  { __typename?: 'OperatingHours' }
  & Pick<OperatingHours, 'closed' | 'closeAtIso8601' | 'dayOfWeek' | 'day' | 'id' | 'openAtIso8601'>
);

export type OperatingHoursForDateFragment = (
  { __typename?: 'OperatingHours' }
  & Pick<OperatingHours, 'closeAt' | 'id' | 'openAt'>
);

export type OrganizationFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name' | 'salesType' | 'fiscalWeekOffsetFromMonday' | 'hideInsightsPopUp' | 'universalId' | 'integrationSettings' | 'locationsCount' | 'itemLevelForecastEnabled' | 'autoSchedulingEnabled'>
  & { shiftRoleCategories: Array<(
    { __typename?: 'ShiftRoleCategory' }
    & ShiftRoleCategoryFragment
  )> }
);

export type PermissionFragment = (
  { __typename?: 'Permission' }
  & Pick<Permission, 'permission'>
  & { scope: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) | (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
  ) }
);

export type LocationTimeOffBlockedDateFragment = (
  { __typename?: 'LocationTimeOffBlockedDate' }
  & Pick<LocationTimeOffBlockedDate, 'locationId' | 'timeOffBlockedDateId'>
);

export type TimeOffBlockedDateFragment = (
  { __typename?: 'TimeOffBlockedDate' }
  & Pick<TimeOffBlockedDate, 'id' | 'name' | 'startAt' | 'endAt' | 'timeOffBlocked' | 'locationClosed' | 'organizationId'>
  & { locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )> }
);

export type TimeOffBlockedDateForFormFragment = (
  { __typename?: 'TimeOffBlockedDate' }
  & Pick<TimeOffBlockedDate, 'id' | 'name' | 'startAt' | 'endAt' | 'timeOffBlocked' | 'locationClosed'>
  & { locationTimeOffBlockedDates: Array<(
    { __typename?: 'LocationTimeOffBlockedDate' }
    & Pick<LocationTimeOffBlockedDate, 'id' | 'locationId'>
  )> }
);

export type PlanSummaryFragment = (
  { __typename?: 'Plan' }
  & Pick<Plan, 'averageWage' | 'dueAt' | 'submittedAt' | 'state' | 'endOn' | 'id' | 'databaseId' | 'laborPercentOfRevenue' | 'lastUpdatedAt' | 'updatedBy' | 'startOn' | 'needsConfirmation' | 'autoGenerated'>
  & { submitter: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )>, weekSalesAndLaborSummary: (
    { __typename?: 'SalesAndLaborSummary' }
    & SalesAndLaborSummaryFragment
  ), lastWeekSameDayReportedSales: Array<(
    { __typename?: 'DailySalesBenchmark' }
    & Pick<DailySalesBenchmark, 'date' | 'reportedSales'>
  )>, lastMonthSameDayReportedSales: Array<(
    { __typename?: 'DailySalesBenchmark' }
    & Pick<DailySalesBenchmark, 'date' | 'reportedSales'>
  )>, lastYearSameDayReportedSales: Array<(
    { __typename?: 'DailySalesBenchmark' }
    & Pick<DailySalesBenchmark, 'date' | 'reportedSales'>
  )> }
);

export type PlanSummaryLightFragment = (
  { __typename?: 'Plan' }
  & Pick<Plan, 'id' | 'endOn' | 'startOn' | 'submittedAt' | 'autoGenerated' | 'state' | 'lastUpdatedAt' | 'updatedBy'>
  & { submitter: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )>, weekSalesAndLaborSummary: (
    { __typename?: 'SalesAndLaborSummary' }
    & Pick<SalesAndLaborSummary, 'originallyPredictedSales' | 'optimalLaborForPlannedSales' | 'submittedPlannedSales' | 'scheduledLabor' | 'plannedSalesPerScheduledLaborHour' | 'plannedEntreesPerScheduledLaborHour' | 'actualSales' | 'actualLabor' | 'actualLaborDollars' | 'actualSalesPerActualLaborHour' | 'actualEntreesPerActualLaborHour'>
  ) }
);

export type PlanSummaryWithDailySummariesFragment = (
  { __typename?: 'Plan' }
  & { dailySalesAndLaborSummaries: Array<(
    { __typename?: 'SalesAndLaborSummary' }
    & SalesAndLaborSummaryFragment
  )> }
  & PlanSummaryFragment
);

export type PlanForReportingFragment = (
  { __typename?: 'Plan' }
  & Pick<Plan, 'id' | 'submittedAt' | 'lastUpdatedAt'>
  & { dailySalesAndLaborSummaries: Array<(
    { __typename?: 'SalesAndLaborSummary' }
    & Pick<SalesAndLaborSummary, 'startOn' | 'preScheduledLabor' | 'actualLabor' | 'actualSales' | 'plannedSales' | 'originallyPredictedSales' | 'latestPredictedSales' | 'optimalLaborForPlannedSales' | 'optimalLaborForActualSales'>
  )>, weekSalesAndLaborSummary: (
    { __typename?: 'SalesAndLaborSummary' }
    & Pick<SalesAndLaborSummary, 'startOn' | 'preScheduledLabor' | 'actualLabor' | 'plannedSales' | 'originallyPredictedSales' | 'latestPredictedSales' | 'optimalLaborForPlannedSales' | 'optimalLaborForActualSales'>
  ) }
);

export type ToucanAuthFragment = (
  { __typename?: 'ToucanAuth' }
  & Pick<ToucanAuth, 'token'>
);

export type PlanFragment = (
  { __typename?: 'Plan' }
  & Pick<Plan, 'autoGenerated' | 'state' | 'planExplanation'>
  & { shifts: Array<(
    { __typename?: 'Shift' }
    & ShiftFragment
  )>, planTemplate: Maybe<(
    { __typename?: 'PlanTemplate' }
    & PlanTemplateFragment
  )>, locationClosedDates: Array<(
    { __typename?: 'TimeOffBlockedDate' }
    & Pick<TimeOffBlockedDate, 'id' | 'startAt' | 'endAt' | 'organizationId' | 'locationClosed'>
  )> }
  & PlanSummaryWithDailySummariesFragment
);

export type RoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'name'>
);

export type ShiftRoleCategoryFragment = (
  { __typename?: 'ShiftRoleCategory' }
  & Pick<ShiftRoleCategory, 'id' | 'name'>
  & { shiftRoles: Array<(
    { __typename?: 'ShiftRole' }
    & Pick<ShiftRole, 'id' | 'name'>
  )> }
);

export type ShiftRoleFragment = (
  { __typename?: 'ShiftRole' }
  & Pick<ShiftRole, 'id' | 'name' | 'integrationSettings' | 'externalId' | 'metaData' | 'payRate' | 'payType' | 'shiftRoleCategoryId'>
  & { shiftRoleCategory: Maybe<(
    { __typename?: 'ShiftRoleCategory' }
    & ShiftRoleCategoryFragment
  )> }
);

export type ShiftSummaryFragment = (
  { __typename?: 'Shift' }
  & Pick<Shift, 'id' | 'startAt' | 'endAt' | 'displayHours' | 'hours' | 'isUnavailability' | 'notes' | 'wage'>
  & { shiftBreaks: Maybe<Array<(
    { __typename?: 'ShiftBreak' }
    & Pick<ShiftBreak, 'durationMins'>
  )>>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  ), shiftSwapRequest: Maybe<(
    { __typename?: 'ShiftSwapRequest' }
    & Pick<ShiftSwapRequest, 'id' | 'state'>
  )> }
);

export type ShiftFragment = (
  { __typename?: 'Shift' }
  & { shiftRole: Maybe<(
    { __typename?: 'ShiftRole' }
    & ShiftRoleFragment
  )>, shiftBreaks: Maybe<Array<(
    { __typename?: 'ShiftBreak' }
    & ShiftBreakFragment
  )>>, employee: (
    { __typename?: 'Employee' }
    & EmployeeFragment
  ) }
  & ShiftSummaryFragment
);

export type SalesAndLaborSummaryFragment = (
  { __typename?: 'SalesAndLaborSummary' }
  & Pick<SalesAndLaborSummary, 'id' | 'startOn' | 'numOfDays' | 'originallyPredictedSales' | 'optimalLaborForOriginallyPredictedSales' | 'plannedSales' | 'optimalLaborForPlannedSales' | 'submittedPlannedSales' | 'optimalLaborForSubmittedPlannedSales' | 'preScheduledLabor' | 'preScheduledDisplayLabor' | 'scheduledLabor' | 'latestPredictedSales' | 'optimalLaborForLatestPredictedSales' | 'plannedSalesPerScheduledLaborHour' | 'plannedEntreesPerScheduledLaborHour' | 'actualSales' | 'optimalLaborForActualSales' | 'actualLabor' | 'optimalSalesForActualLabor' | 'actualSalesPerActualLaborHour' | 'actualEntreesPerActualLaborHour' | 'lastWeekBenchmark' | 'latestPredictedCateringSales' | 'latestPredictedOnlineSales' | 'latestPredictedDineInSales' | 'latestPredictedCategorySales'>
);

export type TopEventFragment = (
  { __typename?: 'TopEvent' }
  & Pick<TopEvent, 'id' | 'isHoliday' | 'name' | 'startAt'>
);

export type CurrentUserInfoFragment = (
  { __typename?: 'User' }
  & Pick<User, 'avatarUrl' | 'id' | 'email' | 'observer' | 'name'>
  & { locations: Array<(
    { __typename?: 'Location' }
    & LocationSummaryFragment
  )>, organization: (
    { __typename?: 'Organization' }
    & OrganizationSettingsFragment
  ), permissions: Array<(
    { __typename?: 'Permission' }
    & PermissionFragment
  )>, roles: Array<(
    { __typename?: 'Role' }
    & RoleFragment
  )>, authorizedOrganizations: Array<(
    { __typename?: 'Organization' }
    & OrganizationSettingsFragment
  )> }
);

export type ViolationFragment = (
  { __typename?: 'Violation' }
  & Pick<Violation, 'id' | 'name' | 'limit' | 'actual'>
  & { shifts: Array<(
    { __typename?: 'Shift' }
    & ShiftSummaryFragment
  )>, availability: Maybe<(
    { __typename?: 'EmployeeAvailability' }
    & EmployeeAvailabilityFragment
  )>, timeOffRequests: Array<(
    { __typename?: 'TimeOffRequest' }
    & TimeOffRequestFragment
  )> }
);

export type CovidDashboardFragment = (
  { __typename?: 'CovidDashboard' }
  & Pick<CovidDashboard, 'id' | 'name' | 'email' | 'addresses' | 'url'>
);

export type PlanTemplateFragment = (
  { __typename?: 'PlanTemplate' }
  & Pick<PlanTemplate, 'id' | 'name'>
);

export type DaysAvailableFragment = (
  { __typename?: 'DaysAvailable' }
  & Pick<DaysAvailable, 'id' | 'unavailable' | 'dayOfWeek'>
  & { timeSlots: Maybe<Array<(
    { __typename?: 'TimeSlot' }
    & TimeSlotFragment
  )>> }
);

export type TimeSlotFragment = (
  { __typename?: 'TimeSlot' }
  & Pick<TimeSlot, 'id' | 'startTime' | 'endTime'>
);

export type EmployeeAvailabilityFragment = (
  { __typename?: 'EmployeeAvailability' }
  & Pick<EmployeeAvailability, 'id' | 'organizationId' | 'pendingDaysAvailable' | 'employeeNote' | 'managerNote' | 'state' | 'createdAt' | 'updatedAt'>
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), daysAvailable: Maybe<Array<(
    { __typename?: 'DaysAvailable' }
    & DaysAvailableFragment
  )>> }
);

export type EmployeeAvailabilitySummaryFragment = (
  { __typename?: 'EmployeeAvailability' }
  & Pick<EmployeeAvailability, 'id' | 'state'>
  & { daysAvailable: Maybe<Array<(
    { __typename?: 'DaysAvailable' }
    & DaysAvailableFragment
  )>> }
);

export type TimeOffRequestFragment = (
  { __typename?: 'TimeOffRequest' }
  & Pick<TimeOffRequest, 'id' | 'organizationId' | 'employeeNote' | 'managerNote' | 'state' | 'createdAt' | 'updatedAt' | 'startAt' | 'endAt' | 'isAllDay'>
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ) }
);

export type TimeOffRequestSummaryFragment = (
  { __typename?: 'TimeOffRequest' }
  & Pick<TimeOffRequest, 'id' | 'state' | 'startAt' | 'endAt' | 'isAllDay'>
);

export type ShiftSwapRequestFragment = (
  { __typename?: 'ShiftSwapRequest' }
  & Pick<ShiftSwapRequest, 'id' | 'employeeNote' | 'managerNote' | 'state' | 'createdAt' | 'updatedAt'>
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), shift: (
    { __typename?: 'Shift' }
    & Pick<Shift, 'id' | 'startAt' | 'endAt'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
    ), shiftRole: Maybe<(
      { __typename?: 'ShiftRole' }
      & Pick<ShiftRole, 'id' | 'name'>
    )> }
  ), newEmployee: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )> }
);

export type MinimumLaborBaselineFragment = (
  { __typename?: 'MinimumLaborBaseline' }
  & Pick<MinimumLaborBaseline, 'laborCount' | 'startAt' | 'endAt' | 'dayOfWeek'>
);

export type OrganizationManagementRequestsFragment = (
  { __typename?: 'Organization' }
  & { employeeAvailabilities: Array<(
    { __typename?: 'EmployeeAvailability' }
    & EmployeeAvailabilityFragment
  )>, timeOffRequests: Array<(
    { __typename?: 'TimeOffRequest' }
    & TimeOffRequestFragment
  )> }
);

export type GetUsersForOrganizationQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type GetUsersForOrganizationQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & UserForOrganizationSettingsFragment
  )> }
);

export type OrganizationSettingsFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'overtimeHoursEmailOn' | 'overtimeHoursThreshold' | 'laborPercentOfRevenue'>
  & OrganizationFragment
);

export type UserForOrganizationSettingsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'email' | 'id' | 'name' | 'locationNames'>
);

export type ActiveLocationShiftRoleFragment = (
  { __typename?: 'LocationShiftRoles' }
  & Pick<LocationShiftRoles, 'shiftRoleId' | 'active'>
);

export type SalesDayPartFragment = (
  { __typename?: 'SalesDayPart' }
  & Pick<SalesDayPart, 'id' | 'dayPartTitle' | 'startAt' | 'endAt'>
);

export const EmployeeLocationFragmentDoc = gql`
    fragment EmployeeLocation on EmployeeLocation {
  id
  locationId
  employeeId
  externalId
  integrationSettings
  active
  discardedAt
  excludeFromLabor
}
    `;
export const TimeSlotFragmentDoc = gql`
    fragment TimeSlot on TimeSlot {
  id
  startTime
  endTime
}
    `;
export const DaysAvailableFragmentDoc = gql`
    fragment DaysAvailable on DaysAvailable {
  id
  unavailable
  dayOfWeek
  timeSlots {
    ...TimeSlot
  }
}
    ${TimeSlotFragmentDoc}`;
export const EmployeeAvailabilityFragmentDoc = gql`
    fragment EmployeeAvailability on EmployeeAvailability {
  id
  employee {
    id
    name
  }
  organizationId
  daysAvailable {
    ...DaysAvailable
  }
  pendingDaysAvailable
  employeeNote
  managerNote
  state
  createdAt
  updatedAt
}
    ${DaysAvailableFragmentDoc}`;
export const EmployeeForEditFragmentDoc = gql`
    fragment EmployeeForEdit on Employee {
  email
  id
  name
  phone
  wage
  minHours
  maxHours
  confirmedHoursSubmittedAt
  confirmedHoursSubmittedBy
  locations {
    id
    name
    autoSchedulingEnabled
    operatingHours {
      scheduleBeginIso8601
      scheduleEndIso8601
      day
    }
  }
  employeeLocations {
    id
    active
    excludeFromLabor
    locationId
    discardedAt
  }
  availability {
    ...EmployeeAvailability
  }
}
    ${EmployeeAvailabilityFragmentDoc}`;
export const TimeOffRequestSummaryFragmentDoc = gql`
    fragment TimeOffRequestSummary on TimeOffRequest {
  id
  state
  startAt
  endAt
  isAllDay
}
    `;
export const EmployeeForScheduleFragmentDoc = gql`
    fragment EmployeeForSchedule on Employee {
  id
  name
  wage
  shiftRoles {
    id
    shiftRoleCategoryId
    name
    externalId
    payRate
  }
  employeeLocations {
    id
    active
    locationId
    excludeFromLabor
    integrationSettings
  }
  timeOffRequests {
    ...TimeOffRequestSummary
  }
  availability {
    ...EmployeeAvailability
  }
}
    ${TimeOffRequestSummaryFragmentDoc}
${EmployeeAvailabilityFragmentDoc}`;
export const EmployeeAvailabilitySummaryFragmentDoc = gql`
    fragment EmployeeAvailabilitySummary on EmployeeAvailability {
  id
  state
  daysAvailable {
    ...DaysAvailable
  }
}
    ${DaysAvailableFragmentDoc}`;
export const EmployeeListingFragmentDoc = gql`
    fragment EmployeeListing on Employee {
  id
  name
  email
  phone
  locations {
    id
    name
  }
  employeeLocations {
    id
    active
    excludeFromLabor
    locationId
  }
  availability {
    ...EmployeeAvailabilitySummary
  }
}
    ${EmployeeAvailabilitySummaryFragmentDoc}`;
export const EmployeeWithLocationStatusFragmentDoc = gql`
    fragment EmployeeWithLocationStatus on EmployeeLocation {
  id
  active
  excludeFromLabor
  employee {
    ...EmployeeListing
  }
}
    ${EmployeeListingFragmentDoc}`;
export const ShiftSummaryFragmentDoc = gql`
    fragment ShiftSummary on Shift {
  id
  startAt
  endAt
  displayHours
  hours
  isUnavailability
  notes
  wage
  shiftBreaks {
    durationMins
  }
  location {
    id
    name
  }
  shiftSwapRequest {
    id
    state
  }
}
    `;
export const EmployeeShiftsFragmentDoc = gql`
    fragment EmployeeShifts on Employee {
  email
  id
  name
  phone
  shifts {
    ...ShiftSummary
    shiftRole {
      id
      name
    }
  }
}
    ${ShiftSummaryFragmentDoc}`;
export const ForecastFragmentDoc = gql`
    fragment Forecast on Forecast {
  date
  laborHours
  id
  totalSales
}
    `;
export const ItemForecastDayFragmentDoc = gql`
    fragment ItemForecastDay on ItemForecastDay {
  category
  name
  date
  value
}
    `;
export const ItemsForecastFragmentDoc = gql`
    fragment ItemsForecast on ItemsForecast {
  valueBy
  updatedAt
  items {
    ...ItemForecastDay
  }
}
    ${ItemForecastDayFragmentDoc}`;
export const LaborDollarsDailyFragmentDoc = gql`
    fragment LaborDollarsDaily on LaborDollarsDaily {
  cents
  day
}
    `;
export const ImportEventFragmentDoc = gql`
    fragment ImportEvent on ImportEvent {
  id
  completedAt
}
    `;
export const InsightFragmentDoc = gql`
    fragment Insight on Insight {
  date
  id
  otherReasons
  wasUnusual
  reasons
  affectedChannels
  willHappenAgain
}
    `;
export const IntervalOrderSumFragmentDoc = gql`
    fragment IntervalOrderSum on IntervalOrderSum {
  category
  endAt
  futureReportedSales
  id
  reportedSales
  startAt
}
    `;
export const IntervalPredictionSumFragmentDoc = gql`
    fragment IntervalPredictionSum on IntervalPredictionSum {
  category
  createdOn
  endAt
  id
  modelName
  reportedSales
  startAt
  orderCount
}
    `;
export const ShiftSwapRequestFragmentDoc = gql`
    fragment ShiftSwapRequest on ShiftSwapRequest {
  id
  employee {
    id
    name
  }
  employeeNote
  managerNote
  state
  createdAt
  updatedAt
  shift {
    id
    startAt
    endAt
    location {
      id
    }
    shiftRole {
      id
      name
    }
  }
  newEmployee {
    id
    name
  }
}
    `;
export const TimeOffRequestFragmentDoc = gql`
    fragment TimeOffRequest on TimeOffRequest {
  id
  employee {
    id
    name
  }
  organizationId
  employeeNote
  managerNote
  state
  createdAt
  updatedAt
  startAt
  endAt
  isAllDay
}
    `;
export const OrganizationManagementRequestsFragmentDoc = gql`
    fragment OrganizationManagementRequests on Organization {
  employeeAvailabilities {
    ...EmployeeAvailability
  }
  timeOffRequests {
    ...TimeOffRequest
  }
}
    ${EmployeeAvailabilityFragmentDoc}
${TimeOffRequestFragmentDoc}`;
export const LocationManagementRequestsFragmentDoc = gql`
    fragment LocationManagementRequests on Location {
  shiftSwapRequests {
    ...ShiftSwapRequest
  }
  employees {
    id
  }
  organization {
    ...OrganizationManagementRequests
  }
}
    ${ShiftSwapRequestFragmentDoc}
${OrganizationManagementRequestsFragmentDoc}`;
export const ShiftRoleCategoryFragmentDoc = gql`
    fragment ShiftRoleCategory on ShiftRoleCategory {
  id
  name
  shiftRoles {
    id
    name
  }
}
    `;
export const ShiftRoleFragmentDoc = gql`
    fragment ShiftRole on ShiftRole {
  id
  name
  integrationSettings
  externalId
  metaData
  payRate
  payType
  shiftRoleCategoryId
  shiftRoleCategory {
    ...ShiftRoleCategory
  }
}
    ${ShiftRoleCategoryFragmentDoc}`;
export const LocationShiftRolesFragmentDoc = gql`
    fragment LocationShiftRoles on Location {
  shiftRoles {
    ...ShiftRole
  }
  organization {
    shiftRoleCategories {
      ...ShiftRoleCategory
    }
  }
}
    ${ShiftRoleFragmentDoc}
${ShiftRoleCategoryFragmentDoc}`;
export const LocationUserSummaryFragmentDoc = gql`
    fragment LocationUserSummary on User {
  id
  name
  email
  roles {
    name
  }
}
    `;
export const LocationForReportingFragmentDoc = gql`
    fragment LocationForReporting on Location {
  id
  name
  metrogroup
  timeZone
  fiscalWeekOffsetFromMonday
}
    `;
export const LocationForModifyShiftFragmentDoc = gql`
    fragment LocationForModifyShift on Location {
  id
  name
  organization {
    universalId
  }
  employees {
    id
    name
    employeeLocations {
      id
      locationId
      active
    }
    shiftRoles {
      id
    }
    availability {
      ...EmployeeAvailabilitySummary
    }
    timeOffRequests {
      ...TimeOffRequestSummary
    }
  }
  shiftRoles {
    id
    name
    shiftRoleCategory {
      ...ShiftRoleCategory
    }
  }
}
    ${EmployeeAvailabilitySummaryFragmentDoc}
${TimeOffRequestSummaryFragmentDoc}
${ShiftRoleCategoryFragmentDoc}`;
export const OrganizationFragmentDoc = gql`
    fragment Organization on Organization {
  id
  name
  salesType
  fiscalWeekOffsetFromMonday
  hideInsightsPopUp
  universalId
  integrationSettings
  locationsCount
  itemLevelForecastEnabled
  shiftRoleCategories {
    ...ShiftRoleCategory
  }
  autoSchedulingEnabled
}
    ${ShiftRoleCategoryFragmentDoc}`;
export const LocationFragmentDoc = gql`
    fragment Location on Location {
  averageWage
  address
  createdAt
  id
  categories
  laborCalculationType
  laborPercentOfRevenue
  externalId
  name
  organizationId
  pos
  timeZone
  metrogroup
  salesType
  hideInsightsPopUp
  itemLevelForecastEnabled
  fiscalWeekOffsetFromMonday
  shiftRoles {
    ...ShiftRole
  }
  status
  prefersEntreesPerLaborHourMetric
  organization {
    ...Organization
  }
  tripleseatEnabled
  autoSchedulingEnabled
  planForecastEnabled
  universalId
  userCount
  reservationsImporterType
  sevenShiftEnabled
  demoLocation
  salesDayParts {
    dayPartTitle
  }
}
    ${ShiftRoleFragmentDoc}
${OrganizationFragmentDoc}`;
export const MassNotificationsFragmentDoc = gql`
    fragment MassNotifications on MassNotification {
  id
  userName
  messageText
  createdAt
  shiftRoleCategories {
    ...ShiftRoleCategory
  }
  shiftRoles {
    ...ShiftRole
  }
}
    ${ShiftRoleCategoryFragmentDoc}
${ShiftRoleFragmentDoc}`;
export const OperatingHoursFragmentDoc = gql`
    fragment OperatingHours on OperatingHours {
  closed
  closeAtIso8601
  dayOfWeek
  day
  id
  openAtIso8601
}
    `;
export const OperatingHoursForDateFragmentDoc = gql`
    fragment OperatingHoursForDate on OperatingHours {
  closeAt
  id
  openAt
}
    `;
export const LocationTimeOffBlockedDateFragmentDoc = gql`
    fragment LocationTimeOffBlockedDate on LocationTimeOffBlockedDate {
  locationId
  timeOffBlockedDateId
}
    `;
export const TimeOffBlockedDateFragmentDoc = gql`
    fragment TimeOffBlockedDate on TimeOffBlockedDate {
  id
  name
  startAt
  endAt
  timeOffBlocked
  locationClosed
  organizationId
  locations {
    id
    name
  }
}
    `;
export const TimeOffBlockedDateForFormFragmentDoc = gql`
    fragment TimeOffBlockedDateForForm on TimeOffBlockedDate {
  id
  name
  startAt
  endAt
  timeOffBlocked
  locationClosed
  locationTimeOffBlockedDates {
    id
    locationId
  }
}
    `;
export const PlanSummaryLightFragmentDoc = gql`
    fragment PlanSummaryLight on Plan {
  id
  endOn
  startOn
  submittedAt
  submitter {
    name
  }
  weekSalesAndLaborSummary {
    originallyPredictedSales
    optimalLaborForPlannedSales
    submittedPlannedSales
    scheduledLabor
    plannedSalesPerScheduledLaborHour
    plannedEntreesPerScheduledLaborHour
    actualSales
    actualLabor
    actualLaborDollars
    actualSalesPerActualLaborHour
    actualEntreesPerActualLaborHour
  }
  autoGenerated
  state
  lastUpdatedAt
  updatedBy
}
    `;
export const PlanForReportingFragmentDoc = gql`
    fragment PlanForReporting on Plan {
  id
  submittedAt
  lastUpdatedAt
  dailySalesAndLaborSummaries {
    startOn
    preScheduledLabor
    actualLabor
    actualSales
    plannedSales
    originallyPredictedSales
    latestPredictedSales
    optimalLaborForPlannedSales
    optimalLaborForActualSales
  }
  weekSalesAndLaborSummary {
    startOn
    preScheduledLabor
    actualLabor
    plannedSales
    originallyPredictedSales
    latestPredictedSales
    optimalLaborForPlannedSales
    optimalLaborForActualSales
  }
}
    `;
export const ToucanAuthFragmentDoc = gql`
    fragment ToucanAuth on ToucanAuth {
  token
}
    `;
export const SalesAndLaborSummaryFragmentDoc = gql`
    fragment SalesAndLaborSummary on SalesAndLaborSummary {
  id
  startOn
  numOfDays
  originallyPredictedSales
  optimalLaborForOriginallyPredictedSales
  plannedSales
  optimalLaborForPlannedSales
  submittedPlannedSales
  optimalLaborForSubmittedPlannedSales
  preScheduledLabor
  preScheduledDisplayLabor
  scheduledLabor
  latestPredictedSales
  optimalLaborForLatestPredictedSales
  plannedSalesPerScheduledLaborHour
  plannedEntreesPerScheduledLaborHour
  actualSales
  optimalLaborForActualSales
  actualLabor
  optimalSalesForActualLabor
  actualSalesPerActualLaborHour
  actualEntreesPerActualLaborHour
  lastWeekBenchmark
  latestPredictedCateringSales
  latestPredictedOnlineSales
  latestPredictedDineInSales
  latestPredictedCategorySales
}
    `;
export const PlanSummaryFragmentDoc = gql`
    fragment PlanSummary on Plan {
  averageWage
  dueAt
  submittedAt
  state
  submitter {
    name
  }
  endOn
  id
  databaseId
  laborPercentOfRevenue
  lastUpdatedAt
  updatedBy
  startOn
  needsConfirmation
  weekSalesAndLaborSummary {
    ...SalesAndLaborSummary
  }
  lastWeekSameDayReportedSales {
    date
    reportedSales
  }
  lastMonthSameDayReportedSales {
    date
    reportedSales
  }
  lastYearSameDayReportedSales {
    date
    reportedSales
  }
  autoGenerated
}
    ${SalesAndLaborSummaryFragmentDoc}`;
export const PlanSummaryWithDailySummariesFragmentDoc = gql`
    fragment PlanSummaryWithDailySummaries on Plan {
  ...PlanSummary
  dailySalesAndLaborSummaries {
    ...SalesAndLaborSummary
  }
}
    ${PlanSummaryFragmentDoc}
${SalesAndLaborSummaryFragmentDoc}`;
export const ShiftBreakFragmentDoc = gql`
    fragment ShiftBreak on ShiftBreak {
  id
  shiftId
  durationMins
}
    `;
export const EmployeeFragmentDoc = gql`
    fragment Employee on Employee {
  avatarUrl
  email
  id
  name
  phone
  shiftRoles {
    ...ShiftRole
  }
  availability {
    ...EmployeeAvailability
  }
  timeOffRequests {
    ...TimeOffRequest
  }
  wage
  employeeLocations {
    id
    employeeId
    locationId
    integrationSettings
    active
    excludeFromLabor
  }
  locations {
    id
    name
    status
  }
}
    ${ShiftRoleFragmentDoc}
${EmployeeAvailabilityFragmentDoc}
${TimeOffRequestFragmentDoc}`;
export const ShiftFragmentDoc = gql`
    fragment Shift on Shift {
  ...ShiftSummary
  shiftRole {
    ...ShiftRole
  }
  shiftBreaks {
    ...ShiftBreak
  }
  employee {
    ...Employee
  }
}
    ${ShiftSummaryFragmentDoc}
${ShiftRoleFragmentDoc}
${ShiftBreakFragmentDoc}
${EmployeeFragmentDoc}`;
export const PlanTemplateFragmentDoc = gql`
    fragment PlanTemplate on PlanTemplate {
  id
  name
}
    `;
export const PlanFragmentDoc = gql`
    fragment Plan on Plan {
  ...PlanSummaryWithDailySummaries
  shifts {
    ...Shift
  }
  planTemplate {
    ...PlanTemplate
  }
  autoGenerated
  state
  locationClosedDates {
    id
    startAt
    endAt
    organizationId
    locationClosed
  }
  planExplanation
}
    ${PlanSummaryWithDailySummariesFragmentDoc}
${ShiftFragmentDoc}
${PlanTemplateFragmentDoc}`;
export const TopEventFragmentDoc = gql`
    fragment TopEvent on TopEvent {
  id
  isHoliday
  name
  startAt
}
    `;
export const LocationSummaryFragmentDoc = gql`
    fragment LocationSummary on Location {
  id
  name
  address
  categories
  createdAt
  fiscalWeekOffsetFromMonday
  hideInsightsPopUp
  itemLevelForecastEnabled
  laborCalculationType
  laborPercentOfRevenue
  organization {
    id
    name
    shiftRoleCategories {
      ...ShiftRoleCategory
    }
  }
  pos
  prefersEntreesPerLaborHourMetric
  salesType
  status
  timeZone
  userCount
  universalId
  planForecastEnabled
  tripleseatEnabled
  reservationsImporterType
  sevenShiftEnabled
  autoSchedulingEnabled
  demoLocation
  salesDayParts {
    dayPartTitle
  }
}
    ${ShiftRoleCategoryFragmentDoc}`;
export const OrganizationSettingsFragmentDoc = gql`
    fragment OrganizationSettings on Organization {
  ...Organization
  overtimeHoursEmailOn
  overtimeHoursThreshold
  laborPercentOfRevenue
}
    ${OrganizationFragmentDoc}`;
export const PermissionFragmentDoc = gql`
    fragment Permission on Permission {
  permission
  scope {
    ... on Location {
      id
    }
    ... on Organization {
      id
    }
  }
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on Role {
  name
}
    `;
export const CurrentUserInfoFragmentDoc = gql`
    fragment CurrentUserInfo on User {
  avatarUrl
  id
  email
  locations {
    ...LocationSummary
  }
  observer
  organization {
    ...OrganizationSettings
  }
  name
  permissions {
    ...Permission
  }
  roles {
    ...Role
  }
  authorizedOrganizations {
    ...OrganizationSettings
  }
}
    ${LocationSummaryFragmentDoc}
${OrganizationSettingsFragmentDoc}
${PermissionFragmentDoc}
${RoleFragmentDoc}`;
export const ViolationFragmentDoc = gql`
    fragment Violation on Violation {
  id
  name
  limit
  actual
  shifts {
    ...ShiftSummary
  }
  availability {
    ...EmployeeAvailability
  }
  timeOffRequests {
    ...TimeOffRequest
  }
}
    ${ShiftSummaryFragmentDoc}
${EmployeeAvailabilityFragmentDoc}
${TimeOffRequestFragmentDoc}`;
export const CovidDashboardFragmentDoc = gql`
    fragment CovidDashboard on CovidDashboard {
  id
  name
  email
  addresses
  url
}
    `;
export const MinimumLaborBaselineFragmentDoc = gql`
    fragment MinimumLaborBaseline on MinimumLaborBaseline {
  laborCount
  startAt
  endAt
  dayOfWeek
}
    `;
export const UserForOrganizationSettingsFragmentDoc = gql`
    fragment UserForOrganizationSettings on User {
  email
  id
  name
  locationNames
}
    `;
export const ActiveLocationShiftRoleFragmentDoc = gql`
    fragment ActiveLocationShiftRole on LocationShiftRoles {
  shiftRoleId
  active
}
    `;
export const SalesDayPartFragmentDoc = gql`
    fragment SalesDayPart on SalesDayPart {
  id
  dayPartTitle
  startAt
  endAt
}
    `;
export const GetLocationMessagesDocument = gql`
    query getLocationMessages($locationId: ID!) {
  massNotifications(locationId: $locationId) {
    ...MassNotifications
  }
}
    ${MassNotificationsFragmentDoc}`;
export type GetLocationMessagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationMessagesQuery, GetLocationMessagesQueryVariables>, 'query'> & ({ variables: GetLocationMessagesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationMessagesComponent = (props: GetLocationMessagesComponentProps) => (
      <ApolloReactComponents.Query<GetLocationMessagesQuery, GetLocationMessagesQueryVariables> query={GetLocationMessagesDocument} {...props} />
    );
    

/**
 * __useGetLocationMessagesQuery__
 *
 * To run a query within a React component, call `useGetLocationMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationMessagesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetLocationMessagesQuery, GetLocationMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationMessagesQuery, GetLocationMessagesQueryVariables>(GetLocationMessagesDocument, options);
      }
export function useGetLocationMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationMessagesQuery, GetLocationMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationMessagesQuery, GetLocationMessagesQueryVariables>(GetLocationMessagesDocument, options);
        }
export type GetLocationMessagesQueryHookResult = ReturnType<typeof useGetLocationMessagesQuery>;
export type GetLocationMessagesLazyQueryHookResult = ReturnType<typeof useGetLocationMessagesLazyQuery>;
export type GetLocationMessagesQueryResult = Apollo.QueryResult<GetLocationMessagesQuery, GetLocationMessagesQueryVariables>;
export const GetTimeOffBlockedDateDocument = gql`
    query getTimeOffBlockedDate($id: ID!) {
  timeOffBlockedDate(id: $id) {
    ...TimeOffBlockedDateForForm
  }
}
    ${TimeOffBlockedDateForFormFragmentDoc}`;
export type GetTimeOffBlockedDateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTimeOffBlockedDateQuery, GetTimeOffBlockedDateQueryVariables>, 'query'> & ({ variables: GetTimeOffBlockedDateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTimeOffBlockedDateComponent = (props: GetTimeOffBlockedDateComponentProps) => (
      <ApolloReactComponents.Query<GetTimeOffBlockedDateQuery, GetTimeOffBlockedDateQueryVariables> query={GetTimeOffBlockedDateDocument} {...props} />
    );
    

/**
 * __useGetTimeOffBlockedDateQuery__
 *
 * To run a query within a React component, call `useGetTimeOffBlockedDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeOffBlockedDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeOffBlockedDateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTimeOffBlockedDateQuery(baseOptions: Apollo.QueryHookOptions<GetTimeOffBlockedDateQuery, GetTimeOffBlockedDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimeOffBlockedDateQuery, GetTimeOffBlockedDateQueryVariables>(GetTimeOffBlockedDateDocument, options);
      }
export function useGetTimeOffBlockedDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimeOffBlockedDateQuery, GetTimeOffBlockedDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimeOffBlockedDateQuery, GetTimeOffBlockedDateQueryVariables>(GetTimeOffBlockedDateDocument, options);
        }
export type GetTimeOffBlockedDateQueryHookResult = ReturnType<typeof useGetTimeOffBlockedDateQuery>;
export type GetTimeOffBlockedDateLazyQueryHookResult = ReturnType<typeof useGetTimeOffBlockedDateLazyQuery>;
export type GetTimeOffBlockedDateQueryResult = Apollo.QueryResult<GetTimeOffBlockedDateQuery, GetTimeOffBlockedDateQueryVariables>;
export const GetTimeOffBlockedDatesDocument = gql`
    query getTimeOffBlockedDates($organizationId: ID!) {
  timeOffBlockedDates(organizationId: $organizationId) {
    ...TimeOffBlockedDate
  }
}
    ${TimeOffBlockedDateFragmentDoc}`;
export type GetTimeOffBlockedDatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTimeOffBlockedDatesQuery, GetTimeOffBlockedDatesQueryVariables>, 'query'> & ({ variables: GetTimeOffBlockedDatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTimeOffBlockedDatesComponent = (props: GetTimeOffBlockedDatesComponentProps) => (
      <ApolloReactComponents.Query<GetTimeOffBlockedDatesQuery, GetTimeOffBlockedDatesQueryVariables> query={GetTimeOffBlockedDatesDocument} {...props} />
    );
    

/**
 * __useGetTimeOffBlockedDatesQuery__
 *
 * To run a query within a React component, call `useGetTimeOffBlockedDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeOffBlockedDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeOffBlockedDatesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetTimeOffBlockedDatesQuery(baseOptions: Apollo.QueryHookOptions<GetTimeOffBlockedDatesQuery, GetTimeOffBlockedDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimeOffBlockedDatesQuery, GetTimeOffBlockedDatesQueryVariables>(GetTimeOffBlockedDatesDocument, options);
      }
export function useGetTimeOffBlockedDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimeOffBlockedDatesQuery, GetTimeOffBlockedDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimeOffBlockedDatesQuery, GetTimeOffBlockedDatesQueryVariables>(GetTimeOffBlockedDatesDocument, options);
        }
export type GetTimeOffBlockedDatesQueryHookResult = ReturnType<typeof useGetTimeOffBlockedDatesQuery>;
export type GetTimeOffBlockedDatesLazyQueryHookResult = ReturnType<typeof useGetTimeOffBlockedDatesLazyQuery>;
export type GetTimeOffBlockedDatesQueryResult = Apollo.QueryResult<GetTimeOffBlockedDatesQuery, GetTimeOffBlockedDatesQueryVariables>;
export const GetEmployeeLocationsListingDocument = gql`
    query getEmployeeLocationsListing($locationId: ID!) {
  employeeLocations(locationId: $locationId) {
    ...EmployeeWithLocationStatus
  }
}
    ${EmployeeWithLocationStatusFragmentDoc}`;
export type GetEmployeeLocationsListingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEmployeeLocationsListingQuery, GetEmployeeLocationsListingQueryVariables>, 'query'> & ({ variables: GetEmployeeLocationsListingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEmployeeLocationsListingComponent = (props: GetEmployeeLocationsListingComponentProps) => (
      <ApolloReactComponents.Query<GetEmployeeLocationsListingQuery, GetEmployeeLocationsListingQueryVariables> query={GetEmployeeLocationsListingDocument} {...props} />
    );
    

/**
 * __useGetEmployeeLocationsListingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeLocationsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeLocationsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeLocationsListingQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetEmployeeLocationsListingQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeLocationsListingQuery, GetEmployeeLocationsListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeLocationsListingQuery, GetEmployeeLocationsListingQueryVariables>(GetEmployeeLocationsListingDocument, options);
      }
export function useGetEmployeeLocationsListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeLocationsListingQuery, GetEmployeeLocationsListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeLocationsListingQuery, GetEmployeeLocationsListingQueryVariables>(GetEmployeeLocationsListingDocument, options);
        }
export type GetEmployeeLocationsListingQueryHookResult = ReturnType<typeof useGetEmployeeLocationsListingQuery>;
export type GetEmployeeLocationsListingLazyQueryHookResult = ReturnType<typeof useGetEmployeeLocationsListingLazyQuery>;
export type GetEmployeeLocationsListingQueryResult = Apollo.QueryResult<GetEmployeeLocationsListingQuery, GetEmployeeLocationsListingQueryVariables>;
export const GetEmployeeLocationDocument = gql`
    query getEmployeeLocation($employeeId: ID!, $locationId: ID!) {
  employeeLocation(employeeId: $employeeId, locationId: $locationId) {
    ...EmployeeLocation
  }
}
    ${EmployeeLocationFragmentDoc}`;
export type GetEmployeeLocationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEmployeeLocationQuery, GetEmployeeLocationQueryVariables>, 'query'> & ({ variables: GetEmployeeLocationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEmployeeLocationComponent = (props: GetEmployeeLocationComponentProps) => (
      <ApolloReactComponents.Query<GetEmployeeLocationQuery, GetEmployeeLocationQueryVariables> query={GetEmployeeLocationDocument} {...props} />
    );
    

/**
 * __useGetEmployeeLocationQuery__
 *
 * To run a query within a React component, call `useGetEmployeeLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeLocationQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetEmployeeLocationQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeLocationQuery, GetEmployeeLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeLocationQuery, GetEmployeeLocationQueryVariables>(GetEmployeeLocationDocument, options);
      }
export function useGetEmployeeLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeLocationQuery, GetEmployeeLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeLocationQuery, GetEmployeeLocationQueryVariables>(GetEmployeeLocationDocument, options);
        }
export type GetEmployeeLocationQueryHookResult = ReturnType<typeof useGetEmployeeLocationQuery>;
export type GetEmployeeLocationLazyQueryHookResult = ReturnType<typeof useGetEmployeeLocationLazyQuery>;
export type GetEmployeeLocationQueryResult = Apollo.QueryResult<GetEmployeeLocationQuery, GetEmployeeLocationQueryVariables>;
export const GetEmployeeForEditDocument = gql`
    query getEmployeeForEdit($id: ID!) {
  employee(id: $id) {
    ...EmployeeForEdit
  }
}
    ${EmployeeForEditFragmentDoc}`;
export type GetEmployeeForEditComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEmployeeForEditQuery, GetEmployeeForEditQueryVariables>, 'query'> & ({ variables: GetEmployeeForEditQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEmployeeForEditComponent = (props: GetEmployeeForEditComponentProps) => (
      <ApolloReactComponents.Query<GetEmployeeForEditQuery, GetEmployeeForEditQueryVariables> query={GetEmployeeForEditDocument} {...props} />
    );
    

/**
 * __useGetEmployeeForEditQuery__
 *
 * To run a query within a React component, call `useGetEmployeeForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeForEditQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeForEditQuery, GetEmployeeForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeForEditQuery, GetEmployeeForEditQueryVariables>(GetEmployeeForEditDocument, options);
      }
export function useGetEmployeeForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeForEditQuery, GetEmployeeForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeForEditQuery, GetEmployeeForEditQueryVariables>(GetEmployeeForEditDocument, options);
        }
export type GetEmployeeForEditQueryHookResult = ReturnType<typeof useGetEmployeeForEditQuery>;
export type GetEmployeeForEditLazyQueryHookResult = ReturnType<typeof useGetEmployeeForEditLazyQuery>;
export type GetEmployeeForEditQueryResult = Apollo.QueryResult<GetEmployeeForEditQuery, GetEmployeeForEditQueryVariables>;
export const GetFeatureTogglesDocument = gql`
    query getFeatureToggles($actorType: String, $actorId: ID) {
  features(actorType: $actorType, actorId: $actorId) {
    data {
      name
      active
    }
  }
}
    `;
export type GetFeatureTogglesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFeatureTogglesQuery, GetFeatureTogglesQueryVariables>, 'query'>;

    export const GetFeatureTogglesComponent = (props: GetFeatureTogglesComponentProps) => (
      <ApolloReactComponents.Query<GetFeatureTogglesQuery, GetFeatureTogglesQueryVariables> query={GetFeatureTogglesDocument} {...props} />
    );
    

/**
 * __useGetFeatureTogglesQuery__
 *
 * To run a query within a React component, call `useGetFeatureTogglesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureTogglesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureTogglesQuery({
 *   variables: {
 *      actorType: // value for 'actorType'
 *      actorId: // value for 'actorId'
 *   },
 * });
 */
export function useGetFeatureTogglesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureTogglesQuery, GetFeatureTogglesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureTogglesQuery, GetFeatureTogglesQueryVariables>(GetFeatureTogglesDocument, options);
      }
export function useGetFeatureTogglesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureTogglesQuery, GetFeatureTogglesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureTogglesQuery, GetFeatureTogglesQueryVariables>(GetFeatureTogglesDocument, options);
        }
export type GetFeatureTogglesQueryHookResult = ReturnType<typeof useGetFeatureTogglesQuery>;
export type GetFeatureTogglesLazyQueryHookResult = ReturnType<typeof useGetFeatureTogglesLazyQuery>;
export type GetFeatureTogglesQueryResult = Apollo.QueryResult<GetFeatureTogglesQuery, GetFeatureTogglesQueryVariables>;
export const GetLatestSuccessfulImportEventDocument = gql`
    query getLatestSuccessfulImportEvent($locationId: ID!) {
  importEvent(locationId: $locationId) {
    ...ImportEvent
  }
}
    ${ImportEventFragmentDoc}`;
export type GetLatestSuccessfulImportEventComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLatestSuccessfulImportEventQuery, GetLatestSuccessfulImportEventQueryVariables>, 'query'> & ({ variables: GetLatestSuccessfulImportEventQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLatestSuccessfulImportEventComponent = (props: GetLatestSuccessfulImportEventComponentProps) => (
      <ApolloReactComponents.Query<GetLatestSuccessfulImportEventQuery, GetLatestSuccessfulImportEventQueryVariables> query={GetLatestSuccessfulImportEventDocument} {...props} />
    );
    

/**
 * __useGetLatestSuccessfulImportEventQuery__
 *
 * To run a query within a React component, call `useGetLatestSuccessfulImportEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestSuccessfulImportEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestSuccessfulImportEventQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLatestSuccessfulImportEventQuery(baseOptions: Apollo.QueryHookOptions<GetLatestSuccessfulImportEventQuery, GetLatestSuccessfulImportEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestSuccessfulImportEventQuery, GetLatestSuccessfulImportEventQueryVariables>(GetLatestSuccessfulImportEventDocument, options);
      }
export function useGetLatestSuccessfulImportEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestSuccessfulImportEventQuery, GetLatestSuccessfulImportEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestSuccessfulImportEventQuery, GetLatestSuccessfulImportEventQueryVariables>(GetLatestSuccessfulImportEventDocument, options);
        }
export type GetLatestSuccessfulImportEventQueryHookResult = ReturnType<typeof useGetLatestSuccessfulImportEventQuery>;
export type GetLatestSuccessfulImportEventLazyQueryHookResult = ReturnType<typeof useGetLatestSuccessfulImportEventLazyQuery>;
export type GetLatestSuccessfulImportEventQueryResult = Apollo.QueryResult<GetLatestSuccessfulImportEventQuery, GetLatestSuccessfulImportEventQueryVariables>;
export const GetInsightDocument = gql`
    query getInsight($locationId: ID!, $date: ISO8601Date!) {
  insight(locationId: $locationId, date: $date) {
    ...Insight
  }
}
    ${InsightFragmentDoc}`;
export type GetInsightComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInsightQuery, GetInsightQueryVariables>, 'query'> & ({ variables: GetInsightQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInsightComponent = (props: GetInsightComponentProps) => (
      <ApolloReactComponents.Query<GetInsightQuery, GetInsightQueryVariables> query={GetInsightDocument} {...props} />
    );
    

/**
 * __useGetInsightQuery__
 *
 * To run a query within a React component, call `useGetInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsightQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetInsightQuery(baseOptions: Apollo.QueryHookOptions<GetInsightQuery, GetInsightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInsightQuery, GetInsightQueryVariables>(GetInsightDocument, options);
      }
export function useGetInsightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInsightQuery, GetInsightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInsightQuery, GetInsightQueryVariables>(GetInsightDocument, options);
        }
export type GetInsightQueryHookResult = ReturnType<typeof useGetInsightQuery>;
export type GetInsightLazyQueryHookResult = ReturnType<typeof useGetInsightLazyQuery>;
export type GetInsightQueryResult = Apollo.QueryResult<GetInsightQuery, GetInsightQueryVariables>;
export const GetIntervalOrderSumsDocument = gql`
    query getIntervalOrderSums($locationId: ID!, $startAt: ISO8601DateTime!, $endAt: ISO8601DateTime!, $intervalMinutes: Int!) {
  intervalOrderSums(
    locationId: $locationId
    startAt: $startAt
    endAt: $endAt
    intervalMinutes: $intervalMinutes
  ) {
    ...IntervalOrderSum
  }
}
    ${IntervalOrderSumFragmentDoc}`;
export type GetIntervalOrderSumsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetIntervalOrderSumsQuery, GetIntervalOrderSumsQueryVariables>, 'query'> & ({ variables: GetIntervalOrderSumsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetIntervalOrderSumsComponent = (props: GetIntervalOrderSumsComponentProps) => (
      <ApolloReactComponents.Query<GetIntervalOrderSumsQuery, GetIntervalOrderSumsQueryVariables> query={GetIntervalOrderSumsDocument} {...props} />
    );
    

/**
 * __useGetIntervalOrderSumsQuery__
 *
 * To run a query within a React component, call `useGetIntervalOrderSumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntervalOrderSumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntervalOrderSumsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      intervalMinutes: // value for 'intervalMinutes'
 *   },
 * });
 */
export function useGetIntervalOrderSumsQuery(baseOptions: Apollo.QueryHookOptions<GetIntervalOrderSumsQuery, GetIntervalOrderSumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntervalOrderSumsQuery, GetIntervalOrderSumsQueryVariables>(GetIntervalOrderSumsDocument, options);
      }
export function useGetIntervalOrderSumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntervalOrderSumsQuery, GetIntervalOrderSumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntervalOrderSumsQuery, GetIntervalOrderSumsQueryVariables>(GetIntervalOrderSumsDocument, options);
        }
export type GetIntervalOrderSumsQueryHookResult = ReturnType<typeof useGetIntervalOrderSumsQuery>;
export type GetIntervalOrderSumsLazyQueryHookResult = ReturnType<typeof useGetIntervalOrderSumsLazyQuery>;
export type GetIntervalOrderSumsQueryResult = Apollo.QueryResult<GetIntervalOrderSumsQuery, GetIntervalOrderSumsQueryVariables>;
export const GetIntervalPredictionSumsDocument = gql`
    query getIntervalPredictionSums($locationId: ID!, $startAt: ISO8601DateTime!, $endAt: ISO8601DateTime!, $intervalMinutes: Int!) {
  intervalPredictionSums(
    locationId: $locationId
    startAt: $startAt
    endAt: $endAt
    intervalMinutes: $intervalMinutes
  ) {
    ...IntervalPredictionSum
  }
}
    ${IntervalPredictionSumFragmentDoc}`;
export type GetIntervalPredictionSumsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetIntervalPredictionSumsQuery, GetIntervalPredictionSumsQueryVariables>, 'query'> & ({ variables: GetIntervalPredictionSumsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetIntervalPredictionSumsComponent = (props: GetIntervalPredictionSumsComponentProps) => (
      <ApolloReactComponents.Query<GetIntervalPredictionSumsQuery, GetIntervalPredictionSumsQueryVariables> query={GetIntervalPredictionSumsDocument} {...props} />
    );
    

/**
 * __useGetIntervalPredictionSumsQuery__
 *
 * To run a query within a React component, call `useGetIntervalPredictionSumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntervalPredictionSumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntervalPredictionSumsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      intervalMinutes: // value for 'intervalMinutes'
 *   },
 * });
 */
export function useGetIntervalPredictionSumsQuery(baseOptions: Apollo.QueryHookOptions<GetIntervalPredictionSumsQuery, GetIntervalPredictionSumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntervalPredictionSumsQuery, GetIntervalPredictionSumsQueryVariables>(GetIntervalPredictionSumsDocument, options);
      }
export function useGetIntervalPredictionSumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntervalPredictionSumsQuery, GetIntervalPredictionSumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntervalPredictionSumsQuery, GetIntervalPredictionSumsQueryVariables>(GetIntervalPredictionSumsDocument, options);
        }
export type GetIntervalPredictionSumsQueryHookResult = ReturnType<typeof useGetIntervalPredictionSumsQuery>;
export type GetIntervalPredictionSumsLazyQueryHookResult = ReturnType<typeof useGetIntervalPredictionSumsLazyQuery>;
export type GetIntervalPredictionSumsQueryResult = Apollo.QueryResult<GetIntervalPredictionSumsQuery, GetIntervalPredictionSumsQueryVariables>;
export const GetDailyHistoricalPredictionsDocument = gql`
    query getDailyHistoricalPredictions($locationId: ID!, $startOn: ISO8601Date!, $endOn: ISO8601Date!) {
  dailyHistoricalPredictions0: dailyHistoricalPredictions(
    locationId: $locationId
    startOn: $startOn
    endOn: $endOn
    ageInDays: 0
  ) {
    ...IntervalPredictionSum
  }
  dailyHistoricalPredictions7: dailyHistoricalPredictions(
    locationId: $locationId
    startOn: $startOn
    endOn: $endOn
    ageInDays: 7
  ) {
    ...IntervalPredictionSum
  }
  dailyHistoricalPredictions14: dailyHistoricalPredictions(
    locationId: $locationId
    startOn: $startOn
    endOn: $endOn
    ageInDays: 14
  ) {
    ...IntervalPredictionSum
  }
}
    ${IntervalPredictionSumFragmentDoc}`;
export type GetDailyHistoricalPredictionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDailyHistoricalPredictionsQuery, GetDailyHistoricalPredictionsQueryVariables>, 'query'> & ({ variables: GetDailyHistoricalPredictionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDailyHistoricalPredictionsComponent = (props: GetDailyHistoricalPredictionsComponentProps) => (
      <ApolloReactComponents.Query<GetDailyHistoricalPredictionsQuery, GetDailyHistoricalPredictionsQueryVariables> query={GetDailyHistoricalPredictionsDocument} {...props} />
    );
    

/**
 * __useGetDailyHistoricalPredictionsQuery__
 *
 * To run a query within a React component, call `useGetDailyHistoricalPredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyHistoricalPredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyHistoricalPredictionsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *   },
 * });
 */
export function useGetDailyHistoricalPredictionsQuery(baseOptions: Apollo.QueryHookOptions<GetDailyHistoricalPredictionsQuery, GetDailyHistoricalPredictionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailyHistoricalPredictionsQuery, GetDailyHistoricalPredictionsQueryVariables>(GetDailyHistoricalPredictionsDocument, options);
      }
export function useGetDailyHistoricalPredictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailyHistoricalPredictionsQuery, GetDailyHistoricalPredictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailyHistoricalPredictionsQuery, GetDailyHistoricalPredictionsQueryVariables>(GetDailyHistoricalPredictionsDocument, options);
        }
export type GetDailyHistoricalPredictionsQueryHookResult = ReturnType<typeof useGetDailyHistoricalPredictionsQuery>;
export type GetDailyHistoricalPredictionsLazyQueryHookResult = ReturnType<typeof useGetDailyHistoricalPredictionsLazyQuery>;
export type GetDailyHistoricalPredictionsQueryResult = Apollo.QueryResult<GetDailyHistoricalPredictionsQuery, GetDailyHistoricalPredictionsQueryVariables>;
export const GetLocationForScheduleDocument = gql`
    query getLocationForSchedule($id: ID!, $startOnLastWeek: ISO8601Date!, $endOnLastWeek: ISO8601Date!) {
  location(id: $id) {
    id
    categories
    name
    salesType
    shiftRoles {
      ...ShiftRole
    }
    employees {
      ...EmployeeForSchedule
    }
    shiftsLastWeek: shifts(startOn: $startOnLastWeek, endOn: $endOnLastWeek) {
      id
    }
    minimumLaborBaselines {
      ...MinimumLaborBaseline
    }
    fiscalWeekOffsetFromMonday
    planForecastEnabled
    tripleseatEnabled
    autoSchedulingEnabled
    reservationsImporterType
  }
}
    ${ShiftRoleFragmentDoc}
${EmployeeForScheduleFragmentDoc}
${MinimumLaborBaselineFragmentDoc}`;
export type GetLocationForScheduleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationForScheduleQuery, GetLocationForScheduleQueryVariables>, 'query'> & ({ variables: GetLocationForScheduleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationForScheduleComponent = (props: GetLocationForScheduleComponentProps) => (
      <ApolloReactComponents.Query<GetLocationForScheduleQuery, GetLocationForScheduleQueryVariables> query={GetLocationForScheduleDocument} {...props} />
    );
    

/**
 * __useGetLocationForScheduleQuery__
 *
 * To run a query within a React component, call `useGetLocationForScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationForScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationForScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startOnLastWeek: // value for 'startOnLastWeek'
 *      endOnLastWeek: // value for 'endOnLastWeek'
 *   },
 * });
 */
export function useGetLocationForScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetLocationForScheduleQuery, GetLocationForScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationForScheduleQuery, GetLocationForScheduleQueryVariables>(GetLocationForScheduleDocument, options);
      }
export function useGetLocationForScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationForScheduleQuery, GetLocationForScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationForScheduleQuery, GetLocationForScheduleQueryVariables>(GetLocationForScheduleDocument, options);
        }
export type GetLocationForScheduleQueryHookResult = ReturnType<typeof useGetLocationForScheduleQuery>;
export type GetLocationForScheduleLazyQueryHookResult = ReturnType<typeof useGetLocationForScheduleLazyQuery>;
export type GetLocationForScheduleQueryResult = Apollo.QueryResult<GetLocationForScheduleQuery, GetLocationForScheduleQueryVariables>;
export const GetLocationForModifyShiftDocument = gql`
    query getLocationForModifyShift($id: ID!) {
  location(id: $id) {
    ...LocationForModifyShift
  }
}
    ${LocationForModifyShiftFragmentDoc}`;
export type GetLocationForModifyShiftComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationForModifyShiftQuery, GetLocationForModifyShiftQueryVariables>, 'query'> & ({ variables: GetLocationForModifyShiftQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationForModifyShiftComponent = (props: GetLocationForModifyShiftComponentProps) => (
      <ApolloReactComponents.Query<GetLocationForModifyShiftQuery, GetLocationForModifyShiftQueryVariables> query={GetLocationForModifyShiftDocument} {...props} />
    );
    

/**
 * __useGetLocationForModifyShiftQuery__
 *
 * To run a query within a React component, call `useGetLocationForModifyShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationForModifyShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationForModifyShiftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationForModifyShiftQuery(baseOptions: Apollo.QueryHookOptions<GetLocationForModifyShiftQuery, GetLocationForModifyShiftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationForModifyShiftQuery, GetLocationForModifyShiftQueryVariables>(GetLocationForModifyShiftDocument, options);
      }
export function useGetLocationForModifyShiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationForModifyShiftQuery, GetLocationForModifyShiftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationForModifyShiftQuery, GetLocationForModifyShiftQueryVariables>(GetLocationForModifyShiftDocument, options);
        }
export type GetLocationForModifyShiftQueryHookResult = ReturnType<typeof useGetLocationForModifyShiftQuery>;
export type GetLocationForModifyShiftLazyQueryHookResult = ReturnType<typeof useGetLocationForModifyShiftLazyQuery>;
export type GetLocationForModifyShiftQueryResult = Apollo.QueryResult<GetLocationForModifyShiftQuery, GetLocationForModifyShiftQueryVariables>;
export const GetLocationManagementRequestsDocument = gql`
    query getLocationManagementRequests($locationId: ID!) {
  location(id: $locationId) {
    ...LocationManagementRequests
  }
}
    ${LocationManagementRequestsFragmentDoc}`;
export type GetLocationManagementRequestsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationManagementRequestsQuery, GetLocationManagementRequestsQueryVariables>, 'query'> & ({ variables: GetLocationManagementRequestsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationManagementRequestsComponent = (props: GetLocationManagementRequestsComponentProps) => (
      <ApolloReactComponents.Query<GetLocationManagementRequestsQuery, GetLocationManagementRequestsQueryVariables> query={GetLocationManagementRequestsDocument} {...props} />
    );
    

/**
 * __useGetLocationManagementRequestsQuery__
 *
 * To run a query within a React component, call `useGetLocationManagementRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationManagementRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationManagementRequestsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationManagementRequestsQuery(baseOptions: Apollo.QueryHookOptions<GetLocationManagementRequestsQuery, GetLocationManagementRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationManagementRequestsQuery, GetLocationManagementRequestsQueryVariables>(GetLocationManagementRequestsDocument, options);
      }
export function useGetLocationManagementRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationManagementRequestsQuery, GetLocationManagementRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationManagementRequestsQuery, GetLocationManagementRequestsQueryVariables>(GetLocationManagementRequestsDocument, options);
        }
export type GetLocationManagementRequestsQueryHookResult = ReturnType<typeof useGetLocationManagementRequestsQuery>;
export type GetLocationManagementRequestsLazyQueryHookResult = ReturnType<typeof useGetLocationManagementRequestsLazyQuery>;
export type GetLocationManagementRequestsQueryResult = Apollo.QueryResult<GetLocationManagementRequestsQuery, GetLocationManagementRequestsQueryVariables>;
export const GetLocationShiftRolesDocument = gql`
    query getLocationShiftRoles($locationId: ID!) {
  location(id: $locationId) {
    ...LocationShiftRoles
  }
}
    ${LocationShiftRolesFragmentDoc}`;
export type GetLocationShiftRolesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationShiftRolesQuery, GetLocationShiftRolesQueryVariables>, 'query'> & ({ variables: GetLocationShiftRolesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationShiftRolesComponent = (props: GetLocationShiftRolesComponentProps) => (
      <ApolloReactComponents.Query<GetLocationShiftRolesQuery, GetLocationShiftRolesQueryVariables> query={GetLocationShiftRolesDocument} {...props} />
    );
    

/**
 * __useGetLocationShiftRolesQuery__
 *
 * To run a query within a React component, call `useGetLocationShiftRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationShiftRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationShiftRolesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationShiftRolesQuery(baseOptions: Apollo.QueryHookOptions<GetLocationShiftRolesQuery, GetLocationShiftRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationShiftRolesQuery, GetLocationShiftRolesQueryVariables>(GetLocationShiftRolesDocument, options);
      }
export function useGetLocationShiftRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationShiftRolesQuery, GetLocationShiftRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationShiftRolesQuery, GetLocationShiftRolesQueryVariables>(GetLocationShiftRolesDocument, options);
        }
export type GetLocationShiftRolesQueryHookResult = ReturnType<typeof useGetLocationShiftRolesQuery>;
export type GetLocationShiftRolesLazyQueryHookResult = ReturnType<typeof useGetLocationShiftRolesLazyQuery>;
export type GetLocationShiftRolesQueryResult = Apollo.QueryResult<GetLocationShiftRolesQuery, GetLocationShiftRolesQueryVariables>;
export const GetMeDocument = gql`
    query getMe {
  me {
    ...CurrentUserInfo
  }
}
    ${CurrentUserInfoFragmentDoc}`;
export type GetMeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMeQuery, GetMeQueryVariables>, 'query'>;

    export const GetMeComponent = (props: GetMeComponentProps) => (
      <ApolloReactComponents.Query<GetMeQuery, GetMeQueryVariables> query={GetMeDocument} {...props} />
    );
    

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetUsersForLocationDocument = gql`
    query getUsersForLocation($locationId: ID!) {
  usersForLocation(locationId: $locationId) {
    ...LocationUserSummary
  }
}
    ${LocationUserSummaryFragmentDoc}`;
export type GetUsersForLocationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsersForLocationQuery, GetUsersForLocationQueryVariables>, 'query'> & ({ variables: GetUsersForLocationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUsersForLocationComponent = (props: GetUsersForLocationComponentProps) => (
      <ApolloReactComponents.Query<GetUsersForLocationQuery, GetUsersForLocationQueryVariables> query={GetUsersForLocationDocument} {...props} />
    );
    

/**
 * __useGetUsersForLocationQuery__
 *
 * To run a query within a React component, call `useGetUsersForLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetUsersForLocationQuery(baseOptions: Apollo.QueryHookOptions<GetUsersForLocationQuery, GetUsersForLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersForLocationQuery, GetUsersForLocationQueryVariables>(GetUsersForLocationDocument, options);
      }
export function useGetUsersForLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersForLocationQuery, GetUsersForLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersForLocationQuery, GetUsersForLocationQueryVariables>(GetUsersForLocationDocument, options);
        }
export type GetUsersForLocationQueryHookResult = ReturnType<typeof useGetUsersForLocationQuery>;
export type GetUsersForLocationLazyQueryHookResult = ReturnType<typeof useGetUsersForLocationLazyQuery>;
export type GetUsersForLocationQueryResult = Apollo.QueryResult<GetUsersForLocationQuery, GetUsersForLocationQueryVariables>;
export const GetOperatingHoursDocument = gql`
    query getOperatingHours($locationId: ID!) {
  operatingHours(locationId: $locationId) {
    ...OperatingHours
  }
}
    ${OperatingHoursFragmentDoc}`;
export type GetOperatingHoursComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOperatingHoursQuery, GetOperatingHoursQueryVariables>, 'query'> & ({ variables: GetOperatingHoursQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOperatingHoursComponent = (props: GetOperatingHoursComponentProps) => (
      <ApolloReactComponents.Query<GetOperatingHoursQuery, GetOperatingHoursQueryVariables> query={GetOperatingHoursDocument} {...props} />
    );
    

/**
 * __useGetOperatingHoursQuery__
 *
 * To run a query within a React component, call `useGetOperatingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatingHoursQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetOperatingHoursQuery(baseOptions: Apollo.QueryHookOptions<GetOperatingHoursQuery, GetOperatingHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperatingHoursQuery, GetOperatingHoursQueryVariables>(GetOperatingHoursDocument, options);
      }
export function useGetOperatingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperatingHoursQuery, GetOperatingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperatingHoursQuery, GetOperatingHoursQueryVariables>(GetOperatingHoursDocument, options);
        }
export type GetOperatingHoursQueryHookResult = ReturnType<typeof useGetOperatingHoursQuery>;
export type GetOperatingHoursLazyQueryHookResult = ReturnType<typeof useGetOperatingHoursLazyQuery>;
export type GetOperatingHoursQueryResult = Apollo.QueryResult<GetOperatingHoursQuery, GetOperatingHoursQueryVariables>;
export const GetOperatingHoursForDateDocument = gql`
    query getOperatingHoursForDate($locationId: ID!, $startOn: ISO8601Date!, $endOn: ISO8601Date) {
  operatingHoursForDate(locationId: $locationId, startOn: $startOn, endOn: $endOn) {
    ...OperatingHoursForDate
  }
}
    ${OperatingHoursForDateFragmentDoc}`;
export type GetOperatingHoursForDateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOperatingHoursForDateQuery, GetOperatingHoursForDateQueryVariables>, 'query'> & ({ variables: GetOperatingHoursForDateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOperatingHoursForDateComponent = (props: GetOperatingHoursForDateComponentProps) => (
      <ApolloReactComponents.Query<GetOperatingHoursForDateQuery, GetOperatingHoursForDateQueryVariables> query={GetOperatingHoursForDateDocument} {...props} />
    );
    

/**
 * __useGetOperatingHoursForDateQuery__
 *
 * To run a query within a React component, call `useGetOperatingHoursForDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatingHoursForDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatingHoursForDateQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *   },
 * });
 */
export function useGetOperatingHoursForDateQuery(baseOptions: Apollo.QueryHookOptions<GetOperatingHoursForDateQuery, GetOperatingHoursForDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperatingHoursForDateQuery, GetOperatingHoursForDateQueryVariables>(GetOperatingHoursForDateDocument, options);
      }
export function useGetOperatingHoursForDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperatingHoursForDateQuery, GetOperatingHoursForDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperatingHoursForDateQuery, GetOperatingHoursForDateQueryVariables>(GetOperatingHoursForDateDocument, options);
        }
export type GetOperatingHoursForDateQueryHookResult = ReturnType<typeof useGetOperatingHoursForDateQuery>;
export type GetOperatingHoursForDateLazyQueryHookResult = ReturnType<typeof useGetOperatingHoursForDateLazyQuery>;
export type GetOperatingHoursForDateQueryResult = Apollo.QueryResult<GetOperatingHoursForDateQuery, GetOperatingHoursForDateQueryVariables>;
export const GetLocationShiftRoleActiveDocument = gql`
    query getLocationShiftRoleActive($locationId: ID!, $shiftRoleId: ID!) {
  locationShiftRole(locationId: $locationId, shiftRoleId: $shiftRoleId) {
    ...ActiveLocationShiftRole
  }
}
    ${ActiveLocationShiftRoleFragmentDoc}`;
export type GetLocationShiftRoleActiveComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationShiftRoleActiveQuery, GetLocationShiftRoleActiveQueryVariables>, 'query'> & ({ variables: GetLocationShiftRoleActiveQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationShiftRoleActiveComponent = (props: GetLocationShiftRoleActiveComponentProps) => (
      <ApolloReactComponents.Query<GetLocationShiftRoleActiveQuery, GetLocationShiftRoleActiveQueryVariables> query={GetLocationShiftRoleActiveDocument} {...props} />
    );
    

/**
 * __useGetLocationShiftRoleActiveQuery__
 *
 * To run a query within a React component, call `useGetLocationShiftRoleActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationShiftRoleActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationShiftRoleActiveQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      shiftRoleId: // value for 'shiftRoleId'
 *   },
 * });
 */
export function useGetLocationShiftRoleActiveQuery(baseOptions: Apollo.QueryHookOptions<GetLocationShiftRoleActiveQuery, GetLocationShiftRoleActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationShiftRoleActiveQuery, GetLocationShiftRoleActiveQueryVariables>(GetLocationShiftRoleActiveDocument, options);
      }
export function useGetLocationShiftRoleActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationShiftRoleActiveQuery, GetLocationShiftRoleActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationShiftRoleActiveQuery, GetLocationShiftRoleActiveQueryVariables>(GetLocationShiftRoleActiveDocument, options);
        }
export type GetLocationShiftRoleActiveQueryHookResult = ReturnType<typeof useGetLocationShiftRoleActiveQuery>;
export type GetLocationShiftRoleActiveLazyQueryHookResult = ReturnType<typeof useGetLocationShiftRoleActiveLazyQuery>;
export type GetLocationShiftRoleActiveQueryResult = Apollo.QueryResult<GetLocationShiftRoleActiveQuery, GetLocationShiftRoleActiveQueryVariables>;
export const GetLocationShiftRolesActiveDocument = gql`
    query getLocationShiftRolesActive($locationId: ID!) {
  locationShiftRoles(locationId: $locationId) {
    ...ActiveLocationShiftRole
  }
}
    ${ActiveLocationShiftRoleFragmentDoc}`;
export type GetLocationShiftRolesActiveComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationShiftRolesActiveQuery, GetLocationShiftRolesActiveQueryVariables>, 'query'> & ({ variables: GetLocationShiftRolesActiveQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLocationShiftRolesActiveComponent = (props: GetLocationShiftRolesActiveComponentProps) => (
      <ApolloReactComponents.Query<GetLocationShiftRolesActiveQuery, GetLocationShiftRolesActiveQueryVariables> query={GetLocationShiftRolesActiveDocument} {...props} />
    );
    

/**
 * __useGetLocationShiftRolesActiveQuery__
 *
 * To run a query within a React component, call `useGetLocationShiftRolesActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationShiftRolesActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationShiftRolesActiveQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationShiftRolesActiveQuery(baseOptions: Apollo.QueryHookOptions<GetLocationShiftRolesActiveQuery, GetLocationShiftRolesActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationShiftRolesActiveQuery, GetLocationShiftRolesActiveQueryVariables>(GetLocationShiftRolesActiveDocument, options);
      }
export function useGetLocationShiftRolesActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationShiftRolesActiveQuery, GetLocationShiftRolesActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationShiftRolesActiveQuery, GetLocationShiftRolesActiveQueryVariables>(GetLocationShiftRolesActiveDocument, options);
        }
export type GetLocationShiftRolesActiveQueryHookResult = ReturnType<typeof useGetLocationShiftRolesActiveQuery>;
export type GetLocationShiftRolesActiveLazyQueryHookResult = ReturnType<typeof useGetLocationShiftRolesActiveLazyQuery>;
export type GetLocationShiftRolesActiveQueryResult = Apollo.QueryResult<GetLocationShiftRolesActiveQuery, GetLocationShiftRolesActiveQueryVariables>;
export const GetToucanAuthDocument = gql`
    query getToucanAuth($startDate: ISO8601Date!, $endDate: ISO8601Date!, $locationIds: [ID!]!) {
  toucanAuth(startDate: $startDate, endDate: $endDate, locationIds: $locationIds) {
    ...ToucanAuth
  }
}
    ${ToucanAuthFragmentDoc}`;
export type GetToucanAuthComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetToucanAuthQuery, GetToucanAuthQueryVariables>, 'query'> & ({ variables: GetToucanAuthQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetToucanAuthComponent = (props: GetToucanAuthComponentProps) => (
      <ApolloReactComponents.Query<GetToucanAuthQuery, GetToucanAuthQueryVariables> query={GetToucanAuthDocument} {...props} />
    );
    

/**
 * __useGetToucanAuthQuery__
 *
 * To run a query within a React component, call `useGetToucanAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToucanAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToucanAuthQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useGetToucanAuthQuery(baseOptions: Apollo.QueryHookOptions<GetToucanAuthQuery, GetToucanAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetToucanAuthQuery, GetToucanAuthQueryVariables>(GetToucanAuthDocument, options);
      }
export function useGetToucanAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetToucanAuthQuery, GetToucanAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetToucanAuthQuery, GetToucanAuthQueryVariables>(GetToucanAuthDocument, options);
        }
export type GetToucanAuthQueryHookResult = ReturnType<typeof useGetToucanAuthQuery>;
export type GetToucanAuthLazyQueryHookResult = ReturnType<typeof useGetToucanAuthLazyQuery>;
export type GetToucanAuthQueryResult = Apollo.QueryResult<GetToucanAuthQuery, GetToucanAuthQueryVariables>;
export const GetPlanDocument = gql`
    query getPlan($endOn: ISO8601Date!, $locationId: ID!, $startOn: ISO8601Date!) {
  plan(endOn: $endOn, locationId: $locationId, startOn: $startOn) {
    ...Plan
  }
}
    ${PlanFragmentDoc}`;
export type GetPlanComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlanQuery, GetPlanQueryVariables>, 'query'> & ({ variables: GetPlanQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPlanComponent = (props: GetPlanComponentProps) => (
      <ApolloReactComponents.Query<GetPlanQuery, GetPlanQueryVariables> query={GetPlanDocument} {...props} />
    );
    

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      endOn: // value for 'endOn'
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
      }
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
        }
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export const GetPlanForReportingDocument = gql`
    query getPlanForReporting($endOn: ISO8601Date!, $locationId: ID!, $startOn: ISO8601Date!) {
  plan(endOn: $endOn, locationId: $locationId, startOn: $startOn) {
    ...PlanForReporting
  }
}
    ${PlanForReportingFragmentDoc}`;
export type GetPlanForReportingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlanForReportingQuery, GetPlanForReportingQueryVariables>, 'query'> & ({ variables: GetPlanForReportingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPlanForReportingComponent = (props: GetPlanForReportingComponentProps) => (
      <ApolloReactComponents.Query<GetPlanForReportingQuery, GetPlanForReportingQueryVariables> query={GetPlanForReportingDocument} {...props} />
    );
    

/**
 * __useGetPlanForReportingQuery__
 *
 * To run a query within a React component, call `useGetPlanForReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanForReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanForReportingQuery({
 *   variables: {
 *      endOn: // value for 'endOn'
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *   },
 * });
 */
export function useGetPlanForReportingQuery(baseOptions: Apollo.QueryHookOptions<GetPlanForReportingQuery, GetPlanForReportingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanForReportingQuery, GetPlanForReportingQueryVariables>(GetPlanForReportingDocument, options);
      }
export function useGetPlanForReportingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanForReportingQuery, GetPlanForReportingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanForReportingQuery, GetPlanForReportingQueryVariables>(GetPlanForReportingDocument, options);
        }
export type GetPlanForReportingQueryHookResult = ReturnType<typeof useGetPlanForReportingQuery>;
export type GetPlanForReportingLazyQueryHookResult = ReturnType<typeof useGetPlanForReportingLazyQuery>;
export type GetPlanForReportingQueryResult = Apollo.QueryResult<GetPlanForReportingQuery, GetPlanForReportingQueryVariables>;
export const GetLaborDollarsDocument = gql`
    query getLaborDollars($planId: ID!, $locationId: ID!) {
  laborDollars(planId: $planId, locationId: $locationId) {
    totalCents
    daily {
      ...LaborDollarsDaily
    }
  }
}
    ${LaborDollarsDailyFragmentDoc}`;
export type GetLaborDollarsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLaborDollarsQuery, GetLaborDollarsQueryVariables>, 'query'> & ({ variables: GetLaborDollarsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLaborDollarsComponent = (props: GetLaborDollarsComponentProps) => (
      <ApolloReactComponents.Query<GetLaborDollarsQuery, GetLaborDollarsQueryVariables> query={GetLaborDollarsDocument} {...props} />
    );
    

/**
 * __useGetLaborDollarsQuery__
 *
 * To run a query within a React component, call `useGetLaborDollarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLaborDollarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLaborDollarsQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLaborDollarsQuery(baseOptions: Apollo.QueryHookOptions<GetLaborDollarsQuery, GetLaborDollarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLaborDollarsQuery, GetLaborDollarsQueryVariables>(GetLaborDollarsDocument, options);
      }
export function useGetLaborDollarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLaborDollarsQuery, GetLaborDollarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLaborDollarsQuery, GetLaborDollarsQueryVariables>(GetLaborDollarsDocument, options);
        }
export type GetLaborDollarsQueryHookResult = ReturnType<typeof useGetLaborDollarsQuery>;
export type GetLaborDollarsLazyQueryHookResult = ReturnType<typeof useGetLaborDollarsLazyQuery>;
export type GetLaborDollarsQueryResult = Apollo.QueryResult<GetLaborDollarsQuery, GetLaborDollarsQueryVariables>;
export const GetTopEventsDocument = gql`
    query getTopEvents($endOn: ISO8601Date, $locationId: ID!, $startOn: ISO8601Date!) {
  topEvents(endOn: $endOn, locationId: $locationId, startOn: $startOn) {
    ...TopEvent
  }
}
    ${TopEventFragmentDoc}`;
export type GetTopEventsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTopEventsQuery, GetTopEventsQueryVariables>, 'query'> & ({ variables: GetTopEventsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTopEventsComponent = (props: GetTopEventsComponentProps) => (
      <ApolloReactComponents.Query<GetTopEventsQuery, GetTopEventsQueryVariables> query={GetTopEventsDocument} {...props} />
    );
    

/**
 * __useGetTopEventsQuery__
 *
 * To run a query within a React component, call `useGetTopEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopEventsQuery({
 *   variables: {
 *      endOn: // value for 'endOn'
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *   },
 * });
 */
export function useGetTopEventsQuery(baseOptions: Apollo.QueryHookOptions<GetTopEventsQuery, GetTopEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopEventsQuery, GetTopEventsQueryVariables>(GetTopEventsDocument, options);
      }
export function useGetTopEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopEventsQuery, GetTopEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopEventsQuery, GetTopEventsQueryVariables>(GetTopEventsDocument, options);
        }
export type GetTopEventsQueryHookResult = ReturnType<typeof useGetTopEventsQuery>;
export type GetTopEventsLazyQueryHookResult = ReturnType<typeof useGetTopEventsLazyQuery>;
export type GetTopEventsQueryResult = Apollo.QueryResult<GetTopEventsQuery, GetTopEventsQueryVariables>;
export const GetWeeklyPlansDocument = gql`
    query getWeeklyPlans($endOn: ISO8601Date!, $locationId: ID!, $startOn: ISO8601Date!) {
  weeklyPlans(endOn: $endOn, locationId: $locationId, startOn: $startOn) {
    ...Plan
  }
}
    ${PlanFragmentDoc}`;
export type GetWeeklyPlansComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWeeklyPlansQuery, GetWeeklyPlansQueryVariables>, 'query'> & ({ variables: GetWeeklyPlansQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWeeklyPlansComponent = (props: GetWeeklyPlansComponentProps) => (
      <ApolloReactComponents.Query<GetWeeklyPlansQuery, GetWeeklyPlansQueryVariables> query={GetWeeklyPlansDocument} {...props} />
    );
    

/**
 * __useGetWeeklyPlansQuery__
 *
 * To run a query within a React component, call `useGetWeeklyPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeeklyPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeeklyPlansQuery({
 *   variables: {
 *      endOn: // value for 'endOn'
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *   },
 * });
 */
export function useGetWeeklyPlansQuery(baseOptions: Apollo.QueryHookOptions<GetWeeklyPlansQuery, GetWeeklyPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeeklyPlansQuery, GetWeeklyPlansQueryVariables>(GetWeeklyPlansDocument, options);
      }
export function useGetWeeklyPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeeklyPlansQuery, GetWeeklyPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeeklyPlansQuery, GetWeeklyPlansQueryVariables>(GetWeeklyPlansDocument, options);
        }
export type GetWeeklyPlansQueryHookResult = ReturnType<typeof useGetWeeklyPlansQuery>;
export type GetWeeklyPlansLazyQueryHookResult = ReturnType<typeof useGetWeeklyPlansLazyQuery>;
export type GetWeeklyPlansQueryResult = Apollo.QueryResult<GetWeeklyPlansQuery, GetWeeklyPlansQueryVariables>;
export const GetForecastedItemsDocument = gql`
    query getForecastedItems($locationId: ID!, $startOn: ISO8601Date!, $valueBy: ForecastedItemsValueBy!) {
  forecastedItems(locationId: $locationId, startOn: $startOn, valueBy: $valueBy) {
    ...ItemsForecast
  }
}
    ${ItemsForecastFragmentDoc}`;
export type GetForecastedItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetForecastedItemsQuery, GetForecastedItemsQueryVariables>, 'query'> & ({ variables: GetForecastedItemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetForecastedItemsComponent = (props: GetForecastedItemsComponentProps) => (
      <ApolloReactComponents.Query<GetForecastedItemsQuery, GetForecastedItemsQueryVariables> query={GetForecastedItemsDocument} {...props} />
    );
    

/**
 * __useGetForecastedItemsQuery__
 *
 * To run a query within a React component, call `useGetForecastedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForecastedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForecastedItemsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *      valueBy: // value for 'valueBy'
 *   },
 * });
 */
export function useGetForecastedItemsQuery(baseOptions: Apollo.QueryHookOptions<GetForecastedItemsQuery, GetForecastedItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetForecastedItemsQuery, GetForecastedItemsQueryVariables>(GetForecastedItemsDocument, options);
      }
export function useGetForecastedItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetForecastedItemsQuery, GetForecastedItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetForecastedItemsQuery, GetForecastedItemsQueryVariables>(GetForecastedItemsDocument, options);
        }
export type GetForecastedItemsQueryHookResult = ReturnType<typeof useGetForecastedItemsQuery>;
export type GetForecastedItemsLazyQueryHookResult = ReturnType<typeof useGetForecastedItemsLazyQuery>;
export type GetForecastedItemsQueryResult = Apollo.QueryResult<GetForecastedItemsQuery, GetForecastedItemsQueryVariables>;
export const GetWeeklyPlanSummariesDocument = gql`
    query getWeeklyPlanSummaries($endOn: ISO8601Date!, $locationId: ID!, $startOn: ISO8601Date!) {
  weeklyPlans(endOn: $endOn, locationId: $locationId, startOn: $startOn) {
    ...PlanSummaryLight
  }
}
    ${PlanSummaryLightFragmentDoc}`;
export type GetWeeklyPlanSummariesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWeeklyPlanSummariesQuery, GetWeeklyPlanSummariesQueryVariables>, 'query'> & ({ variables: GetWeeklyPlanSummariesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWeeklyPlanSummariesComponent = (props: GetWeeklyPlanSummariesComponentProps) => (
      <ApolloReactComponents.Query<GetWeeklyPlanSummariesQuery, GetWeeklyPlanSummariesQueryVariables> query={GetWeeklyPlanSummariesDocument} {...props} />
    );
    

/**
 * __useGetWeeklyPlanSummariesQuery__
 *
 * To run a query within a React component, call `useGetWeeklyPlanSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeeklyPlanSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeeklyPlanSummariesQuery({
 *   variables: {
 *      endOn: // value for 'endOn'
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *   },
 * });
 */
export function useGetWeeklyPlanSummariesQuery(baseOptions: Apollo.QueryHookOptions<GetWeeklyPlanSummariesQuery, GetWeeklyPlanSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeeklyPlanSummariesQuery, GetWeeklyPlanSummariesQueryVariables>(GetWeeklyPlanSummariesDocument, options);
      }
export function useGetWeeklyPlanSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeeklyPlanSummariesQuery, GetWeeklyPlanSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeeklyPlanSummariesQuery, GetWeeklyPlanSummariesQueryVariables>(GetWeeklyPlanSummariesDocument, options);
        }
export type GetWeeklyPlanSummariesQueryHookResult = ReturnType<typeof useGetWeeklyPlanSummariesQuery>;
export type GetWeeklyPlanSummariesLazyQueryHookResult = ReturnType<typeof useGetWeeklyPlanSummariesLazyQuery>;
export type GetWeeklyPlanSummariesQueryResult = Apollo.QueryResult<GetWeeklyPlanSummariesQuery, GetWeeklyPlanSummariesQueryVariables>;
export const GetWeeklyPlanSummariesWithDailySummariesDocument = gql`
    query getWeeklyPlanSummariesWithDailySummaries($endOn: ISO8601Date!, $locationId: ID!, $startOn: ISO8601Date!) {
  weeklyPlans(endOn: $endOn, locationId: $locationId, startOn: $startOn) {
    ...PlanSummaryWithDailySummaries
  }
}
    ${PlanSummaryWithDailySummariesFragmentDoc}`;
export type GetWeeklyPlanSummariesWithDailySummariesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWeeklyPlanSummariesWithDailySummariesQuery, GetWeeklyPlanSummariesWithDailySummariesQueryVariables>, 'query'> & ({ variables: GetWeeklyPlanSummariesWithDailySummariesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWeeklyPlanSummariesWithDailySummariesComponent = (props: GetWeeklyPlanSummariesWithDailySummariesComponentProps) => (
      <ApolloReactComponents.Query<GetWeeklyPlanSummariesWithDailySummariesQuery, GetWeeklyPlanSummariesWithDailySummariesQueryVariables> query={GetWeeklyPlanSummariesWithDailySummariesDocument} {...props} />
    );
    

/**
 * __useGetWeeklyPlanSummariesWithDailySummariesQuery__
 *
 * To run a query within a React component, call `useGetWeeklyPlanSummariesWithDailySummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeeklyPlanSummariesWithDailySummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeeklyPlanSummariesWithDailySummariesQuery({
 *   variables: {
 *      endOn: // value for 'endOn'
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *   },
 * });
 */
export function useGetWeeklyPlanSummariesWithDailySummariesQuery(baseOptions: Apollo.QueryHookOptions<GetWeeklyPlanSummariesWithDailySummariesQuery, GetWeeklyPlanSummariesWithDailySummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeeklyPlanSummariesWithDailySummariesQuery, GetWeeklyPlanSummariesWithDailySummariesQueryVariables>(GetWeeklyPlanSummariesWithDailySummariesDocument, options);
      }
export function useGetWeeklyPlanSummariesWithDailySummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeeklyPlanSummariesWithDailySummariesQuery, GetWeeklyPlanSummariesWithDailySummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeeklyPlanSummariesWithDailySummariesQuery, GetWeeklyPlanSummariesWithDailySummariesQueryVariables>(GetWeeklyPlanSummariesWithDailySummariesDocument, options);
        }
export type GetWeeklyPlanSummariesWithDailySummariesQueryHookResult = ReturnType<typeof useGetWeeklyPlanSummariesWithDailySummariesQuery>;
export type GetWeeklyPlanSummariesWithDailySummariesLazyQueryHookResult = ReturnType<typeof useGetWeeklyPlanSummariesWithDailySummariesLazyQuery>;
export type GetWeeklyPlanSummariesWithDailySummariesQueryResult = Apollo.QueryResult<GetWeeklyPlanSummariesWithDailySummariesQuery, GetWeeklyPlanSummariesWithDailySummariesQueryVariables>;
export const GetWeatherCurrentDocument = gql`
    query getWeatherCurrent($locationId: ID!) {
  weatherCurrent(locationId: $locationId) {
    temperatureFahrenheit
    conditionCode
    conditionName
    iconCode
  }
}
    `;
export type GetWeatherCurrentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWeatherCurrentQuery, GetWeatherCurrentQueryVariables>, 'query'> & ({ variables: GetWeatherCurrentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWeatherCurrentComponent = (props: GetWeatherCurrentComponentProps) => (
      <ApolloReactComponents.Query<GetWeatherCurrentQuery, GetWeatherCurrentQueryVariables> query={GetWeatherCurrentDocument} {...props} />
    );
    

/**
 * __useGetWeatherCurrentQuery__
 *
 * To run a query within a React component, call `useGetWeatherCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeatherCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeatherCurrentQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetWeatherCurrentQuery(baseOptions: Apollo.QueryHookOptions<GetWeatherCurrentQuery, GetWeatherCurrentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeatherCurrentQuery, GetWeatherCurrentQueryVariables>(GetWeatherCurrentDocument, options);
      }
export function useGetWeatherCurrentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeatherCurrentQuery, GetWeatherCurrentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeatherCurrentQuery, GetWeatherCurrentQueryVariables>(GetWeatherCurrentDocument, options);
        }
export type GetWeatherCurrentQueryHookResult = ReturnType<typeof useGetWeatherCurrentQuery>;
export type GetWeatherCurrentLazyQueryHookResult = ReturnType<typeof useGetWeatherCurrentLazyQuery>;
export type GetWeatherCurrentQueryResult = Apollo.QueryResult<GetWeatherCurrentQuery, GetWeatherCurrentQueryVariables>;
export const GetWeatherDailyDocument = gql`
    query getWeatherDaily($locationId: ID!, $startOn: ISO8601Date!, $endOn: ISO8601Date!) {
  weatherDaily(locationId: $locationId, startOn: $startOn, endOn: $endOn) {
    id
    temperatureFahrenheit
    conditionCode
    conditionName
    date
    iconCode
  }
}
    `;
export type GetWeatherDailyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWeatherDailyQuery, GetWeatherDailyQueryVariables>, 'query'> & ({ variables: GetWeatherDailyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWeatherDailyComponent = (props: GetWeatherDailyComponentProps) => (
      <ApolloReactComponents.Query<GetWeatherDailyQuery, GetWeatherDailyQueryVariables> query={GetWeatherDailyDocument} {...props} />
    );
    

/**
 * __useGetWeatherDailyQuery__
 *
 * To run a query within a React component, call `useGetWeatherDailyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeatherDailyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeatherDailyQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *   },
 * });
 */
export function useGetWeatherDailyQuery(baseOptions: Apollo.QueryHookOptions<GetWeatherDailyQuery, GetWeatherDailyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeatherDailyQuery, GetWeatherDailyQueryVariables>(GetWeatherDailyDocument, options);
      }
export function useGetWeatherDailyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeatherDailyQuery, GetWeatherDailyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeatherDailyQuery, GetWeatherDailyQueryVariables>(GetWeatherDailyDocument, options);
        }
export type GetWeatherDailyQueryHookResult = ReturnType<typeof useGetWeatherDailyQuery>;
export type GetWeatherDailyLazyQueryHookResult = ReturnType<typeof useGetWeatherDailyLazyQuery>;
export type GetWeatherDailyQueryResult = Apollo.QueryResult<GetWeatherDailyQuery, GetWeatherDailyQueryVariables>;
export const GetLocationsForReportingDocument = gql`
    query getLocationsForReporting {
  locationsForReporting {
    ...LocationForReporting
  }
}
    ${LocationForReportingFragmentDoc}`;
export type GetLocationsForReportingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationsForReportingQuery, GetLocationsForReportingQueryVariables>, 'query'>;

    export const GetLocationsForReportingComponent = (props: GetLocationsForReportingComponentProps) => (
      <ApolloReactComponents.Query<GetLocationsForReportingQuery, GetLocationsForReportingQueryVariables> query={GetLocationsForReportingDocument} {...props} />
    );
    

/**
 * __useGetLocationsForReportingQuery__
 *
 * To run a query within a React component, call `useGetLocationsForReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsForReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsForReportingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsForReportingQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsForReportingQuery, GetLocationsForReportingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsForReportingQuery, GetLocationsForReportingQueryVariables>(GetLocationsForReportingDocument, options);
      }
export function useGetLocationsForReportingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsForReportingQuery, GetLocationsForReportingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsForReportingQuery, GetLocationsForReportingQueryVariables>(GetLocationsForReportingDocument, options);
        }
export type GetLocationsForReportingQueryHookResult = ReturnType<typeof useGetLocationsForReportingQuery>;
export type GetLocationsForReportingLazyQueryHookResult = ReturnType<typeof useGetLocationsForReportingLazyQuery>;
export type GetLocationsForReportingQueryResult = Apollo.QueryResult<GetLocationsForReportingQuery, GetLocationsForReportingQueryVariables>;
export const PreviewViolationsDocument = gql`
    query previewViolations($id: ID, $locationId: ID!, $startAt: ISO8601DateTime!, $endAt: ISO8601DateTime!, $employeeId: ID!, $shiftRoleId: ID, $shiftBreaks: [Int!], $isUnavailability: Boolean!) {
  previewViolations(
    id: $id
    locationId: $locationId
    startAt: $startAt
    endAt: $endAt
    employeeId: $employeeId
    shiftRoleId: $shiftRoleId
    shiftBreaks: $shiftBreaks
    isUnavailability: $isUnavailability
  ) {
    ...Violation
  }
}
    ${ViolationFragmentDoc}`;
export type PreviewViolationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PreviewViolationsQuery, PreviewViolationsQueryVariables>, 'query'> & ({ variables: PreviewViolationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PreviewViolationsComponent = (props: PreviewViolationsComponentProps) => (
      <ApolloReactComponents.Query<PreviewViolationsQuery, PreviewViolationsQueryVariables> query={PreviewViolationsDocument} {...props} />
    );
    

/**
 * __usePreviewViolationsQuery__
 *
 * To run a query within a React component, call `usePreviewViolationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewViolationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewViolationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locationId: // value for 'locationId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      employeeId: // value for 'employeeId'
 *      shiftRoleId: // value for 'shiftRoleId'
 *      shiftBreaks: // value for 'shiftBreaks'
 *      isUnavailability: // value for 'isUnavailability'
 *   },
 * });
 */
export function usePreviewViolationsQuery(baseOptions: Apollo.QueryHookOptions<PreviewViolationsQuery, PreviewViolationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewViolationsQuery, PreviewViolationsQueryVariables>(PreviewViolationsDocument, options);
      }
export function usePreviewViolationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewViolationsQuery, PreviewViolationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewViolationsQuery, PreviewViolationsQueryVariables>(PreviewViolationsDocument, options);
        }
export type PreviewViolationsQueryHookResult = ReturnType<typeof usePreviewViolationsQuery>;
export type PreviewViolationsLazyQueryHookResult = ReturnType<typeof usePreviewViolationsLazyQuery>;
export type PreviewViolationsQueryResult = Apollo.QueryResult<PreviewViolationsQuery, PreviewViolationsQueryVariables>;
export const GetViolationsPlanDocument = gql`
    query GetViolationsPlan($planId: ID!, $locationId: ID!) {
  violationsPlan(planId: $planId, locationId: $locationId) {
    ...Violation
  }
}
    ${ViolationFragmentDoc}`;
export type GetViolationsPlanComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetViolationsPlanQuery, GetViolationsPlanQueryVariables>, 'query'> & ({ variables: GetViolationsPlanQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetViolationsPlanComponent = (props: GetViolationsPlanComponentProps) => (
      <ApolloReactComponents.Query<GetViolationsPlanQuery, GetViolationsPlanQueryVariables> query={GetViolationsPlanDocument} {...props} />
    );
    

/**
 * __useGetViolationsPlanQuery__
 *
 * To run a query within a React component, call `useGetViolationsPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViolationsPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViolationsPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetViolationsPlanQuery(baseOptions: Apollo.QueryHookOptions<GetViolationsPlanQuery, GetViolationsPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetViolationsPlanQuery, GetViolationsPlanQueryVariables>(GetViolationsPlanDocument, options);
      }
export function useGetViolationsPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetViolationsPlanQuery, GetViolationsPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetViolationsPlanQuery, GetViolationsPlanQueryVariables>(GetViolationsPlanDocument, options);
        }
export type GetViolationsPlanQueryHookResult = ReturnType<typeof useGetViolationsPlanQuery>;
export type GetViolationsPlanLazyQueryHookResult = ReturnType<typeof useGetViolationsPlanLazyQuery>;
export type GetViolationsPlanQueryResult = Apollo.QueryResult<GetViolationsPlanQuery, GetViolationsPlanQueryVariables>;
export const GetCovidDashboardDocument = gql`
    query GetCovidDashboard($id: ID!) {
  covidDashboard(id: $id) {
    ...CovidDashboard
  }
}
    ${CovidDashboardFragmentDoc}`;
export type GetCovidDashboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCovidDashboardQuery, GetCovidDashboardQueryVariables>, 'query'> & ({ variables: GetCovidDashboardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCovidDashboardComponent = (props: GetCovidDashboardComponentProps) => (
      <ApolloReactComponents.Query<GetCovidDashboardQuery, GetCovidDashboardQueryVariables> query={GetCovidDashboardDocument} {...props} />
    );
    

/**
 * __useGetCovidDashboardQuery__
 *
 * To run a query within a React component, call `useGetCovidDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCovidDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCovidDashboardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCovidDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetCovidDashboardQuery, GetCovidDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCovidDashboardQuery, GetCovidDashboardQueryVariables>(GetCovidDashboardDocument, options);
      }
export function useGetCovidDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCovidDashboardQuery, GetCovidDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCovidDashboardQuery, GetCovidDashboardQueryVariables>(GetCovidDashboardDocument, options);
        }
export type GetCovidDashboardQueryHookResult = ReturnType<typeof useGetCovidDashboardQuery>;
export type GetCovidDashboardLazyQueryHookResult = ReturnType<typeof useGetCovidDashboardLazyQuery>;
export type GetCovidDashboardQueryResult = Apollo.QueryResult<GetCovidDashboardQuery, GetCovidDashboardQueryVariables>;
export const GetPlanTemplatesDocument = gql`
    query GetPlanTemplates($locationId: ID!) {
  planTemplates(locationId: $locationId) {
    ...PlanTemplate
  }
}
    ${PlanTemplateFragmentDoc}`;
export type GetPlanTemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlanTemplatesQuery, GetPlanTemplatesQueryVariables>, 'query'> & ({ variables: GetPlanTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPlanTemplatesComponent = (props: GetPlanTemplatesComponentProps) => (
      <ApolloReactComponents.Query<GetPlanTemplatesQuery, GetPlanTemplatesQueryVariables> query={GetPlanTemplatesDocument} {...props} />
    );
    

/**
 * __useGetPlanTemplatesQuery__
 *
 * To run a query within a React component, call `useGetPlanTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanTemplatesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetPlanTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GetPlanTemplatesQuery, GetPlanTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanTemplatesQuery, GetPlanTemplatesQueryVariables>(GetPlanTemplatesDocument, options);
      }
export function useGetPlanTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanTemplatesQuery, GetPlanTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanTemplatesQuery, GetPlanTemplatesQueryVariables>(GetPlanTemplatesDocument, options);
        }
export type GetPlanTemplatesQueryHookResult = ReturnType<typeof useGetPlanTemplatesQuery>;
export type GetPlanTemplatesLazyQueryHookResult = ReturnType<typeof useGetPlanTemplatesLazyQuery>;
export type GetPlanTemplatesQueryResult = Apollo.QueryResult<GetPlanTemplatesQuery, GetPlanTemplatesQueryVariables>;
export const GetOAuthDocument = gql`
    query GetOAuth($posType: String!) {
  oauthAuthorization(posType: $posType) {
    url
  }
}
    `;
export type GetOAuthComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOAuthQuery, GetOAuthQueryVariables>, 'query'> & ({ variables: GetOAuthQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOAuthComponent = (props: GetOAuthComponentProps) => (
      <ApolloReactComponents.Query<GetOAuthQuery, GetOAuthQueryVariables> query={GetOAuthDocument} {...props} />
    );
    

/**
 * __useGetOAuthQuery__
 *
 * To run a query within a React component, call `useGetOAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOAuthQuery({
 *   variables: {
 *      posType: // value for 'posType'
 *   },
 * });
 */
export function useGetOAuthQuery(baseOptions: Apollo.QueryHookOptions<GetOAuthQuery, GetOAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOAuthQuery, GetOAuthQueryVariables>(GetOAuthDocument, options);
      }
export function useGetOAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOAuthQuery, GetOAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOAuthQuery, GetOAuthQueryVariables>(GetOAuthDocument, options);
        }
export type GetOAuthQueryHookResult = ReturnType<typeof useGetOAuthQuery>;
export type GetOAuthLazyQueryHookResult = ReturnType<typeof useGetOAuthLazyQuery>;
export type GetOAuthQueryResult = Apollo.QueryResult<GetOAuthQuery, GetOAuthQueryVariables>;
export const GetDailyLaborHoursBreakDownDocument = gql`
    query getDailyLaborHoursBreakDown($locationId: ID!, $day: ISO8601Date!) {
  laborHoursDailyBreakdown(locationId: $locationId, day: $day) {
    laborHourEmployees {
      hours
      plannedHours
      shifts {
        id
        startAt
        endAt
      }
      employee {
        id
        name
      }
      shiftRoles {
        id
        name
      }
    }
    excludeFromLabor {
      hours
      plannedHours
      shifts {
        id
        startAt
        endAt
      }
      employee {
        id
        name
      }
      shiftRoles {
        id
        name
      }
    }
    totalLaborHoursExcluded
    totalLaborHours
    totalPlannedHours
    totalPlannedHoursExcluded
  }
}
    `;
export type GetDailyLaborHoursBreakDownComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDailyLaborHoursBreakDownQuery, GetDailyLaborHoursBreakDownQueryVariables>, 'query'> & ({ variables: GetDailyLaborHoursBreakDownQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDailyLaborHoursBreakDownComponent = (props: GetDailyLaborHoursBreakDownComponentProps) => (
      <ApolloReactComponents.Query<GetDailyLaborHoursBreakDownQuery, GetDailyLaborHoursBreakDownQueryVariables> query={GetDailyLaborHoursBreakDownDocument} {...props} />
    );
    

/**
 * __useGetDailyLaborHoursBreakDownQuery__
 *
 * To run a query within a React component, call `useGetDailyLaborHoursBreakDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyLaborHoursBreakDownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyLaborHoursBreakDownQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      day: // value for 'day'
 *   },
 * });
 */
export function useGetDailyLaborHoursBreakDownQuery(baseOptions: Apollo.QueryHookOptions<GetDailyLaborHoursBreakDownQuery, GetDailyLaborHoursBreakDownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailyLaborHoursBreakDownQuery, GetDailyLaborHoursBreakDownQueryVariables>(GetDailyLaborHoursBreakDownDocument, options);
      }
export function useGetDailyLaborHoursBreakDownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailyLaborHoursBreakDownQuery, GetDailyLaborHoursBreakDownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailyLaborHoursBreakDownQuery, GetDailyLaborHoursBreakDownQueryVariables>(GetDailyLaborHoursBreakDownDocument, options);
        }
export type GetDailyLaborHoursBreakDownQueryHookResult = ReturnType<typeof useGetDailyLaborHoursBreakDownQuery>;
export type GetDailyLaborHoursBreakDownLazyQueryHookResult = ReturnType<typeof useGetDailyLaborHoursBreakDownLazyQuery>;
export type GetDailyLaborHoursBreakDownQueryResult = Apollo.QueryResult<GetDailyLaborHoursBreakDownQuery, GetDailyLaborHoursBreakDownQueryVariables>;
export const GetHoursDateRangeBreakDownDocument = gql`
    query getHoursDateRangeBreakDown($locationId: ID!, $startAt: ISO8601Date!, $endAt: ISO8601Date!) {
  laborHoursDateRangeBreakdown(
    locationId: $locationId
    startAt: $startAt
    endAt: $endAt
  ) {
    totalWeeklyPlannedHours
    totalWeeklyHours
    totalWeeklyHoursExcluded
    totalWeeklyPlannedHoursExcluded
    dailyBreakDowns {
      day
      dailyBreakDown {
        excludeFromLabor {
          plannedHours
          hours
          shifts {
            id
            startAt
            endAt
          }
          shiftRoles {
            id
            name
          }
          employee {
            id
            name
          }
        }
        laborHourEmployees {
          plannedHours
          hours
          shifts {
            id
            startAt
            endAt
          }
          shiftRoles {
            id
            name
          }
          employee {
            id
            name
          }
        }
        totalLaborHoursExcluded
        totalLaborHours
        totalPlannedHours
        totalPlannedHoursExcluded
      }
    }
  }
}
    `;
export type GetHoursDateRangeBreakDownComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetHoursDateRangeBreakDownQuery, GetHoursDateRangeBreakDownQueryVariables>, 'query'> & ({ variables: GetHoursDateRangeBreakDownQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetHoursDateRangeBreakDownComponent = (props: GetHoursDateRangeBreakDownComponentProps) => (
      <ApolloReactComponents.Query<GetHoursDateRangeBreakDownQuery, GetHoursDateRangeBreakDownQueryVariables> query={GetHoursDateRangeBreakDownDocument} {...props} />
    );
    

/**
 * __useGetHoursDateRangeBreakDownQuery__
 *
 * To run a query within a React component, call `useGetHoursDateRangeBreakDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHoursDateRangeBreakDownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHoursDateRangeBreakDownQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useGetHoursDateRangeBreakDownQuery(baseOptions: Apollo.QueryHookOptions<GetHoursDateRangeBreakDownQuery, GetHoursDateRangeBreakDownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHoursDateRangeBreakDownQuery, GetHoursDateRangeBreakDownQueryVariables>(GetHoursDateRangeBreakDownDocument, options);
      }
export function useGetHoursDateRangeBreakDownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHoursDateRangeBreakDownQuery, GetHoursDateRangeBreakDownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHoursDateRangeBreakDownQuery, GetHoursDateRangeBreakDownQueryVariables>(GetHoursDateRangeBreakDownDocument, options);
        }
export type GetHoursDateRangeBreakDownQueryHookResult = ReturnType<typeof useGetHoursDateRangeBreakDownQuery>;
export type GetHoursDateRangeBreakDownLazyQueryHookResult = ReturnType<typeof useGetHoursDateRangeBreakDownLazyQuery>;
export type GetHoursDateRangeBreakDownQueryResult = Apollo.QueryResult<GetHoursDateRangeBreakDownQuery, GetHoursDateRangeBreakDownQueryVariables>;
export const GetRelevantShiftsEmployeeDocument = gql`
    query getRelevantShiftsEmployee($planId: ID!, $locationId: ID!) {
  relevantShiftsForPlan(planId: $planId, locationId: $locationId) {
    ...EmployeeShifts
  }
}
    ${EmployeeShiftsFragmentDoc}`;
export type GetRelevantShiftsEmployeeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRelevantShiftsEmployeeQuery, GetRelevantShiftsEmployeeQueryVariables>, 'query'> & ({ variables: GetRelevantShiftsEmployeeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRelevantShiftsEmployeeComponent = (props: GetRelevantShiftsEmployeeComponentProps) => (
      <ApolloReactComponents.Query<GetRelevantShiftsEmployeeQuery, GetRelevantShiftsEmployeeQueryVariables> query={GetRelevantShiftsEmployeeDocument} {...props} />
    );
    

/**
 * __useGetRelevantShiftsEmployeeQuery__
 *
 * To run a query within a React component, call `useGetRelevantShiftsEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelevantShiftsEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelevantShiftsEmployeeQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetRelevantShiftsEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetRelevantShiftsEmployeeQuery, GetRelevantShiftsEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRelevantShiftsEmployeeQuery, GetRelevantShiftsEmployeeQueryVariables>(GetRelevantShiftsEmployeeDocument, options);
      }
export function useGetRelevantShiftsEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRelevantShiftsEmployeeQuery, GetRelevantShiftsEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRelevantShiftsEmployeeQuery, GetRelevantShiftsEmployeeQueryVariables>(GetRelevantShiftsEmployeeDocument, options);
        }
export type GetRelevantShiftsEmployeeQueryHookResult = ReturnType<typeof useGetRelevantShiftsEmployeeQuery>;
export type GetRelevantShiftsEmployeeLazyQueryHookResult = ReturnType<typeof useGetRelevantShiftsEmployeeLazyQuery>;
export type GetRelevantShiftsEmployeeQueryResult = Apollo.QueryResult<GetRelevantShiftsEmployeeQuery, GetRelevantShiftsEmployeeQueryVariables>;
export const GetInternalEventsDocument = gql`
    query getInternalEvents($startOn: ISO8601Date!, $endOn: ISO8601Date!, $locationId: ID!) {
  internalEvents(startOn: $startOn, endOn: $endOn, locationId: $locationId) {
    id
    eventStartDate
    eventStartTime
    eventEndTime
    guestCount
    foodAndBeverageMinDollars
    rooms
    status
    externalId
    startAtTimeWithSetupTime
    endAtTimeWithTeardownTime
  }
}
    `;
export type GetInternalEventsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInternalEventsQuery, GetInternalEventsQueryVariables>, 'query'> & ({ variables: GetInternalEventsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInternalEventsComponent = (props: GetInternalEventsComponentProps) => (
      <ApolloReactComponents.Query<GetInternalEventsQuery, GetInternalEventsQueryVariables> query={GetInternalEventsDocument} {...props} />
    );
    

/**
 * __useGetInternalEventsQuery__
 *
 * To run a query within a React component, call `useGetInternalEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalEventsQuery({
 *   variables: {
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetInternalEventsQuery(baseOptions: Apollo.QueryHookOptions<GetInternalEventsQuery, GetInternalEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInternalEventsQuery, GetInternalEventsQueryVariables>(GetInternalEventsDocument, options);
      }
export function useGetInternalEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInternalEventsQuery, GetInternalEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInternalEventsQuery, GetInternalEventsQueryVariables>(GetInternalEventsDocument, options);
        }
export type GetInternalEventsQueryHookResult = ReturnType<typeof useGetInternalEventsQuery>;
export type GetInternalEventsLazyQueryHookResult = ReturnType<typeof useGetInternalEventsLazyQuery>;
export type GetInternalEventsQueryResult = Apollo.QueryResult<GetInternalEventsQuery, GetInternalEventsQueryVariables>;
export const GetSalesDayPartsDocument = gql`
    query getSalesDayParts($locationId: ID!) {
  salesDayParts(locationId: $locationId) {
    ...SalesDayPart
  }
}
    ${SalesDayPartFragmentDoc}`;
export type GetSalesDayPartsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSalesDayPartsQuery, GetSalesDayPartsQueryVariables>, 'query'> & ({ variables: GetSalesDayPartsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSalesDayPartsComponent = (props: GetSalesDayPartsComponentProps) => (
      <ApolloReactComponents.Query<GetSalesDayPartsQuery, GetSalesDayPartsQueryVariables> query={GetSalesDayPartsDocument} {...props} />
    );
    

/**
 * __useGetSalesDayPartsQuery__
 *
 * To run a query within a React component, call `useGetSalesDayPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesDayPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesDayPartsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetSalesDayPartsQuery(baseOptions: Apollo.QueryHookOptions<GetSalesDayPartsQuery, GetSalesDayPartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesDayPartsQuery, GetSalesDayPartsQueryVariables>(GetSalesDayPartsDocument, options);
      }
export function useGetSalesDayPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesDayPartsQuery, GetSalesDayPartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesDayPartsQuery, GetSalesDayPartsQueryVariables>(GetSalesDayPartsDocument, options);
        }
export type GetSalesDayPartsQueryHookResult = ReturnType<typeof useGetSalesDayPartsQuery>;
export type GetSalesDayPartsLazyQueryHookResult = ReturnType<typeof useGetSalesDayPartsLazyQuery>;
export type GetSalesDayPartsQueryResult = Apollo.QueryResult<GetSalesDayPartsQuery, GetSalesDayPartsQueryVariables>;
export const GetReservationsDocument = gql`
    query getReservations($startOn: ISO8601Date!, $endOn: ISO8601Date!, $locationId: ID!) {
  reservations(startDate: $startOn, endDate: $endOn, locationId: $locationId) {
    id
    fiscalDate
    largeParty
    scheduledTimeLocalFormatted
    partySize
    scheduledTime
    state
    reservationUrl
  }
}
    `;
export type GetReservationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReservationsQuery, GetReservationsQueryVariables>, 'query'> & ({ variables: GetReservationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetReservationsComponent = (props: GetReservationsComponentProps) => (
      <ApolloReactComponents.Query<GetReservationsQuery, GetReservationsQueryVariables> query={GetReservationsDocument} {...props} />
    );
    

/**
 * __useGetReservationsQuery__
 *
 * To run a query within a React component, call `useGetReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsQuery({
 *   variables: {
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetReservationsQuery(baseOptions: Apollo.QueryHookOptions<GetReservationsQuery, GetReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsQuery, GetReservationsQueryVariables>(GetReservationsDocument, options);
      }
export function useGetReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsQuery, GetReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsQuery, GetReservationsQueryVariables>(GetReservationsDocument, options);
        }
export type GetReservationsQueryHookResult = ReturnType<typeof useGetReservationsQuery>;
export type GetReservationsLazyQueryHookResult = ReturnType<typeof useGetReservationsLazyQuery>;
export type GetReservationsQueryResult = Apollo.QueryResult<GetReservationsQuery, GetReservationsQueryVariables>;
export const GetShiftsPerLocationsDocument = gql`
    query getShiftsPerLocations($locationIds: [ID!]!, $startAt: ISO8601DateTime!, $endAt: ISO8601DateTime!) {
  shiftsPerLocations(locationIds: $locationIds, startAt: $startAt, endAt: $endAt) {
    id
  }
}
    `;
export type GetShiftsPerLocationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetShiftsPerLocationsQuery, GetShiftsPerLocationsQueryVariables>, 'query'> & ({ variables: GetShiftsPerLocationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetShiftsPerLocationsComponent = (props: GetShiftsPerLocationsComponentProps) => (
      <ApolloReactComponents.Query<GetShiftsPerLocationsQuery, GetShiftsPerLocationsQueryVariables> query={GetShiftsPerLocationsDocument} {...props} />
    );
    

/**
 * __useGetShiftsPerLocationsQuery__
 *
 * To run a query within a React component, call `useGetShiftsPerLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftsPerLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftsPerLocationsQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useGetShiftsPerLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetShiftsPerLocationsQuery, GetShiftsPerLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShiftsPerLocationsQuery, GetShiftsPerLocationsQueryVariables>(GetShiftsPerLocationsDocument, options);
      }
export function useGetShiftsPerLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShiftsPerLocationsQuery, GetShiftsPerLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShiftsPerLocationsQuery, GetShiftsPerLocationsQueryVariables>(GetShiftsPerLocationsDocument, options);
        }
export type GetShiftsPerLocationsQueryHookResult = ReturnType<typeof useGetShiftsPerLocationsQuery>;
export type GetShiftsPerLocationsLazyQueryHookResult = ReturnType<typeof useGetShiftsPerLocationsLazyQuery>;
export type GetShiftsPerLocationsQueryResult = Apollo.QueryResult<GetShiftsPerLocationsQuery, GetShiftsPerLocationsQueryVariables>;
export const UpsertTimeOffBlockedDateDocument = gql`
    mutation upsertTimeOffBlockedDate($input: UpsertTimeOffBlockedDateMutationInput!) {
  upsertTimeOffBlockedDate(input: $input) {
    success
    blockedDate {
      ...TimeOffBlockedDate
    }
  }
}
    ${TimeOffBlockedDateFragmentDoc}`;
export type UpsertTimeOffBlockedDateMutationFn = Apollo.MutationFunction<UpsertTimeOffBlockedDateMutation, UpsertTimeOffBlockedDateMutationVariables>;
export type UpsertTimeOffBlockedDateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertTimeOffBlockedDateMutation, UpsertTimeOffBlockedDateMutationVariables>, 'mutation'>;

    export const UpsertTimeOffBlockedDateComponent = (props: UpsertTimeOffBlockedDateComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertTimeOffBlockedDateMutation, UpsertTimeOffBlockedDateMutationVariables> mutation={UpsertTimeOffBlockedDateDocument} {...props} />
    );
    

/**
 * __useUpsertTimeOffBlockedDateMutation__
 *
 * To run a mutation, you first call `useUpsertTimeOffBlockedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTimeOffBlockedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTimeOffBlockedDateMutation, { data, loading, error }] = useUpsertTimeOffBlockedDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTimeOffBlockedDateMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTimeOffBlockedDateMutation, UpsertTimeOffBlockedDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTimeOffBlockedDateMutation, UpsertTimeOffBlockedDateMutationVariables>(UpsertTimeOffBlockedDateDocument, options);
      }
export type UpsertTimeOffBlockedDateMutationHookResult = ReturnType<typeof useUpsertTimeOffBlockedDateMutation>;
export type UpsertTimeOffBlockedDateMutationResult = Apollo.MutationResult<UpsertTimeOffBlockedDateMutation>;
export type UpsertTimeOffBlockedDateMutationOptions = Apollo.BaseMutationOptions<UpsertTimeOffBlockedDateMutation, UpsertTimeOffBlockedDateMutationVariables>;
export const DeleteTimeOffBlockedDateDocument = gql`
    mutation deleteTimeOffBlockedDate($input: DeleteTimeOffBlockedDateMutationInput!) {
  deleteTimeOffBlockedDate(input: $input) {
    success
  }
}
    `;
export type DeleteTimeOffBlockedDateMutationFn = Apollo.MutationFunction<DeleteTimeOffBlockedDateMutation, DeleteTimeOffBlockedDateMutationVariables>;
export type DeleteTimeOffBlockedDateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTimeOffBlockedDateMutation, DeleteTimeOffBlockedDateMutationVariables>, 'mutation'>;

    export const DeleteTimeOffBlockedDateComponent = (props: DeleteTimeOffBlockedDateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTimeOffBlockedDateMutation, DeleteTimeOffBlockedDateMutationVariables> mutation={DeleteTimeOffBlockedDateDocument} {...props} />
    );
    

/**
 * __useDeleteTimeOffBlockedDateMutation__
 *
 * To run a mutation, you first call `useDeleteTimeOffBlockedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeOffBlockedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeOffBlockedDateMutation, { data, loading, error }] = useDeleteTimeOffBlockedDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTimeOffBlockedDateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeOffBlockedDateMutation, DeleteTimeOffBlockedDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeOffBlockedDateMutation, DeleteTimeOffBlockedDateMutationVariables>(DeleteTimeOffBlockedDateDocument, options);
      }
export type DeleteTimeOffBlockedDateMutationHookResult = ReturnType<typeof useDeleteTimeOffBlockedDateMutation>;
export type DeleteTimeOffBlockedDateMutationResult = Apollo.MutationResult<DeleteTimeOffBlockedDateMutation>;
export type DeleteTimeOffBlockedDateMutationOptions = Apollo.BaseMutationOptions<DeleteTimeOffBlockedDateMutation, DeleteTimeOffBlockedDateMutationVariables>;
export const UpsertLocationTimeOffBlockedDatesDocument = gql`
    mutation upsertLocationTimeOffBlockedDates($input: UpsertLocationTimeOffBlockedDatesMutationInput!) {
  upsertLocationTimeOffBlockedDates(input: $input) {
    success
  }
}
    `;
export type UpsertLocationTimeOffBlockedDatesMutationFn = Apollo.MutationFunction<UpsertLocationTimeOffBlockedDatesMutation, UpsertLocationTimeOffBlockedDatesMutationVariables>;
export type UpsertLocationTimeOffBlockedDatesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertLocationTimeOffBlockedDatesMutation, UpsertLocationTimeOffBlockedDatesMutationVariables>, 'mutation'>;

    export const UpsertLocationTimeOffBlockedDatesComponent = (props: UpsertLocationTimeOffBlockedDatesComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertLocationTimeOffBlockedDatesMutation, UpsertLocationTimeOffBlockedDatesMutationVariables> mutation={UpsertLocationTimeOffBlockedDatesDocument} {...props} />
    );
    

/**
 * __useUpsertLocationTimeOffBlockedDatesMutation__
 *
 * To run a mutation, you first call `useUpsertLocationTimeOffBlockedDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLocationTimeOffBlockedDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLocationTimeOffBlockedDatesMutation, { data, loading, error }] = useUpsertLocationTimeOffBlockedDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLocationTimeOffBlockedDatesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLocationTimeOffBlockedDatesMutation, UpsertLocationTimeOffBlockedDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLocationTimeOffBlockedDatesMutation, UpsertLocationTimeOffBlockedDatesMutationVariables>(UpsertLocationTimeOffBlockedDatesDocument, options);
      }
export type UpsertLocationTimeOffBlockedDatesMutationHookResult = ReturnType<typeof useUpsertLocationTimeOffBlockedDatesMutation>;
export type UpsertLocationTimeOffBlockedDatesMutationResult = Apollo.MutationResult<UpsertLocationTimeOffBlockedDatesMutation>;
export type UpsertLocationTimeOffBlockedDatesMutationOptions = Apollo.BaseMutationOptions<UpsertLocationTimeOffBlockedDatesMutation, UpsertLocationTimeOffBlockedDatesMutationVariables>;
export const UpsertSalesDayPartsDocument = gql`
    mutation upsertSalesDayParts($input: UpsertSalesDayPartsMutationInput!) {
  upsertSalesDayParts(input: $input) {
    success
  }
}
    `;
export type UpsertSalesDayPartsMutationFn = Apollo.MutationFunction<UpsertSalesDayPartsMutation, UpsertSalesDayPartsMutationVariables>;
export type UpsertSalesDayPartsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertSalesDayPartsMutation, UpsertSalesDayPartsMutationVariables>, 'mutation'>;

    export const UpsertSalesDayPartsComponent = (props: UpsertSalesDayPartsComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertSalesDayPartsMutation, UpsertSalesDayPartsMutationVariables> mutation={UpsertSalesDayPartsDocument} {...props} />
    );
    

/**
 * __useUpsertSalesDayPartsMutation__
 *
 * To run a mutation, you first call `useUpsertSalesDayPartsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSalesDayPartsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSalesDayPartsMutation, { data, loading, error }] = useUpsertSalesDayPartsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSalesDayPartsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSalesDayPartsMutation, UpsertSalesDayPartsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSalesDayPartsMutation, UpsertSalesDayPartsMutationVariables>(UpsertSalesDayPartsDocument, options);
      }
export type UpsertSalesDayPartsMutationHookResult = ReturnType<typeof useUpsertSalesDayPartsMutation>;
export type UpsertSalesDayPartsMutationResult = Apollo.MutationResult<UpsertSalesDayPartsMutation>;
export type UpsertSalesDayPartsMutationOptions = Apollo.BaseMutationOptions<UpsertSalesDayPartsMutation, UpsertSalesDayPartsMutationVariables>;
export const DeleteSalesDayPartsDocument = gql`
    mutation deleteSalesDayParts($input: DeleteSalesDayPartsMutationInput!) {
  deleteSalesDayParts(input: $input) {
    success
  }
}
    `;
export type DeleteSalesDayPartsMutationFn = Apollo.MutationFunction<DeleteSalesDayPartsMutation, DeleteSalesDayPartsMutationVariables>;
export type DeleteSalesDayPartsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSalesDayPartsMutation, DeleteSalesDayPartsMutationVariables>, 'mutation'>;

    export const DeleteSalesDayPartsComponent = (props: DeleteSalesDayPartsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSalesDayPartsMutation, DeleteSalesDayPartsMutationVariables> mutation={DeleteSalesDayPartsDocument} {...props} />
    );
    

/**
 * __useDeleteSalesDayPartsMutation__
 *
 * To run a mutation, you first call `useDeleteSalesDayPartsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesDayPartsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesDayPartsMutation, { data, loading, error }] = useDeleteSalesDayPartsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSalesDayPartsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSalesDayPartsMutation, DeleteSalesDayPartsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSalesDayPartsMutation, DeleteSalesDayPartsMutationVariables>(DeleteSalesDayPartsDocument, options);
      }
export type DeleteSalesDayPartsMutationHookResult = ReturnType<typeof useDeleteSalesDayPartsMutation>;
export type DeleteSalesDayPartsMutationResult = Apollo.MutationResult<DeleteSalesDayPartsMutation>;
export type DeleteSalesDayPartsMutationOptions = Apollo.BaseMutationOptions<DeleteSalesDayPartsMutation, DeleteSalesDayPartsMutationVariables>;
export const CreateEmployeeDocument = gql`
    mutation createEmployee($input: CreateEmployeeMutationInput!) {
  createEmployee(input: $input) {
    success
    errors
    employee {
      ...Employee
    }
  }
}
    ${EmployeeFragmentDoc}`;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export type CreateEmployeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>, 'mutation'>;

    export const CreateEmployeeComponent = (props: CreateEmployeeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEmployeeMutation, CreateEmployeeMutationVariables> mutation={CreateEmployeeDocument} {...props} />
    );
    

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, options);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation updateEmployee($input: UpdateEmployeeMutationInput!) {
  updateEmployee(input: $input) {
    success
    errors
    employee {
      ...Employee
    }
  }
}
    ${EmployeeFragmentDoc}`;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export type UpdateEmployeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>, 'mutation'>;

    export const UpdateEmployeeComponent = (props: UpdateEmployeeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables> mutation={UpdateEmployeeDocument} {...props} />
    );
    

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const UpdateEmployeeMinMaxHoursDocument = gql`
    mutation updateEmployeeMinMaxHours($input: UpdateEmployeeMinMaxHoursInput!) {
  updateEmployeeMinMaxHours(input: $input) {
    success
    errors
    employee {
      ...Employee
    }
  }
}
    ${EmployeeFragmentDoc}`;
export type UpdateEmployeeMinMaxHoursMutationFn = Apollo.MutationFunction<UpdateEmployeeMinMaxHoursMutation, UpdateEmployeeMinMaxHoursMutationVariables>;
export type UpdateEmployeeMinMaxHoursComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEmployeeMinMaxHoursMutation, UpdateEmployeeMinMaxHoursMutationVariables>, 'mutation'>;

    export const UpdateEmployeeMinMaxHoursComponent = (props: UpdateEmployeeMinMaxHoursComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEmployeeMinMaxHoursMutation, UpdateEmployeeMinMaxHoursMutationVariables> mutation={UpdateEmployeeMinMaxHoursDocument} {...props} />
    );
    

/**
 * __useUpdateEmployeeMinMaxHoursMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMinMaxHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMinMaxHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMinMaxHoursMutation, { data, loading, error }] = useUpdateEmployeeMinMaxHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMinMaxHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMinMaxHoursMutation, UpdateEmployeeMinMaxHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMinMaxHoursMutation, UpdateEmployeeMinMaxHoursMutationVariables>(UpdateEmployeeMinMaxHoursDocument, options);
      }
export type UpdateEmployeeMinMaxHoursMutationHookResult = ReturnType<typeof useUpdateEmployeeMinMaxHoursMutation>;
export type UpdateEmployeeMinMaxHoursMutationResult = Apollo.MutationResult<UpdateEmployeeMinMaxHoursMutation>;
export type UpdateEmployeeMinMaxHoursMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMinMaxHoursMutation, UpdateEmployeeMinMaxHoursMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($input: UpdateOrganizationMutationInput!) {
  updateOrganization(input: $input) {
    organization {
      ...OrganizationSettings
    }
  }
}
    ${OrganizationSettingsFragmentDoc}`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export type UpdateOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>, 'mutation'>;

    export const UpdateOrganizationComponent = (props: UpdateOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> mutation={UpdateOrganizationDocument} {...props} />
    );
    

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation updateLocation($input: UpdateLocationMutationInput!) {
  updateLocation(input: $input) {
    location {
      ...LocationSummary
    }
  }
}
    ${LocationSummaryFragmentDoc}`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;
export type UpdateLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLocationMutation, UpdateLocationMutationVariables>, 'mutation'>;

    export const UpdateLocationComponent = (props: UpdateLocationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLocationMutation, UpdateLocationMutationVariables> mutation={UpdateLocationDocument} {...props} />
    );
    

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const UpdateEmployeeLocationsDocument = gql`
    mutation updateEmployeeLocations($input: UpdateEmployeeLocationsMutationInput!) {
  updateEmployeeLocation(input: $input) {
    success
    errors
  }
}
    `;
export type UpdateEmployeeLocationsMutationFn = Apollo.MutationFunction<UpdateEmployeeLocationsMutation, UpdateEmployeeLocationsMutationVariables>;
export type UpdateEmployeeLocationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEmployeeLocationsMutation, UpdateEmployeeLocationsMutationVariables>, 'mutation'>;

    export const UpdateEmployeeLocationsComponent = (props: UpdateEmployeeLocationsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEmployeeLocationsMutation, UpdateEmployeeLocationsMutationVariables> mutation={UpdateEmployeeLocationsDocument} {...props} />
    );
    

/**
 * __useUpdateEmployeeLocationsMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeLocationsMutation, { data, loading, error }] = useUpdateEmployeeLocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeLocationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeLocationsMutation, UpdateEmployeeLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeLocationsMutation, UpdateEmployeeLocationsMutationVariables>(UpdateEmployeeLocationsDocument, options);
      }
export type UpdateEmployeeLocationsMutationHookResult = ReturnType<typeof useUpdateEmployeeLocationsMutation>;
export type UpdateEmployeeLocationsMutationResult = Apollo.MutationResult<UpdateEmployeeLocationsMutation>;
export type UpdateEmployeeLocationsMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeLocationsMutation, UpdateEmployeeLocationsMutationVariables>;
export const DeleteEmployeeLocationsDocument = gql`
    mutation deleteEmployeeLocations($input: DeleteEmployeeLocationsMutationInput!) {
  deleteEmployeeLocations(input: $input) {
    success
    errors
    employeeLocations {
      ...EmployeeLocation
    }
  }
}
    ${EmployeeLocationFragmentDoc}`;
export type DeleteEmployeeLocationsMutationFn = Apollo.MutationFunction<DeleteEmployeeLocationsMutation, DeleteEmployeeLocationsMutationVariables>;
export type DeleteEmployeeLocationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteEmployeeLocationsMutation, DeleteEmployeeLocationsMutationVariables>, 'mutation'>;

    export const DeleteEmployeeLocationsComponent = (props: DeleteEmployeeLocationsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteEmployeeLocationsMutation, DeleteEmployeeLocationsMutationVariables> mutation={DeleteEmployeeLocationsDocument} {...props} />
    );
    

/**
 * __useDeleteEmployeeLocationsMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeLocationsMutation, { data, loading, error }] = useDeleteEmployeeLocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEmployeeLocationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeLocationsMutation, DeleteEmployeeLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeLocationsMutation, DeleteEmployeeLocationsMutationVariables>(DeleteEmployeeLocationsDocument, options);
      }
export type DeleteEmployeeLocationsMutationHookResult = ReturnType<typeof useDeleteEmployeeLocationsMutation>;
export type DeleteEmployeeLocationsMutationResult = Apollo.MutationResult<DeleteEmployeeLocationsMutation>;
export type DeleteEmployeeLocationsMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeLocationsMutation, DeleteEmployeeLocationsMutationVariables>;
export const SquareRevokeAuthorizationDocument = gql`
    mutation squareRevokeAuthorization {
  squareRevokeAuthorization(input: {}) {
    success
  }
}
    `;
export type SquareRevokeAuthorizationMutationFn = Apollo.MutationFunction<SquareRevokeAuthorizationMutation, SquareRevokeAuthorizationMutationVariables>;
export type SquareRevokeAuthorizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SquareRevokeAuthorizationMutation, SquareRevokeAuthorizationMutationVariables>, 'mutation'>;

    export const SquareRevokeAuthorizationComponent = (props: SquareRevokeAuthorizationComponentProps) => (
      <ApolloReactComponents.Mutation<SquareRevokeAuthorizationMutation, SquareRevokeAuthorizationMutationVariables> mutation={SquareRevokeAuthorizationDocument} {...props} />
    );
    

/**
 * __useSquareRevokeAuthorizationMutation__
 *
 * To run a mutation, you first call `useSquareRevokeAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquareRevokeAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squareRevokeAuthorizationMutation, { data, loading, error }] = useSquareRevokeAuthorizationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSquareRevokeAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<SquareRevokeAuthorizationMutation, SquareRevokeAuthorizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SquareRevokeAuthorizationMutation, SquareRevokeAuthorizationMutationVariables>(SquareRevokeAuthorizationDocument, options);
      }
export type SquareRevokeAuthorizationMutationHookResult = ReturnType<typeof useSquareRevokeAuthorizationMutation>;
export type SquareRevokeAuthorizationMutationResult = Apollo.MutationResult<SquareRevokeAuthorizationMutation>;
export type SquareRevokeAuthorizationMutationOptions = Apollo.BaseMutationOptions<SquareRevokeAuthorizationMutation, SquareRevokeAuthorizationMutationVariables>;
export const SquareHistoricImportDocument = gql`
    mutation squareHistoricImport($locationId: ID!) {
  squareHistoricImport(input: {locationId: $locationId}) {
    location {
      ...Location
    }
  }
}
    ${LocationFragmentDoc}`;
export type SquareHistoricImportMutationFn = Apollo.MutationFunction<SquareHistoricImportMutation, SquareHistoricImportMutationVariables>;
export type SquareHistoricImportComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SquareHistoricImportMutation, SquareHistoricImportMutationVariables>, 'mutation'>;

    export const SquareHistoricImportComponent = (props: SquareHistoricImportComponentProps) => (
      <ApolloReactComponents.Mutation<SquareHistoricImportMutation, SquareHistoricImportMutationVariables> mutation={SquareHistoricImportDocument} {...props} />
    );
    

/**
 * __useSquareHistoricImportMutation__
 *
 * To run a mutation, you first call `useSquareHistoricImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquareHistoricImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squareHistoricImportMutation, { data, loading, error }] = useSquareHistoricImportMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useSquareHistoricImportMutation(baseOptions?: Apollo.MutationHookOptions<SquareHistoricImportMutation, SquareHistoricImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SquareHistoricImportMutation, SquareHistoricImportMutationVariables>(SquareHistoricImportDocument, options);
      }
export type SquareHistoricImportMutationHookResult = ReturnType<typeof useSquareHistoricImportMutation>;
export type SquareHistoricImportMutationResult = Apollo.MutationResult<SquareHistoricImportMutation>;
export type SquareHistoricImportMutationOptions = Apollo.BaseMutationOptions<SquareHistoricImportMutation, SquareHistoricImportMutationVariables>;
export const CreateSessionDocument = gql`
    mutation createSession($input: LoginMutationInput!) {
  login(input: $input) {
    success
    errorCode
    user {
      ...CurrentUserInfo
    }
  }
}
    ${CurrentUserInfoFragmentDoc}`;
export type CreateSessionMutationFn = Apollo.MutationFunction<CreateSessionMutation, CreateSessionMutationVariables>;
export type CreateSessionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSessionMutation, CreateSessionMutationVariables>, 'mutation'>;

    export const CreateSessionComponent = (props: CreateSessionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSessionMutation, CreateSessionMutationVariables> mutation={CreateSessionDocument} {...props} />
    );
    

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, options);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export type CreateSessionMutationResult = Apollo.MutationResult<CreateSessionMutation>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<CreateSessionMutation, CreateSessionMutationVariables>;
export const DeleteForecastDocument = gql`
    mutation deleteForecast($input: DeleteForecastMutationInput!) {
  deleteForecast(input: $input) {
    success
  }
}
    `;
export type DeleteForecastMutationFn = Apollo.MutationFunction<DeleteForecastMutation, DeleteForecastMutationVariables>;
export type DeleteForecastComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteForecastMutation, DeleteForecastMutationVariables>, 'mutation'>;

    export const DeleteForecastComponent = (props: DeleteForecastComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteForecastMutation, DeleteForecastMutationVariables> mutation={DeleteForecastDocument} {...props} />
    );
    

/**
 * __useDeleteForecastMutation__
 *
 * To run a mutation, you first call `useDeleteForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteForecastMutation, { data, loading, error }] = useDeleteForecastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteForecastMutation(baseOptions?: Apollo.MutationHookOptions<DeleteForecastMutation, DeleteForecastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteForecastMutation, DeleteForecastMutationVariables>(DeleteForecastDocument, options);
      }
export type DeleteForecastMutationHookResult = ReturnType<typeof useDeleteForecastMutation>;
export type DeleteForecastMutationResult = Apollo.MutationResult<DeleteForecastMutation>;
export type DeleteForecastMutationOptions = Apollo.BaseMutationOptions<DeleteForecastMutation, DeleteForecastMutationVariables>;
export const DeleteSessionDocument = gql`
    mutation deleteSession {
  logout(input: {}) {
    success
  }
}
    `;
export type DeleteSessionMutationFn = Apollo.MutationFunction<DeleteSessionMutation, DeleteSessionMutationVariables>;
export type DeleteSessionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSessionMutation, DeleteSessionMutationVariables>, 'mutation'>;

    export const DeleteSessionComponent = (props: DeleteSessionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSessionMutation, DeleteSessionMutationVariables> mutation={DeleteSessionDocument} {...props} />
    );
    

/**
 * __useDeleteSessionMutation__
 *
 * To run a mutation, you first call `useDeleteSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSessionMutation, { data, loading, error }] = useDeleteSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteSessionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSessionMutation, DeleteSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSessionMutation, DeleteSessionMutationVariables>(DeleteSessionDocument, options);
      }
export type DeleteSessionMutationHookResult = ReturnType<typeof useDeleteSessionMutation>;
export type DeleteSessionMutationResult = Apollo.MutationResult<DeleteSessionMutation>;
export type DeleteSessionMutationOptions = Apollo.BaseMutationOptions<DeleteSessionMutation, DeleteSessionMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation requestPasswordReset($email: String!) {
  requestPasswordReset(input: {email: $email}) {
    success
  }
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export type RequestPasswordResetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>, 'mutation'>;

    export const RequestPasswordResetComponent = (props: RequestPasswordResetComponentProps) => (
      <ApolloReactComponents.Mutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables> mutation={RequestPasswordResetDocument} {...props} />
    );
    

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($resetPasswordToken: String!, $newPassword: String!) {
  resetPassword(
    input: {resetPasswordToken: $resetPasswordToken, newPassword: $newPassword}
  ) {
    success
    errorCode
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordToken: // value for 'resetPasswordToken'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpsertForecastDocument = gql`
    mutation upsertForecast($input: UpsertForecastMutationInput!) {
  upsertForecast(input: $input) {
    forecast {
      ...Forecast
    }
  }
}
    ${ForecastFragmentDoc}`;
export type UpsertForecastMutationFn = Apollo.MutationFunction<UpsertForecastMutation, UpsertForecastMutationVariables>;
export type UpsertForecastComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertForecastMutation, UpsertForecastMutationVariables>, 'mutation'>;

    export const UpsertForecastComponent = (props: UpsertForecastComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertForecastMutation, UpsertForecastMutationVariables> mutation={UpsertForecastDocument} {...props} />
    );
    

/**
 * __useUpsertForecastMutation__
 *
 * To run a mutation, you first call `useUpsertForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertForecastMutation, { data, loading, error }] = useUpsertForecastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertForecastMutation(baseOptions?: Apollo.MutationHookOptions<UpsertForecastMutation, UpsertForecastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertForecastMutation, UpsertForecastMutationVariables>(UpsertForecastDocument, options);
      }
export type UpsertForecastMutationHookResult = ReturnType<typeof useUpsertForecastMutation>;
export type UpsertForecastMutationResult = Apollo.MutationResult<UpsertForecastMutation>;
export type UpsertForecastMutationOptions = Apollo.BaseMutationOptions<UpsertForecastMutation, UpsertForecastMutationVariables>;
export const UpsertInsightDocument = gql`
    mutation upsertInsight($input: UpsertInsightMutationInput!) {
  upsertInsight(input: $input) {
    insight {
      ...Insight
    }
  }
}
    ${InsightFragmentDoc}`;
export type UpsertInsightMutationFn = Apollo.MutationFunction<UpsertInsightMutation, UpsertInsightMutationVariables>;
export type UpsertInsightComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertInsightMutation, UpsertInsightMutationVariables>, 'mutation'>;

    export const UpsertInsightComponent = (props: UpsertInsightComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertInsightMutation, UpsertInsightMutationVariables> mutation={UpsertInsightDocument} {...props} />
    );
    

/**
 * __useUpsertInsightMutation__
 *
 * To run a mutation, you first call `useUpsertInsightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertInsightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertInsightMutation, { data, loading, error }] = useUpsertInsightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertInsightMutation(baseOptions?: Apollo.MutationHookOptions<UpsertInsightMutation, UpsertInsightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertInsightMutation, UpsertInsightMutationVariables>(UpsertInsightDocument, options);
      }
export type UpsertInsightMutationHookResult = ReturnType<typeof useUpsertInsightMutation>;
export type UpsertInsightMutationResult = Apollo.MutationResult<UpsertInsightMutation>;
export type UpsertInsightMutationOptions = Apollo.BaseMutationOptions<UpsertInsightMutation, UpsertInsightMutationVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($input: UpdatePlanMutationInput!) {
  updatePlan(input: $input) {
    state
  }
}
    `;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;
export type UpdatePlanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePlanMutation, UpdatePlanMutationVariables>, 'mutation'>;

    export const UpdatePlanComponent = (props: UpdatePlanComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePlanMutation, UpdatePlanMutationVariables> mutation={UpdatePlanDocument} {...props} />
    );
    

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const ClearPlanShiftsDocument = gql`
    mutation clearPlanShifts($input: ClearPlanShiftsMutationInput!) {
  clearPlanShifts(input: $input) {
    success
  }
}
    `;
export type ClearPlanShiftsMutationFn = Apollo.MutationFunction<ClearPlanShiftsMutation, ClearPlanShiftsMutationVariables>;
export type ClearPlanShiftsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ClearPlanShiftsMutation, ClearPlanShiftsMutationVariables>, 'mutation'>;

    export const ClearPlanShiftsComponent = (props: ClearPlanShiftsComponentProps) => (
      <ApolloReactComponents.Mutation<ClearPlanShiftsMutation, ClearPlanShiftsMutationVariables> mutation={ClearPlanShiftsDocument} {...props} />
    );
    

/**
 * __useClearPlanShiftsMutation__
 *
 * To run a mutation, you first call `useClearPlanShiftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPlanShiftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPlanShiftsMutation, { data, loading, error }] = useClearPlanShiftsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearPlanShiftsMutation(baseOptions?: Apollo.MutationHookOptions<ClearPlanShiftsMutation, ClearPlanShiftsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearPlanShiftsMutation, ClearPlanShiftsMutationVariables>(ClearPlanShiftsDocument, options);
      }
export type ClearPlanShiftsMutationHookResult = ReturnType<typeof useClearPlanShiftsMutation>;
export type ClearPlanShiftsMutationResult = Apollo.MutationResult<ClearPlanShiftsMutation>;
export type ClearPlanShiftsMutationOptions = Apollo.BaseMutationOptions<ClearPlanShiftsMutation, ClearPlanShiftsMutationVariables>;
export const ResetPlanStateDocument = gql`
    mutation ResetPlanState($input: ResetPlanStateMutationInput!) {
  resetPlanState(input: $input) {
    state
  }
}
    `;
export type ResetPlanStateMutationFn = Apollo.MutationFunction<ResetPlanStateMutation, ResetPlanStateMutationVariables>;
export type ResetPlanStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPlanStateMutation, ResetPlanStateMutationVariables>, 'mutation'>;

    export const ResetPlanStateComponent = (props: ResetPlanStateComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPlanStateMutation, ResetPlanStateMutationVariables> mutation={ResetPlanStateDocument} {...props} />
    );
    

/**
 * __useResetPlanStateMutation__
 *
 * To run a mutation, you first call `useResetPlanStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPlanStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPlanStateMutation, { data, loading, error }] = useResetPlanStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPlanStateMutation(baseOptions?: Apollo.MutationHookOptions<ResetPlanStateMutation, ResetPlanStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPlanStateMutation, ResetPlanStateMutationVariables>(ResetPlanStateDocument, options);
      }
export type ResetPlanStateMutationHookResult = ReturnType<typeof useResetPlanStateMutation>;
export type ResetPlanStateMutationResult = Apollo.MutationResult<ResetPlanStateMutation>;
export type ResetPlanStateMutationOptions = Apollo.BaseMutationOptions<ResetPlanStateMutation, ResetPlanStateMutationVariables>;
export const CreateShiftDocument = gql`
    mutation createShift($input: CreateShiftMutationInput!) {
  createShift(input: $input) {
    ...Shift
  }
}
    ${ShiftFragmentDoc}`;
export type CreateShiftMutationFn = Apollo.MutationFunction<CreateShiftMutation, CreateShiftMutationVariables>;
export type CreateShiftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateShiftMutation, CreateShiftMutationVariables>, 'mutation'>;

    export const CreateShiftComponent = (props: CreateShiftComponentProps) => (
      <ApolloReactComponents.Mutation<CreateShiftMutation, CreateShiftMutationVariables> mutation={CreateShiftDocument} {...props} />
    );
    

/**
 * __useCreateShiftMutation__
 *
 * To run a mutation, you first call `useCreateShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftMutation, { data, loading, error }] = useCreateShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShiftMutation(baseOptions?: Apollo.MutationHookOptions<CreateShiftMutation, CreateShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShiftMutation, CreateShiftMutationVariables>(CreateShiftDocument, options);
      }
export type CreateShiftMutationHookResult = ReturnType<typeof useCreateShiftMutation>;
export type CreateShiftMutationResult = Apollo.MutationResult<CreateShiftMutation>;
export type CreateShiftMutationOptions = Apollo.BaseMutationOptions<CreateShiftMutation, CreateShiftMutationVariables>;
export const UpdateShiftDocument = gql`
    mutation updateShift($input: UpdateShiftMutationInput!) {
  updateShift(input: $input) {
    ...Shift
  }
}
    ${ShiftFragmentDoc}`;
export type UpdateShiftMutationFn = Apollo.MutationFunction<UpdateShiftMutation, UpdateShiftMutationVariables>;
export type UpdateShiftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateShiftMutation, UpdateShiftMutationVariables>, 'mutation'>;

    export const UpdateShiftComponent = (props: UpdateShiftComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateShiftMutation, UpdateShiftMutationVariables> mutation={UpdateShiftDocument} {...props} />
    );
    

/**
 * __useUpdateShiftMutation__
 *
 * To run a mutation, you first call `useUpdateShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftMutation, { data, loading, error }] = useUpdateShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShiftMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShiftMutation, UpdateShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShiftMutation, UpdateShiftMutationVariables>(UpdateShiftDocument, options);
      }
export type UpdateShiftMutationHookResult = ReturnType<typeof useUpdateShiftMutation>;
export type UpdateShiftMutationResult = Apollo.MutationResult<UpdateShiftMutation>;
export type UpdateShiftMutationOptions = Apollo.BaseMutationOptions<UpdateShiftMutation, UpdateShiftMutationVariables>;
export const DeleteShiftDocument = gql`
    mutation deleteShift($input: DeleteShiftMutationInput!) {
  deleteShift(input: $input) {
    success
  }
}
    `;
export type DeleteShiftMutationFn = Apollo.MutationFunction<DeleteShiftMutation, DeleteShiftMutationVariables>;
export type DeleteShiftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteShiftMutation, DeleteShiftMutationVariables>, 'mutation'>;

    export const DeleteShiftComponent = (props: DeleteShiftComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteShiftMutation, DeleteShiftMutationVariables> mutation={DeleteShiftDocument} {...props} />
    );
    

/**
 * __useDeleteShiftMutation__
 *
 * To run a mutation, you first call `useDeleteShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShiftMutation, { data, loading, error }] = useDeleteShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteShiftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShiftMutation, DeleteShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShiftMutation, DeleteShiftMutationVariables>(DeleteShiftDocument, options);
      }
export type DeleteShiftMutationHookResult = ReturnType<typeof useDeleteShiftMutation>;
export type DeleteShiftMutationResult = Apollo.MutationResult<DeleteShiftMutation>;
export type DeleteShiftMutationOptions = Apollo.BaseMutationOptions<DeleteShiftMutation, DeleteShiftMutationVariables>;
export const CopyLastWeekShiftsDocument = gql`
    mutation CopyLastWeekShifts($input: CopyLastWeekShiftsMutationInput!) {
  copyLastWeekShifts(input: $input) {
    id
  }
}
    `;
export type CopyLastWeekShiftsMutationFn = Apollo.MutationFunction<CopyLastWeekShiftsMutation, CopyLastWeekShiftsMutationVariables>;
export type CopyLastWeekShiftsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyLastWeekShiftsMutation, CopyLastWeekShiftsMutationVariables>, 'mutation'>;

    export const CopyLastWeekShiftsComponent = (props: CopyLastWeekShiftsComponentProps) => (
      <ApolloReactComponents.Mutation<CopyLastWeekShiftsMutation, CopyLastWeekShiftsMutationVariables> mutation={CopyLastWeekShiftsDocument} {...props} />
    );
    

/**
 * __useCopyLastWeekShiftsMutation__
 *
 * To run a mutation, you first call `useCopyLastWeekShiftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyLastWeekShiftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyLastWeekShiftsMutation, { data, loading, error }] = useCopyLastWeekShiftsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyLastWeekShiftsMutation(baseOptions?: Apollo.MutationHookOptions<CopyLastWeekShiftsMutation, CopyLastWeekShiftsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyLastWeekShiftsMutation, CopyLastWeekShiftsMutationVariables>(CopyLastWeekShiftsDocument, options);
      }
export type CopyLastWeekShiftsMutationHookResult = ReturnType<typeof useCopyLastWeekShiftsMutation>;
export type CopyLastWeekShiftsMutationResult = Apollo.MutationResult<CopyLastWeekShiftsMutation>;
export type CopyLastWeekShiftsMutationOptions = Apollo.BaseMutationOptions<CopyLastWeekShiftsMutation, CopyLastWeekShiftsMutationVariables>;
export const CreateAutoScheduleDocument = gql`
    mutation CreateAutoSchedule($input: CreateAutoScheduleMutationInput!) {
  createAutoSchedule(input: $input) {
    id
  }
}
    `;
export type CreateAutoScheduleMutationFn = Apollo.MutationFunction<CreateAutoScheduleMutation, CreateAutoScheduleMutationVariables>;
export type CreateAutoScheduleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAutoScheduleMutation, CreateAutoScheduleMutationVariables>, 'mutation'>;

    export const CreateAutoScheduleComponent = (props: CreateAutoScheduleComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAutoScheduleMutation, CreateAutoScheduleMutationVariables> mutation={CreateAutoScheduleDocument} {...props} />
    );
    

/**
 * __useCreateAutoScheduleMutation__
 *
 * To run a mutation, you first call `useCreateAutoScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutoScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutoScheduleMutation, { data, loading, error }] = useCreateAutoScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAutoScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateAutoScheduleMutation, CreateAutoScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAutoScheduleMutation, CreateAutoScheduleMutationVariables>(CreateAutoScheduleDocument, options);
      }
export type CreateAutoScheduleMutationHookResult = ReturnType<typeof useCreateAutoScheduleMutation>;
export type CreateAutoScheduleMutationResult = Apollo.MutationResult<CreateAutoScheduleMutation>;
export type CreateAutoScheduleMutationOptions = Apollo.BaseMutationOptions<CreateAutoScheduleMutation, CreateAutoScheduleMutationVariables>;
export const CreateCovidDashboardDocument = gql`
    mutation CreateCovidDashboard($input: CreateCovidDashboardMutationInput!) {
  createCovidDashboard(input: $input) {
    ...CovidDashboard
  }
}
    ${CovidDashboardFragmentDoc}`;
export type CreateCovidDashboardMutationFn = Apollo.MutationFunction<CreateCovidDashboardMutation, CreateCovidDashboardMutationVariables>;
export type CreateCovidDashboardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCovidDashboardMutation, CreateCovidDashboardMutationVariables>, 'mutation'>;

    export const CreateCovidDashboardComponent = (props: CreateCovidDashboardComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCovidDashboardMutation, CreateCovidDashboardMutationVariables> mutation={CreateCovidDashboardDocument} {...props} />
    );
    

/**
 * __useCreateCovidDashboardMutation__
 *
 * To run a mutation, you first call `useCreateCovidDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCovidDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCovidDashboardMutation, { data, loading, error }] = useCreateCovidDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCovidDashboardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCovidDashboardMutation, CreateCovidDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCovidDashboardMutation, CreateCovidDashboardMutationVariables>(CreateCovidDashboardDocument, options);
      }
export type CreateCovidDashboardMutationHookResult = ReturnType<typeof useCreateCovidDashboardMutation>;
export type CreateCovidDashboardMutationResult = Apollo.MutationResult<CreateCovidDashboardMutation>;
export type CreateCovidDashboardMutationOptions = Apollo.BaseMutationOptions<CreateCovidDashboardMutation, CreateCovidDashboardMutationVariables>;
export const UpdateCovidDashboardDocument = gql`
    mutation UpdateCovidDashboard($input: UpdateCovidDashboardMutationInput!) {
  updateCovidDashboard(input: $input) {
    success
    errors
    covidDashboard {
      ...CovidDashboard
    }
  }
}
    ${CovidDashboardFragmentDoc}`;
export type UpdateCovidDashboardMutationFn = Apollo.MutationFunction<UpdateCovidDashboardMutation, UpdateCovidDashboardMutationVariables>;
export type UpdateCovidDashboardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCovidDashboardMutation, UpdateCovidDashboardMutationVariables>, 'mutation'>;

    export const UpdateCovidDashboardComponent = (props: UpdateCovidDashboardComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCovidDashboardMutation, UpdateCovidDashboardMutationVariables> mutation={UpdateCovidDashboardDocument} {...props} />
    );
    

/**
 * __useUpdateCovidDashboardMutation__
 *
 * To run a mutation, you first call `useUpdateCovidDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCovidDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCovidDashboardMutation, { data, loading, error }] = useUpdateCovidDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCovidDashboardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCovidDashboardMutation, UpdateCovidDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCovidDashboardMutation, UpdateCovidDashboardMutationVariables>(UpdateCovidDashboardDocument, options);
      }
export type UpdateCovidDashboardMutationHookResult = ReturnType<typeof useUpdateCovidDashboardMutation>;
export type UpdateCovidDashboardMutationResult = Apollo.MutationResult<UpdateCovidDashboardMutation>;
export type UpdateCovidDashboardMutationOptions = Apollo.BaseMutationOptions<UpdateCovidDashboardMutation, UpdateCovidDashboardMutationVariables>;
export const CreatePlanTemplateDocument = gql`
    mutation CreatePlanTemplate($input: CreatePlanTemplateMutationInput!) {
  createPlanTemplate(input: $input) {
    success
    errors
    planTemplate {
      ...PlanTemplate
    }
  }
}
    ${PlanTemplateFragmentDoc}`;
export type CreatePlanTemplateMutationFn = Apollo.MutationFunction<CreatePlanTemplateMutation, CreatePlanTemplateMutationVariables>;
export type CreatePlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreatePlanTemplateMutation, CreatePlanTemplateMutationVariables>, 'mutation'>;

    export const CreatePlanTemplateComponent = (props: CreatePlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CreatePlanTemplateMutation, CreatePlanTemplateMutationVariables> mutation={CreatePlanTemplateDocument} {...props} />
    );
    

/**
 * __useCreatePlanTemplateMutation__
 *
 * To run a mutation, you first call `useCreatePlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanTemplateMutation, { data, loading, error }] = useCreatePlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanTemplateMutation, CreatePlanTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanTemplateMutation, CreatePlanTemplateMutationVariables>(CreatePlanTemplateDocument, options);
      }
export type CreatePlanTemplateMutationHookResult = ReturnType<typeof useCreatePlanTemplateMutation>;
export type CreatePlanTemplateMutationResult = Apollo.MutationResult<CreatePlanTemplateMutation>;
export type CreatePlanTemplateMutationOptions = Apollo.BaseMutationOptions<CreatePlanTemplateMutation, CreatePlanTemplateMutationVariables>;
export const CreateWeeklyPlanExportDocument = gql`
    mutation CreateWeeklyPlanExport($input: CreateWeeklyPlanExportMutationInput!) {
  createWeeklyPlanExportMutation(input: $input) {
    url
  }
}
    `;
export type CreateWeeklyPlanExportMutationFn = Apollo.MutationFunction<CreateWeeklyPlanExportMutation, CreateWeeklyPlanExportMutationVariables>;
export type CreateWeeklyPlanExportComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateWeeklyPlanExportMutation, CreateWeeklyPlanExportMutationVariables>, 'mutation'>;

    export const CreateWeeklyPlanExportComponent = (props: CreateWeeklyPlanExportComponentProps) => (
      <ApolloReactComponents.Mutation<CreateWeeklyPlanExportMutation, CreateWeeklyPlanExportMutationVariables> mutation={CreateWeeklyPlanExportDocument} {...props} />
    );
    

/**
 * __useCreateWeeklyPlanExportMutation__
 *
 * To run a mutation, you first call `useCreateWeeklyPlanExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWeeklyPlanExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWeeklyPlanExportMutation, { data, loading, error }] = useCreateWeeklyPlanExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWeeklyPlanExportMutation(baseOptions?: Apollo.MutationHookOptions<CreateWeeklyPlanExportMutation, CreateWeeklyPlanExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWeeklyPlanExportMutation, CreateWeeklyPlanExportMutationVariables>(CreateWeeklyPlanExportDocument, options);
      }
export type CreateWeeklyPlanExportMutationHookResult = ReturnType<typeof useCreateWeeklyPlanExportMutation>;
export type CreateWeeklyPlanExportMutationResult = Apollo.MutationResult<CreateWeeklyPlanExportMutation>;
export type CreateWeeklyPlanExportMutationOptions = Apollo.BaseMutationOptions<CreateWeeklyPlanExportMutation, CreateWeeklyPlanExportMutationVariables>;
export const OnboardingOAuthDocument = gql`
    mutation OnboardingOAuth($input: OnboardingOauthMutationInput!) {
  onboardingOauth(input: $input) {
    success
    locations {
      id
      address
      name
      status
    }
  }
}
    `;
export type OnboardingOAuthMutationFn = Apollo.MutationFunction<OnboardingOAuthMutation, OnboardingOAuthMutationVariables>;
export type OnboardingOAuthComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OnboardingOAuthMutation, OnboardingOAuthMutationVariables>, 'mutation'>;

    export const OnboardingOAuthComponent = (props: OnboardingOAuthComponentProps) => (
      <ApolloReactComponents.Mutation<OnboardingOAuthMutation, OnboardingOAuthMutationVariables> mutation={OnboardingOAuthDocument} {...props} />
    );
    

/**
 * __useOnboardingOAuthMutation__
 *
 * To run a mutation, you first call `useOnboardingOAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingOAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingOAuthMutation, { data, loading, error }] = useOnboardingOAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingOAuthMutation(baseOptions?: Apollo.MutationHookOptions<OnboardingOAuthMutation, OnboardingOAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardingOAuthMutation, OnboardingOAuthMutationVariables>(OnboardingOAuthDocument, options);
      }
export type OnboardingOAuthMutationHookResult = ReturnType<typeof useOnboardingOAuthMutation>;
export type OnboardingOAuthMutationResult = Apollo.MutationResult<OnboardingOAuthMutation>;
export type OnboardingOAuthMutationOptions = Apollo.BaseMutationOptions<OnboardingOAuthMutation, OnboardingOAuthMutationVariables>;
export const CopyShiftsFromPlanTemplateDocument = gql`
    mutation CopyShiftsFromPlanTemplate($input: CopyShiftsFromPlanTemplateMutationInput!) {
  copyShiftsFromPlanTemplate(input: $input) {
    id
  }
}
    `;
export type CopyShiftsFromPlanTemplateMutationFn = Apollo.MutationFunction<CopyShiftsFromPlanTemplateMutation, CopyShiftsFromPlanTemplateMutationVariables>;
export type CopyShiftsFromPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyShiftsFromPlanTemplateMutation, CopyShiftsFromPlanTemplateMutationVariables>, 'mutation'>;

    export const CopyShiftsFromPlanTemplateComponent = (props: CopyShiftsFromPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CopyShiftsFromPlanTemplateMutation, CopyShiftsFromPlanTemplateMutationVariables> mutation={CopyShiftsFromPlanTemplateDocument} {...props} />
    );
    

/**
 * __useCopyShiftsFromPlanTemplateMutation__
 *
 * To run a mutation, you first call `useCopyShiftsFromPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyShiftsFromPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyShiftsFromPlanTemplateMutation, { data, loading, error }] = useCopyShiftsFromPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyShiftsFromPlanTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CopyShiftsFromPlanTemplateMutation, CopyShiftsFromPlanTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyShiftsFromPlanTemplateMutation, CopyShiftsFromPlanTemplateMutationVariables>(CopyShiftsFromPlanTemplateDocument, options);
      }
export type CopyShiftsFromPlanTemplateMutationHookResult = ReturnType<typeof useCopyShiftsFromPlanTemplateMutation>;
export type CopyShiftsFromPlanTemplateMutationResult = Apollo.MutationResult<CopyShiftsFromPlanTemplateMutation>;
export type CopyShiftsFromPlanTemplateMutationOptions = Apollo.BaseMutationOptions<CopyShiftsFromPlanTemplateMutation, CopyShiftsFromPlanTemplateMutationVariables>;
export const ApproveManagementRequestDocument = gql`
    mutation ApproveManagementRequest($input: ApproveManagementRequestMutationInput!) {
  approveManagementRequest(input: $input) {
    success
  }
}
    `;
export type ApproveManagementRequestMutationFn = Apollo.MutationFunction<ApproveManagementRequestMutation, ApproveManagementRequestMutationVariables>;
export type ApproveManagementRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveManagementRequestMutation, ApproveManagementRequestMutationVariables>, 'mutation'>;

    export const ApproveManagementRequestComponent = (props: ApproveManagementRequestComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveManagementRequestMutation, ApproveManagementRequestMutationVariables> mutation={ApproveManagementRequestDocument} {...props} />
    );
    

/**
 * __useApproveManagementRequestMutation__
 *
 * To run a mutation, you first call `useApproveManagementRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveManagementRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveManagementRequestMutation, { data, loading, error }] = useApproveManagementRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveManagementRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveManagementRequestMutation, ApproveManagementRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveManagementRequestMutation, ApproveManagementRequestMutationVariables>(ApproveManagementRequestDocument, options);
      }
export type ApproveManagementRequestMutationHookResult = ReturnType<typeof useApproveManagementRequestMutation>;
export type ApproveManagementRequestMutationResult = Apollo.MutationResult<ApproveManagementRequestMutation>;
export type ApproveManagementRequestMutationOptions = Apollo.BaseMutationOptions<ApproveManagementRequestMutation, ApproveManagementRequestMutationVariables>;
export const SignupDocument = gql`
    mutation signup($input: SignupMutationInput!) {
  signup(input: $input) {
    success
    errorCode
    user {
      ...CurrentUserInfo
    }
  }
}
    ${CurrentUserInfoFragmentDoc}`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;
export type SignupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignupMutation, SignupMutationVariables>, 'mutation'>;

    export const SignupComponent = (props: SignupComponentProps) => (
      <ApolloReactComponents.Mutation<SignupMutation, SignupMutationVariables> mutation={SignupDocument} {...props} />
    );
    

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const CreateMassNotificationDocument = gql`
    mutation createMassNotification($input: CreateMassNotificationMutationInput!) {
  createMassNotification(input: $input) {
    success
    errors
    massNotification {
      ...MassNotifications
    }
  }
}
    ${MassNotificationsFragmentDoc}`;
export type CreateMassNotificationMutationFn = Apollo.MutationFunction<CreateMassNotificationMutation, CreateMassNotificationMutationVariables>;
export type CreateMassNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMassNotificationMutation, CreateMassNotificationMutationVariables>, 'mutation'>;

    export const CreateMassNotificationComponent = (props: CreateMassNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMassNotificationMutation, CreateMassNotificationMutationVariables> mutation={CreateMassNotificationDocument} {...props} />
    );
    

/**
 * __useCreateMassNotificationMutation__
 *
 * To run a mutation, you first call `useCreateMassNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMassNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMassNotificationMutation, { data, loading, error }] = useCreateMassNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMassNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateMassNotificationMutation, CreateMassNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMassNotificationMutation, CreateMassNotificationMutationVariables>(CreateMassNotificationDocument, options);
      }
export type CreateMassNotificationMutationHookResult = ReturnType<typeof useCreateMassNotificationMutation>;
export type CreateMassNotificationMutationResult = Apollo.MutationResult<CreateMassNotificationMutation>;
export type CreateMassNotificationMutationOptions = Apollo.BaseMutationOptions<CreateMassNotificationMutation, CreateMassNotificationMutationVariables>;
export const UpsertEmployeeAvailabilityDocument = gql`
    mutation UpsertEmployeeAvailability($input: UpsertEmployeeAvailabilityMutationInput!) {
  upsertEmployeeAvailability(input: $input) {
    state
    daysAvailable {
      ...DaysAvailable
    }
    employeeId
    employeeNote
    managerNote
    organizationId
  }
}
    ${DaysAvailableFragmentDoc}`;
export type UpsertEmployeeAvailabilityMutationFn = Apollo.MutationFunction<UpsertEmployeeAvailabilityMutation, UpsertEmployeeAvailabilityMutationVariables>;
export type UpsertEmployeeAvailabilityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertEmployeeAvailabilityMutation, UpsertEmployeeAvailabilityMutationVariables>, 'mutation'>;

    export const UpsertEmployeeAvailabilityComponent = (props: UpsertEmployeeAvailabilityComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertEmployeeAvailabilityMutation, UpsertEmployeeAvailabilityMutationVariables> mutation={UpsertEmployeeAvailabilityDocument} {...props} />
    );
    

/**
 * __useUpsertEmployeeAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpsertEmployeeAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEmployeeAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEmployeeAvailabilityMutation, { data, loading, error }] = useUpsertEmployeeAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertEmployeeAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<UpsertEmployeeAvailabilityMutation, UpsertEmployeeAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertEmployeeAvailabilityMutation, UpsertEmployeeAvailabilityMutationVariables>(UpsertEmployeeAvailabilityDocument, options);
      }
export type UpsertEmployeeAvailabilityMutationHookResult = ReturnType<typeof useUpsertEmployeeAvailabilityMutation>;
export type UpsertEmployeeAvailabilityMutationResult = Apollo.MutationResult<UpsertEmployeeAvailabilityMutation>;
export type UpsertEmployeeAvailabilityMutationOptions = Apollo.BaseMutationOptions<UpsertEmployeeAvailabilityMutation, UpsertEmployeeAvailabilityMutationVariables>;
export const UpdateOperatingHoursDocument = gql`
    mutation UpdateOperatingHours($input: UpdateOperatingHoursMutationInput!) {
  updateOperatingHours(input: $input) {
    success
    errors
  }
}
    `;
export type UpdateOperatingHoursMutationFn = Apollo.MutationFunction<UpdateOperatingHoursMutation, UpdateOperatingHoursMutationVariables>;
export type UpdateOperatingHoursComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOperatingHoursMutation, UpdateOperatingHoursMutationVariables>, 'mutation'>;

    export const UpdateOperatingHoursComponent = (props: UpdateOperatingHoursComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOperatingHoursMutation, UpdateOperatingHoursMutationVariables> mutation={UpdateOperatingHoursDocument} {...props} />
    );
    

/**
 * __useUpdateOperatingHoursMutation__
 *
 * To run a mutation, you first call `useUpdateOperatingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperatingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperatingHoursMutation, { data, loading, error }] = useUpdateOperatingHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOperatingHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOperatingHoursMutation, UpdateOperatingHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOperatingHoursMutation, UpdateOperatingHoursMutationVariables>(UpdateOperatingHoursDocument, options);
      }
export type UpdateOperatingHoursMutationHookResult = ReturnType<typeof useUpdateOperatingHoursMutation>;
export type UpdateOperatingHoursMutationResult = Apollo.MutationResult<UpdateOperatingHoursMutation>;
export type UpdateOperatingHoursMutationOptions = Apollo.BaseMutationOptions<UpdateOperatingHoursMutation, UpdateOperatingHoursMutationVariables>;
export const ResetDemoLocationDocument = gql`
    mutation ResetDemoLocation($input: ResetDemoLocationMutationInput!) {
  resetDemoLocation(input: $input) {
    success
  }
}
    `;
export type ResetDemoLocationMutationFn = Apollo.MutationFunction<ResetDemoLocationMutation, ResetDemoLocationMutationVariables>;
export type ResetDemoLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetDemoLocationMutation, ResetDemoLocationMutationVariables>, 'mutation'>;

    export const ResetDemoLocationComponent = (props: ResetDemoLocationComponentProps) => (
      <ApolloReactComponents.Mutation<ResetDemoLocationMutation, ResetDemoLocationMutationVariables> mutation={ResetDemoLocationDocument} {...props} />
    );
    

/**
 * __useResetDemoLocationMutation__
 *
 * To run a mutation, you first call `useResetDemoLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDemoLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDemoLocationMutation, { data, loading, error }] = useResetDemoLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetDemoLocationMutation(baseOptions?: Apollo.MutationHookOptions<ResetDemoLocationMutation, ResetDemoLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetDemoLocationMutation, ResetDemoLocationMutationVariables>(ResetDemoLocationDocument, options);
      }
export type ResetDemoLocationMutationHookResult = ReturnType<typeof useResetDemoLocationMutation>;
export type ResetDemoLocationMutationResult = Apollo.MutationResult<ResetDemoLocationMutation>;
export type ResetDemoLocationMutationOptions = Apollo.BaseMutationOptions<ResetDemoLocationMutation, ResetDemoLocationMutationVariables>;
export const GetUsersForOrganizationDocument = gql`
    query getUsersForOrganization($organizationId: ID!) {
  users(organizationId: $organizationId) {
    ...UserForOrganizationSettings
  }
}
    ${UserForOrganizationSettingsFragmentDoc}`;
export type GetUsersForOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsersForOrganizationQuery, GetUsersForOrganizationQueryVariables>, 'query'> & ({ variables: GetUsersForOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUsersForOrganizationComponent = (props: GetUsersForOrganizationComponentProps) => (
      <ApolloReactComponents.Query<GetUsersForOrganizationQuery, GetUsersForOrganizationQueryVariables> query={GetUsersForOrganizationDocument} {...props} />
    );
    

/**
 * __useGetUsersForOrganizationQuery__
 *
 * To run a query within a React component, call `useGetUsersForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetUsersForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetUsersForOrganizationQuery, GetUsersForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersForOrganizationQuery, GetUsersForOrganizationQueryVariables>(GetUsersForOrganizationDocument, options);
      }
export function useGetUsersForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersForOrganizationQuery, GetUsersForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersForOrganizationQuery, GetUsersForOrganizationQueryVariables>(GetUsersForOrganizationDocument, options);
        }
export type GetUsersForOrganizationQueryHookResult = ReturnType<typeof useGetUsersForOrganizationQuery>;
export type GetUsersForOrganizationLazyQueryHookResult = ReturnType<typeof useGetUsersForOrganizationLazyQuery>;
export type GetUsersForOrganizationQueryResult = Apollo.QueryResult<GetUsersForOrganizationQuery, GetUsersForOrganizationQueryVariables>;