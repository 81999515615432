import { PermissionFragment, PermissionName } from 'config/graphqlTypes'

const getHasPermission = (permission: PermissionName) => (
  permissions: PermissionFragment[]
) =>
  permissions.some(
    ({ permission: existingPermission }) => existingPermission === permission
  )

export const getHasViewReportingPermission = getHasPermission(
  PermissionName.ViewReporting
)

export const getHasSettingsPermission = getHasPermission(
  PermissionName.ManageEmployees
)

export const getHasManageOrganizationsPermission = getHasPermission(
  PermissionName.ManageOrganizations
)

export const getHasEditLaborAllowancePermission = getHasPermission(
  PermissionName.EditLaborAllowance
)

export const getHasManagePredictions = getHasPermission(
  PermissionName.ManagePredictions
)

export const getHasUpdateSalesDayPartsPermission = getHasPermission(
  PermissionName.UpdateSalesDayParts
)

export const getHasResetDemoLocationsPermission = getHasPermission(
  PermissionName.ResetDemoLocations
)
