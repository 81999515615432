import React from 'react'
import { times } from 'lodash'
import { Interval } from 'luxon'

import {
  TitleCell,
  PlanningCell,
  PlanningCellBodyText,
  PlanningCellText,
  SubSubTitleText,
} from 'pages/plan/rows/styles'
import { UnprintablePlanningTableRow } from 'pages/plan/styles'
import { FlexCenter } from 'pages/plan/Prediction/styles'
import { InternalEventCard, InternalEventType } from '../InternalEventCard'
import { useTranslation } from 'react-i18next'
import { ReservationCard, ReservationType } from '../ReservationCard'

interface DailyDetailsPlanRowProps {
  days: Interval[]
  title: string
  getDailyDetails: (
    day: Interval
  ) => string[][] | InternalEventType[] | ReservationType[]
  noDetailsMsg: string
  isInternalEvent?: boolean
  isReservation?: boolean
}

export const DailyDetailsPlanRow = ({
  days,
  title,
  getDailyDetails,
  noDetailsMsg,
  isInternalEvent,
  isReservation,
}: DailyDetailsPlanRowProps) => (
  <PlanRow title={title}>
    <>
      {days.map((day, index) => {
        const dailyDetails = getDailyDetails(day)
        return (
          <PlanningCell key={index}>
            {dailyDetails.length === 0 ? (
              <PlanningCellText>
                {isInternalEvent || isReservation ? (
                  <span
                    style={{
                      color: 'var(--gray3)',
                      fontSize: '18px',
                      fontWeight: '500',
                    }}
                  >
                    _
                  </span>
                ) : (
                  noDetailsMsg
                )}
              </PlanningCellText>
            ) : isInternalEvent ? (
              <InternalEventDetails
                events={dailyDetails as InternalEventType[]}
              />
            ) : isReservation ? (
              <ReservationDetails
                reservations={dailyDetails as ReservationType[]}
              />
            ) : (
              <EventDetails events={dailyDetails as string[][]} />
            )}
          </PlanningCell>
        )
      })}
      <PlanningCell>&nbsp;</PlanningCell>
    </>
  </PlanRow>
)

const EventDetails = ({ events }: { events: string[][] }) => (
  <>
    {events.map(([title, subtitle], index) => (
      <FlexCenter key={index}>
        <PlanningCellBodyText>{title}</PlanningCellBodyText>
        <PlanningCellText isSmallMarginTop>{subtitle}</PlanningCellText>
      </FlexCenter>
    ))}
  </>
)

const InternalEventDetails = ({ events }: { events: InternalEventType[] }) => {
  const { t } = useTranslation()

  return (
    <>
      {events.map((event, index) => (
        <FlexCenter key={index}>
          <PlanningCellBodyText>{event.eventStartTime}</PlanningCellBodyText>
          <PlanningCellText isSmallMarginTop color="var(--lineup-blue)">
            <InternalEventCard event={event} />
          </PlanningCellText>
          <PlanningCellText isSmallMarginTop>
            {t('planning.internalEvent.guestCount', {
              count: event.guestCount,
            })}
          </PlanningCellText>
          <PlanningCellText isSmallMarginTop>
            {t('planning.internalEvent.foodAndBeverageMinDollars', {
              min: event.foodAndBeverageMinDollars,
            })}
          </PlanningCellText>
        </FlexCenter>
      ))}
    </>
  )
}

const ReservationDetails = ({
  reservations,
}: {
  reservations: ReservationType[]
}) => {
  const { t } = useTranslation()
  const totalCovers = reservations.reduce(function(prev, current) {
    return prev + current.partySize
  }, 0)
  const largePartyReservations = reservations.filter(res => res.largeParty)

  return (
    <FlexCenter>
      {largePartyReservations.length > 0 && (
        <PlanningCellText isSmallMarginTop color="var(--lineup-blue)">
          <ReservationCard reservations={largePartyReservations} />
        </PlanningCellText>
      )}
      <PlanningCellText isSmallMarginTop>
        {t('planning.reservations.totalCovers', {
          total: totalCovers,
        })}
      </PlanningCellText>
    </FlexCenter>
  )
}

interface MultiRowPlanRowProps {
  title: string
  subsubtitle?: string
  subtitles: string[]
  dataRows: string[][]
}

export const MultiRowPlanRow = ({
  title,
  subsubtitle,
  subtitles,
  dataRows,
}: MultiRowPlanRowProps) => {
  const [firstDataRow, ...otherDataRows] = dataRows

  return (
    <UnprintablePlanningTableRow isBodyRow>
      <TitleCell>
        <PlanningCellBodyText>
          {title}
          {subsubtitle && <SubSubTitleText>{subsubtitle}</SubSubTitleText>}
        </PlanningCellBodyText>
        {subtitles.map(subtitle => (
          <PlanningCellText
            key={subtitle}
            id={'planning-cell-text-' + subtitle}
            indent
          >
            {subtitle}
          </PlanningCellText>
        ))}
      </TitleCell>
      {times(firstDataRow.length, index => (
        <PlanningCell key={index} id={'planning-cell-' + index}>
          <PlanningCellBodyText>{firstDataRow[index]}</PlanningCellBodyText>
          {otherDataRows.map((dataRow, i) => (
            <PlanningCellText key={i}>{dataRow[index]}</PlanningCellText>
          ))}
        </PlanningCell>
      ))}
    </UnprintablePlanningTableRow>
  )
}

interface PlanRowProps {
  title: string
  children: JSX.Element | JSX.Element[]
}

const PlanRow = ({ title, children }: PlanRowProps) => (
  <UnprintablePlanningTableRow isBodyRow>
    <TitleCell>
      <PlanningCellBodyText>{title}</PlanningCellBodyText>
    </TitleCell>
    {children}
  </UnprintablePlanningTableRow>
)
