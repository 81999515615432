import styled from 'styled-components'

import dropdownArrow from 'assets/images/dropdown-arrow.svg'

// Text
export const baseTextStyle = {
  marginBottom: 0,
}

export const smallBodyTextStyle = {
  ...baseTextStyle,
  fontSize: '0.625rem', // 10px
  lineHeight: 1,
  fontWeight: 500,
}

export const smallBodyBoldTextStyle = {
  ...smallBodyTextStyle,
  fontWeight: 800,
}

export const regularBodyTextStyle = {
  ...baseTextStyle,
  fontSize: '0.875rem', // 14px
  lineHeight: 1.07,
  fontWeight: 500,
}

export const regularBodyBoldTextStyle = {
  ...baseTextStyle,
  fontSize: '0.875rem', // 14px
  lineHeight: 1.07,
  fontWeight: 800,
}

export const mediumBodyTextStyle = {
  ...baseTextStyle,
  fontSize: '1rem', // 16px
  fontWeight: 400,
  lineHeight: 1.25,
}

export const mediumBodyBoldTextStyle = {
  ...mediumBodyTextStyle,
  fontWeight: 800,
}

export const largeBodyTextStyle = {
  ...baseTextStyle,
  fontSize: '1.125rem', // 18px
  lineHeight: 1.111,
}

export const largeBodyBoldTextStyle = {
  ...baseTextStyle,
  fontSize: '1.125rem', // 18px
  fontWeight: 800,
  lineHeight: 1,
}

export const tableLabelTextStyle = {
  ...baseTextStyle,
  letterSpacing: '-0.02em',
  fontSize: '0.75rem', // 12px
  lineHeight: 1.25,
  fontWeight: 500,
}

export const tableLabelBoldTextStyle = {
  ...tableLabelTextStyle,
  fontWeight: 800,
}

export const tableLabelTinyTextStyle = {
  ...baseTextStyle,
  fontSize: '0.5625rem', // 9px
  lineHeight: '0.9375rem',
  fontWeight: 600,
}

export const tableLabelTinyBoldTextStyle = {
  ...tableLabelTinyTextStyle,
  fontWeight: 800,
}

export const tableAnnotationTextStyle = {
  ...baseTextStyle,
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '0.5625rem', // 9px
  lineHeight: '0.9375rem',
  letterSpacing: '0.02em',
  textTransform: 'uppercase' as const,
}

export const largeHeaderStyle = {
  ...baseTextStyle,
  fontWeight: 900,
  fontSize: 32,
  lineHeight: 1,
}

export const h2Style = {
  ...baseTextStyle,
  fontWeight: 900,
  fontSize: 26,
  lineHeight: 1,
}

export const h3Style = {
  ...baseTextStyle,
  fontWeight: 900,
  fontSize: 22,
  lineHeight: '24px',
}

export const graphLabelTextStyle = {
  ...baseTextStyle,
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: 10,
  lineHeight: '12px',
}

// Forms
export const baseInputStyle = {
  ...largeBodyTextStyle,
  border: '1px solid',
  borderRadius: 25,
  borderColor: 'var(--gray5)',
  paddingLeft: 23,
  color: 'var(--gray3)',
}

export const inputWithArrowStyle = {
  ...baseInputStyle,
  backgroundImage: `url(${dropdownArrow})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center right 5%',
}

export const baseSelectStyle = {
  ...inputWithArrowStyle,
  appearance: 'none' as 'none',
}

export const baseLabelStyle = {
  ...tableLabelBoldTextStyle,
  fontWeight: 700,
}

export const baseHelperTextStyle = {
  ...baseLabelStyle,
  color: 'var(--gray4)',
}

export const checkboxLabelStyle = {
  ...baseLabelStyle,
  whiteSpace: 'nowrap' as 'nowrap',
}

export const baseFeedbackStyle = {
  display: 'block',
  ...regularBodyBoldTextStyle,
}

// Misc
export const sectionBorder = `
  1px solid var(--gray5)
`

export const ResponsiveHorizontallyScrollableContainer = styled.div({
  overflowX: 'auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

export const MOBILE_BREAKPOINT_WIDTH = 667
