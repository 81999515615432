import React, { FormEvent } from 'react'
import * as yup from 'yup'
import { Form, StringInputGroup } from 'components/Form'
import { Formik } from 'formik'
import styled from 'styled-components'
import { useSignupMutation } from 'config/graphqlTypes'
import Button from 'components/Button'
import { UnauthenticatedPage, FormContainer } from 'pages/Page'
import { cacheUserAndLogin } from 'utils/cacheUserAndLogin'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { routeMap } from './Routes'
import logo from 'assets/images/logos/lineup-ai-blue-black.png'

const values = yup.object({
  name: yup
    .string()
    .min(2, 'Must be 2 characters or more')
    .required('Required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Required'),
  organization: yup.string().required('Required'),
  password: yup
    .string()
    .required('Required')
    .matches(
      /^(?=.*([A-Z]){1,})(?=.*[!@#{$&}*]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,100}$/,
      'Password needs at least one uppercase. ' +
        'Password needs at least one special case letter. ' +
        'Password needs at least one digit. ' +
        'Password needs at least one lowercase. ' +
        'Password of length 8 or more'
    ),
})

const WelcomeContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export const Logo = styled.img({
  width: '280px',
  height: '96px',
  resizeMode: 'contain',
})

const Line = styled.div({
  height: 0,
  width: '100%',
  borderBottom: '1px solid var(--gray4)',
})

const Title = styled.p({
  color: 'var(--gray2)',
  fontWeight: 800,
  fontSize: '1.125rem',
  textAlign: 'center',
  marginBottom: 30,
  marginTop: 30,
})

const LoginContainer = styled.div({
  alignItems: 'center',
  marginTop: 24,
})

const LoginBody = styled.p({
  color: 'var(--gray3)',
  fontWeight: 800,
  fontSize: '0.875rem',
  textAlign: 'center',
  marginTop: 25,
  marginBottom: 15,
})

const LoginLink = styled(Link)({
  color: 'var(--gray3)',
  paddingLeft: 5,
  textDecoration: 'underline',
})

export default () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [signupMutation] = useSignupMutation({
    update: (cache, result) => {
      const signupResult = result?.data?.signup
      const user = signupResult?.user

      if (user) {
        cacheUserAndLogin(cache, user, history)
      }
    },
  })

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        organization: '',
        password: '',
      }}
      validationSchema={values}
      onSubmit={(values, actions) => {
        signupMutation({ variables: { input: { ...values } } })
        actions.setSubmitting(false)
      }}
      render={({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        const onSubmit = (e: FormEvent<HTMLFormElement>) => {
          e.preventDefault()
          handleSubmit()
        }

        return (
          <UnauthenticatedPage>
            <FormContainer>
              <WelcomeContainer>
                <Logo src={logo} alt={'Lineup Logo'} />
              </WelcomeContainer>
              <Title>{t('signup.title')}</Title>

              <Form onSubmit={onSubmit}>
                <StringInputGroup
                  errorFeedbackProps={{
                    error: touched.name ? errors.name ?? '' : '',
                  }}
                  controlProps={{
                    placeholder: t('signup.name'),
                    value: values.name,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    name: 'name',
                    type: 'text',
                  }}
                />
                <StringInputGroup
                  errorFeedbackProps={{
                    error: touched.organization
                      ? errors.organization ?? ''
                      : '',
                  }}
                  controlProps={{
                    placeholder: t('signup.organization'),
                    value: values.organization,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    name: 'organization',
                    type: 'text',
                  }}
                />
                <StringInputGroup
                  errorFeedbackProps={{
                    error: touched.email ? errors.email ?? '' : '',
                  }}
                  controlProps={{
                    placeholder: t('signup.email'),
                    value: values.email,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    name: 'email',
                    type: 'email',
                  }}
                />
                <StringInputGroup
                  errorFeedbackProps={{
                    error: touched.password ? errors.password ?? '' : '',
                  }}
                  controlProps={{
                    placeholder: t('signup.password'),
                    value: values.password,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    name: 'password',
                    type: 'password',
                  }}
                />
                <Button block size="lg" type="submit" variant="blue">
                  {t('signup.signUp')}
                </Button>
              </Form>
              <LoginContainer>
                <Line />
                <LoginBody>
                  {t('signup.loginLabel')}
                  <LoginLink
                    data-testid={t('signup.loginText')}
                    to={routeMap.login}
                  >
                    {t('signup.loginText')}
                  </LoginLink>
                </LoginBody>
              </LoginContainer>
            </FormContainer>
          </UnauthenticatedPage>
        )
      }}
    ></Formik>
  )
}
