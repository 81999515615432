import React, { useState, useRef, Key } from 'react'
import Popover from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

import { DropdownButton } from 'components/DropdownButton'
import { RegularBodyText } from './Text'
import { CheckInput } from 'components/Form'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
})

const OptionButton = styled.button({
  display: 'block',
  width: '100%',
  background: 'none',
  outline: 'none !important',
  border: 'none',
  padding: 10,
  textAlign: 'left',
})

export interface Option<T> {
  value: T
  text: string
}

interface SingleSelectProps<T> {
  text: string | null
  placeholder: string
  options: Option<T>[]
  onSelect: (value: T) => void
}

export const PopoverSelect = <T extends any>({
  text,
  placeholder,
  options,
  onSelect,
}: SingleSelectProps<T>) => {
  const [show, setShow] = useState(false)
  const ref = useRef(null)

  const toggle = () => {
    setShow(!show)
  }

  const onClick = (value: T) => () => {
    setShow(false)
    onSelect(value)
  }

  const onHide = () => {
    setShow(false)
  }

  return (
    <Container ref={ref}>
      <DropdownButton text={text} placeholder={placeholder} onClick={toggle} />

      <Overlay
        show={show}
        placement="bottom"
        container={ref.current}
        target={ref.current}
        onHide={onHide}
        rootClose
      >
        <Popover id="popover-select-popover">
          {options.map(({ text, value }) => (
            <OptionButton onClick={onClick(value)} key={value as Key}>
              {text}
            </OptionButton>
          ))}
        </Popover>
      </Overlay>
    </Container>
  )
}

const CheckboxContainer = styled(Form.Check)({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 10,
})

const CheckboxLabel = styled(Form.Check.Label)({
  flexGrow: 1,
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 5,
  paddingRight: 10,
})

interface OptionCheckboxProps {
  name: string
  selected: boolean
  onClick: () => void
}

const OptionCheckbox = ({ name, selected, onClick }: OptionCheckboxProps) => (
  <CheckboxContainer inline id={name}>
    <CheckInput
      data-testid={name}
      inputProps={{
        checked: selected,
        onChange: onClick,
      }}
    />
    <CheckboxLabel>
      <RegularBodyText>{name}</RegularBodyText>
    </CheckboxLabel>
  </CheckboxContainer>
)

interface MultiSelectProps<T> {
  text: string | null
  placeholder: string
  options: Option<T>[]
  selected: T[]
  onChange: (value: T[]) => void
}

export const PopoverMultiSelect = <T extends any>({
  text,
  placeholder,
  options,
  selected,
  onChange,
}: MultiSelectProps<T>) => {
  const [show, setShow] = useState(false)
  const ref = useRef(null)

  const toggle = () => {
    setShow(!show)
  }

  const onClick = (value: T) => () => {
    const newSelected = selected.includes(value)
      ? selected.filter(v => v !== value)
      : [...selected, value]
    onChange(newSelected)
  }

  const onHide = () => {
    setShow(false)
  }

  const isSelected = (value: T) =>
    !!selected.find(selectedValue => selectedValue === value)

  return (
    <Container ref={ref}>
      <DropdownButton text={text} placeholder={placeholder} onClick={toggle} />

      <Overlay
        show={show}
        placement="bottom"
        container={ref.current}
        target={ref.current}
        onHide={onHide}
        rootClose
      >
        <Popover id="popover-select-popover">
          {options.map(({ text, value }) => (
            <OptionCheckbox
              name={text}
              selected={isSelected(value)}
              onClick={onClick(value)}
              key={value as Key}
            />
          ))}
        </Popover>
      </Overlay>
    </Container>
  )
}
