import React, { useState, FormEvent } from 'react'
import 'react-phone-number-input/style.css'
import Form from 'react-bootstrap/Form'
import Button from 'components/Button'
import { FormWrapper, ButtonsWrapper } from '../../styles'
import { InputGroup } from 'react-bootstrap'
import styled from 'styled-components'
import SettingsSection from '../SettingsSection'
import { useTranslation } from 'react-i18next'
import SalesDayParts from '../salesDayParts/SalesDayParts'
import { LocationFragment } from '../../../../config/graphqlTypes'
import { omit } from 'lodash'
import { convertTo24HourFormat } from '../../../../utils/timeFormats'
import { SalesDayPartType } from './EditLocation'

export interface SubmitInput {
  laborPercentOfRevenue: number
  salesDayParts: SalesDayPartType[]
}

interface Props {
  allowedToEdit: boolean
  submitText: string
  salesDayParts: SalesDayPartType[]
  hasSalesDayPartsPerm: boolean
  onSubmit: (input: SubmitInput) => void
  location: LocationFragment
}

export const LocationForm = ({
  allowedToEdit,
  submitText,
  onSubmit,
  location,
  salesDayParts,
  hasSalesDayPartsPerm,
}: Props) => {
  const { t } = useTranslation()
  const [isValidForm, setIsValidForm] = useState(true)
  const [laborPercentOfRevenue, setLaborPercentOfRevenue] = useState(
    location?.laborPercentOfRevenue ?? ''
  )

  const normalizeSalesDayParts = (
    parts: SalesDayPartType[],
    isDateTime: boolean = false
  ) =>
    parts.map(part => ({
      ...omit(part, '__typename', part.id.startsWith('new') ? 'id' : ''),
      startAt: convertTo24HourFormat(part.startAt, isDateTime),
      endAt: convertTo24HourFormat(part.endAt, isDateTime),
    })) as SalesDayPartType[]

  const [salesDayPartsState, setSalesDayPartsState] = useState(() =>
    normalizeSalesDayParts(salesDayParts, true)
  )

  const onLaborPercentOfRevenueChange = (percent: number) => {
    if (percent === 0) {
      setLaborPercentOfRevenue(1)
    } else if (percent > 100) {
      setLaborPercentOfRevenue(100)
    } else {
      setLaborPercentOfRevenue(percent)
    }
  }

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onSubmit({
      laborPercentOfRevenue: laborPercentOfRevenue,
      salesDayParts: salesDayPartsState,
    })
  }

  const handleValidateForm = (isValid: boolean) => {
    setIsValidForm(isValid)
  }

  const handleSalesDayPartsChange = (parts: SalesDayPartType[]) => {
    setSalesDayPartsState(normalizeSalesDayParts(parts))
  }

  const readOnly = !allowedToEdit

  const translationPrefix = 'management.settings.locations.generalInfo'

  return (
    <FormWrapper>
      <Form>
        <EditLocationFormContainer>
          <LocationHeader> {t(`${translationPrefix}.label`)}</LocationHeader>
          <span>{location.name}</span>
          <EditLocationGeneralHeader></EditLocationGeneralHeader>
          <LocationHeader>Address</LocationHeader>
          <span>{location.address}</span>
          <SettingsSection
            header={t(`${translationPrefix}.general.header`)}
            title={t(`${translationPrefix}.general.title`)}
            description={t(`${translationPrefix}.general.description`)}
          >
            <Form.Control
              required={true}
              readOnly={readOnly}
              type="number"
              value={laborPercentOfRevenue}
              onChange={e =>
                onLaborPercentOfRevenueChange(Number(e.target.value))
              }
            />
            <InputGroup.Text>%</InputGroup.Text>
          </SettingsSection>
          <SalesDayPartsContainer>
            <SalesDayParts
              parts={salesDayParts}
              hasSalesDayPartsPerm={hasSalesDayPartsPerm}
              onValidForm={handleValidateForm}
              onChangeParts={handleSalesDayPartsChange}
            />
          </SalesDayPartsContainer>
        </EditLocationFormContainer>
        <div style={{ width: 220 }}>
          <ButtonsWrapper>
            <Button
              onClick={handleOnSubmit}
              block
              disabled={(readOnly && !hasSalesDayPartsPerm) || !isValidForm}
              size="lg"
              type="submit"
              variant="blue"
            >
              {submitText}
            </Button>
          </ButtonsWrapper>
        </div>
      </Form>
    </FormWrapper>
  )
}

const SalesDayPartsContainer = styled.div`
  margin-top: 20px;
`

const LocationHeader = styled.div`
  font-weight: bold;
`

const EditLocationFormContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`

const EditLocationGeneralHeader = styled(LocationHeader)`
  margin-top: 20px;
  margin-bottom: 10px;
`
