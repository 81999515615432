import React, { useState, ChangeEvent, FormEvent } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { routeMap } from './Routes'
import { useCreateSessionMutation } from 'config/graphqlTypes'
import Button from 'components/Button'
import { LargeBodyBoldText } from 'components/Text'
import { UnauthenticatedPage, FormContainer } from 'pages/Page'
import { cacheUserAndLogin } from 'utils/cacheUserAndLogin'
import { Form, StringInputGroup } from 'components/Form'
import { useQueryParams } from 'utils/useQueryParams'
import * as Analytics from 'config/analytics'
import { useLocalStorage } from '../utils/useLocalStorage'
import { CurrentUserInfoFragment } from '../config/graphqlTypesRaw'
import { Spinner } from 'react-bootstrap'

export const LOGGED_IN_USER_KEY = 'lineup_logged_in_user'

const WelcomeContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const Welcome = styled(LargeBodyBoldText)({
  whiteSpace: 'nowrap',
  color: 'var(--gray3)',
  textAlign: 'center',
  marginRight: 10,
  marginLeft: 10,
  marginBottom: 0,
})

const Line = styled.div({
  height: 0,
  width: '100%',
  borderBottom: '1px solid var(--gray4)',
})

const AppName = styled.p({
  color: 'var(--blue)',
  fontWeight: 900,
  fontSize: '3.75rem',
  textAlign: 'center',
  marginBottom: 15,
})

const ResetPasswordContainer = styled.div({
  alignItems: 'center',
  marginTop: 24,
})

const ResetPasswordBody = styled.p({
  color: 'var(--gray3)',
  fontWeight: 800,
  fontSize: '0.875rem',
  textAlign: 'center',
  marginTop: 25,
  marginBottom: 15,
})

const ResetPasswordLink = styled(Link)({
  color: 'var(--gray3)',
  paddingLeft: 5,
  textDecoration: 'underline',
})

export default () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const successMsg =
    useQueryParams().get('after-reset') === 'true'
      ? t('login.resetSuccess')
      : ''

  const { setItem: storeUser } = useLocalStorage<CurrentUserInfoFragment>(
    LOGGED_IN_USER_KEY
  )

  const [loginMutation, { loading }] = useCreateSessionMutation({
    update: (cache, result) => {
      const loginResult = result?.data?.login
      const user = loginResult?.user

      if (user) {
        storeUser(user)
        cacheUserAndLogin(cache, user, history)
        Analytics.track('User logged in', { id: user?.id, name: user?.name })
      } else {
        const error = (() => {
          if (loginResult?.errorCode === 'noRole') {
            return t('login.noRole')
          }
          if (loginResult?.errorCode === 'noPermissions') {
            return t('login.noPermissions')
          }

          return t('login.invalid')
        })()
        setError(error)
      }
    },
    variables: { input: { email, password } },
  })

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setError('')
  }

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setError('')
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    loginMutation()
  }

  return (
    <UnauthenticatedPage>
      <FormContainer>
        <WelcomeContainer>
          <Line />
          <Welcome>{t('login.welcome')}</Welcome>
          <Line />
        </WelcomeContainer>
        <AppName>{t('misc.appName')}</AppName>
        <Form error={error} successMsg={successMsg} onSubmit={onSubmit}>
          <StringInputGroup
            controlProps={{
              placeholder: t('login.email'),
              value: email,
              onChange: onEmailChange,
              type: 'email',
            }}
          />
          <StringInputGroup
            controlProps={{
              placeholder: t('login.password'),
              value: password,
              onChange: onPasswordChange,
              type: 'password',
            }}
          />
          <Button
            block
            disabled={!(email && password)}
            size="lg"
            type="submit"
            variant="blue"
          >
            <Spinner animation={'border'} size={'sm'} hidden={!loading} />{' '}
            {t('login.logIn')}
          </Button>
        </Form>
        <ResetPasswordContainer>
          <Line />
          <ResetPasswordBody>
            {t('login.troubleLoggingIn')}
            <ResetPasswordLink
              data-testid={t('login.resetPassword')}
              to={routeMap.requestPasswordReset}
            >
              {t('login.resetPassword')}
            </ResetPasswordLink>
          </ResetPasswordBody>
        </ResetPasswordContainer>
      </FormContainer>
    </UnauthenticatedPage>
  )
}
