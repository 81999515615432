import React from 'react'
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit'
import {
  LocationFragment,
  useGetUsersForLocationQuery,
} from '../../../../../config/graphqlTypes'
import { Loading } from '../../../../../components/Loading'
import { SettingsTableHeader } from '../../SettingsTableHeader'
import DataTable from '../../../../../components/DataTable/DataTable'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { DATA_TABLE_PAGE_SIZE_OPTIONS } from '../../../../../components/DataTable/dataTableUtils'
import { useTranslation } from 'react-i18next'
import { LocationUserSummaryFragment } from '../../../../../config/graphqlTypesRaw'
import styled from 'styled-components'

interface Props {
  location: LocationFragment
}

export const LocationUsers = ({ location }: Props) => {
  const { t } = useTranslation()
  const { data, loading } = useGetUsersForLocationQuery({
    variables: { locationId: location.id },
  })

  const nameFormatter = (
    name: LocationUserSummaryFragment['name'],
    user: LocationUserSummaryFragment
  ) => (
    <NameContainer>
      <Name>{name}</Name>
      <Email>{user.email}</Email>
    </NameContainer>
  )

  const roleFormatter = (roles: LocationUserSummaryFragment['roles']) =>
    roles.map((role, i) => <Role key={i}>{role.name}</Role>)

  const columns = [
    {
      dataField: 'name',
      text: t('management.settings.locations.locationUsers.modal.name'),
      sort: true,
      formatter: nameFormatter,
    },
    {
      dataField: 'roles',
      text: t('management.settings.locations.locationUsers.modal.role'),
      formatter: roleFormatter,
    },
  ]

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ToolkitProvider
          keyField="id"
          data={data?.usersForLocation ?? []}
          columns={columns}
          search
        >
          {(props: ToolkitContextType) => (
            <>
              <SettingsTableHeader searchProps={props.searchProps} />
              <DataTable
                {...props.baseProps}
                keyField="id"
                minHeight={'600px'}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPageList: DATA_TABLE_PAGE_SIZE_OPTIONS,
                })}
              />
            </>
          )}
        </ToolkitProvider>
      )}
    </>
  )
}

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Role = styled.span`
  display: block;
  color: var(--gray1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Name = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 15px;
`

const Email = styled.span`
  color: var(--gray4);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
