import { useState, useEffect } from 'react'
import { addProps } from 'ad-hok'

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return width
}

export default useWindowWidth

type AddWindowWidth = <SimplePropsAdder extends {}>(
  props: SimplePropsAdder
) => SimplePropsAdder & {
  windowWidth: number
}

export const addWindowWidth: AddWindowWidth = addProps(() => ({
  windowWidth: useWindowWidth(),
}))
