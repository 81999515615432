import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const ChartLegend = () => {
  const { t } = useTranslation()

  return (
    <StyledContainer fluid>
      <StyledRow className="justify-content-md-end">
        <Col md="auto">
          <Row>
            <StyledSvg>
              <StyledCircle color={'var(--iris-blue)'} />
            </StyledSvg>
            <LegendLabor>
              <span>{t('charts.legend.labor')}</span>
            </LegendLabor>
          </Row>
        </Col>
        <Col md="auto">
          <Row>
            <StyledSvg>
              <StyledCircle color={'var(--blue-light)'} />
            </StyledSvg>
            <LegendSales>
              <span>{t('charts.legend.sales')}</span>
            </LegendSales>
          </Row>
        </Col>
      </StyledRow>
    </StyledContainer>
  )
}

const LegendLabor = styled.div({
  color: 'var(--iris-blue)',
  fontWeight: 'bold',
})

const LegendSales = styled.div({
  color: 'var(--blue-light)',
  fontWeight: 'bold',
})

const StyledCircle = styled.circle<{ color: string }>`
  fill: ${({ color }) => color};
  cx: 10px;
  cy: 12px;
  r: 6px;
  stroke-width: 2px;
`
const StyledSvg = styled.svg`
  height: 20px;
  width: 20px;
`

const StyledRow = styled(Row)`
  column-gap: 16px;
`

const StyledContainer = styled(Container)`
  padding-top: 6px;

  @media (min-width: 992px) {
    padding: 6px 24px 6px;
  }
`
