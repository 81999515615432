import {
  ShiftRoleFragment,
  ShiftRoleCategoryFragment,
  useGetLocationForModifyShiftQuery,
  ShiftFragment,
} from 'config/graphqlTypes'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  isRowEntityEmployee,
  isRowEntityUnavilability,
  RowEntity,
} from 'pages/plan/scheduling/RowEntity'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { EmployeeForModifyShift } from 'pages/plan/scheduling/SchedulingForm'
import { ActiveLocationShiftRoleFragment } from 'config/graphqlTypesRaw'

const NO_CATEGORY_ID = 'NO CATEGORY'

const NoCategory = (): ShiftRoleCategoryFragment => {
  const { t } = useTranslation()

  return {
    id: NO_CATEGORY_ID,
    name: t('planning.noCategory'),
    shiftRoles: [],
  }
}

export const isTheNoCategory = (shiftRoleCategory: ShiftRoleCategoryFragment) =>
  shiftRoleCategory.id === NO_CATEGORY_ID

interface ShiftRoleCategoryBucket<T> {
  shiftRoleCategory: ShiftRoleCategoryFragment
  categoryMembers: Array<T>
}

type MakeShiftRoleCategoryBucketsType = <T>(
  shiftRoles: ShiftRoleFragment[],
  getCategoryMembers: (shiftRoleCategory: ShiftRoleCategoryFragment) => Array<T>
) => Array<ShiftRoleCategoryBucket<T>>

export const makeShiftRoleCategoryBuckets: MakeShiftRoleCategoryBucketsType = (
  shiftRoles,
  getCategoryMembers
) =>
  _(shiftRoles)
    .map('shiftRoleCategory')
    .uniqBy('id')
    .compact()
    .push(NoCategory())
    .map(shiftRoleCategory => ({
      shiftRoleCategory,
      categoryMembers: getCategoryMembers(shiftRoleCategory),
    }))
    .filter(({ categoryMembers }) => categoryMembers.length > 0)
    .value()

export const shiftRoleMatchesCategory = (
  shiftRole: Pick<ShiftRoleFragment, 'shiftRoleCategoryId'> | null,
  shiftRoleCategory: ShiftRoleCategoryFragment
) =>
  (shiftRole?.shiftRoleCategoryId?.toString() ?? NO_CATEGORY_ID) ===
  shiftRoleCategory.id

const employeeIsActive = (locationId: string) => (
  employee: EmployeeForModifyShift
) =>
  employee.employeeLocations?.find(el => el?.locationId === locationId)
    ?.active || false

export const useCurrentLocationActiveEmployeesAndRoles = (
  executeQuery: boolean,
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
) => {
  const locationId = useCurrentLocationId()
  const { data, loading } = useGetLocationForModifyShiftQuery({
    skip: !executeQuery,
    variables: { id: locationId },
  })

  if (loading || !data) {
    return {
      activeEmployeesAndRolesLoading: loading,
      employees: [],
      shiftRoles: [],
    }
  }

  const { employees, shiftRoles } = data?.location

  return {
    activeEmployeesAndRolesLoading: false,
    employees: employees.filter(employeeIsActive(locationId)),
    shiftRoles: shiftRoles.filter(sr =>
      activeLocationShiftRoles.find(alr => alr.shiftRoleId === sr.id)
    ),
  }
}

export const getShiftsForRow = (
  shifts: ShiftFragment[],
  rowEntity: RowEntity,
  shiftRoleCategory: ShiftRoleCategoryFragment
) => {
  const isEmployee = isRowEntityEmployee(rowEntity)
  const isRowUnavilability = isRowEntityUnavilability(rowEntity)

  return isEmployee
    ? shifts.filter(
        ({ employee, shiftRole }) =>
          rowEntity.id === employee.id &&
          shiftRoleMatchesCategory(shiftRole, shiftRoleCategory)
      )
    : shifts.filter(({ isUnavailability, shiftRole }) =>
        isUnavailability ? isRowUnavilability : rowEntity.id === shiftRole?.id
      )
}
