import React from 'react'
import { FC } from 'react'
import cx from 'classnames'

import { PlanForReportingFragment } from 'config/graphqlTypes'
import {
  StyledReportingTableRow,
  StyledReportingSubheaderCell,
} from 'pages/reporting/styles'
import { ReportingDataCell } from 'pages/reporting/ReportingDataCell'
import { asHours } from 'pages/tracking/breakdown/CurrentWeekTable'
import { useTranslation } from 'react-i18next'

interface Props {
  plan: PlanForReportingFragment
}

export const LaborBudgetRow: FC<Props> = ({ plan }) => {
  const { t } = useTranslation()
  return (
    <StyledReportingTableRow>
      <StyledReportingSubheaderCell>
        {t('reporting.labor')}
      </StyledReportingSubheaderCell>
      {plan.dailySalesAndLaborSummaries.map(
        ({ optimalLaborForPlannedSales }, index) => (
          <ReportingDataCell
            labelTranslationKey={index === 0 ? 'reporting.budget' : undefined}
            className={cx(index % 2 === 0 && 'stripe')}
            key={index}
          >
            {asHours(t)(optimalLaborForPlannedSales, true)}
          </ReportingDataCell>
        )
      )}
      <ReportingDataCell className="bold">
        {asHours(t)(
          plan.weekSalesAndLaborSummary.optimalLaborForPlannedSales,
          true
        )}
      </ReportingDataCell>
    </StyledReportingTableRow>
  )
}
