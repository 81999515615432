import React, { FC, ReactNode } from 'react'
import {
  flowMax,
  addDisplayName,
  addRef,
  addState,
  addEffect,
  addProps,
} from 'ad-hok'

import typedAs from 'utils/typedAs'
import { addWindowWidth } from 'utils/useWindowWidth'
import { StyledTbody } from 'pages/plan/styles'

export const Tbody: FC<{ children: ReactNode }> = flowMax(
  addDisplayName('Tbody'),
  addRef('tbodyRef', typedAs<HTMLTableSectionElement | null>(null)),
  addState('scrollWidth', 'setScrollWidth', typedAs<number | null>(null)),
  addWindowWidth,
  addEffect(
    ({ tbodyRef, setScrollWidth }) => () => {
      setScrollWidth(null)
      const timeoutId = setTimeout(() => {
        const tbodyElement = tbodyRef.current
        if (!tbodyElement) return
        const measuredScrollWidth = tbodyElement.scrollWidth
        setScrollWidth(measuredScrollWidth)
      }, 100)
      return () => {
        clearTimeout(timeoutId)
      }
    },
    ['windowWidth']
  ),
  addProps(({ scrollWidth }) => ({
    style: scrollWidth
      ? {
          width: scrollWidth,
        }
      : {},
  })),
  ({ tbodyRef, children, style }) => (
    <StyledTbody ref={tbodyRef} style={style}>
      {children}
    </StyledTbody>
  )
)
