import React from 'react'
import AuthenticatedPage from 'pages/AuthenticatedPage'
import { useTranslation } from 'react-i18next'
import { ManagementWrapper } from 'pages/management/ManagementWrapper'
import {
  MessagesStyledContainer,
  ManagementHeader,
  CenterTexVerticallyWrapper,
  MessageHour,
  NoMessagesMessage,
} from 'pages/management/styles'
import { MessagesDataTable } from 'pages/management/messages/MessagesDataTable'
import { MessagesHeader } from 'pages/management/messages/MessagesHeader'
import { Loading } from 'components/Loading'
import {
  MassNotificationsFragment,
  useGetLocationMessagesQuery,
} from 'config/graphqlTypes'
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { CSSTransition } from 'react-transition-group'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit'
import { ShiftRole, ShiftRoleCategory } from 'config/graphqlTypesRaw'

export const Messages = () => {
  const { t } = useTranslation()
  const locationId = useCurrentLocationId()
  const { data, loading } = useGetLocationMessagesQuery({
    variables: {
      locationId,
    },
  })

  const messagesListing = _.orderBy(
    data?.massNotifications ?? [],
    ({ createdAt }) => DateTime.fromISO(createdAt),
    ['desc']
  )

  const dateFormatter = (date: string) => {
    const dateTime = DateTime.fromISO(date)
    return (
      <CenterTexVerticallyWrapper>
        <span>{dateTime.toFormat('MM/dd/yyyy')}</span>
        <MessageHour>{dateTime.toFormat('t')}</MessageHour>
      </CenterTexVerticallyWrapper>
    )
  }

  const departmentsFormatter = (departments: ShiftRoleCategory[]) => {
    return (
      <CenterTexVerticallyWrapper>
        {departments.length ? (
          departments.map(department => (
            <span key={department.id}>{department.name}</span>
          ))
        ) : (
          <span>
            {t('management.massNotification.departments.allDepartments')}
          </span>
        )}
      </CenterTexVerticallyWrapper>
    )
  }

  const rolesFormatter = (roles: ShiftRole[]) => {
    return (
      <CenterTexVerticallyWrapper>
        {roles.length ? (
          roles.map(role => <span key={role.id}>{role.name}</span>)
        ) : (
          <span>{t('management.massNotification.roles.allRoles')}</span>
        )}
      </CenterTexVerticallyWrapper>
    )
  }

  const textWrapper = (column: string) => (
    <CenterTexVerticallyWrapper>{column}</CenterTexVerticallyWrapper>
  )

  const columns = [
    {
      dataField: 'createdAt',
      text: t('management.messagesColumns.date'),
      sort: true,
      formatter: dateFormatter,
      filterValue: (_: any, row: MassNotificationsFragment) => {
        const dateTime = DateTime.fromISO(row.createdAt)
        return `${dateTime.toFormat('MM/dd/yyyy')} ${dateTime.toFormat('t')}`
      },
    },
    {
      dataField: 'userName',
      text: t('management.messagesColumns.sender'),
      sort: true,
      formatter: textWrapper,
    },
    {
      dataField: 'shiftRoleCategories',
      text: t('management.messagesColumns.department'),
      formatter: departmentsFormatter,
    },
    {
      dataField: 'shiftRoles',
      text: t('management.messagesColumns.role'),
      formatter: rolesFormatter,
    },
    {
      dataField: 'messageText',
      text: t('management.messagesColumns.messageText'),
      formatter: textWrapper,
    },
  ]

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeader>{t('management.messages')}</ManagementHeader>

          {loading ? (
            <Loading />
          ) : (
            <MessagesStyledContainer>
              <ToolkitProvider
                keyField="id"
                data={messagesListing}
                columns={columns}
                search
              >
                {(props: ToolkitContextType) => (
                  <>
                    <MessagesHeader searchProps={props.searchProps} />
                    <MessagesDataTable
                      {...props.baseProps}
                      messages={messagesListing}
                      columns={columns}
                    />
                  </>
                )}
              </ToolkitProvider>
              {messagesListing.length === 0 && (
                <CSSTransition timeout={900} classNames="item">
                  <NoMessagesMessage>
                    {t('management.noMessages')}
                  </NoMessagesMessage>
                </CSSTransition>
              )}
            </MessagesStyledContainer>
          )}
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}
