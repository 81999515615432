import { round } from 'lodash'
import { DateTime, Duration } from 'luxon'

const FIFTEEN_MINUTES_IN_SECONDS = 60 * 15
const roundToNearestFifteenMinutes = (seconds: number) =>
  Math.round(seconds / FIFTEEN_MINUTES_IN_SECONDS) * FIFTEEN_MINUTES_IN_SECONDS

export const getGraphTimelineHelpers = (
  graphTimeline: DateTime[],
  timelineWidthPixels: number
) => {
  const firstWindowPoint = graphTimeline[0]
  const lastWindowPoint = graphTimeline[graphTimeline.length - 1]

  const windowStartSecond = firstWindowPoint.toSeconds()
  const windowEndSecond = lastWindowPoint.toSeconds()
  const windowDurationInSeconds = windowEndSecond - windowStartSecond

  const getTimeWithOffset = (time: DateTime, offsetPixels: number) => {
    const offsetSeconds =
      windowDurationInSeconds * (offsetPixels / timelineWidthPixels)

    return time.plus(Duration.fromMillis(1000 * offsetSeconds))
  }

  const getPercentFromLeft = (startAt: DateTime, offsetPixels?: number) => {
    const secondsAfterWindowStart = startAt.toSeconds() - windowStartSecond
    const rawPercent = round(
      (secondsAfterWindowStart / windowDurationInSeconds) * 100,
      2
    )

    return rawPercent + offsetToRoundedPercent(offsetPixels)
  }

  const getPercentFromRight = (endAt: DateTime, offsetPixels?: number) => {
    const secondsBeforeWindowEnd = windowEndSecond - endAt.toSeconds()

    const rawPercent = round(
      (secondsBeforeWindowEnd / windowDurationInSeconds) * 100,
      2
    )

    return rawPercent - offsetToRoundedPercent(offsetPixels)
  }

  const getNearestFifteenMinutesInSeconds = (xOffsetPixels: number) => {
    const offsetSeconds =
      windowDurationInSeconds * (xOffsetPixels / timelineWidthPixels)
    return roundToNearestFifteenMinutes(offsetSeconds)
  }

  const getDurationFromOffset = (xOffsetPixels: number) => {
    const seconds = getNearestFifteenMinutesInSeconds(xOffsetPixels)
    return Duration.fromMillis(1000 * seconds)
  }

  const roundOffsetToFifteen = (xOffsetPixels: number) => {
    const seconds = getNearestFifteenMinutesInSeconds(xOffsetPixels)
    return timelineWidthPixels * (seconds / windowDurationInSeconds)
  }

  const offsetToRoundedPercent = (offsetPixels?: number) => {
    if (!offsetPixels || timelineWidthPixels === 0) return 0

    const roundedOffset = roundOffsetToFifteen(offsetPixels)
    return (100 * roundedOffset) / timelineWidthPixels
  }

  return {
    getTimeWithOffset,
    getPercentFromLeft,
    getPercentFromRight,
    getDurationFromOffset,
    roundOffsetToFifteen,
  }
}

export type TimelineHelpers = ReturnType<typeof getGraphTimelineHelpers>
