import { useState, useEffect } from 'react'
import { reject } from 'lodash/fp'

import * as Analytics from 'config/analytics'
import { Address } from 'pages/covid/types'
import { useQueryParams } from 'utils/useQueryParams'
import { getAddressesForDashboard } from 'pages/covid/getAddressesForDashboard'
import { useGetDashboard } from 'queries/useGetDashboard'
import {
  useUpdateCovidDashboardMutation,
  useCreateCovidDashboardMutation,
} from 'config/graphqlTypes'
import { getAddress } from 'pages/covid/getAddress'
import { routeMap } from 'pages/Routes'

export interface DashboardInfo {
  id?: string
  name?: string
  email?: string
  url?: string
  addresses: Address[]
}

const EMPTY_DASH = {
  addresses: [],
}

const useInitializeDashboard = (
  setDashboard: (dashboard: DashboardInfo) => void
) => {
  const qp = useQueryParams()
  const queryDashboardId = qp.get('dashboard_id') || ''
  const queryInitialAddress = qp.get('initial_address')

  const dashFromQuery = useGetDashboard(queryDashboardId)
  useEffect(() => {
    if (!(queryDashboardId || queryInitialAddress)) {
      setDashboard(EMPTY_DASH)
      return
    }

    if (dashFromQuery) {
      Analytics.crcDashboardIdentify(queryDashboardId, {})

      getAddressesForDashboard(dashFromQuery).then(addresses => {
        setDashboard({ ...dashFromQuery, addresses })
      })
    } else if (queryInitialAddress) {
      getAddress(queryInitialAddress).then((address: Address) => {
        setDashboard({ addresses: [address] })
        updateUrl('')
      })
    }

    // This should only ever run once to grab the location from the query param.
    // BUT, on the first run, the useGetDashboard query won't have returned yet, so
    // we usually need to run until we get a dash with an ID.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashFromQuery?.id])
}

const updateUrl = (path: string) => {
  window.history.replaceState({}, '', routeMap.covidDashboard + path)
}

export const useDashboard = () => {
  const [dashboard, setDashboard] = useState<DashboardInfo | null>(null)
  useInitializeDashboard(setDashboard)

  const [createDashboard] = useCreateCovidDashboardMutation()
  const saveNewDashboard = async (name: string, email: string) => {
    if (!dashboard) {
      return
    }

    const fullAddresses = dashboard.addresses.map(a => a.fullAddress)
    const result = await createDashboard({
      variables: { input: { name, email, addresses: fullAddresses } },
    })

    const newBackendDash = result.data!.createCovidDashboard
    const dashId = newBackendDash.id
    const newDashboard: DashboardInfo = {
      ...newBackendDash,
      addresses: dashboard.addresses,
    }

    setDashboard(newDashboard)
    updateUrl(`?dashboard_id=${dashId}`)

    const analyticsPayload = {
      email,
      dashboardName: name,
      addresses: fullAddresses,
      backendDashboardID: dashId,
    }
    Analytics.crcDashboardIdentify(dashId, analyticsPayload)
    Analytics.track('[CRC]: Saved dashboard modal', analyticsPayload)
  }

  const [updateBackendDashboard] = useUpdateCovidDashboardMutation()
  const updateDashboardAddresses = (newAddresses: Address[]) => {
    setDashboard({
      ...dashboard,
      addresses: newAddresses,
    })

    if (!dashboard?.id) {
      return
    }

    const newFullAddresses = newAddresses.map(a => a.fullAddress)
    updateBackendDashboard({
      variables: { input: { addresses: newFullAddresses, id: dashboard.id } },
    })
  }

  const addAddress = (address: Address) => {
    const newAddresses = [address, ...(dashboard?.addresses || [])]
    updateDashboardAddresses(newAddresses)
  }

  const removeAddress = (addressToRemove: Address) => {
    Analytics.track('[CRC]: Removed address from dashboard', {
      fullAddress: addressToRemove.fullAddress,
    })

    const isAddressToRemove = (address: Address) =>
      addressToRemove.key === address.key

    const newAddresses = reject(isAddressToRemove)(dashboard?.addresses)
    updateDashboardAddresses(newAddresses)
  }

  return {
    dashboard,
    addAddress,
    removeAddress,
    saveNewDashboard,
  }
}
