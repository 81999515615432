import React from 'react'
import { Link } from 'react-router-dom'
import { FC } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

import { LocationSummaryFragment } from 'config/graphqlTypes'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import {
  DropdownButton,
  StyledCurrentLocationName,
} from 'components/Navbar/styles'

interface Props {
  locations: LocationSummaryFragment[]
  getPath: (locationId: string) => string
}

export const LocationSelector: FC<Props> = ({ locations, getPath }) => {
  const currentLocationName = useCurrentLocation().location.name

  return (
    <Dropdown>
      <Dropdown.Toggle
        data-testid="location-selector"
        as={DropdownButton}
        id="nav-dropdown"
      >
        <StyledCurrentLocationName>
          {currentLocationName}
        </StyledCurrentLocationName>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {locations
          .filter(l => l.status !== 'disabled')
          .map(({ id, name }) => (
            <Dropdown.Item as={Link} to={getPath(id)} key={id}>
              {name}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
