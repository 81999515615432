import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import Button from 'components/Button'
import {
  AddTemplateHeader,
  SaveTemplateModalHeader,
  SavedTemplateText,
  SaveTemplateModalBody,
} from 'pages/plan/header/styles'
import { Label, StringInputGroup } from 'components/Form'
import { useCreatePlanTemplateMutation } from 'config/graphqlTypes'
import { usePlanQueryVariables } from 'pages/plan/planQueryVariablesContext'
import { useGetPlan } from '../../../queries/useGetPlan'

interface Props {
  open: boolean
  setOpen: (setOpen: boolean) => void
  startDate: DateTime
  locationId: string
}

export const SaveTemplateModal = ({
  open,
  setOpen,
  startDate,
  locationId,
}: Props) => {
  const { t } = useTranslation()
  const [templateName, setTemplateName] = useState('')
  const [templateCreated, setTemplateCreated] = useState(false)
  const [nameError, setNameError] = useState('')
  const [
    createPlanTemplate,
    { error: createPlanTemplateError },
  ] = useCreatePlanTemplateMutation()
  const planQueryVariables = usePlanQueryVariables()
  const { startOn, endOn } = planQueryVariables
  const { refetch: refetchPlan } = useGetPlan(startOn, endOn)

  const saveNewPlanTemplate = async () => {
    if (!templateName) {
      return
    }

    const result = await createPlanTemplate({
      variables: {
        input: { name: templateName, startOn: startDate, locationId },
      },
    })
    const createTemplateRequest = result?.data?.createPlanTemplate
    const newPlanTemplate = createTemplateRequest?.planTemplate
    if (createTemplateRequest?.success && newPlanTemplate) {
      setTemplateName(newPlanTemplate.name)
      setTemplateCreated(true)
    } else if (createTemplateRequest) {
      setNameError(createTemplateRequest.errors[0])
    }
  }

  const handleDismissModal = () => {
    setOpen(false)
    if (templateCreated && !createPlanTemplateError) {
      refetchPlan()
    }
  }

  return (
    <Modal
      data-testid="submit-modal"
      show={open}
      centered
      onHide={handleDismissModal}
    >
      {templateCreated ? (
        <>
          <Modal.Header closeButton>
            <SaveTemplateModalHeader>
              <AddTemplateHeader>
                {t('planning.saveTemplateModal.templateSaved')}
              </AddTemplateHeader>
            </SaveTemplateModalHeader>
          </Modal.Header>
          <Modal.Body>
            <SaveTemplateModalBody>
              <SavedTemplateText>
                {t('planning.saveTemplateModal.templateSavedText', {
                  name: templateName,
                })}
              </SavedTemplateText>
              <Button
                variant="blue"
                disabled={!templateName}
                onClick={handleDismissModal}
                size="lg"
              >
                {t('planning.saveTemplateModal.done')}
              </Button>
            </SaveTemplateModalBody>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <SaveTemplateModalHeader>
              <AddTemplateHeader>
                {t('planning.saveTemplateModal.createTemplate')}
              </AddTemplateHeader>
              <p>{t('planning.saveTemplateModal.templateText')}</p>
            </SaveTemplateModalHeader>
          </Modal.Header>
          <Modal.Body>
            <SaveTemplateModalBody>
              <Label
                label={t('planning.saveTemplateModal.templateNameLabel')}
              />
              <StringInputGroup
                errorFeedbackProps={{ error: nameError }}
                controlProps={{
                  placeholder: t('planning.saveTemplateModal.templateName'),
                  value: templateName,
                  onChange: ({ target }) => setTemplateName(target.value),
                }}
              />
              <Button
                variant="blue"
                disabled={!templateName}
                onClick={saveNewPlanTemplate}
                block
                size="lg"
                data-testid="submit-template"
                waitOnPromise
              >
                {t('planning.saveTemplateModal.saveTemplate')}
              </Button>
            </SaveTemplateModalBody>
          </Modal.Body>
        </>
      )}
    </Modal>
  )
}
