import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledDashboardLogo, LogoText, Logo } from 'pages/covid/styles'
import logoColor from 'assets/images/logos/lineup-ai-black.png'
import logoWhite from 'assets/images/logos/lineup-ai-white.png'

interface Props {
  forceColorWhite?: boolean
}

export const DashboardLogo: FC<Props> = ({ forceColorWhite }) => {
  const { t } = useTranslation()
  const logo = forceColorWhite ? logoWhite : logoColor

  return (
    <StyledDashboardLogo
      forceColorWhite={!!forceColorWhite}
      href="https://www.lineup.ai/"
      target="_blank"
    >
      <LogoText>{t('covidDashboard.footerLogoTextByLineup')}</LogoText>
      <Logo src={logo} alt={t('navbar.logoAlt')} />
    </StyledDashboardLogo>
  )
}
