import React, { HTMLAttributes, useEffect, useState } from 'react'
import { OperatingHoursFragment } from '../../../../config/graphqlTypes'
import {
  convertTo24HourFormat,
  convertToDefault12HourFormat,
} from '../../../../utils/timeFormats'
import { useTranslation } from 'react-i18next'
import TimeInputsRow from '../../../../components/TimeInputsRow'

const DEFAULT_OPEN_AT_24HR = '09:00'
const DEFAULT_CLOSE_AT_24HR = '21:00'
const DEFAULT_OPEN_AT_12HR = '9:00 AM'
const DEFAULT_CLOSE_AT_12HR = '9:00 PM'

interface Props extends HTMLAttributes<HTMLDivElement> {
  operatingHrsDay: OperatingHoursFragment
  isCancelled: boolean
  onChange: () => void
  onChangeUpdate: (operatingHrsDay: OperatingHoursFragment) => void
}

function OperatingHoursRow({
  operatingHrsDay,
  isCancelled,
  onChangeUpdate,
  onChange,
  ...props
}: Props) {
  const { day, closed, openAtIso8601, closeAtIso8601 } = operatingHrsDay

  const startAt12Hr = convertToDefault12HourFormat(openAtIso8601)
  const closeAt12Hr = convertToDefault12HourFormat(closeAtIso8601)

  const [openAt, setOpenAt] = useState(startAt12Hr)
  const [closeAt, setCloseAt] = useState(closeAt12Hr)
  const [closedCheckbox, setClosedCheckbox] = useState(closed ?? false)

  const { t } = useTranslation()

  function handleOpenAtChange(time: string) {
    onChangeUpdate({
      ...operatingHrsDay,
      closed: closedCheckbox,
      openAtIso8601: time,
      closeAtIso8601: closeAt,
    })
    setOpenAt(time)
  }

  function handleCloseAtChange(time: string) {
    onChangeUpdate({
      ...operatingHrsDay,
      closed: closedCheckbox,
      openAtIso8601: openAt,
      closeAtIso8601: time,
    })
    setCloseAt(time)
  }

  function handleClosedCheckboxChange(checked: boolean) {
    setClosedCheckbox(checked)

    if (closedCheckbox) {
      onChangeUpdate({
        ...operatingHrsDay,
        openAtIso8601: DEFAULT_OPEN_AT_24HR,
        closeAtIso8601: DEFAULT_CLOSE_AT_24HR,
        closed: checked,
      })
      setOpenAt(DEFAULT_OPEN_AT_12HR)
      setCloseAt(DEFAULT_CLOSE_AT_12HR)
    } else {
      if (checked) {
        setOpenAt('')
        setCloseAt('')
      } else {
        setOpenAt(startAt12Hr)
        setCloseAt(closeAt12Hr)
      }

      onChangeUpdate({
        ...operatingHrsDay,
        openAtIso8601: convertTo24HourFormat(openAt),
        closeAtIso8601: convertTo24HourFormat(closeAt),
        closed: checked,
      })
    }
  }

  useEffect(() => {
    if (isCancelled) {
      setOpenAt(startAt12Hr)
      setCloseAt(closeAt12Hr)
      setClosedCheckbox(closed ?? false)
      onChange()
    }
  }, [startAt12Hr, closeAt12Hr, openAt, closeAt, isCancelled, closed, onChange])

  return (
    <TimeInputsRow
      label={t(`misc.fullDaysOfWeek.${day}`)}
      start={openAt}
      end={closeAt}
      disableStart={closedCheckbox}
      disableEnd={closedCheckbox}
      onChangeStart={handleOpenAtChange}
      onChangeEnd={handleCloseAtChange}
      onBlurStart={handleOpenAtChange}
      onBlurEnd={handleCloseAtChange}
      rowControls={{
        checkboxId: `management.locationSettings.operatingHours.closedCheckbox.${day}`,
        checked: closedCheckbox,
        checkboxLabel: 'Closed',
        flags: { showCheckbox: true },
        actions: { onClickCheck: handleClosedCheckboxChange },
      }}
      {...props}
    />
  )
}

export default OperatingHoursRow
