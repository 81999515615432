import React from 'react'
import styled from 'styled-components'

import { WeatherDisplayProps } from 'queries/useWeather'
import { mediumBodyTextStyle, regularBodyTextStyle } from 'config/styles'
import { MediumBodyBoldText } from 'components/Text'
import { WeatherIcon } from 'pages/tracking/metrics/styles'

export const WeatherDisplayRow: React.FC<Props> = ({
  textSize = 'medium',
  weather: { summary, icon, temperature },
  align = 'left',
}) => (
  <Weather>
    <IconTemp align={align}>
      <WeatherIcon src={icon} alt={summary} />
      {temperature}°
    </IconTemp>
    <Summary size={textSize}>{summary}</Summary>
  </Weather>
)

const Weather = styled.div({
  color: 'var(--gray2)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  height: '100%',
  marginBottom: 9,
  alignItems: 'center',
})

const IconTemp = styled(MediumBodyBoldText)<{ align: 'left' | 'center' }>(
  ({ align }) => ({
    paddingRight: 9,
    marginRight: 18,
    borderRight: 'solid 1px var(--gray6)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: align,
  })
)

type SummaryTextSize = 'medium' | 'regular'

const Summary = styled.p<{ size: SummaryTextSize }>(({ size }) => ({
  ...(size === 'medium' ? mediumBodyTextStyle : regularBodyTextStyle),
  fontWeight: 500,
}))

interface Props {
  textSize?: SummaryTextSize
  weather: WeatherDisplayProps
  align?: 'left' | 'center'
}
