import React from 'react'
import Col from 'react-bootstrap/Col'

import { AddressColContentWrapper } from 'pages/covid/dashboard/addressRow/styles'
import { useIsColumnLayout } from 'pages/covid/dashboard/useIsColumnLayout'

interface Props {
  isFirst?: boolean
  header: JSX.Element
  children: JSX.Element | JSX.Element[]
}

export const AddressCol = ({ isFirst, header, children }: Props) => {
  const xl = isFirst ? 4 : 3
  const isColumnLayout = useIsColumnLayout()

  return (
    <Col xl={xl}>
      {isColumnLayout && header}
      <AddressColContentWrapper isFirst={isFirst}>
        {children}
      </AddressColContentWrapper>
    </Col>
  )
}
