import {
  ApolloClient,
  InMemoryCache,
  ServerParseError,
  ServerError,
  ApolloLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { HttpLink } from '@apollo/client/link/http'
import { GraphQLError } from 'graphql'

import possibleTypes from 'config/possibleTypes.json'
import { DeleteSessionDocument } from './graphqlTypes'
import { navigateToLogin } from 'pages/Routes'
import { LOGGED_IN_USER_KEY } from '../pages/Login'

const httpLink = new HttpLink({
  uri:
    (window as any)._env_?.REACT_APP_GRAPHQL_ENDPOINT ||
    process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include',
})

const onNetworkErrorHandlers: ((
  error: Error | ServerError | ServerParseError
) => void)[] = []
const onGraphQLErrorHandlers: ((error: GraphQLError) => void)[] = []

export const subscribeToNetworkErrors = (
  handler: (error: Error | ServerError | ServerParseError) => void
) => {
  onNetworkErrorHandlers.push(handler)
}

export const subscribeToGraphQLErrors = (
  handler: (error: GraphQLError) => void
) => {
  onGraphQLErrorHandlers.push(handler)
}

subscribeToGraphQLErrors(error => {
  if (error.message === 'Not Authorized') {
    apolloLogOut()
  }
})

var isLoggingOut = false

const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError }) => {
    if (isLoggingOut && operation?.operationName !== 'deleteSession') {
      // After the logout request, we call `document.location.href =` to redirect
      // and sometime after that the browser stops evaluating JS and actually
      // redirects. During that transition, other midflight requests might get Not
      // Authorized responses or throw Failed to Fetch errors because they have been
      // cancelled. Those could still trigger this onError callback. Ignore
      // those transient errors.

      if (response) {
        // @ts-ignore
        response.errors = null
      }
      return
    }
    if (graphQLErrors?.length) {
      graphQLErrors.forEach(error => {
        onGraphQLErrorHandlers.forEach(handler => handler(error))
      })
    }
    if (networkError) {
      onNetworkErrorHandlers.forEach(handler => handler(networkError))
    }
  }
)

const link = ApolloLink.from([errorLink, httpLink])

const cache = new InMemoryCache({ possibleTypes })

const client = new ApolloClient({
  link,
  cache,
})

export const apolloLogOut = async () => {
  isLoggingOut = true

  try {
    await client.mutate({ mutation: DeleteSessionDocument })
  } catch {}

  client.stop()

  navigateToLogin()
  localStorage.removeItem(LOGGED_IN_USER_KEY)
}

export default client
