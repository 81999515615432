import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import {
  Hero,
  AboveAddressBar,
  AddressFormRow,
  LogoRow,
  Title,
  TryItToday,
  SubTitle,
} from 'pages/covid/welcome/styles'
import { LearnMore } from 'pages/covid/welcome/LearnMore'
import { AddressForm } from 'pages/covid/AddressForm'
import { routeMap } from 'pages/Routes'
import { Address } from 'pages/covid/types'
import { DashboardLogo } from 'pages/covid/dashboard/DashboardLogo'
import { WestfieldLogo } from 'pages/covid/dashboard/WestfieldLogo'

export const HeroBlock = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const isDashboard = location.pathname === '/localized-activity-dashboard'

  const onSubmitAddress = (address: Address) => {
    history.push({
      pathname: routeMap.covidDashboard,
      search: `initial_address=${address.fullAddress}`,
      state: { lastPath: location.pathname },
    })
  }

  return (
    <Hero>
      {location.pathname === '/reopenright' ? (
        <LogoRow>
          <WestfieldLogo />
          <DashboardLogo forceColorWhite />
        </LogoRow>
      ) : (
        <DashboardLogo forceColorWhite />
      )}
      <Title>
        {t(isDashboard ? 'covidDashboard.dashTitle' : 'covidDashboard.title')}
      </Title>
      <SubTitle>
        {t(
          isDashboard
            ? 'covidDashboard.dashSubtitle'
            : 'covidDashboard.subtitle'
        )}
      </SubTitle>
      <TryItToday>{t('covidDashboard.youCanItsFree')}</TryItToday>
      <AboveAddressBar>{t('covidDashboard.aboveAddressBar')}</AboveAddressBar>
      <AddressFormRow>
        <AddressForm addAddress={onSubmitAddress} />
      </AddressFormRow>
      <LearnMore />
    </Hero>
  )
}
