import { Interval, DateTime } from 'luxon'
import { ceil } from 'lodash/fp'
import { ShiftFragment } from 'config/graphqlTypes'
import { useGetOperatingHoursOrDefaults } from 'pages/plan/dailyViewModal/useGetOperatingHoursOrDefaults'

export const useDailyTimeline = (
  shiftsForDay: ShiftFragment[],
  day: Interval
): DateTime[] => {
  //TODO: commented out this for now as a temporary fix. It'll need to be revisited.
  // It's a hack and may cause bugs in the future.
  //const predictionTimes = predictionsForDay.map(pred => pred.startAt)

  const shiftTimes = shiftsForDay.flatMap(({ startAt, endAt }) => [
    startAt,
    endAt,
  ])

  const { openOrDefault, closeOrDefault } = useGetOperatingHoursOrDefaults(day)

  const allTimes = [
    ...shiftTimes,
    //...predictionTimes,
    openOrDefault,
    closeOrDefault,
  ].sort()

  const startTime = allTimes[0].startOf('hour')
  const endTime = allTimes.pop()!.endOf('hour')
  const hours = ceil(endTime.diff(startTime, 'hour').hours)

  const timeline = Array.from({ length: hours }, (_, i) =>
    startTime.plus({ hour: i })
  )

  return timeline
}
