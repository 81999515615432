export type DaysOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export interface TimeSlot {
  id: string
  startTime: string
  endTime: string
}
export interface OperatingHours {
  day: DaysOfWeek
  scheduleBeginIso8601: string
  scheduleEndIso8601: string
}
export interface DaysAvailable {
  id: string
  dayOfWeek: DaysOfWeek
  unavailable: boolean
  timeSlots: TimeSlot[]
}
export interface Availability {
  daysAvailable: DaysAvailable[]
  id: string
  state: string
  managerNote: string
  employeeNote: string
}

export const dayOfWeekToNumber = (day: DaysOfWeek): number =>
  ({
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
  }[day])

export const getDefaultShifts = (location: any): DaysAvailable[] => {
  const shifts: DaysAvailable[] = []

  location.operatingHours.forEach((operatingHours: OperatingHours) => {
    shifts.push({
      id: '',
      dayOfWeek: operatingHours.day as DaysAvailable['dayOfWeek'],
      unavailable: false,
      timeSlots: [
        {
          id: '',
          startTime: operatingHours.scheduleBeginIso8601 ?? '',
          endTime: operatingHours.scheduleEndIso8601 ?? '',
        },
      ],
    })
  })

  return sortDaysAvailable(shifts, location)
}

export const sortDaysAvailable = (shifts: DaysAvailable[], location: any) =>
  shifts
    .sort(function sortByDay(a, b) {
      return dayOfWeekToNumber(a.dayOfWeek) - dayOfWeekToNumber(b.dayOfWeek)
    })
    .map((shift, i) => {
      if (shift.timeSlots.length === 0) {
        return {
          ...shift,
          timeSlots: [
            {
              id: '',
              startTime: location.operatingHours[i]?.scheduleBeginIso8601 ?? '',
              endTime: location.operatingHours[i]?.scheduleEndIso8601 ?? '',
            },
          ],
        }
      } else {
        return shift
      }
    })
