import { Interval, DateTime } from 'luxon'

interface PlanPartialWithDailySummaries<T extends { startOn: DateTime }> {
  dailySalesAndLaborSummaries: T[]
}

export const getDailySummary = <T extends { startOn: DateTime }>(
  day: Interval,
  plan: PlanPartialWithDailySummaries<T>,
  fiscalWeekOffsetFromMonday: number
) => {
  let start = day.start

  if (start.weekday < fiscalWeekOffsetFromMonday) {
    const daysOffset = fiscalWeekOffsetFromMonday - start.weekday
    start.plus({ days: daysOffset })
  }

  const summary = plan.dailySalesAndLaborSummaries.find(
    s => s.startOn.toISODate() === start.toISODate()
  )

  if (!summary) {
    throw new Error(`Daily summary not found for day: ${day.start.toISODate()}`)
  }

  return summary!
}
