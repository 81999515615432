import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  Redirect,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Settings } from 'luxon'
import { useIntercom } from 'react-use-intercom'

import * as Analytics from 'config/analytics'
import { CurrentUserInfoFragment } from 'config/graphqlTypes'
import Login from 'pages/Login'
import Signup from 'pages/Signup'
import Onboarding from 'pages/Onboarding'
import {
  RequestPasswordReset,
  RequestPasswordResetSuccess,
} from 'pages/RequestPasswordReset'
import ResetPassword from 'pages/ResetPassword'
import { CovidDashboardWelcome } from 'pages/covid/welcome'
import { CovidDashboard } from 'pages/covid/dashboard'
import Tracking from 'pages/tracking'
import Plan from 'pages/plan'
import {
  useTryCurrentLocation,
  useCurrentLocationId,
} from 'queries/useCurrentLocation'
import {
  useLoggedInUser,
  useLoggedInUserOrRedirect,
} from 'queries/useLoggedInUser'
import Reporting from 'pages/reporting'
import { UnauthenticatedPage } from './Page'
import { Plans } from 'pages/plans/index'
import { Messages } from 'pages/management/messages/Messages'
import { ManageRequests } from 'pages/management/availabilityRequests/ManageRequests'
import { Employees } from './management/employees/Employees'
import { EditEmployee } from './management/employees/EditEmployee'
import { Locations } from './management/settings/locations/Locations'
import { EditLocation } from './management/settings/locations/EditLocation'
import { Organizations } from './management/settings/organizations/Organizations'
import { EditOrganization } from './management/settings/organizations/EditOrganization'
import RedirectToMobileApp from './RedirectToMobileApp'
import { AddEmployee } from './management/employees/AddEmployee'
import { IntegrationSettings } from './management/posIntegrations/integrationSettings'
import { TotalSales } from './insights/total_sales/TotalSales'
import { AverageOrders } from './insights/average_orders/AverageOrders'
import { BaselineComparisons } from './insights/baseline_comparisons/BaselineComparisons'
import { ScheduledActualLabor } from './insights/scheduled_actual_labor/ScheduledActualLabor'
import { LaborTargetBreakdowns } from './insights/labor_target_breakdowns/LaborTargetBreakdowns'
import { Overtime } from './insights/overtime/Overtime'
import { ToucanInsightsProvider } from 'pages/insights/ToucanInsightsContext'
import { ToucanInsights } from './insights/ToucanInsights'
import { AddEditTimeOffBlockedDate } from './management/settings/organizations/timeOffBlockedDate/AddEditTimeOffBlockedDate'
import { Items } from './items'

export const routeMap = {
  redirectToMobileApp: '/mobile/:screen_name',
  signup: '/signup',
  onboarding: '/onboarding',
  login: '/',
  requestPasswordReset: '/request-password-reset',
  requestPasswordResetSuccess: '/request-password-reset-success',
  resetPassword: '/reset-password',
  covidDashboardWelcome: '/reopening',
  dashboardWelcome: '/localized-activity-dashboard',
  covidDashboardWelcomeWestfield: '/reopenright',
  covidDashboardWelcomeTechTable: '/restauranttech',
  covidDashboard: '/covid/dashboard',
  locations: '/locations',
  location: {
    root: '/locations/:location_id',
    tracking: '/tracking',
    plans: '/plans',
    plan: '/plans/:start_date',
    reportingRoot: '/reporting',
    reporting: '/reporting/:start_date',
    items: '/items',
    management: {
      management: '/management',
      requests: '/management/requests',
      requestHistory: '/management/requests/history',
      integrationSettings: '/management/integrations',
      employees: '/management/employees',
      employeeNew: '/management/employees/new',
      employeeEdit: '/management/employees/:id/edit',
      messages: '/management/messages',
    },
  },
  management: {
    root: '/management',
    locations: '/management/locations',
    location: '/management/locations/:location_id',
    organizations: '/management/organizations',
    organization: {
      root: '/management/organizations/:organization_id',
      blockedPTODatesNew:
        '/management/organizations/:organization_id/blocked-pto-dates/new',
      blockedPTODatesEdit:
        '/management/organizations/:organization_id/blocked-pto-dates/:id',
    },
  },
  insights: {
    insightsRoot: '/insights',
    totalSales: '/insights/total-sales',
    historicalSales: '/insights/historical-sales',
    averageOrders: '/insights/average-orders',
    baselineComparisons: '/insights/reported-forecasted-sales',
    scheduledActualLabor: '/insights/scheduled-actual-labor',
    laborTargetBreakdowns: '/insights/labor-target-breakdowns',
    overtime: '/insights/overtime',
  },
  employeeSettings: '/settings/employees', //not sure these employee lines are
  employeeSettingsNew: '/settings/employees/new',
  employeeSettingsEdit: '/settings/employees/:id/edit',
  integrationSettings: '/settings/integrations',
}

export const getLocationNavigationPath = (locationId: string) =>
  `${routeMap.locations}/${locationId}`

export const defaultLoggedInPath = (user: CurrentUserInfoFragment) => {
  if (user.locations.length > 0) {
    return `${getLocationNavigationPath(user.locations[0].id)}${
      routeMap.location.tracking
    }`
  }
  return `${routeMap.onboarding}`
}

export const navigateToLogin = () => {
  document.location.href = routeMap.login
}

export const useLocationNavigation = () => {
  const locationId = useCurrentLocationId()

  return { locationPath: getLocationNavigationPath(locationId) }
}

const RedirectAuthenticatedUser = () => {
  const user = useLoggedInUserOrRedirect()

  return <Redirect to={{ pathname: defaultLoggedInPath(user) }} />
}

const LocationRoutes = () => {
  const { path } = useRouteMatch()

  const location = useTryCurrentLocation()

  if (!location) {
    return <RedirectAuthenticatedUser />
  }

  Settings.defaultZoneName = location.timeZone

  return (
    <>
      <Switch>
        <AuthenticatedRoute exact path={path + routeMap.location.tracking}>
          <Tracking />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={path + routeMap.location.plans}>
          <Plans />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={path + routeMap.location.items}>
          <Items />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={path + routeMap.location.plan}>
          <Plan />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={path + routeMap.location.reporting}>
          <Reporting />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={path + routeMap.location.reportingRoot}>
          <Reporting />
        </AuthenticatedRoute>
        {/* Redirect leggacy route. Remove after 3/1/2021 */}
        <AuthenticatedRoute path={path + '/planning'}>
          <Redirect to="/" />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={path + routeMap.location.management.requests}
        >
          <ManageRequests />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={path + routeMap.location.management.requestHistory}
        >
          <ManageRequests isManagementHistory />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={path + routeMap.location.management.employees}
        >
          <Employees />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={path + routeMap.location.management.messages}
        >
          <Messages />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={path + routeMap.location.management.integrationSettings}
        >
          <IntegrationSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={path + routeMap.location.management.employeeEdit}
        >
          <EditEmployee />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={path + routeMap.location.management.employeeNew}
        >
          <AddEmployee />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={path + routeMap.management.locations}>
          <Locations />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={path + routeMap.management.location}>
          <EditLocation />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={path + routeMap.management.organizations}
        >
          <Organizations />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={path + routeMap.management.organization.root}
        >
          <EditOrganization />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path={path + routeMap.management.organization.blockedPTODatesNew}
        >
          <AddEditTimeOffBlockedDate />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path={path + routeMap.management.organization.blockedPTODatesEdit}
        >
          <AddEditTimeOffBlockedDate />
        </AuthenticatedRoute>
      </Switch>
    </>
  )
}

const UnauthenticatedRoute = (props: RouteProps) => {
  const isLoggedIn = !!useLoggedInUser()

  if (isLoggedIn) {
    return <RedirectAuthenticatedUser />
  }

  return <Route {...props} />
}

const AuthenticatedRoute = (props: RouteProps) => {
  const me = useLoggedInUser()
  useEffect(() => {
    if (!me) {
      navigateToLogin()
    } else {
      let activeLocations = false

      if (me.locations.length === 0) {
        return
      }

      for (let i = 0; i < me.locations.length; i++) {
        if (me.locations[i].status !== 'disabled') {
          activeLocations = true
          break
        }
      }

      if (
        !activeLocations &&
        !document.location.href.includes(routeMap.onboarding) &&
        !document.location.href.includes('/management/integrations')
      ) {
        document.location.href = routeMap.onboarding
      }
    }
  }, [me])

  if (!me) {
    return <UnauthenticatedPage />
  }
  return <Route {...props} />
}

const usePageViews = () => {
  const location = useLocation()

  useEffect(() => {
    Analytics.page()
  }, [location.pathname])
}

const useIdentify = () => {
  const location = useLocation()
  const { update: updateIntercom } = useIntercom()

  const user = useLoggedInUser()

  useEffect(() => {
    if (!user) return
    const locationId = location.pathname.split('/')[2]
    const currentLocation = user?.locations.find(
      location => location.id === locationId
    )
    const locationUniversalId = currentLocation?.universalId
    const locationName = currentLocation?.name

    Analytics.identify(user.id, {
      id: user.id,
      name: user.name,
      email: user.email,
      observer: user.observer,
      roles: user.roles.map(({ name }) => name),
      locations: user.locations.map(location => ({
        name: location.name,
        universalId: location.universalId,
      })),
      organizationUniversalId: user?.organization?.universalId,
      organizationName: user?.organization?.name,
      locationUniversalId: locationUniversalId,
      locationName: locationName,
    })
  }, [location.pathname, user])

  useEffect(() => {
    if (!user) return

    updateIntercom({
      userId: user.id,
      name: user.name,
      email: user.email,
    })
  }, [updateIntercom, user])
}

const Routes = () => {
  usePageViews()
  useIdentify()

  return (
    <Switch>
      <UnauthenticatedRoute exact path={routeMap.signup}>
        <Signup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path={routeMap.login}>
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path={routeMap.requestPasswordReset}>
        <RequestPasswordReset />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path={routeMap.requestPasswordResetSuccess}>
        <RequestPasswordResetSuccess />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path={routeMap.resetPassword}>
        <ResetPassword />
      </UnauthenticatedRoute>
      <Route exact path={routeMap.covidDashboardWelcome}>
        <CovidDashboardWelcome />
      </Route>
      <Route exact path={routeMap.dashboardWelcome}>
        <CovidDashboardWelcome />
      </Route>
      <Route exact path={routeMap.covidDashboardWelcomeTechTable}>
        <CovidDashboardWelcome />
      </Route>
      <Route exact path={routeMap.covidDashboardWelcomeWestfield}>
        <CovidDashboardWelcome />
      </Route>
      <Route exact path={routeMap.covidDashboard}>
        <CovidDashboard />
      </Route>
      <Route exact path={routeMap.redirectToMobileApp}>
        <RedirectToMobileApp />
      </Route>
      <Redirect from="/covid" to={routeMap.covidDashboardWelcome} />
      <AuthenticatedRoute path={routeMap.onboarding}>
        <Onboarding />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={routeMap.location.root}>
        <LocationRoutes />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={routeMap.integrationSettings}>
        <IntegrationSettings />
      </AuthenticatedRoute>
      <ToucanInsightsProvider>
        <AuthenticatedRoute exact path={routeMap.insights.insightsRoot}>
          <ToucanInsights />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={routeMap.insights.totalSales}>
          <TotalSales />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={routeMap.insights.averageOrders}>
          <AverageOrders />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={routeMap.insights.baselineComparisons}>
          <BaselineComparisons />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={routeMap.insights.scheduledActualLabor}>
          <ScheduledActualLabor />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={routeMap.insights.laborTargetBreakdowns}
        >
          <LaborTargetBreakdowns />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={routeMap.insights.overtime}>
          <Overtime />
        </AuthenticatedRoute>
      </ToucanInsightsProvider>
    </Switch>
  )
}

export default () => (
  <Router>
    <Routes />
  </Router>
)
