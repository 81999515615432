import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useGetReservationsQuery } from 'config/graphqlTypesRaw'
import { DateTime } from 'luxon'

export const useGetReservationDetails = (
  startAt: DateTime,
  endAt: DateTime
) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  const reservations =
    useGetReservationsQuery({
      variables: {
        startOn: startAt.toISODate(),
        endOn: endAt.toISODate(),
        locationId,
      },
    }).data?.reservations || []

  return reservations
}
