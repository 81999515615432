import React from 'react'
import { useTranslation } from 'react-i18next'

import { useLocationNavigation, routeMap } from 'pages/Routes'
import {
  StyledBackSpan,
  StyledBackChevron,
  StyledBackLink,
} from 'pages/plan/header/styles'

export const BackButton = () => {
  const { t } = useTranslation()
  const { locationPath } = useLocationNavigation()
  const backPath = locationPath + routeMap.location.plans

  return (
    <StyledBackLink data-testid={t('planning.back')} to={backPath}>
      <StyledBackChevron title={t('planning.back')} />
      <StyledBackSpan>{t('planning.back')}</StyledBackSpan>
    </StyledBackLink>
  )
}
