import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface Props {
  pageNumber: number
  numberOfPages: number | undefined
  disabled?: boolean
  onChangePage: (offset: number) => void
  containerStyles?: CSSProperties
}

export const PdfPagesNavigator: FC<Props> = ({
  pageNumber,
  numberOfPages,
  disabled = false,
  onChangePage,
  containerStyles,
}) => {
  const { t } = useTranslation()

  function nextPage() {
    onChangePage(1)
  }

  function previousPage() {
    onChangePage(-1)
  }

  return (
    <Container style={containerStyles}>
      <ArrowLeft
        type="button"
        disabled={pageNumber <= 1 || disabled}
        onClick={previousPage}
      ></ArrowLeft>
      <HeaderLabel>
        {t('pdfPagesNavigator.page')} {pageNumber || numberOfPages || 1}{' '}
        {t('pdfPagesNavigator.of')} {numberOfPages || 1}
      </HeaderLabel>
      <ArrowRight
        type="button"
        onClick={nextPage}
        disabled={pageNumber >= (numberOfPages ?? 0) || disabled}
      ></ArrowRight>
    </Container>
  )
}

const arrowStyles = `
  background: none !important;
  border: none;
  padding: 0 !important;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
`

const disabledArrowStyles = `
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
`

const Container = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  flex-wrap: nowrap;
`

const ArrowLeft = styled.button<{ disabled: boolean }>`
  ${arrowStyles}

  &:hover {
    border-right: 16px solid #454545;
  }

  border-right: 16px solid #000;

  ${({ disabled }) => disabled && disabledArrowStyles}
`

const ArrowRight = styled.button<{ disabled: boolean }>`
  ${arrowStyles}

  &:hover {
    border-left: 16px solid #454545;
  }

  border-left: 16px solid #000;

  ${({ disabled }) => disabled && disabledArrowStyles}
`

const HeaderLabel = styled.span`
  font-size: 16px;
`
