import React, { ChangeEvent, FC, useState } from 'react'

import { ApolloError } from '@apollo/client'
import { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { Col } from 'react-bootstrap'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
// @ts-ignore
import { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import {
  EmployeeAvailabilityFragment,
  TimeOffRequestFragment,
  ShiftSwapRequestFragment,
  useApproveManagementRequestMutation,
} from 'config/graphqlTypes'
import {
  ApproveManagementRequestMutationInput,
  GetLocationManagementRequestsDocument,
} from 'config/graphqlTypesRaw'

import Button from 'components/Button'
import ConfirmationDialog from 'components/ConfirmationDialog'
import DataTable from 'components/DataTable/DataTable'
import { Check } from 'components/Form'
import { Loading } from 'components/Loading'
import { SmallBodyText } from 'components/Text'
import { NoRequestsMessage } from 'components/Management/styles'
import {
  StyledSubmitLabel,
  StyledShiftSwapDeclinedExplanation,
  TimeSlotsContainer,
} from 'components/Management/styles'
import { AvailabilityDescriptionCell } from 'components/Management/AvailabilityDescriptionCell'
import { TimeOffDescriptionCell } from 'components/Management/TimeOffDescriptionCell'
import { ShiftSwapDescriptionCell } from 'components/Management/ShiftSwapDescriptionCell'

import AuthenticatedPage from 'pages/AuthenticatedPage'
import { StatusDot } from 'pages/plans/styles'
import { ManagementHeader, SearchInputWrapper } from 'pages/management/styles'
import { useGetManagementRequests } from 'pages/management/useGetManagementRequests'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { simpleDateFormat } from 'utils/daysAndTimes'
import { ManagementWrapper } from '../ManagementWrapper'
import '../animation-styles.css'

const PAGE_SIZE_OPTIONS = [20, 50, 100].map(n => ({
  text: n.toString(),
  value: n,
}))

type ScheduleManagementRequest = (
  | TimeOffRequestFragment
  | EmployeeAvailabilityFragment
  | ShiftSwapRequestFragment
) & { uniqueId: string }

const SearchBar = SearchInputWrapper(Search.SearchBar)

const makeTypeNameLabelAndDetailCell = (
  scheduleRequest: ScheduleManagementRequest,
  t: TFunction
): { typeNameLabel: string; scheduleRequestDescriptionCell: JSX.Element } => {
  switch (scheduleRequest.__typename) {
    case 'EmployeeAvailability':
      return {
        typeNameLabel: t('management.requests.availability'),
        scheduleRequestDescriptionCell: (
          <AvailabilityDescriptionCell employeeAvailability={scheduleRequest} />
        ),
      }

    case 'TimeOffRequest':
      return {
        typeNameLabel: t('management.requests.timeOff'),
        scheduleRequestDescriptionCell: (
          <TimeOffDescriptionCell timeOffRequest={scheduleRequest} />
        ),
      }

    case 'ShiftSwapRequest':
      return {
        typeNameLabel: t('management.requests.shiftSwap'),
        scheduleRequestDescriptionCell: (
          <ShiftSwapDescriptionCell shiftSwapRequest={scheduleRequest} />
        ),
      }
  }

  throw new Error(
    `Unexpected management request type found: ${scheduleRequest.__typename}`
  )
}

const shouldShowRequest = (isManagementHistory?: boolean) => (
  request: ScheduleManagementRequest
) => {
  switch (request.state) {
    case 'approved':
    case 'declined':
    case 'expired':
    case 'cancelled':
    case 'swap_approved':
    case 'swap_declined':
    case 'swap_expired':
      return isManagementHistory

    case 'pending':
    case 'swap_pending':
      if (
        request.__typename === 'TimeOffRequest' &&
        request.startAt < DateTime.now()
      ) {
        return isManagementHistory
      }
      return !isManagementHistory

    default:
      return false
  }
}

interface Props {
  isManagementHistory?: boolean
}

export const ManageRequests: FC<Props> = ({ isManagementHistory }) => {
  const { t } = useTranslation()
  const locationId = useCurrentLocationId()

  const [errorSubmissionMessage, setErrorSubmissionMessage] = useState('')
  const [declined, setDeclined] = useState<Array<string>>([])
  const [managerNotes, setManagerNote] = useState<{ [key: string]: string }>({})
  const [neverShowThisAgainCheck, setNeverShowThisAgainCheck] = useState(false)
  const [
    selectedRequest,
    setSelectedRequest,
  ] = useState<ScheduleManagementRequest | null>(null)
  const [shouldFadeOut, setShouldFadeOut] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const maxLengthRejectionMessage = 200
  const localStorageNeverAskAgain = 'neverAskConfirmation'
  const neverAskConfirmation = () =>
    window.localStorage.getItem(localStorageNeverAskAgain)

  const requestInput = selectedRequest
    ? {
        requestId: selectedRequest.id,
        requestType: selectedRequest.__typename!,
        approved: declined.indexOf(selectedRequest.uniqueId) === -1,
        managerNote: managerNotes[selectedRequest.uniqueId] || '',
      }
    : ({} as ApproveManagementRequestMutationInput)

  const [approveRequest] = useApproveManagementRequestMutation({
    variables: {
      input: requestInput,
    },
    refetchQueries: [
      {
        query: GetLocationManagementRequestsDocument,
        variables: { locationId },
      },
    ],
    fetchPolicy: 'no-cache',
  })

  const allScheduleRequests = useGetManagementRequests()
  const scheduleRequests =
    allScheduleRequests &&
    allScheduleRequests.filter(shouldShowRequest(isManagementHistory))

  const typeNameLabelCell = (label: String, date: string, state: string) => (
    <>
      {label}
      <br />
      {state === 'cancelled' ? (
        <SmallBodyText>
          {' '}
          <i>
            Employee cancelled time off request{' '}
            {simpleDateFormat(DateTime.fromISO(date))}
          </i>
        </SmallBodyText>
      ) : (
        <SmallBodyText>
          {' '}
          <i>Submitted {simpleDateFormat(DateTime.fromISO(date))}</i>
        </SmallBodyText>
      )}
    </>
  )

  const showToast = () => {
    toast.success(t('management.toastMessages.submitRequest'), {
      position: toast.POSITION.BOTTOM_LEFT,
      style: { whiteSpace: 'nowrap', width: 'fit-content' },
    })
  }
  const onHide = () => {
    setShowConfirmation(false)
  }
  const onGmConflict = async () => {
    window.location.reload()
  }
  const onConfirm = async () => {
    setShouldFadeOut(true)
    setShowConfirmation(false)

    // // Let the fade run and finish before calling the mutation. Otherwise, the mutation updates
    // // the data stored in apollo context and causes the parent component to totally
    // // re-render subverting the animation.

    setTimeout(() => {
      approveRequest()
        .then(showToast)
        .catch((error: ApolloError) => {
          const isGmConflict =
            (error.graphQLErrors as any).at(0)?.extensions?.code ===
            'EMPLOYEE_REQUEST_CONFLICT'

          //set submission error message
          if (isGmConflict) {
            setErrorSubmissionMessage(
              t('management.requests.gm_conflict_error_description_main')
            )
          } else {
            setErrorSubmissionMessage(
              t('management.requests.unknown_error_description')
            )
          }
        })

      if (neverShowThisAgainCheck) {
        window.localStorage.setItem(localStorageNeverAskAgain, 'true')
      }
    }, 500)
  }
  const submitButton = (request: ScheduleManagementRequest) => (
    <Button
      data-testid="submit-response"
      onClick={() => {
        setSelectedRequest(request)
        toast.dismiss()
        if (neverAskConfirmation()) {
          onConfirm()
        } else {
          setShowConfirmation(true)
        }
      }}
      disabled={false}
      block
      size="md"
      type="submit"
      variant="primary"
    >
      <StyledSubmitLabel>
        {t('management.requests.submitLabel')}
      </StyledSubmitLabel>
    </Button>
  )

  const approveCheckbox = (requestId: string, declined: Array<string>) => {
    return (
      <Check
        id="approve.submit"
        style={{
          fontSize: '1rem',
          fontWeight: '800',
          marginLeft: 10,
        }}
        styleContainer={{
          paddingTop: '0.75rem',
          paddingBottom: '1rem',
        }}
        label={t('management.requests.approve')}
        inputProps={{
          checked: declined.indexOf(requestId) === -1,
          onChange: () => {
            const isDeclined = declined.indexOf(requestId) > -1
            if (!isDeclined) setDeclined([...declined, requestId])
            else setDeclined(declined.filter(id => id !== requestId))
          },
        }}
      />
    )
  }

  const historyColumns = [
    {
      dataField: 'state',
      text: t('management.requests.status'),
      sort: true,
      formatter: (state: string) => {
        const statusDotColor = ['approved', 'swap_approved'].includes(state)
          ? 'green'
          : 'red'
        return (
          <>
            <span>{t(`management.requests.${state}`)}</span>
            &nbsp;&nbsp;
            <StatusDot
              data-testid={`${statusDotColor}-status-dot`}
              color={statusDotColor}
            />
          </>
        )
      },
    },
    {
      dataField: 'managerNote',
      text: t('management.requests.managerNotes'),
      sort: true,
      formatter: (managerNote: string) => (
        <>{managerNote ? <span>{managerNote}</span> : <span>&#8212;</span>}</>
      ),
    },
  ]

  const requestColumns = [
    {
      dataField: 'managerNote',
      text: t('management.requests.actions'),
      sort: false,
      formatExtraData: { declined, managerNotes },
      formatter: (
        cell: String,
        request: ScheduleManagementRequest,
        index: number,
        extraData: any
      ) => {
        const isDeclined = extraData.declined.indexOf(request.uniqueId) > -1
        const managerNote =
          extraData.managerNotes[request.uniqueId] || request.managerNote
        const isShiftSwapRequest = request.__typename === 'ShiftSwapRequest'
        const swapRequestMessageComponent = isDeclined && isShiftSwapRequest && (
          <StyledShiftSwapDeclinedExplanation>
            {t(
              'management.requests.shift_swap_notification_decline_explanation',
              {
                employee:
                  (request as ShiftSwapRequestFragment)?.newEmployee?.name ||
                  '',
              }
            )}
          </StyledShiftSwapDeclinedExplanation>
        )
        const managerNoteComponent = managerNote &&
          managerNote.length >= maxLengthRejectionMessage - 50 && (
            <label>
              {t('management.requests.charactersLeft')}{' '}
              {maxLengthRejectionMessage - managerNote.length}
            </label>
          )
        const rejectionMessage = isDeclined && (
          <p>
            <textarea
              className="form-control"
              rows={4}
              id="submitMessageId"
              data-testid="rejection-message"
              placeholder={t('management.requests.rejectionPlaceholder')}
              defaultValue={managerNote as string}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                setManagerNote({
                  ...managerNotes,
                  [request.uniqueId]: e.target.value,
                })
              }}
              maxLength={maxLengthRejectionMessage}
            />
          </p>
        )
        return (
          <>
            {swapRequestMessageComponent}
            <br />
            {managerNoteComponent}
            <br />
            {rejectionMessage}
            <br />
            {submitButton(request)}
          </>
        )
      },
    },
  ]

  const columns = [
    {
      dataField: 'employee.name',
      text: t('management.requests.employee'),
      sort: true,
    },
    {
      dataField: '__typename',
      text: t('management.requests.type'),
      sort: true,
      formatter: (cell: String, request: ScheduleManagementRequest) => {
        const { typeNameLabel } = makeTypeNameLabelAndDetailCell(request, t)
        return typeNameLabelCell(
          typeNameLabel,
          request.updatedAt,
          request.state
        )
      },
    },
    {
      dataField: 'employeeNote',
      text: t('management.requests.employeeNotes'),
      sort: true,
      formatter: (employeeNote: String) =>
        employeeNote ? <>{employeeNote}</> : <>&#8212;</>,
    },
    {
      dataField: 'id',
      text: t('management.requests.request'),
      sort: true,
      headerStyle: () => {
        return { minWidth: 250, maxWidth: 250, width: '20%' }
      },
      formatExtraData: declined,
      formatter: (
        cell: String,
        request: ScheduleManagementRequest,
        index: number,
        declined: Array<string>
      ) => {
        const {
          scheduleRequestDescriptionCell,
        } = makeTypeNameLabelAndDetailCell(request, t)
        return (
          <>
            {!isManagementHistory &&
              approveCheckbox(request.uniqueId, declined)}
            <TimeSlotsContainer
              isManagementHistory={isManagementHistory}
              data-testid={`management-request-${request.__typename}`}
            >
              {scheduleRequestDescriptionCell}
            </TimeSlotsContainer>
          </>
        )
      },
    },
    ...(isManagementHistory ? historyColumns : requestColumns),
  ]

  const rowStyle = (row: ScheduleManagementRequest) => {
    const fadeOut = row.id === selectedRequest?.id && shouldFadeOut
    return {
      opacity: fadeOut ? 0 : undefined,
      transition: fadeOut ? 'opacity .5s linear' : undefined,
      alignItems: 'center',
    }
  }

  const title = isManagementHistory
    ? t('management.requests.historyTitle')
    : t('management.requests.requestsTitle')

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeader>{title}</ManagementHeader>
          {!scheduleRequests ? (
            <Loading />
          ) : (
            <>
              {scheduleRequests.length > 0 && (
                <ToolkitProvider
                  keyField="uniqueId"
                  data={scheduleRequests}
                  columns={columns}
                  search
                >
                  {(props: ToolkitContextType) => (
                    <>
                      <Col xs={12} md={{ span: 3, offset: 9 }}>
                        <SearchBar {...props.searchProps} delay={500} />
                      </Col>
                      <DataTable
                        {...props.baseProps}
                        keyField="uniqueId"
                        striped
                        hover
                        minHeight={'600px'}
                        rowStyle={rowStyle}
                        filter={filterFactory()}
                        pagination={paginationFactory({
                          sizePerPageList: PAGE_SIZE_OPTIONS,
                        })}
                      />
                    </>
                  )}
                </ToolkitProvider>
              )}
              {scheduleRequests.length === 0 && !isManagementHistory && (
                <CSSTransition timeout={900} classNames="item">
                  <NoRequestsMessage>
                    {t('management.requests.noRequests')}
                  </NoRequestsMessage>
                </CSSTransition>
              )}
              <ConfirmationDialog
                show={showConfirmation}
                title={t('management.requests.confirmDialogTitle')}
                description={t('management.requests.confirmDialogDescription')}
                onConfirm={onConfirm}
                onHide={onHide}
                confirmLabel={t('management.requests.submitLabel')}
                children={
                  <Check
                    id={'neverShowAgain'}
                    label={t('management.requests.confirmDialogDontAskAgain')}
                    inputProps={{
                      checked: neverShowThisAgainCheck,
                      onChange: () => {
                        setNeverShowThisAgainCheck(!neverShowThisAgainCheck)
                      },
                    }}
                  />
                }
              />
              <ConfirmationDialog
                show={errorSubmissionMessage.length !== 0}
                title={t('management.requests.request_not_submitted')}
                description={errorSubmissionMessage}
                onConfirm={onGmConflict}
                onHide={onGmConflict}
                confirmLabel={'Close'}
                hideCancel={true}
                children={
                  errorSubmissionMessage.length ? (
                    <div>
                      {' '}
                      {t(
                        'management.requests.gm_conflict_error_description_sub'
                      )}
                      <a href={window.location.href + '/history'}>
                        History Page
                      </a>
                    </div>
                  ) : (
                    <div></div>
                  )
                }
              />
            </>
          )}
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}
