import React from 'react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import { NonBugsnagErrorBoundary } from 'components/NonBugsnagErrorBoundary'

const bugsnagClient = (() => {
  if (process.env.REACT_APP_BUGSNAG_KEY) {
    const client = bugsnag({
      apiKey: process.env.REACT_APP_BUGSNAG_KEY,
      releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
    })
    client.use(bugsnagReact, React)
    console.log(
      `Bugsnag configured for stage ${process.env.REACT_APP_BUGSNAG_RELEASE_STAGE}`
    )
    return client
  } else {
    console.log('Bugsnag disabled')
    return null
  }
})()

export const ErrorBoundary =
  bugsnagClient?.getPlugin('react') ?? NonBugsnagErrorBoundary

export const notify = (msg: string) => notifyError(Error(msg))

export const notifyError = (error: Error) => bugsnagClient?.notify(error)
