import { DateTime } from 'luxon'
import { min, max } from 'lodash'

import { OperatingHoursForDateFragment } from 'config/graphqlTypes'

export const operatingHoursForDay = (
  operatingHours: OperatingHoursForDateFragment[]
): [DateTime?, DateTime?] => {
  const openAt = min(operatingHours.map(hours => hours.openAt))
  const closeAt = max(operatingHours.map(hours => hours.closeAt))

  return [openAt, closeAt]
}
