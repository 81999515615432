import React from 'react'
import { map } from 'lodash'

import {
  TitleCell,
  TinyLine,
  TitleText,
  DailyCell,
  TotalCell,
} from 'pages/tracking/breakdown/styles'
import sumOrNull from 'utils/sumOrNull'
import { ByDay } from 'pages/tracking/breakdown/byDay'

const sumTotals = (totals: ByDay) => sumOrNull(Object.values(totals))

interface Props {
  actual?: boolean
  title: string
  titleColor: string
  totalsByDay: ByDay
  formatter: (num: number | null, flag?: boolean) => string
}

export const DailyRow = ({
  actual,
  formatter,
  title,
  titleColor,
  totalsByDay,
}: Props) => (
  <tr>
    <TitleCell category actual={actual} color={titleColor}>
      <TinyLine color={titleColor} />
      <TitleText>{title}</TitleText>
    </TitleCell>
    {map(totalsByDay, (total, day) => (
      <DailyCell key={day} actual={actual} title={formatter(total, true)}>
        {formatter(total)}
      </DailyCell>
    ))}
    <TotalCell
      actual={actual}
      total
      title={formatter(sumTotals(totalsByDay), true)}
    >
      {formatter(sumTotals(totalsByDay))}
    </TotalCell>
  </tr>
)
