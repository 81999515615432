import React from 'react'
import { PointTooltipProps, CustomLayerProps, Point } from '@nivo/line'

import { formattedDollars } from 'utils/currency'
import {
  getActuals,
  getSerieConfig,
  isFutureActuals,
  isActuals,
} from 'components/Chart/chartDataConfig'

const STROBE_NAME = 'def-strobe'
const STROBE_DURATION = '1.2'
const STROBE_SIZE = 30

export const Points = (
  highlightMostRecentActual: boolean,
  setPointData: (TooltipArgs: PointTooltipProps | null) => any,
  overridePointColor?: (point: Point) => string | undefined
) => ({ points, series }: CustomLayerProps) => {
  const actuals = getActuals(series)
  const mostRecentActualIndex =
    actuals && actuals.data.length !== 0 ? actuals.data.length - 1 : -1

  return (
    <g>
      {points.map(point => {
        const { id, serieId, x, y, data } = point

        const indexString = id.split('.')[1]!

        // If there are actuals, then the first future series point is on top of
        // the last real time actual point. Only display the real time point.
        if (
          isFutureActuals(serieId) &&
          indexString === '0' &&
          actuals &&
          actuals.data.length > 0
        ) {
          return null
        }

        const pointIsMostRecentActual =
          mostRecentActualIndex !== -1 &&
          indexString === mostRecentActualIndex.toString()

        const { testIdSlug, stroke, color } = getSerieConfig(serieId)
        const fill = (overridePointColor && overridePointColor(point)) ?? color

        const testId = (() => {
          // @ts-ignore
          const time = data.displayTime as string
          const dollar = formattedDollars(data.y as number)

          return `dot-${testIdSlug}-${time}-${dollar}`
        })()

        return (
          <g key={id}>
            {isFutureActuals(serieId) && (
              <circle
                cx={x}
                cy={y}
                r={6}
                strokeWidth={2}
                style={{ fill: stroke, stroke: fill }}
              />
            )}
            {!!(
              isActuals(serieId) &&
              highlightMostRecentActual &&
              pointIsMostRecentActual
            ) && (
              <>
                <defs>
                  {/* Strobe background circle */}
                  <circle
                    id={STROBE_NAME}
                    cx={0}
                    cy={0}
                    r={STROBE_SIZE}
                    opacity={0}
                    style={{ fill, stroke }}
                  >
                    {/* Strobe opacity animation */}
                    <animate
                      attributeName="opacity"
                      from={1}
                      to={0}
                      dur={`${STROBE_DURATION}s`}
                      repeatCount="indefinite"
                    />
                  </circle>
                </defs>
                {/* Strobe centering translation */}
                <use
                  xlinkHref={`#${STROBE_NAME}`}
                  transform={`translate(${x} ${y})`}
                >
                  {/* Strobe scale animation */}
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    additive="sum"
                    from={0}
                    to={1}
                    dur={`${STROBE_DURATION}s`}
                    repeatCount="indefinite"
                  />
                </use>
                {/* Strobe foreground circle */}
                <circle
                  cx={x}
                  cy={y}
                  r={10}
                  strokeWidth={2}
                  style={{ fill, stroke }}
                />
              </>
            )}
            <circle
              data-testid={testId}
              onMouseEnter={() => setPointData({ point: point })}
              onMouseLeave={() => setPointData(null)}
              cx={x}
              cy={y}
              r={isFutureActuals(serieId) ? 4 : 6}
              strokeWidth={2}
              style={{ fill, stroke }}
            />
          </g>
        )
      })}
    </g>
  )
}
