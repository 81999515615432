import { TFunction } from 'i18next'
import numeral from 'numeral'

import { EM_DASH } from 'utils/formattedNumberWithFallback'

export const getFormattedSingleOptionalDecimalPlace = (num: number) =>
  numeral(num).format('0.[0]')

const getFormattedHours = ({
  hours,
  format,
  t,
}: {
  hours: number | null
  format?: string
  t: TFunction
}) => {
  if (hours === null) {
    return EM_DASH
  }
  const formattedHours = format
    ? numeral(hours).format(format)
    : getFormattedSingleOptionalDecimalPlace(hours)
  return t('misc.hourAbbreviationWithCountFormatted', {
    hours: formattedHours,
    count: Number(formattedHours),
  })
}

export default getFormattedHours
