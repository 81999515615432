import styled from 'styled-components'
import Popover from 'react-bootstrap/Popover'
import Row from 'react-bootstrap/Row'

import {
  regularBodyBoldTextStyle,
  smallBodyBoldTextStyle,
  tableLabelBoldTextStyle,
} from 'config/styles'
import { StyledPlanningTableHeadCell } from 'pages/plan/rows/styles'
import { TextButton } from 'components/Button'
import { ExpandCollapseIcon } from 'components/ExpandCollapseIcon'
import { WeekNavigator } from 'components/WeekNavigator'
import { InfoTooltip } from 'components/InfoTooltip'

export const REPORTING_LEFT_PADDING = 40

export const StyledLocationNameCell = styled.td<{ isFutureWeek?: boolean }>(
  ({ isFutureWeek }) => ({
    paddingLeft: REPORTING_LEFT_PADDING,
    paddingTop: '0.75rem',
    gridRow: `span ${isFutureWeek ? 3 : 5}`,
  })
)

export const StyledLocationName = styled.div({
  ...regularBodyBoldTextStyle,
})

export const StyledSubmittedBadge = styled.div<{ isSubmitted: boolean }>(
  ({ isSubmitted }) => ({
    height: '1.375rem',
    borderRadius: '0.75rem',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    backgroundColor: isSubmitted ? 'var(--blue)' : 'var(--red)',
    color: 'var(--white)',
    ...smallBodyBoldTextStyle,
    marginTop: '0.5rem',
  })
)

export const StyledTooltipIconContainer = styled.div({
  position: 'absolute',
  top: 23,
  right: 3,
  height: 24,
  width: 32,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export const StyledTooltipRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginTop: '0.4rem',
  marginBottom: '0.4rem',
  minWidth: '8.35rem',
  '&.gray': {
    color: 'var(--gray4)',
  },
  '&.blue': {
    color: 'var(--blue)',
  },
})

export const StyledTooltipAmount = styled.span({
  marginLeft: '0.7rem',
})

export const StyledTooltipDivider = styled.div({
  width: 13,
  height: 2,
  backgroundColor: 'var(--gray5)',
  marginLeft: 3,
})

export const StyledTooltipContent = styled(Popover.Content)({
  ...smallBodyBoldTextStyle,
  fontWeight: 'bold',
  color: 'var(--gray1)',
  padding: '0.15rem 0.75rem 0.375rem 0.6rem',
})

export const StyledTooltipTitle = styled(Popover.Title)({
  ...tableLabelBoldTextStyle,
  color: 'var(--gray2)',
  padding: '0.25rem 0.58rem 0.13rem',
})

export const StyledForecastAndDiffContainer = styled.div<{ diff?: number }>(
  ({ diff }) => ({
    color: diff && diff > 0 ? 'var(--blue)' : 'var(--gray1)',
  })
)

export const StyledHeaderRow = styled(Row)({
  alignItems: 'center',
  paddingLeft: REPORTING_LEFT_PADDING,
  paddingRight: 40,
  paddingTop: 20,
})

export const StyledTable = styled.table({
  display: 'grid',
  gridTemplateColumns:
    'minmax(12rem, 1fr) minmax(4em, 0fr) repeat(8, minmax(7.5rem, 1fr))',
})

export const StyledThead = styled.thead({
  display: 'contents',
})

export const StyledTbody = styled.tbody({
  display: 'contents',
})

export const StyledTableRow = styled.tr({
  display: 'contents',
})

export const StyledTableHeadCell = styled(StyledPlanningTableHeadCell)({
  fontSize: '0.75rem',
  height: '1.875rem',
  zIndex: 1,
})

export const StyledTableHeadTotalsCell = styled(StyledTableHeadCell)({
  color: 'var(--gray1)',
})

export const StyledMetrogroupNameCell = styled.td({
  gridColumn: 'span 10',
  paddingLeft: `calc(${REPORTING_LEFT_PADDING}px - 33px)`,
})

export const StyledMetrogroupNameButton = styled(TextButton)({
  fontSize: '1.375rem',
  lineHeight: '1.5rem',
  fontWeight: 900,
  color: 'var(--gray2)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '&, &:hover, &:focus': {
    textDecoration: 'none',
  },
})

export const StyledExpandCollapseIcon = styled(ExpandCollapseIcon)({
  marginRight: 10,
})

export const StyledVerticallyScrollableContainer = styled.div({
  overflowY: 'auto',
  flex: 1,
})

export const StyledRow = styled(Row)({
  flex: 1,
  flexDirection: 'column',
  overflowY: 'hidden',
})

export const StyledBottomSpacingCell = styled.td({
  height: '2rem',
})

export const StyledWeekNavigator = styled(WeekNavigator)({
  marginBottom: 20,
})

export const StyledTooltipIcon = styled.img({
  position: 'absolute',
  top: 11,
  right: 12,
})

export const StyledInfoTooltip = styled(InfoTooltip)({
  maxWidth: '8rem',
})

export const StyledDataLabel = styled.div({
  fontSize: '0.625rem',
  lineHeight: '0.75rem',
  fontWeight: 800,
  color: 'var(--gray1)',
  height: '0.875rem',
  whiteSpace: 'nowrap',
})

export const StyledBlueText = styled.span({
  color: 'var(--blue)',
})

export const StyledDash = styled.span({
  color: 'var(--gray4)',
})

export const StyledReportingTableRow = styled.tr({
  display: 'contents',
})

export const StyledReportingCell = styled.td({
  '&.bottom-border': {
    borderBottom: '1px solid var(--gray5)',
  },
  '&.last-row': {
    marginBottom: 12,
  },
})

export const StyledReportingSubheaderCell = styled(StyledReportingCell)({
  ...tableLabelBoldTextStyle,
  paddingTop: '0.875rem',
  paddingLeft: '0.1875rem',
})

export const StyledReportingDataCell = styled(StyledReportingCell)({
  height: '4.375rem',
  paddingLeft: '1.0625rem',
  paddingTop: '0.8125rem',
  fontSize: '1.125rem',
  lineHeight: '1.25rem',
  position: 'relative',
  '&.stripe': {
    backgroundColor: 'var(--gray7)',
  },
  '&.bold': {
    fontWeight: 800,
  },
})

export const StyledReportingDifference = styled.div({
  fontSize: '0.625rem',
  lineHeight: '0.75rem',
  fontWeight: 800,
  marginTop: '-0.0625rem',
  '&.gray': {
    color: 'var(--gray4)',
  },
})

export const StyledDividerCell = styled(StyledReportingCell)({
  height: 11,
  borderTop: '1px solid var(--gray5)',
})

export const StyledTopBorderWithLeftPadding = styled.div({
  borderTop: '1px solid var(--gray5)',
  position: 'relative',
  top: -1,
  marginLeft: `calc(${REPORTING_LEFT_PADDING}px - 2px)`,
})

export const StyledDataValue = styled.span<{ isEmpty?: boolean }>(
  ({ isEmpty }) =>
    isEmpty
      ? {
          color: 'var(--gray4)',
        }
      : {}
)

export const LaborHoursDataValue = styled.span({
  cursor: 'pointer',
  textDecoration: 'underline dotted',
  textUnderlinePosition: 'under',
})
