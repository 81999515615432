import { ShiftFragment } from 'config/graphqlTypes'
import { useState } from 'react'

export const useShiftModal = () => {
  const [showShiftModal, setShowShiftModal] = useState(false)

  const [selectedShift, setSelectedShift] = useState<ShiftFragment | null>(null)
  const onClickShift = (shift: ShiftFragment) => {
    setSelectedShift(shift)
    setShowShiftModal(true)
  }

  const onClickAddShift = () => {
    setSelectedShift(null)
    setShowShiftModal(true)
  }

  const onHideShiftModal = () => {
    setShowShiftModal(false)
    setSelectedShift(null)
  }

  return {
    showShiftModal,
    selectedShift,
    onClickShift,
    onClickAddShift,
    onHideShiftModal,
  }
}
