import React, { FC } from 'react'
import filterFactory from 'react-bootstrap-table2-filter'
import DataTable from 'components/DataTable/DataTable'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { MassNotificationsFragment } from 'config/graphqlTypes'

const PAGE_SIZE_OPTIONS = [20, 50, 100].map(n => ({
  text: n.toString(),
  value: n,
}))

interface Props {
  messages: MassNotificationsFragment[]
  columns: ColumnDescription[]
}

export const MessagesDataTable: FC<Props> = ({
  messages,
  columns,
  ...props
}) => {
  const isMessagesPresent = messages.length !== 0
  return (
    <DataTable
      {...props}
      keyField="id"
      striped
      hover
      minHeight={isMessagesPresent ? '600px' : '0px'}
      data={messages}
      columns={columns}
      filter={filterFactory()}
      rowStyle={{ backgroundColor: '#FFF' }}
      pagination={
        isMessagesPresent
          ? paginationFactory({
              sizePerPageList: PAGE_SIZE_OPTIONS,
            })
          : undefined
      }
    />
  )
}
