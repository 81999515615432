import { DateTime } from 'luxon'
import { uniq } from 'lodash/fp'

import { IntervalOrderSumFragment } from 'config/graphqlTypes'
import { useGetIntervalOrderSums } from 'queries/useGetIntervalOrderSums'

export const getReportedSalesForSum = ({
  reportedSales,
  futureReportedSales,
}: IntervalOrderSumFragment) =>
  Number(reportedSales) + Number(futureReportedSales)

export const useGetFutureOrderSums = (
  startDate: DateTime,
  endDate: DateTime
) => {
  const orderSums = useGetIntervalOrderSums(startDate, endDate, 24 * 60, {
    fetchPolicy: 'no-cache',
  })

  const nonZeroOrderSums = orderSums.filter(
    os => getReportedSalesForSum(os) > 0
  )
  const categories = uniq(nonZeroOrderSums.map(os => os.category))

  return { orderSums: nonZeroOrderSums, categories }
}
