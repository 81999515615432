import SelectDropdown, { SelectDropdownOption } from 'components/SelectDropdown'
import React from 'react'
import { MultiValue } from 'react-select'

interface Props {
  options: SelectDropdownOption[]
  selectAllLabel: string
  onChange: (options: MultiValue<SelectDropdownOption>) => void
  value: SelectDropdownOption[]
}

const SelectControl = ({ options, onChange, value, selectAllLabel }: Props) => {
  return (
    <SelectDropdown
      isMulti
      allowSelectAll
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      options={options}
      onChange={onChange}
      value={value}
      controlStyles={controlStyles}
      menuStyles={menuStyles}
      optionStyles={optionStyles}
      selectAllOptionTagLabel={selectAllLabel}
      selectAllOptionLabel={selectAllLabel}
    />
  )
}

const FONT_SIZE = 14

const controlStyles = {
  borderRadius: 25,
  fontSize: FONT_SIZE,
  border: '1px solid var(--gray5)',
  borderColor: 'var(--gray5)',
  paddingLeft: 12,
  color: 'var(--gray3)',
  boxShadow: 'none',
  '&:hover': {
    border: '1px solid var(--gray5)',
  },
}

const menuStyles = {
  fontSize: FONT_SIZE,
}

const optionStyles = {
  fontSize: FONT_SIZE,
}

export default SelectControl
