import React from 'react'
import styled from 'styled-components'

import { AvatarDiv } from 'components/Avatar'

const StyledDiv = styled(AvatarDiv)<{
  size: number
  styles: string | undefined
}>`
  background-color: var(--bittersweet);
  color: var(--white);
  ${({ styles }) => styles}
`

interface Props {
  size?: number
  fontSize?: string
  styles?: string
}

export const ViolationIcon = ({ size = 30, fontSize, styles }: Props) => (
  <StyledDiv size={size} fontSize={fontSize} styles={styles}>
    !
  </StyledDiv>
)
