import React, { FC, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { map } from 'lodash'
import { mapValues, capitalize } from 'lodash'
import sumOrNull from 'utils/sumOrNull'
import { ByDay } from 'pages/tracking/breakdown/byDay'
import UpChevron from 'assets/images/up-chevron.svg'
import { asDollars } from 'utils/currency'
import {
  CategoryValues,
  CategorySalesTitle,
  CollapseIcon,
  StyledTr,
  TinyLine,
  TitleText,
  TitleCell,
} from 'pages/tracking/breakdown/styles'
import {
  IntervalPredictionSumFragment,
  IntervalOrderSumFragment,
} from 'config/graphqlTypes'

interface Props {
  title: string
  totalsByDay: ByDay
  predictionTotals?: ByDay
  actualTotals?: ByDay
  categories: { [key: string]: string[] }
  categoryNames: string[]
  salesType: string
  color: string
  backgroundColor: string
  values: {
    [key: string]: IntervalPredictionSumFragment[] | IntervalOrderSumFragment[]
  }
}

type IntervalsUnion = IntervalPredictionSumFragment | IntervalOrderSumFragment

interface AccordionButtonProps {
  title: string
  onClick: () => void
  isExpanded: boolean
  color: string
}

interface CategoryRowProps {
  totalsByDay: ByDay
  category: string
  backgroundColor: string
}

const getTotal = (totals: ByDay) =>
  Object.keys(totals ?? {}).reduce((prev, curr) => {
    return prev + (totals?.[curr] ?? 0)
  }, 0)

const AccordionButton = ({
  title,
  onClick,
  isExpanded,
  color,
}: AccordionButtonProps) => {
  return (
    <TitleCell onClick={onClick} color={color}>
      <TinyLine color={color} />
      <TitleText>{title}</TitleText>
      <CollapseIcon src={UpChevron} alt="" isExpanded={isExpanded} />
    </TitleCell>
  )
}

const CategoryRow = ({
  category,
  backgroundColor,
  totalsByDay,
}: CategoryRowProps) => {
  return (
    <StyledTr backgroundColor={backgroundColor}>
      <CategorySalesTitle>
        {capitalize(category.replace('_', '-'))}
      </CategorySalesTitle>
      {map(totalsByDay, (value, key) => (
        <CategoryValues key={`${key}-${value}`}>
          {asDollars(value)}
        </CategoryValues>
      ))}
      <CategoryValues>{asDollars(getTotal(totalsByDay))}</CategoryValues>
    </StyledTr>
  )
}

const CategoryAccordion: FC<Props> = ({
  totalsByDay,
  title,
  categories,
  categoryNames,
  values,
  salesType,
  color,
  backgroundColor,
}) => {
  const [isToggle, setToggle] = useState(false)

  const onToggle = () => setToggle(!isToggle)

  const salesTypeName = 'reportedSales'

  const getValuesByCategory = (categoryName: string) =>
    mapValues(values, (dayPredictionSums: IntervalsUnion[]) =>
      sumOrNull(
        dayPredictionSums
          .filter(({ category }) => category === categoryName)
          .map(p => p?.[salesTypeName])
      )
    )

  return (
    <>
      <StyledTr backgroundColor={backgroundColor} onClick={onToggle}>
        <AccordionButton
          title={title}
          onClick={onToggle}
          isExpanded={isToggle}
          color={color}
        />
        {map(totalsByDay, (value, key) => (
          <CategoryValues key={`${key}-${value}`} color="var(--gray2)">
            {asDollars(value)}
          </CategoryValues>
        ))}
        <CategoryValues color="var(--gray2)">
          {asDollars(getTotal(totalsByDay))}
        </CategoryValues>
      </StyledTr>
      <Collapse in={isToggle}>
        <>
          {isToggle ? (
            categoryNames.map(categoryName => (
              <CategoryRow
                key={`${categories[categoryName]}-${title}`}
                backgroundColor={backgroundColor}
                totalsByDay={getValuesByCategory(categories[categoryName][0])}
                category={categoryName}
              />
            ))
          ) : (
            <tr style={{ display: isToggle ? 'contents' : 'none' }}></tr>
          )}
        </>
      </Collapse>
    </>
  )
}

export default CategoryAccordion
