import ConfirmationDialog from 'components/ConfirmationDialog'
import { capitalize } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  show: boolean
  showSpinner?: boolean
  onHide: () => void
  onConfirmDelete: () => Promise<void>
}

export const ConfirmDeleteModal: FC<Props> = ({
  show,
  onHide,
  showSpinner = false,
  onConfirmDelete,
}) => {
  const { t } = useTranslation()

  const deleteModalTitle = t('schedule.confirmDeleteShiftDialog.title', {
    type: capitalize('shift'),
  })
  const deleteModalDescription = t(
    'schedule.confirmDeleteShiftDialog.description',
    {
      type: 'shift',
    }
  )
  const deleteModalConfirmLabel = t(
    'schedule.confirmDeleteShiftDialog.confirmLabel',
    {
      type: capitalize('shift'),
    }
  )

  return (
    <ConfirmationDialog
      show={show}
      onHide={onHide}
      title={deleteModalTitle}
      description={deleteModalDescription}
      confirmLabel={deleteModalConfirmLabel}
      onConfirm={onConfirmDelete}
      showSpinner={showSpinner}
      waitOnPromise={false}
    />
  )
}
