import { OperationVariables, QueryHookOptions } from '@apollo/client'
import { Interval } from 'luxon'

import { useGetOperatingHoursForDate } from 'queries/useGetOperatingHours'
import { operatingHoursForDay } from 'utils/operatingHoursForDay'

export const defaultOpenHour = 9
export const defaultCloseHour = 20

export const useGetOperatingHoursOrDefaults = (
  day: Interval,
  queryOptions?: QueryHookOptions<any, OperationVariables>
) => {
  const startOn = day.start
  const operatingHours = useGetOperatingHoursForDate(
    {
      startOn,
      endOn: startOn,
    },
    queryOptions
  )

  const [openAt, closeAt] = operatingHoursForDay(operatingHours)

  // Display a sensible default window for days without operating hours
  const openOrDefault = openAt || startOn.plus({ hours: defaultOpenHour })
  const closeOrDefault = closeAt || startOn.plus({ hours: defaultCloseHour })

  return { openAt, closeAt, openOrDefault, closeOrDefault }
}
