import React from 'react'
import { FC } from 'react'
import { sum } from 'lodash'
import cx from 'classnames'

import {
  LocationForReportingFragment,
  PlanForReportingFragment,
} from 'config/graphqlTypes'
import {
  StyledReportingTableRow,
  StyledReportingSubheaderCell,
} from 'pages/reporting/styles'
import { ReportingDataCell } from 'pages/reporting/ReportingDataCell'
import { asDollarsOrEmDash } from 'utils/currency'
import { ReportingDataValue } from 'pages/reporting/ReportingDataValue'
import { getDailySummary } from 'utils/getDailySummary'
import { Interval } from 'luxon'

interface Props {
  plan: PlanForReportingFragment
  days: Interval[]
  location: LocationForReportingFragment
}

export const ActualSalesRow: FC<Props> = ({ plan, days, location }) => {
  const fiscalWeekOffsetFromMonday = location.fiscalWeekOffsetFromMonday ?? 0
  const actualSalesPerDay = days.map(
    day =>
      getDailySummary(day, plan, fiscalWeekOffsetFromMonday).actualSales ?? null
  )
  const totalActualSales = sum(
    actualSalesPerDay.map(actualSales => actualSales ?? 0)
  )
  return (
    <StyledReportingTableRow>
      <StyledReportingSubheaderCell className="bottom-border" />
      {actualSalesPerDay.map((daySales, index) => (
        <ReportingDataCell
          labelTranslationKey={index === 0 ? 'reporting.actual' : undefined}
          className={cx('bottom-border', index % 2 === 0 && 'stripe')}
          key={index}
        >
          <ReportingDataValue>{asDollarsOrEmDash(daySales)}</ReportingDataValue>
        </ReportingDataCell>
      ))}
      <ReportingDataCell className="bold bottom-border">
        <ReportingDataValue>
          {asDollarsOrEmDash(totalActualSales)}
        </ReportingDataValue>
      </ReportingDataCell>
    </StyledReportingTableRow>
  )
}
