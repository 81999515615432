import { DateTime, Interval } from 'luxon'
import { useRouteMatch } from 'react-router-dom'

import {
  useCurrentLocation,
  useTryCurrentLocation,
} from 'queries/useCurrentLocation'

export const useGetWeekFromDate = ({
  defaultStartDate,
}: {
  defaultStartDate: DateTime
}): {
  requestedDate: DateTime | null
  startDate: DateTime
  endDate: DateTime
} => {
  const currentLocation = useTryCurrentLocation()
  const {
    params: { start_date: requestedDateString },
  } = useRouteMatch<{ start_date: string }>()

  const timeZone = currentLocation?.timeZone ?? DateTime.local().zoneName
  const fiscalWeekOffset = currentLocation?.fiscalWeekOffsetFromMonday ?? 0

  const requestedDate = requestedDateString
    ? DateTime.fromISO(requestedDateString, {
        zone: timeZone,
      })
    : null

  const startDate =
    requestedDate && requestedDate.isValid
      ? requestedDate.startOf('week').plus({ days: fiscalWeekOffset })
      : defaultStartDate
  const endDate = startDate.endOf('week').plus({ days: fiscalWeekOffset })

  return { requestedDate, startDate, endDate }
}

export const useFiscalWeekFinder = () => {
  const { location } = useCurrentLocation()
  const fiscalWeekOffset = location?.fiscalWeekOffsetFromMonday ?? 0
  const offset = { days: fiscalWeekOffset }
  return (dateTime: DateTime) => {
    const weekStart = dateTime
      .minus(offset)
      .startOf('week')
      .plus(offset)
    const weekEnd = dateTime
      .minus(offset)
      .endOf('week')
      .plus(offset)
    return Interval.fromDateTimes(weekStart, weekEnd)
  }
}
