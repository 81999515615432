import { DateTime } from 'luxon'

import { useGetInsightQuery } from 'config/graphqlTypes'
import { useCurrentLocation } from 'queries/useCurrentLocation'

export const useGetInsight = (date: DateTime) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  return useGetInsightQuery({
    variables: {
      locationId,
      date,
    },
  })
}
