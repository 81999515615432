import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useGetTopEventsQuery } from 'config/graphqlTypes'

export const NUM_EVENT_ROWS = 3

export const useDailyLocationEvents = (
  startAt: DateTime,
  endAt: DateTime,
  limit?: boolean
) => {
  const { t } = useTranslation()
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  let events =
    useGetTopEventsQuery({
      variables: {
        endOn: endAt,
        locationId,
        startOn: startAt,
      },
    }).data?.topEvents || []

  if (limit) {
    events = events.slice(0, NUM_EVENT_ROWS)
  }

  return events.map(event => ({
    ...event,
    timeOrHoliday: event.isHoliday
      ? t('misc.holiday')
      : event.startAt.toFormat('ha').toLowerCase(),
  }))
}
