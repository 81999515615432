import React from 'react'

import { ShiftPillContainer } from 'pages/plan/dailyViewModal/schedule/styles'
import { TimelineHelpers } from 'pages/plan/dailyViewModal/schedule/getGraphTimelineHelpers'
import { ShiftSegment } from 'pages/plan/dailyViewModal/schedule/getShiftSegments'
import {
  ShiftPillDragType,
  changePillEnd,
  changePillStart,
} from 'pages/plan/dailyViewModal/schedule/DragPill'
import { getFormattedSingleShiftTime } from 'utils/getFormattedSingleShiftTime'
import {} from 'pages/plan/dailyViewModal/styles'
import { ShiftLabel } from 'pages/plan/dailyViewModal/schedule/ShiftLabel'

export interface Props {
  shiftSegment: ShiftSegment
  timelineHelpers: TimelineHelpers
  xOffset: number
  dragType: ShiftPillDragType
}

export const ShiftPillDropPreview = ({
  shiftSegment: { startAt, endAt },
  timelineHelpers: {
    getPercentFromLeft,
    getPercentFromRight,
    getTimeWithOffset,
  },
  xOffset,
  dragType,
}: Props) => {
  const offsetLeft = changePillStart(dragType) ? xOffset : 0
  const offsetRight = changePillEnd(dragType) ? xOffset : 0

  const percentFromLeft = getPercentFromLeft(startAt, offsetLeft)
  const labelStartTime = getTimeWithOffset(startAt, offsetLeft)
  const percentFromRight = getPercentFromRight(endAt, offsetRight)
  const labelEndTime = getTimeWithOffset(endAt, offsetRight)

  return (
    <ShiftPillContainer left={percentFromLeft} right={percentFromRight}>
      <ShiftLabel
        label={getFormattedSingleShiftTime(labelStartTime)}
        position="start"
      />
      <ShiftLabel
        label={getFormattedSingleShiftTime(labelEndTime)}
        position="end"
      />
    </ShiftPillContainer>
  )
}
