import { DailyViewAddShiftButton } from 'pages/plan/dailyViewModal/DailyViewAddShiftButton'
import {
  CalendarIcon,
  NoShiftsMessageContainer,
  NoShiftsSubtitle,
  NoShiftsTitle,
} from 'pages/plan/dailyViewModal/styles'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  onClickAddShift: () => void
}

export const NoShiftsDefaultScreen: FC<Props> = ({ onClickAddShift }) => {
  const { t } = useTranslation()

  return (
    <NoShiftsMessageContainer data-testid="no-shifts-message-container">
      <CalendarIcon />
      <NoShiftsTitle>
        {t('planning.dailyViewModal.noShiftsTitle')}
      </NoShiftsTitle>
      <NoShiftsSubtitle>
        {t('planning.dailyViewModal.noShiftsSubtitle')}
      </NoShiftsSubtitle>
      {<DailyViewAddShiftButton onClickAddShift={onClickAddShift} />}
    </NoShiftsMessageContainer>
  )
}
