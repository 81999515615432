import React from 'react'
import { useTranslation } from 'react-i18next'
import { round, mapValues } from 'lodash'
import { DateTime, Interval, Duration } from 'luxon'
import { TFunction } from 'i18next'
import { formattedNumberWithFallback } from 'utils/formattedNumberWithFallback'
import sumOrNull from 'utils/sumOrNull'
import {
  IntervalOrderSumFragment,
  IntervalPredictionSumFragment,
  PlanSummaryWithDailySummariesFragment,
} from 'config/graphqlTypes'
import { DailyRow } from 'pages/tracking/breakdown/DailyRow'
import { ByDay } from 'pages/tracking/breakdown/byDay'
import { Table } from 'pages/tracking/breakdown/Table'
import { TableHeader } from 'pages/tracking/breakdown/styles'
import { getDailySummary } from 'utils/getDailySummary'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import RevenueCenterAccordion from 'pages/tracking/breakdown/RevenueCenterAccordion'
import { useCurrentLocationCategories } from 'queries/useCurrentLocation'
const dayKey = (dateTime: DateTime): string =>
  dateTime.toFormat('ccc').substring(0, 2)

export const asHours = (t: TFunction) => (
  num: number | null,
  decimals: boolean = false
): string =>
  formattedNumberWithFallback(num, hours =>
    t('misc.hourAbbreviationWithCount', {
      count: round(hours, decimals ? 1 : 0),
    })
  )

interface Props {
  interval: Interval
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  plan: PlanSummaryWithDailySummariesFragment
  salesType: string
}

export const CurrentWeekTable = ({
  interval,
  orderSums,
  predictionSums,
  plan,
  salesType,
}: Props) => {
  const { t } = useTranslation()
  const intervalDays = interval.splitBy(Duration.fromObject({ days: 1 }))
  const fiscalWeekOffsetFromMonday =
    useCurrentLocation().location.fiscalWeekOffsetFromMonday ?? 0

  const orderSumsByDay = intervalDays.reduce(
    (byDay: { [key: string]: IntervalOrderSumFragment[] }, day) => ({
      ...byDay,
      [dayKey(day.start)]: orderSums.filter(s => day.contains(s.startAt)),
    }),
    {}
  )
  const predictionSumsByDay = intervalDays.reduce(
    (byDay: { [key: string]: IntervalPredictionSumFragment[] }, day) => ({
      ...byDay,
      [dayKey(day.start)]: predictionSums.filter(s => day.contains(s.startAt)),
    }),
    {}
  )

  const predictionLaborHoursByDay = intervalDays.reduce((acc, day) => {
    acc[dayKey(day.start)] = getDailySummary(
      day,
      plan,
      fiscalWeekOffsetFromMonday
    ).optimalLaborForLatestPredictedSales

    return acc
  }, {} as ByDay)

  const forecastLaborHoursByDay = intervalDays.reduce((acc, day) => {
    acc[dayKey(day.start)] = getDailySummary(
      day,
      plan,
      fiscalWeekOffsetFromMonday
    ).scheduledLabor

    return acc
  }, {} as ByDay)

  const predictionTotalsByDay = mapValues(
    predictionSumsByDay,
    dayPredictionSums => sumOrNull(dayPredictionSums.map(p => p.reportedSales))
  )
  const salesByDay = mapValues(orderSumsByDay, dayOrderSums =>
    sumOrNull(dayOrderSums.map(s => s.reportedSales))
  )
  const { categories, categoryNames } = useCurrentLocationCategories()

  return (
    <>
      <Table
        title={t('tracking.todaysProgress.sales')}
        headers={
          <>
            {Object.keys(predictionTotalsByDay).map(day => (
              <TableHeader key={`${day}-sales`}>{day}</TableHeader>
            ))}
            <TableHeader>
              {t('tracking.todaysProgress.breakdownTotal')}
            </TableHeader>
          </>
        }
        rows={
          <>
            <RevenueCenterAccordion
              categories={categories}
              categoryNames={categoryNames}
              color="var(--blue)"
              title={t('tracking.todaysProgress.totalPrediction')}
              totalsByDay={predictionTotalsByDay}
              values={predictionSumsByDay}
              salesType={salesType}
              backgroundColor="#FFF"
            />
            <RevenueCenterAccordion
              categories={categories}
              categoryNames={categoryNames}
              color="var(--blue)"
              title={t('tracking.todaysProgress.totalActual')}
              totalsByDay={salesByDay}
              values={orderSumsByDay}
              salesType={salesType}
              backgroundColor="var(--gray7)"
            />
          </>
        }
      />
      <Table
        title={t('tracking.todaysProgress.breakdownLabor')}
        headers={<></>}
        rows={
          <>
            <DailyRow
              title={t('tracking.todaysProgress.breakdownSuggested')}
              titleColor="var(--teal)"
              totalsByDay={predictionLaborHoursByDay}
              formatter={asHours(t)}
            />
            <DailyRow
              actual
              title={t('tracking.todaysProgress.breakdownPlanned')}
              titleColor="var(--teal)"
              totalsByDay={forecastLaborHoursByDay}
              formatter={asHours(t)}
            />
          </>
        }
      />
    </>
  )
}
