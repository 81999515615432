import React from 'react'
import ConfirmationDialog from 'components/ConfirmationDialog'
import { useTranslation } from 'react-i18next'
import { TextButton } from 'components/Button'
import { Warning } from 'components/Text'
import { ViolationIcon } from 'components/ViolationIcon'
import { MinimumLaborBaselineViolationType } from '../dailyViewModal/useUnderMinimumLaborBaseline'
import {
  MinLbrViolationRow,
  MinLbrViolationText,
  MinLbrViolationWrapper,
} from '../styles'
type props = {
  show: boolean
  onHide: () => Promise<void>
  onConfirm: () => Promise<void>
  violations: MinimumLaborBaselineViolationType[]
}

type ViolationsListProps = {
  violations?: MinimumLaborBaselineViolationType[]
}

const ViolationsList = ({ violations }: ViolationsListProps) => {
  const { t } = useTranslation()
  if (!violations?.length) return null
  const sortedViolations = violations.sort(
    (a, b) => a.date.weekday - b.date.weekday
  )
  return (
    <MinLbrViolationWrapper>
      {sortedViolations.map(
        ({ start, end, date, employeesRequired }, index) => (
          <MinLbrViolationRow borderTop={index > 0} key={date?.toMillis()}>
            <MinLbrViolationText>
              {date?.toFormat('EEE LLL d').toUpperCase()}
            </MinLbrViolationText>
            <MinLbrViolationText>
              {`${start?.toFormat('ha').toLowerCase()}-${end
                .toFormat('ha')
                .toLowerCase()}`}
            </MinLbrViolationText>
            <MinLbrViolationText>
              {`${t('schedule.minimumLaborBaselineViolationDialog.violation', {
                count: employeesRequired,
              })}`}
            </MinLbrViolationText>
          </MinLbrViolationRow>
        )
      )}
    </MinLbrViolationWrapper>
  )
}

const MinimumLaborBaselineViolation = ({
  show,
  onHide,
  onConfirm,
  violations,
}: props) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      show={show}
      onHide={onHide}
      hideCancel={true}
      title={t('schedule.minimumLaborBaselineViolationDialog.title')}
      description={<ViolationsList violations={violations} />}
      confirmLabel={t(
        'schedule.minimumLaborBaselineViolationDialog.makeChanges'
      )}
      warning={
        <Warning
          fontSize="14px"
          style={{
            paddingLeft: '40px',
            paddingRight: '40px',
          }}
        >
          <ViolationIcon styles="margin-right: 10px" />
          {t('schedule.minimumLaborBaselineViolationDialog.warning')}
        </Warning>
      }
      alternativeAction={
        <TextButton
          style={{ fontWeight: 600, color: '#3B4EFF' }}
          onClick={onConfirm}
        >
          {t('schedule.minimumLaborBaselineViolationDialog.confirm')}
        </TextButton>
      }
      onConfirm={onHide}
    />
  )
}

export default MinimumLaborBaselineViolation
