import React, { FC } from 'react'
import BootstrapTable, {
  BootstrapTableProps,
  ColumnDescription,
} from 'react-bootstrap-table-next'
import { BootstrapTableWrapper } from './styles'

type DataTableProps = {
  bordered?: boolean
  data: any
  columns: ColumnDescription[]
  filter?: any
  minHeight?: string | number
  rowHeight?: string | number
} & BootstrapTableProps

const DataTable: FC<DataTableProps> = ({
  bordered = false,
  data,
  columns,
  filter,
  minHeight,
  rowHeight,
  ...props
}) => {
  return (
    <BootstrapTableWrapper minHeight={minHeight} rowHeight={rowHeight}>
      <BootstrapTable
        {...props}
        bootstrap4
        bordered={bordered}
        wrapperClasses="table-responsive"
        data={data}
        columns={columns}
        filter={filter}
      />
    </BootstrapTableWrapper>
  )
}

export default DataTable
