import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SmallSparkleIcon } from '../assets/images/small-sparkle.svg'
import { RegularBoldBodyText, TableLabelBoldText } from 'components/Text'
import { PlanFragment, PlanSummaryFragment } from 'config/graphqlTypes'
import { InfoTooltipIcon } from './InfoTooltipIcon'
import {
  AutoScheduleText,
  StyledNoScheduleText,
} from 'pages/plan/scheduling/styles'
import editIcon from 'assets/images/editicon.svg'

const BadgeWrapper = styled.div<{ asColumn: boolean }>(({ asColumn }) => ({
  display: 'flex',
  flexDirection: asColumn ? 'column' : 'row',
  alignItems: 'center',
  marginBottom: 0,
}))

const AnnotationText = styled(TableLabelBoldText)<{ asColumn: boolean }>(
  ({ asColumn }) => ({
    fontWeight: 600,
    paddingTop: asColumn ? 3 : 0,
    paddingLeft: 7,
    color: 'var(--gray4)',
    fontSize: asColumn ? '10px' : '0.75rem',
  })
)

type PlanStatusProps = Pick<
  PlanFragment,
  | 'submittedAt'
  | 'submitter'
  | 'autoGenerated'
  | 'state'
  | 'lastUpdatedAt'
  | 'updatedBy'
>

export const usePlanStatus = ({
  submittedAt,
  submitter,
  autoGenerated,
  state,
  lastUpdatedAt,
  updatedBy,
}: PlanStatusProps | PlanSummaryFragment) => {
  const { t } = useTranslation()

  if (submitter && submittedAt) {
    return {
      isSubmitted: true,
      statusText: t('planning.submitted'),
      submittedBy: t('planning.submittedBy', {
        name: submitter.name,
      }),
      submittedAt: t('planning.submittedAt', {
        date: submittedAt.toFormat('L.d.yy'),
        time: submittedAt.toFormat('h:mm a'),
      }),
      lastUpdatedAt: t('planning.lastUpdatedAt', {
        date: lastUpdatedAt?.toFormat('L.d.yy'),
        time: lastUpdatedAt?.toFormat('h:mm a'),
      }),
      updatedBy,
      textColor: 'var(--blue)',
      autoGenerated,
      backgroundColor: null,
      state,
    }
  } else {
    return {
      statusText: t('planning.proceedToSchedule'),
      textColor: 'white',
      backgroundColor: 'var(--blue)',
      autoGenerated,
      state,
      lastUpdatedAt:
        state !== 'initial_creation'
          ? t('planning.lastUpdatedAt', {
              date: lastUpdatedAt?.toFormat('L.d.yy'),
              time: lastUpdatedAt?.toFormat('h:mm a'),
            })
          : '',
      updatedBy,
    }
  }
}

interface PlanStatusBadgeProps {
  submittedAt: string | undefined
  submittedBy: string | undefined
  lastUpdatedAt: string | undefined
  updatedBy: string | undefined
  autoGenerated: boolean
  backgroundColor: string | null
  showEditable?: boolean | undefined
  textColor: string
  statusText: string
  state: string
}

const EditBadge = styled.img({ marginLeft: '8px' })
const ScheduleSubmittedBadge = styled.div<{
  textColor: string
  backgroundColor: string | null
  asColumn: boolean
}>(({ textColor, backgroundColor, asColumn }) => ({
  backgroundColor: backgroundColor || 'transparent',
  color: textColor,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: asColumn ? (backgroundColor ? '11px 23px' : '0') : '8px 15px',
  minWidth: asColumn ? 180 : 0,
  borderRadius: 25,
}))

export const PlanStatusBadge = ({
  lastUpdatedAt,
  updatedBy,
  submittedAt,
  submittedBy,
  autoGenerated,
  state,
}: PlanStatusBadgeProps) => {
  const { t } = useTranslation()
  const isDraft = state === 'in_progress' || state === 'pending_confirmation'

  return (
    <BadgeWrapper data-testid="plan-status" asColumn={false}>
      {isDraft && (
        <>
          {autoGenerated ? (
            <StyledContainer style={{ columnGap: 4 }}>
              <AutoScheduleText>
                <SmallSparkleIcon />
                {t('schedule.createSchedule.autoScheduleDraft')}
              </AutoScheduleText>
              <InfoTooltipIcon
                message={t(
                  'schedule.selectTemplateModal.autoScheduleDisclaimer'
                )}
              />
            </StyledContainer>
          ) : (
            <>
              <StyledNoScheduleText>
                {t('schedule.createSchedule.scheduleDraft')}
              </StyledNoScheduleText>
            </>
          )}
          <AnnotationText asColumn>
            {updatedBy && `Last updated by ${updatedBy} |`}
          </AnnotationText>
          <AnnotationText asColumn>{lastUpdatedAt}</AnnotationText>
        </>
      )}
      <AnnotationText asColumn={false}>
        {submittedBy && `${submittedBy} |`}
      </AnnotationText>
      <AnnotationText asColumn={false}>{submittedAt}</AnnotationText>
      {!isDraft && (
        <>
          <AnnotationText asColumn={false}>
            {updatedBy && `Last updated by ${updatedBy} |`}
          </AnnotationText>
          <AnnotationText asColumn={false}>{lastUpdatedAt}</AnnotationText>
        </>
      )}
    </BadgeWrapper>
  )
}

export const PlanStatusBadgeColumn = ({
  backgroundColor,
  showEditable,
  textColor,
  statusText,
  submittedAt,
  submittedBy,
  lastUpdatedAt,
  updatedBy,
  autoGenerated,
  state,
}: PlanStatusBadgeProps) => {
  const { t } = useTranslation()
  const isDraft = state === 'in_progress' || state === 'pending_confirmation'
  return (
    <BadgeWrapper data-testid="plan-status" asColumn>
      {isDraft ? (
        <>
          {autoGenerated ? (
            <StyledContainer>
              <AutoScheduleText>
                <SmallSparkleIcon />
                {t('schedule.createSchedule.autoScheduleDraft')}
              </AutoScheduleText>
              <EditBadge src={editIcon} alt="" />
            </StyledContainer>
          ) : (
            <StyledContainer>
              <StyledNoScheduleText style={{ color: 'var(--blue)' }}>
                <RegularBoldBodyText>
                  {t('schedule.createSchedule.scheduleDraft')}
                </RegularBoldBodyText>
              </StyledNoScheduleText>
              <EditBadge src={editIcon} alt="" />
            </StyledContainer>
          )}

          <AnnotationText asColumn>
            {updatedBy && `Last updated by ${updatedBy} |`}
          </AnnotationText>
          <AnnotationText asColumn>{lastUpdatedAt}</AnnotationText>
        </>
      ) : (
        <ScheduleSubmittedBadge
          data-testid="status-badge"
          asColumn
          backgroundColor={backgroundColor}
          textColor={textColor}
        >
          <RegularBoldBodyText>{statusText}</RegularBoldBodyText>
          {showEditable && <EditBadge src={editIcon} alt="" />}
        </ScheduleSubmittedBadge>
      )}
      <AnnotationText asColumn>
        {submittedBy && `${submittedBy} |`}
      </AnnotationText>
      <AnnotationText asColumn>{submittedAt}</AnnotationText>
      {!isDraft && (
        <>
          <AnnotationText asColumn>
            {updatedBy && `Last updated by ${updatedBy} |`}
          </AnnotationText>
          <AnnotationText asColumn>{lastUpdatedAt}</AnnotationText>
        </>
      )}
    </BadgeWrapper>
  )
}

export const PlanStatus = (plan: PlanStatusProps) => {
  const {
    submittedAt,
    submittedBy,
    state,
    autoGenerated,
    textColor,
    backgroundColor,
    statusText,
    lastUpdatedAt,
    updatedBy,
  } = usePlanStatus(plan)
  return (
    <PlanStatusBadge
      submittedAt={submittedAt}
      submittedBy={submittedBy}
      autoGenerated={autoGenerated}
      state={state}
      textColor={textColor}
      backgroundColor={backgroundColor}
      statusText={statusText}
      lastUpdatedAt={lastUpdatedAt}
      updatedBy={updatedBy || ''}
    />
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-size: 14px;
  }
`
