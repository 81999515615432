import React, { FC } from 'react'
import {
  LaborHrsBreakdownAccordionToggleTitle,
  LaborHrsBreakdownHeader,
  StyledRow,
} from './styles'
import { useTranslation } from 'react-i18next'
import { Col } from 'react-bootstrap'
import { LaborHoursBreakdownHeading } from './LaborHoursBreakdownHeading'
import { DateTime } from 'luxon'
import infoIcon from 'assets/images/info.svg'
import styled from 'styled-components'

type LaborHoursBreakdownHeaderProps = {
  isWeekView: boolean
  startOfWeek: DateTime
  day: DateTime
}
export const LaborHoursBreakdownHeader: FC<LaborHoursBreakdownHeaderProps> = ({
  isWeekView,
  startOfWeek,
  day,
}) => {
  const { t } = useTranslation()
  const heading = isWeekView
    ? t('tracking.laborActualHours.dayHeadingLabel', {
        day: startOfWeek.toFormat('LLL d'),
      })
    : day.toFormat('ccc, LLL d')
  const StyledIcon = styled.img({})
  return (
    <StyledRow>
      <Col>
        <LaborHrsBreakdownHeader>
          {t('tracking.laborActualHours.headerLabel')}
        </LaborHrsBreakdownHeader>
        <LaborHoursBreakdownHeading heading={heading} />
        <StyledIcon src={infoIcon} />{' '}
        <LaborHrsBreakdownAccordionToggleTitle className="">
          {t('tracking.todaysProgress.excludedFromLaborWarning')}
        </LaborHrsBreakdownAccordionToggleTitle>
      </Col>
    </StyledRow>
  )
}
