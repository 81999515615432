import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SettingsSection from '../SettingsSection'
import TimeInputsRow from '../../../../components/TimeInputsRow'
import styled from 'styled-components'
import {
  convertTo12HourFormat,
  convertTo24HourFormat,
  convertToDefault12HourFormat,
} from '../../../../utils/timeFormats'
import { StringInputGroup } from '../../../../components/Form'
import { DateTime } from 'luxon'
import { overlappingTimeSlot } from '../../../../utils/daysAndTimes'
import { ReactComponent as ErrorStatusIcon } from '../../../../assets/images/error-status-icon.svg'
import { SalesDayPartType } from '../locations/EditLocation'

type SalesDayPartKeys = keyof SalesDayPartType

interface SalesDayPartRowsProps {
  parts: SalesDayPartType[]
  hasSalesDayPartsPerm: boolean
  onChange: (parts: SalesDayPartType[]) => void
}

interface Props {
  parts: SalesDayPartType[]
  hasSalesDayPartsPerm: boolean
  onValidForm: (isValid: boolean) => void
  onChangeParts: (parts: SalesDayPartType[]) => void
}

function SalesDayParts({
  parts,
  hasSalesDayPartsPerm,
  onValidForm,
  onChangeParts,
}: Props) {
  const { t } = useTranslation()
  const [salesDayParts, setSalesDayParts] = useState(() =>
    parts.map(part => ({
      ...part,
      startAt: convertToDefault12HourFormat(part.startAt),
      endAt: convertToDefault12HourFormat(part.endAt),
    }))
  )

  const handleOnChange = (salesDayParts: SalesDayPartType[]) => {
    const isInvalid = isInvalidForm(salesDayParts)
    const timesOverlap = overlappingTimeSlot(salesDayParts)
    onValidForm(!isInvalid && !timesOverlap)
    setSalesDayParts([...salesDayParts])
    onChangeParts(salesDayParts)
  }

  return (
    <SettingsSection
      descriptionStyle={{ width: '100%' }}
      title={t('management.settings.salesDayParts.title')}
      description={t('management.settings.salesDayParts.description')}
      innerSection={
        <SalesDayPartRows
          parts={salesDayParts}
          onChange={handleOnChange}
          hasSalesDayPartsPerm={hasSalesDayPartsPerm}
        />
      }
    />
  )
}

const isInvalidForm = (parts: SalesDayPartType[]) => {
  return parts.some(part => {
    const { dayPartTitle, startAt, endAt } = part
    const start = convertTo12HourFormat(startAt)
    const end = convertTo12HourFormat(endAt)
    const isValid = !!start && !!end
    return !isValid || !dayPartTitle?.trim()
  })
}

function SalesDayPartRows({
  parts,
  hasSalesDayPartsPerm,
  onChange,
}: SalesDayPartRowsProps) {
  const { t } = useTranslation()
  const [salesDayParts, setSalesDayParts] = useState(parts)
  const disableForm = !hasSalesDayPartsPerm

  const handleUpdate = (
    id: SalesDayPartType['id'],
    key: SalesDayPartKeys,
    value: string
  ) => {
    const updateParts = (parts: SalesDayPartType[]) =>
      parts.map(part => (part.id === id ? { ...part, [key]: value } : part))
    setSalesDayParts(currentState => updateParts(currentState))
    onChange(updateParts(salesDayParts))
  }

  const handleAddRow = (endTime: SalesDayPartType['endAt']) => {
    const endDateTime = DateTime.fromISO(convertTo24HourFormat(endTime)).plus({
      hours: 1,
    })
    const newPart = {
      id: `new-${Date.now()}`,
      dayPartTitle: '',
      startAt: endTime,
      endAt: endDateTime.toFormat('h:mm a'),
    }
    setSalesDayParts(currentState => [...currentState, newPart])
    onChange([...salesDayParts, newPart])
  }

  const handleRemoveRow = (id: SalesDayPartType['id']) => {
    const updatedParts = salesDayParts.filter(part => part.id !== id)
    setSalesDayParts(updatedParts)
    onChange(updatedParts)
  }

  const timesOverlap = overlappingTimeSlot(salesDayParts)

  return (
    <PartRowsContainer>
      {salesDayParts.map((salesDayPart, index) => {
        const { id, dayPartTitle, startAt, endAt } = salesDayPart
        const showRemoveIcon = index !== 0
        const showAddIcon =
          index === salesDayParts.length - 1 && salesDayParts.length < 3
        const isInvalidLastRowEndTime = convertTo12HourFormat(
          salesDayParts[salesDayParts.length - 1].endAt
        )

        return (
          <Container key={id}>
            <StringInputGroup
              containerStyle={{ marginBottom: -8 }}
              labelProps={{
                label:
                  index === 0
                    ? t('management.settings.salesDayParts.inputLabel')
                    : '',
              }}
              controlProps={{
                style: {
                  width: timesOverlap ? '20rem' : '',
                },
                disabled: disableForm,
                value: dayPartTitle,
                onChange: e => handleUpdate(id, 'dayPartTitle', e.target.value),
              }}
              errorFeedbackProps={{
                error: (
                  <>
                    {timesOverlap && timesOverlap.id === id ? (
                      <ErrorMessage>
                        <ErrorStatusIcon />
                        {t(
                          'management.settings.salesDayParts.overlappingErrorMsg'
                        )}
                      </ErrorMessage>
                    ) : null}
                  </>
                ),
              }}
            />
            <TimeInputsRow
              containerStyle={{
                marginBottom: index === salesDayParts.length - 1 ? 0 : -8,
              }}
              label={
                index === 0
                  ? t('management.settings.salesDayParts.inputHrs')
                  : ''
              }
              start={startAt}
              end={endAt}
              disableStart={disableForm}
              disableEnd={disableForm}
              rowControls={{
                flags: {
                  showRemoveIcon,
                  showAddIcon,
                  disableAddIcon: !isInvalidLastRowEndTime || disableForm,
                  disableRemoveIcon: disableForm,
                },
                actions: {
                  onClickAdd: () => handleAddRow(endAt),
                  onClickRemove: () => handleRemoveRow(id),
                },
              }}
              onChangeStart={time => handleUpdate(id, 'startAt', time)}
              onChangeEnd={time => handleUpdate(id, 'endAt', time)}
              onBlurStart={time => handleUpdate(id, 'startAt', time)}
              onBlurEnd={time => handleUpdate(id, 'endAt', time)}
            />
          </Container>
        )
      })}
    </PartRowsContainer>
  )
}

const PartRowsContainer = styled.div`
  margin-top: 1.5rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4.5rem;
`

const ErrorMessage = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`

export default SalesDayParts
