import React from 'react'

import { CustomLayerProps } from 'components/Chart/CustomLayer'

export const RightBackgroundLayer = ({
  height,
  width,
  margin,
}: CustomLayerProps) => {
  const marginLeft = margin?.left ?? 0
  const marginRight = margin?.right ?? 0
  const marginTop = margin?.top ?? 0

  return (
    <rect
      fill="var(--gray7)"
      color=""
      height={height + marginTop}
      width={marginRight}
      x={width - (marginLeft + marginRight) + 40}
      y={-marginTop}
    />
  )
}
