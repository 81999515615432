import { MutableRefObject, useLayoutEffect, useState } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

const useResizableElementWidth = (
  element: MutableRefObject<HTMLElement | null>
) => {
  const [width, setWidth] = useState<null | number>(null)

  useLayoutEffect(() => {
    setWidth(element.current?.getBoundingClientRect().width ?? 0)
  }, [element])

  useResizeObserver(element, entry => setWidth(entry.contentRect.width))
  return width ?? 0
}

export default useResizableElementWidth
