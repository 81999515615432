import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next'

import { routeMap } from 'pages/Routes'
import {
  HowItWorksCol,
  HowItWorksIcon,
  HowItWorksRow,
  HowItWorksItemNumber,
  HowItWorksItemDetail,
  HowItWorksItemTitle,
  HereToHelpRow,
  HereToHelpTitleCol,
  HereToHelpDetailCol,
  WelcomeFooter,
  FooterColumn,
  FooterLinksBig,
  FooterLinkBig,
  FooterLinkSmall,
  WelcomeBlockText,
  WelcomeSubtitle,
} from 'pages/covid/welcome/styles'
import howItWorks1 from 'assets/images/how-it-works-1.svg'
import howItWorks2 from 'assets/images/how-it-works-2.svg'
import howItWorks3 from 'assets/images/how-it-works-3.svg'
import { DashboardLogo } from 'pages/covid/dashboard/DashboardLogo'

interface HowItWorksItemProps {
  number: string
  icon: string
  title: string
  detail: string
}

const HowItWorksItem: FC<HowItWorksItemProps> = ({
  number,
  icon,
  title,
  detail,
}) => (
  <HowItWorksCol md={12} l={4} xl={4}>
    <HowItWorksIcon src={icon} alt={title} />
    <HowItWorksItemNumber>{number}</HowItWorksItemNumber>
    <HowItWorksItemTitle>{title}</HowItWorksItemTitle>
    <HowItWorksItemDetail>{detail}</HowItWorksItemDetail>
  </HowItWorksCol>
)

export const Welcome = () => {
  const { t } = useTranslation()

  const termsOfServiceUrl = `${process.env.REACT_APP_S3_ASSETS_DOMAIN}/Lineup+LLC+Terms+of+Service.pdf`
  const privacyUrl = `${process.env.REACT_APP_S3_ASSETS_DOMAIN}/Lineup+LLC+Privacy+Policy.pdf`
  const location = useLocation()
  const welcomeText =
    location.pathname === routeMap.covidDashboardWelcomeTechTable
      ? t('covidDashboard.whyLineupTechTable')
      : t('covidDashboard.whyLineupText')

  return (
    <Container fluid>
      <WelcomeSubtitle>
        <Col>{t('covidDashboard.whyLineup')}</Col>
      </WelcomeSubtitle>
      <WelcomeBlockText>{welcomeText}</WelcomeBlockText>
      <HereToHelpRow>
        <HereToHelpTitleCol xl={5}>
          <p>{t('covidDashboard.hereToHelpTitle')}</p>
        </HereToHelpTitleCol>
        <HereToHelpDetailCol xl={7}>
          <p>{t('covidDashboard.hereToHelpDetail')}</p>
        </HereToHelpDetailCol>
      </HereToHelpRow>
      <WelcomeSubtitle>
        <Col>{t('covidDashboard.howDoesItWorkTitle')}</Col>
      </WelcomeSubtitle>
      <WelcomeBlockText>
        {t('covidDashboard.howDoesItWorkText')}
      </WelcomeBlockText>
      <HowItWorksRow>
        <HowItWorksItem
          number={'01'}
          icon={howItWorks1}
          title={t('covidDashboard.howItWorks1Title')}
          detail={t('covidDashboard.howItWorks1Detail')}
        />
        <HowItWorksItem
          number={'02'}
          icon={howItWorks2}
          title={t('covidDashboard.howItWorks2Title')}
          detail={t('covidDashboard.howItWorks2Detail')}
        />
        <HowItWorksItem
          number={'03'}
          icon={howItWorks3}
          title={t('covidDashboard.howItWorks3Title')}
          detail={t('covidDashboard.howItWorks3Detail')}
        />
      </HowItWorksRow>
      <WelcomeFooter sm={12}>
        <FooterColumn>
          <DashboardLogo />
          <div>
            <FooterLinksBig>
              <FooterLinkBig
                href="https://www.lineup.ai/"
                target="_blank"
                key="about"
              >
                {t('covidDashboard.about')}
              </FooterLinkBig>
              <FooterLinkBig
                href="mailto:info@lineup.ai"
                target="_blank"
                key="contact"
              >
                {t('covidDashboard.contact')}
              </FooterLinkBig>
            </FooterLinksBig>
            <FooterLinkSmall
              href={termsOfServiceUrl}
              target="_blank"
              key="termsOfService"
            >
              {t('covidDashboard.termsOfService')}
            </FooterLinkSmall>
            {'   •   '}
            <FooterLinkSmall
              href={privacyUrl}
              target="_blank"
              key="privacyPolicy"
            >
              {t('covidDashboard.privacyPolicy')}
            </FooterLinkSmall>
          </div>
        </FooterColumn>
      </WelcomeFooter>
    </Container>
  )
}
