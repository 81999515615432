import React, { CSSProperties, ReactNode } from 'react'
import { InputGroup } from 'react-bootstrap'
import styled from 'styled-components'

interface Props {
  header?: string
  title: string
  description: string
  borderless?: boolean
  noControlsGroup?: boolean
  innerSection?: ReactNode
  descriptionStyle?: CSSProperties
  children?: ReactNode
}

function SettingsSection({
  header,
  title,
  description,
  borderless,
  noControlsGroup,
  innerSection,
  descriptionStyle,
  children,
}: Props) {
  return (
    <>
      {header && <Header>{header}</Header>}
      <Wrapper borderless={borderless}>
        <Title>{title}</Title>
        <Body>
          <Description style={descriptionStyle}>{description}</Description>
          <ControlsWrapper noControlsGroup={noControlsGroup}>
            {children}
          </ControlsWrapper>
        </Body>
        {innerSection}
      </Wrapper>
    </>
  )
}

const Wrapper = ({
  borderless,
  children,
}: {
  borderless: boolean | undefined
  children: ReactNode
}) => (borderless ? <>{children}</> : <Container>{children}</Container>)

const ControlsWrapper = ({
  noControlsGroup,
  children,
}: {
  noControlsGroup: boolean | undefined
  children: ReactNode
}) =>
  noControlsGroup ? (
    <>{children}</>
  ) : (
    <ControlsContainer>{children}</ControlsContainer>
  )

const Header = styled.div`
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`

const Description = styled.div`
  width: 600px;
  color: var(--gray3);
`

const Title = styled.div`
  font-weight: bold;
`

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`

const Container = styled.div`
  border: solid;
  border-color: var(--gray5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 960px;
`

const ControlsContainer = styled(InputGroup)`
  width: 140px;
`

export default SettingsSection
