import React, { FC } from 'react'
import { PredictionSubTitleCell } from 'pages/plan/rows/styles'

interface Props {
  categories: string[]
  expand?: boolean
  fontSize?: string
}

export const PredictionSubTitleWrapper: FC<Props> = ({
  categories,
  expand,
  fontSize,
}) => {
  const categoriresToDisplay = categories.slice(
    0,
    expand ? categories.length : 3
  )
  return (
    <div>
      {categoriresToDisplay.map((category: string) => (
        <PredictionSubTitleCell key={category} fontSize={fontSize}>
          {category}
        </PredictionSubTitleCell>
      ))}
    </div>
  )
}
