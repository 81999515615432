import { Dashboard } from 'pages/covid/types'
import { getAddress } from 'pages/covid/getAddress'
import { notify } from 'config/errorReporting'

export const getAddressesForDashboard = async (dashboard: Dashboard) => {
  const foundAddresses = []

  for (const addressString of dashboard.addresses) {
    const address = await getAddress(addressString)

    if (address) {
      foundAddresses.push(address)
    } else {
      notify(
        `Dashboard record has invalid address. Address string: ${addressString}. Dashboard: ${JSON.stringify(
          dashboard
        )}`
      )
    }
  }

  return foundAddresses
}
