import { compact, last } from 'lodash'
import { Datum, Serie } from '@nivo/line'
import { DateTime } from 'luxon'

import {
  IntervalOrderSumFragment,
  IntervalPredictionSumFragment,
} from 'config/graphqlTypes'
import { buildSeries, SeriesPoint } from 'pages/tracking/SalesChart/buildSeries'
import { chartDataConfig } from 'components/Chart/chartDataConfig'

const timeOfFirstPositiveValue = (
  series: { value: number; time: DateTime }[]
) => series.find(({ value }) => value > 0)?.time

const timeOfLastPositiveValue = (series: { value: number; time: DateTime }[]) =>
  timeOfFirstPositiveValue(series.slice().reverse())?.endOf('hour')

const withoutAfterHours = (
  fulfilled: SeriesPoint[],
  unfulfilled: SeriesPoint[],
  predicted: SeriesPoint[],
  openAt: DateTime,
  closeAt: DateTime
) => {
  const endOfFirstInterval = DateTime.min(
    ...compact([
      timeOfFirstPositiveValue(fulfilled),
      timeOfFirstPositiveValue(predicted),
      timeOfFirstPositiveValue(unfulfilled),
      // Start with the interval preceding the opening
      openAt.startOf('hour').minus({ seconds: 1 }),
    ])
  )

  const endOfLastInterval = DateTime.max(
    ...compact([
      timeOfLastPositiveValue(fulfilled),
      timeOfLastPositiveValue(predicted),
      timeOfLastPositiveValue(unfulfilled),
      closeAt,
    ])
  )

  const doTruncate = (seriesPoints: SeriesPoint[]) =>
    seriesPoints.filter(
      seriesPoint =>
        seriesPoint.time >= endOfFirstInterval &&
        seriesPoint.time <= endOfLastInterval
    )

  const truncatedFulfilled = doTruncate(fulfilled)
  const truncatedUnfulfilled = doTruncate(unfulfilled)
  const truncatedPredictions = doTruncate(predicted)

  // If there are unfulfilled dots, ensure they connect to the fulfilled series,
  // even if we have to show a 0 at the start of the day
  const hasFutureOrders = truncatedUnfulfilled.some(u => u.value > 0)
  const fulfilledOrZeroAtOpening =
    truncatedFulfilled.length === 0 && hasFutureOrders
      ? [truncatedUnfulfilled[0]]
      : truncatedFulfilled

  return [fulfilledOrZeroAtOpening, truncatedUnfulfilled, truncatedPredictions]
}

const generatePoints = (
  getDisplayTimestamp: (dateTime: DateTime) => string,
  isWeekView: boolean,
  seriesPoints: SeriesPoint[],
  xAxisFormat: string,
  startingPoints: Datum[] = []
): Datum[] =>
  seriesPoints.reduce<Datum[]>((previousPoints, { value, time }) => {
    // In hour view, a point such as "11am" represents data from 10:00:00am
    // through 10:59:59am. Therefore, add a second before formatting.
    const modifiedDateTime = time.plus({ seconds: isWeekView ? 0 : 1 })

    return [
      ...(previousPoints || []),
      {
        y: value / 100 + Number(last(previousPoints)?.y || 0),
        value: value / 100,
        x: modifiedDateTime.toFormat(xAxisFormat),
        // Pass in the full timestamp in this custom property which is
        // available to the Tooltip. This allows us to display the realtime
        // timestamp for today's date in the week-view.
        endAtISO: modifiedDateTime.toISO(),
        displayTime: getDisplayTimestamp(modifiedDateTime),
      },
    ]
  }, startingPoints)

export const buildChartData = (
  orderSums: IntervalOrderSumFragment[],
  predictionSums: IntervalPredictionSumFragment[],
  openAt: DateTime,
  closeAt: DateTime,
  xAxisFormat: string,
  isWeekView: boolean,
  salesType: string,
  getDisplayTimestamp: (dateTime: DateTime) => string,
  now = DateTime.local()
): Serie[] => {
  const { fulfilled, unfulfilled, predicted } = buildSeries(
    orderSums,
    predictionSums,
    isWeekView,
    salesType,
    now
  )

  const [
    truncatedFulfilled,
    truncatedUnfulfilled,
    truncatedPredicted,
  ] = isWeekView
    ? [fulfilled, unfulfilled, predicted]
    : withoutAfterHours(fulfilled, unfulfilled, predicted, openAt, closeAt)

  const unfulfilledWithoutZeroes = truncatedUnfulfilled.filter(u => u.value)

  const fulfilledPoints = generatePoints(
    getDisplayTimestamp,
    isWeekView,
    truncatedFulfilled,
    xAxisFormat
  )
  const predictionPoints = generatePoints(
    getDisplayTimestamp,
    isWeekView,
    truncatedPredicted,
    xAxisFormat
  )

  // Begin the unfulfilled series with the last fulfilled point
  const startOfUnfulfilledSeries =
    fulfilledPoints.length > 0 && unfulfilledWithoutZeroes.length > 0
      ? [fulfilledPoints[fulfilledPoints.length - 1]]
      : []

  const unfulfilledPoints = generatePoints(
    getDisplayTimestamp,
    isWeekView,
    unfulfilledWithoutZeroes,
    xAxisFormat,
    startOfUnfulfilledSeries
  )

  if (predictionPoints.length > 0 || fulfilledPoints.length > 0) {
    predictionPoints.unshift({
      displayTime: 'Open',
      endAtISO: openAt.toISO(),
      x: openAt.startOf('hour').toFormat(xAxisFormat),
      y: 0,
      value: 0,
    })
    fulfilledPoints.unshift({
      displayTime: 'Open',
      endAtISO: openAt.toISO(),
      x: openAt.startOf('hour').toFormat(xAxisFormat),
      y: 0,
      value: 0,
    })
  }
  return [
    {
      id: chartDataConfig.predictions.id,
      data: predictionPoints,
    },
    {
      id: chartDataConfig.actuals.id,
      data: fulfilledPoints,
    },
    {
      id: chartDataConfig.futureActuals.id,
      data: unfulfilledPoints,
    },
  ]
}
