import React from 'react'
import styled from 'styled-components'
import { ReactComponent as PersonIcon } from 'assets/images/person.svg'
import { ReactComponent as TimeIcon } from 'assets/images/time.svg'
import { Reservation } from '../../config/graphqlTypesRaw'
import CustomPopover from '../../components/CustomPopover'
import { useTranslation } from 'react-i18next'

export type ReservationType = Omit<Reservation, 'createdAt' | 'updatedAt'>

interface Props {
  reservations: ReservationType[]
}

export const ReservationCard = ({ reservations }: Props) => {
  const { t } = useTranslation()
  const totalReservations = reservations.length
  const reservationTimes = Array.from(
    new Set(
      reservations
        .map(
          (reservation: ReservationType) =>
            reservation.scheduledTimeLocalFormatted
        )
        .sort((a, b) => (a > b ? 1 : -1))
    )
  )

  function filteredReservations(
    reservations: ReservationType[],
    reservationTime: string
  ): ReservationType[] {
    return reservations.filter(
      res => res.scheduledTimeLocalFormatted === reservationTime
    )
  }

  return (
    <CustomPopover
      id={reservations[0].id}
      trigger={['hover', 'focus']}
      popoverStyles={{ minWidth: 230 }}
      content={
        <StyledCard>
          <EventTitleContainer>
            <EventTitle>
              {t('planning.reservations.largePartiesBreakdown')}
            </EventTitle>
          </EventTitleContainer>
          <>
            {reservationTimes.map(reservationTime => (
              <>
                <EventSection>
                  <Text>
                    <TimeIconStyled style={{ marginRight: 3 }} />
                    {reservationTime}
                  </Text>
                  <Divider> | </Divider>
                  <ReservationPartySizeSection>
                    {filteredReservations(reservations, reservationTime).map(
                      reservation => (
                        <div>
                          <PersonIconStyled />
                          <Text style={{ marginLeft: 5 }}>
                            {reservation.partySize} guests
                          </Text>
                        </div>
                      )
                    )}
                  </ReservationPartySizeSection>
                </EventSection>
                <p />
              </>
            ))}
          </>
          <EventLink href={reservations[0].reservationUrl} target="_blank">
            {t('planning.reservations.viewDetails')}
          </EventLink>
        </StyledCard>
      }
    >
      <EventLabel>
        {totalReservations}&nbsp;
        {t('planning.reservations.largeParties', {
          count: totalReservations,
        })}
      </EventLabel>
    </CustomPopover>
  )
}

const EventTitle = styled.span({
  fontSize: 16,
  fontWeight: 800,
  color: 'var(--black)',
  wordBreak: 'break-word',
  paddingBottom: 5,
})

const EventTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 4px;
`

const Text = styled.span`
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
`

const Divider = styled.span`
  color: var(--gray-lighter);
  font-size: 14px;
  font-weight: 500;
`

const PersonIconStyled = styled(PersonIcon)`
  width: 14px;
  height: 14px;
`

const TimeIconStyled = styled(TimeIcon)`
  width: 14px;
  height: 14px;
`

const EventLabel = styled.span({
  cursor: 'pointer',
  color: 'var(--lineup-blue)',
})

const StyledCard = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 100,
})

const EventSection = styled.div({
  display: 'flex',
  flexDirection: 'row',
  columnGap: 8,
})

const ReservationPartySizeSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  columnGap: 8,
})

const EventLink = styled.a({
  color: 'var(--blue)',
  fontSize: 12,
  fontWeight: 500,
  cursor: 'pointer',
  width: 'fit-content',
})
