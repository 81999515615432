/// <reference types="@types/segment-analytics" />

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

export const page = () => {
  console.debug('[Analytics] page view')
  window.analytics?.page()
}

export const track = (name: string, properties: Object | undefined) => {
  console.debug(
    `[Analytics] track: ${name} ${JSON.stringify(properties || {})}`
  )
  window.analytics?.track(name, properties)
}

export const identify = (userId: string, properties: Object | undefined) => {
  console.debug(
    `[Analytics] identify: ${userId} ${JSON.stringify(properties || {})}`
  )
  window.analytics?.identify(userId, properties)
}

export const crcDashboardIdentify = (
  dashboardId: string,
  properties: Object | undefined
) => identify(`crc-dashboard-${dashboardId}`, properties)

export const reset = () => {
  console.debug('[Analytics] reset')
  window.analytics?.reset()
}

let isAnalyticsConfigured = false
export const configure = () => {
  if (isAnalyticsConfigured || !window.analytics) {
    return
  }

  if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
    window.analytics?.load(process.env.REACT_APP_SEGMENT_WRITE_KEY)

    isAnalyticsConfigured = true
    console.debug('[Analytics] loaded')
  } else {
    console.log('[Analytics] disabled')
  }
}
