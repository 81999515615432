import React, { FC } from 'react'
//FIXME: This is a hack for now to get around this issue: https://github.com/webpack/webpack/issues/12197
// @ts-ignore
import { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
// @ts-ignore
import { SearchBarProps } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'

const { SearchBar } = Search

export const SettingsSearchInput: FC<SearchBarProps> = ({ ...props }) => (
  <SearchBar {...props} />
)
