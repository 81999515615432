import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  DashboardCenterRow,
  DashboardCenterCol,
  NoLocationsAddedIcon,
  NoLocationsAddedTitle,
  NoLocationsAddedDetail,
} from 'pages/covid/dashboard/styles'
import NoLocationsAddedImage from 'assets/images/no-locations-added.svg'

export const NoLocationsAdded = () => {
  const { t } = useTranslation()

  return (
    <DashboardCenterRow noGutters>
      <DashboardCenterCol>
        <NoLocationsAddedIcon
          src={NoLocationsAddedImage}
          alt={t('covidDashboard.noLocationsAddedTitle')}
        />
        <NoLocationsAddedTitle>
          {t('covidDashboard.noLocationsAddedTitle')}
        </NoLocationsAddedTitle>
        <NoLocationsAddedDetail>
          {t('covidDashboard.noLocationsAddedDetail')}
        </NoLocationsAddedDetail>
      </DashboardCenterCol>
    </DashboardCenterRow>
  )
}
