import { TFunction } from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AffectedChannel, InsightFragment, Reason } from 'config/graphqlTypes'
import { RegularBodyText } from 'components/Text'
import {
  SavedBox,
  DetailsButton,
  SectionHeader,
  EditButton,
  SavedHeader,
} from 'pages/tracking/insight/styles'

const getReasonText = (insight: InsightFragment, t: TFunction) => (
  reason: Reason
) =>
  reason === Reason.Other
    ? t('tracking.insight.reasons.otherWithDescription', {
        description: insight.otherReasons,
      })
    : t(`tracking.insight.reasons.${reason}`)

const getAffectedChannelsText = (t: TFunction) => (
  affectedChannel: AffectedChannel
) => t(`tracking.insight.channelsAffected.${affectedChannel}`)

const getWhatHappenedText = (
  insight: InsightFragment,
  t: TFunction
): string[] =>
  insight.wasUnusual
    ? insight.reasons!.map(getReasonText(insight, t))
    : [t('tracking.insight.saved.notUnusual')]

const getWhatChannelsAffectedText = (
  insight: InsightFragment,
  t: TFunction
): string[] | null =>
  insight.wasUnusual
    ? insight.affectedChannels!.map(getAffectedChannelsText(t))
    : null

const getWillHappenAgainText = (
  { willHappenAgain }: InsightFragment,
  t: TFunction
) =>
  willHappenAgain
    ? t(`tracking.insight.willHappenAgain.${willHappenAgain}`)
    : null

interface Props {
  insight: InsightFragment
  onEdit: () => void
}

export const SavedInsight = ({ insight, onEdit }: Props) => {
  const [showDetails, setShowDetails] = useState(false)
  const toggleShowDetails = () => setShowDetails(!showDetails)

  const { t } = useTranslation()
  const toggleShowDetailsButtonTitle = showDetails
    ? t('tracking.insight.saved.hideDetails')
    : t('tracking.insight.saved.showDetails')

  const whatHappenedText = getWhatHappenedText(insight, t)
  const whatAffectedChannelsText = getWhatChannelsAffectedText(insight, t)
  const willHappenAgainText = getWillHappenAgainText(insight, t)

  return (
    <SavedBox blue={insight.wasUnusual}>
      <SavedHeader>{t('tracking.insight.saved.header')}</SavedHeader>
      <RegularBodyText>{t('tracking.insight.saved.body')}</RegularBodyText>
      <DetailsButton onClick={toggleShowDetails} active={showDetails}>
        {toggleShowDetailsButtonTitle}
      </DetailsButton>
      {showDetails && (
        <>
          <SectionHeader>
            {t('tracking.insight.saved.whatHappened')}
          </SectionHeader>
          {whatHappenedText.map(reason => (
            <RegularBodyText key={reason}>{reason}</RegularBodyText>
          ))}
          {!!whatAffectedChannelsText && (
            <>
              <SectionHeader>
                {t('tracking.insight.saved.channelsAffected')}
              </SectionHeader>
              {whatAffectedChannelsText.map(channel => (
                <RegularBodyText key={channel}>{channel}</RegularBodyText>
              ))}
            </>
          )}
          {!!willHappenAgainText && (
            <>
              <SectionHeader>
                {t('tracking.insight.saved.willHappenAgain')}
              </SectionHeader>
              <RegularBodyText>{willHappenAgainText}</RegularBodyText>
            </>
          )}
          <EditButton onClick={onEdit}>
            {t('tracking.insight.saved.edit')}
          </EditButton>
        </>
      )}
    </SavedBox>
  )
}
