import { TopPanelData, Address } from 'pages/covid/types'
import { covidBackendFetch } from 'pages/covid/dashboard/addressRow/covidBackendFetch'
import { getCBG } from 'pages/covid/dashboard/addressRow/getCBG'

export const getTopPanelData = async (address: Address) => {
  const cbg = await getCBG(address)

  if (!cbg) {
    return null
  }

  const path = `top_panel_summary?cbg=${cbg}&city=${address.city}&state=${address.region}&address=${address.streetAddress}`
  const data = (await covidBackendFetch(path)) as TopPanelData

  return data
}
