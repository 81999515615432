import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ShiftTotal,
  ShiftTotalDiff,
  ShiftTotalWrapper,
} from 'pages/plan/dailyViewModal/schedule/styles'
import { useFormattedHours } from 'utils/useFormattedHours'
import { getFormattedSingleOptionalDecimalPlace } from 'utils/getFormattedHours'

interface Props {
  hours: number
  diff?: number
  isRoleTotal?: boolean
  optimalHours?: number
}

export const DayTotalHours = ({
  hours,
  diff,
  isRoleTotal,
  optimalHours,
}: Props) => {
  const { t } = useTranslation()

  const formattedHours = useFormattedHours(hours)
  const formattedOptimalHours = useFormattedHours(optimalHours ?? 0)
  const formattedSingleHours = getFormattedSingleOptionalDecimalPlace(hours)
  const displayHours = !!optimalHours ? formattedSingleHours : formattedHours
  const displayOptimalHours = !!optimalHours && `/${formattedOptimalHours}`
  const diffLabel =
    !!diff &&
    (diff >= 0
      ? t('planning.dailyViewModal.laborAllowanceRemaining', { hours: diff })
      : t('planning.dailyViewModal.laborAllowanceOverstaffed', {
          hours: 0 - diff,
        }))

  return (
    <ShiftTotalWrapper>
      <ShiftTotal isRoleTotal={isRoleTotal}>
        {displayHours}
        {displayOptimalHours}
      </ShiftTotal>
      {diffLabel && <ShiftTotalDiff>{diffLabel}</ShiftTotalDiff>}
    </ShiftTotalWrapper>
  )
}
