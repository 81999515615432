import React from 'react'
import { calculateLaborPercentageDay } from 'utils/calculateLaborPercentageDay'
import { useTranslation } from 'react-i18next'
import { asDollars } from 'utils/currency'
import {
  StyledLaborPercentageCell,
  StyledPercentageTooltipIcon,
  StyledScheduleDailyPercentageCost,
} from 'pages/plan/scheduling/styles'
import {
  SalesAndLaborSummaryFragment,
  ShiftFragment,
  ViolationFragment,
} from 'config/graphqlTypes'
import { LaborPercentagePopover } from './LaborPercentagePopover'
import { SpanDivider } from '../styles'

interface Props {
  id: string | undefined
  testId: string
  overHoursWeekViolations: ViolationFragment[]
  laborDollars: number
  shifts: ShiftFragment[]
  dailyPredictedSales: SalesAndLaborSummaryFragment['latestPredictedSales']
  locationId: string
}

export const LaborPercentageDayCell = ({
  dailyPredictedSales,
  overHoursWeekViolations,
  laborDollars,
  id,
  testId,
  shifts,
  locationId,
}: Props) => {
  const { t } = useTranslation()

  const {
    laborPercentage,
    employeesMissingPayRate,
  } = calculateLaborPercentageDay(
    dailyPredictedSales,
    overHoursWeekViolations,
    shifts,
    locationId
  )

  return (
    <StyledLaborPercentageCell data-testid={testId}>
      <StyledScheduleDailyPercentageCost>
        {t('planning.calculatedLaborPercentage', {
          value: laborPercentage.toFixed(1),
        })}{' '}
        <SpanDivider>{'|'}</SpanDivider>
        {asDollars(laborDollars)}
        <StyledPercentageTooltipIcon>
          {employeesMissingPayRate.length > 0 && (
            <LaborPercentagePopover
              id={id}
              employeeName={employeesMissingPayRate.map(e => e.name).join(', ')}
            />
          )}
        </StyledPercentageTooltipIcon>
      </StyledScheduleDailyPercentageCost>
    </StyledLaborPercentageCell>
  )
}
