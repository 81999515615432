import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'

import {
  horizontalPagePadding,
  printHorizontalPagePadding,
} from 'pages/plan/styles'
import { TableLabelBoldText } from 'components/Text'
import { BaseStyledInput } from 'components/Form'
import { smallBodyBoldTextStyle } from 'config/styles'
import { ReactComponent as LeftChevronRaw } from 'assets/images/left-chevron.svg'

// On XXL displays we display a single header row
export const IF_SINGLE_HEADER_ROW = `@media only screen and (min-width: 1400px)`

export const HeaderRow = styled(Row)({
  marginTop: 15,
  marginBottom: 15,
  paddingLeft: horizontalPagePadding,
  paddingRight: horizontalPagePadding,

  '@media print': {
    paddingLeft: printHorizontalPagePadding,
    paddingRight: printHorizontalPagePadding,
  },
})

export const StyledBackLink = styled(Link)({
  fontSize: '0.75rem',
  marginLeft: horizontalPagePadding,
  '@media print': {
    display: 'none',
  },
  marginRight: 7,
  marginTop: 25,
  '&:hover, &:focus': {
    textDecoration: 'none',
  },
})

export const StyledBackChevron = styled(LeftChevronRaw)({
  '> path': {
    stroke: 'var(--gray4)',
  },
})

export const StyledBackSpan = styled.span({
  ...smallBodyBoldTextStyle,
  color: 'var(--gray4)',
})

export const FarRightCol = styled(Col)({
  '@media print': {
    display: 'none',
  },
  alignItems: 'center',
  minWidth: 300,
  marginTop: 20,
  [IF_SINGLE_HEADER_ROW]: {
    marginTop: 0,
  },
  '&:nth-child(1)': {
    marginRight: 10,
  },
})

export const FarRightRow = styled(Row)({
  [IF_SINGLE_HEADER_ROW]: {
    justifyContent: 'flex-end',
  },
})

export const HeaderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

export const PlanStatusWrapper = styled.div({
  display: 'flex',
  '@media only screen and (max-width: 500px)': {
    marginTop: 10,
  },
})

export const SettingsWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
})

const settingsHeight = '35px'

export const LaborSettingsTitle = styled(TableLabelBoldText)({
  marginBottom: 0,
  color: 'var(--gray4)',
  lineHeight: settingsHeight,
  marginRight: 10,
})

export const StyledStringInput = styled(BaseStyledInput)({
  '&:disabled': {
    border: 'none',
    backgroundColor: 'var(--white)',
  },
})

export const PercentRevenueWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  //marginRight: 10,
  '@media only screen and (max-width: 400px)': {
    marginBottom: 10,
  },

  '& span': {
    fontSize: 10,
    fontWeight: 800,
    position: 'absolute',
    zIndex: 1,
    cursor: 'text',
    pointerEvents: 'none',
    color: 'var(--gray3)',
    marginLeft: 15,
  },

  '& input': {
    fontSize: 14,
    textAlign: 'right',
    fontWeight: 800,
    color: 'var(--gray1)',
    width: 160,
    height: settingsHeight,
  },
})

export const AddTemplateHeader = styled.span({
  color: 'var(--gray2)',
  fontSize: '1.75rem',
  fontWeight: 800,
  padding: '3px 8px',
})

export const SaveTemplateModalHeader = styled.div({
  textAlign: 'center',
  width: '100%',
})

export const SaveTemplateButton = styled.button({
  background: 'var(--gray7)',
  border: '1px solid var(--gray5)',
  borderRadius: 20,
  fontWeight: 800,
  fontSize: 12,
  padding: '0 10px',
  color: 'var(--gray2)',
  marginRight: 5,
  marginLeft: 10,
  minHeight: 30,
})

export const SavedTemplateText = styled.p({
  textAlign: 'center',
  padding: 10,
})

export const SaveTemplateModalBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  '& *': {
    margin: '5px 0',
  },
})

export const PlanTemplateName = styled.span({
  marginLeft: 10,
  fontSize: '0.75rem',
  fontWeight: 800,
  color: 'var(--gray2)',
  alignSelf: 'center',
})
