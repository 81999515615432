import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { groupBy } from 'lodash'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {
  WhiteFixedFooter,
  ActionButton,
  FooterButtonContainer,
} from 'pages/plan/styles'
import { ClearScheduleButton } from 'pages/plan/actionFooter/ClearScheduleButton'
import { Check } from '../../../components/Form'
import MinimumLaborBaselineViolation from 'pages/plan/actionFooter/MinimumLaborBaselineViolation'
import { MinimumLaborBaselineViolationType } from '../dailyViewModal/useUnderMinimumLaborBaseline'
import { usePlanPageContext } from '../planPageContext'
import { Spinner } from 'react-bootstrap'

interface Props {
  isShowingSchedule: boolean
  submitPlanLoading: boolean
  showSchedule: () => void
  hideSchedule: () => void
  onSubmitPlan: (notifyOnlyImpactedEmployees: boolean) => Promise<void>
  isUnderMinimumLaborBaseline: Boolean
  minimumLaborBaselineViolations: MinimumLaborBaselineViolationType[]
}

const onlyImpactedEmployeesCheckStyles = {
  marginLeft: 15,
  marginBottom: 8,
}

const allEmployeesCheckStyles = {
  marginLeft: 15,
}

const checkStyles = { fontSize: 14, fontWeight: 'normal' }

export const ActionFooter = ({
  onSubmitPlan,
  submitPlanLoading,
  isShowingSchedule,
  showSchedule,
  hideSchedule,
  isUnderMinimumLaborBaseline,
  minimumLaborBaselineViolations,
}: Props) => {
  const { t } = useTranslation()
  const [isMinLbrViolationDialog, setIsMinLbrViolationDialog] = useState<
    boolean
  >(false)

  const { plan } = usePlanPageContext()
  const { databaseId, state, submittedAt, needsConfirmation, shifts } = plan

  const onHideMinLbrViolationDialog = async () =>
    setIsMinLbrViolationDialog(false)

  const [
    isShowingConfirmationDialog,
    setIsShowingConfirmationDialog,
  ] = useState<boolean>(false)

  const [
    notifyOnlyImpactedEmployees,
    setNotifyOnlyImpactedEmployees,
  ] = useState(true)

  const hideDialog = () => {
    setIsShowingConfirmationDialog(false)
  }

  const handleNotifyOnlyImpactedEmployees = () => {
    setNotifyOnlyImpactedEmployees(!notifyOnlyImpactedEmployees)
  }

  const onConfirm = async () => {
    hideDialog()
    onSubmitPlan(notifyOnlyImpactedEmployees)
  }

  const groupedShifts = groupBy(shifts, shift => shift.employee.id === '-1')
  const hasAssignedShifts = (groupedShifts.false?.length || 0) > 0
  const hasNoUnassignedShifts = (groupedShifts.true?.length || 0) <= 0

  const canSubmitPlan =
    hasAssignedShifts && hasNoUnassignedShifts && state !== 'submitted'
  const isUpdatingPlan = !!submittedAt

  const confirmationDialogTitle = isUpdatingPlan
    ? t('schedule.confirmUpdatePlanDialog.title')
    : t('schedule.confirmSubmitPlanDialog.title')

  const confirmationDialogDescription = isUpdatingPlan
    ? t('schedule.confirmUpdatePlanDialog.description')
    : t('schedule.confirmSubmitPlanDialog.description')

  const confirmationDialogConfirmLabel = isUpdatingPlan
    ? t('schedule.confirmUpdatePlanDialog.confirm')
    : t('schedule.confirmSubmitPlanDialog.confirm')

  const label = needsConfirmation
    ? t(`planning.changePlanState.${state}`)
    : t('planning.submitPlan')

  const {
    submitButtonLabel,
    submitDisabled,
    onClickSubmitButton,
  } = isShowingSchedule
    ? {
        submitButtonLabel: label,
        submitDisabled: !canSubmitPlan,
        onClickSubmitButton: () => {
          if (isUnderMinimumLaborBaseline) {
            setIsMinLbrViolationDialog(true)
            return
          }
          setIsShowingConfirmationDialog(true)
        },
      }
    : {
        submitButtonLabel: t('planning.proceedToSchedule'),
        submitDisabled: false,
        onClickSubmitButton: showSchedule,
      }

  const scheduleIsClearable =
    state === 'in_progress' && !submittedAt && shifts.length > 0

  const onMinLbrAlternativeAction = async () => {
    onHideMinLbrViolationDialog()
    onSubmitPlan(notifyOnlyImpactedEmployees)
  }

  return (
    <>
      <WhiteFixedFooter>
        <FooterButtonContainer>
          {scheduleIsClearable && (
            <ClearScheduleButton
              databaseId={databaseId}
              hideSchedule={hideSchedule}
            />
          )}
        </FooterButtonContainer>

        <ActionButton
          onClick={onClickSubmitButton}
          variant="blue"
          disabled={submitDisabled}
        >
          <Spinner animation="border" size="sm" hidden={!submitPlanLoading} />{' '}
          {submitButtonLabel}
        </ActionButton>

        {/* A little weird, but simplifies the layout  */}
        <FooterButtonContainer>
          {scheduleIsClearable && ' '}
        </FooterButtonContainer>
      </WhiteFixedFooter>
      <MinimumLaborBaselineViolation
        show={isMinLbrViolationDialog}
        onHide={onHideMinLbrViolationDialog}
        onConfirm={onMinLbrAlternativeAction}
        violations={minimumLaborBaselineViolations}
      />

      <ConfirmationDialog
        show={isShowingConfirmationDialog}
        onHide={hideDialog}
        title={confirmationDialogTitle}
        description={
          isUpdatingPlan ? (
            <>
              <p>
                <strong>{confirmationDialogDescription}</strong>
              </p>
              <Check
                id="onlyImpactedEmployees"
                label={t(
                  'schedule.confirmUpdatePlanDialog.onlyImpactedEmployees'
                )}
                styleContainer={onlyImpactedEmployeesCheckStyles}
                style={checkStyles}
                inputProps={{
                  type: 'radio',
                  checked: notifyOnlyImpactedEmployees,
                  onChange: handleNotifyOnlyImpactedEmployees,
                }}
              />
              <Check
                id="allEmployees"
                label={t('schedule.confirmUpdatePlanDialog.allEmployees')}
                styleContainer={allEmployeesCheckStyles}
                style={checkStyles}
                inputProps={{
                  type: 'radio',
                  checked: !notifyOnlyImpactedEmployees,
                  onChange: handleNotifyOnlyImpactedEmployees,
                }}
              />
            </>
          ) : (
            confirmationDialogDescription
          )
        }
        confirmLabel={confirmationDialogConfirmLabel}
        onConfirm={onConfirm}
      />
    </>
  )
}
