import React from 'react'
import { useTranslation } from 'react-i18next'

import { Row } from 'react-bootstrap'
import { StyledContainer, LeftCol, LeftColTitleText, RightCol } from './styles'
import { NavLink } from 'react-router-dom'
import { routeMap } from 'pages/Routes'

const navLinkStyle = {
  color: 'var(--gray2)',
  textDecoration: 'none',
}

const activeStyle = {
  fontWeight: 800,
}

const tabRowStyle = {
  marginRight: '0px',
}

interface Props {
  children: JSX.Element
}

export const InsightsWrapper = ({ children }: Props) => {
  const { t } = useTranslation()

  return (
    <StyledContainer fluid>
      <Row noGutters>
        <LeftCol xs={12} md={2}>
          <LeftColTitleText>
            <NavLink
              to={routeMap.insights.insightsRoot}
              activeStyle={activeStyle}
              style={navLinkStyle}
            >
              {t('insights.sideBar.dashboard')}
            </NavLink>
          </LeftColTitleText>
          <br />
          <Row style={tabRowStyle}>
            <NavLink
              to={routeMap.insights.totalSales}
              className={'ml-3'}
              activeStyle={activeStyle}
              style={navLinkStyle}
            >
              {t('insights.sideBar.total_sales')}
            </NavLink>
          </Row>
          <br />
          <Row style={tabRowStyle}>
            <NavLink
              to={routeMap.insights.baselineComparisons}
              className={'ml-3'}
              activeStyle={activeStyle}
              style={navLinkStyle}
            >
              {t('insights.sideBar.baseline_comparisons')}
            </NavLink>
          </Row>
          <>
            <br />
            <Row style={tabRowStyle}>
              <NavLink
                to={routeMap.insights.averageOrders}
                className={'ml-3'}
                activeStyle={activeStyle}
                style={navLinkStyle}
              >
                {t('insights.sideBar.average_orders')}
              </NavLink>
            </Row>
            <br />
            <Row style={tabRowStyle}>
              <NavLink
                to={routeMap.insights.scheduledActualLabor}
                className={'ml-3'}
                activeStyle={activeStyle}
                style={navLinkStyle}
              >
                {t('insights.sideBar.scheduled_actual_labor')}
              </NavLink>
            </Row>
            <br />
            <Row style={tabRowStyle}>
              <NavLink
                to={routeMap.insights.laborTargetBreakdowns}
                className={'ml-3'}
                activeStyle={activeStyle}
                style={navLinkStyle}
              >
                {t('insights.sideBar.labor_target_breakdowns')}
              </NavLink>
            </Row>
          </>
        </LeftCol>
        <RightCol xs={12} md={10}>
          {children}
        </RightCol>
      </Row>
    </StyledContainer>
  )
}
