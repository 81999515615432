import React from 'react'
import AuthenticatedPage from 'pages/AuthenticatedPage'
import { useTranslation } from 'react-i18next'
import { ManagementWrapper } from 'pages/management/ManagementWrapper'
import { ManagementHeader } from 'pages/management/styles'
import { useHistory, useLocation } from 'react-router-dom'
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit'
import { toast } from 'react-toastify'
import buildingIcon from 'assets/images/building-05-icon.svg'
import mapMarkerIcon from 'assets/images/marker-pin-01-icon.svg'

import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { Organization } from 'config/graphqlTypesRaw'
import { SettingsTableHeader } from '../SettingsTableHeader'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import DataTable from '../../../../components/DataTable/DataTable'
import { DATA_TABLE_PAGE_SIZE_OPTIONS } from '../../../../components/DataTable/dataTableUtils'
import styled from 'styled-components'
import Button from '../../../../components/Button'

export const Organizations = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { authorizedOrganizations } = useLoggedInUserOrRedirect()

  const actionsFormatter = (_: any, row: any) => (
    <ViewOrgButton
      onClick={() => {
        toast.dismiss()
        history.push(`${location.pathname}/${row.id}`)
      }}
      block
      size="sm"
      variant="primary"
      type="button"
    >
      <ButtonText>{t('management.settings.actions.viewBtn')}</ButtonText>
    </ViewOrgButton>
  )

  const namesFormatter = (name: string) => {
    return (
      <NameContainer>
        <OrgIcon alt="" src={buildingIcon} />
        <OrgName>{name}</OrgName>
      </NameContainer>
    )
  }

  const locationsFormatter = (_: any, row: Organization) => (
    <LocationsContainer>
      <MarkerIcon alt="" src={mapMarkerIcon} />
      <LocationsCount>{row.locationsCount}</LocationsCount>
    </LocationsContainer>
  )

  const columns = [
    {
      dataField: 'name',
      text: t('management.settings.organizations.name'),
      sort: false,
      headerStyle: () => ({ width: '60%' }),
      formatter: namesFormatter,
    },
    {
      dataField: 'locations',
      text: t('settings.employeeSettings.locations'),
      headerStyle: () => ({ width: '30%' }),
      formatter: locationsFormatter,
    },
    {
      dataField: 'actions',
      text: t('management.settings.actions.label'),
      sort: false,
      headerStyle: () => ({ width: '10%' }),
      formatter: actionsFormatter,
    },
  ]

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeader>
            {t('management.settings.organizations.label')}
          </ManagementHeader>
          <OrgsContainer>
            <ToolkitProvider
              keyField="id"
              data={authorizedOrganizations}
              columns={columns}
              search
            >
              {(props: ToolkitContextType) => (
                <>
                  <SettingsTableHeader searchProps={props.searchProps} />
                  <DataTable
                    {...props.baseProps}
                    keyField="id"
                    striped
                    hover
                    minHeight={'600px'}
                    filter={filterFactory()}
                    pagination={paginationFactory({
                      sizePerPageList: DATA_TABLE_PAGE_SIZE_OPTIONS,
                    })}
                  />
                </>
              )}
            </ToolkitProvider>
          </OrgsContainer>
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}

const OrgsContainer = styled.div`
  padding-top: 20px;
`

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const OrgIcon = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`

const OrgName = styled.span`
  color: var(--gray1);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 14px;
`

const LocationsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const MarkerIcon = styled.img`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`

const LocationsCount = styled.span`
  color: var(--gray1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`

const ViewOrgButton = styled(Button)({
  maxWidth: '80%',
  minWidth: '80px',
})

const ButtonText = styled.span`
  color: var(--white);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 15px;
`
