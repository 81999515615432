import React from 'react'
import { DateTime } from 'luxon'
import { PointTooltipProps } from '@nivo/line'
import { useTranslation } from 'react-i18next'

import { formattedDollars } from 'utils/currency'
import { ValueDescription } from 'components/Chart/Tooltip/ValueDescription'
import { Tooltip } from 'components/Chart/Tooltip/Tooltip'
import { DailyChartDatum } from 'pages/plan/dailyViewModal/useMakeChartData'
import getFormattedHours from 'utils/getFormattedHours'
import useWindowWidth from 'utils/useWindowWidth'
import {
  fullWidth,
  LaborTooLowNote,
  LaborTooLowDot,
  LaborTooLowLabel,
} from 'pages/plan/dailyViewModal/styles'
import { Col, Row } from 'react-bootstrap'

export const DailyViewTooltip = (
  getDisplayTimestamp: (dateTime: DateTime) => string,
  getMinimumLaborBaseline: (time: DateTime) => number
) =>
  function TooltipComponent({ point }: PointTooltipProps) {
    const { t } = useTranslation()

    const data = (point.data as unknown) as DailyChartDatum

    const time = point.data.x as Date
    const dateTime = DateTime.fromISO(time.toISOString())
    const hourStart = getDisplayTimestamp(dateTime)
    const hourEnd = getDisplayTimestamp(dateTime.plus({ hours: 1 }))
    const title = t('charts.tooltip.dailyTitle', { hourStart, hourEnd })

    const dollars = formattedDollars(data.tooltipInfo.dollars)
    const hours = getFormattedHours({
      hours: data.tooltipInfo.hours,
      format: '0.[00]',
      t,
    })
    const employeeCount = data.tooltipInfo.employeeCount
    const orderCount = String(Math.round(data.tooltipInfo.orderCount))

    const width = useWindowWidth()
    const upsideDown = width >= fullWidth && point.y < 120

    const minimumLaborBaseline = getMinimumLaborBaseline(dateTime)

    const laborIsTooLow = employeeCount < minimumLaborBaseline
    const laborCountsColor = laborIsTooLow ? 'bittersweet' : 'iris-blue'

    return (
      <Tooltip
        title={title}
        upsideDown={upsideDown}
        hasErrorNote={laborIsTooLow}
      >
        <>
          <Row>
            <Col>
              <ValueDescription
                transKey="charts.tooltip.forecastedSales"
                formattedValue={dollars}
                labelColor="blue-light"
                valueColor="blue-light"
              />
              <ValueDescription
                transKey="charts.tooltip.forecastedOrders"
                formattedValue={orderCount}
                labelColor="blue-light"
                valueColor="blue-light"
              />
            </Col>
            <Col>
              <ValueDescription
                transKey="charts.tooltip.plannedLabor"
                formattedValue={hours}
                labelColor="iris-blue"
                valueColor={laborCountsColor}
              />
              <ValueDescription
                transKey="charts.tooltip.employeeCount"
                formattedValue={`${employeeCount}`}
                labelColor="iris-blue"
                valueColor={laborCountsColor}
              />
            </Col>
          </Row>
          {laborIsTooLow && (
            <LaborTooLowNote>
              <LaborTooLowDot color={'var(--bittersweet)'}>!</LaborTooLowDot>
              <LaborTooLowLabel>
                {t('charts.tooltip.employeesRequired', {
                  employeesRequired: minimumLaborBaseline,
                })}
              </LaborTooLowLabel>
            </LaborTooLowNote>
          )}
        </>
      </Tooltip>
    )
  }
