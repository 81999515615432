import React, { FC, useState } from 'react'
import filterFactory from 'react-bootstrap-table2-filter'
import { uniqBy, remove } from 'lodash'
import DataTable from 'components/DataTable/DataTable'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { ENABLE_EMPLOYEE_DELETE } from 'pages/management/employees/DeleteEmployee'
import { EmployeeListingForLocation } from 'pages/management/employees/Employees'

const PAGE_SIZE_OPTIONS = [20, 50, 100].map(n => ({
  text: n.toString(),
  value: n,
}))

interface Props {
  employees: EmployeeListingForLocation[]
  columns: ColumnDescription[]
  hasSettingsPermission: boolean
  onSelectEmployees: (employees: EmployeeListingForLocation[]) => void
  locationId: string
  currentPage: number
  setCurrentPage: (page: number) => void
}

export const EmployeesDataTable: FC<Props> = ({
  employees,
  columns,
  onSelectEmployees,
  hasSettingsPermission,
  currentPage,
  setCurrentPage,
  ...props
}) => {
  const [selectedEmployees, setSelectedEmployees] = useState<
    EmployeeListingForLocation[]
  >([])
  const rowStyle = ({ active }: EmployeeListingForLocation) =>
    !active ? { color: 'var(--gray4)' } : {}

  const canDeleteEmployee = ENABLE_EMPLOYEE_DELETE && hasSettingsPermission

  const selectRow = {
    mode: 'checkbox' as const,
    clickToSelect: true,
    hideSelectColumn: !canDeleteEmployee,
    hideSelectAll: !canDeleteEmployee,
    onSelect: (row: EmployeeListingForLocation, isSelect: boolean) => {
      if (isSelect) {
        const employees = uniqBy([...selectedEmployees, row], 'id')
        setSelectedEmployees(employees)
        onSelectEmployees(employees)
      } else {
        const employeesCopy = [...selectedEmployees]
        remove(employeesCopy, employee => employee.id === row.id)
        setSelectedEmployees(employeesCopy)
        onSelectEmployees(employeesCopy)
      }
    },
    onSelectAll: (isSelect: boolean, rows: EmployeeListingForLocation[]) => {
      if (isSelect) {
        const employees = uniqBy([...selectedEmployees, ...rows], 'id')
        setSelectedEmployees(employees)
        onSelectEmployees(employees)
      } else {
        setSelectedEmployees([])
        onSelectEmployees([])
      }
    },
  }
  return (
    <DataTable
      {...props}
      keyField="id"
      striped
      hover
      minHeight={'600px'}
      data={employees}
      columns={columns}
      selectRow={selectRow}
      rowStyle={rowStyle}
      filter={filterFactory()}
      pagination={paginationFactory({
        sizePerPageList: PAGE_SIZE_OPTIONS,
        onPageChange: setCurrentPage,
        page: currentPage,
      })}
    />
  )
}
