import React from 'react'
import { createRoot } from 'react-dom/client'
import 'react-app-polyfill/stable'

import App from 'App'
import * as serviceWorker from 'serviceWorker'
import 'config/i18next'

import 'index.css'
import 'theme.scss'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import 'react-toastify/dist/ReactToastify.min.css'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  //TODO: turn on strict mode when we're ready. It'll help us find potential problems in the app
  // See https://reactjs.org/docs/strict-mode.html
  //<React.StrictMode>
  <App />
  //</React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
