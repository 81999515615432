import React, { Component, ComponentType, ReactNode } from 'react'

interface NonBugsnagErrorBoundaryProps {
  FallbackComponent: ComponentType
  children?: ReactNode
}

interface NonBugsnagErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

export class NonBugsnagErrorBoundary extends Component<
  NonBugsnagErrorBoundaryProps,
  NonBugsnagErrorBoundaryState
> {
  constructor(props: NonBugsnagErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch() {}

  render() {
    const { FallbackComponent, children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return <FallbackComponent />
    }

    return children
  }
}
