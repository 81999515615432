import React from 'react'
import { useTranslation } from 'react-i18next'
import { asDollars } from 'utils/currency'
import { MultiRowPlanRow } from 'pages/plan/rows/PlanRow'
import { usePlanPageContext } from '../planPageContext'

export const BenchmarksRow = () => {
  const { t } = useTranslation()
  const { plan } = usePlanPageContext()
  const {
    lastMonthSameDayReportedSales,
    lastYearSameDayReportedSales,
    lastWeekSameDayReportedSales,
  } = plan

  const benchmarkDataRow = (data: { reportedSales: any }[]) => {
    let sum = 0
    sum = data.reduce((sum, { reportedSales }) => sum + reportedSales, 0)

    return [
      ...data.map(({ reportedSales }) =>
        reportedSales ? asDollars(reportedSales) : '-'
      ),
      sum ? asDollars(sum) : '-',
    ]
  }

  const dataRows = [
    ['', '', '', '', '', '', '', ''],
    benchmarkDataRow(lastWeekSameDayReportedSales),
    benchmarkDataRow(lastMonthSameDayReportedSales),
    benchmarkDataRow(lastYearSameDayReportedSales),
  ]

  const subtitles = [
    t('planning.lastWeek'),
    t('planning.lastMonth'),
    t('planning.lastYear'),
  ]

  return (
    <MultiRowPlanRow
      title={t('planning.benchmarks')}
      subsubtitle={t('planning.sameDayOfWeek')}
      subtitles={subtitles}
      dataRows={dataRows}
    />
  )
}
