import React, { FC } from 'react'
import { useDrop } from 'react-dnd'
import { Interval } from 'luxon'
import { includes } from 'lodash/fp'

import plusIcon from 'assets/images/plus-grey.svg'
import {
  ActiveLocationShiftRoleFragment,
  ShiftFragment,
  ShiftRoleFragment,
  ViolationFragment,
} from 'config/graphqlTypes'
import { GraphLabelText } from 'components/Text'
import {
  isRowEntityEmployee,
  isRowEntityShiftRole,
  isRowEntityUnavilability,
  RowEntity,
} from 'pages/plan/scheduling/RowEntity'
import { DragShift, DragShiftResult } from 'pages/plan/scheduling/dragShift'
import { ShiftCard } from 'pages/plan/scheduling/ShiftCard'
import {
  StyledAddShiftButton,
  StyledAddShiftButtonSmall,
  StyledScheduleCell,
  StyledShiftCardBlock,
  StyledAddShiftButtonLabel,
  ShiftHoverIndicator,
} from 'pages/plan/scheduling/styles'
import { isAllDay } from 'utils/daysAndTimes'
import { useTranslation } from 'react-i18next'
import { ShiftModal } from 'pages/plan/scheduling/ShiftModal'
import { useShiftModal } from 'pages/plan/scheduling/useShiftModal'
import { usePlanPageContext } from '../planPageContext'

interface Props {
  days: Interval[]
  rowEntity: RowEntity
  shifts: ShiftFragment[]
  violations: ViolationFragment[]
  day: Interval
  isFirstRow: boolean
  maxDayShifts: number
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export const ScheduleCell: FC<Props> = ({
  days,
  rowEntity,
  shifts,
  day,
  violations,
  isFirstRow,
  maxDayShifts,
  activeLocationShiftRoles,
}) => {
  const date = day.start
  const { t } = useTranslation()
  const { overlapWithLocationClosedDays } = usePlanPageContext()
  const [{ isHovering }, dropRef] = useDrop<
    DragShift,
    DragShiftResult,
    { isHovering: boolean }
  >({
    accept: ['shift'],
    canDrop: item => !includes(item.shift)(shifts),
    collect: monitor => ({
      isHovering: monitor.canDrop() && monitor.isOver(),
    }),
    drop: () => ({ date, rowEntity }),
  })

  const isTimeAvailable = !shifts.some(s => isAllDay(s.startAt, s.endAt))
  const AddShiftButton =
    maxDayShifts - shifts.length >= 1
      ? StyledAddShiftButton
      : StyledAddShiftButtonSmall

  const selectedEmployee = isRowEntityEmployee(rowEntity) ? rowEntity : null
  const selectedShiftRole =
    isRowEntityShiftRole(rowEntity) || isRowEntityUnavilability(rowEntity)
      ? (rowEntity as ShiftRoleFragment)
      : null

  const {
    showShiftModal,
    selectedShift,
    onClickShift,
    onClickAddShift,
    onHideShiftModal,
  } = useShiftModal()

  const allowAddShift =
    !isHovering && isTimeAvailable && !overlapWithLocationClosedDays(day)

  return (
    <StyledScheduleCell isFirstRow={isFirstRow} ref={dropRef}>
      <StyledShiftCardBlock>
        {shifts
          .sort(
            (s1, s2) =>
              Date.parse(`${s1.startAt}`) - Date.parse(`${s2.startAt}`)
          )
          .map(shift => (
            <ShiftCard
              violations={violations}
              shift={shift}
              key={shift.id}
              onClick={() => onClickShift(shift)}
            />
          ))}
        {allowAddShift && (
          <AddShiftButton onClick={onClickAddShift}>
            <img src={plusIcon} alt="" />
            <StyledAddShiftButtonLabel>
              {t('schedule.addShift')}
            </StyledAddShiftButtonLabel>
          </AddShiftButton>
        )}
        {isHovering && (
          <ShiftHoverIndicator>
            <GraphLabelText>{t('schedule.hover')}</GraphLabelText>
          </ShiftHoverIndicator>
        )}
      </StyledShiftCardBlock>
      {showShiftModal && (
        <ShiftModal
          show={showShiftModal}
          onHide={onHideShiftModal}
          selectedEmployee={selectedEmployee}
          selectedShiftRole={selectedShiftRole}
          selectedDate={date}
          selectedShift={selectedShift}
          days={days}
          activeLocationShiftRoles={activeLocationShiftRoles}
        />
      )}
    </StyledScheduleCell>
  )
}
