import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
// import { Row, Badge } from 'react-bootstrap'
import { H3Text } from 'components/Text'
import Col from 'react-bootstrap/Col'
import { sectionBorder } from '../../config/styles'

export const StyledContainer = styled(Container)({
  paddingLeft: 0,
  paddingRight: 0,
})

export const Wrapper = styled.div(() => ({
  width: '100%',
  height: '50%',
}))

export const LeftCol = styled(Col)({
  backgroundColor: 'var(--gray7)',
  paddingLeft: '40px !important',
  '@media only screen and (min-width: 768px)': {
    minHeight: '100vh',
    borderRight: sectionBorder,
    paddingTop: 30,
    paddingBottom: 30,
  },
  '@media only screen and (max-width: 768px)': {
    borderBottom: sectionBorder,
    paddingTop: 20,
    paddingBottom: 20,
  },
})

export const RightCol = styled(Col)({
  paddingTop: 28,
  paddingBottom: 28,
  paddingLeft: '40px !important',
  paddingRight: '40px !important',
})

export const LeftColTitleText = styled(H3Text)({
  color: 'var(--gray4)',
  '@media only screen and (min-width: 768px)': {
    marginBottom: 60,
  },
  '@media only screen and (max-width: 767px)': {
    marginBottom: 25,
  },
  '@media only screen and (max-width: 1140px)': {
    fontSize: '1.1rem',
    fontWeight: 800,
  },
})
