import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  IntervalOrderSumFragment,
  IntervalPredictionSumFragment,
} from 'config/graphqlTypes'
import { asDollars } from 'utils/currency'
import { useCurrentLocationCategories } from 'queries/useCurrentLocation'
import sumOrNull from 'utils/sumOrNull'
import { Cell, TableHeader } from 'pages/tracking/breakdown/styles'
import {
  Table,
  PredictionLabel,
  ActualLabel,
} from 'pages/tracking/breakdown/Table'

interface Props {
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  salesType: string
}

interface RowProps {
  title: string
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  salesType: string
  allTotals?: boolean
}

const Row = ({
  orderSums,
  predictionSums,
  title,
  salesType,
  allTotals,
}: RowProps) => (
  <tr>
    <Cell category total={allTotals}>
      {title}
    </Cell>
    <Cell
      total={allTotals}
      title={asDollars(
        sumOrNull(predictionSums.map(p => p.reportedSales)),
        true
      )}
    >
      {asDollars(sumOrNull(predictionSums.map(p => p.reportedSales)))}
    </Cell>
    <Cell
      actual
      total={allTotals}
      title={asDollars(sumOrNull(orderSums.map(o => o.reportedSales)), true)}
    >
      {asDollars(sumOrNull(orderSums.map(o => o.reportedSales)))}
    </Cell>
  </tr>
)

export const PreviousTable = ({
  orderSums,
  predictionSums,
  salesType,
}: Props) => {
  const { t } = useTranslation()

  const { categories, categoryNames } = useCurrentLocationCategories()
  const orderSum = (categoryName: string) =>
    orderSums.filter(s => categories[categoryName].indexOf(s.category) > -1)
  const predictionSum = (categoryName: string) =>
    predictionSums.filter(
      s => categories[categoryName].indexOf(s.category) > -1
    )
  return (
    <Table
      title={t('tracking.todaysProgress.breakdownSales')}
      headers={
        <>
          <TableHeader>
            <PredictionLabel />
          </TableHeader>
          <TableHeader>
            <ActualLabel />
          </TableHeader>
        </>
      }
      rows={
        <>
          {categoryNames.map((categoryName: string) => (
            <Row
              key={`${categories[categoryName].join('-')}-sales`}
              title={categoryName}
              orderSums={orderSum(categoryName)}
              predictionSums={predictionSum(categoryName)}
              salesType={salesType}
            />
          ))}
          <Row
            allTotals
            orderSums={orderSums}
            predictionSums={predictionSums}
            title={t('tracking.todaysProgress.breakdownTotal')}
            salesType={salesType}
          />
        </>
      }
    />
  )
}
