import styled from 'styled-components'
import { Row, Col, Badge } from 'react-bootstrap'
import {
  tableLabelBoldTextStyle,
  regularBodyBoldTextStyle,
  regularBodyTextStyle,
} from 'config/styles'

export const ManageHeaderRow = styled(Row)({
  ...tableLabelBoldTextStyle,
  alignItems: 'center',
  paddingTop: 30,
})

export const NoRequestsMessage = styled.div({
  padding: '30px',
  textAlign: 'center',
})

export const TimeSlotsContainer = styled.div<{
  isManagementHistory?: boolean
}>(({ isManagementHistory }) => {
  const minWidthMedia = isManagementHistory ? '1420px' : '1500px'
  const paddingRight = isManagementHistory ? 60 : 85

  return {
    display: 'flex',
    flexDirection: 'column',
    [`@media only screen and (min-width: ${minWidthMedia})`]: {
      paddingRight,
    },
  }
})

export const StyledCol = styled(Col)({
  wordBreak: 'break-word',
  '@media only screen and (max-width: 842px)': {
    paddingTop: '20px',
  },
})

export const StyledSubmitLabel = styled.span({
  whiteSpace: 'nowrap',
})

export const AvailabilityScheduleEntry = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

export const ScheduleDayRow = styled.div({
  textAlign: 'right',
})

export const ShiftSwapDescriptionContainer = styled.div({
  ...regularBodyTextStyle,
})

export const ShiftSwapDescriptionDetail = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: 10,
  lineHeight: 1.5,
})

export const ViolationsIndicator = styled(Badge)({
  ...regularBodyBoldTextStyle,
  height: 20,
  width: 20,
  lineHeight: 0.9,
  borderRadius: '50%',
  backgroundColor: 'var(--bittersweet)',
  color: 'var(--white)',
  marginRight: 10,
})

export const ViolationDetails = styled.div({
  ...regularBodyBoldTextStyle,
  color: 'var(--bittersweet)',
})

export const ViolationDetailsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
})

export const StyledShiftSwapDeclinedExplanation = styled.div({
  ...regularBodyTextStyle,
  color: 'var(--gray3)',
  fontSize: 12,
  fontWeight: 800,
})
