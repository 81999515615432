import styled from 'styled-components'

import { Cell, MAKE_ROWS_STICKY } from 'pages/plan/styles'
import { TableLabelBoldText, TableLabelText } from 'components/Text'
import { basePredictionValueStyle } from 'pages/plan/Prediction/styles'

export const PREDICTION_ROW_HEIGHT = 125
const topRowStyle = (isShowingSchedule: boolean) => ({
  '@media screen': {
    [MAKE_ROWS_STICKY]: {
      position: !isShowingSchedule
        ? ('sticky' as 'sticky')
        : ('static' as 'static'),
      top: 0,
    },
    filter: 'drop-shadow(1px 4px 2px rgba(0,0,0,0.20))',
  },
})
export const PlanningCell = styled(Cell)({
  color: 'var(--gray3)',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '& p': {
    marginBottom: 0,
  },
  'p:empty': {
    opacity: 0,
  },
  'p:empty::before': {
    content: '"_"',
  },
  '*:first-child': {
    marginTop: 0,
  },
  '*:not(:first-child)': {
    marginTop: 12,
  },
})
export const TitleCell = styled(PlanningCell)({
  alignItems: 'flex-start !important',
  textAlign: 'left',
})
export const SubSubTitleText = styled.div({
  fontSize: 8,
})
export const PlanningCellBodyText = TableLabelBoldText
export const PlanningCellText = styled(TableLabelText)<{
  indent?: boolean
  isSmallMarginTop?: boolean
  color?: string
}>(({ indent, isSmallMarginTop, color }) => ({
  fontWeight: 500,
  color,
  ...(isSmallMarginTop ? { marginTop: '3px !important' } : {}),
  ...(indent ? { marginLeft: 15 } : {}),
}))
export const PredictionTitleCell = styled(TitleCell)<{
  isShowingSchedule: boolean
}>(({ isShowingSchedule }) => ({
  color: 'var(--gray1)',
  fontSize: 18,
  whiteSpace: 'nowrap',
  '@media print': {
    fontSize: 12,
  },
  fontWeight: 800,
  lineHeigth: 20,
  ...topRowStyle(isShowingSchedule),
}))
export const PredictionSubTitleCell = styled.div<{ fontSize?: string }>(
  ({ fontSize }) => ({
    fontSize: fontSize || 12,
    fontWeight: 600,
    paddingLeft: 15,
    paddingRight: 15,
    margin: '12px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '@media print': {
      padding: 0,
      fontSize: 10,
    },
  })
)
export const StyledPredictionCell = styled(Cell)<{
  isShowingSchedule: boolean
}>(({ isShowingSchedule }) => ({
  paddingLeft: 15,
  paddingRight: 15,
  justifyContent: 'center',
  ...topRowStyle(isShowingSchedule),
}))
export const WeeklyTotalAmount = styled.p({
  ...basePredictionValueStyle,
  marginBottom: 0,
})
export const PLANNING_TABLE_HEADER_HEIGHT = '2.1875rem'
export const StyledPlanningTableHeadCell = styled.th({
  background: 'var(--gray6)',
  border: 'none',
  borderLeft: '1px solid var(--white)',
  borderRight: '1px solid var(--white)',
  height: PLANNING_TABLE_HEADER_HEIGHT,
  '@media screen': {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
  },
  '@media print': {
    textAlign: 'center',
  },
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '0.9rem',
  fontWeight: 900,
  color: 'var(--gray3)',
  top: 0,
  '&:first-of-type': {
    borderLeft: 'none',
  },
  '&:last-of-type': {
    borderRight: 'none',
  },
})

export const ExpandChevronIcon = styled.a({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  height: 0,
})

export const ExpandChevronContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignContent: 'center',
  width: '100%',
  cursor: 'pointer',
  margin: '12px',
  paddingLeft: 15,
})

export const ExpandChevronLabel = styled.div({
  color: 'var(--buzzy-blue)',
  fontWeight: 800,
  fontSize: '12px',
  marginRight: '10px',
})

export const ReviewPredictionTitle = styled.div({
  marginTop: '30px !important',
})
