import React, { FC, useState, useRef, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { flow, round } from 'lodash'
import { replace as fReplace } from 'lodash/fp'
import { DateTime } from 'luxon'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { PlanFragment, useUpdatePlanMutation } from 'config/graphqlTypes'
import {
  LaborSettingsTitle,
  SettingsWrapper,
  PercentRevenueWrapper,
  StyledStringInput,
} from 'pages/plan/header/styles'
import { StringInput } from 'components/Form'
import { usePlanQueryVariables } from '../planQueryVariablesContext'
import { useGetPlan } from '../../../queries/useGetPlan'
import styled from 'styled-components'

export const formatNewPercent = (newPercent: string) =>
  flow(
    fReplace(/%|[^0-9.,]+/g, ''),
    s => Number(s) || 1,
    (n: number) => Math.max(n, 1),
    (n: number) => Math.min(n, 100),
    (n: number) => round(n, 1)
  )(newPercent)

export const formatNewAverageWage = (newAverageWage: string) =>
  flow(
    fReplace(/\$|[^0-9.,]+/g, ''),
    s => Number(s) || 1,
    (n: number) => round(n, 2)
  )(newAverageWage)

type PartialPlanProps = Pick<
  PlanFragment,
  'laborPercentOfRevenue' | 'averageWage'
>

interface Props {
  partialPlan: PartialPlanProps
  disableSettings: boolean
  disableLaborSettings: boolean
  startDate: DateTime
}

export const LaborSettings: FC<Props> = ({
  partialPlan: { laborPercentOfRevenue, averageWage },
  disableLaborSettings,
  startDate,
}) => {
  const { t } = useTranslation()
  const { location } = useCurrentLocation()
  const [percentageOfRevenue, setPercentageOfRevenue] = useState<number>(
    laborPercentOfRevenue
  )

  const percentRef = useRef<HTMLInputElement>(null)

  const onPercentageOfRevenueChange = (e: ChangeEvent<HTMLInputElement>) =>
    setPercentageOfRevenue(formatNewPercent(e.target.value))

  const formatPercentageOfRevenueValue = percentageOfRevenue.toString() + '%'

  const planQueryVariables = usePlanQueryVariables()
  const { startOn, endOn } = planQueryVariables
  const { refetch: refetchPlan } = useGetPlan(startOn, endOn)
  const [
    updatePlanSettingsMutation,
    { error: updatePlanError },
  ] = useUpdatePlanMutation({
    variables: {
      input: {
        locationId: location.id,
        startOn: startDate,
        averageWage: averageWage,
        laborPercentOfRevenue: percentageOfRevenue,
        notifyOnlyImpactedEmployees: false,
      },
    },
  })

  const updatePlanSettings = async () => {
    await updatePlanSettingsMutation()
    !updatePlanError && refetchPlan()
  }

  const onPercentageOfRevenueBlur = () => {
    if (percentRef.current) {
      percentRef.current.value = formatPercentageOfRevenueValue
    }
    updatePlanSettings()
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toUpperCase() === 'ENTER') {
      updatePlanSettings()
    }
  }

  return (
    <Container>
      <LaborSettingsTitle>
        {t('planning.laborSettings.title')}
      </LaborSettingsTitle>
      <SettingsWrapper>
        <PercentRevenueWrapper>
          <span>{t('planning.laborSettings.percentOfRevenue')}</span>
          <StringInput
            testId={t('planning.percentageOfRevenue')}
            controlProps={{
              as: StyledStringInput,
              disabled: disableLaborSettings,
              defaultValue: formatPercentageOfRevenueValue,
              onBlur: onPercentageOfRevenueBlur,
              onChange: onPercentageOfRevenueChange,
              onKeyPress: onKeyPress,
            }}
            ref={percentRef}
          />
        </PercentRevenueWrapper>
      </SettingsWrapper>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})
