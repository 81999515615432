import React from 'react'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  AvailabilityRequestsCounterContainer,
  LeftCol,
  LeftColTitleText,
  LeftColSubTitleText,
  ManagementTextContainer,
  RightCol,
  StyledContainer,
} from './styles'
import {
  getLocationNavigationPath,
  routeMap,
  useLocationNavigation,
} from 'pages/Routes'
import {
  useCurrentLocationId,
  useTryCurrentLocation,
} from 'queries/useCurrentLocation'
import { NavLink } from 'react-router-dom'
import { AvailabilityRequestsCounter } from './availabilityRequests/availabilityRequestsCounter'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { useFeatureToggles } from '../../contexts/FeatureToggles'
interface Props {
  children: JSX.Element
}

export const ManagementWrapper = ({ children }: Props) => {
  const { t } = useTranslation()
  const toggles = useFeatureToggles()
  const { authorizedOrganizations, locations } = useLoggedInUserOrRedirect()
  const { locationPath: currentLocationPath } = useLocationNavigation()
  const currentLocationId = useCurrentLocationId()
  const locationId = locations[0]?.id
  const hasCurrentLocation = !!currentLocationId
  const resolvedLocationPath = hasCurrentLocation
    ? currentLocationPath
    : getLocationNavigationPath(locationId)
  const locationPos = useTryCurrentLocation()?.pos
  const hideNavLinks = !hasCurrentLocation && !locationId
  const showIntegrationSettingsNavLink = locationPos === 'square'

  const gmSettingsToggle = toggles.features.find(t => t.name === 'gmSettings')

  const showOrganizationLink =
    authorizedOrganizations.length && gmSettingsToggle?.active

  const navLinkStyle = {
    color: 'var(--gray2)',
    textDecoration: 'none',
  }

  const activeStyle = {
    fontWeight: 800,
  }

  const IntegrationSettingsNavLink = ({ link }: { link: string }) => (
    <Row>
      <NavLink
        to={link}
        className={'ml-3'}
        activeStyle={activeStyle}
        style={navLinkStyle}
      >
        {t('management.sideBar.integrations')}
      </NavLink>
    </Row>
  )

  return (
    <StyledContainer fluid>
      <Row noGutters>
        <LeftCol xs={12} md={2}>
          <LeftColTitleText>
            {t('management.sideBar.management')}
          </LeftColTitleText>
          {hideNavLinks ? (
            <IntegrationSettingsNavLink link="#" />
          ) : (
            <>
              <Row>
                <NavLink
                  to={
                    resolvedLocationPath +
                    routeMap.location.management.employees
                  }
                  className={'ml-3'}
                  activeStyle={activeStyle}
                  style={navLinkStyle}
                >
                  {t('management.sideBar.employees')}
                </NavLink>
              </Row>
              <br />
              <Row>
                <NavLink
                  to={
                    resolvedLocationPath + routeMap.location.management.messages
                  }
                  className={'ml-3'}
                  activeStyle={activeStyle}
                  style={navLinkStyle}
                >
                  {t('management.sideBar.messages')}
                </NavLink>
              </Row>
              {showIntegrationSettingsNavLink && (
                <>
                  <br />
                  <IntegrationSettingsNavLink
                    link={`${resolvedLocationPath +
                      routeMap.location.management.integrationSettings}`}
                  />
                </>
              )}
              <br />
              <Row>
                <NavLink
                  to={
                    resolvedLocationPath + routeMap.location.management.requests
                  }
                  className={'ml-3'}
                  activeStyle={activeStyle}
                  style={navLinkStyle}
                >
                  <AvailabilityRequestsCounterContainer>
                    <ManagementTextContainer>
                      {t('management.sideBar.requests')}
                    </ManagementTextContainer>
                    <AvailabilityRequestsCounter />
                  </AvailabilityRequestsCounterContainer>
                </NavLink>
              </Row>
              <br />
              <Row>
                <NavLink
                  to={
                    resolvedLocationPath +
                    routeMap.location.management.requestHistory
                  }
                  className={'ml-5'}
                  activeStyle={activeStyle}
                  style={navLinkStyle}
                >
                  {t('management.sideBar.requestHistory')}
                </NavLink>
              </Row>
              <>
                <LeftColSubTitleText>
                  {t('management.sideBar.settings')}
                </LeftColSubTitleText>
                {showOrganizationLink && (
                  <>
                    <Row>
                      <NavLink
                        to={
                          resolvedLocationPath +
                          routeMap.management.organizations
                        }
                        className={'ml-3'}
                        activeStyle={activeStyle}
                        style={navLinkStyle}
                      >
                        {t('management.sideBar.organizations')}
                      </NavLink>
                    </Row>
                    <br />
                  </>
                )}
                <Row>
                  <NavLink
                    to={resolvedLocationPath + routeMap.management.locations}
                    className={'ml-3'}
                    activeStyle={activeStyle}
                    style={navLinkStyle}
                  >
                    {t('management.sideBar.locations')}
                  </NavLink>
                </Row>
              </>
            </>
          )}
        </LeftCol>
        <RightCol xs={12} md={10}>
          {children}
        </RightCol>
      </Row>
    </StyledContainer>
  )
}
