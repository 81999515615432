import { DateTime } from 'luxon'
import React from 'react'
import { useGetToucanAuth } from '../../queries/useGetToucanAuth'
import { useToucanUrlScript } from './useToucanUrlScript'
import { InsightsWrapper } from './InsightsWrapper'
import { Loading } from '../../components/Loading'
import styled from 'styled-components'
import { DatePicker, useDatePickerDates } from '../../components/DatePicker'
import { useToucanInsights } from './ToucanInsightsContext'
import { MultiValue } from 'react-select'
import SelectDropdown, {
  SelectDropdownOption,
} from '../../components/SelectDropdown'
import AuthenticatedPage from '../AuthenticatedPage'
import { useTranslation } from 'react-i18next'

const TChart = styled.div({
  height: '100vh',
})

const DatePickerContainer = styled.div({
  cursor: 'pointer',
  maxHeight: 38,
  borderRadius: 4,
  flexShrink: 0,
  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
})

const SelectDropdownContainer = styled.div({
  flexGrow: 1,
})

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 20,
  gap: 20,
})

const controlCustomStyles = {
  border: 'none',
  cursor: 'pointer',
  borderColor: 'transparent',
  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
}

interface Props {
  url?: string
}

const defaultUrl = `https://lineup.toucantoco.com/scripts/embedLauncher.js?id=e027f377-4575-446e-a26c-32ddfaaf3c21&panel=true`

export const ToucanInsights = ({ url = defaultUrl }: Props) => {
  const { t } = useTranslation()
  const {
    startDate,
    endDate,
    dateRangeType,
    locationOptions,
    selectedLocationOptions,
    setDateRangeType,
    setStartDate,
    setEndDate,
    setSelectedLocationOptions,
  } = useToucanInsights()

  const dates = useDatePickerDates(dateRangeType)
  const now = DateTime.now()
  const [start, end] = dates ?? [now, now]
  const { token, loading } = useGetToucanAuth(
    startDate ?? start,
    endDate ?? end,
    selectedLocationOptions.map(option => option?.value!)
  )

  const divRef = useToucanUrlScript(url, token)

  const handleSelectDates = (dates: [DateTime, DateTime]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleSelectLocations = (
    selectedOptions: MultiValue<SelectDropdownOption | null>
  ) => setSelectedLocationOptions(selectedOptions)

  return (
    <AuthenticatedPage>
      <InsightsWrapper>
        <>
          <HeaderContainer>
            <SelectDropdownContainer>
              <SelectDropdown
                isMulti
                allowSelectAll
                options={locationOptions}
                controlStyles={controlCustomStyles}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                placeholder={t('insights.locationsDropdown.placeholder')}
                selectAllOptionTagLabel={t(
                  'insights.locationsDropdown.allLocationsTag'
                )}
                selectAllOptionLabel={t(
                  'insights.locationsDropdown.allLocationsLabel'
                )}
                onChange={handleSelectLocations}
                value={selectedLocationOptions}
              />
            </SelectDropdownContainer>
            <DatePickerContainer>
              <DatePicker
                defaultRangeType={dateRangeType}
                defaultDates={
                  dateRangeType === 'customRange'
                    ? [startDate, endDate]
                    : undefined
                }
                onSelectDates={handleSelectDates}
                onChangeRange={setDateRangeType}
              />
            </DatePickerContainer>
          </HeaderContainer>
          {loading ? <Loading /> : <TChart ref={divRef} />}
        </>
      </InsightsWrapper>
    </AuthenticatedPage>
  )
}
