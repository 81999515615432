import React, { FC } from 'react'
import {
  ExportScheduleOptionsSelect,
  SelectMultiOrSingleValueDropdown,
} from './ExportScheduleOptionsSelect'
import { useTranslation } from 'react-i18next'
import { SelectDropdownOption } from '../../../../components/SelectDropdown'

interface Props {
  departments: SelectDropdownOption[]
  onSelectDepartments: (departments: SelectMultiOrSingleValueDropdown) => void
}

export const ExportScheduleDepartmentSelect: FC<Props> = ({
  departments,
  onSelectDepartments,
}) => {
  const { t } = useTranslation()

  return (
    <ExportScheduleOptionsSelect
      label={t('planning.exportScheduleModal.exportOptions.department.label')}
      onSelectOptions={onSelectDepartments}
      options={departments.length ? departments : []}
      selectAllOptionLabel={t(
        'planning.exportScheduleModal.exportOptions.department.selectAllDepartments'
      )}
      selectAllOptionTagLabel={t(
        'planning.exportScheduleModal.exportOptions.department.allDepartments'
      )}
      testId={'exportScheduleDepartmentSelect'}
    />
  )
}
