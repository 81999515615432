import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import triangleDownIcon from 'assets/images/triangle-down.svg'
import triangleRightIcon from 'assets/images/triangle-right.svg'

const StyledContainer = styled.div({
  width: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

interface Props {
  isExpanded: boolean
  className?: string
}

export const ExpandCollapseIcon = ({ isExpanded, className }: Props) => {
  const { t } = useTranslation()

  const src = isExpanded ? triangleDownIcon : triangleRightIcon
  const alt = isExpanded ? t('misc.collapse') : t('misc.expand')

  return (
    <StyledContainer className={className}>
      <img src={src} alt={alt} />
    </StyledContainer>
  )
}
