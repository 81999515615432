import React, { FC, useRef, useState } from 'react'
import { PDFDocumentProxy } from 'pdfjs-dist'
import { Document, Page, pdfjs } from 'react-pdf'
import { PdfPagesNavigator } from './PdfPagesNavigator'
import styled from 'styled-components'

import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'
import useResizableElementWidth from '../../utils/useResizableElementWidth'

//@ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

type PDFFile = string | File | null | undefined

interface Props {
  file: PDFFile
  isLoading?: boolean
  serverError?: ApolloError
  onLoadError?: (error: Error) => void
  onNumberOfPages?: (numOfPages: number) => void
  onChangePage?: (pageNumber: number) => void
}

export const PdfFileViewer: FC<Props> = ({
  file,
  isLoading,
  serverError,
  onLoadError,
  onNumberOfPages,
  onChangePage,
}) => {
  const [numOfPages, setNumOfPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState(1)

  const documentContainerRef = useRef(null)
  const documentWidth = useResizableElementWidth(documentContainerRef)

  const { t } = useTranslation()

  function onDocumentLoadSuccess({
    numPages: totalNumOfPages,
  }: PDFDocumentProxy) {
    setNumOfPages(totalNumOfPages)
    setPageNumber(1)
    onNumberOfPages?.(totalNumOfPages)
    onChangePage?.(1)
  }

  function onDocumentLoadError(error: Error) {
    onLoadError?.(error)
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
    onChangePage?.(pageNumber + offset)
  }

  const loading = isLoading || (!file && !serverError)

  return (
    <Container>
      <DocumentContainer ref={documentContainerRef}>
        <LoadingOverlayContainer isLoading={loading}>
          {loading && <LoadingLabel>{t('pdfFileViewer.loading')}</LoadingLabel>}
          {!file && !serverError ? (
            <MessageAlertContainer></MessageAlertContainer>
          ) : serverError ? (
            <MessageAlertContainer>
              {t('pdfFileViewer.serverErrorMsg')}
            </MessageAlertContainer>
          ) : (
            <Document
              file={file}
              noData=""
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              error={t('pdfFileViewer.errorMsg')}
            >
              <Page pageNumber={pageNumber} width={documentWidth} />
            </Document>
          )}
        </LoadingOverlayContainer>
      </DocumentContainer>

      <PdfPagesNavigator
        disabled={isLoading}
        containerStyles={pdfPagesNavigatorStyles}
        numberOfPages={numOfPages}
        pageNumber={pageNumber}
        onChangePage={changePage}
      />
    </Container>
  )
}

const pdfPagesNavigatorStyles = { margin: '12px 0 24px', zIndex: 2 }

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
})

const LoadingOverlayContainer = styled.div<{ isLoading: boolean }>`
  height: 100%;

  ${({ isLoading }) =>
    isLoading &&
    `
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 28px;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    pointer-events: none;
  }

  &:before {
    width: 100%;
    height: 100%;
    background-color: rgba(211, 211, 211, 0.8);
  }

  &:after {
    margin: auto;
    width: 32px;
    height: 32px;
    border: 4px solid #4ea6ea;
    border-left-color: transparent;
    border-top-color: transparent;
    border-radius: 50%;
    -webkit-animation: spin 600ms infinite linear;
    animation: spin 600ms infinite linear;
  }

  @-webkit-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  
  `}
`

const MessageAlertContainer = styled.div`
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  height: 100%;
  min-height: 128px;
  text-align: center;
  padding: 24px;
`

const LoadingLabel = styled.span`
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 28px;
  z-index: 2;
  font-size: 12px;
`

const DocumentContainer = styled.div`
  position: relative;
  align-self: stretch;
  flex: 1;

  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .react-pdf__Page {
    flex: 1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }

  .react-pdf__message {
    text-align: center;
    padding: 24px;
  }

  .react-pdf__message--error {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    flex: 1;
  }
`
