import React from 'react'
import { DateTime } from 'luxon'
import { FC } from 'react'
import { flowMax, addProps, addDisplayName, branch, returns } from 'ad-hok'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import addTranslationHelpers from 'utils/addTranslationHelpers'
import { ReactComponent as LeftChevronRaw } from 'assets/images/left-chevron.svg'
import { ReactComponent as RightChevronRaw } from 'assets/images/right-chevron.svg'
import { WeekHeader } from 'components/WeekHeader'

const Navigator = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 25,
  minWidth: 300,
})

const LeftChevron = styled(LeftChevronRaw)({
  marginRight: 15,
  '> path': {
    stroke: 'var(--gray2)',
  },
})

const RightChevron = styled(RightChevronRaw)<{ disabled?: boolean }>(
  ({ disabled }) => ({
    width: 15,
    marginLeft: 9,
    marginRight: 20,
    '> path': {
      stroke: disabled ? 'var(--gray5)' : 'var(--gray2)',
    },
  })
)

interface NextWeekLinkProps {
  disabled: boolean
  path: string
}

const NextWeekLink: FC<NextWeekLinkProps> = flowMax(
  addDisplayName('NextWeekLink'),
  branch(
    ({ disabled }) => disabled,
    returns(() => (
      <span>
        <RightChevron disabled />
      </span>
    ))
  ),
  addTranslationHelpers,
  ({ path, t }) => (
    <Link data-testid={t('planning.nextWeekAlt')} to={path}>
      <RightChevron title={t('planning.nextWeekAlt')} />
    </Link>
  )
)

interface Props {
  startDate: DateTime
  getPath: (weekStartDate: DateTime) => string
  getDisableFutureDates?: (nextStartDate: DateTime) => boolean
  labelTranslationKey: string
  className?: string
}

export const WeekNavigator: FC<Props> = flowMax(
  addDisplayName('WeekNavigator'),
  addProps(({ startDate }) => ({
    previousStartDate: startDate.minus({ weeks: 1 }),
    nextStartDate: startDate.plus({ weeks: 1 }),
    endDate: startDate.endOf('week'),
  })),
  addProps(
    ({ previousStartDate, nextStartDate, getPath, getDisableFutureDates }) => ({
      previousPath: getPath(previousStartDate),
      nextPath: getPath(nextStartDate),
      disableFutureDates: !!getDisableFutureDates?.(nextStartDate),
    })
  ),
  addTranslationHelpers,
  ({
    previousPath,
    nextPath,
    disableFutureDates,
    className,
    t,
    startDate,
    labelTranslationKey,
  }) => (
    <Navigator className={className}>
      <Link data-testid={t('planning.lastWeekAlt')} to={previousPath}>
        <LeftChevron title={t('planning.lastWeekAlt')} />
      </Link>
      <WeekHeader
        startDate={startDate}
        labelTranslationKey={labelTranslationKey}
      />
      <NextWeekLink disabled={disableFutureDates} path={nextPath} />
    </Navigator>
  )
)
