import React from 'react'
import { sumBy } from 'lodash'

import {
  ShiftFragment,
  EmployeeFragment,
  ShiftRoleFragment,
  ActiveLocationShiftRoleFragment,
} from 'config/graphqlTypes'
import { Avatar } from 'components/Avatar'
import { getFirstNameAndLastInitial } from 'utils/getFirstNameAndLastInitial'
import {
  EmployeeName,
  EmployeeNameAvatarWrapper,
} from 'pages/plan/dailyViewModal/schedule/styles'
import { DailyTimelineRow } from 'pages/plan/dailyViewModal/DailyTimelineRow'
import { DayTotalHours } from 'pages/plan/dailyViewModal/schedule/DayTotalHours'
import { EmployeeSchedule } from 'pages/plan/dailyViewModal/schedule/EmployeeSchedule'
import { TimelineHelpers } from 'pages/plan/dailyViewModal/schedule/getGraphTimelineHelpers'
import { Interval } from 'luxon'

interface Props {
  roleShifts: ShiftFragment[]
  otherShifts: ShiftFragment[]
  employee: EmployeeFragment
  role: ShiftRoleFragment
  VerticalLines: JSX.Element
  timelineHelpers: TimelineHelpers
  isEditable: boolean
  day: Interval
  days: Interval[]
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export const EmployeeScheduleRow = ({
  roleShifts,
  otherShifts,
  employee,
  role,
  VerticalLines,
  timelineHelpers,
  isEditable,
  day,
  days,
  activeLocationShiftRoles,
}: Props) => {
  const hours = sumBy(roleShifts, 'displayHours')

  return (
    <DailyTimelineRow
      LeftSection={
        <EmployeeNameAvatarWrapper>
          <Avatar
            name={getFirstNameAndLastInitial(employee)}
            avatarUrl={employee.avatarUrl}
          />
          <EmployeeName>{employee.name}</EmployeeName>
        </EmployeeNameAvatarWrapper>
      }
      MiddleSection={
        <EmployeeSchedule
          employee={employee}
          role={role}
          roleShifts={roleShifts}
          otherShifts={otherShifts}
          VerticalLines={VerticalLines}
          timelineHelpers={timelineHelpers}
          isEditable={isEditable}
          day={day}
          days={days}
          activeLocationShiftRoles={activeLocationShiftRoles}
        />
      }
      RightSection={<DayTotalHours hours={hours} />}
    />
  )
}
