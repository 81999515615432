import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { regularBodyTextStyle, sectionBorder } from 'config/styles'
import { TableLabelBoldText, LargeHeaderText } from 'components/Text'

export const StyledLoadingImage = styled.img({
  marginBottom: 130,
})

export const RightCol = styled(Col)({
  paddingTop: 20,
  '@media only screen and (min-width: 768px)': {
    paddingTop: 0,
    textAlign: 'right',
    paddingLeft: 35,
  },
})

export const SummaryRow = styled(Row)({
  paddingTop: 20,
})

export const ProgessHeaderRow = styled(SummaryRow)({
  alignItems: 'flex-end',
  paddingBottom: 0,
})

export const MetricsRow = styled(Row)({
  marginTop: 5,
})

export const UpdatedAt = styled.span({
  ...regularBodyTextStyle,
  display: 'inline',
  marginLeft: '0.5rem',
  fontStyle: 'italic',
  color: 'var(--gray4)',
})

export const DateOrWeekText = styled(TableLabelBoldText)({
  color: 'var(--gray4)',
  marginTop: 5,
})

export const RecapHeader = styled(LargeHeaderText)({
  color: 'var(--gray4)',
  overflow: 'hidden',
  maxHeight: 32,
})

export const PreviousCol = styled(Col)({
  backgroundColor: 'var(--gray7)',
  paddingTop: 0,
  paddingBottom: 38,
  paddingLeft: 40,
  paddingRight: 40,
})

export const CurrentCol = styled(Col)({
  paddingTop: 0,
  paddingBottom: 38,
  paddingLeft: 40,
  paddingRight: 40,
  '@media only screen and (min-width: 992px)': {
    borderRight: sectionBorder,
  },
  '@media only screen and (max-width: 991px)': {
    borderBottom: sectionBorder,
  },
})

export const CurrentSalesChart = styled.div({
  margin: '15px 0 0',
})

export const PreviousSalesChart = styled.div({
  marginTop: 5,
})
