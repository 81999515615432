import React, { ReactNode, FC, ComponentType } from 'react'
import styled from 'styled-components'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import infoIcon from 'assets/images/info.svg'
import { InfoTooltip } from 'components/InfoTooltip'

const StyledTooltipIcon = styled.img({})

interface Props {
  message: string | ReactNode
  id?: string
  StyledImageComponent?: ComponentType
  StyledInfoTooltipComponent?: ComponentType
}

export const InfoTooltipIcon: FC<Props> = ({
  message,
  id,
  StyledImageComponent = StyledTooltipIcon,
  StyledInfoTooltipComponent = InfoTooltip,
}) => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 150, hide: 500 }}
    overlay={
      <StyledInfoTooltipComponent id={id}>{message}</StyledInfoTooltipComponent>
    }
    popperConfig={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 18],
          },
        },
      ],
    }}
  >
    <StyledImageComponent src={infoIcon}></StyledImageComponent>
  </OverlayTrigger>
)
