import React from 'react'
import { useTranslation } from 'react-i18next'

import { WeatherDisplay } from 'components/Weather/WeatherDisplay'
import { useDailyLocationEvents } from 'queries/useDailyLocationEvents'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useWeatherCurrent } from 'queries/useWeather'
import { MetricContainer } from './Metric'
import {
  NoWeather,
  NoEvents,
  Event,
  Time,
  MetricNameValueContainer,
  WeatherIcon,
  NoWeatherContainer,
} from 'pages/tracking/metrics/styles'
import cloudy from 'assets/images/weather-icons/cloudy.svg'

export const WeatherMetric = () => {
  const currentWeather = useWeatherCurrent()
  const { t } = useTranslation()

  return (
    <MetricContainer
      title={t('weather.title')}
      isShort
      lineColor={currentWeather ? '--gray5' : '--yellow'}
      numXsColumns={12}
    >
      <MetricNameValueContainer>
        {currentWeather ? (
          <WeatherDisplay weather={currentWeather} />
        ) : (
          <NoWeatherContainer>
            <NoWeather>{t('weather.unavailable')}</NoWeather>
            <WeatherIcon src={cloudy} />
          </NoWeatherContainer>
        )}
      </MetricNameValueContainer>
    </MetricContainer>
  )
}

const EventList = () => {
  const { t } = useTranslation()
  const {
    times: { startOfToday, endOfToday },
  } = useCurrentLocation()

  const dailyEvents = useDailyLocationEvents(startOfToday, endOfToday)
  return dailyEvents.length === 0 ? (
    <NoEvents>{t('tracking.todaysProgress.noEventsToday')}</NoEvents>
  ) : (
    <>
      {dailyEvents.map(({ name, timeOrHoliday }) => (
        <Event key={name}>
          <Time as="span">{timeOrHoliday}</Time>
          <span title={name}>{name}</span>
        </Event>
      ))}
    </>
  )
}

export const EventsMetric = () => {
  const { t } = useTranslation()

  return (
    <MetricContainer
      isShort
      lineColor="--gray5"
      numXsColumns={12}
      title={t('events.title')}
    >
      <MetricNameValueContainer>
        <EventList />
      </MetricNameValueContainer>
    </MetricContainer>
  )
}
