import React from 'react'
import { useTranslation } from 'react-i18next'

import greenArrowIcon from 'assets/images/green-arrow.svg'
import redArrowIcon from 'assets/images/red-arrow.svg'
import { Subtitle } from 'pages/covid/dashboard/addressRow/styles'
import { formatLargeNumber } from 'utils/formattedNumberWithFallback'

interface Props {
  sentiment: string
  current: number
  past: number
  timeframe: 'week' | 'month'
}

export const GraphSubtitle = ({
  current,
  past,
  timeframe,
  sentiment,
}: Props) => {
  const { t } = useTranslation()
  const isPositive = current > past
  const difference = formatLargeNumber(Math.ceil(Math.abs(current - past)))

  return (
    <Subtitle>
      {sentiment !== 'neutral' && (
        <img
          src={sentiment === 'good' ? greenArrowIcon : redArrowIcon}
          alt="arrow"
        />
      )}
      <span>
        {difference}{' '}
        {t('covidDashboard.graphSubtitle', {
          adjective: isPositive ? 'more' : 'less',
        })}{' '}
        {timeframe}
      </span>
    </Subtitle>
  )
}
