import { EmployeeFragment, ShiftRoleFragment } from 'config/graphqlTypes'

export type RowEntity = EmployeeFragment | ShiftRoleFragment

export const isRowEntityEmployee = (
  rowEntity: RowEntity | null
): rowEntity is EmployeeFragment => rowEntity?.__typename === 'Employee'

export const isRowEntityShiftRole = (
  rowEntity: RowEntity | null
): rowEntity is ShiftRoleFragment => rowEntity?.__typename === 'ShiftRole'

// Provide a "fake" shift role for unavailability
const UnavailabilityRowEntityName = 'Unavailability'

export const UnavailabilityRowEntity = {
  id: '0',
  name: UnavailabilityRowEntityName,
} as ShiftRoleFragment

export const isRowEntityUnavilability = (entity: RowEntity | null) =>
  entity?.name === UnavailabilityRowEntityName
