import { DateTime } from 'luxon'
import {
  useUpdateShiftMutation,
  useCreateShiftMutation,
  ShiftFragment,
  useDeleteShiftMutation,
  useResetPlanStateMutation,
  PlanFragmentDoc,
  PlanFragment,
  useGetLaborDollarsQuery,
} from 'config/graphqlTypes'
import {
  deserializeISOStrings,
  serializeISOStrings,
} from 'utils/dateSerialization'
import {
  usePlanQueryVariables,
  usePlanId,
} from 'pages/plan/planQueryVariablesContext'
import { useGetEmployeesShiftsForWeek } from '../../../queries/useGetEmployeeShiftsForWeek'
import { usePlanPageContext } from '../planPageContext'
import { useGetPlan } from '../../../queries/useGetPlan'
import client from '../../../config/apolloClient'
import apolloClient from '../../../config/apolloClient'
import { useCallback } from 'react'
import { debounce } from 'lodash'
import { useGetViolationsPlan } from '../../../queries/useGetViolationsPlan'

type MutationType = 'create' | 'update' | 'delete'

export const useResetAndRefetchAll = (
  plan: PlanFragment,
  isScheduleByRole: boolean
) => {
  const planQueryVariables = usePlanQueryVariables()
  const { startOn, endOn, locationId } = planQueryVariables
  const { refetch: refetchPlan } = useGetPlan(startOn, endOn)
  const { id: planId, state: planState } = plan
  const { refetch: refetchViolations } = useGetViolationsPlan(planId)

  const {
    refetch: refetchEmployeesShiftsForWeek,
  } = useGetEmployeesShiftsForWeek(planId)
  const { refetch: refetchLaborDollars } = useGetLaborDollarsQuery({
    variables: {
      locationId,
      planId,
    },
  })
  const [
    resetPlanStateMutation,
    { error: resetPlanError },
  ] = useResetPlanStateMutation()

  const resetCachedPlan = () => {
    const cacheArgs = {
      fragment: PlanFragmentDoc,
      fragmentName: 'Plan',
      id: `Plan:${planId}`,
    }

    const cachedPlan = apolloClient.readFragment(cacheArgs)
    const planToUse = cachedPlan || plan

    if (!planToUse) return

    apolloClient.writeFragment({
      ...cacheArgs,
      data: serializeISOStrings({
        ...planToUse,
        state: 'in_progress',
      }),
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchAllDebounce = useCallback(
    debounce(async () => {
      !isScheduleByRole && refetchEmployeesShiftsForWeek()
      refetchViolations()
      refetchLaborDollars()
      refetchPlan()
      // wait for 3 seconds before fetching all necessary queries
    }, 3000),
    [isScheduleByRole]
  )

  return {
    resetAndRefetchAll: async () => {
      if (planState === 'submitted') {
        await resetPlanStateMutation({
          variables: {
            input: {
              locationId,
              startOn,
            },
          },
        })
        if (!resetPlanError) resetCachedPlan()
      }
      refetchAllDebounce()
    },
  }
}

export const useUpdatePlanCache = () => {
  const planId = usePlanId()
  const { plan: planContext } = usePlanPageContext()

  return {
    updateCachedPlan: (
      updatedOrCreatedShift: ShiftFragment | null,
      mutationType: MutationType,
      shiftId?: ShiftFragment['id'],
      plan?: PlanFragment
    ) => {
      const cacheArgs = {
        fragment: PlanFragmentDoc,
        fragmentName: 'Plan',
        id: `Plan:${planId}`,
      }

      const cachedPlan = client.cache?.readFragment<PlanFragment>(cacheArgs)

      const getUpdatedShifts = (plan: PlanFragment, shift?: ShiftFragment) => {
        const shifts = [...(plan.shifts ?? [])] as ShiftFragment[]

        if (mutationType === 'delete') {
          const index = shifts.findIndex(s => s.id === shiftId)
          if (index > -1) {
            shifts.splice(index, 1)
          }
          return shifts
        }
        if (!shift) return shifts
        const index = shifts.findIndex(s => s.id === shift.id)
        const tempIndex = shifts.findIndex(s => s.id === shiftId)
        if (index > -1) {
          shifts.splice(index, 1, shift)
        } else if (tempIndex > -1) {
          shifts.splice(tempIndex, 1, shift)
        } else {
          shifts.push(shift)
        }
        return shifts
      }

      const updateCache = (plan: PlanFragment, shifts: ShiftFragment[]) => {
        client.cache.writeFragment({
          ...cacheArgs,
          data: serializeISOStrings({ ...plan, shifts }),
        })
      }

      //For some reason, sometimes Apollo returns the cached plan as null,
      //so we use the plan from PlanPageContext to update the cache
      if (!cachedPlan) {
        const selectedPlan = planContext || plan

        if (!selectedPlan) return

        if (mutationType === 'delete') {
          const shifts = getUpdatedShifts(selectedPlan)
          return updateCache(selectedPlan, shifts)
        }

        if (updatedOrCreatedShift) {
          const shifts = getUpdatedShifts(selectedPlan, updatedOrCreatedShift)
          return updateCache(selectedPlan, shifts)
        }
        return
      }

      //If there is already plan stored in the cache, we only need to update it if a shift was created or deleted
      if (mutationType === 'create' && updatedOrCreatedShift) {
        const shifts = getUpdatedShifts(cachedPlan, updatedOrCreatedShift)
        return updateCache(cachedPlan, shifts)
      }

      if (mutationType === 'delete') {
        const shifts = getUpdatedShifts(cachedPlan)
        return updateCache(cachedPlan, shifts)
      }
    },
  }
}

export const useShiftMutations = (
  locationId: string,
  startTime: DateTime | null,
  endTime: DateTime | null,
  employeeId: string | null,
  shiftRoleId: string | null,
  selectedShift: ShiftFragment | null,
  isUnavailability: boolean,
  shiftBreaks: number[],
  notes: string | null
) => {
  const { plan, isScheduleByRole } = usePlanPageContext()
  const { updateCachedPlan } = useUpdatePlanCache()
  const { resetAndRefetchAll } = useResetAndRefetchAll(plan, isScheduleByRole)

  const [
    mutateCreateShift,
    { loading: createShiftIsBusy },
  ] = useCreateShiftMutation({
    variables: {
      input: {
        locationId,
        isUnavailability,
        startAt: startTime!,
        endAt: endTime!,
        employeeId: employeeId!,
        shiftRoleId: shiftRoleId,
        shiftBreaks: shiftBreaks!,
        notes,
      },
    },
    update: async (_, { errors, data }) => {
      if (errors) return

      const createShift = data?.createShift
        ? deserializeISOStrings(data.createShift)
        : null

      updateCachedPlan(
        { ...createShift, shiftRoleId } as ShiftFragment,
        'create'
      )

      await resetAndRefetchAll()
    },
  })

  const [
    mutateUpdateShift,
    { loading: updateShiftIsBusy },
  ] = useUpdateShiftMutation({
    variables: {
      input: {
        isUnavailability,
        id: selectedShift?.id!,
        startAt: startTime!,
        endAt: endTime!,
        employeeId: employeeId!,
        shiftRoleId: shiftRoleId,
        shiftBreaks: shiftBreaks!,
        notes,
      },
    },
    update: async (_, { errors, data }) => {
      if (errors) return

      const updateShift = data?.updateShift
        ? deserializeISOStrings(data.updateShift)
        : null

      updateCachedPlan(updateShift, 'update')

      await resetAndRefetchAll()
    },
  })

  const [
    mutateDeleteShift,
    { loading: deleteShiftIsBusy },
  ] = useDeleteShiftMutation({
    variables: {
      input: {
        id: selectedShift?.id!,
      },
    },
    update: async (_, { errors }) => {
      if (errors) return

      updateCachedPlan(null, 'delete', selectedShift?.id)

      await resetAndRefetchAll()
    },
  })

  return {
    mutateCreateShift,
    mutateUpdateShift,
    mutateDeleteShift,
    createShiftIsBusy,
    updateShiftIsBusy,
    deleteShiftIsBusy,
  }
}
