import React, { Fragment, useState, useEffect } from 'react'
import AuthenticatedPage from 'pages/AuthenticatedPage'
import { useTranslation } from 'react-i18next'
import { ManagementWrapper } from 'pages/management/ManagementWrapper'
import {
  EditEmployeeButton,
  ManagementContainer,
  ManagementHeader,
} from 'pages/management/styles'
import { EmployeesDataTable } from 'pages/management/employees/EmployeesDataTable'
import { EmployeesHeader } from 'pages/management/employees/EmployeesHeader'
import { Loading } from 'components/Loading'
import {
  EmployeeListingFragment,
  useGetEmployeeLocationsListingQuery,
} from 'config/graphqlTypes'
import { formatPhoneNumber } from 'react-phone-number-input'
import { useHistory, useLocation } from 'react-router-dom'
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import { toast } from 'react-toastify'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { getHasSettingsPermission } from 'utils/permissions'
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit'
import { AvailabilityDescriptionCell } from 'components/Management/AvailabilityDescriptionCell'
import { useQueryParams } from 'utils/useQueryParams'

export interface EmployeeListingForLocation extends EmployeeListingFragment {
  locationId: string
  active: boolean
}

export const Employees = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const locationId = useCurrentLocationId()
  const [selectedEmployees, setSelectedEmployees] = useState<
    EmployeeListingForLocation[]
  >()
  const { data, loading } = useGetEmployeeLocationsListingQuery({
    variables: {
      locationId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [currentPage, setCurrentPage] = useState(
    parseInt(useQueryParams().get('currentPage') || '1')
  )

  const employeeToScroll = useQueryParams().get('employeeId') || ''

  useEffect(() => {
    document.getElementById(employeeToScroll)?.scrollIntoView()
  })

  const employeeListings: EmployeeListingForLocation[] =
    data?.employeeLocations
      .map(el => ({
        ...el.employee,
        locationId,
        active: el.employee.employeeLocations
          ? el.employee.employeeLocations.some(loc => loc.active)
          : false,
        excludeFromLabor: el.employee.employeeLocations
          ? el.employee.employeeLocations
              .filter(loc => loc.excludeFromLabor)
              .map(loc => loc.locationId)
          : [],
      }))
      .sort((a, b) => a.name.localeCompare(b.name)) ?? []

  const { permissions } = useLoggedInUserOrRedirect()

  const hasSettingsPermission = getHasSettingsPermission(permissions)

  const excludeFromLaborFormatter = (
    excludeFromLabor: Array<string>,
    employee: EmployeeListingFragment
  ) => {
    return excludeFromLabor.length
      ? excludeFromLabor.map(locId => (
          <Fragment key={locId}>
            {employee.locations.find(loc => loc.id === locId)?.name}
            <br />
          </Fragment>
        ))
      : t('settings.employeeSettings.excludeFromLaborNo')
  }

  const actionsFormatter = (_: any, row: any) => (
    <EditEmployeeButton
      onClick={() => {
        toast.dismiss()
        history.push(
          `${location.pathname}/${row.id}/edit?currentPage=${currentPage}`
        )
      }}
      block
      size="sm"
      variant="primary"
      type="button"
      id={row.id}
    >
      {t('management.actions.editBtn')}
    </EditEmployeeButton>
  )

  const contactFormatter = (
    email: string,
    employee: EmployeeListingFragment
  ) => (
    <>
      <span>{email}</span>
      {employee.phone ? <br /> : null}
      <span>{formatPhoneNumber(employee.phone || '')}</span>
    </>
  )

  const availabilityFormatter = (availability: any) =>
    availability ? (
      <AvailabilityDescriptionCell employeeAvailability={availability} />
    ) : (
      t('settings.employeeSettings.noAvailability')
    )

  const columns = [
    {
      dataField: 'name',
      text: t('settings.employeeSettings.name'),
      sort: true,
    },
    {
      dataField: 'email',
      text: t('settings.employeeSettings.contact'),
      formatter: contactFormatter,
      filterValue: (_: any, row: EmployeeListingFragment) => {
        return `${row.email} ${row.phone}`
      },
    },
    {
      dataField: 'availability',
      text: 'Availability',
      formatter: availabilityFormatter,
    },
    {
      dataField: 'excludeFromLabor',
      text: t('settings.employeeSettings.excludeFromLabor'),
      formatter: excludeFromLaborFormatter,
      filterValue: (cell: boolean) => {
        return cell ? 'yes' : 'no'
      },
      sort: true,
    },
  ]
  if (hasSettingsPermission) {
    columns.push({
      dataField: 'actions',
      text: t('management.actions.label'),
      formatter: actionsFormatter,
      filterValue: () => 'actions',
    })
  }

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeader>{t('management.employees')}</ManagementHeader>

          {loading ? (
            <Loading />
          ) : (
            <ManagementContainer>
              <ToolkitProvider
                keyField="id"
                data={employeeListings}
                columns={columns}
                search
              >
                {(props: ToolkitContextType) => (
                  <>
                    <EmployeesHeader
                      selectedEmployees={selectedEmployees}
                      searchProps={props.searchProps}
                      hasSettingsPermission={hasSettingsPermission}
                    />
                    <EmployeesDataTable
                      {...props.baseProps}
                      employees={employeeListings}
                      columns={columns}
                      onSelectEmployees={setSelectedEmployees}
                      hasSettingsPermission={hasSettingsPermission}
                      locationId={locationId}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </>
                )}
              </ToolkitProvider>
            </ManagementContainer>
          )}
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}
