import React from 'react'
import styled from 'styled-components'

import { smallBodyBoldTextStyle } from 'config/styles'

interface AxisLabelProps {
  color: string
  position: {
    x: number
    y: number
  }
  textPosition: {
    x: number
    y: number
  }
  value: string | number
  isXAxis?: boolean
}

const Text = styled.text<{ color: string }>(({ color }) => ({
  ...smallBodyBoldTextStyle,
  fill: `var(--${color})`,
}))

const AxisLabel: React.FC<AxisLabelProps> = ({
  color,
  position,
  textPosition,
  value,
  isXAxis,
}) => {
  const textAnchor = isXAxis ? 'middle' : 'end'
  const dominantBaseline = isXAxis ? 'before-edge' : 'middle'
  const transform = `translate(${textPosition.x},${textPosition.y +
    (isXAxis ? 10 : 0)}) rotate(0)`

  return (
    <g transform={`translate(${position.x},${position.y})`}>
      <Text
        color={color}
        dominantBaseline={dominantBaseline}
        textAnchor={textAnchor}
        transform={transform}
      >
        {value}
      </Text>
    </g>
  )
}

export default AxisLabel
