import styled, { createGlobalStyle } from 'styled-components'
import Row from 'react-bootstrap/Row'

import { regularBodyBoldTextStyle, sectionBorder } from 'config/styles'
import Button, { TextButton } from 'components/Button'

// There are several "sticky" rows which look good on desktop but not on mobile.
export const MAKE_ROWS_STICKY_WIDTH = 1200
export const MAKE_ROWS_STICKY = `@media only screen and (min-width: ${MAKE_ROWS_STICKY_WIDTH}px)`

export const horizontalPagePadding = 40
export const printHorizontalPagePadding = 30

export const cellPadding = 15
export const printCellPadding = 0

export const StyledTable = styled.table<{
  numTableRows: number
}>(({ numTableRows }) => ({
  '@media screen': {
    borderTop: sectionBorder,
    display: 'grid',
    height: '100%',
    gridTemplateRows: `repeat(${numTableRows -
      2}, min-content) auto min-content`,
    gridTemplateColumns: 'minmax(12rem, 1.3fr) repeat(8, minmax(8rem, 1fr))',
  },
  '@media print': {
    width: '100%',
    height: '100%',
    'thead tr th': {
      width: '1.0in',
    },
  },
}))

export const PrintAllContent = styled.tr<{ isScheduleByRole: boolean }>(
  ({ isScheduleByRole }) => ({
    '@media print': {
      height: isScheduleByRole ? 'calc(100vh + 300px)' : '300px',
    },
  })
)

export const StyledThead = styled.thead({
  '@media screen': {
    display: 'contents',
  },
})

export const StyledTfoot = styled.tfoot({
  '@media screen': {
    display: 'contents',
  },
  '@media print': {
    display: 'table-row-group' /* only print once */,
  },
})

export const HorizontalScrollableOnLargeScreensContainer = styled.div({
  overflowX: 'scroll',
  [MAKE_ROWS_STICKY]: {
    overflowX: 'initial',
  },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '@media print': {
    display: 'inline-block !important',
  },
})

export const PLANNING_FOOTER_HEIGHT = 70

export const StyledVerticallyScrollableContainer = styled.div<{
  disabled?: boolean
}>(({ disabled }) => ({
  marginBottom: disabled ? 0 : PLANNING_FOOTER_HEIGHT,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}))

export const PrintingStyle = createGlobalStyle({
  '@page': {
    size: 'A4 landscape',
    margin: '0',
  },
  '@media print': {
    fontSize: '12px',
  },
})

export const StyledTbody = styled.tbody({
  '@media screen': {
    display: 'contents',
  },
})

export const StyledRow = styled(Row)({
  '@media screen': {
    flex: 1,
    flexDirection: 'column',
  },
})

export const PlanningTableRow = styled.tr<{
  isBodyRow?: boolean
  nthChildren?: number[]
}>(({ isBodyRow = false, nthChildren }) => ({
  '> :nth-child(odd)': {
    backgroundColor: isBodyRow ? 'var(--gray8)' : 'var(--gray7)',
  },
  '> :nth-child(even)': {
    backgroundColor: 'var(--white)',
  },
  [nthChildren?.length
    ? nthChildren.map(n => `>:nth-child(${n})`).join(',')
    : '']: {
    background:
      'repeating-linear-gradient(135deg, var(--gray-lighter), var(--gray-lighter) 2px, ' +
      'var(--gray5) 2px, var(--gray5) 5px )',
  },

  '@media screen': {
    display: 'contents',
  },
  '@media print': {
    pageBreakInside: 'avoid',
    pageBreakAfter: 'auto',
  },
}))

export const UnprintablePlanningTableRow = styled(PlanningTableRow)({
  '@media print': {
    display: 'none',
  },
})

export const categoryRowStyle = {
  ...regularBodyBoldTextStyle,
  textTransform: 'uppercase' as const,
  height: 33,
  background: 'var(--gray6) !important',
  borderColor: 'var(--gray5)',
  borderWidth: 1,
  borderStyle: 'solid none',
  display: 'flex',
  alignItems: 'center',
}

export const Cell = styled.td({
  paddingTop: 15,
  paddingBottom: 15,
  textAlign: 'center',
  verticalAlign: 'top',

  '@media screen': {
    paddingLeft: cellPadding,
    paddingRight: cellPadding,

    '&:first-of-type': {
      paddingLeft: horizontalPagePadding,
    },

    display: 'flex',
    alignItems: 'center',
  },

  '@media print': {
    borderLeft: '1px solid var(--gray6)',
    borderRight: '1px solid var(--gray6)',

    paddingLeft: printCellPadding,
    paddingRight: printCellPadding,

    maxWidth: 100,

    '&:first-of-type': {
      padding: 5,
      width: 'auto',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
})

export const WhiteFixedFooter = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  backgroundColor: '#FFF',
  bottom: 0,
  height: PLANNING_FOOTER_HEIGHT,
  position: 'fixed',
  width: '100%',
  '@media print': {
    display: 'none',
  },
})

export const ActionButton = styled(Button)({
  width: '184.62px',
  height: '50px',
  fontSize: '18px',
  lineHeight: '20px',
})

export const FooterButtonContainer = styled.div({
  width: 200,
})

export const ClearScheduleButtonElement = styled(TextButton)({
  fontSize: '13px',
  fontWeight: 700,
  color: 'var(--red)',
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  paddingLeft: 36,
  ':hover': {
    color: 'var(--red)',
  },
})

export const MinLbrViolationRow = styled.div<{ borderTop: boolean }>(
  ({ borderTop }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '364px',
    gap: '30px',
    borderTop: borderTop ? '0.5px solid var(--gray5)' : 'undefined',
    marginBottom: '11px',
    paddingTop: '7px',
  })
)

export const MinLbrViolationWrapper = styled.div({
  marginTop: '26px',
})

export const MinLbrViolationText = styled.div({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  maxWidth: '120px',
})

export const SpanDivider = styled.span({
  marginLeft: 3,
  marginRight: 3,
})
