import React, { useState, useEffect } from 'react'
import BootstrapAlert from 'react-bootstrap/Alert'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { TFunction } from 'i18next'
import { subscribe as subscribeToAppAlertMessages } from '../utils/useAppAlertMessages'
import { subscribeToNetworkErrors } from 'config/apolloClient'
import { useOnlineStatus } from 'utils/useOnlineStatus'
import supportedBrowsers from 'utils/supportedBrowsers'
import { useInterval } from 'utils/useInterval'
import { submitAppAlertMessage } from '../utils/useAppAlertMessages'
import { useLoggedInUser } from 'queries/useLoggedInUser'

export const Alert = styled(BootstrapAlert)({
  marginBottom: 0,
  borderRadius: 0,
})

const ReloadLink = styled.a({
  fontWeight: 900,
})

const expireErrorDuration = 120000

const versionCheckInterval = 10 * 60 * 1000

export const getAlertMessage = ({
  isOutOfDate,
  onlineStatus,
  networkingError,
  userAgent,
  t,
  customMessage,
}: {
  isOutOfDate: boolean
  onlineStatus: boolean
  networkingError: boolean
  userAgent: string
  t: TFunction
  customMessage?: { message: string; type: string }
}) => {
  if (isOutOfDate) {
    return (
      <Trans i18nKey="misc.outOfDateErrorMessage">
        A new version of Lineup is available. Please
        <ReloadLink href={window.location.href}>reload</ReloadLink>
        to get the latest version
      </Trans>
    )
  }
  if (networkingError) {
    return t('misc.genericErrorMessage')
  } else if (!onlineStatus) {
    return t('misc.offlineErrorMessage')
  } else if (!supportedBrowsers.test(userAgent)) {
    return t('misc.unsupportedBrowserErrorMessage')
  } else if (customMessage) {
    return customMessage.message
  } else {
    return null
  }
}

const useNetworkingError = (): boolean => {
  const [networkingError, setNetworkingError] = useState(false)

  useEffect(() => {
    subscribeToNetworkErrors(() => {
      setNetworkingError(true)
    })
  }, [])

  useEffect(() => {
    if (!networkingError) return

    const timeout = setTimeout(() => {
      setNetworkingError(false)
    }, expireErrorDuration)

    return () => {
      clearTimeout(timeout)
    }
  }, [networkingError])

  return networkingError
}

const useOutOfDate = () => {
  const [isOutOfDate, setOutOfDate] = useState(false)

  useInterval(() => {
    const xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      const latestVersion = xhr.responseText.trim()
      const runningVersion = process.env['REACT_APP_VERSION']

      if (latestVersion !== runningVersion) {
        setOutOfDate(true)
      } else {
        setOutOfDate(false)
      }
    })

    const url = `${process.env['PUBLIC_URL']}/version.txt`
    xhr.open('GET', url)
    xhr.send()
  }, versionCheckInterval)

  return isOutOfDate
}

const useCheckOnboardingLocations = (t: Function) => {
  let locations = useLoggedInUser()?.locations
  let showWarning = false
  if (!locations) locations = []
  for (let i = 0; i < locations.length && !showWarning; i++) {
    if (locations[i].status === 'pending_historic_import') {
      showWarning = true
    }
  }

  useEffect(() => {
    if (showWarning) {
      submitAppAlertMessage(t('misc.locationsOnboardingInProgress'), 'success')
    }
  }, [showWarning, t])

  return showWarning
}

export const AppAlert = () => {
  const [messageAndType, setCustomMessage] = useState({
    message: '',
    type: 'danger',
  })
  const { t } = useTranslation()

  //long term these statuses should get refactored to use the new subscription based model
  //also this should be refactored to handle multiple messages
  const onlineStatus = useOnlineStatus()
  const isOutOfDate = useOutOfDate()
  const networkingError = useNetworkingError()
  subscribeToAppAlertMessages((message: string, type: string) => {
    setCustomMessage({ message, type })
  })

  useCheckOnboardingLocations(t)

  const alertMessage = getAlertMessage({
    isOutOfDate,
    onlineStatus,
    networkingError,
    userAgent: navigator.userAgent,
    t,
    customMessage: messageAndType,
  })

  if (!alertMessage) {
    return null
  }

  return <Alert variant={messageAndType.type}>{alertMessage}</Alert>
}
