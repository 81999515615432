import React, { FormEvent, useEffect, useState } from 'react'
import {
  OperatingHoursFragment,
  useUpdateOperatingHoursMutation,
} from 'config/graphqlTypes'
import { useCurrentLocationId } from '../../../../queries/useCurrentLocation'
import { Loading } from '../../../../components/Loading'
import { Form } from '../../../../components/Form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { omit } from 'lodash'
import Button from '../../../../components/Button'
import { ButtonsWrapper } from '../../styles'
import { useGetOperatingHoursLazyQuery } from '../../../../config/graphqlTypesRaw'
import { toast } from 'react-toastify'
import {
  convertTo12HourFormat,
  convertTo24HourFormat,
  dayOfWeekToNumber,
  DaysOfWeek,
} from '../../../../utils/timeFormats'
import OperatingHoursRow from './OperatingHoursRow'

export const OperatingHours = () => {
  const { t } = useTranslation()
  const locationId = useCurrentLocationId()
  const [isCancelled, setIsCancelled] = useState(false)

  const [
    getOperatingHours,
    { loading: operatingHrsLoading, error: operatingHrsError, refetch },
  ] = useGetOperatingHoursLazyQuery({
    variables: { locationId },
  })

  const [
    updateOperatingHours,
    { loading: updateOperatingHrsLoading, error: updateOperatingHrsError },
  ] = useUpdateOperatingHoursMutation()

  const [formValid, setFormValid] = useState(true)

  const [originalOperatingHours, setOriginalOperatingHours] = useState<
    OperatingHoursFragment[]
  >([])

  const [operatingHours, setOperatingHours] = useState<
    OperatingHoursFragment[]
  >([])

  useEffect(() => {
    getOperatingHours().then(res => {
      const operatingHours = res.data?.operatingHours
      if (operatingHours) {
        setOperatingHours(operatingHours)
        setOriginalOperatingHours(operatingHours)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function updateOperatingHrs(updatedOperatingHrs: OperatingHoursFragment) {
    setOperatingHours(currentOperatingHrs => [
      ...currentOperatingHrs.filter(
        operatingHrs => operatingHrs.id !== updatedOperatingHrs.id
      ),
      updatedOperatingHrs,
    ])
  }

  function handleUpdate(updatedOperatingHrs: OperatingHoursFragment) {
    const isFormValid = validateForm(updatedOperatingHrs, operatingHours)
    updateOperatingHrs(updatedOperatingHrs)
    setIsCancelled(false)
    setFormValid(isFormValid)
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const operatingHoursInput = operatingHours.map(operatingHour => {
      const operatingHourCopy = { ...operatingHour }

      operatingHourCopy.openAtIso8601 =
        convertTo24HourFormat(operatingHour.openAtIso8601) ||
        operatingHour.openAtIso8601

      operatingHourCopy.closeAtIso8601 =
        convertTo24HourFormat(operatingHour.closeAtIso8601) ||
        operatingHour.closeAtIso8601

      return {
        ...omit(operatingHourCopy, '__typename'),
        locationId,
      }
    })

    await updateOperatingHours({
      variables: { input: { operatingHours: operatingHoursInput } },
    })

    if (updateOperatingHrsError) {
      toast.error(t('management.settings.genericError'), {
        toastId: 'error',
        position: toast.POSITION.BOTTOM_LEFT,
      })
    } else {
      toast.success(t('management.settings.locations.updateOperatingHrs'), {
        toastId: 'success',
        position: toast.POSITION.BOTTOM_LEFT,
      })

      refetch()
      setOriginalOperatingHours(operatingHours)
    }
  }

  function resetOperatingHoursToOriginal() {
    setFormValid(true)
    setIsCancelled(true)
    setOperatingHours([...originalOperatingHours])
  }

  const sortOperatingHours = (days: OperatingHoursFragment[]) =>
    [...days].sort(
      (a, b) =>
        dayOfWeekToNumber(a.day as DaysOfWeek) -
        dayOfWeekToNumber(b.day as DaysOfWeek)
    )

  if (operatingHrsLoading) {
    return <Loading />
  }

  const sortedOperatingHours = sortOperatingHours(operatingHours)

  return (
    <Row>
      <Col xs={12} md={6}>
        {operatingHrsError ? (
          <div>{t('management.settings.genericError')}</div>
        ) : (
          <Form>
            <DayRowsContainer>
              {sortedOperatingHours.map(
                (operatingHrsDay: OperatingHoursFragment) => (
                  <OperatingHoursRow
                    key={operatingHrsDay.id}
                    isCancelled={isCancelled}
                    operatingHrsDay={operatingHrsDay}
                    onChangeUpdate={handleUpdate}
                    onChange={() => setIsCancelled(false)}
                  />
                )
              )}
            </DayRowsContainer>
            <ButtonsWrapper>
              <CancelButton
                disabled={false}
                onClick={resetOperatingHoursToOriginal}
                block
                size="lg"
                type="button"
                variant="secondary"
              >
                {t('management.settings.locations.cancel')}
              </CancelButton>
              <SubmitButton
                onClick={handleSubmit}
                block
                disabled={!formValid || updateOperatingHrsLoading}
                size="lg"
                type="submit"
                variant="blue"
              >
                {t('management.settings.locations.update')}
              </SubmitButton>
            </ButtonsWrapper>
          </Form>
        )}
      </Col>
    </Row>
  )
}

function validateForm(
  updatedOperatingHrs: OperatingHoursFragment,
  operatingHours: OperatingHoursFragment[]
) {
  const operatingHoursCopy = operatingHours.filter(
    hrs => hrs.id !== updatedOperatingHrs.id
  )
  const isInvalid = [...operatingHoursCopy, updatedOperatingHrs].find(hrs => {
    const openTime24Hr = convertTo12HourFormat(hrs.openAtIso8601)
    const closeTime24Hr = convertTo12HourFormat(hrs.closeAtIso8601)
    const isTimeValid = !!openTime24Hr && !!closeTime24Hr

    return !hrs.closed && !isTimeValid
  })

  return !isInvalid
}

const DayRowsContainer = styled.div`
  margin-bottom: 24px;
`

const CancelButton = styled(Button)`
  border-radius: 25px;
  color: var(--white);
  font-weight: 800;
  max-width: 206px;
  margin-top: 0.5rem;
  margin-right: 13px;
`

const SubmitButton = styled(Button)`
  max-width: 206px;
`
