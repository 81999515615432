import React, { FC } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import { LaborHoursBreakdownAccordionToggle } from './LaborHoursBreakdownAccordionToggle'
import {
  ExcludedFromLaborTotalHrsWeeklyWrapper,
  LaborHrsBreakdownAccordionContainer,
  LaborHrsBreakdownAccordionToggleTitle,
  StyledAccordionHeader,
  StyledLabel,
} from './styles'
import {
  LaborHourEmployee,
  LaborHoursDateRange,
} from '../../config/graphqlTypesRaw'
import { LaborHoursBreakdownList } from './LaborHoursBreakdownList'
import { LaborHoursBreakdownTotalHrs } from './LaborHoursBreakdownTotalHrs'
import { LaborHoursBreakdownListHeader } from './LaborHoursBreakdownListHeader'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

type LaborHoursBreakdownAccordionProps = {
  title: string
  totalHrsTitle: string
  showHeader?: boolean
  headerTitles?: string[]
  isCurrentDayOrWeek: boolean
  laborHourEmployees?: LaborHourEmployee[]
  laborHoursDateRange?: LaborHoursDateRange
  totalHrs?: number
  totalPlannedHrs?: number
  eventKey: string
}

export const LaborHoursBreakdownAccordion: FC<LaborHoursBreakdownAccordionProps> = ({
  title,
  totalHrsTitle,
  showHeader,
  headerTitles,
  isCurrentDayOrWeek,
  laborHourEmployees,
  laborHoursDateRange,
  totalPlannedHrs,
  totalHrs,
  eventKey,
}) => {
  const { t } = useTranslation()
  const dayFormat = 'EEEE'

  return (
    <Row>
      <Col>
        <Accordion>
          <LaborHoursBreakdownAccordionToggle eventKey={eventKey}>
            <LaborHrsBreakdownAccordionToggleTitle>
              {title}
            </LaborHrsBreakdownAccordionToggleTitle>
          </LaborHoursBreakdownAccordionToggle>
          <Accordion.Collapse eventKey={eventKey}>
            <LaborHrsBreakdownAccordionContainer>
              {showHeader && (
                <StyledAccordionHeader>
                  <LaborHoursBreakdownListHeader columns={headerTitles ?? []} />
                </StyledAccordionHeader>
              )}
              {laborHoursDateRange ? (
                <>
                  {laborHoursDateRange.dailyBreakDowns.map((weekDay, i) => (
                    <div key={i}>
                      <div style={{ paddingTop: i !== 0 ? 20 : 0 }}>
                        <StyledLabel style={{ fontWeight: 800 }}>
                          {DateTime.fromISO(weekDay.day).toFormat(dayFormat)}
                        </StyledLabel>
                        <LaborHoursBreakdownList
                          isCurrentDayOrWeek={isCurrentDayOrWeek}
                          laborHourEmployees={
                            weekDay.dailyBreakDown.excludeFromLabor
                          }
                        />
                        <LaborHoursBreakdownTotalHrs
                          hideBottomLine
                          title={t(
                            'tracking.laborActualHours.weekDayTotalHrsLabel',
                            {
                              day: DateTime.fromISO(weekDay.day).toFormat(
                                dayFormat
                              ),
                            }
                          )}
                          totalHrs={
                            weekDay.dailyBreakDown.totalLaborHoursExcluded
                          }
                          totalPlannedHrs={
                            weekDay.dailyBreakDown.totalPlannedHoursExcluded
                          }
                        />
                      </div>
                    </div>
                  ))}
                  <ExcludedFromLaborTotalHrsWeeklyWrapper>
                    <LaborHoursBreakdownTotalHrs
                      title={totalHrsTitle}
                      totalHrs={totalHrs ?? 0}
                      totalPlannedHrs={totalPlannedHrs ?? 0}
                    />
                  </ExcludedFromLaborTotalHrsWeeklyWrapper>
                </>
              ) : (
                <>
                  <LaborHoursBreakdownList
                    isCurrentDayOrWeek={isCurrentDayOrWeek}
                    laborHourEmployees={laborHourEmployees}
                  />
                  <LaborHoursBreakdownTotalHrs
                    title={totalHrsTitle}
                    totalHrs={totalHrs ?? 0}
                    totalPlannedHrs={totalPlannedHrs ?? 0}
                  />
                </>
              )}
            </LaborHrsBreakdownAccordionContainer>
          </Accordion.Collapse>
        </Accordion>
      </Col>
    </Row>
  )
}
