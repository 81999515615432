import React, { ReactNode } from 'react'

import { InfoTooltipIcon } from 'components/InfoTooltipIcon'
import {
  SmallMetricLine,
  MetricLine,
  MetricCol,
  MetricContainerWrapper,
  MetricTitle,
  SmallMetricValue,
  LargeMetricValue,
  MetricNameValueContainer,
  MetricName,
  StyledTooltipIcon,
} from 'pages/tracking/metrics/styles'

interface MetricContainerProps {
  children: JSX.Element
  title?: string
  isShort?: boolean
  lineColor: string
  isSmall?: boolean
  numXsColumns?: number
}

export const MetricContainer = ({
  title,
  lineColor,
  children,
  isShort,
  isSmall,
  numXsColumns = 6,
}: MetricContainerProps) => {
  const Line = isShort ? SmallMetricLine : MetricLine

  return (
    <MetricCol md="auto" xs={numXsColumns}>
      <MetricContainerWrapper>
        <Line color={lineColor} isSmall={isSmall} />
        {children}
      </MetricContainerWrapper>
      {!!title && <MetricTitle isSmall={isSmall}>{title}</MetricTitle>}
    </MetricCol>
  )
}

export interface MetricProps {
  id: string
  title?: string
  name: string
  value: string
  isSmall?: boolean
  isShort?: boolean
  underlined?: boolean
  lineColor: string
  valueColor?: string
  tooltip?: string | ReactNode
  cursor?: string
  onClick?: () => void
}

export const Metric = ({
  id,
  title,
  name,
  value,
  isSmall,
  isShort,
  underlined,
  lineColor,
  valueColor,
  tooltip,
  cursor,
  onClick,
}: MetricProps) => {
  const MetricValue = isSmall ? SmallMetricValue : LargeMetricValue

  return (
    <MetricContainer
      title={title}
      isShort={isShort}
      lineColor={lineColor}
      isSmall={isSmall}
    >
      <MetricNameValueContainer data-testid={id}>
        <MetricName>
          {name}
          {tooltip && (
            <InfoTooltipIcon
              message={tooltip}
              id="metric-tooltip"
              StyledImageComponent={StyledTooltipIcon}
            />
          )}
        </MetricName>
        <MetricValue
          color={valueColor}
          cursor={cursor}
          underlined={underlined}
          onClick={() => onClick && onClick()}
        >
          {value}
        </MetricValue>
      </MetricNameValueContainer>
    </MetricContainer>
  )
}
