import React, { useState } from 'react'
import _, { partition, sumBy } from 'lodash'
import { Interval } from 'luxon'

import {
  ActiveLocationShiftRoleFragment,
  EmployeeFragment,
  ShiftFragment,
  ShiftRoleFragment,
} from 'config/graphqlTypes'
import { EmployeeScheduleRow } from 'pages/plan/dailyViewModal/schedule/EmployeeScheduleRow'
import { DailyTimelineRow } from 'pages/plan/dailyViewModal/DailyTimelineRow'
import {
  RoleHeading,
  ShiftRoleName,
  ExpandCollapseButton,
} from 'pages/plan/dailyViewModal/schedule/styles'
import { ExpandCollapseIcon } from 'components/ExpandCollapseIcon'
import { DayTotalHours } from 'pages/plan/dailyViewModal/schedule/DayTotalHours'
import useWindowWidth from 'utils/useWindowWidth'
import { smallScreenWidth } from 'pages/plan/dailyViewModal/styles'
import { TimelineHelpers } from 'pages/plan/dailyViewModal/schedule/getGraphTimelineHelpers'

interface Props {
  shifts: ShiftFragment[]
  shiftRole: ShiftRoleFragment
  VerticalLines: JSX.Element
  timelineHelpers: TimelineHelpers
  isEditable: boolean
  day: Interval
  days: Interval[]
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

const shiftsForEmployee = (
  employee: EmployeeFragment,
  shifts: ShiftFragment[]
) => shifts.filter(({ employee: { id } }) => id === employee.id)

export const ShiftRoleSchedule = ({
  shifts,
  shiftRole,
  VerticalLines,
  timelineHelpers,
  isEditable,
  day,
  days,
  activeLocationShiftRoles,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const width = useWindowWidth()
  const isMobile = width <= smallScreenWidth

  const [roleShifts, otherShifts] = partition(
    shifts,
    ({ shiftRole: givenShiftRole }) => givenShiftRole?.id === shiftRole.id
  )

  const hours = sumBy(roleShifts, 'hours')

  const employees = _(roleShifts)
    .sortBy('startAt')
    .map(({ employee }) => employee)
    .uniqBy('id')
    .value()

  return (
    <>
      <DailyTimelineRow
        LeftSection={
          <RoleHeading>
            {isMobile || (
              <ExpandCollapseButton
                onClick={() => setIsExpanded(!isExpanded)}
                data-testid="collapse-button"
              >
                <ExpandCollapseIcon isExpanded={isExpanded} />
              </ExpandCollapseButton>
            )}
            <ShiftRoleName>{shiftRole.name}</ShiftRoleName>
          </RoleHeading>
        }
        MiddleSection={VerticalLines}
        RightSection={<DayTotalHours hours={hours} isRoleTotal />}
      />
      {isExpanded &&
        employees.map(employee => (
          <EmployeeScheduleRow
            key={employee.id}
            role={shiftRole}
            employee={employee}
            roleShifts={shiftsForEmployee(employee, roleShifts)}
            otherShifts={shiftsForEmployee(employee, otherShifts)}
            VerticalLines={VerticalLines}
            timelineHelpers={timelineHelpers}
            isEditable={isEditable}
            day={day}
            days={days}
            activeLocationShiftRoles={activeLocationShiftRoles}
          />
        ))}
    </>
  )
}
