import React from 'react'
import { Serie } from '@nivo/line'
import { max, round } from 'lodash'

import AxisLabel from 'components/Chart/AxisLabel'
import { ChartYAxisConfig } from 'components/Chart'

const minYTickIncrement = 100
const maxYTickIncrement = 1000
const maxYTickCount = 4
const defaultMaxY = 5000
const formatMoney = (value: number) =>
  value < maxYTickIncrement
    ? `$${value}`
    : `$${round(value / maxYTickIncrement, 1)}k`

const generateYAxisValues = (
  data: Serie[],
  actualLabor: number,
  targetLabor: number
) => {
  const yValues = data.flatMap(item => item.data.map(d => Number(d.y)))
  const maxY = max([actualLabor, targetLabor, ...yValues]) || defaultMaxY

  const yTickIncrement =
    maxY < maxYTickIncrement ? minYTickIncrement : maxYTickIncrement
  const maxYTick = Math.ceil(maxY / yTickIncrement) * yTickIncrement
  const yTickCount = maxY === 0 ? 1 : maxYTickCount
  const yTick = maxYTick / yTickCount

  return Array.from(Array(yTickCount), (_value, i) => {
    const value = yTick + i * yTick

    return {
      formattedValue: formatMoney(value),
      labelColor: actualLabor && actualLabor === value ? 'teal' : 'gray3',
      value,
    }
  })
}

export const makeYAxisConfig = (
  data: Serie[],
  actualLabor: number,
  targetLabor: number,
  yAxisPosition: number
): ChartYAxisConfig => {
  const yAxisValues = generateYAxisValues(data, actualLabor, targetLabor)

  return {
    axisLeft: {
      // the type definition for renderTick makes no sense so we have
      // to use 'any' here for tickData
      tickPadding: yAxisPosition,
      tickValues: yAxisValues.map(yAxisValue => yAxisValue.value),
      renderTick: (tickData: any) => {
        const yAxisValue = yAxisValues.find(y => y.value === tickData.value)
        if (!yAxisValue) {
          return null
        }

        return (
          <AxisLabel
            color={yAxisValue.labelColor}
            position={{ x: tickData.x, y: tickData.y }}
            textPosition={{ x: tickData.textX, y: tickData.textY }}
            value={yAxisValue.formattedValue}
          />
        )
      },
    },
    gridYValues: yAxisValues.length,
    yScale: {
      max: yAxisValues[yAxisValues.length - 1].value,
      min: 0,
      type: 'linear',
    },
  }
}
