import React from 'react'
import { useLocation } from 'react-router-dom'
import { get } from 'lodash'

import { Address } from 'pages/covid/types'
import { AddressForm } from 'pages/covid/AddressForm'
import {
  TopbarContainer,
  TopBarRow,
  TopBarAddressFormCol,
} from 'pages/covid/styles'
import { DashboardLogo } from 'pages/covid/dashboard/DashboardLogo'
import { WestfieldLogo } from 'pages/covid/dashboard/WestfieldLogo'

interface Props {
  addAddress: (address: Address) => void
}

export const TopBarBlock = ({ addAddress }: Props) => {
  const location = useLocation()
  let lastPath = get(location, 'state.lastPath')

  return (
    <TopbarContainer fluid>
      {lastPath === '/reopenright' ? (
        <TopBarRow noGutters>
          <WestfieldLogo />
          <TopBarAddressFormCol>
            <AddressForm addAddress={addAddress} topbar={true} />
          </TopBarAddressFormCol>
          <DashboardLogo forceColorWhite />
        </TopBarRow>
      ) : (
        <TopBarRow noGutters>
          <DashboardLogo forceColorWhite />
          <TopBarAddressFormCol>
            <AddressForm addAddress={addAddress} topbar={true} />
          </TopBarAddressFormCol>
        </TopBarRow>
      )}
    </TopbarContainer>
  )
}
