import React, { Fragment, useEffect, useState } from 'react'
import { DateTime, Interval, Duration } from 'luxon'

import * as Analytics from 'config/analytics'
import {
  useUpdatePlanMutation,
  GetLocationForScheduleQuery,
  PlanFragment,
  MinimumLaborBaselineFragment,
  useGetLaborDollarsQuery,
} from 'config/graphqlTypes'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { Header } from 'pages/plan/header'
import { Table } from 'pages/plan/Table'
import { ActionFooter } from 'pages/plan/actionFooter'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { getUnderMinimumBaselineDays } from 'pages/plan/dailyViewModal/useUnderMinimumLaborBaseline'
import { getHasManagePredictions } from 'utils/permissions'
import { LaborDollars, PlanPageProvider } from './planPageContext'
import { useGetPlan } from '../../queries/useGetPlan'
import { ExportScheduleModal } from './exportScheduleModal'
import { useGetEmployeesShiftsForWeek } from '../../queries/useGetEmployeeShiftsForWeek'

interface Props {
  startDate: DateTime
  endDate: DateTime
  isPastSubmitDate: boolean
  location: GetLocationForScheduleQuery['location']
  plan: PlanFragment
}

export const PlanPage = ({
  startDate,
  endDate,
  isPastSubmitDate,
  plan,
  location,
}: Props) => {
  const { id: planId, shifts } = plan

  const days = Interval.fromDateTimes(startDate, endDate).splitBy(
    Duration.fromObject({ days: 1 })
  )
  const minLbrViolations = getUnderMinimumBaselineDays(
    shifts,
    location.minimumLaborBaselines as MinimumLaborBaselineFragment[],
    days
  )
  const isPresentMinLbrViolations = !!minLbrViolations.length
  const user = useLoggedInUserOrRedirect()
  const [isScheduleByRole, setIsScheduleByRole] = useState<boolean>(true)
  const [isShowingSchedule, setIsShowingSchedule] = useState<boolean>(
    shifts.length > 0
  )
  const [showExportScheduleModal, setShowExportScheduleModal] = useState(false)
  const { t } = useTranslation()
  const { refetch } = useGetPlan(startDate, endDate)
  const {
    data: employeeShiftsForWeek,
    refetch: refetchEmployeesShiftsForWeek,
  } = useGetEmployeesShiftsForWeek(planId)

  let {
    data: laborDollarsData,
    refetch: refetchLaborDollars,
  } = useGetLaborDollarsQuery({
    variables: {
      locationId: location.id,
      planId,
    },
  })

  let laborDollars: LaborDollars = laborDollarsData?.laborDollars
    ? {
        total: laborDollarsData?.laborDollars.totalCents,
        daily: laborDollarsData?.laborDollars.daily.reduce(
          (acc, entry) => ({ [entry.day]: entry.cents, ...acc }),
          {}
        ),
      }
    : null

  const toggleShowExportScheduleModal = () =>
    setShowExportScheduleModal(show => !show)

  const toastNotify = (msg: string) =>
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_LEFT,
    })

  const handleSetIsScheduleByRole = (isScheduleByRole: boolean) => {
    !isScheduleByRole && refetchEmployeesShiftsForWeek()
    setIsScheduleByRole(isScheduleByRole)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const analyticsProperties = {
    startDate,
    endDate,
    user: user.email,
    location: location.name,
  }

  const hideSchedule = () => setIsShowingSchedule(false)

  const showSchedule = () => {
    Analytics.track('Started Scheduling', analyticsProperties)
    setIsShowingSchedule(true)
  }

  const hasManagePredictions = getHasManagePredictions(user.permissions)

  useEffect(() => {
    const onAfterPrint = () =>
      Analytics.track('Print Schedule', analyticsProperties)

    window.addEventListener('afterprint', onAfterPrint)

    return () => {
      window.removeEventListener('afterprint', onAfterPrint)
    }
  }, [analyticsProperties])

  const updatePlanVars = {
    locationId: location.id,
    startOn: startDate,
    averageWage: null,
    laborPercentOfRevenue: null,
    notifyOnlyImpactedEmployees: false,
  }

  const [
    updatePlan,
    { loading: submitPlanLoading, error: submitPlanError },
  ] = useUpdatePlanMutation({
    variables: {
      input: updatePlanVars,
    },
  })

  const handleSubmitPlan = async (notifyOnlyImpactedEmployees: boolean) => {
    await updatePlan({
      variables: {
        input: { ...updatePlanVars, notifyOnlyImpactedEmployees },
      },
    })

    if (!submitPlanError) {
      refetchLaborDollars()
      refetch()
      toastNotify(t('planning.submitPlanSuccess'))
    }
  }

  const handleExportSchedule = () => {
    toggleShowExportScheduleModal()
  }

  const key = `${startDate.toISODate()}-${endDate.toISODate()}-${isPastSubmitDate}`
  return (
    <Fragment key={key}>
      {showExportScheduleModal && (
        <ExportScheduleModal
          planId={plan.id}
          planStartOn={plan.startOn}
          isScheduleByRole={isScheduleByRole}
          shifts={shifts}
          show={showExportScheduleModal}
          toggleShow={toggleShowExportScheduleModal}
        />
      )}
      <PlanPageProvider
        plan={plan}
        isScheduleByRole={isScheduleByRole}
        days={days}
        laborDollars={laborDollars}
      >
        <Header
          isPastSubmitDate={isPastSubmitDate}
          disabled={false}
          isShowingSchedule={isShowingSchedule}
          isScheduleByRole={isScheduleByRole}
          setIsScheduleByRole={handleSetIsScheduleByRole}
          onExportSchedule={handleExportSchedule}
          startDate={startDate}
        />
        <Table
          startDate={startDate}
          endDate={endDate}
          days={days}
          isShowingSchedule={isShowingSchedule}
          hideSchedule={hideSchedule}
          location={location}
          employeeShiftsForWeek={employeeShiftsForWeek}
          disableEditPrediction={!hasManagePredictions}
        />
        <ActionFooter
          isUnderMinimumLaborBaseline={isPresentMinLbrViolations}
          isShowingSchedule={isShowingSchedule}
          onSubmitPlan={handleSubmitPlan}
          showSchedule={showSchedule}
          hideSchedule={hideSchedule}
          submitPlanLoading={submitPlanLoading}
          minimumLaborBaselineViolations={minLbrViolations}
        />
      </PlanPageProvider>
    </Fragment>
  )
}
