import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Overlay from 'react-bootstrap/Overlay'

import * as Analytics from 'config/analytics'
import {
  DashboardFooterContainer,
  CopyMyLinkButton,
  EmailALinkButton,
  EmailALinkLabel,
  CopyToClipboardTooltip,
} from 'pages/covid/dashboard/footer/styles'
import { SaveThisViewModal } from 'pages/covid/dashboard/footer/SaveThisViewModal'
import { copyToClipboard } from 'utils/copyToClipboard'
import { DashboardInfo } from 'pages/covid/useDashboard'

interface Props {
  dashboard: DashboardInfo | null
  saveNewDashboard: (name: string, email: string) => void
}

export const DashboardFooter = ({ dashboard, saveNewDashboard }: Props) => {
  const { t } = useTranslation()

  const [isShowingSaveModal, setIsShowingSaveModal] = useState<boolean>(false)
  const showSaveModal = () => {
    Analytics.track('[CRC]: Opened save dashboard modal', {})
    setIsShowingSaveModal(true)
  }
  const hideSaveModal = () => {
    Analytics.track('[CRC]: Dismissed save dashboard modal', {})
    setIsShowingSaveModal(false)
  }

  const [showTooltip, setShowTooltip] = useState(false)
  const target = useRef(null)

  const copyDashboardUrl = () => {
    if (dashboard?.id) {
      const dashboardUrl = `${window.location.origin}/covid/dashboard?dashboard_id=${dashboard.id}`
      Analytics.track('[CRC]: Copied dashboard URL', { dashboardUrl })
      copyToClipboard(dashboardUrl)
      setShowTooltip(true)
      setTimeout(() => {
        setShowTooltip(false)
      }, 2000)
    }
  }

  return (
    <DashboardFooterContainer>
      <SaveThisViewModal
        show={isShowingSaveModal}
        onHide={hideSaveModal}
        saveNewDashboard={saveNewDashboard}
      />
      {dashboard?.id ? (
        <>
          <CopyMyLinkButton ref={target} onClick={copyDashboardUrl}>
            {t('covidDashboard.copyMyLink')}
          </CopyMyLinkButton>
          <Overlay target={target.current} show={showTooltip} placement="top">
            {props => (
              <CopyToClipboardTooltip id="copy-to-clipboard" {...props}>
                {t('covidDashboard.copiedToClipboard')}
              </CopyToClipboardTooltip>
            )}
          </Overlay>
        </>
      ) : (
        <>
          <EmailALinkLabel>
            {t('covidDashboard.wantToViewAgain')}
          </EmailALinkLabel>
          <EmailALinkButton
            onClick={showSaveModal}
            disabled={dashboard?.addresses.length === 0}
          >
            {t('covidDashboard.emailALink')}
          </EmailALinkButton>
        </>
      )}
    </DashboardFooterContainer>
  )
}
