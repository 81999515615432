import { WatchQueryFetchPolicy } from '@apollo/client'
import { DateTime } from 'luxon'

import { useCurrentLocation } from 'queries/useCurrentLocation'
import {
  useGetIntervalOrderSumsQuery,
  GetIntervalOrderSumsQuery,
} from 'config/graphqlTypes'
import { useGetPollingInterval } from 'queries/useGetPollingInterval'
import {
  DataLoadingState,
  makeDataLoadingState,
  wrapDataLoadingHelperThrowColdErrors,
} from 'utils/dataLoading'

export const useGetIntervalOrderSums = (
  startAt: DateTime,
  endAt: DateTime,
  intervalMinutes: number,
  {
    fetchPolicy = 'cache-and-network',
  }: {
    fetchPolicy?: WatchQueryFetchPolicy
  } = {}
) => {
  const pollInterval = useGetPollingInterval()
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  return (
    useGetIntervalOrderSumsQuery({
      fetchPolicy,
      pollInterval,
      variables: {
        locationId,
        startAt,
        endAt,
        intervalMinutes,
      },
    }).data?.intervalOrderSums || []
  )
}

const useGetIntervalOrderSumsDataLoadingWithColdError = (
  startAt: DateTime,
  endAt: DateTime,
  intervalMinutes: number,
  {
    fetchPolicy = 'cache-and-network',
    pollInterval,
  }: {
    fetchPolicy?: WatchQueryFetchPolicy
    pollInterval?: number | null
  } = {}
): DataLoadingState<GetIntervalOrderSumsQuery> => {
  const defaultPollInterval = useGetPollingInterval()
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  return makeDataLoadingState<GetIntervalOrderSumsQuery>(
    useGetIntervalOrderSumsQuery({
      fetchPolicy,
      ...(pollInterval !== null
        ? {
            pollInterval: pollInterval ?? defaultPollInterval,
          }
        : {}),
      variables: {
        locationId,
        startAt,
        endAt,
        intervalMinutes,
      },
    })
  )
}

export const useGetIntervalOrderSumsDataLoading = wrapDataLoadingHelperThrowColdErrors(
  useGetIntervalOrderSumsDataLoadingWithColdError
)
