import React, { FC } from 'react'

import Navbar from 'components/Navbar'
import Page from 'pages/Page'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { ErrorBoundary } from 'config/errorReporting'
import { ErrorPage } from 'pages/ErrorPage'
import { useActiveLearningNotice } from 'components/useActiveLearningNotice'
import { ActiveLearningNotice } from 'components/ActiveLearningNotice'

interface Props {
  children: JSX.Element
}

const AuthenticatedPage: FC<Props> = ({ children }) => {
  const locationId = useCurrentLocationId()

  const showActiveLearningNotice = useActiveLearningNotice()

  return (
    <Page key={locationId}>
      {showActiveLearningNotice && <ActiveLearningNotice />}

      <Navbar />
      <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
    </Page>
  )
}

export default AuthenticatedPage
