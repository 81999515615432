import React, { FC } from 'react'
import Col from 'react-bootstrap/Col'
import { DateTime, Interval, Duration } from 'luxon'
import { groupBy } from 'lodash/fp'

import AuthenticatedPage from 'pages/AuthenticatedPage'
import { routeMap, useLocationNavigation } from 'pages/Routes'
import { useGetWeekFromDate } from 'utils/useGetWeekFromDate'
import {
  StyledHeaderRow,
  StyledWeekNavigator,
  StyledRow,
  StyledVerticallyScrollableContainer,
  StyledTable,
  StyledTbody,
} from 'pages/reporting/styles'
import { BottomSpacingRow } from 'pages/reporting/BottomSpacingRow'
import { TableHead } from 'pages/reporting/TableHead'
import { MetrogroupReport } from 'pages/reporting/MetrogroupReport'
import { ResponsiveHorizontallyScrollableContainer } from 'config/styles'
import { Redirect } from 'react-router-dom'
import { useGetLocationsForReportingQuery } from 'config/graphqlTypes'
import { Loading } from 'components/Loading'
import { useCurrentLocation } from '../../queries/useCurrentLocation'

export interface ReportingIntervalProps {
  days: Interval[]
  startDate: DateTime
  endDate: DateTime
}

const Reporting: FC = () => {
  const { locationPath } = useLocationNavigation()
  const {
    times: { startOfThisWeek },
  } = useCurrentLocation()

  const { requestedDate, startDate, endDate } = useGetWeekFromDate({
    defaultStartDate: startOfThisWeek,
  })

  const { data, loading } = useGetLocationsForReportingQuery({ variables: {} })

  const reportingRoot = `${locationPath}${routeMap.location.reportingRoot}`

  if (!!requestedDate && !requestedDate.isValid) {
    return <Redirect to={reportingRoot} />
  }

  if (loading) {
    return <Loading />
  }

  const getPath = (weekStartDate: DateTime) =>
    `${reportingRoot}/${weekStartDate.toISODate()}`

  const interval = Interval.fromDateTimes(startDate, endDate)
  const days = interval.splitBy(Duration.fromObject({ days: 1 }))

  const locations = data!.locationsForReporting
  const locationsByMetrogroup = groupBy('metrogroup', locations)

  return (
    <AuthenticatedPage>
      <>
        <StyledHeaderRow noGutters>
          <Col sm={12}>
            <StyledWeekNavigator
              startDate={startDate}
              getPath={getPath}
              labelTranslationKey="reporting.weekNavigatorLabel"
            />
          </Col>
        </StyledHeaderRow>
        <StyledRow noGutters>
          <ResponsiveHorizontallyScrollableContainer>
            <StyledVerticallyScrollableContainer>
              <StyledTable>
                <TableHead days={days} />
                <StyledTbody>
                  {Object.keys(locationsByMetrogroup)
                    .sort()
                    .map(metrogroup => (
                      <MetrogroupReport
                        metrogroup={metrogroup}
                        locations={locationsByMetrogroup[metrogroup]}
                        days={days}
                        startDate={startDate}
                        endDate={endDate}
                        key={metrogroup}
                      />
                    ))}
                  <BottomSpacingRow />
                </StyledTbody>
              </StyledTable>
            </StyledVerticallyScrollableContainer>
          </ResponsiveHorizontallyScrollableContainer>
        </StyledRow>
      </>
    </AuthenticatedPage>
  )
}

export default Reporting
