import React, { FC } from 'react'

import {
  StyledReportingDifference,
  StyledForecastAndDiffContainer,
} from 'pages/reporting/styles'
import { asDollars } from 'utils/currency'
import { PlanForReportingFragment } from 'config/graphqlTypes'

interface Props {
  salesAndLaborSummary: PlanForReportingFragment['weekSalesAndLaborSummary']
}

export const ForecastAndDiff: FC<Props> = ({
  salesAndLaborSummary: { plannedSales, originallyPredictedSales },
}) => {
  if (!plannedSales) {
    return null
  }

  const diff = plannedSales - originallyPredictedSales

  return (
    <StyledForecastAndDiffContainer diff={diff}>
      <div>{asDollars(plannedSales)}</div>
      {diff !== 0 && (
        <StyledReportingDifference>
          {diff > 0 ? '+' : '-'}
          {asDollars(Math.abs(diff))}
        </StyledReportingDifference>
      )}
    </StyledForecastAndDiffContainer>
  )
}
