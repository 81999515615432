import styled, { css } from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Popover from 'react-bootstrap/Popover'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import {
  Cell,
  PLANNING_FOOTER_HEIGHT,
  MAKE_ROWS_STICKY,
  categoryRowStyle,
} from 'pages/plan/styles'
import {
  TableLabelBoldText,
  RegularBoldBodyText,
  RegularBodyText,
} from 'components/Text'
import { ShiftCardType } from 'pages/plan/scheduling/ShiftCard'
import { regularBodyBoldTextStyle } from 'config/styles'
import { PREDICTION_ROW_HEIGHT } from 'pages/plan/rows/styles'
import { ReactComponent as UpChevronRaw } from 'assets/images/up-chevron.svg'
import { ReactComponent as DownChevronRaw } from 'assets/images/down-chevron.svg'

const FLEX_DIRECTION_COLUMN = 'column' as 'column'

export const ScheduleByToggleWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '8px 0',
})

export const ScheduledByText = styled(TableLabelBoldText)({
  color: 'var(--gray4)',
  marginRight: 10,
})

export const StyledModalBody = styled(Modal.Body)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 24,

  '.form-group': {
    width: '70%',
    borderTop: '1px solid var(--gray5)',
    paddingTop: 15,
  },
})

export const StyledTemplateButton = styled(Button)({
  marginBottom: 21,
})

export const NoScheduleText = styled.span({
  color: 'var(--gray4)',
  fontWeight: 800,
  fontSize: 12,
})

export const BeginTemplates = styled(NoScheduleText)({
  fontWeight: 700,
  width: '70%',
  textAlign: 'center',
})

export const StyledShiftCardBlock = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: 'calc(100% - 4px)',

  '@media print': {
    display: 'block',
    minHeight: 100,
    breakBefore: 'auto',
    breakInside: 'avoid-page',
  },
})

export const StyledShiftCardButton = styled.button<{
  isAllDay: boolean
  dragging: boolean
}>(({ isAllDay, dragging }) => ({
  background: 'none',
  border: 0,
  padding: 0,
  height: isAllDay ? '100%' : 45,
  minHeight: 45,
  opacity: dragging ? 0.4 : 1,
  '&:not(:first-child)': {
    marginTop: 5,
  },
  outline: '0',
  '&:focus': {
    outline: 0,
  },
  '@media print': {
    height: 45,
    width: '100%',
  },
}))

const shiftCardThemeCss = (cardType: ShiftCardType) => {
  switch (cardType) {
    case ShiftCardType.Role:
    case ShiftCardType.Person:
      return {
        border: '1px solid var(--gray5)',
        borderLeft: '5px solid var(--iris-blue)',
        '.avatar': {
          backgroundColor: 'var(--iris-blue)',
          color: 'var(--white)',
        },
        '.shift-label': {
          color: 'var(--gray1)',
        },
      }
    case ShiftCardType.Unassigned:
      return {
        border: '1px dashed var(--gray5)',
        borderLeft: '5px solid var(--gray4)',
        '.avatar': {
          backgroundColor: 'var(--gray4)',
          color: 'var(--white)',
        },
        '.shift-label': {
          color: 'var(--gray1)',
        },
      }
    case ShiftCardType.Error:
      return {
        border: 'solid 1px var(--red)',
        borderLeft: '5px solid var(--red)',
        '.shift-label': {
          color: 'var(--gray1)',
        },
      }
    case ShiftCardType.Unavailable:
      return {
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        background:
          'repeating-linear-gradient(135deg, #F0F0F0, #F0F0F0 2px, ' +
          'var(--gray7) 2px, var(--gray7) 7px )',
        padding: '0px 9px',
      } as const
    case ShiftCardType.UnavailablePerson:
      return {
        background:
          'repeating-linear-gradient(135deg, #F0F0F0, #F0F0F0 2px, ' +
          'var(--gray7) 2px, var(--gray7) 7px )',
        padding: '0px 9px',
      }
    case ShiftCardType.UnavailableError:
      return {
        background:
          'repeating-linear-gradient( 135deg, #FDE2E2, #FDE2E2 2px, ' +
          '#FEEFEF 2px, #FEEFEF 5px)',
        border: 'solid 1px var(--red)',
        color: 'var(--red)',
        padding: '0px 8px',
        '.shift-label': {
          color: 'var(--red)',
        },
      }
  }
}

export const StyledShiftCard = styled.div<{ cardType: ShiftCardType }>(
  ({ cardType }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    background: 'var(--white)',
    borderRadius: 5,
    color: 'var(--gray4)',
    height: '100%',
    padding: '0px 9px 0px 4px',
    ...shiftCardThemeCss(cardType),

    '@media print': {
      WebkitFilter: 'drop-shadow(0px 2px 1px var(--gray6))',
      backgroundColor: 'var(--gray7)',
      width: '100%',
    },
  })
)

export const ShiftCardIcon = styled.div({
  marginRight: 8,
})

export const NotesIconImg = styled.img({
  width: '20px',
  height: '20px',
  marginLeft: 'auto',
})

export const ShiftCardText = styled.div({
  overflow: 'hidden',
  '*': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
})

export const StyledCellWithBorder = styled(Cell)<{ isFirstRow?: boolean }>(
  ({ isFirstRow }) => ({
    borderTop: `${isFirstRow ? 3 : 1}px solid var(--gray6)`,
    borderBottom: '1px solid var(--gray6)',
    '@media screen': {
      display: 'flex',
      flexDirection: 'column',

      paddingLeft: 0,
      paddingRight: 0,
    },
    '@media print': {
      borderTop: '3px solid var(--gray6)',
      borderBottom: '3px solid var(--gray6)',
    },
  })
)

export const StyledScheduleCell = styled(StyledCellWithBorder)({
  padding: 3,
  minHeight: 99,
})

export const BlankRowCell = styled(StyledScheduleCell)<{
  numScheduleRows: number
  isShowingSchedule: boolean
}>(({ isShowingSchedule, numScheduleRows }) => ({
  // The blank row's height should be dynamic to ensure that the schedule rows
  // take up the full screen (and you don't see any of the "planning" rows).
  // Calculate the height by taking the page height and removing the height of
  // the Predictions Row, the footer, and the schedule rows.
  height: isShowingSchedule
    ? `calc(100vh - ${PREDICTION_ROW_HEIGHT}px - ${PLANNING_FOOTER_HEIGHT *
        2}px - ${numScheduleRows * 100}px)`
    : 'initial',
}))

export const StyledCategoryRowCell = styled(Cell)(categoryRowStyle)

export const StyledCategoryRowChevron = styled.div({
  '> path': {
    stroke: '#000000',
  },
  marginLeft: 7,
})

export const StyledUpChevron = styled(UpChevronRaw)({
  width: 15,
})

export const StyledDownChevron = styled(DownChevronRaw)({
  top: -4,
  width: 15,
})

export const StyledFirstLastCell = styled(StyledCellWithBorder)({
  '@media screen': {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'row',
  },
  '@media print': {
    verticalAlign: 'middle',
    textAlign: 'left',
    width: 100,
  },
  fontSize: '0.875rem',
  fontWeight: 800,
  color: 'var(--gray1)',
})

export const StyledFirstCell = styled(StyledFirstLastCell)({
  paddingTop: 11,
  paddingLeft: '2.5rem',
  lineHeight: '30px',
  '@media all': {
    '& div': {
      marginRight: 10,
    },
    '& .avatar': {
      marginLeft: -15,
    },
  },
})

export const StyledLastCell = styled(StyledFirstLastCell)({
  paddingTop: 14,
  paddingLeft: '28px !important',
})

export const ShiftHoverIndicator = styled.div({
  width: '100%',
  border: '1px dashed var(--gray3)',
  boxSizing: 'border-box',
  borderRadius: 4,
  height: 45,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'end',
  alignItems: 'center',
  color: 'var(--gray3)',
})

export const StyledAddShiftButton = styled.button({
  alignSelf: 'center',
  marginTop: 3,
  marginBottom: 3,
  width: 'calc(100% - 4px)',
  minHeight: 45,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& img, & span': {
    opacity: 0,
  },
  '&:hover': {
    boxShadow: '0 0 6px rgba(0, 0, 0, 0.25)',
    '& img, & span': { opacity: 1 },
  },
  border: '0',
  outline: '0',
  '&:focus': {
    outline: '0',
  },
  backgroundColor: 'transparent',
  padding: '0',

  '@media print': {
    display: 'none',
  },
})

export const StyledAddShiftButtonSmall = styled(StyledAddShiftButton)({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  paddingLeft: 12,
})

export const StyledAddShiftButtonLabel = styled.span({
  color: 'var(--gray3)',
  fontSize: '0.75rem',
  fontWeight: 800,
  padding: '3px 8px',
})

export const ModalFooter = styled(Modal.Footer)({
  display: 'flex',
  flexDirection: 'column',
})

export const ModalButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignSelf: 'flex-end',
})

export const ShiftEditButton = styled(Button)({
  marginRight: 8,
})

export const StyledScheduleDailyTotalBarTrack = styled.div<{ isOver: boolean }>(
  ({ isOver }) => ({
    height: 5,
    borderRadius: 2.5,
    backgroundColor: isOver ? 'var(--iris-blue)' : 'var(--gray5)',
    position: 'relative',
    marginTop: 5,
    width: '100%',

    '@media print': {
      display: 'none',
    },
  })
)

export const StyledScheduleDailyTotalBarBorderBar = styled.div<{
  isOver: boolean
  percent: number
}>(({ isOver, percent }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  width: `${percent}%`,
  borderRadius: 2.5,
  backgroundColor: isOver ? 'var(--gray7)' : 'var(--iris-blue)',
  paddingRight: isOver ? 3 : 0,
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledScheduleDailyTotalBarInnerBar = styled.div({
  flex: 1,
  borderRadius: 2.5,
  backgroundColor: 'var(--red)',
})

export const bottomRowStyle = () => ({
  '@media screen': {
    [MAKE_ROWS_STICKY]: {
      zIndex: 1,
    },
  },
  position: 'sticky' as 'sticky',
  bottom: `${PLANNING_FOOTER_HEIGHT}px`,
  filter:
    'drop-shadow(1px -4px 2px rgba(0,0,0,0.20)) drop-shadow(1px 2px 1px rgba(0,0,0,0.20))',
})

export const FirstCellsContainer = styled.div({
  paddingTop: 5,
})

export const FirstLaborPercentageCell = styled.div({
  '& span': {
    marginRight: 5,
    borderRightColor: 'black',
    borderRightWidth: 5,
  },
  paddingBottom: 5,
})

export const FirstDailyBreakdownCell = styled.div({
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
  color: 'var(--gray4)',
})

export const FirstTotalCell = styled.div({
  paddingBottom: 5,
})

export const StyledLaborPercentageCell = styled.div({
  width: '100%',
  textAlign: 'initial',
  marginBottom: '26px',
})

export const StyledDailyBreakdownCell = styled.button<{ disabled?: boolean }>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: inherit;
`
export const StyledDailyBreakdownDateCell = styled.button<{
  disabled?: boolean
}>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  width: 100%;
  margin-bottom: 20px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const StyledTotalCell = styled.div({
  paddingBottom: 5,
  width: '100%',
  cursor: 'pointer',
  marginBottom: 17.5,
})

export const StyledPercentageTooltipIcon = styled.div({
  paddingLeft: 5,
})

export const StyledDivContainer = styled.div({})

export const StyledFirstFooterCell = styled(StyledFirstCell)(() => ({
  ...bottomRowStyle(),
}))

export const StyledFooterCell = styled(Cell)(() => ({
  ...regularBodyBoldTextStyle,
  ...bottomRowStyle(),
  paddingTop: 10,
  paddingLeft: 14,
  paddingRight: 14,
  flexDirection: FLEX_DIRECTION_COLUMN,
  alignItems: 'flex-start !important',
}))

export const StyledScheduleDailyTotalHours = styled.span({
  display: 'flex',
  fontSize: '14px',
  fontWeight: 400,
  color: 'var(--gray1)',
  flexDirection: 'row',
  whiteSpace: 'nowrap',
})

export const StyledScheduleDailyPercentageCost = styled.div({
  display: 'flex',
  fontSize: '11px',
  fontWeight: 600,
  color: 'var(--gray1)',
  flexDirection: 'row',
  whiteSpace: 'nowrap',
})

export const StyledDailyBreakdown = styled.div({
  display: 'flex',
  fontSize: '10px',
  height: '10px',
  fontWeight: 800,
  color: 'var(--gray4)',
  flexDirection: 'row',
  textUnderlinePosition: 'under',
  textDecoration: 'underline solid',
})

export const StyledDuration = styled.div<{ isEndTimeNextDay?: boolean }>(
  ({ isEndTimeNextDay }) => ({
    flexWrap: 'nowrap',
    alignSelf: 'center',
    fontSize: '0.75rem',
    lineHeight: '0.9375rem',
    fontWeight: 800,
    color: isEndTimeNextDay ? 'var(--red)' : 'var(--gray2)',
  })
)

export const ViolationWrapper = styled.div({
  marginRight: 10,
  paddingBottom: 8,
})

export const ViolationTitle = styled(TableLabelBoldText)({
  fontWeight: 700,
})

export const ViolationDetails = styled.p({
  fontWeight: 600,
  fontSize: '0.75rem',
  lineHeight: '16px',
  color: 'var(--gray3)',
  marginLeft: 8,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
})

export const ViolationPopoverTitle = styled(Popover.Title)({
  background: 'var(--mistyrose)',
  display: 'flex',
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 10,
  paddingRight: 10,
})

export const ShiftModalViolationsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% + 24px)',
  marginLeft: -12,
  marginRight: -12,
  marginTop: -13,
})

export const ViolationBanner = styled.div({
  display: 'flex',
  alignItems: 'center',
  background: 'var(--mistyrose)',
  width: '100%',
  paddingLeft: 25,
  paddingTop: 8,
  paddingBottom: 8,
})

export const ShiftModalViolationsTitle = styled(RegularBoldBodyText)({
  marginLeft: 15,
})

export const ModalViolationsListWrapper = styled.div({
  paddingLeft: 37,
  paddingTop: 13,
})

export const TimeRow = styled.div({
  display: 'flex',
  alignItems: 'center',
})

export const TimeInputContainer = styled.div({
  marginRight: 23,
})

export const TextAreaContainer = styled.div({
  marginRight: 23,
  width: '100%',
})

export const ShiftSwapMessageContainer = styled.div({
  display: 'flex',
})

export const ShiftSwapMesage = styled.div({
  marginLeft: '10px',
})

export const ShiftSwapMessageHeader = styled.h4({
  fontSize: '17px',
})

export const ShiftSwapMessageText = styled.p({
  fontSize: '12px',
})

export const ShiftSwapMessageLink = styled(Link)({
  textDecoration: 'underline',
  color: 'var(--blue)',
})

export const BreakSelectRow = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '5px 0',
})

export const LabelWithDescription = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  fontSize: '0.75rem',
})

export const LabelDescription = styled(RegularBodyText)({
  fontSize: '0.75rem',
  lineHeight: 1.25,
  marginLeft: '5px',
  color: 'var(--gray4)',
})

export const EmployeeHoursScheduled = styled.span<{ hide: boolean }>(
  ({ hide }) => ({
    fontSize: '0.875rem',
    fontWeight: 800,
    color: 'var(--gray4)',
    textDecoration: 'underline solid',
    display: hide ? 'none' : 'block',
    lineHeight: '18px',
    cursor: 'pointer',
  })
)

export const RowTextWrapper = styled.div<{ employeeHours: boolean }>(
  ({ employeeHours }) => ({
    height: '28px',
    lineHeight: employeeHours ? '15px' : 'inherit',
  })
)

export const StyledUnorderedList = styled.ul({
  paddingLeft: '10px',
})

export const AutoScheduleBtnText = styled.span`
  padding-left: 4px;
`

export const autoScheduleStyles = css`
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      101deg,
      #07ccd4 21.78%,
      #3b4fff 47.12%,
      #bb59e0 71.64%,
      #f6726a 95.76%
    );
`

export const AutoScheduleText = styled.span`
  text-align: center;
  // font-size: 12px;
  // line-height: 1.07;
  font-weight: 800;
  bold: true;

  ${autoScheduleStyles};

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const StyledNoScheduleText = styled.span`
  color: var(--gray4);
  font-weight: 800;
  font-size: 12px;
  text-align: center;
`
