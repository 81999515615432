import React from 'react'
import { linearGradientDef } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { TopPanelMonthMetric, TopPanelWeekMetric } from 'pages/covid/types'
import {
  LineChartWrapper,
  GraphTimeframe,
  GraphTimeFrameWrapper,
} from 'pages/covid/dashboard/addressRow/styles'
import { getColorForTrend } from 'pages/covid/dashboard/addressRow/getColorForTrend'

// Missing type definition
declare module '@nivo/core' {
  export function linearGradientDef(a: any, b: any): any
}

interface Props {
  metric: TopPanelMonthMetric | TopPanelWeekMetric
  timeframe: 'month' | 'week'
}

export const LocationChart = ({
  metric: {
    sentiment,
    trend: { x, y },
  },
  timeframe,
}: Props) => {
  const { t } = useTranslation()
  const seriesData = x.map((xValue, index) => ({ x: xValue, y: y[index] }))
  const data = [{ id: '1', data: seriesData }]
  const color = getColorForTrend(sentiment)

  // Convert x from nanoseconds (Python scientific computing timestamp) to milliseconds
  const milliSecondUnix = x[0] / 10 ** 6
  const firstDayOfSeries = DateTime.fromMillis(milliSecondUnix)
  const displayDate = `${firstDayOfSeries.monthShort} ${firstDayOfSeries.year}`

  return (
    <>
      <LineChartWrapper>
        <ResponsiveLine
          colors={color}
          margin={{ top: 5 }}
          data={data}
          animate
          enableArea
          enableSlices="x"
          enableGridX={false}
          enableGridY={false}
          enablePoints={false}
          isInteractive={false}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          yScale={{ type: 'linear', stacked: true }}
          curve="basis"
          defs={[
            linearGradientDef('gradientA', [
              { offset: 0, color: 'inherit' },
              { offset: 200, color: 'inherit', opacity: 0 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
        />
      </LineChartWrapper>
      <GraphTimeFrameWrapper>
        <GraphTimeframe color={color}>{displayDate}</GraphTimeframe>
        <GraphTimeframe color={color}>
          {t('covidDashboard.thisTimeframe', { timeframe })}
        </GraphTimeframe>
      </GraphTimeFrameWrapper>
    </>
  )
}
