import { Interval, DateTime } from 'luxon'
import { findIndex, uniqWith, isEqual } from 'lodash'

import { ShiftFragment, ShiftRoleFragment } from 'config/graphqlTypes'
import { getFormattedSingleShiftTime } from 'utils/getFormattedSingleShiftTime'

export interface ShiftSegment {
  shift?: ShiftFragment
  startAt: DateTime
  endAt: DateTime
  labels: string[]
  other: boolean
  conflict: boolean
}

interface CreateSegmentArgs {
  shift: ShiftFragment
  startAt: DateTime
  endAt: DateTime
  shiftRole: ShiftRoleFragment
  existingFirstLabel?: string
}

const createSegments = (
  createSegment: (args: CreateSegmentArgs) => ShiftSegment
) => (shifts: ShiftFragment[]) =>
  shifts.map(shift => {
    const { id, shiftRole, startAt, endAt } = shift
    const shiftInterval = Interval.fromDateTimes(startAt, endAt)

    const segment = createSegment({
      shift,
      startAt,
      endAt,
      shiftRole: shiftRole!,
    })

    const otherShiftsWithoutSelf = shifts.filter(s => s.id !== id)

    // Look for other shifts that overlap with this one. If so, combine them
    // into a single segment.
    return otherShiftsWithoutSelf.reduce((segment, otherShift) => {
      const overlaps = Interval.fromDateTimes(
        otherShift.startAt,
        otherShift.endAt
      ).overlaps(shiftInterval)

      if (!overlaps) {
        return segment
      }

      // Expand the segment time so that it subsumes the conflicting shift
      const newStartAt = DateTime.min(segment.startAt, otherShift.startAt)
      const newEndAt = DateTime.max(segment.endAt, otherShift.endAt)

      return createSegment({
        shift,
        startAt: newStartAt,
        endAt: newEndAt,
        shiftRole: otherShift.shiftRole!,
        existingFirstLabel: segment.labels[0],
      })
    }, segment as ShiftSegment)
  })

const createRoleSegment = ({ shift, startAt, endAt }: CreateSegmentArgs) => {
  const startTime = getFormattedSingleShiftTime(startAt)
  const endTime = getFormattedSingleShiftTime(endAt)
  const labels = [startTime, endTime]

  return { shift, startAt, endAt, labels, conflict: false, other: false }
}

const getRoleSegments = createSegments(createRoleSegment)

const createOtherSegment = ({
  shift,
  startAt,
  endAt,
  shiftRole: { name },
  existingFirstLabel,
}: CreateSegmentArgs) => ({
  shift,
  startAt,
  endAt,
  labels: [(existingFirstLabel ? `${existingFirstLabel}/` : '') + name, ''],
  conflict: false,
  other: true,
})

const getOtherSegments = createSegments(createOtherSegment)

const getConflictSegments = (
  roleShifts: ShiftFragment[],
  otherShifts: ShiftFragment[]
) => {
  const createConflictSegment = (startAt: DateTime, endAt: DateTime) => ({
    startAt: startAt,
    endAt: endAt,
    labels: [
      getFormattedSingleShiftTime(startAt),
      getFormattedSingleShiftTime(endAt),
    ],
    conflict: true,
    other: false,
  })

  return roleShifts
    .map(shift => {
      const shiftInterval = Interval.fromDateTimes(shift.startAt, shift.endAt)

      return otherShifts.reduce((conflicts, otherShift) => {
        const otherShiftInterval = Interval.fromDateTimes(
          otherShift.startAt,
          otherShift.endAt
        )

        const isConflict = otherShiftInterval.overlaps(shiftInterval)

        if (!isConflict) {
          return conflicts
        }

        // Either extend an existing conflict or add a new conflict

        const overlappingConflict = conflicts.find(({ startAt, endAt }) =>
          otherShiftInterval.overlaps(Interval.fromDateTimes(startAt, endAt))
        )

        if (overlappingConflict) {
          const minStartAt = DateTime.min(
            otherShift.startAt,
            overlappingConflict.startAt
          )
          const maxEndAt = DateTime.min(
            otherShift.endAt,
            overlappingConflict.endAt
          )

          const updatedStartOfConflict = DateTime.max(shift.startAt, minStartAt)
          const updatdEndOfConflict = DateTime.min(shift.endAt, maxEndAt)

          const updatedConflict = createConflictSegment(
            updatedStartOfConflict,
            updatdEndOfConflict
          )

          const index = findIndex(conflicts, overlappingConflict)

          return conflicts.splice(index, 1, updatedConflict)
        }

        const startOfConflict = DateTime.max(shift.startAt, otherShift.startAt)
        const endOfConflict = DateTime.min(shift.endAt, otherShift.endAt)

        const newConflict = createConflictSegment(
          startOfConflict,
          endOfConflict
        )

        return [...conflicts, newConflict]
      }, [] as ShiftSegment[])
    })
    .flat()
}

export const getShiftSegments = (
  roleShifts: ShiftFragment[],
  otherShifts: ShiftFragment[]
) => {
  const roleSegments = getRoleSegments(roleShifts)
  const otherSegments = getOtherSegments(otherShifts)
  const conflictSegments = getConflictSegments(roleShifts, otherShifts)

  const segments = [...roleSegments, ...otherSegments, ...conflictSegments]

  // Because segments link-up and because we map segments from shifts, there is
  // a possibility that multiple shifts will link up into identical segments.
  // Therefore, eliminate duplicates.
  return uniqWith(segments, isEqual)
}
