import { useGetToucanAuthQuery } from 'config/graphqlTypes'
import { DateTime } from 'luxon'

export const useGetToucanAuth = (
  startDate: DateTime,
  endDate: DateTime,
  locationIds: string[]
) => {
  const { data, loading } = useGetToucanAuthQuery({
    variables: { startDate, endDate, locationIds },
    fetchPolicy: 'network-only',
  })

  return { token: data?.toucanAuth?.token, loading }
}
