import React from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'

import { CovidTrends } from 'pages/covid/types'
import {
  CovidCardDivider,
  CovidSourceText,
  CovidCardContentWrapper,
  CovidDataDisclaimer,
} from 'pages/covid/dashboard/addressRow/styles'
import { LargeBodyBoldText } from 'components/Text'
import { CovidMetric } from 'pages/covid/dashboard/addressRow/CovidMetric'

interface Props {
  covidTrends: CovidTrends
  dashboardSaved: boolean
}

export const CovidCard = ({
  covidTrends: { local_cases, local_hospitalizations, local_deaths, state },
  dashboardSaved,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div>
      <CovidCardDivider />
      <CovidCardContentWrapper>
        <LargeBodyBoldText>
          {t('covidDashboard.covidTracking')}
        </LargeBodyBoldText>
        <CovidSourceText>
          {t('covidDashboard.trackingViaCDC', { state })}
        </CovidSourceText>
        <Row xs={1} xl={4} noGutters>
          <CovidMetric
            trendMetric={local_cases}
            header={t('covidDashboard.localCases')}
            dashboardSaved={dashboardSaved}
          />
          <CovidMetric
            trendMetric={local_hospitalizations}
            header={t('covidDashboard.localHospitalizations')}
            dashboardSaved={dashboardSaved}
          />
          <CovidMetric
            trendMetric={local_deaths}
            header={t('covidDashboard.localDeaths')}
            dashboardSaved={dashboardSaved}
          />
        </Row>
        <CovidDataDisclaimer>
          {t('covidDashboard.dataDisclaimer')}
        </CovidDataDisclaimer>
      </CovidCardContentWrapper>
    </div>
  )
}
