import React, { FC, ReactNode, useContext } from 'react'
import { AccordionContext, useAccordionToggle } from 'react-bootstrap'
import { ExpandCollapseIcon } from '../ExpandCollapseIcon'
import { LaborHrsBreakdownAccordionCollapseButton } from './styles'

type LaborHoursBreakdownAccordionToggleProps = {
  eventKey: string
  children: ReactNode
}

export const LaborHoursBreakdownAccordionToggle: FC<LaborHoursBreakdownAccordionToggleProps> = ({
  eventKey,
  children,
}) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(eventKey)

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <>
      <LaborHrsBreakdownAccordionCollapseButton onClick={decoratedOnClick}>
        <ExpandCollapseIcon isExpanded={isCurrentEventKey} />
      </LaborHrsBreakdownAccordionCollapseButton>
      {children}
    </>
  )
}
