import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  HeaderRowStyled,
  HeaderCol,
  HeaderTitle,
  HeaderSubTitle,
} from 'pages/covid/dashboard/styles'

interface HeaderProps {
  titleTransKey: string
  subtitleTransKey: string
  isFirst?: boolean
}

const Header = ({ titleTransKey, subtitleTransKey, isFirst }: HeaderProps) => {
  const { t } = useTranslation()

  return (
    <HeaderCol xl={isFirst ? 4 : 2}>
      <HeaderTitle>{t(titleTransKey)}</HeaderTitle>
      <HeaderSubTitle>{t(subtitleTransKey)}</HeaderSubTitle>
    </HeaderCol>
  )
}

export const YourNeighborhoodHeader = () => {
  return (
    <Header
      titleTransKey="covidDashboard.yourLocations"
      subtitleTransKey="covidDashboard.yourLocationsSubtitle"
      isFirst
    />
  )
}

export const WeeklyVisitsHeader = () => (
  <Header
    titleTransKey="covidDashboard.weeklyVisits"
    subtitleTransKey="covidDashboard.weeklyVisitsSubtitle"
  />
)

export const MonthlyVisitLengthHeader = () => (
  <Header
    titleTransKey="covidDashboard.monthlyVisitLength"
    subtitleTransKey="covidDashboard.monthlyVisitLengthSubtitle"
  />
)

export const MonthlyVisitorsHeader = () => (
  <Header
    titleTransKey="covidDashboard.monthlyVisitors"
    subtitleTransKey="covidDashboard.monthlyVisitorsSubtitle"
  />
)

export const MonthlyDistanceTravelledHeader = () => (
  <Header
    titleTransKey="covidDashboard.monthlyDistanceTravelled"
    subtitleTransKey="covidDashboard.monthlyDistanceTravelledSubtitle"
  />
)

export const HeaderRow = () => (
  <HeaderRowStyled noGutters>
    <YourNeighborhoodHeader />
    <WeeklyVisitsHeader />
    <MonthlyVisitLengthHeader />
    <MonthlyVisitorsHeader />
    <MonthlyDistanceTravelledHeader />
  </HeaderRowStyled>
)
