import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  IntervalOrderSumFragment,
  IntervalPredictionSumFragment,
  SalesDayPartFragment,
} from 'config/graphqlTypes'
import { asDollars } from 'utils/currency'
import sumOrNull from 'utils/sumOrNull'
import { Cell, TableHeader } from 'pages/tracking/breakdown/styles'
import {
  Table,
  PredictionLabel,
  ActualLabel,
} from 'pages/tracking/breakdown/Table'
import { useCurrentLocationCategories } from 'queries/useCurrentLocation'

interface Props {
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  salesType: string
  locationSalesDayParts: SalesDayPartFragment[]
}

interface ShiftSalesProps {
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  allTotals?: boolean
  salesType: string
}

const ShiftSales = ({
  orderSums,
  predictionSums,
  allTotals,
  salesType,
}: ShiftSalesProps) => {
  const predictionsTotal = sumOrNull(predictionSums.map(p => p.reportedSales))
  const ordersTotal = sumOrNull(orderSums.map(o => o.reportedSales))

  return (
    <>
      <Cell total={allTotals} title={asDollars(predictionsTotal, true)}>
        {asDollars(predictionsTotal)}
      </Cell>
      <Cell actual total={allTotals} title={asDollars(ordersTotal, true)}>
        {asDollars(ordersTotal)}
      </Cell>
    </>
  )
}

interface RowProps {
  title: string
  salesDayParts: SalesDayPartFragment[]
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  allTotals?: boolean
  salesType: string
}

const Row = ({
  allTotals,
  title,
  salesDayParts,
  orderSums,
  predictionSums,
  salesType,
}: RowProps) => {
  const predictionsTotal = sumOrNull(predictionSums.map(p => p.reportedSales))
  const ordersTotal = sumOrNull(orderSums.map(o => o.reportedSales))

  return (
    <tr>
      <Cell category total={allTotals}>
        {title}
      </Cell>
      {salesDayParts.map(salesDayPart => (
        <ShiftSales
          key={salesDayPart.dayPartTitle}
          allTotals={allTotals}
          predictionSums={predictionSums.filter(p => {
            return (
              p.startAt >= salesDayPart.startAt && p.endAt <= salesDayPart.endAt
            )
          })}
          orderSums={orderSums.filter(o => {
            return (
              o.startAt >= salesDayPart.startAt && o.endAt <= salesDayPart.endAt
            )
          })}
          salesType={salesType}
        />
      ))}
      <Cell total title={asDollars(predictionsTotal, true)}>
        {asDollars(predictionsTotal)}
      </Cell>
      <Cell actual total title={asDollars(ordersTotal, true)}>
        {asDollars(ordersTotal)}
      </Cell>
    </tr>
  )
}

export const CurrentDayTable = ({
  orderSums,
  predictionSums,
  salesType,
  locationSalesDayParts,
}: Props) => {
  const { t } = useTranslation()

  const salesDayPartTitles = locationSalesDayParts.map(s => s.dayPartTitle)

  const [firstShift, ...otherShifts] = salesDayPartTitles
  const { categories, categoryNames } = useCurrentLocationCategories()

  return (
    <Table
      title={t('tracking.todaysProgress.breakdownSales')}
      headers={
        <>
          <TableHeader colSpan={1} key={`${firstShift}-sales`}>
            <span>{firstShift}</span>
            <PredictionLabel />
          </TableHeader>
          <TableHeader colSpan={1} key={`${firstShift}-actual-sales`}>
            <span className="invisible">{firstShift}</span>
            <ActualLabel />
          </TableHeader>
          {otherShifts.map(shift => (
            <>
              <TableHeader colSpan={1} key={`${shift}-sales`}>
                <span>{shift}</span>
                <PredictionLabel />
              </TableHeader>
              <TableHeader colSpan={1} key={`${shift}-sales`}>
                <span className="invisible">{shift}</span>
                <ActualLabel />
              </TableHeader>
            </>
          ))}
          <TableHeader colSpan={1}>
            <span>{t('tracking.todaysProgress.breakdownTotal')}</span>
            <PredictionLabel />
          </TableHeader>
          <TableHeader colSpan={1}>
            <span className="invisible">
              {t('tracking.todaysProgress.breakdownTotal')}
            </span>
            <ActualLabel />
          </TableHeader>
          <TableHeader />
        </>
      }
      rows={
        <>
          {categoryNames.map((categoryName: string) => (
            <Row
              key={`${categories[categoryName].join('-')}-sales`}
              salesDayParts={locationSalesDayParts}
              title={categoryName}
              orderSums={orderSums.filter(
                s => categories[categoryName].indexOf(s.category) > -1
              )}
              predictionSums={predictionSums.filter(
                s => categories[categoryName].indexOf(s.category) > -1
              )}
              salesType={salesType}
            />
          ))}
          <Row
            salesDayParts={locationSalesDayParts}
            title={t('tracking.todaysProgress.breakdownTotal')}
            orderSums={orderSums}
            predictionSums={predictionSums}
            salesType={salesType}
            allTotals
          />
        </>
      }
    />
  )
}
