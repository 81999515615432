import { ReactNode, useMemo } from 'react'
import React, { useState } from 'react'
import { max, sum } from 'lodash'
import styled from 'styled-components'
import { Datum } from '@nivo/line'
import Measure, { ContentRect } from 'react-measure'
import { DateTime, Interval } from 'luxon'
import { useTranslation } from 'react-i18next'

import { SalesChartTooltip } from 'pages/tracking/SalesChart/SalesChartTooltip'
import {
  IntervalOrderSumFragment,
  IntervalPredictionSumFragment,
} from 'config/graphqlTypes'
import { buildChartData } from 'pages/tracking/SalesChart/buildChartData'
import { HorizontalGrid } from 'pages/tracking/SalesChart/HorizontalGrid'
import Chart from 'components/Chart'
import { MostRecentActualBackground } from 'pages/tracking/SalesChart/MostRecentActualBackground'
import { useGetOperatingHoursOrDefaults } from 'pages/plan/dailyViewModal/useGetOperatingHoursOrDefaults'
import { makeYAxisConfig } from 'pages/tracking/SalesChart/makeYAxisConfig'

export const salesChartHeight = 330

const Container = styled.div({
  height: salesChartHeight,
  width: '100%',
})

interface SalesChartProps {
  constrained?: boolean
  highlightMostRecentActual?: boolean
  isWeekView: boolean
  actualLaborHours: number | null
  actualLaborTotal: number | null
  orderSums: IntervalOrderSumFragment[]
  targetLaborHours: number | null
  targetLaborTotal: number
  predictionSums: IntervalPredictionSumFragment[]
  interval: Interval
  prefix: string
  salesType: string
}

const SalesChart: React.FC<SalesChartProps> = ({
  interval,
  constrained = false,
  actualLaborHours,
  actualLaborTotal,
  highlightMostRecentActual = false,
  isWeekView,
  orderSums,
  targetLaborHours,
  targetLaborTotal,
  predictionSums,
  prefix,
  salesType,
}) => {
  const { t } = useTranslation()

  const {
    openAt,
    closeAt,
    openOrDefault,
    closeOrDefault,
  } = useGetOperatingHoursOrDefaults(interval, { skip: isWeekView })

  const pastOpening = openOrDefault < DateTime.local()

  // To avoid timezone and nivo quarks, use an ISO date format for date view
  // and a unix timestamp for day view. This is ok because we have special logic
  // to handle the final formatting of the X-Axis ticks and tooltips.
  const xAxisFormat = isWeekView ? 'yyyy-LL-dd' : 'x'
  // See https://github.com/d3/d3-time-format
  const xAxisD3Format = isWeekView ? '%Y-%m-%d' : '%Q'

  // Cache keys
  const predictionTotal = sum(predictionSums.map(p => p.reportedSales))
  const predictionCount = predictionSums.length
  const orderTotal = sum(
    orderSums.map(p => (p.reportedSales ?? 0) + (p.futureReportedSales ?? 0))
  )
  const orderCount = orderSums.length
  const timeOfLastOrderSum =
    orderSums[orderSums.length - 1]?.endAt?.valueOf() || 0
  const openOrDefaultEpoch = openOrDefault.valueOf()
  const closeOrDefaultEpoch = closeOrDefault.valueOf()

  const getDisplayTimestamp = (dateTime: DateTime, isForXAxis = false) => {
    const amPm = dateTime.toFormat('a').toLowerCase()
    if (isWeekView) {
      // Show the realtime hour for latest week-view tooltiop
      if (!isForXAxis && dateTime.hour !== 23) {
        return dateTime.toFormat('ccc h:mm') + amPm
      }
      return dateTime.toFormat('ccc').substring(0, 2)
    }
    if (openAt?.hasSame(dateTime, 'minute')) {
      return t('charts.open')
    }
    if (closeAt?.hasSame(dateTime, 'minute')) {
      return t('charts.close')
    }
    if (dateTime.minute > 0) {
      return dateTime.toFormat('h:mm') + amPm
    }
    return dateTime.toFormat('h') + amPm
  }

  const unconstrainedData = useMemo(
    () =>
      buildChartData(
        orderSums,
        predictionSums,
        openOrDefault,
        closeOrDefault,
        xAxisFormat,
        isWeekView,
        salesType,
        getDisplayTimestamp
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      predictionTotal,
      predictionCount,
      orderTotal,
      orderCount,
      openOrDefaultEpoch,
      closeOrDefaultEpoch,
      xAxisFormat,
      isWeekView,
      timeOfLastOrderSum,
    ]
  )

  const [width, setWidth] = useState(0)
  const onResize = ({ bounds }: ContentRect) => bounds && setWidth(bounds.width)

  const stepSize = (() => {
    const maxNumberOfPoints =
      max(unconstrainedData.map(s => s.data.length)) || 1
    const maximumPointsPerWidth = 0.03
    const pointsPerWidth = maxNumberOfPoints / (width || 1)
    return pointsPerWidth < maximumPointsPerWidth ? 1 : 2
  })()

  const data = unconstrainedData.map(({ data, ...serie }) => ({
    ...serie,
    data: data.reduce<Datum[]>(
      (acc, datum, index, arr) =>
        index % stepSize === 0 || index === arr.length - 1
          ? [...acc, datum]
          : acc,
      []
    ),
  }))

  const getXAxisLabel = (formattedTimestamp: string) =>
    getDisplayTimestamp(
      isWeekView
        ? DateTime.fromFormat(formattedTimestamp, xAxisFormat)
        : DateTime.fromMillis(parseInt(formattedTimestamp)),
      true
    )

  const actualLaborInDollars = (actualLaborTotal || 0) / 100
  const targetLaborInDollars = (targetLaborTotal || 0) / 100

  const wrapperTestId = `sales-chart-${prefix}-${isWeekView ? 'week' : 'day'}`

  const TooltipComponent = SalesChartTooltip(
    data,
    isWeekView,
    getDisplayTimestamp
  )

  const yAxisConfig = makeYAxisConfig(
    data,
    actualLaborInDollars,
    targetLaborInDollars,
    constrained ? -20 : 20
  )

  return (
    <Measure bounds onResize={onResize}>
      {({ measureRef }) => (
        <Container ref={measureRef} data-testid={wrapperTestId}>
          <Chart
            data={data}
            layers={
              ([
                HorizontalGrid(
                  actualLaborInDollars,
                  actualLaborHours || 0,
                  targetLaborInDollars,
                  targetLaborHours || 0,
                  prefix
                ),
                MostRecentActualBackground(
                  highlightMostRecentActual && pastOpening
                ),
              ] as unknown) as ReactNode[]
            }
            margin={{
              bottom: 42,
              left: constrained ? 24 : 70,
              right: 70,
              top: 30,
            }}
            tooltip={TooltipComponent}
            xAxisFormat={xAxisD3Format}
            precision={isWeekView ? 'day' : 'minute'}
            xAxisScale={isWeekView ? 'every day' : 'every hour'}
            getXAxisLabel={getXAxisLabel}
            stepSize={stepSize}
            yAxisConfig={yAxisConfig}
            highlightMostRecentActual={highlightMostRecentActual && pastOpening}
            width={width}
          />
        </Container>
      )}
    </Measure>
  )
}

export default SalesChart
