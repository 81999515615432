// const suffixes = /jr|ii|iii/i

export const getFirstNameAndLastInitial = ({ name }: { name: string }) => {
  return name
}

export const getInitialsFromName = ({ name }: { name: string }) => {
  return (
    name
      ?.match(/(^\S\S?|\b\S)?/g)
      ?.join('')
      ?.match(/(^\S|\S$)?/g)
      ?.join('')
      ?.toUpperCase() ?? ''
  )
}
