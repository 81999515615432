import React from 'react'
import { FC } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import numeral from 'numeral'

import { PlanForReportingFragment } from 'config/graphqlTypes'
import {
  StyledReportingTableRow,
  StyledReportingCell,
} from 'pages/reporting/styles'
import { ReportingDataCell } from 'pages/reporting/ReportingDataCell'

const formatLaborHours = (t: TFunction, hours: number | null) =>
  t('misc.hourAbbreviationWithCountFormatted', {
    hours: numeral(hours).format('0,0.0[0]'),
    count: hours ? Number(numeral(hours).format('0,0.0[0]')) : 0,
  })

interface Props {
  plan: PlanForReportingFragment
  isLastRow: boolean
}

export const LaborScheduledRow: FC<Props> = ({ plan, isLastRow }) => {
  const { t } = useTranslation()

  return (
    <StyledReportingTableRow>
      <StyledReportingCell className={cx(isLastRow && 'last-row')} />
      {plan.dailySalesAndLaborSummaries.map(({ preScheduledLabor }, index) => (
        <ReportingDataCell
          labelTranslationKey={index === 0 ? 'reporting.scheduled' : undefined}
          className={cx(index % 2 === 0 && 'stripe', isLastRow && 'last-row')}
          key={index}
        >
          {formatLaborHours(t, preScheduledLabor)}
        </ReportingDataCell>
      ))}
      <ReportingDataCell className={cx('bold', isLastRow && 'last-row')}>
        {formatLaborHours(t, plan.weekSalesAndLaborSummary.preScheduledLabor)}
      </ReportingDataCell>
    </StyledReportingTableRow>
  )
}
