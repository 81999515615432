import { useState } from 'react'

export function useLocalStorage<T>(key: string) {
  const [value, setValue] = useState<T | null>(null)

  const removeItem = () => {
    localStorage.removeItem(key)
    setValue(null)
  }

  const setItem = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value))
    setValue(value)
  }

  const getItem = (): T => {
    if (value) return value

    const storedValue = localStorage.getItem(key)
    const parsedValue = storedValue ? JSON.parse(storedValue) : null

    parsedValue && setValue(parsedValue)
    return parsedValue
  }

  return { getItem, setItem, removeItem }
}
