import React, { useContext } from 'react'

import { GetPlanQueryVariables } from 'config/graphqlTypes'
import { DateTime } from 'luxon'

interface Variables {
  databaseId: string
  endOn: DateTime
  locationId: string
  startOn: DateTime
}

const PlanQueryVariablesContext = React.createContext<Variables | null>(null)

export const usePlanQueryVariables = () => {
  const { locationId, startOn, endOn } =
    useContext(PlanQueryVariablesContext) ?? {}
  const planQueryVariables = { locationId, startOn, endOn }
  if (!planQueryVariables) {
    throw new Error(
      'PlanQueryVariablesProvider not found! Did you forget to add it?'
    )
  }

  return planQueryVariables as GetPlanQueryVariables
}

export const usePlanId = () => {
  const { databaseId } = useContext(PlanQueryVariablesContext) ?? {}

  return databaseId
}

interface Props {
  planQueryVariables: Variables
  children: JSX.Element
}

export const PlanQueryVariablesProvider = ({
  planQueryVariables,
  children,
}: Props) => (
  <PlanQueryVariablesContext.Provider value={planQueryVariables}>
    {children}
  </PlanQueryVariablesContext.Provider>
)
