import React from 'react'
import { useTranslation } from 'react-i18next'
import BootstrapTable from 'react-bootstrap/Table'

import {
  Label,
  TableHead,
  TitleTableHeader,
  TableBody,
} from 'pages/tracking/breakdown/styles'

export const PredictionLabel = () => {
  const { t } = useTranslation()

  return <Label>{t('tracking.todaysProgress.breakdownPredictionLabel')}</Label>
}

export const ActualLabel = () => {
  const { t } = useTranslation()

  return (
    <Label actual>{t('tracking.todaysProgress.breakdownActualLabel')}</Label>
  )
}

interface TableProps {
  headers: JSX.Element
  rows: JSX.Element
  title: string
}

export const Table = ({ headers, rows, title }: TableProps) => (
  <BootstrapTable borderless striped responsive="sm" data-testid={title}>
    <TableHead>
      <tr>
        <TitleTableHeader>{title}</TitleTableHeader>
        {headers}
      </tr>
    </TableHead>
    <TableBody>{rows}</TableBody>
  </BootstrapTable>
)
