import React from 'react'
import AuthenticatedPage from 'pages/AuthenticatedPage'
import { ManagementWrapper } from 'pages/management/ManagementWrapper'
import {
  ManagementContainer,
  ManagementHeader,
  ManagementHeaderContainer,
  ManagementSubheader,
} from 'pages/management/styles'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { AddEditTimeOffBlockedDateForm } from './AddEditTimeOffBlockedDateForm'
import { useGetTimeOffBlockedDateQuery } from '../../../../../config/graphqlTypes'
import { Loading } from '../../../../../components/Loading'
import { useFeatureToggleEnabled } from '../../../../../contexts/FeatureToggles'

interface Params {
  id: string
}

export const AddEditTimeOffBlockedDate = () => {
  const { t } = useTranslation()
  const { id } = useParams<Params>()
  const isNew = !id

  const closedDaysToggleEnabled = useFeatureToggleEnabled(
    'capturingLocationClosedDays'
  )

  const { data, loading, error } = useGetTimeOffBlockedDateQuery({
    variables: { id: id! },
    skip: isNew,
  })

  if (loading) return <Loading />

  if (error)
    toast.error('Failed to load the time off blocked date', {
      toastId: 'error',
      position: toast.POSITION.BOTTOM_LEFT,
    })

  const title = isNew
    ? closedDaysToggleEnabled
      ? t('management.settings.organizations.blockedDates.addTitle')
      : 'Add a blocked date'
    : closedDaysToggleEnabled
    ? t('management.settings.organizations.blockedDates.editTitle')
    : 'Edit a blocked date'

  const subtitle = closedDaysToggleEnabled
    ? t('management.settings.organizations.blockedDates.subtitle')
    : 'Employees will not be able to request these days off.'

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeaderContainer>
            <ManagementHeader>{title}</ManagementHeader>
            <ManagementSubheader>{subtitle}</ManagementSubheader>
          </ManagementHeaderContainer>
          <ManagementContainer>
            {!error && (
              <AddEditTimeOffBlockedDateForm data={data?.timeOffBlockedDate} />
            )}
          </ManagementContainer>
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}
