import React, { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime, Interval } from 'luxon'
import Measure, { ContentRect } from 'react-measure'

import {
  IntervalPredictionSumFragment,
  SalesAndLaborSummaryFragment,
  ShiftFragment,
  MinimumLaborBaselineFragment,
} from 'config/graphqlTypes'
import Chart from 'components/Chart'
import {
  ChartWrapper,
  ChartInnerWrapper,
  smallScreenWidth,
  ChartMargins,
} from 'pages/plan/dailyViewModal/styles'
import { GridLines } from 'pages/plan/dailyViewModal/GridLines'
import { DailyViewTooltip } from 'pages/plan/dailyViewModal/DailyViewTooltip'
import { useGetOperatingHoursOrDefaults } from 'pages/plan/dailyViewModal/useGetOperatingHoursOrDefaults'
import { RightBackgroundLayer } from 'pages/plan/dailyViewModal/RightBackgroundLayer'
import {
  useMakeChartData,
  DailyChartDatum,
} from 'pages/plan/dailyViewModal/useMakeChartData'
import { Point } from '@nivo/line'
import { isPlannedLabor } from 'components/Chart/chartDataConfig'
import { getLaborCountForHour } from './useUnderMinimumLaborBaseline'
export const xAxisScale = 'every hour'

interface Props {
  day: Interval
  shiftsForDay: ShiftFragment[]
  predictionsForDay: IntervalPredictionSumFragment[]
  dailySalesAndLaborSummary: SalesAndLaborSummaryFragment
  onChangeWidth: (width: number) => void
  minimumLaborBaseline: MinimumLaborBaselineFragment[]
}

export const DailyViewChart: FC<Props> = ({
  day,
  shiftsForDay,
  predictionsForDay,
  dailySalesAndLaborSummary,
  onChangeWidth,
  minimumLaborBaseline,
}) => {
  const { t } = useTranslation()
  const [width, setWidth] = useState(0)
  const onResize = ({ bounds }: ContentRect) => {
    if (bounds) {
      setWidth(bounds.width)
      onChangeWidth(bounds.width)
    }
  }

  const { openAt, closeAt } = useGetOperatingHoursOrDefaults(day)

  const getDisplayTimestamp = (dateTime: DateTime, shouldCompact?: boolean) => {
    if (openAt?.equals(dateTime)) {
      return t('charts.open')
    }

    if (closeAt?.equals(dateTime)) {
      return t('charts.close')
    }

    const format = shouldCompact && width < smallScreenWidth - 75 ? 'h' : 'ha'

    return dateTime.toFormat(format).toLowerCase()
  }

  const data = useMakeChartData(
    day,
    shiftsForDay,
    predictionsForDay,
    dailySalesAndLaborSummary,
    getDisplayTimestamp
  )

  const xAxisTickCount = data[0].data.length
  const getXAxisLabel = (formattedTimestamp: string) => {
    const dateTime = DateTime.fromMillis(parseInt(formattedTimestamp))
    return getDisplayTimestamp(dateTime, true)
  }

  const getMinimumLaborBaseline = (time: DateTime) =>
    getLaborCountForHour(time, minimumLaborBaseline)

  const overridePointColor = (point: Point) => {
    if (minimumLaborBaseline && isPlannedLabor(point.serieId)) {
      // This is gross, but I don't think there's an easy want to get a custom
      // point type into nivo without changing the library types
      const data = (point.data as unknown) as DailyChartDatum
      const time = new Date(data.x)
      const dateTime = DateTime.fromISO(time.toISOString())
      const laborCount = getMinimumLaborBaseline(dateTime)

      const employeeCount = data.tooltipInfo?.employeeCount
      if (employeeCount < laborCount) {
        return 'var(--bittersweet)'
      }
    }
  }

  return (
    <Measure bounds onResize={onResize}>
      {({ measureRef }) => (
        <ChartWrapper ref={measureRef}>
          <ChartInnerWrapper width={width}>
            <Chart
              animate={false}
              data={data}
              layers={
                ([
                  RightBackgroundLayer,
                  GridLines(xAxisTickCount),
                ] as unknown) as ReactNode[]
              }
              margin={ChartMargins}
              tooltip={DailyViewTooltip(
                getDisplayTimestamp,
                getMinimumLaborBaseline
              )}
              xAxisFormat="%Q"
              precision="hour"
              xAxisScale={xAxisScale}
              getXAxisLabel={getXAxisLabel}
              stepSize={1}
              highlightMostRecentActual={false}
              overridePointColor={overridePointColor}
              width={width}
            />
          </ChartInnerWrapper>
        </ChartWrapper>
      )}
    </Measure>
  )
}
