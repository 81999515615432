import { useCurrentLocation } from 'queries/useCurrentLocation'
import {
  PlanFragment,
  useGetRelevantShiftsEmployeeQuery,
} from 'config/graphqlTypes'

export const useGetEmployeesShiftsForWeek = (
  planId: PlanFragment['id'],
  skip = false
) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  const { data, loading, refetch } = useGetRelevantShiftsEmployeeQuery({
    skip,
    variables: {
      planId,
      locationId,
    },
  })

  return {
    data: data?.relevantShiftsForPlan ?? [],
    loading,
    refetch,
  }
}
