import React, { useEffect, useState, FormEvent } from 'react'
import BootstrapForm from 'react-bootstrap/Form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import styled from 'styled-components'
import BootstrapTable from 'react-bootstrap/Table'

import 'react-phone-number-input/style.css'
import { Form, StringInputGroup, FormGroup } from 'components/Form'
import Button from 'components/Button'
import { EmployeeForEditFragment } from 'config/graphqlTypes'
import { routeMap, useLocationNavigation } from 'pages/Routes'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { useTryCurrentLocation } from 'queries/useCurrentLocation'
import { notify } from 'config/errorReporting'
import { useEmployeeValidations } from './EmployeeValidationUtils'
import { baseInputStyle } from 'config/styles'
import { DeleteEmployee } from './DeleteEmployee'
import { toast } from 'react-toastify'
import {
  DeleteButtonWrapper,
  FormWrapper,
  ButtonsWrapper,
  CancelButton,
  EmployeeInfoLabel,
  EmployeeLocationsTh,
  EmployeeLocationsThead,
  EmployeeLocationsTd,
} from '../styles'
import { InfoTooltipIcon } from 'components/InfoTooltipIcon'
import { useFeatureToggles } from '../../../contexts/FeatureToggles'
import { Spinner } from 'react-bootstrap'

const getWageInIntegerCents = (neWage: string) =>
  (parseFloat(neWage) * 100).toString()

const parseWageFloatString = (wage: string) =>
  (parseInt(wage) / 100).toFixed(2).toString()

const EMAIL_UNIQUENESS_ERROR = 'Email has already been taken'

export interface SubmitInput {
  name: string
  email: string
  phone: string | null
  wage: string | null
  employeeLocations: any
  currentPage?: number
}

// The PhoneInput doesn't work with our base `StringInput` but
// does work by styling the Bootstrap component directly
const StyledPhoneInput = styled(BootstrapForm.Control)(baseInputStyle)

interface Props {
  submitText: string
  submitForm: (input: SubmitInput) => Promise<string[]>
  employee?: EmployeeForEditFragment
  employeeLocation?: {
    active: boolean
    discardedAt: string | null
    excludeFromLabor: boolean
    modified?: boolean
  } | null
  currentPage?: number | undefined
}

export const EmployeeForm = ({
  submitText,
  submitForm,
  employee,
  employeeLocation,
  currentPage,
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { locationPath } = useLocationNavigation()
  const [busy, setBusy] = useState(false)

  const toggles = useFeatureToggles()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  })

  // Redirect to employees page if employee is discarded
  useEffect(() => {
    if (employeeLocation?.discardedAt) {
      history.push(locationPath + routeMap.location.management.employees)
    }
  }, [employeeLocation, history, locationPath])

  const { locations, organization } = useLoggedInUserOrRedirect()
  const manageEmployeeInfoToggle = toggles.features.find(
    t => t.name === 'manageEmployeeInfo'
  )

  const goToEmployees = () =>
    history.push(
      locationPath +
        routeMap.location.management.employees +
        `?currentPage=${currentPage}&employeeId=${employee?.id}`
    )

  const toastNotify = (msg: string) =>
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_LEFT,
    })

  const {
    calculateNameErrors,
    calculateEmailErrors,
    calculatePhoneErrors,
    calculateWageErrors,
  } = useEmployeeValidations()

  const [name, setName] = useState(employee?.name ?? '')
  const [email, setEmail] = useState(employee?.email ?? '')
  const [wage, setWage] = useState(
    employee?.wage ? parseWageFloatString(employee?.wage) : ''
  )
  const [phone, setPhone] = useState(employee?.phone ?? '')

  const initialEmployeeLocations =
    locations.map(({ id }) => ({
      locationId: id,
      excludeFromLabor:
        employee?.employeeLocations?.find(l => l.locationId === id)
          ?.excludeFromLabor ?? false,
      active:
        employee?.employeeLocations?.find(l => l.locationId === id)?.active ??
        false,
      modified: false,
    })) ?? []

  const [employeeLocations, setEmployeeLocations] = useState(
    initialEmployeeLocations
  )

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [wageError, setWageError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [formError, setFormError] = useState('')

  const onNameChange = (newName: string) => {
    setName(newName)
    if (nameError) {
      setNameError('')
    }
    if (formError) {
      setFormError('')
    }
  }
  const onEmailChange = (newEmail: string) => {
    setEmail(newEmail)
    if (emailError) {
      setEmailError('')
    }
    if (formError) {
      setFormError('')
    }
  }
  const onWageChange = (newWage: string) => {
    setWage(newWage)
    if (wageError) {
      setWageError('')
    }
    if (formError) {
      setFormError('')
    }
  }
  const onPhoneChange = (newPhone: string) => {
    setPhone(newPhone)
    if (phoneError) {
      setPhoneError('')
    }
    if (formError) {
      setFormError('')
    }
  }

  const onNameBlur = () => {
    setNameError(calculateNameErrors(name))
  }
  const onEmailBlur = () => {
    setEmailError(calculateEmailErrors(email))
  }
  const onWageBlur = () => {
    setWageError(calculateWageErrors(wage))
  }
  const onPhoneBlur = () => {
    setPhoneError(calculatePhoneErrors(phone))
  }

  const changeExcludeFromLabor = (locationId: string) => {
    const newEmployeeLocations = employeeLocations.map(el => {
      if (el.locationId === locationId) {
        el.excludeFromLabor = !el.excludeFromLabor
        el.modified = true
      }
      return el
    })
    setEmployeeLocations(newEmployeeLocations)
  }

  const changeActive = (locationId: string) => {
    const newEmployeeLocations = employeeLocations.map(el => {
      if (el.locationId === locationId) {
        el.active = !el.active
        el.modified = true
      }
      return el
    })
    setEmployeeLocations(newEmployeeLocations)
  }

  const disabled = !name || nameError || !email || emailError || phoneError

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formattedEmployeeLocations = employeeLocations
      .filter(el => el.modified)
      .map(el => {
        return {
          location_id: el.locationId,
          active: el.active,
          exclude_from_labor: el.excludeFromLabor,
        }
      })

    setBusy(true)

    const errors = await submitForm({
      name: name!,
      email: email!,
      phone: phone || null,
      wage: getWageInIntegerCents(wage),
      employeeLocations: formattedEmployeeLocations,
    })

    setBusy(false)

    if (errors.length) {
      if (errors.some(e => e === EMAIL_UNIQUENESS_ERROR)) {
        setEmailError(t('settings.employeeSettings.employeeForm.emailTaken'))
        setFormError(t('settings.employeeSettings.employeeForm.employeeExists'))
      } else {
        notify(
          `Received unrecognized validation error when creating an employee:
          ${JSON.stringify(errors)}`
        )

        setFormError(
          errors[0] || t('settings.employeeSettings.employeeForm.oops')
        )
      }
    } else {
      toastNotify(
        employee
          ? t('management.toastMessages.updateEmployee')
          : t('management.toastMessages.addEmployee')
      )
      goToEmployees()
    }
  }

  const locationsLabel =
    `${t('settings.employeeSettings.employeeForm.locations')}* ` +
    t('settings.employeeSettings.employeeForm.selectAll')

  const isInternalUser: boolean = organization.universalId === 'internal'

  const isHeartland = useTryCurrentLocation()?.pos === 'heartland'

  const renderEmailInput = () => (
    <StringInputGroup
      errorFeedbackProps={{ error: emailError }}
      labelProps={{
        label: `${t('settings.employeeSettings.employeeForm.email')}*`,
      }}
      controlProps={{
        required: true,
        value: email,
        onChange: e => onEmailChange(e.target.value),
        onBlur: onEmailBlur,
        type: 'email',
      }}
    />
  )

  return (
    <FormWrapper>
      {employee && (
        <DeleteButtonWrapper>
          <DeleteEmployee
            employees={[employee]}
            size="sm"
            onDelete={goToEmployees}
          />
        </DeleteButtonWrapper>
      )}
      <Form error={formError}>
        {employee && !isInternalUser ? (
          <>
            <EmployeeInfoLabel>
              {t('settings.employeeSettings.employeeForm.employeeName')}
            </EmployeeInfoLabel>
            <p>{employee.name}</p>
            {isHeartland ? (
              renderEmailInput()
            ) : (
              <>
                <EmployeeInfoLabel>
                  {t('settings.employeeSettings.employeeForm.email')}
                </EmployeeInfoLabel>
                <p>{employee.email}</p>
              </>
            )}
            {employee.phone && (
              <>
                <EmployeeInfoLabel>
                  {t('settings.employeeSettings.employeeForm.phone')}
                </EmployeeInfoLabel>
                <p>{employee.phone}</p>
              </>
            )}
            {employee.wage && (
              <>
                <EmployeeInfoLabel>
                  {t('settings.employeeSettings.employeeForm.wage')}
                </EmployeeInfoLabel>
                <p>${parseWageFloatString(employee.wage)}</p>
              </>
            )}
          </>
        ) : (
          <>
            <StringInputGroup
              errorFeedbackProps={{ error: nameError }}
              labelProps={{
                label: `${t(
                  'settings.employeeSettings.employeeForm.employeeName'
                )}*`,
              }}
              controlProps={{
                required: true,
                value: name,
                onChange: e => onNameChange(e.target.value),
                onBlur: onNameBlur,
              }}
            />
            {renderEmailInput()}
            <StringInputGroup
              errorFeedbackProps={{ error: wageError }}
              labelProps={{
                label: `${t('settings.employeeSettings.employeeForm.wage')}`,
              }}
              controlProps={{
                required: false,
                value: wage,
                onChange: e => onWageChange(e.target.value),
                onBlur: onWageBlur,
                type: 'number',
              }}
            />
            <FormGroup
              errorFeedbackProps={{ error: phoneError }}
              testId={t('settings.employeeSettings.employeeForm.phone')}
              labelProps={{
                label: t('settings.employeeSettings.employeeForm.phone'),
              }}
            >
              <PhoneInput
                data-testid={`${t(
                  'settings.employeeSettings.employeeForm.phone'
                )} input`}
                defaultCountry="US"
                value={phone}
                placeholder={t(
                  'settings.employeeSettings.employeeForm.phonePlaceholder'
                )}
                onChange={onPhoneChange}
                isInvalid={!!phoneError}
                onBlur={onPhoneBlur}
                // @ts-ignore
                inputComponent={StyledPhoneInput}
              />
            </FormGroup>
          </>
        )}

        {manageEmployeeInfoToggle?.active && (
          <FormGroup testId={locationsLabel}>
            <BootstrapTable borderless>
              <EmployeeLocationsThead>
                <tr>
                  <EmployeeLocationsTh center={false}>
                    {t('settings.employeeSettings.employeeForm.locations')}
                  </EmployeeLocationsTh>
                  <EmployeeLocationsTh center>
                    {t('settings.employeeSettings.employeeForm.excludeLabor')}
                    <InfoTooltipIcon
                      message={t(
                        'settings.employeeSettings.employeeForm.excludeLaborTooltip'
                      )}
                    />
                  </EmployeeLocationsTh>
                  <EmployeeLocationsTh center>
                    {t('settings.employeeSettings.employeeForm.active')}
                    <InfoTooltipIcon
                      message={t(
                        'settings.employeeSettings.employeeForm.activeTooltip'
                      )}
                    />
                  </EmployeeLocationsTh>
                </tr>
              </EmployeeLocationsThead>
              <tbody>
                {/* Locations current user has access to */}
                {locations.map(location => (
                  <tr key={location.id}>
                    <EmployeeLocationsTd center={false}>
                      {location.name}
                    </EmployeeLocationsTd>
                    <EmployeeLocationsTd center>
                      <input
                        type="checkbox"
                        checked={
                          employeeLocations?.find(
                            el => el.locationId === location.id
                          )?.excludeFromLabor || false
                        }
                        onChange={() => changeExcludeFromLabor(location.id)}
                      />
                    </EmployeeLocationsTd>
                    <EmployeeLocationsTd center>
                      <input
                        type="checkbox"
                        checked={
                          employeeLocations?.find(
                            el => el.locationId === location.id
                          )?.active || false
                        }
                        onChange={() => changeActive(location.id)}
                      />
                    </EmployeeLocationsTd>
                  </tr>
                ))}
              </tbody>
            </BootstrapTable>
          </FormGroup>
        )}
        <ButtonsWrapper>
          <Button
            onClick={goToEmployees}
            block
            size="lg"
            variant="secondary"
            as={CancelButton}
          >
            {t('settings.employeeSettings.employeeForm.cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            block
            disabled={disabled}
            size="lg"
            type="submit"
            variant="blue"
          >
            <Spinner animation={'border'} size={'sm'} hidden={!busy} />{' '}
            {submitText}
          </Button>
        </ButtonsWrapper>
      </Form>
    </FormWrapper>
  )
}
