import { DateTime } from 'luxon'
import { Time } from 'components/TimeInput'

interface Args {
  startTimeInput: Time | null
  endTimeInput: Time | null
  roleSelectValue: string | null
  dateInput: DateTime
}

export const isFormValid = ({
  startTimeInput,
  endTimeInput,
  roleSelectValue,
  dateInput,
}: Args) => !!(startTimeInput && endTimeInput && roleSelectValue && dateInput)
