import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'

export const StyledModalTitle = styled(Modal.Title)({
  flex: 1,
  textAlign: 'center',
  fontSize: '1.375rem',
  fontWeight: 900,
  color: 'var(--gray2)',
})

export const StyledModalTitleAutoSchedule = styled(StyledModalTitle)({
  fontSize: '1.125rem',
})

export default StyledModalTitle
