import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pill } from 'components/Pill'
import styled from 'styled-components'

export type DailyViewToggleByType = 'all' | 'role'

interface Props {
  value: DailyViewToggleByType
  onToggle: (viewBy: DailyViewToggleByType) => void
}

export default ({ value, onToggle }: Props) => {
  const { t } = useTranslation()

  const choices: DailyViewToggleByType[] = ['all', 'role']
  const activeIndex = choices.indexOf(value)
  const options = [
    t('planning.dailyViewModal.viewBy.all'),
    t('planning.dailyViewModal.viewBy.role'),
  ]

  const onChange = (i: number) => {
    onToggle(choices[i])
  }

  return (
    <Container>
      <Label>{t('planning.dailyViewModal.viewBy.label')}</Label>
      <Pill options={options} activeIndex={activeIndex} onChange={onChange} />
    </Container>
  )
}

const Label = styled.div({
  whiteSpace: 'nowrap',
  fontWeight: 700,
  fontSize: '14px',
  marginRight: '16px',
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 12,
  marginTop: 20,
  marginRight: 16,
})
