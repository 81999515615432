import React from 'react'
import { asDollars } from 'utils/currency'
import { useTranslation } from 'react-i18next'

import {
  TableHeader,
  TableHeaderTitle,
  TableHeaderRow,
  TableHeaderTotal,
  TableRow,
  ItemTitleCell,
  GrandTotalCell,
} from 'pages/items/styles'

import BootstrapTable from 'react-bootstrap/Table'
import { ItemCategoryAccordion } from 'pages/items/ItemCategoryAccordion'

import { map } from 'lodash'
import { ForecastedItemsValueBy } from 'config/graphqlTypesRaw'
import { ForecastRow, useForecastedItems } from 'queries/useForecastedItems'
import { Interval } from 'luxon'

interface Props {
  valueBy: ForecastedItemsValueBy
  total: number
  fiscalWeek: Interval
  categories: { [category: string]: ForecastRow }
}

const weekColumns = (fiscalWeek: Interval) => {
  return fiscalWeek
    .splitBy({ day: 1 })
    .map(interval => interval.start.toFormat('MMM dd'))
}

export const ItemsTable = ({
  valueBy,
  total,
  fiscalWeek,
  categories,
}: Props) => {
  const { t } = useTranslation()
  const formatter: (x: number) => string =
    valueBy === ForecastedItemsValueBy.Dollars
      ? asDollars
      : x => x.toFixed(1).toString()

  const sortedCategories = Object.values(categories).sort(
    (rowA, rowB) => rowB.total - rowA.total
  )
  const categoryNames = Object.keys(categories)
  const forecastedCategories = useForecastedItems(
    fiscalWeek,
    categoryNames,
    valueBy
  )

  return (
    <BootstrapTable borderless striped responsive="sm" style={{ margin: 0 }}>
      <thead>
        <TableHeaderRow>
          <TableHeaderTitle>{t('items.table.categories')}</TableHeaderTitle>
          {weekColumns(fiscalWeek).map((title, index) => (
            <TableHeader key={`${title}-${index}`}>{title}</TableHeader>
          ))}
          <TableHeaderTotal>{t('items.table.totalItems')}</TableHeaderTotal>
        </TableHeaderRow>
      </thead>
      <tbody>
        {map(sortedCategories, (categorySummary, key) => (
          <ItemCategoryAccordion
            key={key}
            categorySummary={categorySummary}
            items={forecastedCategories?.[categorySummary.name] ?? {}}
            formatter={formatter}
          />
        ))}
        <TableRow>
          <ItemTitleCell />
          <GrandTotalCell>
            <span>{t('items.table.totalItems')}</span>
            {formatter(total)}
          </GrandTotalCell>
        </TableRow>
      </tbody>
    </BootstrapTable>
  )
}
