import React from 'react'
import { DateTime, Interval } from 'luxon'
import { DailyDetailsPlanRow } from './PlanRow'
import { useGetInternalEventDetails } from '../../../queries/useGetInternalEventDetails'
import { useTranslation } from 'react-i18next'

interface Props {
  days: Interval[]
  endDate: DateTime
  startDate: DateTime
}

export const InternalEventsRow = ({ days, endDate, startDate }: Props) => {
  const { t } = useTranslation()
  const events = useGetInternalEventDetails(startDate, endDate)

  const getInternalEventDetails = (day: Interval) =>
    events.filter(e => e.eventStartDate === day.start.toISODate())

  return (
    <DailyDetailsPlanRow
      isInternalEvent
      days={days}
      title={t('planning.internalEvents')}
      getDailyDetails={getInternalEventDetails}
      noDetailsMsg={t('events.unavailable')}
    />
  )
}
