import React from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from '../../../../../components/ConfirmationDialog'

const TRANSLATE_PREFIX =
  'management.settings.organizations.blockedDates.confirmationDialog'

interface Props {
  show: boolean
  onHide: () => void
  onConfirm: () => Promise<void>
}

function ClosedDaysConfirmationDialog({ show, onHide, onConfirm }: Props) {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      show={show}
      onHide={onHide}
      title={t(`${TRANSLATE_PREFIX}.title`)}
      description={t(`${TRANSLATE_PREFIX}.description`)}
      confirmLabel={t(`${TRANSLATE_PREFIX}.confirm`)}
      onConfirm={onConfirm}
      buttonVariant="red"
      bodyTextAlign="center"
    />
  )
}

export default ClosedDaysConfirmationDialog
