import React, { FC } from 'react'
import { BottomLineRow, ListBottomLine, TotalHrsWrapper } from './styles'
import { Col, Row } from 'react-bootstrap'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

type LaborHoursBreakdownTotalHrsProps = {
  title: string
  totalPlannedHrs: number
  totalHrs: number
  hideBottomLine?: boolean
}
export const LaborHoursBreakdownTotalHrs: FC<LaborHoursBreakdownTotalHrsProps> = ({
  title,
  totalPlannedHrs,
  totalHrs,
  hideBottomLine,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {!hideBottomLine && (
        <BottomLineRow>
          <Col>
            <ListBottomLine />
          </Col>
        </BottomLineRow>
      )}
      <Row>
        <Col xs={6} md={6}>
          <TotalHrsWrapper>{title}</TotalHrsWrapper>
        </Col>
        <Col xs={3} md={3}>
          <TotalHrsWrapper>
            {t('misc.hourAbbreviationWithCount', {
              count: round(totalHrs, 2),
            })}
          </TotalHrsWrapper>
        </Col>
        <Col xs={3} md={3}>
          <TotalHrsWrapper>
            {t('misc.hourAbbreviationWithCount', {
              count: round(totalPlannedHrs, 2),
            })}
          </TotalHrsWrapper>
        </Col>
      </Row>
    </>
  )
}
