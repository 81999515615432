import {
  SelectInputGroup,
  StringInputGroup,
  Form,
  Check,
} from 'components/Form'
import React, { ChangeEvent, useState, FormEvent } from 'react'
import { Card, Col, Container, Row, Table } from 'react-bootstrap'
import AuthenticatedPage from './AuthenticatedPage'
import styled from 'styled-components'
import logo from 'assets/images/logos/lineup-ai-blue-black.png'
import Button from 'components/Button'
import { useGetOAuth } from 'queries/useGetOAuth'

export const Logo = styled.img({
  width: '280px',
  height: '96px',
  resizeMode: 'contain',
  marginBottom: 70,
})

const Title = styled.p({
  color: 'var(--gray2)',
  fontWeight: 800,
  fontSize: '1.125rem',
})

const MainContainer = styled(Row)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 60,
})

interface GenerateEmailProps {
  pos: string
  emailAddress: string
  cc: string
}

interface GetNameProps {
  setName: Function
  setOrg: Function
}

const GenerateEmail = ({ pos, emailAddress, cc }: GenerateEmailProps) => {
  const [generateEmailState, setGenerateEmailState] = useState('getFullName')
  const [fullName, setFullName] = useState('')
  const [orgName, setOrgName] = useState('')

  const GetName = ({ setName, setOrg }: GetNameProps) => {
    const [fullName, setFullName] = useState('')
    const [orgName, setOrgName] = useState('')

    const submit = () => {
      if (pos.toLowerCase() === 'brink') setGenerateEmailState('getLocations')
      else setGenerateEmailState('showMessage')
      setName(fullName)
      setOrg(orgName)
    }

    const FormContainer = styled.div({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: 'var(--white)',
      paddingTop: 28,
      paddingBottom: 53,
      paddingLeft: 26,
      paddingRight: 26,
      boxShadow: '0px 0px 20px rgba(13, 13, 13, 0.5)',
      borderRadius: 10,
    })

    return (
      <FormContainer>
        <Form onSubmit={submit}>
          <h1>Enter Full Name and Organization</h1>
          <StringInputGroup
            controlProps={{
              placeholder: 'Full Name',
              value: fullName,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                setFullName(e.target.value)
              },
              type: 'text',
            }}
          />
          <StringInputGroup
            controlProps={{
              placeholder: 'Organization Name',
              value: orgName,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                setOrgName(e.target.value)
              },
              type: 'text',
            }}
          />
          <Button
            block
            size="lg"
            type="submit"
            variant="blue"
            disabled={!(fullName && orgName)}
          >
            {'Continue'}
          </Button>
        </Form>
      </FormContainer>
    )
  }

  interface Location {
    name: string
    id: string
  }

  const [locationsToAdd, setLocationsToAdd] = useState(Array<Location>())

  const GetLocations = () => {
    const [locationName, setLocationName] = useState('')
    const [locationId, setLocationId] = useState('')
    const [addAllLocations, setAddAllLocations] = useState(false)

    const continueState = () => {
      setGenerateEmailState('showMessage')
    }

    const addLocation = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setLocationsToAdd([
        { name: locationName, id: locationId },
        ...locationsToAdd,
      ])
    }

    const onLocationNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setLocationName(e.target.value)
    }

    const onLocationIdChange = (e: ChangeEvent<HTMLInputElement>) => {
      setLocationId(e.target.value)
    }

    return (
      <Container>
        <h1>{'Enter Locations'}</h1>
        <br></br>
        <Container>
          {!addAllLocations && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Location Name</th>
                  <th>Location Id</th>
                </tr>
              </thead>
              <tbody>
                {locationsToAdd.map(l => {
                  return (
                    <tr key={l.id}>
                      <td>{l.name}</td>
                      <td>{l.id}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
          {!addAllLocations && (
            <Form onSubmit={addLocation}>
              <StringInputGroup
                controlProps={{
                  placeholder: 'Location Name',
                  value: locationName,
                  onChange: onLocationNameChange,
                  type: 'text',
                }}
              />
              <StringInputGroup
                controlProps={{
                  placeholder: 'Location Id',
                  value: locationId,
                  onChange: onLocationIdChange,
                  type: 'text',
                }}
              />
              <Button
                block
                disabled={!(locationName && locationId)}
                size="lg"
                type="submit"
                variant="blue"
              >
                {'Add Location'}
              </Button>
              <br />
            </Form>
          )}
        </Container>
        <Check
          id={'addAllLocations'}
          label={'Add All Locations'}
          inputProps={{
            checked: addAllLocations,
            onChange: () => {
              setAddAllLocations(!addAllLocations)
            },
          }}
        />
        <Button
          block
          size="lg"
          type="submit"
          variant="blue"
          onClick={continueState}
        >
          {'Continue'}
        </Button>
      </Container>
    )
  }

  const generateEmailBodyText = (pos: string): string => {
    if (pos.toLowerCase() === 'brink') {
      let body =
        ` Dear Tech Support, I, ${fullName}, as an authorized representative of ` +
        `${orgName}, authorize Lineup.ai API access to the locations listed below and ParTech to issue locations tokens and access information on our behalf. Please allow access to the following: %0d%0a`

      for (let i = 0; i < locationsToAdd.length; i++) {
        body += 'Location Name: ' + locationsToAdd[i].name + '%0d%0a'
        body += 'Location Id: ' + locationsToAdd[i].id + '%0d%0a%0d%0a'
      }

      if (locationsToAdd.length === 0) {
        body += 'All Locations'
      }

      return body
    }

    return `Dear Support at LineupAI, I, ${fullName}, as an authorized representative of ${orgName}, authorize lineup ai to integrate with our pos ${pos}, please assist me. Thanks.`
  }

  //display message with email button
  const ShowEmailMessage = () => {
    return (
      <div>
        <h1>Please send the following email to {pos}</h1>
        <p>
          Dear Tech Support, I, {fullName}, as an authorized representative of{' '}
          {orgName}, authorize Lineup.ai API access to the locations listed
          below and ParTech to issue locations tokens and access information on
          our behalf. Please allow access to the following: <br />
        </p>
        {locationsToAdd.length > 0
          ? locationsToAdd.map(l => {
              return (
                <p key={l.id}>
                  {' '}
                  Location Name {l.name} <br />
                  Location Id {l.id}
                </p>
              )
            })
          : 'All Locations'}
        <br />
        <a
          href={`mailto:${emailAddress}?subject=Adding API Support For LineupAI&cc=${cc}&body=${generateEmailBodyText(
            pos
          )}`}
        >
          <Button block size="lg" type="submit" variant="blue">
            Send Email
          </Button>
        </a>
      </div>
    )
  }

  const BuildPosMessage = () => {
    switch (generateEmailState) {
      case 'getFullName': {
        return <GetName setName={setFullName} setOrg={setOrgName}></GetName>
      }
      case 'getLocations': {
        return <GetLocations></GetLocations>
      }
      case 'showMessage': {
        return <ShowEmailMessage></ShowEmailMessage>
      }
      default: {
        console.error('Invalid generate email state: ' + generateEmailState)
        return (
          <div>
            <h1>For Support Onboarding Contact LineupAi</h1>
            <p>
              There was a technical error, please contact brian@lineup.ai for
              more onboarding information.
            </p>
          </div>
        )
      }
    }
  }

  return <BuildPosMessage></BuildPosMessage>
}

const PosForm = ({ posSelected }: any) => {
  switch (posSelected) {
    case 'toast':
    case 'upserve':
    case 'brink': {
      const emailRaw: string | undefined =
        posSelected === 'brink'
          ? process.env.BRINK_ONBOARDING_EMAIL
          : process.env.LINEUP_AI_ONBOARDING_EMAIL
      const sendToEmail: string =
        emailRaw === undefined ? 'brian@lineup.ai' : emailRaw
      const ccEmail: string =
        process.env.LINEUP_AI_ONBOARDING_EMAIL === undefined
          ? 'brian@lineup.ai'
          : process.env.LINEUP_AI_ONBOARDING_EMAIL

      return (
        <GenerateEmail
          pos={posSelected.charAt(0).toUpperCase() + posSelected.slice(1)}
          emailAddress={sendToEmail}
          cc={ccEmail}
        />
      )
    }
    default: {
      return <div></div>
    }
  }
}

//This can be refactored later on, for now this is good enough
export default () => {
  const [posSelected, setPosSelected] = useState<string>('')
  const [disableNextBtn, setDisableNextBtn] = useState<boolean>(true)

  const oAuthUrl = useGetOAuth(posSelected)

  const handlePosChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const pos: string = event.target.value
    setPosSelected(pos)
    pos === 'square' || pos === 'clover'
      ? setDisableNextBtn(false)
      : setDisableNextBtn(true)
  }

  const handleClickNext = () => {
    if (posSelected === 'square' || posSelected === 'clover') {
      if (oAuthUrl?.oauthAuthorization.url) {
        window.location.href = oAuthUrl?.oauthAuthorization.url
      } else {
        console.error('oauth url invalid')
      }
    }
  }

  return (
    <AuthenticatedPage>
      <MainContainer>
        <Col
          xs={11}
          md={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Card
            style={{
              width: '30.25rem',
              height: '28rem',
              borderRadius: 10,
              justifyContent: 'center',
            }}
          >
            <Row style={{ justifyContent: 'center' }}>
              <Logo src={logo} alt={'Lineup Logo'} />
              <Row style={{ justifyContent: 'center' }}>
                <Col xs={10} md={12}>
                  <Title>First, let’s connect your POS system to Lineup.</Title>
                </Col>
              </Row>
              <Col xs={10} md={10}>
                <SelectInputGroup
                  controlId="pos.selector"
                  controlProps={{
                    value: undefined,
                    onChange: handlePosChange,
                    style: { textTransform: 'capitalize' },
                    children: (
                      <>
                        <option value="">{'Select your POS system'}</option>

                        {['clover', 'square', 'brink', 'upserve', 'toast'].map(
                          pos => (
                            <option value={pos} key={pos}>
                              {pos}
                            </option>
                          )
                        )}
                      </>
                    ),
                  }}
                />
              </Col>
            </Row>

            <PosForm posSelected={posSelected} />

            <Row style={{ justifyContent: 'center' }}>
              <Col xs={10} md={6}>
                <Button
                  block
                  size="lg"
                  type="submit"
                  variant="blue"
                  style={{ marginTop: 50 }}
                  disabled={disableNextBtn}
                  onClick={handleClickNext}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </MainContainer>
    </AuthenticatedPage>
  )
}
