import React, { FC } from 'react'
import {
  ExportScheduleOptionsSelect,
  SelectMultiOrSingleValueDropdown,
} from './ExportScheduleOptionsSelect'
import { useTranslation } from 'react-i18next'
import { SelectDropdownOption } from '../../../../components/SelectDropdown'

interface Props {
  roles: SelectDropdownOption[]
  onSelectRoles: (roles: SelectMultiOrSingleValueDropdown) => void
}

export const ExportScheduleRoleSelect: FC<Props> = ({
  roles,
  onSelectRoles,
}) => {
  const { t } = useTranslation()

  return (
    <ExportScheduleOptionsSelect
      label={t('planning.exportScheduleModal.exportOptions.role.label')}
      onSelectOptions={onSelectRoles}
      options={roles}
      selectAllOptionTagLabel={
        !roles.length
          ? t('planning.exportScheduleModal.exportOptions.role.noRoles')
          : undefined
      }
      selectAllOptionLabel={t(
        'planning.exportScheduleModal.exportOptions.role.selectAllRoles'
      )}
      testId={'exportScheduleRoleSelect'}
    />
  )
}
