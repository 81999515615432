import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { DateTime, Interval } from 'luxon'
import { sum, flatten } from 'lodash'

import * as Analytics from 'config/analytics'
import AuthenticatedPage from 'pages/AuthenticatedPage'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useGetIntervalOrderSumsDataLoading } from 'queries/useGetIntervalOrderSums'
import { useInterval } from 'utils/useInterval'
import { isDataLoadingCold } from 'utils/dataLoading'
import { Loading } from 'components/Loading'
import { useGetIntervalPredictionSumsDataLoading } from 'queries/useGetIntervalPredictionSums'
import { useGetWeeklyPlanWithDailySummariesDataLoading } from 'queries/useGetWeeklyPlans'
import { CurrentColumn, PreviousColumn } from 'pages/tracking/columns'
import { StyledLoadingImage } from 'pages/tracking/styles'
import { notify } from 'config/errorReporting'
import { getDailySummary } from 'utils/getDailySummary'
import { LaborHoursBreakdown } from '../../components/LaborActualHours'
import { useGetOperatingHoursTracking } from './useGetOperatingHoursTracking'
import { useGetSalesDayPartsQuery } from 'config/graphqlTypes'

const refreshInterval = 2 * 60 * 1000

interface TrackingType {
  currentDayInterval: Interval
  previousDayInterval: Interval
}

const Tracking = ({
  currentDayInterval,
  previousDayInterval,
}: TrackingType) => {
  const [isWeekView, setIsWeekViewState] = useState(false)
  const [
    isCurrentActualLaborClicked,
    setIsCurrentActualLaborClicked,
  ] = useState(false)
  const [
    showLaborHoursBreakdownModal,
    setShowLaborHoursBreakdownModal,
  ] = useState(false)
  const setIsWeekView = (newIsWeekView: boolean) => {
    Analytics.track('Toggling day/week view on Tracking page', {
      togglingToWeekView: newIsWeekView,
    })
    setIsWeekViewState(newIsWeekView)
  }

  const onToggleLaborHoursBreakdownShowModal = () =>
    setShowLaborHoursBreakdownModal(prevState => !prevState)

  const onClickLaborActualPrevious = () => {
    setIsCurrentActualLaborClicked(false)
    setShowLaborHoursBreakdownModal(prevState => !prevState)
  }

  const onClickLaborActualCurrent = () => {
    setIsCurrentActualLaborClicked(true)
    setShowLaborHoursBreakdownModal(prevState => !prevState)
  }

  const { location, times } = useCurrentLocation()

  const [currentTime, setCurrentTime] = useState(times.localTime)
  const salesType = location.salesType
  const fiscalWeekoffset = location.fiscalWeekOffsetFromMonday ?? 0
  const locationSalesDayParts =
    useGetSalesDayPartsQuery({
      variables: { locationId: location.id },
    }).data?.salesDayParts || []
  useInterval(() => {
    setCurrentTime(DateTime.local())
  }, refreshInterval)

  const currentWeekStart = currentTime
    .minus({ days: fiscalWeekoffset })
    .startOf('week')
    .plus({ days: fiscalWeekoffset })
  const currentWeekEnd = currentTime
    .minus({ days: fiscalWeekoffset })
    .endOf('week')
    .plus({ days: fiscalWeekoffset })

  const currentWeekInterval = Interval.fromDateTimes(
    currentWeekStart,
    currentWeekEnd
  )

  const previousWeekInterval = Interval.fromDateTimes(
    currentWeekInterval.start.minus({ weeks: 1 }),
    currentWeekInterval.end.minus({ weeks: 1 })
  )

  const hourlyOrderSumsDataLoading = useGetIntervalOrderSumsDataLoading(
    previousDayInterval.start,
    currentDayInterval.end,
    60,
    { fetchPolicy: 'no-cache' }
  )

  const hourlyPredictionSumsDataLoading = useGetIntervalPredictionSumsDataLoading(
    previousDayInterval.start,
    currentDayInterval.end,
    60
  )

  const dailyPredictionSumsDataLoading = useGetIntervalPredictionSumsDataLoading(
    previousWeekInterval.start,
    currentWeekInterval.end,
    60 * 24
  )

  const dailyOrderSumsDataLoading = useGetIntervalOrderSumsDataLoading(
    previousWeekInterval.start,
    currentWeekInterval.end,
    60 * 24,
    { fetchPolicy: 'no-cache' }
  )

  const plansDataLoading = useGetWeeklyPlanWithDailySummariesDataLoading(
    previousWeekInterval.start,
    currentWeekInterval.end
  )

  if (
    isDataLoadingCold(hourlyOrderSumsDataLoading) ||
    isDataLoadingCold(dailyOrderSumsDataLoading) ||
    isDataLoadingCold(hourlyPredictionSumsDataLoading) ||
    isDataLoadingCold(dailyPredictionSumsDataLoading) ||
    isDataLoadingCold(plansDataLoading)
  ) {
    return <Loading StyledImage={StyledLoadingImage} />
  }

  const hourlyOrderSums = hourlyOrderSumsDataLoading.data.intervalOrderSums
  const dailyOrderSums = dailyOrderSumsDataLoading.data.intervalOrderSums
  const hourlyPredictionSums =
    hourlyPredictionSumsDataLoading.data.intervalPredictionSums
  const dailyPredictionSums =
    dailyPredictionSumsDataLoading.data.intervalPredictionSums
  const plans = plansDataLoading.data.weeklyPlans

  const orderSums = isWeekView ? dailyOrderSums : hourlyOrderSums
  const predictionSums = isWeekView ? dailyPredictionSums : hourlyPredictionSums

  const currentInterval = isWeekView ? currentWeekInterval : currentDayInterval
  const previousInterval = isWeekView
    ? previousWeekInterval
    : previousDayInterval

  const columnProps = (givenInterval: Interval) => {
    givenInterval = givenInterval.set({
      start: givenInterval.start.startOf('hour'),
      end: givenInterval.end.endOf('hour'),
    })

    const filteredOrderSums = orderSums.filter(o =>
      givenInterval.contains(o.startAt)
    )
    const filteredPredictionSums = predictionSums.filter(p =>
      givenInterval.contains(p.startAt)
    )

    const filteredIds = flatten([
      filteredPredictionSums.map(o => o.id),
      filteredOrderSums.map(o => o.id),
    ])

    const getFilteredSum = (sums: any[], salesField: string) =>
      sum(
        sums
          .filter(
            o =>
              !filteredIds.includes(o.id) &&
              ((givenInterval.start > o.startAt &&
                givenInterval.start.day === o.startAt.day) ||
                (givenInterval.end < o.endAt &&
                  givenInterval.end.day === o.endAt.day &&
                  givenInterval.end.day === givenInterval.start.day))
          )
          .map(o => o[salesField] || 0) || 0
      )

    const salesField = 'reportedSales'
    const preOperatingHoursOrderSum = getFilteredSum(orderSums, salesField)
    const preOperatingHoursPredictionSum = getFilteredSum(
      predictionSums,
      salesField
    )

    filteredOrderSums[0][salesField] =
      (filteredOrderSums[0][salesField] || 0) + preOperatingHoursOrderSum
    filteredPredictionSums[0][salesField] =
      (filteredPredictionSums[0][salesField] || 0) +
      preOperatingHoursPredictionSum

    const plan = (() => {
      const foundPlan = plans.find(p =>
        Interval.fromDateTimes(p.startOn, p.endOn.endOf('day')).contains(
          givenInterval.start
        )
      )

      if (foundPlan) {
        return foundPlan
      }

      notify(`No plan found. previous inteval start: ${previousWeekInterval.start.toISO()},
              current interval end: ${currentWeekInterval.end.toISO()},
              Plans: ${JSON.stringify(plans)}`)

      const foundPlan2 = plans.find(p =>
        Interval.fromDateTimes(p.startOn, p.endOn.endOf('day')).contains(
          givenInterval.end
        )
      )

      if (foundPlan2) {
        return foundPlan2
      }

      notify('No plan found even after backup')

      return plans[1]!
    })()

    const fiscalWeekOffsetFromMonday = location.fiscalWeekOffsetFromMonday ?? 0
    const summary = isWeekView
      ? plan.weekSalesAndLaborSummary
      : getDailySummary(givenInterval, plan, fiscalWeekOffsetFromMonday)

    return {
      isWeekView,
      setIsWeekView,
      onClickLaborActualCurrent,
      onClickLaborActualPrevious,
      interval: givenInterval,
      orderSums: filteredOrderSums,
      predictionSums: filteredPredictionSums,
      latestPredictedSales: summary.latestPredictedSales,
      plan,
      submittedPlannedSales: summary.submittedPlannedSales || null,
      laborPredicted: summary.optimalLaborForLatestPredictedSales,
      scheduledLabor: summary.scheduledLabor || null,
      laborActual: summary.actualLabor || null,
      optimalSalesForActualLabor: summary.optimalSalesForActualLabor || null,
      laborOptimal: summary.optimalLaborForActualSales || null,
      actualSales: summary.actualSales || null,
      dailySalesAndLaborSummaries: plan.dailySalesAndLaborSummaries,
      salesType: salesType,
      locationSalesDayParts: locationSalesDayParts,
    }
  }

  const day = isCurrentActualLaborClicked
    ? currentDayInterval.start
    : previousDayInterval.start
  const startOfWeek = isCurrentActualLaborClicked
    ? currentWeekInterval.start
    : previousWeekInterval.start

  return (
    <Row>
      <CurrentColumn {...columnProps(currentInterval)} />
      <PreviousColumn {...columnProps(previousInterval)} />
      <LaborHoursBreakdown
        isWeekView={isWeekView}
        day={day}
        startOfWeek={startOfWeek}
        isCurrentDayOrWeek={isCurrentActualLaborClicked}
        showModal={showLaborHoursBreakdownModal}
        toggleShowModal={onToggleLaborHoursBreakdownShowModal}
        locationId={location.id}
      />
    </Row>
  )
}

export default () => {
  const operatingHours = useGetOperatingHoursTracking()

  return (
    <AuthenticatedPage>
      <Container fluid>
        {operatingHours ? (
          <Tracking
            currentDayInterval={operatingHours.currentDayInterval}
            previousDayInterval={operatingHours.previousDayInterval}
          />
        ) : (
          <Loading StyledImage={StyledLoadingImage} />
        )}
      </Container>
    </AuthenticatedPage>
  )
}
