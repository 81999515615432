import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { useGetLocationManagementRequestsQuery } from 'config/graphqlTypes'
import { filter, find } from 'lodash'

import { sortBy } from 'lodash'
import { DateTime } from 'luxon'

export const useGetManagementRequests = () => {
  const locationId = useCurrentLocationId()

  const { data, loading } = useGetLocationManagementRequestsQuery({
    variables: {
      locationId,
    },
    fetchPolicy: 'network-only',
  })

  if (loading || !data) return null

  const {
    shiftSwapRequests,
    employees,
    organization: { employeeAvailabilities, timeOffRequests },
  } = data.location

  const getIntersectRequests = <T>(requests: T[]) =>
    filter(requests, ({ employee: { id } }: { employee: { id: string } }) =>
      find(employees, e => e.id === id)
    ) as T[]

  const employeeAvailabilityIntersections = getIntersectRequests(
    employeeAvailabilities
  )
  const timeOffRequestIntersections = getIntersectRequests(timeOffRequests)
  const shiftSwapRequestIntersections = getIntersectRequests(shiftSwapRequests)

  return sortBy(
    [
      ...employeeAvailabilityIntersections,
      ...timeOffRequestIntersections,
      ...shiftSwapRequestIntersections,
    ],
    mr => DateTime.fromISO(mr.updatedAt)
  ).map(request => ({
    ...request,
    uniqueId: `${request.__typename}-${request.id}`,
  }))
}

export const useGetManagementRequestsCount = () =>
  useGetManagementRequests()?.reduce(
    (count, { state }) =>
      ['pending', 'swap_pending'].includes(state) ? count + 1 : count,
    0
  ) ?? 0
