import { DateTime, Interval } from 'luxon'

import {
  IntervalPredictionSumFragment,
  SalesAndLaborSummaryFragment,
  ShiftFragment,
} from 'config/graphqlTypes'
import { useDailyTimeline } from 'pages/plan/dailyViewModal/useDailyTimeline'
import { chartDataConfig } from 'components/Chart/chartDataConfig'
import { useCurrentLocation } from 'queries/useCurrentLocation'

export interface DailyChartDatum {
  x: string
  y: number
  tooltipInfo: {
    dollars: number
    hours: number
    employeeCount: number
    orderCount: number
  }
}

const getSalesInDollars = (
  prediction: IntervalPredictionSumFragment,
  salesType: string
) => Number(prediction.reportedSales) / 100

const getPredictedDollarsForHour = (
  predictions: IntervalPredictionSumFragment[],
  hour: DateTime,
  salesType: string
) =>
  predictions.reduce(
    (hourlyTotalDollars, p) =>
      p.startAt.equals(hour)
        ? hourlyTotalDollars + getSalesInDollars(p, salesType)
        : hourlyTotalDollars,
    0
  )

// This is a crude approach to start, but will ensure that the two series are around the same height for typical data
const calculateDollarsPerHour = (
  predictions: IntervalPredictionSumFragment[],
  dailyLaborHours: number,
  salesType: string
) => {
  const dailySales = predictions.reduce(
    (dailyTotalDollars, p) =>
      dailyTotalDollars + getSalesInDollars(p, salesType),
    0
  )

  // Handle the edge cases. If no predictions, this will still show a nice labor graph.
  if (dailySales === 0 || dailyLaborHours === 0) {
    return 1
  }

  return dailySales / dailyLaborHours
}

const calculateShiftOverlap = (
  { hours, startAt, endAt }: ShiftFragment,
  hourInterval: Interval
) => {
  if (hours === 0) {
    return 0.0
  }

  const shiftInterval = Interval.fromDateTimes(startAt, endAt)
  const overlapInterval = shiftInterval.intersection(hourInterval)
  return overlapInterval?.length('hours') ?? 0.0
}

const getShiftHoursForHour = (shifts: ShiftFragment[], hour: DateTime) => {
  const hourInterval = Interval.fromDateTimes(hour, hour.endOf('hour'))
  return shifts.reduce(
    (totalHours, shift) =>
      totalHours + calculateShiftOverlap(shift, hourInterval),
    0.0
  )
}

export const getEmployeeCountForHour = (
  shifts: ShiftFragment[],
  hour: DateTime
) => {
  const QUARTER_HOUR = 15
  const hourInterval = Interval.fromDateTimes(
    hour.startOf('hour'),
    hour.endOf('hour')
  )

  const quarterHourIntervals = hourInterval?.splitBy({ minutes: 15 })

  let employeesPerInterval = new Map<string, Set<string>>()

  quarterHourIntervals?.forEach(interval => {
    employeesPerInterval.set(interval.toString(), new Set<string>())
  })

  shifts.forEach(({ startAt, endAt, employee }) => {
    const shiftInterval = Interval.fromDateTimes(startAt, endAt)

    quarterHourIntervals?.forEach(quarterHourInterval => {
      const intersectionInterval = shiftInterval.intersection(
        quarterHourInterval
      )

      if (!intersectionInterval) {
        return
      }

      const durationObject = intersectionInterval
        .toDuration('minutes')
        .toObject()
      const minutes = durationObject.minutes ?? 0
      const minutesRounded = Math.ceil(minutes)

      if (minutesRounded === QUARTER_HOUR && employee.id !== '-1') {
        let key = quarterHourInterval.toString()
        let currentEmployees =
          employeesPerInterval.get(key) ?? new Set<string>()
        employeesPerInterval.set(key, currentEmployees.add(employee.id))
      }
    })
  })

  const employeeSets = Array.from(employeesPerInterval.values())
  let minEmployeeCount = Number.MAX_VALUE

  employeeSets.forEach(employees => {
    minEmployeeCount = Math.min(minEmployeeCount, employees.size)
  })

  return minEmployeeCount
}

const makeDatum = (
  time: DateTime,
  predictionDollars: number,
  laborDollars: number,
  laborHours: number,
  employeeCount: number,
  orderCount: number,
  getDisplayTimestamp: (dateTime: DateTime) => string
) => (seriesId: 'predictions' | 'plannedLabor') => ({
  x: time.toMillis().toString(),
  y: seriesId === 'predictions' ? predictionDollars : laborDollars,
  tooltipInfo: {
    dollars: predictionDollars,
    hours: laborHours,
    employeeCount: employeeCount,
    orderCount: orderCount,
  },
  displayTime: getDisplayTimestamp(time),
})

export const getOrderCountForHour = (
  predictions: IntervalPredictionSumFragment[],
  hour: DateTime
): number => {
  let orderCount = 0
  predictions.forEach(p => {
    if (p.startAt.equals(hour)) orderCount += Number(p.orderCount)
  })
  return orderCount
}

export const useMakeChartData = (
  day: Interval,
  shiftsForDay: ShiftFragment[],
  predictionsForDay: IntervalPredictionSumFragment[],
  dailySalesAndLaborSummary: SalesAndLaborSummaryFragment,
  getDisplayTimestamp: (dateTime: DateTime) => string
) => {
  const timeline = useDailyTimeline(shiftsForDay, day)
  const salesType = useCurrentLocation().location.salesType
  const hoursToDollarsMultiplier = calculateDollarsPerHour(
    predictionsForDay,
    dailySalesAndLaborSummary.optimalLaborForPlannedSales,
    salesType
  )

  const predictionsData: DailyChartDatum[] = []
  const plannedLaborData: DailyChartDatum[] = []

  timeline.forEach(hour => {
    const predictionDollars = getPredictedDollarsForHour(
      predictionsForDay,
      hour,
      salesType
    )
    const laborHours = getShiftHoursForHour(shiftsForDay, hour)
    const laborDollars = laborHours * hoursToDollarsMultiplier
    const employeeCount = getEmployeeCountForHour(shiftsForDay, hour)
    const orderCount = getOrderCountForHour(predictionsForDay, hour)

    const makeDatumForSerie = makeDatum(
      hour,
      predictionDollars,
      laborDollars,
      laborHours,
      employeeCount,
      orderCount,
      getDisplayTimestamp
    )

    predictionsData.push(makeDatumForSerie('predictions'))
    plannedLaborData.push(makeDatumForSerie('plannedLabor'))
  })

  return [
    { id: chartDataConfig.predictions.id, data: predictionsData },
    { id: chartDataConfig.plannedLabor.id, data: plannedLaborData },
  ]
}
