import AuthenticatedPage from 'pages/AuthenticatedPage'
import React, { useState } from 'react'
import { LocationsTable } from './LocationTable'
import { RevokePermissions } from './RevokePermissions'
import { useOnboardingOAuthMutation } from 'config/graphqlTypes'
import { LocationType } from './LocationType'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { useHistory } from 'react-router-dom'
import { routeMap } from 'pages/Routes'
import { submitAppAlertMessage } from 'utils/useAppAlertMessages'
import { useTranslation } from 'react-i18next'
import { ManagementWrapper } from '../ManagementWrapper'
import { ManagementHeader } from '../styles'
import { Loading } from '../../../components/Loading'

export const IntegrationSettings = () => {
  const urlResponse = new URLSearchParams(window.location.search)
  const state = urlResponse.get('state')
  const code = urlResponse.get('code')
  const clientId = urlResponse.get('client_id')
  const merchantId = urlResponse.get('merchant_id')
  const error = urlResponse.get('error')
  const responseType = urlResponse.get('response_type')
  const errorDescription = urlResponse.get('error_description')
  const fetchedLocations = useLoggedInUserOrRedirect().locations
  const isRedirectPage = state || clientId
  let userLocations: LocationType[] | null = !isRedirectPage
    ? ((fetchedLocations as unknown) as LocationType[])
    : null

  const { t } = useTranslation()
  const [
    onboardingOAuthMutation,
    { loading, called },
  ] = useOnboardingOAuthMutation()
  const [locations, setLocations] = useState<LocationType[]>(
    !isRedirectPage ? (userLocations == null ? [] : userLocations) : []
  )
  const [successLoadingLocations, setSuccessLoadingLocations] = useState(!error)

  const history = useHistory()

  const posType = clientId === 'EMKRMCHFXHHX2' ? 'clover' : 'square'

  const input = {
    state: state?.split(' ').join('') ?? '',
    posType,
    code,
    merchantId,
    responseType,
    error,
    errorDescription,
  }

  !called &&
    isRedirectPage &&
    onboardingOAuthMutation({
      variables: {
        input,
      },
    }).then(r => {
      const locations: LocationType[] = (r.data?.onboardingOauth
        .locations as unknown) as LocationType[]
      if (locations) {
        setLocations(locations)
      }
      setSuccessLoadingLocations(r.data?.onboardingOauth.success as boolean)

      if (r.data?.onboardingOauth.success === false) {
        history.push(routeMap.onboarding)
        submitAppAlertMessage(t('misc.squareAuthorizationDenied'))
      }
    })

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeader>
            {t('management.integrationSettings')}
          </ManagementHeader>

          {loading ? (
            <Loading />
          ) : (
            <>
              {successLoadingLocations ? (
                <LocationsTable locations={locations}></LocationsTable>
              ) : null}
              <RevokePermissions></RevokePermissions>
            </>
          )}
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}
