import React, { useRef, useEffect } from 'react'
import { Interval } from 'luxon'
import { max, sumBy } from 'lodash'
import {
  ShiftRoleCategoryFragment,
  ViolationFragment,
  EmployeeShiftsFragment,
  ActiveLocationShiftRoleFragment,
} from 'config/graphqlTypes'
import { Avatar } from 'components/Avatar'
import {
  RowEntity,
  isRowEntityEmployee,
  isRowEntityUnavilability,
} from 'pages/plan/scheduling/RowEntity'
import { getFirstNameAndLastInitial } from 'utils/getFirstNameAndLastInitial'
import { ScheduleCell } from 'pages/plan/scheduling/ScheduleCell'
import EmployeeScheduleTooltip from 'pages/plan/scheduling/EmployeeScheduledHoursTooltip'
import { PlanningTableRow, MAKE_ROWS_STICKY_WIDTH } from 'pages/plan/styles'
import {
  StyledFirstCell,
  StyledLastCell,
  RowTextWrapper,
} from 'pages/plan/scheduling/styles'
import { PREDICTION_ROW_HEIGHT } from 'pages/plan/rows/styles'
import { useFormattedHours } from 'utils/useFormattedHours'
import { getShiftsPerDay } from 'utils/getShiftsPerDay'
import { getShiftsForRow } from 'pages/plan/shiftRoleCategoryHelpers'
import { usePlanPageContext } from '../planPageContext'

interface Props {
  days: Interval[]
  violations: ViolationFragment[]
  employeeShiftsForWeek: EmployeeShiftsFragment[]
  isFirst: boolean
  rowEntity: RowEntity
  shiftRoleCategory: ShiftRoleCategoryFragment
  hide: boolean
  hideOnPrint: boolean
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export interface EmployeeSpecificHours {
  [index: string]: {
    total: number
    shifts: {
      [index: string]: number
    }
  }
}

export const ScheduleRow = ({
  days,
  isFirst,
  rowEntity,
  violations,
  employeeShiftsForWeek,
  shiftRoleCategory,
  hide,
  hideOnPrint,
  activeLocationShiftRoles,
}: Props) => {
  const { plan, locationClosedDaysIndexes } = usePlanPageContext()
  const { shifts: planShifts } = plan
  const cellRef = useRef<HTMLTableDataCellElement>(null)

  useEffect(() => {
    if (cellRef.current && isFirst) {
      // Offset the scroll so that the first scheduling row is under the
      // predictions row
      const offset =
        window.screen.width > MAKE_ROWS_STICKY_WIDTH ? PREDICTION_ROW_HEIGHT : 0

      window.scrollBy({
        left: 0,
        top: cellRef.current.getBoundingClientRect().top - offset,
        behavior: 'smooth',
      })
    }
  }, [isFirst, cellRef])

  const isRowUnavilability = isRowEntityUnavilability(rowEntity)
  const isEmployee = isRowEntityEmployee(rowEntity)
  const rowLabel = isEmployee
    ? getFirstNameAndLastInitial(rowEntity)
    : rowEntity.name

  const shifts = getShiftsForRow(planShifts, rowEntity, shiftRoleCategory)

  const hours = sumBy(shifts, 'displayHours')
  const formattedHours = useFormattedHours(hours)

  const avatarName = isEmployee ? rowEntity.name : null
  const avatarUrl = null

  const shiftsPerDay = getShiftsPerDay({ days, shifts })
  const maxDayShifts = max(shiftsPerDay.map(dayShifts => dayShifts.length))!

  const employeeShifts = isEmployee
    ? employeeShiftsForWeek?.find(({ id }) => id === rowEntity.id)
    : null

  const employeeTotalHours = parseFloat(
    employeeShifts?.shifts
      ?.reduce((prev, curr) => {
        return curr?.displayHours ? prev + curr?.displayHours : prev
      }, 0)
      .toFixed(2) ?? '0'
  )

  const defaultValue: EmployeeSpecificHours = {}

  const employeeSpecificHours = employeeShifts?.shifts?.reduce((prev, curr) => {
    const location = prev[curr?.location?.name] ?? {
      total: 0,
      shifts: {},
    }
    location.total = location?.total + Number(curr?.displayHours)
    location.shifts[curr.shiftRole?.name ?? ''] =
      Number(curr?.displayHours) +
      (location?.shifts[curr.shiftRole?.name ?? ''] ?? 0)

    return location.total > 0
      ? { ...prev, [curr?.location?.name]: location }
      : { ...prev }
  }, defaultValue)
  let hideRowClass: string = ''
  if (hide) hideRowClass += 'd-none'
  if (hideOnPrint) hideRowClass += ' d-print-none'
  return (
    <PlanningTableRow
      className={hideRowClass}
      nthChildren={locationClosedDaysIndexes}
      isBodyRow
    >
      <StyledFirstCell isFirstRow={isFirst} ref={cellRef}>
        {!!avatarName && <Avatar name={avatarName} avatarUrl={avatarUrl} />}
        <RowTextWrapper employeeHours={!!employeeTotalHours}>
          {rowLabel}
          <EmployeeScheduleTooltip
            employeeSpecificHours={employeeSpecificHours}
            employeeTotalHours={employeeTotalHours}
          />
        </RowTextWrapper>
      </StyledFirstCell>
      {days.map((day, dayIndex) => (
        <ScheduleCell
          days={days}
          day={day}
          violations={violations}
          rowEntity={rowEntity}
          shifts={shiftsPerDay[dayIndex]}
          maxDayShifts={maxDayShifts}
          isFirstRow={isFirst}
          key={day.start.toISODate()}
          activeLocationShiftRoles={activeLocationShiftRoles}
        />
      ))}
      <StyledLastCell isFirstRow={isFirst}>
        {isRowUnavilability || formattedHours}
      </StyledLastCell>
    </PlanningTableRow>
  )
}
