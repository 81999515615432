import React from 'react'
import { sortBy } from 'lodash'

import {
  Datum,
  CovidBarChart,
} from 'pages/covid/dashboard/addressRow/CovidBarChart'
import { TrendMetric } from 'pages/covid/types'
import {
  CovidMetricCol,
  CovidGraphHeader,
  CovidLatestValue,
} from 'pages/covid/dashboard/addressRow/styles'

const trendToData = ({ trend: { x, y } }: TrendMetric): Datum[] =>
  x.map((xVal: number, index) => ({
    key: xVal.toString(),
    value: y[index],
  }))

interface Props {
  header: string
  trendMetric: TrendMetric
  dashboardSaved: boolean
}

export const CovidMetric = ({ header, trendMetric, dashboardSaved }: Props) => {
  const chartData = trendToData(trendMetric)
  const latestValue = sortBy(chartData, 'key').pop()?.value

  return (
    <CovidMetricCol blur={!dashboardSaved}>
      <CovidGraphHeader>{header}</CovidGraphHeader>
      <CovidLatestValue>{latestValue || '—'}</CovidLatestValue>
      <CovidBarChart data={chartData} sentiment={trendMetric.sentiment} />
    </CovidMetricCol>
  )
}
