import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import Button from 'components/Button'
import { IS_COLUMN_LAYOUT } from 'pages/covid/dashboard/useIsColumnLayout'

export const standardSidePadding = '5vw'
export const gutterSize = 15

export const AddressFormRow = styled(Row)({
  justifyContent: 'center',
})

export const TopbarContainer = styled(Container)({
  background: 'var(--blue)',
  paddingLeft: standardSidePadding,
  paddingRight: standardSidePadding,
})

export const TopBarLogo = styled.img({
  color: 'var(--white)',
})

export const TopBarRow = styled(Row)({
  display: 'flex',
  alignItems: 'center',
  '@media only screen and (max-width: 768px)': {
    justifyContent: 'center',
  },
})

const TopBarVerticalPadding = 40

export const TopBarLogoCol = styled(Col)({
  paddingTop: TopBarVerticalPadding,
  paddingBottom: TopBarVerticalPadding,
  display: 'flex',
  justifyContent: 'flex-start',
  [IS_COLUMN_LAYOUT]: {
    justifyContent: 'center',
  },
})

export const TopBarAddressFormCol = styled(Col)({
  display: 'flex',
  paddingTop: TopBarVerticalPadding,
  justifyContent: 'center',
  [IS_COLUMN_LAYOUT]: {
    justifyContent: 'center',
  },
  '@media only screen and (max-width: 768px)': {
    display: 'flex',
    order: 3,
    paddingBottom: 10,
  },
})

const commonAddressInputAndSuggestionBoxStyles = {
  width: '50vw',
  minWidth: 340,
  maxWidth: 600,
}

export const StyledAddressInputCol = styled.div<{
  isDisplayingSuggestions: boolean
}>(({ isDisplayingSuggestions }) => ({
  ...commonAddressInputAndSuggestionBoxStyles,
  paddingBottom: TopBarVerticalPadding,
  input: {
    border: 'none',
    height: 50,
    borderRadius: isDisplayingSuggestions
      ? '25px 25px 0px 0px'
      : '25px 25px 25px 25px',
  },
}))

export const SuggestionsWrapper = styled.div({
  ...commonAddressInputAndSuggestionBoxStyles,
  marginTop: '-1px',
  zIndex: 1,
  textAlign: 'center',
  position: 'absolute',
  color: 'black',
  backgroundColor: 'var(--white)',
  boxShadow: '4px 5px 5px 0.5px rgb(0 0 0 / 10%)',
  borderBottomLeftRadius: 25,
  borderBottomRightRadius: 25,
  p: {
    cursor: 'pointer',
  },
})

export const SuggestionDivider = styled.div({
  marginLeft: 50,
  marginRight: 50,
  marginBottom: 20,
  borderTop: '1px solid var(--gray5)',
})

export const AddressFormButton = styled(Button)<{ topbar: boolean }>(
  ({ topbar }) => ({
    marginLeft: 12,
    marginRight: 12,
    marginBottom: TopBarVerticalPadding,
    width: 125,
    height: 50,
    borderRadius: 30,
    backgroundColor: topbar ? 'var(--b2b-blue)' : 'transparent',
    borderColor: topbar ? 'var(--b2b-blue)' : 'var(--white)',
    borderWidth: 2,
    '&[disabled]': {
      backgroundColor: topbar ? 'var(--b2b-blue)' : 'transparent',
      borderColor: topbar ? 'var(--b2b-blue)' : 'var(--white)',
    },
  })
)

export const FlexCol = styled(Col)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

export const StyledDashboardLogo = styled.a<{ forceColorWhite: boolean }>(
  ({ forceColorWhite }) => ({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'none',
    },
    '& *': {
      color: forceColorWhite ? 'var(--white) !important' : undefined,
    },
    '@media only screen and (max-width: 768px)': {
      display: 'flex',
      order: 2,
      paddingBottom: 10,
    },
  })
)

export const StyledWestfieldLogo = styled.a({
  display: 'block',
  whiteSpace: 'nowrap',
  '&:hover': {
    textDecoration: 'none',
  },
  '& *': {
    color: 'var(--white) !important',
  },
  '@media only screen and (max-width: 768px)': {
    display: 'flex',
    order: 1,
    paddingBottom: 10,
  },
})

export const LogoText = styled.span({
  fontSize: 32,
  lineHeight: '33px',
  fontWeight: 600,
  color: 'var(--gray2)',
  whiteSpace: 'nowrap',
})

export const Logo = styled.img({
  height: 60,
  marginRight: 15,
  verticalAlign: 'bottom',
  '@media only screen and (max-width: 768px)': {
    marginRight: 0,
  },
})

export const LargeLogo = styled.img({
  height: 60,
  paddingBottom: 10,
  verticalAlign: 'middle',
})

export const LogoRow = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
  flexDirection: 'row',
  width: '100%',
})
