import Button from 'components/Button'
import ConfirmationDialog from 'components/ConfirmationDialog'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { submitAppAlertMessage } from 'utils/useAppAlertMessages'
import { useTranslation } from 'react-i18next'
import { useSquareRevokeAuthorizationMutation } from 'config/graphqlTypes'
import styled from 'styled-components'
import { routeMap } from 'pages/Routes'
import { useHistory } from 'react-router-dom'

const RevokeAccessBtn = styled(Button)({
  minWidth: '250px',
  whiteSpace: 'nowrap',
})

export const RevokePermissions = () => {
  const [revokeMutation] = useSquareRevokeAuthorizationMutation()
  const { t } = useTranslation()
  const history = useHistory()

  const revoke = async () => {
    setShowModal(false)
    const resp = await revokeMutation()

    if (resp.data?.squareRevokeAuthorization.success) {
      history.push(routeMap.onboarding)
      submitAppAlertMessage(t('misc.squareAuthorizationRevoked'))
    } else {
      console.error(resp)
    }
  }

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <Row
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Col xs={12} md={12}>
        <RevokeAccessBtn variant="danger" onClick={() => setShowModal(true)}>
          Revoke Lineup.ai access
        </RevokeAccessBtn>
      </Col>
      <ConfirmationDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        title={'Revoke Lineup.ai access'}
        description={
          'Are you sure you want to revoke Lineup.ai access to your Square account? You will lose access to Lineup.ai data.'
        }
        confirmLabel={'Confirm'}
        onConfirm={revoke}
      />
    </Row>
  )
}
