import { EmployeeForEditFragment } from 'config/graphqlTypes'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { CancelButton, FormWrapper } from '../styles'
import Form from 'react-bootstrap/Form'
import {
  Body,
  BorderContainer,
  ButtonContainer,
  Container,
  ControlsContainer,
  Description,
  Divider,
  PageDescription,
  Title,
  LastUpdated,
} from './styles'
import Button from 'components/Button'
import { routeMap, useLocationNavigation } from 'pages/Routes'
import { useHistory } from 'react-router-dom'
import { useUpdateEmployeeMinMaxHoursMutation } from 'config/graphqlTypes'
import { UpdateEmployeeMinMaxHoursInput } from 'config/graphqlTypesRaw'

interface HoursInputProps {
  value: number | null
  onChange: (hours: number | null) => void
}

const parseFloatOrNull = (s: string) => {
  const n = parseFloat(parseFloat(s).toFixed(2))

  if (isNaN(n)) {
    return null
  }

  if (n < 0) {
    return null
  } else if (n > 999) {
    return 999
  } else {
    return n
  }
}

const HoursInput = ({ value, onChange: parentOnChange }: HoursInputProps) => {
  const { t } = useTranslation()
  const displayValue =
    value === null
      ? ''
      : t('misc.hourAbbreviationWithCountFormatted_other', { hours: value })

  const [editing, setEditing] = useState(false)
  const [editingValue, setEditingValue] = useState(
    value === null ? '' : value.toString()
  )

  const onFocus = () => {
    setEditing(true)
    setEditingValue(value === null ? '' : value.toString())
  }

  const onBlur = () => {
    const newValue = parseFloatOrNull(editingValue)

    parentOnChange(newValue)
    setEditing(false)
    setEditingValue('')
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditingValue(e.target.value)
    const newValue = parseFloatOrNull(e.target.value)
    parentOnChange(newValue)
  }

  return (
    <Form.Control
      value={editing ? editingValue : displayValue}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
    />
  )
}

interface Props {
  employee: EmployeeForEditFragment
}

export const EmployeeAutoScheduleForm = ({ employee }: Props) => {
  const { t } = useTranslation()
  const { locationPath } = useLocationNavigation()
  const history = useHistory()

  const lastUpdated = employee.confirmedHoursSubmittedAt
    ? DateTime.fromISO(employee.confirmedHoursSubmittedAt)
    : null

  const lastUpdatedText = lastUpdated
    ? t('settings.employeeSettings.autoSchedule.lastUpdated', {
        user: employee.confirmedHoursSubmittedBy,
        day: lastUpdated.toFormat('dd MMM yyyy'),
        time: lastUpdated.toFormat('h:mm a'),
      })
    : null

  const [updateEmployeeHours] = useUpdateEmployeeMinMaxHoursMutation()

  const goToEmployees = () =>
    history.push(locationPath + routeMap.location.management.employees)

  const [minHours, setMinHours] = useState<number | null>(
    employee.minHours ?? null
  )
  const [maxHours, setMaxHours] = useState<number | null>(
    employee.maxHours ?? null
  )

  const onSubmit = async () => {
    if (minHours === null || maxHours === null) return

    const input: UpdateEmployeeMinMaxHoursInput = {
      minHours,
      maxHours,
      id: employee.id,
    }
    await updateEmployeeHours({ variables: { input } })
  }

  return (
    <FormWrapper>
      <Form>
        <Container>
          <Title>
            {t('settings.employeeSettings.autoSchedule.preferences')}
          </Title>
          {lastUpdated ? (
            <LastUpdated>{lastUpdatedText}</LastUpdated>
          ) : (
            <PageDescription>
              {t('settings.employeeSettings.autoSchedule.inferredFromPrevious')}
              <b>
                {' '}
                {t('settings.employeeSettings.autoSchedule.toOverrideEdit')}
              </b>
            </PageDescription>
          )}
        </Container>

        <BorderContainer>
          <Title>
            {t('settings.employeeSettings.autoSchedule.minHours.title')}
          </Title>
          <Body>
            <Description>
              {t('settings.employeeSettings.autoSchedule.minHours.description')}
            </Description>
            <ControlsContainer>
              <HoursInput value={minHours} onChange={setMinHours} />
            </ControlsContainer>
          </Body>
          <Divider />
          <Title>
            {t('settings.employeeSettings.autoSchedule.maxHours.title')}
          </Title>
          <Body>
            <Description>
              {t('settings.employeeSettings.autoSchedule.maxHours.description')}
            </Description>
            <ControlsContainer>
              <HoursInput value={maxHours} onChange={setMaxHours} />
            </ControlsContainer>
          </Body>
        </BorderContainer>
        <ButtonContainer>
          <Button
            onClick={goToEmployees}
            block
            size="lg"
            variant="secondary"
            as={CancelButton}
          >
            {t('settings.employeeSettings.autoSchedule.cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            block
            disabled={minHours === null || maxHours === null}
            size="lg"
            type="submit"
            variant="blue"
          >
            {t('settings.employeeSettings.autoSchedule.update')}
          </Button>
        </ButtonContainer>
      </Form>
    </FormWrapper>
  )
}
