import React, { FC } from 'react'
import { Interval, DateTime } from 'luxon'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import cx from 'classnames'

import * as Analytics from 'config/analytics'
import tooltipIcon from 'assets/images/ellipsis-icon.svg'
import {
  LocationForReportingFragment,
  PlanForReportingFragment,
} from 'config/graphqlTypes'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import {
  StyledTooltipTitle,
  StyledTooltipContent,
  StyledTooltipRow,
  StyledTooltipAmount,
  StyledTooltipDivider,
  StyledTooltipIconContainer,
} from 'pages/reporting/styles'
import { asDollarsOrEmDash } from 'utils/currency'
import { useTranslation } from 'react-i18next'

interface Props {
  day: Interval
  dailySalesAndLaborSummary: PlanForReportingFragment['dailySalesAndLaborSummaries'][0]
  startOfToday: DateTime
  planSubmittedDate: DateTime | null
  location: LocationForReportingFragment
}

export const PredictedSalesTooltip: FC<Props> = ({
  day,
  dailySalesAndLaborSummary: {
    latestPredictedSales,
    originallyPredictedSales,
    plannedSales,
  },
  startOfToday,
  planSubmittedDate,
  location,
}) => {
  const user = useLoggedInUserOrRedirect()
  const dayLabel = day.start.toFormat('M/d')
  const todayLabel = startOfToday.toFormat('M/d')
  const isTodayOrFuture = startOfToday.toISODate() <= day.start.toISODate()
  const tooltipId = `predicted-sales-tooltip-${dayLabel}`

  const onToggle = (isVisible: boolean) =>
    isVisible &&
    Analytics.track('Hovered Over Reporting Planned Sales Tooltip', {
      date: day.start.toISODate(),
      dayOfWeek: day.start.toFormat('EEE'),
      location: location.name,
      user: user.email,
    })

  const formattedPlanSubmittedDate = planSubmittedDate?.toFormat('M/d') ?? ''

  const salesChanged = originallyPredictedSales !== plannedSales

  const { t } = useTranslation()

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 200 }}
      onToggle={onToggle}
      overlay={
        <Popover id={tooltipId}>
          <StyledTooltipTitle>
            {t('reporting.predictedSalesTooltip.title', { day: dayLabel })}
          </StyledTooltipTitle>
          <StyledTooltipContent>
            <StyledTooltipRow>
              <span>
                {isTodayOrFuture
                  ? t('reporting.predictedSalesTooltip.todaysPrediction', {
                      day: todayLabel,
                    })
                  : t('reporting.predictedSalesTooltip.predictionsOn', {
                      day: dayLabel,
                    })}
              </span>
              <StyledTooltipAmount>
                {asDollarsOrEmDash(latestPredictedSales)}
              </StyledTooltipAmount>
            </StyledTooltipRow>
            {planSubmittedDate && (
              <>
                <StyledTooltipRow className={cx(salesChanged && 'blue')}>
                  <span>
                    {t(
                      salesChanged
                        ? 'reporting.predictedSalesTooltip.changedOn'
                        : 'reporting.predictedSalesTooltip.predictionsOn',
                      {
                        day: formattedPlanSubmittedDate,
                      }
                    )}
                  </span>
                  <StyledTooltipAmount>
                    {asDollarsOrEmDash(plannedSales || null)}
                  </StyledTooltipAmount>
                </StyledTooltipRow>
                <StyledTooltipDivider />
                <StyledTooltipRow>
                  <span>
                    {t('reporting.predictedSalesTooltip.predictionsOn', {
                      day: formattedPlanSubmittedDate,
                    })}
                  </span>
                  <span>{asDollarsOrEmDash(originallyPredictedSales)}</span>
                </StyledTooltipRow>
              </>
            )}
          </StyledTooltipContent>
        </Popover>
      }
    >
      <StyledTooltipIconContainer>
        <img
          src={tooltipIcon}
          alt={t('reporting.predictedSalesTooltip.tooltipIconAlt')}
        />
      </StyledTooltipIconContainer>
    </OverlayTrigger>
  )
}
