import React from 'react'

import { PlanForReportingFragment } from 'config/graphqlTypes'
import {
  StyledReportingTableRow,
  StyledReportingCell,
} from 'pages/reporting/styles'
import { LaborHoursTotalCell } from 'pages/reporting/LaborHoursTotalCell'

interface Props {
  plan: PlanForReportingFragment
  locationId: string
}

export const LaborActualRow = ({
  plan: { dailySalesAndLaborSummaries, weekSalesAndLaborSummary },
  locationId,
}: Props) => (
  <StyledReportingTableRow>
    <StyledReportingCell className="last-row" />
    {dailySalesAndLaborSummaries.map((dailySalesAndLaborSummary, index) => (
      <LaborHoursTotalCell
        labelTranslationKey={index === 0 ? 'reporting.actual' : undefined}
        className={index % 2 === 0 ? 'stripe' : ''}
        salesAndLaborSummary={dailySalesAndLaborSummary}
        locationId={locationId}
        key={index}
      />
    ))}
    <LaborHoursTotalCell
      className="bold"
      salesAndLaborSummary={weekSalesAndLaborSummary}
      locationId={locationId}
      isWeekTotal
    />
  </StyledReportingTableRow>
)
