import React, { ChangeEvent, FC, useState } from 'react'
import { Check, FormGroup } from '../../../../components/Form'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  CreateWeeklyPlanExportMutationInput,
  ReservationsImporter,
} from 'config/graphqlTypesRaw'
import { useCurrentLocation } from 'queries/useCurrentLocation'

interface Props {
  isScheduleByRole: boolean
  onSelectTotalHoursCheck: (totalHours: boolean) => void
  onSelectRolesCheck: (roles: boolean) => void
  onSelectSalesDataCheck: (salesData: boolean) => void
  onSelectWeatherCheck: (weather: boolean) => void
  onSelectExternalEventsCheck: (events: boolean) => void
  onSelectInternalEventsCheck: (events: boolean) => void
  onSelectReservationsCheck: (reservations: boolean) => void
  defaultCreateWeeklyPlanExportInput: CreateWeeklyPlanExportMutationInput
}

export const ExportScheduleOptionCheckboxes: FC<Props> = ({
  isScheduleByRole,
  onSelectTotalHoursCheck,
  onSelectRolesCheck,
  onSelectSalesDataCheck,
  onSelectExternalEventsCheck,
  onSelectInternalEventsCheck,
  onSelectWeatherCheck,
  onSelectReservationsCheck,
  defaultCreateWeeklyPlanExportInput,
}) => {
  const { t } = useTranslation()

  const { location } = useCurrentLocation()
  const contentOptions = defaultCreateWeeklyPlanExportInput.contentOptions
  const [totalHoursCheck, setTotalHoursCheck] = useState(
    contentOptions?.totalHours
  )
  const [rolesCheck, setRolesCheck] = useState(contentOptions?.roles)
  const [weatherCheck, setWeatherCheck] = useState(contentOptions?.weather)
  const [externalEventsCheck, setExternalEventsCheck] = useState(
    contentOptions?.externalEvents
  )
  const [internalEventsCheck, setInternalEventsCheck] = useState(
    contentOptions?.internalEvents
  )
  const [salesDataCheck, setSalesDataCheck] = useState(
    contentOptions?.salesData
  )

  const [reservationsCheck, setReservationsCheck] = useState(false)

  const handleTotalHoursCheckChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setTotalHoursCheck(t => !t)
    onSelectTotalHoursCheck(event.target.checked)
  }

  const handleRolesCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRolesCheck(r => !r)
    onSelectRolesCheck(event.target.checked)
  }

  const handleSalesDataCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSalesDataCheck(s => !s)
    onSelectSalesDataCheck(event.target.checked)
  }
  const handleWeatherCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWeatherCheck(w => !w)
    onSelectWeatherCheck(event.target.checked)
  }

  const handleExternalEventsCheckChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setExternalEventsCheck(e => !e)
    onSelectExternalEventsCheck(event.target.checked)
  }

  const handleInternalEventsCheckChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInternalEventsCheck(e => !e)
    onSelectInternalEventsCheck(event.target.checked)
  }

  const handleReservationsCheckChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setReservationsCheck(r => !r)
    onSelectReservationsCheck(event.target.checked)
  }

  return (
    <FormGroup
      testId={'exportScheduleOptionCheckboxes'}
      labelProps={{
        label: t(
          'planning.exportScheduleModal.exportOptions.includeInExport.label'
        ),
      }}
    >
      <Container>
        <Check
          id="totalHours"
          style={checkStyles}
          label={t(
            'planning.exportScheduleModal.exportOptions.includeInExport.totalHours'
          )}
          inputProps={{
            checked: totalHoursCheck,
            onChange: handleTotalHoursCheckChange,
          }}
        />
        {!isScheduleByRole && (
          <Check
            id="roles"
            style={checkStyles}
            label={t(
              'planning.exportScheduleModal.exportOptions.includeInExport.roles'
            )}
            inputProps={{
              checked: rolesCheck,
              onChange: handleRolesCheckChange,
            }}
          />
        )}
        <Check
          id="salesData"
          style={checkStyles}
          label={t(
            'planning.exportScheduleModal.exportOptions.includeInExport.salesData'
          )}
          inputProps={{
            checked: salesDataCheck,
            onChange: handleSalesDataCheckChange,
          }}
        />
        <Check
          id="weather"
          style={checkStyles}
          label={t(
            'planning.exportScheduleModal.exportOptions.includeInExport.weather'
          )}
          inputProps={{
            checked: weatherCheck,
            onChange: handleWeatherCheckChange,
          }}
        />
        <Check
          id="externalEvents"
          style={checkStyles}
          label={t(
            'planning.exportScheduleModal.exportOptions.includeInExport.externalEvents'
          )}
          inputProps={{
            checked: externalEventsCheck,
            onChange: handleExternalEventsCheckChange,
          }}
        />
        {location.reservationsImporterType !==
          ReservationsImporter.NoImporter && (
          <Check
            id="reservations"
            style={checkStyles}
            label={t(
              'planning.exportScheduleModal.exportOptions.includeInExport.reservations'
            )}
            inputProps={{
              checked: reservationsCheck,
              onChange: handleReservationsCheckChange,
            }}
          />
        )}
        {location.tripleseatEnabled && (
          <Check
            id="internalEvents"
            style={checkStyles}
            label={t(
              'planning.exportScheduleModal.exportOptions.includeInExport.internalEvents'
            )}
            inputProps={{
              checked: internalEventsCheck,
              onChange: handleInternalEventsCheckChange,
            }}
          />
        )}
      </Container>
    </FormGroup>
  )
}

const checkStyles = {
  fontWeight: 500,
}

const Container = styled.div({
  paddingTop: '.4rem',
})
