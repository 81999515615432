import React from 'react'
import { range } from 'lodash'

import { UnprintablePlanningTableRow } from 'pages/plan/styles'
import { BlankRowCell } from 'pages/plan/scheduling/styles'
import { usePlanPageContext } from '../planPageContext'

interface Props {
  numTableRows: number
  isShowingSchedule: boolean
}

export const BlankScheduleRow = ({
  numTableRows,
  isShowingSchedule,
}: Props) => {
  const { locationClosedDaysIndexes } = usePlanPageContext()
  const numScheduleRows = numTableRows - 3

  return (
    <UnprintablePlanningTableRow
      isBodyRow
      nthChildren={locationClosedDaysIndexes}
    >
      {range(9).map(columnNum => (
        <BlankRowCell
          numScheduleRows={numScheduleRows}
          isShowingSchedule={isShowingSchedule}
          key={columnNum}
        />
      ))}
    </UnprintablePlanningTableRow>
  )
}
