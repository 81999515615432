import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useGetLatestSuccessfulImportEventQuery } from 'config/graphqlTypes'
import { useGetPollingInterval } from 'queries/useGetPollingInterval'

export const useGetLatestSuccessfulImportEvent = () => {
  const pollInterval = useGetPollingInterval()

  const {
    location: { id: locationId },
  } = useCurrentLocation()

  return (
    useGetLatestSuccessfulImportEventQuery({
      fetchPolicy: 'cache-and-network',
      pollInterval,
      variables: {
        locationId,
      },
    }).data?.importEvent || null
  )
}
