import { Col } from 'react-bootstrap'
import {
  AddNewEmployeeButton,
  EmployeeHeaderContainer,
  EmployeesTableHeaderRow,
  SearchInputWrapper,
} from '../styles'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { DeleteEmployee } from './DeleteEmployee'
import { BootstrapTableProps, SearchProps } from 'react-bootstrap-table-next'
import { toast } from 'react-toastify'
import { EmployeeListingForLocation } from 'pages/management/employees/Employees'
import { EmployeeSearchInput } from './EmployeeSearchInput'

type EmployeesHeaderProps = {
  selectedEmployees: EmployeeListingForLocation[] | undefined
  searchProps: SearchProps<BootstrapTableProps>
  hasSettingsPermission: boolean
}

const EmployeeSearchInputWrapper = SearchInputWrapper(EmployeeSearchInput)

export const EmployeesHeader: FC<EmployeesHeaderProps> = ({
  selectedEmployees,
  searchProps,
  hasSettingsPermission,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const onNewEmployee = () => {
    toast.dismiss()
    history.push(`${location.pathname}/new`)
  }

  return (
    <EmployeesTableHeaderRow>
      <Col xs={12} md={12}>
        <EmployeeHeaderContainer>
          {hasSettingsPermission && (
            <>
              {!!selectedEmployees?.length && (
                <DeleteEmployee employees={selectedEmployees} />
              )}
              <AddNewEmployeeButton variant="secondary" onClick={onNewEmployee}>
                {t('settings.addNew')}
              </AddNewEmployeeButton>
            </>
          )}
          <EmployeeSearchInputWrapper {...searchProps} delay={500} />
        </EmployeeHeaderContainer>
      </Col>
    </EmployeesTableHeaderRow>
  )
}
