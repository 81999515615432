import React, { ReactNode } from 'react'
import { FC } from 'react'

import { EM_DASH } from 'utils/formattedNumberWithFallback'
import { StyledDataValue } from 'pages/reporting/styles'

export const ReportingDataValue: FC<{ children?: ReactNode }> = ({
  children,
}) => (
  <StyledDataValue isEmpty={children === EM_DASH}>{children}</StyledDataValue>
)
