import React, { useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { IntercomProvider } from 'react-use-intercom'
import styled from 'styled-components'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import * as Analytics from 'config/analytics'
import apolloClient from 'config/apolloClient'
import { ErrorBoundary } from 'config/errorReporting'
import { getIsIntercomConfigured, getIntercomAppId } from 'config/intercom'
import Routes from 'pages/Routes'
import { AppAlert } from 'components/AppAlert'
import { ErrorPage } from 'pages/ErrorPage'
import { addErrorTriggering } from 'utils/addErrorTriggering'
import { Loading } from 'components/Loading'
import { ToastContainer } from 'react-toastify'
import { FeatureTogglesProvider } from './contexts/FeatureToggles'
import { useGetMeQuery } from './config/graphqlTypes'
import { LOGGED_IN_USER_KEY } from './pages/Login'

const StyledErrorPage = styled(ErrorPage)({
  height: '100vh',
})

const App = () => {
  const [isConfigured, setIsConfigured] = useState(false)
  const isIntercomConfigured = getIsIntercomConfigured()
  const intercomAppId = getIntercomAppId()

  const { data, loading } = useGetMeQuery({})

  useEffect(() => {
    addErrorTriggering('_trigger_top_level_error')
    Analytics.configure()
    Analytics.track('App has been foregrounded', {})
    document.onvisibilitychange = () => {
      if (document.visibilityState === 'visible') {
        Analytics.track('App has been foregrounded', {})
      } else {
        Analytics.track('App has been backgrounded', {})
      }
    }
    setIsConfigured(true)
  }, [])

  useEffect(() => {
    const user = data?.me
    if (user) {
      localStorage.setItem(LOGGED_IN_USER_KEY, JSON.stringify(user))
    }
  }, [data])

  if (!isConfigured || loading) {
    return <Loading />
  }

  return (
    <FeatureTogglesProvider>
      <IntercomProvider
        appId={intercomAppId}
        autoBoot={isIntercomConfigured}
        shouldInitialize={isIntercomConfigured}
      >
        <AppAlert />
        <Routes />
        <ToastContainer />
      </IntercomProvider>
    </FeatureTogglesProvider>
  )
}

export default () => (
  <ErrorBoundary FallbackComponent={StyledErrorPage}>
    <DndProvider backend={HTML5Backend}>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </DndProvider>
  </ErrorBoundary>
)
