import styled from 'styled-components'

import { TextButton } from 'components/Button'
import {
  regularBodyTextStyle,
  regularBodyBoldTextStyle,
  largeBodyTextStyle,
  largeBodyBoldTextStyle,
  tableLabelTextStyle,
  tableLabelBoldTextStyle,
  tableAnnotationTextStyle,
  largeHeaderStyle,
  h2Style,
  h3Style,
  mediumBodyTextStyle,
  mediumBodyBoldTextStyle,
  smallBodyTextStyle,
  smallBodyBoldTextStyle,
  graphLabelTextStyle,
} from 'config/styles'

export const SmallBodyText = styled.p(smallBodyTextStyle)

export const SmallBoldBodyText = styled.p(smallBodyBoldTextStyle)

export const RegularBodyText = styled.p(regularBodyTextStyle)

export const RegularBoldBodyText = styled.p(regularBodyBoldTextStyle)

export const MediumBodyText = styled.p(mediumBodyTextStyle)

export const MediumBodyBoldText = styled.p(mediumBodyBoldTextStyle)

export const LargeBodyText = styled.p(largeBodyTextStyle)

export const LargeBodyBoldText = styled.p(largeBodyBoldTextStyle)

export const TableLabelText = styled.p(tableLabelTextStyle)

export const TableLabelBoldText = styled.p(tableLabelBoldTextStyle)

export const LargeHeaderText = styled.p(largeHeaderStyle)

export const H2Text = styled.p(h2Style)

export const H3Text = styled.p(h3Style)

export const GraphLabelText = styled.p(graphLabelTextStyle)

export const RegularBodyTextButton = styled(TextButton)(regularBodyTextStyle)

export const TableLabelTextButton = styled(TextButton)(tableLabelTextStyle)

export const TableAnnotationTextButton = styled(TextButton)(
  tableAnnotationTextStyle
)

export const Warning = styled.div<{ fontSize: string }>(({ fontSize }) => ({
  color: '#F5635B',
  fontWeight: 800,
  fontSize: fontSize,
  whiteSpace: 'pre-wrap',
  lineHeight: '15px',
  display: 'flex',
  flexDirection: 'row',
}))

export const Subheader = styled.div({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  alignItems: 'center',
  textAlign: 'center',
})

export const CharacterCountText = styled.div({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  alignItems: 'center',
  textAlign: 'right',
  color: '#757575',
  marginTop: -12,
})
