import { Col, Row } from 'react-bootstrap'
import { StyledLabel } from './styles'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

type LaborHoursBreakdownListHeaderProps = {
  columns: string[]
}

export const LaborHoursBreakdownListHeader: FC<LaborHoursBreakdownListHeaderProps> = ({
  columns,
}) => {
  const { t } = useTranslation()

  return (
    <Row>
      {columns.map((col, i) => (
        <Col xs={12} md={3} key={i}>
          <StyledLabel>{t(`tracking.laborActualHours.${col}`)}</StyledLabel>
        </Col>
      ))}
    </Row>
  )
}
