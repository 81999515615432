import Popover from 'react-bootstrap/Popover'
import styled from 'styled-components'
import {
  mediumBodyBoldTextStyle,
  tableLabelBoldTextStyle,
  regularBodyBoldTextStyle,
} from 'config/styles'

export const Box = styled(Popover)<{
  $upsideDown?: boolean
  $hasErrorNote?: boolean
}>(({ $upsideDown, $hasErrorNote }) => {
  // Translate value is based on the height of the tooltip on the daily view.
  // This could be done dynamically, but would be a little complicated a not
  // worth it for now
  let translateDistance = 0
  if ($upsideDown) {
    translateDistance = $hasErrorNote ? 216 : 201
  }

  return {
    backgroundColor: 'var(--white)',
    border: 0,
    borderRadius: 5,
    filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))',
    margin: 0,
    position: 'relative',
    transform: `translate(0, ${translateDistance}px)`,
  }
})

export const Title = styled(Popover.Title)({
  color: 'var(--gray3)',
  ...regularBodyBoldTextStyle,
  textAlign: 'center',
})

export const Content = styled(Popover.Content)({
  textAlign: 'center',
})

export const Container = styled.div({
  marginBottom: 7,
  '&:last-of-type': {
    marginBottom: 0,
  },
})

export const Label = styled.label(({ color }) => ({
  ...tableLabelBoldTextStyle,
  color,
  display: 'block',
  marginBottom: 2,
}))

export const Value = styled.p(({ color }) => ({
  ...mediumBodyBoldTextStyle,
  color,
}))

const Arrow = styled.div({
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  height: 0,
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, 0)',
  width: 0,
})

export const DownArrow = styled(Arrow)({
  borderTop: '10px solid var(--white)',
  bottom: -10,
})

export const UpArrow = styled(Arrow)({
  borderBottom: '10px solid var(--gray7)',
  top: -10,
})
