import React, { FC, ReactNode } from 'react'
import Tabs, { TabsProps } from 'react-bootstrap/Tabs'
import styled from 'styled-components'

type Props = {
  onSelectTabKey?: (tabKey: string | null) => void
  className?: string
  children: ReactNode
} & TabsProps

const TabsSelect: FC<Props> = ({
  onSelectTabKey,
  className,
  children,
  ...props
}) => (
  <DefaultStyledTabsContainer>
    <Tabs onSelect={onSelectTabKey} className={className} {...props}>
      {children}
    </Tabs>
  </DefaultStyledTabsContainer>
)

const DefaultStyledTabsContainer = styled.div`
  .nav-item {
    border: none;
    color: var(--gray1);
    padding: 5px 40px;
    margin-right: 40px;
  }

  .nav-item.active {
    border: none;
    border-bottom: 0.15rem solid;
    color: var(--blue) !important;
    font-weight: 800;
  }

  .nav-tabs .nav-link:hover {
    color: blue;
  }
`

export default TabsSelect
