import React, { FC } from 'react'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import getFormattedHours, {
  getFormattedSingleOptionalDecimalPlace,
} from 'utils/getFormattedHours'
import { ScheduleDailyTotalBar } from 'pages/plan/scheduling/ScheduleDailyTotalBar'
import {
  StyledTotalCell,
  StyledScheduleDailyTotalHours,
  StyledDailyBreakdownCell,
} from 'pages/plan/scheduling/styles'
import { SalesAndLaborSummaryFragment } from 'config/graphqlTypes'

interface Props {
  salesAndLaborSummary: SalesAndLaborSummaryFragment
  disabled?: boolean
  isLastCell?: boolean
  onClick?: () => void
  testId: string
}

export const TotalCell: FC<Props> = ({
  salesAndLaborSummary: { preScheduledLabor, optimalLaborForPlannedSales },
  disabled = false,
  isLastCell,
  onClick,
  testId,
}) => {
  const { t } = useTranslation()

  const percentOfForecasted =
    (preScheduledLabor / optimalLaborForPlannedSales) * 100
  const hoursDiff = preScheduledLabor - optimalLaborForPlannedSales
  const roundedHoursDiff = round(hoursDiff, 1)
  const shouldShowAs100Percent =
    preScheduledLabor > 0 && Math.abs(roundedHoursDiff) <= 0
  const shouldShowAs0Percent = preScheduledLabor === 0

  const formattedTotalShiftHours = getFormattedHours({
    hours: optimalLaborForPlannedSales,
    t,
  })

  const formattedPreScheduledLaborHours = getFormattedSingleOptionalDecimalPlace(
    preScheduledLabor
  )

  const barPercent = shouldShowAs100Percent
    ? 100
    : shouldShowAs0Percent
    ? 0
    : hoursDiff > 0
    ? 100
    : percentOfForecasted

  const barIsOver = hoursDiff > 0 && !shouldShowAs100Percent

  return (
    <StyledTotalCell data-testid={testId}>
      {isLastCell ? (
        <StyledScheduleDailyTotalHours>
          <b>{formattedPreScheduledLaborHours}/</b>
          {formattedTotalShiftHours}
        </StyledScheduleDailyTotalHours>
      ) : (
        <StyledScheduleDailyTotalHours>
          <StyledDailyBreakdownCell onClick={onClick} disabled={disabled}>
            <b>{formattedPreScheduledLaborHours}/</b>
            {formattedTotalShiftHours}
          </StyledDailyBreakdownCell>
        </StyledScheduleDailyTotalHours>
      )}
      <ScheduleDailyTotalBar percent={barPercent} isOver={barIsOver} />
    </StyledTotalCell>
  )
}
