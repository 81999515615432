import { notify } from 'config/errorReporting'

export const covidBackendFetch = async (path: string) => {
  const url = `${process.env.REACT_APP_COVID_DASHBOARD_BACKEND}/${path}`
  const authToken = process.env.REACT_APP_COVID_DASHBOARD_BACKEND_API_TOKEN

  if (!authToken) {
    throw new Error(
      `Missing env var: REACT_APP_COVID_DASHBOARD_BACKEND_API_TOKEN`
    )
  }

  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('x-token', authToken)

  const response = await window.fetch(url, {
    method: 'GET',
    headers: requestHeaders,
  })

  // The backend returns 422 for CBGs with no data and 429 for rate limit error
  if (response.status === 422 || response.status === 429) {
    notify(`Received ${response.status} from backend for url: ${url}`)

    return null
  }

  if (!response.ok) {
    const responseText = await response.text()

    throw new Error(
      `Failed response from covid backend. ` +
        `URL: ${url}, status: ${response.status}, ` +
        `response body: ${responseText}`
    )
  }

  const json = await response.json()

  return json
}
