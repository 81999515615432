import React, { useState, ChangeEvent, FormEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { routeMap } from './Routes'
import { useResetPasswordMutation } from 'config/graphqlTypes'
import Button from 'components/Button'
import { Form, StringInputGroup } from 'components/Form'
import { UnauthenticatedPage, FormContainer } from 'pages/Page'
import { useQueryParams } from 'utils/useQueryParams'
import { regularBodyBoldTextStyle } from 'config/styles'

const ReturnToLoginLink = styled(Link)({
  ...regularBodyBoldTextStyle,
  color: 'var(--gray3)',
  marginBottom: 28,
  textAlign: 'center',
})

const HeaderText = styled.p({
  fontSize: '1.625rem',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '1.625rem',
  marginBottom: 12,
  textAlign: 'center',
})

const BodyText = styled.p({
  fontSize: '1.125rem',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '24px',
  marginBottom: 30,
  textAlign: 'center',
})

const minimumPasswordLength = 8

export default () => {
  const { t } = useTranslation()

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [error, setError] = useState('')
  const resetPasswordToken = useQueryParams().get('token') || ''
  const history = useHistory()
  const [resetPasswordMutation] = useResetPasswordMutation({
    update: (_, result) => {
      const resetPasswordResult = result?.data?.resetPassword

      if (resetPasswordResult) {
        const success: boolean = resetPasswordResult.success

        if (success) {
          history.push(`${routeMap.login}?after-reset=true`)
        } else {
          setError(
            t(
              resetPasswordResult.errorCode === 'weakPassword'
                ? 'resetPassword.weakPassword'
                : 'resetPassword.invalidResetLink'
            )
          )
        }
      } else {
        setError(t('resetPassword.somethingWentWrong'))
      }
    },
    variables: { resetPasswordToken, newPassword: password },
  })

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setError('')
  }

  const onPasswordConfirmationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmation(e.target.value)
    setError('')
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    resetPasswordMutation()
  }

  const isDisabled =
    password.length < minimumPasswordLength || password !== passwordConfirmation

  return (
    <UnauthenticatedPage>
      <FormContainer>
        <ReturnToLoginLink
          data-testid={t('resetPassword.returnToLogin')}
          to={routeMap.login}
        >
          {'<< ' + t('resetPassword.returnToLogin')}
        </ReturnToLoginLink>
        <HeaderText>{t('resetPassword.resetYourPassword')}</HeaderText>
        <BodyText>{t('resetPassword.instructions')}</BodyText>
        <Form onSubmit={onSubmit} error={error}>
          <StringInputGroup
            controlProps={{
              placeholder: t('resetPassword.passwordPlaceholder'),
              value: password,
              onChange: onPasswordChange,
              type: 'password',
            }}
          />
          <StringInputGroup
            controlProps={{
              placeholder: t('resetPassword.passwordConfirmationPlaceholder'),
              value: passwordConfirmation,
              onChange: onPasswordConfirmationChange,
              type: 'password',
            }}
          />
          <Button
            block
            disabled={isDisabled}
            size="lg"
            type="submit"
            variant="primary"
          >
            {t('resetPassword.changePassword')}
          </Button>
        </Form>
      </FormContainer>
    </UnauthenticatedPage>
  )
}
