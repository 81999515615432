import React from 'react'

import LearnMoreTextImage from 'assets/images/learn-more-text.svg'
import LearnMoreChevronImage from 'assets/images/learn-more-chevron.svg'
import { LearnMoreWrapper, LearnMoreChevron } from 'pages/covid/welcome/styles'

export const LearnMore = () => (
  <LearnMoreWrapper>
    <img src={LearnMoreTextImage} alt="Learn More" />
    <LearnMoreChevron src={LearnMoreChevronImage} alt="learn-more" />
  </LearnMoreWrapper>
)
