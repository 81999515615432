import React from 'react'
import { useTranslation } from 'react-i18next'
import { asDollars } from 'utils/currency'
import {
  StyledLaborPercentageCell,
  StyledScheduleDailyPercentageCost,
} from 'pages/plan/scheduling/styles'
import { SalesAndLaborSummaryFragment } from 'config/graphqlTypes'
import { SpanDivider } from '../styles'

interface Props {
  testId: string
  weeklyTotalDollarsScheduled: number
  weeklyPredictedSales: SalesAndLaborSummaryFragment['latestPredictedSales']
}

export const LaborPercentageWeekCell = ({
  testId,
  weeklyTotalDollarsScheduled,
  weeklyPredictedSales,
}: Props) => {
  const { t } = useTranslation()

  const laborPercentage = weeklyPredictedSales
    ? ((weeklyTotalDollarsScheduled || 0) / weeklyPredictedSales) * 100
    : 0

  return (
    <StyledLaborPercentageCell data-testid={testId}>
      <StyledScheduleDailyPercentageCost>
        {t('planning.calculatedLaborPercentage', {
          value: laborPercentage.toFixed(1),
        })}{' '}
        <SpanDivider>{'|'}</SpanDivider>
        {asDollars(weeklyTotalDollarsScheduled)}
      </StyledScheduleDailyPercentageCost>
    </StyledLaborPercentageCell>
  )
}
