import React, { FC } from 'react'
import { CustomLayerProps } from '@nivo/line'
import { SmartMotion } from '@nivo/core'

import {
  getSerieConfig,
  isFutureActuals,
} from 'components/Chart/chartDataConfig'

// nivo doesn't export these types from @nivo/core so define them here
declare module '@nivo/core' {
  export function SmartMotion(x: any): any
}

const springConfig = {
  damping: 15,
  stiffness: 90,
}

export const LineLayer: FC<CustomLayerProps> = ({
  lineGenerator,
  lineWidth,
  series,
}) => {
  return (
    <>
      {series.map(({ id, data }) => {
        const points = data.map(d => d.position)
        const { color } = getSerieConfig(id)
        const dashed = isFutureActuals(id)

        return (
          <SmartMotion
            key={id}
            style={(spring: any) => ({
              d: spring(lineGenerator(points), springConfig),
            })}
          >
            {(style: { d: string }) => (
              <path
                d={style.d}
                fill="none"
                strokeDasharray={dashed ? '3 4' : undefined}
                strokeWidth={lineWidth}
                style={{ stroke: color }}
              />
            )}
          </SmartMotion>
        )
      })}
    </>
  )
}
