import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import {
  AccordionExcludeFromLaborWrapper,
  AnimatedLoading,
  CloseLaborHrsBreakdownButton,
  LaborHrsBreakdownStyledModal,
  StyledLaborHrsBreakdownModalContainer,
  WeeklyTotalHrsWrapper,
} from './styles'
import CloseIcon from 'assets/images/close-icon.svg'
import { DateTime } from 'luxon'
import { LaborHoursBreakdownHeader } from './LaborHoursBreakdownHeader'
import { LaborHoursBreakdownList } from './LaborHoursBreakdownList'
import { Loading } from '../Loading'
import { CSSTransition } from 'react-transition-group'
import { LaborHoursBreakdownTotalHrs } from './LaborHoursBreakdownTotalHrs'
import { LaborHoursBreakdownAccordion } from './LaborHoursBreakdownAccordion'
import { LaborHoursBreakdownListHeader } from './LaborHoursBreakdownListHeader'
import { useTranslation } from 'react-i18next'
import {
  LaborHoursDaily,
  LaborHoursDateRange,
} from '../../config/graphqlTypesRaw'
import { LaborHoursBreakdownWeekdayAccordions } from './LaborHoursBreakdownWeekdayAccordions'

interface LaborHoursBreakdownModalProps {
  isWeekView: boolean
  show: boolean
  loading: boolean
  isCurrentDayOrWeek: boolean
  day: DateTime // the day to show
  startOfWeek: DateTime // beginning of week to show
  toggleShow: () => void
  laborHoursDaily: LaborHoursDaily | undefined
  laborHoursDateRange: LaborHoursDateRange | undefined
}

export const LaborHoursBreakdownModal = ({
  isWeekView,
  show,
  loading,
  day,
  startOfWeek,
  isCurrentDayOrWeek,
  toggleShow,
  laborHoursDaily,
  laborHoursDateRange,
}: LaborHoursBreakdownModalProps) => {
  const [showContent, setShowContent] = useState(false)

  const { t } = useTranslation()
  const excludedFromLaborTitle = t(
    'tracking.laborActualHours.excludedFromLaborBreakdown'
  )

  const translationNamespaceColumns = [
    'employeeLabel',
    'roleLabel',
    'actualHrsWorkedLabel',
    isCurrentDayOrWeek ? 'shiftLabel' : 'scheduledHrsLabel',
  ]

  const dailyTotalHrsTitle = t('tracking.laborActualHours.totalHrsLabel')
  const weeklyTotalHrsTitle = t('tracking.laborActualHours.weeklyTotalHrsLabel')
  const weeklyExcludedTotalHrsTitle = t(
    'tracking.laborActualHours.weeklyExcludedTotalHrsLabel'
  )

  const onHideModal = () => {
    setShowContent(false)
    toggleShow()
  }

  return (
    <LaborHrsBreakdownStyledModal
      show={show}
      onHide={onHideModal}
      scrollable={true}
      size="lg"
    >
      <Modal.Body>
        <StyledLaborHrsBreakdownModalContainer fluid>
          <CloseLaborHrsBreakdownButton onClick={onHideModal}>
            <img src={CloseIcon} alt="close labor hours breakdown view" />
          </CloseLaborHrsBreakdownButton>
          <LaborHoursBreakdownHeader
            day={day}
            isWeekView={isWeekView}
            startOfWeek={startOfWeek}
          />
          {showContent &&
            (isWeekView ? (
              <>
                <LaborHoursBreakdownWeekdayAccordions
                  headerTitles={translationNamespaceColumns}
                  isCurrentDayOrWeek={isCurrentDayOrWeek}
                  laborHoursDateRange={laborHoursDateRange}
                />
                <WeeklyTotalHrsWrapper>
                  <LaborHoursBreakdownTotalHrs
                    title={weeklyTotalHrsTitle}
                    totalPlannedHrs={
                      laborHoursDateRange?.totalWeeklyPlannedHours ?? 0
                    }
                    totalHrs={laborHoursDateRange?.totalWeeklyHours ?? 0}
                  />
                </WeeklyTotalHrsWrapper>
                <AccordionExcludeFromLaborWrapper>
                  <LaborHoursBreakdownAccordion
                    eventKey="0"
                    title={excludedFromLaborTitle}
                    totalHrsTitle={weeklyExcludedTotalHrsTitle}
                    isCurrentDayOrWeek={isCurrentDayOrWeek}
                    laborHoursDateRange={laborHoursDateRange}
                    totalHrs={laborHoursDateRange?.totalWeeklyHoursExcluded}
                    totalPlannedHrs={
                      laborHoursDateRange?.totalWeeklyPlannedHoursExcluded
                    }
                  />
                </AccordionExcludeFromLaborWrapper>
              </>
            ) : (
              <>
                <LaborHoursBreakdownListHeader
                  columns={translationNamespaceColumns}
                />
                <LaborHoursBreakdownList
                  isCurrentDayOrWeek={isCurrentDayOrWeek}
                  laborHourEmployees={laborHoursDaily?.laborHourEmployees}
                />
                <LaborHoursBreakdownTotalHrs
                  title={dailyTotalHrsTitle}
                  totalPlannedHrs={laborHoursDaily?.totalPlannedHours ?? 0}
                  totalHrs={laborHoursDaily?.totalLaborHours ?? 0}
                />
                <AccordionExcludeFromLaborWrapper>
                  <LaborHoursBreakdownAccordion
                    eventKey="0"
                    title={excludedFromLaborTitle}
                    totalHrsTitle={dailyTotalHrsTitle}
                    isCurrentDayOrWeek={isCurrentDayOrWeek}
                    laborHourEmployees={laborHoursDaily?.excludeFromLabor}
                    totalHrs={laborHoursDaily?.totalLaborHoursExcluded}
                    totalPlannedHrs={laborHoursDaily?.totalPlannedHoursExcluded}
                  />
                </AccordionExcludeFromLaborWrapper>
              </>
            ))}
          <CSSTransition
            in={loading}
            timeout={500}
            unmountOnExit
            classNames="loading"
            onEnter={() => setShowContent(false)}
            onExited={() => setShowContent(true)}
          >
            <AnimatedLoading>
              <Loading height="20rem" />
            </AnimatedLoading>
          </CSSTransition>
        </StyledLaborHrsBreakdownModalContainer>
      </Modal.Body>
    </LaborHrsBreakdownStyledModal>
  )
}
