import React, { FC } from 'react'
import Button from 'components/Button'
import {
  DailyViewAddShiftButtonWrapper,
  DailyViewAddShiftIcon,
} from 'pages/plan/dailyViewModal/schedule/styles'
import plusIconWhite from 'assets/images/plus-white.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  onClickAddShift: () => void
}

export const DailyViewAddShiftButton: FC<Props> = ({ onClickAddShift }) => {
  const { t } = useTranslation()

  return (
    <DailyViewAddShiftButtonWrapper>
      <Button
        data-testid="daily-view-add-shift-button"
        variant="secondary"
        size="sm"
        onClick={onClickAddShift}
      >
        <DailyViewAddShiftIcon src={plusIconWhite} alt="" />
        {t('schedule.addShiftBtn')}
      </Button>
    </DailyViewAddShiftButtonWrapper>
  )
}
