import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'

import translations from './translations.json'

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng: 'en',
    returnNull: false,
    resources: translations,
    lng: 'en',
    interpolation: { escapeValue: false },
  })
