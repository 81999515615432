import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { partition } from 'lodash'

import { PlanSummaryLightFragment } from 'config/graphqlTypes'
import { PlanRow } from 'pages/plans/PlanRow'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import {
  Col,
  Label,
  HeaderRow,
  FlexTable,
  TableContainer,
  HistoryDividerRow,
} from 'pages/plans/styles'

interface Props {
  plans: PlanSummaryLightFragment[]
}

export const PlansTable = ({ plans }: Props) => {
  const { t } = useTranslation()
  const {
    location: { prefersEntreesPerLaborHourMetric },
  } = useCurrentLocation()

  const localDateTime = DateTime.local()

  const [futurePlans, pastPlans] = partition(
    plans,
    plan =>
      (plan.endOn.hasSame(localDateTime, 'year') &&
        plan.endOn.hasSame(localDateTime, 'month') &&
        plan.endOn.hasSame(localDateTime, 'day')) ||
      plan.endOn > localDateTime
  )
  const perLaborHourLabel = t(
    prefersEntreesPerLaborHourMetric ? 'plans.eplh' : 'plans.splh'
  )

  return (
    <TableContainer>
      <FlexTable>
        <HeaderRow data-testid="plans-headers-row">
          <Col size={1.4}>{t('plans.period')}</Col>
          <Col>
            {t('plans.planned')}
            <Label>{t('plans.sales')}</Label>
          </Col>
          <Col singleLabel>
            <Label>{t('plans.labor')}</Label>
          </Col>
          <Col singleLabel>
            <Label>{perLaborHourLabel}</Label>
          </Col>
          <Col>
            {t('plans.actual')}
            <Label>{t('plans.sales')}</Label>
          </Col>
          <Col singleLabel>
            <Label>{t('plans.labor')}</Label>
          </Col>
          <Col singleLabel>
            <Label>{perLaborHourLabel}</Label>
          </Col>
          <Col center>{t('plans.status')}</Col>
        </HeaderRow>
        {futurePlans
          .sort((a, b) => a.startOn.valueOf() - b.startOn.valueOf())
          .map((plan, index) => (
            <PlanRow key={plan.id} plan={plan} index={index} />
          ))}
        <HistoryDividerRow>{t('plans.history')}</HistoryDividerRow>
        {pastPlans.map((plan, index) => (
          <PlanRow key={plan.id} plan={plan} isPast index={index} />
        ))}
      </FlexTable>
    </TableContainer>
  )
}
