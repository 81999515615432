import React, { FC, useEffect, useReducer, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button, { TextButton } from 'components/Button'
import StyledModalTitle from 'components/StyledModalTitle'
import { useTranslation } from 'react-i18next'
import { CharacterCountText, Subheader } from 'components/Text'
import { SelectDropdownOption } from 'components/SelectDropdown'
import { MultiValue } from 'react-select'
import { MessageFormLabel as Label } from '../styles'
import SelectControl from './SelectControl'
import {
  useCurrentLocationShiftRoles,
  Role,
  Department,
  LocationShiftRoles,
} from 'queries/useCurrentLocationShiftRoles'
import { isEqual, size, values } from 'lodash'

export type SelectedRoles = {
  departments: number[] | null
  roles: number[] | null
}

interface Props {
  show: boolean
  onHide: () => void
  description?: string | JSX.Element
  textBox?: JSX.Element
  inputText: string
  onConfirm?: () => Promise<void>
  onSelectRoles: (selectedRoles: SelectedRoles) => void
  disable?: boolean
  waitOnPromise?: boolean
  hideCancel?: boolean
}

type State = {
  roles: { [id: string]: Role }
  departments: { [id: string]: Department }
  activeRoles: Role[]
  selectedRoles: Role[]
  selectedDepartments: Department[]
  onSelectRoles: (selectedRoles: SelectedRoles) => void
}

type Action = {
  type: 'changeRoles' | 'changeDepartments' | 'setLocationShiftRoles'
  options: MultiValue<SelectDropdownOption>
  locationShiftRoles?: LocationShiftRoles
}

const triggerCallback = (state: State) => {
  const roleIds =
    state.selectedRoles.length === state.activeRoles.length
      ? null
      : state.selectedRoles.map(role => parseInt(role.id))
  const departmentIds =
    state.selectedDepartments.length === size(state.departments)
      ? null
      : state.selectedDepartments.map(dept => parseInt(dept.id))
  state.onSelectRoles({ roles: roleIds, departments: departmentIds })
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setLocationShiftRoles': {
      if (!action.locationShiftRoles) return state
      const { roles, departments } = action.locationShiftRoles
      const newState = {
        ...state,
        roles,
        departments,
        activeRoles: values(roles),
        selectedRoles: values(roles),
        selectedDepartments: values(departments),
      }
      triggerCallback(newState)
      return newState
    }

    case 'changeRoles': {
      const newState = {
        ...state,
        selectedRoles: action.options.map(option => state.roles[option.value]),
      }
      triggerCallback(newState)
      return newState
    }

    case 'changeDepartments': {
      const selectedDepartments = action.options.map(
        option => state.departments[option.value]
      )
      const activeRoles = action.options.flatMap(option =>
        state.departments[option.value]?.roles.map(
          roleId => state.roles[roleId]
        )
      )
      const newState = {
        ...state,
        selectedDepartments,
        activeRoles,
        selectedRoles: activeRoles,
      }
      triggerCallback(newState)
      return newState
    }
  }
}

const MassNotificationModal: FC<Props> = ({
  show,
  onHide,
  description,
  textBox,
  inputText,
  onConfirm,
  onSelectRoles,
  disable,
  waitOnPromise = true,
  hideCancel = false,
}) => {
  const { t } = useTranslation()

  const locationShiftRoles = useCurrentLocationShiftRoles()

  const [state, dispatch] = useReducer(reducer, {
    roles: {},
    departments: {},
    activeRoles: [],
    selectedRoles: [],
    selectedDepartments: [],
    onSelectRoles,
  })

  const locationShiftRolesRef = useRef<LocationShiftRoles>()
  useEffect(() => {
    if (isEqual(locationShiftRolesRef.current, locationShiftRoles)) return
    locationShiftRolesRef.current = locationShiftRoles
    dispatch({ type: 'setLocationShiftRoles', locationShiftRoles, options: [] })
  }, [locationShiftRoles])

  const onChangeDepartments = (options: MultiValue<SelectDropdownOption>) => {
    dispatch({ type: 'changeDepartments', options })
  }

  const onChangeRoles = (options: MultiValue<SelectDropdownOption>) => {
    dispatch({ type: 'changeRoles', options })
  }

  return (
    <Modal
      data-testid="submit-modal"
      show={show}
      onHide={onHide}
      centered
      size={'lg'}
    >
      <Modal.Header closeButton style={{ border: 'none' }}>
        <StyledModalTitle>
          {t('management.massNotification.title')}
        </StyledModalTitle>
      </Modal.Header>
      <Subheader style={{ paddingBottom: '20px' }}>
        {t('management.massNotification.subheader')}
      </Subheader>
      <Modal.Body
        style={{
          paddingLeft: '65px',
          paddingRight: '65px',
          whiteSpace: 'pre-wrap',
          textAlign: 'left',
        }}
      >
        {description}
        <Label>{t('management.massNotification.departmentLabel')}</Label>
        <SelectControl
          options={values(state.departments).map(dept => ({
            value: dept.id,
            label: dept.name,
          }))}
          onChange={onChangeDepartments}
          value={state.selectedDepartments.map(dept => ({
            value: dept.id,
            label: dept.name,
          }))}
          selectAllLabel={t(
            'management.massNotification.departments.allDepartments'
          )}
        />
        <Label style={{ paddingTop: '30px' }}>
          {t('management.massNotification.roleLabel')}
        </Label>
        <SelectControl
          options={state.activeRoles.map(role => ({
            value: role.id,
            label: role.name,
          }))}
          value={state.selectedRoles.map(role => ({
            value: role.id,
            label: role.name,
          }))}
          onChange={onChangeRoles}
          selectAllLabel={t('management.massNotification.roles.allRoles')}
        />
        <Label style={{ paddingTop: '30px' }}>
          {t('management.massNotification.messageLabel')}
        </Label>
        {textBox}
        <CharacterCountText>
          Characters Left: {500 - inputText.length}
        </CharacterCountText>
      </Modal.Body>
      <Modal.Footer
        style={{
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {onConfirm && (
          <Button
            style={{ minWidth: '220px', marginTop: '15px' }}
            size="lg"
            variant="blue"
            onClick={onConfirm}
            data-testid="confirm-submit-modal"
            waitOnPromise={waitOnPromise}
            disabled={disable}
          >
            {t('management.massNotification.confirm')}
          </Button>
        )}
        {!hideCancel && (
          <TextButton
            style={{ fontWeight: 500, color: 'var(--gray3)' }}
            onClick={onHide}
          >
            {t('buttons.cancel')}
          </TextButton>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default MassNotificationModal
