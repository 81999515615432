import * as chrono from 'chrono-node'
import { DateTime } from 'luxon'

const ROUND_TO_MINUTES = 10

export type DaysOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export const dayOfWeekToNumber = (day: DaysOfWeek): number =>
  ({
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
  }[day])

export const convertTo12HourFormat = (time: string) => {
  const components = chrono.parse(time)[0]?.start

  if (!components) return null

  const hour = components.get('hour')
  const minute = components.get('minute')

  if (hour === null || minute === null) return null

  const roundedMinute = Math.round(minute / ROUND_TO_MINUTES) * ROUND_TO_MINUTES
  const roundedHour = roundedMinute === 60 ? hour + 1 : hour

  const fixedMinute = roundedMinute === 60 ? 0 : roundedMinute

  const paddedHour = roundedHour.toString().padStart(2, '0')
  const paddedMinute = fixedMinute.toString().padStart(2, '0')

  const dateTime = DateTime.fromISO(`${paddedHour}:${paddedMinute}`)

  if (!dateTime.isValid) return null

  return dateTime.toFormat('h:mm a')
}

export const convertTo24HourFormat = (time: string, isDateTime = false) => {
  const timeToFormat = isDateTime
    ? ((time as unknown) as DateTime)
    : DateTime.fromFormat(time, 'h:mm a')
  return time && timeToFormat.isValid ? timeToFormat.toFormat('HH:mm') : ''
}

export const convertToDefault12HourFormat = (time: string) => {
  const timeToFormat = DateTime.fromISO(time)
  return time && timeToFormat.isValid ? timeToFormat.toFormat('h:mm a') : ''
}
