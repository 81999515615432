import React, { createContext, ReactNode, useContext, useState } from 'react'
import { DatePickerRange } from '../../components/DatePicker'
import { DateTime } from 'luxon'
import { useLoggedInUser } from '../../queries/useLoggedInUser'
import { MultiValue } from 'react-select'
import { SelectDropdownOption } from '../../components/SelectDropdown'

type ContextProps = {
  dateRangeType: DatePickerRange
  startDate: DateTime | null
  endDate: DateTime | null
  locationOptions: SelectDropdownOption[]
  selectedLocationOptions: MultiValue<SelectDropdownOption | null>
  setDateRangeType: (dateRangeType: DatePickerRange) => void
  setStartDate: (startDate: DateTime | null) => void
  setEndDate: (endDate: DateTime | null) => void
  setSelectedLocationOptions: (
    locationOptions: MultiValue<SelectDropdownOption | null>
  ) => void
}

type Props = {
  children: ReactNode
}

const ToucanInsightsContext = createContext<ContextProps>({
  dateRangeType: 'lastWeek',
  startDate: null,
  endDate: null,
  locationOptions: [],
  selectedLocationOptions: [],
  setDateRangeType: () => {
    throw new Error(
      'setDateRangeType not implemented! Are you inside ToucanInsightsContext Provider?'
    )
  },
  setStartDate: () => {
    throw new Error(
      'setStartDate not implemented! Are you inside ToucanInsightsContext Provider?'
    )
  },
  setEndDate: () => {
    throw new Error(
      'setEndDate not implemented! Are you inside ToucanInsightsContext Provider?'
    )
  },
  setSelectedLocationOptions: () => {
    throw new Error(
      'setLocationOptions not implemented! Are you inside ToucanInsightsContext Provider?'
    )
  },
})

export const ToucanInsightsProvider = ({ children }: Props) => {
  const [dateRangeType, setDateRangeType] = useState<DatePickerRange>(
    'lastWeek'
  )
  const [startDate, setStartDate] = useState<DateTime | null>(null)
  const [endDate, setEndDate] = useState<DateTime | null>(null)
  const user = useLoggedInUser()

  let locationOptions =
    user?.locations.map(location => ({
      label: location.name,
      value: location.id,
    })) ?? []

  const [selectedLocationOptions, setSelectedLocationOptions] = useState<
    MultiValue<SelectDropdownOption | null>
  >(locationOptions)

  return (
    <ToucanInsightsContext.Provider
      value={{
        dateRangeType,
        startDate,
        endDate,
        locationOptions,
        selectedLocationOptions,
        setDateRangeType,
        setStartDate,
        setEndDate,
        setSelectedLocationOptions,
      }}
    >
      {children}
    </ToucanInsightsContext.Provider>
  )
}

export const useToucanInsights = () => useContext(ToucanInsightsContext)
