import styled from 'styled-components'

const Page = styled.div({
  minHeight: '100vh',
  '@media screen': {
    display: 'flex',
    flexDirection: 'column',
  },

  '@media print': {
    height: 'auto',
  },
})

export const UnauthenticatedPage = styled(Page)({
  backgroundColor: 'var(--blue)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 50,
})

export const FormContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 458,
  backgroundColor: 'var(--white)',
  paddingTop: 28,
  paddingBottom: 53,
  paddingLeft: 26,
  paddingRight: 26,
  boxShadow: '0px 0px 20px rgba(13, 13, 13, 0.5)',
  borderRadius: 10,
})

export default Page
