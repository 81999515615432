const MIN_EMAIL_LENGTH = 5
const EMAIL_REGEX = /^\S+@\S+\..+$/

export const isEmailValidLength = (email: string) =>
  email.length >= MIN_EMAIL_LENGTH

export const isEmailValidFormat = (email: string) => EMAIL_REGEX.test(email)

export const isEmailValid = (email: string) =>
  isEmailValidLength(email) && isEmailValidFormat(email)
