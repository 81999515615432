import { useEffect, useRef } from 'react'

export const useToucanUrlScript = (url: string, token: string | undefined) => {
  const divRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!token) return

    const script = document.createElement('script')
    script.src = `${url}&token=${token}`
    script.type = 'text/javascript'
    script.async = true

    divRef.current?.appendChild(script)

    const divRefInstance = divRef.current

    return () => {
      divRefInstance?.removeChild(divRefInstance?.children[0])
    }
  }, [token, url])

  return divRef
}
