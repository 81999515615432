import React from 'react'

import { CustomLayerProps } from 'components/Chart/CustomLayer'

const axisPaddingLeft = 0
const axisPaddingRight = 0

export const BottomChartBorderColor = 'var(--gray5)'
export const BottomChartBorderWidth = 2
export const ChartVerticalLineColor = 'var(--gray6)'
export const ChartVerticalLineWidth = 1

export const GridLines = (xTickCount: number) => ({
  height,
  margin: marginMaybe,
  width,
}: CustomLayerProps) => {
  const margin = { top: 0, right: 0, bottom: 0, left: 0, ...marginMaybe }
  const graphHeight = height - (margin.top + margin.bottom)
  const graphWidth = width - (margin.right + margin.left)

  const xTicks = Array.from(
    Array(xTickCount),
    (_, i) => i * (graphWidth / (xTickCount - 1))
  )

  return (
    <g>
      {xTicks.map((xTick, i) => {
        return (
          <line
            key={i}
            data-testid="chart-grid-line"
            stroke={ChartVerticalLineColor}
            strokeWidth={ChartVerticalLineWidth}
            x1={xTick}
            x2={xTick}
            y1={0}
            y2={graphHeight}
          />
        )
      })}
      <line
        key="grid-x-axis"
        stroke={BottomChartBorderColor}
        strokeWidth={BottomChartBorderWidth}
        x1={axisPaddingLeft - margin.left}
        x2={width - margin.left - axisPaddingRight}
        y1={graphHeight}
        y2={graphHeight}
      />
    </g>
  )
}
