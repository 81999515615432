import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { ViolationFragment } from 'config/graphqlTypes'
import { getViolationDetails } from 'utils/getViolationDetails'
import {
  ViolationWrapper,
  ViolationTitle,
  ViolationDetails,
} from 'pages/plan/scheduling/styles'
import { useCurrentLocationId } from 'queries/useCurrentLocation'

export interface ViolationShiftDetails {
  endAt?: DateTime
  startAt?: DateTime
  date?: DateTime
  locationId?: string | null
  shiftId?: string
  unavailabilityId?: string
  isAllDayUnavailability?: boolean
}

interface ViolationProps {
  employeeName: string
  currentShift: ViolationShiftDetails
  violation: ViolationFragment
  date?: DateTime
}

const Violation = ({
  employeeName,
  currentShift,
  violation,
  violation: { name, limit },
  date,
}: ViolationProps) => {
  const { t } = useTranslation()
  const locationId = useCurrentLocationId()
  const details = getViolationDetails({
    employeeName,
    currentShift,
    violation,
    t,
    locationId,
    date,
  })
  const title = t(`schedule.violations.validations.${name}.title`, {
    count: limit,
    employeeName,
  })

  return (
    <ViolationWrapper data-testid={violation.id}>
      <ViolationTitle>• {title}</ViolationTitle>
      <ViolationDetails>{details}</ViolationDetails>
    </ViolationWrapper>
  )
}

interface Props {
  employeeName: string
  currentShift: ViolationShiftDetails
  violations: ViolationFragment[]
  date?: DateTime
}

export const Violations = ({
  employeeName,
  currentShift,
  violations,
  date,
}: Props) => (
  <>
    {violations.map(violation => (
      <Violation
        employeeName={employeeName}
        key={violation.id}
        currentShift={currentShift}
        violation={violation}
        date={date}
      />
    ))}
  </>
)
