import React from 'react'
import { DateTime, Interval } from 'luxon'
import { useTranslation } from 'react-i18next'

import { asDollarsOrEmDash } from 'utils/currency'
import sumOrNull from 'utils/sumOrNull'
import {
  getReportedSalesForSum,
  useGetFutureOrderSums,
} from 'pages/plan/useGetFutureOrderSums'
import { MultiRowPlanRow } from 'pages/plan/rows/PlanRow'
import { IntervalOrderSumFragment } from 'config/graphqlTypes'
import { useCurrentLocationCategories } from 'queries/useCurrentLocation'

export const sumSalesAndFormat = (
  orderSums: IntervalOrderSumFragment[],
  salesType: string
) => asDollarsOrEmDash(sumOrNull(orderSums.map(getReportedSalesForSum)))

const getDataRows = (
  days: Interval[],
  sales: IntervalOrderSumFragment[],
  categories: string[],
  salesType: string
) => {
  const dailyAndWeeklySums = (filteredSales: IntervalOrderSumFragment[]) => {
    const dailySums = days.map(day =>
      sumSalesAndFormat(
        filteredSales.filter(s => day.contains(s.startAt)),
        salesType
      )
    )

    const weeklySum = sumSalesAndFormat(filteredSales, salesType)

    return [...dailySums, weeklySum]
  }

  const sumRow = dailyAndWeeklySums(sales)

  const categoryRows = categories.map(category =>
    dailyAndWeeklySums(sales.filter(s => s.category === category))
  )

  return [sumRow, ...categoryRows]
}

interface Props {
  startDate: DateTime
  endDate: DateTime
  days: Interval[]
  salesType: string
}

export const ExistingOrdersRow = ({
  startDate,
  endDate,
  days,
  salesType,
}: Props) => {
  const { t } = useTranslation()

  const { orderSums, categories } = useGetFutureOrderSums(startDate, endDate)
  const { categories: locationCategories } = useCurrentLocationCategories()

  const subtitles = categories.map(categoryName => {
    const matchingCategory = Object.keys(locationCategories).find(category =>
      locationCategories[category].includes(categoryName)
    )
    return matchingCategory || t(`orderCategories.${categoryName}`)
  })
  const dataRows = getDataRows(days, orderSums, categories, salesType)

  return (
    <MultiRowPlanRow
      title={t('planning.existingOrders')}
      subtitles={subtitles}
      dataRows={dataRows}
    />
  )
}
