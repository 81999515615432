import React, { ChangeEvent, CSSProperties, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Check, StringInputGroup } from './Form'
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg'
import { ReactComponent as PlusIcon } from '../assets/images/plus-grey.svg'
import { convertTo12HourFormat } from '../utils/timeFormats'

type TimeInputEvent = ChangeEvent<HTMLInputElement>

type ControlFlags = {
  showRemoveIcon?: boolean
  showAddIcon?: boolean
  showCheckbox?: boolean
  disableAddIcon?: boolean
  disableRemoveIcon?: boolean
}

type ControlActions = {
  onClickCheck?: (checked: boolean) => void
  onClickAdd?: () => void
  onClickRemove?: () => void
}

type RowControlsType = {
  checkboxId?: string
  checked?: boolean
  checkboxLabel?: string
  flags?: ControlFlags
  actions?: ControlActions
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  label: string
  start: string
  end: string
  disableStart?: boolean
  disableEnd?: boolean
  containerStyle?: CSSProperties
  rowControls?: RowControlsType
  onChangeStart: (start: string) => void
  onChangeEnd: (end: string) => void
  onBlurStart?: (start: string) => void
  onBlurEnd?: (end: string) => void
}

function TimeInputsRow({
  label,
  start,
  end,
  containerStyle,
  disableStart,
  disableEnd,
  rowControls,
  onChangeStart,
  onChangeEnd,
  onBlurStart,
  onBlurEnd,
  ...props
}: Props) {
  const handleStartChange = (e: TimeInputEvent) => {
    onChangeStart(e.target.value)
  }

  const handleEndChange = (e: TimeInputEvent) => {
    onChangeEnd(e.target.value)
  }

  const handleStartBlur = (e: TimeInputEvent) => {
    const time = convertTo12HourFormat(e.target.value)
    time && onBlurStart?.(time)
  }

  const handleEndBlur = (e: TimeInputEvent) => {
    const time = convertTo12HourFormat(e.target.value)
    time && onBlurEnd?.(time)
  }

  return (
    <Container {...props}>
      <StringInputGroup
        labelProps={{
          label,
        }}
        controlProps={{
          disabled: disableStart,
          value: start,
          onChange: handleStartChange,
          onBlur: handleStartBlur,
          style: {
            minWidth: 125,
            maxWidth: 125,
          },
        }}
        containerStyle={containerStyle}
      >
        <Divider />
      </StringInputGroup>

      <StringInputGroup
        labelProps={{
          label: ' ',
        }}
        controlProps={{
          disabled: disableEnd,
          value: end,
          onChange: handleEndChange,
          onBlur: handleEndBlur,
          style: {
            minWidth: 125,
            maxWidth: 125,
          },
        }}
        containerStyle={containerStyle}
      >
        {rowControls && <RowControls {...rowControls} />}
      </StringInputGroup>
    </Container>
  )
}

function RowControls({
  checkboxId,
  checked,
  checkboxLabel,
  flags,
  actions,
}: RowControlsType) {
  const {
    showCheckbox,
    showRemoveIcon,
    showAddIcon,
    disableAddIcon,
    disableRemoveIcon,
  } = flags ?? {
    showCheckbox: false,
    showRemoveIcon: false,
    showAddIcon: false,
    disableAddIcon: false,
    disableRemoveIcon: false,
  }

  const { onClickCheck, onClickRemove, onClickAdd } = actions ?? {
    onClickCheck: undefined,
    onClickAdd: undefined,
    onClickRemove: undefined,
  }

  if (showCheckbox && !checkboxId) {
    throw new Error('Checkbox ID is required')
  }

  const handleCheckChange = (e: TimeInputEvent) => {
    onClickCheck?.(e.target.checked)
  }

  const handleAddClick = () => {
    !disableAddIcon && onClickAdd?.()
  }

  const handleRemoveClick = () => {
    !disableRemoveIcon && onClickRemove?.()
  }

  return (
    <>
      {showCheckbox && (
        <Check
          id={checkboxId ?? ''}
          label={checkboxLabel ?? ''}
          inputProps={{
            checked: checked,
            onChange: handleCheckChange,
          }}
        />
      )}
      {showRemoveIcon && (
        <StyledTrashIcon
          onClick={handleRemoveClick}
          title="Remove this row"
          disabled={disableRemoveIcon}
        />
      )}
      {showAddIcon && (
        <StyledPlusIcon
          onClick={handleAddClick}
          title="Add new row "
          disabled={disableAddIcon}
        />
      )}
    </>
  )
}

const StyledPlusIcon = styled(PlusIcon)<{ disabled: boolean | undefined }>`
  width: 20px;
  height: 20px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const StyledTrashIcon = styled(TrashIcon)<{ disabled: boolean | undefined }>`
  width: 20px;
  height: 20px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

const Divider = styled.div`
  display: flex;
  width: 17.636px;
  height: 1px;
  flex-shrink: 0;
  background: var(--gray5);
`

export default TimeInputsRow
