import React, { Fragment } from 'react'
import { Interval } from 'luxon'
import { useTranslation } from 'react-i18next'
import {
  useCurrentLocation,
  useCurrentLocationCategories,
} from 'queries/useCurrentLocation'
import {
  StatsContainer,
  StatSection,
  StatTitle,
  StatSubtitle,
  StatValue,
  CompoundStatTitle,
  OrdersTotal,
  StatTableTitle,
  OrdersTotalTitle,
  StatSectionCol,
  StatValueRow,
  CompoundStatBody,
} from 'pages/plan/dailyViewModal/styles'
import { TableLabelText } from 'components/Text'
import { asDollarsOrEmDash } from 'utils/currency'
import { useGetFutureOrderSums } from 'pages/plan/useGetFutureOrderSums'
import { useWeatherDaily } from 'queries/useWeather'
import { useDailyLocationEvents } from 'queries/useDailyLocationEvents'
import { sumSalesAndFormat } from 'pages/plan/rows/ExistingOrdersRow'
import {
  InternalEventCard,
  InternalEventType,
} from 'pages/plan/InternalEventCard'
import { useGetInternalEventDetails } from 'queries/useGetInternalEventDetails'
import { useGetReservationDetails } from 'queries/useGetReservationDetails'
import { ReservationCard, ReservationType } from '../ReservationCard'
import { ReservationsImporter } from 'config/graphqlTypesRaw'
import { WeatherDisplayRow } from 'components/Weather/WeatherDisplayRow'

interface Props {
  day: Interval
  lastWeekSameDayBenchmark: number
  lastMonthSameDayBenchmark: number
  lastYearSameDayBenchmark: number
}

const CompoundStat = ({ title, body }: { title: string; body: string }) => (
  <StatValueRow>
    <CompoundStatTitle>{title}</CompoundStatTitle>
    <CompoundStatBody>{body}</CompoundStatBody>
  </StatValueRow>
)

const InternalEventStat = ({ event }: { event: InternalEventType }) => (
  <StatValueRow>
    <CompoundStatTitle>{event.eventStartTime}</CompoundStatTitle>
    <CompoundStatBody>
      <InternalEventCard event={event} />
      <TableLabelText>{event.guestCount} guests</TableLabelText>
    </CompoundStatBody>
  </StatValueRow>
)

const ReservationsStat = ({
  reservations,
}: {
  reservations: ReservationType[]
}) => {
  const { t } = useTranslation()
  const totalCovers = reservations.reduce(function(prev, current) {
    return prev + current.partySize
  }, 0)
  const largePartyReservations = reservations.filter(res => res.largeParty)
  return (
    <StatValue>
      {largePartyReservations.length > 0 && (
        <ReservationCard reservations={largePartyReservations} />
      )}
      {t('planning.reservations.totalCovers', {
        total: totalCovers,
      })}
    </StatValue>
  )
}

export const DailyViewStats = ({
  day,
  lastWeekSameDayBenchmark,
  lastMonthSameDayBenchmark,
  lastYearSameDayBenchmark,
}: Props) => {
  const { t } = useTranslation()
  const location = useCurrentLocation().location
  const lastWeek = asDollarsOrEmDash(lastWeekSameDayBenchmark)
  const lastMonth = asDollarsOrEmDash(lastMonthSameDayBenchmark)
  const lastYear = asDollarsOrEmDash(lastYearSameDayBenchmark)
  const salesType = location.salesType

  const dayStart = day.start
  const dayEnd = dayStart.endOf('day')

  const { orderSums } = useGetFutureOrderSums(dayStart, dayEnd)
  const { categories } = useCurrentLocationCategories()
  const weather = useWeatherDaily(dayStart, dayEnd)[0]
  const dailyExternalEvents = useDailyLocationEvents(dayStart, dayEnd, true)
  const dailyInternalEvents = useGetInternalEventDetails(dayStart, dayEnd)
  const dailyReservations = useGetReservationDetails(dayStart, dayEnd)

  const tripleSeatEnabled: boolean = location.tripleseatEnabled
  const reservationsEnabled: boolean =
    location.reservationsImporterType !== ReservationsImporter.NoImporter

  const cateringOrderSums = orderSums.filter(orderSum => {
    return (
      categories['Catering'] &&
      categories['Catering'].indexOf(orderSum.category) > -1
    )
  })

  return (
    <StatsContainer>
      <StatTableTitle>
        {t('planning.benchmarks')} {t('planning.sameDayOfWeek')}
      </StatTableTitle>
      <StatSection>
        <StatSubtitle>{t('planning.lastWeek')}</StatSubtitle>
        <StatValue data-testid="last-week-total">{lastWeek}</StatValue>

        <StatSubtitle>{t('planning.lastMonth')}</StatSubtitle>
        <StatValue data-testid="last-month-total">{lastMonth}</StatValue>

        <StatSubtitle>{t('planning.lastYear')}</StatSubtitle>
        <StatValue data-testid="last-year-total">{lastYear}</StatValue>
      </StatSection>
      <StatSection>
        <OrdersTotalTitle>{t(`planning.existingOrders`)}</OrdersTotalTitle>
        <OrdersTotal data-testid="existing-orders-total">
          {sumSalesAndFormat(orderSums, salesType)}
        </OrdersTotal>
        {'Catering' in categories && (
          <Fragment>
            <StatSubtitle>Catering</StatSubtitle>
            <StatValue data-testid={`existing-orders-catering`}>
              {sumSalesAndFormat(cateringOrderSums, salesType)}
            </StatValue>
          </Fragment>
        )}
      </StatSection>
      <StatSectionCol data-testid="weather">
        <StatTitle>{t('planning.weather')}</StatTitle>
        {weather ? (
          <WeatherDisplayRow weather={weather} textSize="regular" />
        ) : (
          <StatValue>{t('weather.unavailable')}</StatValue>
        )}
      </StatSectionCol>
      <StatSectionCol data-testid="events">
        <StatTitle>{t('planning.externalEvents')}</StatTitle>
        {dailyExternalEvents.length > 0 ? (
          dailyExternalEvents.map(({ timeOrHoliday, name }) => (
            <CompoundStat key={name} title={timeOrHoliday} body={name} />
          ))
        ) : (
          <StatValue>{t('events.unavailable')}</StatValue>
        )}
      </StatSectionCol>
      {tripleSeatEnabled && (
        <StatSectionCol data-testid="internalEvents">
          <StatTitle>Internal Events</StatTitle>
          {dailyInternalEvents.length ? (
            dailyInternalEvents.map(event => (
              <InternalEventStat key={event.externalId} event={event} />
            ))
          ) : (
            <OrdersTotal>{asDollarsOrEmDash(null)}</OrdersTotal>
          )}
        </StatSectionCol>
      )}
      {reservationsEnabled && (
        <StatSection data-testid="reservations">
          <StatTitle>{t('planning.reservations.rowTitle')}</StatTitle>
          {dailyReservations.length ? (
            <ReservationsStat reservations={dailyReservations} />
          ) : (
            <OrdersTotal>{asDollarsOrEmDash(null)}</OrdersTotal>
          )}
        </StatSection>
      )}
    </StatsContainer>
  )
}
