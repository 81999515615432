const subscribers: Function[] = []

export const subscribe = (handler: Function) => {
  subscribers.push(handler)
}

export const submitAppAlertMessage = (
  message: string,
  type: 'danger' | 'info' | 'success' = 'danger'
) => {
  if (message !== null && message?.length) {
    subscribers.forEach(h => {
      h(message, type)
    })
  }
}
