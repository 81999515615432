import styled from 'styled-components'

import {
  tableAnnotationTextStyle,
  largeBodyTextStyle,
  largeBodyBoldTextStyle,
} from 'config/styles'
import { Dot } from 'components/Dot'

export const TableContainer = styled.div({
  width: '100vw',
  overflow: 'scroll',
})

export const SubtitleLabel = styled.div({
  color: 'var(--blue1)',
})

export const FlexTable = styled.div({
  minWidth: '1400px',
})

export const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderTop: '1px solid var(--gray5)',
  padding: '1.75rem 40px',
  '&:hover': {
    backgroundColor: 'var(--gray7)',
  },
})

export const HeaderRow = styled.div({
  display: 'flex',
  fontWeight: 800,
  borderTop: '0',
  padding: '0.75rem 40px',
})

export const HistoryDividerRow = styled.div({
  fontWeight: 800,
  color: 'var(--gray4)',
  backgroundColor: 'var(--gray7)',
  borderTop: '1px solid var(--gray5)',
  padding: '0.75rem 20px',
})

export const Col = styled.div<{
  size?: number
  singleLabel?: boolean
  center?: boolean
  isPast?: boolean | null
}>(({ size, singleLabel, center, isPast }) => ({
  flex: size || 1,
  alignItems: 'flex-end',
  display: singleLabel ? 'flex' : 'block',
  textAlign: center ? 'center' : 'initial',
  color: isPast ? 'var(--gray3)' : 'var(--gray2)',
}))

export const StatusDot = styled(Dot)({
  marginRight: '8px',
})

export const HeaderContainer = styled.div({
  marginTop: '25px',
  marginBottom: '25px',
  paddingLeft: '40px',
  paddingRight: '40px',
})

export const SmallSpan = styled.span({
  fontSize: '10px',
  fontWeight: 800,
  maxWidth: '45px',
  color: 'var(--gray4)',
})

export const BigSpan = styled.span({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '20px',
  maxWidth: '45px',
})

export const LargeBodySpan = styled.span({
  ...largeBodyTextStyle,
})

export const LargeBodyBoldSpan = styled.span({
  ...largeBodyBoldTextStyle,
})

export const SmallBlueSpan = styled.div({
  display: 'block',
  color: 'var(--buzzy-blue)',
  fontWeight: 800,
  fontSize: '10px',
  letterSpacing: '-0.5px',
})

export const Label = styled.span({
  ...tableAnnotationTextStyle,
  display: 'block',
  color: 'var(--gray4)',
  textTransform: 'capitalize',
  fontSize: '12px',
  letterSpacing: '-0.5px',
})

export const LoadMoreContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 38,
  marginBottom: 55,
  minHeight: 60,
})
