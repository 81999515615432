import {
  DailyViewLegendDescription,
  DailyViewLegendText,
  DailyViewLegendTitle,
  StyledDailyViewLegend,
  StyledDailyViewMinimumLaborLegend,
  DailyViewMinimumLaborLegendText,
} from 'pages/plan/dailyViewModal/styles'
import { ViolationIcon } from 'components/ViolationIcon'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  chartHasShifts: boolean
  isUnderMinimumLaborBaseline: boolean
}

export const DailyViewLegend: FC<Props> = ({
  chartHasShifts,
  isUnderMinimumLaborBaseline,
}) => {
  const { t } = useTranslation()
  const titleKey = chartHasShifts
    ? 'planning.dailyViewModal.optimizingLaborTitle'
    : 'planning.dailyViewModal.noShiftsTodayTitle'
  const descriptionKey = chartHasShifts
    ? 'planning.dailyViewModal.optimizingLaborDescription'
    : 'planning.dailyViewModal.noShiftsTodayDescription'

  const minimumLaborDescription = t(
    'planning.dailyViewModal.minimumLaborDescription'
  )

  return (
    <>
      <StyledDailyViewLegend>
        <DailyViewLegendText>
          <DailyViewLegendTitle>{t(titleKey)}</DailyViewLegendTitle>
          <DailyViewLegendDescription>
            {t(descriptionKey)}
          </DailyViewLegendDescription>
        </DailyViewLegendText>
      </StyledDailyViewLegend>
      <StyledDailyViewMinimumLaborLegend enable={isUnderMinimumLaborBaseline}>
        <DailyViewMinimumLaborLegendText>
          <ViolationIcon size={17} />
          <DailyViewLegendDescription>
            {minimumLaborDescription}
          </DailyViewLegendDescription>
        </DailyViewMinimumLaborLegendText>
      </StyledDailyViewMinimumLaborLegend>
    </>
  )
}
