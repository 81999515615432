import React from 'react'
import styled from 'styled-components'

import { inputWithArrowStyle } from 'config/styles'
import { LargeBodyText } from 'components/Text'

const Button = styled.button(inputWithArrowStyle)

const Text = styled(LargeBodyText)({
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'var(--gray1)',
})

const Placeholder = styled(Text)({
  color: 'var(--gray3)',
})

interface Props {
  text: string | null
  placeholder: string
  onClick: () => void
}

export const DropdownButton = ({ text, placeholder, onClick }: Props) => (
  <Button
    className="form-control" // Inherit bootstrap base styles to mimic select
    onClick={e => {
      e.preventDefault()
      onClick()
    }}
  >
    {text ? <Text>{text}</Text> : <Placeholder>{placeholder}</Placeholder>}
  </Button>
)
