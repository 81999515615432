import React, { FC } from 'react'
import Popover from 'react-bootstrap/Popover'
import { useTranslation } from 'react-i18next'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { TableLabelBoldText } from 'components/Text'
import { ViolationIcon } from 'components/ViolationIcon'
import { ViolationFragment, ShiftFragment } from 'config/graphqlTypes'
import { getUniqueViolations } from 'utils/getUniqueViolations'
import { Violations } from 'pages/plan/scheduling/Violations'
import { ViolationPopoverTitle } from 'pages/plan/scheduling/styles'

interface Props {
  violations: ViolationFragment[]
  children: JSX.Element
  currentShift: ShiftFragment
  hidePopover?: boolean
}

export const ViolationPopover: FC<Props> = ({
  violations,
  children,
  currentShift,
  hidePopover,
}) => {
  const { t } = useTranslation()
  const popoverId = `violations-${currentShift.id}`
  const uniqueViolations = getUniqueViolations(violations)
  const currentShiftAsViolationShiftDetails = {
    endAt: currentShift.endAt,
    startAt: currentShift.startAt,
    locationId: currentShift.location.id,
    shiftId: currentShift.id,
  }
  return (
    <OverlayTrigger
      key={popoverId}
      overlay={
        <Popover id={popoverId} hidden={hidePopover}>
          <ViolationPopoverTitle>
            <ViolationIcon
              styles="margin-right: 5px; padding-bottom: 1px;"
              size={15}
              fontSize="10px"
            />
            <TableLabelBoldText>
              {t('schedule.violations.violationsCount', {
                count: uniqueViolations.length,
              })}
            </TableLabelBoldText>
          </ViolationPopoverTitle>
          <Popover.Content>
            <Violations
              employeeName={currentShift?.employee?.name}
              currentShift={currentShiftAsViolationShiftDetails}
              violations={uniqueViolations}
              date={currentShift.startAt}
            />
          </Popover.Content>
        </Popover>
      }
      placement="top"
      delay={{ show: 100, hide: 0 }}
    >
      {children}
    </OverlayTrigger>
  )
}
