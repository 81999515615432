import { DateTime, Interval } from 'luxon'

import {
  useCreateShiftMutation,
  useUpdateShiftMutation,
  ShiftFragment,
  ShiftRoleFragment,
  EmployeeFragment,
  PlanFragment,
} from 'config/graphqlTypes'
import {
  deserializeISOStrings,
  serializeISOStrings,
} from 'utils/dateSerialization'
import {
  getTimeFromDateTime,
  getUpdatedDateTimeFromTime,
} from 'components/TimeInput'
import {
  isRowEntityShiftRole,
  isRowEntityEmployee,
  RowEntity,
} from 'pages/plan/scheduling/RowEntity'
import { usePlanQueryVariables } from 'pages/plan/planQueryVariablesContext'
import { useResetAndRefetchAll, useUpdatePlanCache } from './useShiftMutations'

export type DraggedShiftFragment = Omit<
  ShiftFragment,
  'id' | 'hours' | 'displayHours'
>

const displayHoursForOptimisticResponse = (
  startAt: DateTime,
  endAt: DateTime
) => endAt.diff(startAt, 'hours').hours

const hoursForOptimisticResponse = (
  startAt: DateTime,
  endAt: DateTime,
  excludeFromLabor: boolean
) => (excludeFromLabor ? 0 : displayHoursForOptimisticResponse(startAt, endAt))

export const moveStartEndAtToDate = (
  date: DateTime,
  startEndAt: DateTime
): DateTime => {
  const time = getTimeFromDateTime(startEndAt)
  return getUpdatedDateTimeFromTime(date, time)
}

const shiftRoleFromRowEntity = (
  rowEntity: RowEntity
): ShiftRoleFragment | null => {
  if (isRowEntityShiftRole(rowEntity)) {
    return rowEntity
  } else {
    return null
  }
}

const employeeFromRowEntity = (
  rowEntity: RowEntity
): EmployeeFragment | null => {
  if (isRowEntityEmployee(rowEntity)) {
    return rowEntity
  } else {
    return null
  }
}

export const moveShift = (
  {
    startAt,
    endAt,
    shiftRole,
    employee,
    location,
    isUnavailability,
    shiftSwapRequest,
    shiftBreaks,
    notes,
    wage,
  }: DraggedShiftFragment,
  date: DateTime,
  rowEntity: RowEntity
): DraggedShiftFragment => {
  const newStartAt = moveStartEndAtToDate(date, startAt)
  const newEndAt = moveStartEndAtToDate(date, endAt)

  const isValidRange = Interval.fromDateTimes(newStartAt, newEndAt).isValid

  const newShiftRole = shiftRoleFromRowEntity(rowEntity) ?? shiftRole
  const newEmployee = employeeFromRowEntity(rowEntity) ?? employee
  return {
    location,
    isUnavailability,
    startAt: newStartAt,
    endAt: !isValidRange ? newEndAt.plus({ days: 1 }) : newEndAt,
    employee: newEmployee,
    shiftRole: newShiftRole,
    shiftSwapRequest: shiftSwapRequest,
    shiftBreaks,
    notes,
    wage,
  }
}

export const useCreateShift = (
  plan: PlanFragment,
  isScheduleByRole: boolean
) => {
  const [mutateCreateShift] = useCreateShiftMutation()
  const { locationId } = usePlanQueryVariables()
  const { updateCachedPlan } = useUpdatePlanCache()
  const { resetAndRefetchAll } = useResetAndRefetchAll(plan, isScheduleByRole)

  return async ({
    startAt,
    endAt,
    employee,
    shiftRole,
    location,
    isUnavailability,
    shiftSwapRequest,
    shiftBreaks,
    notes,
    wage,
  }: DraggedShiftFragment) => {
    const tempId = `${startAt}${endAt}${employee.id}${isUnavailability}${shiftRole?.id}`
    const excludeFromLabor =
      employee?.employeeLocations?.find(el => el.locationId === location.id)
        ?.excludeFromLabor ?? false
    const copyShiftBreaks = shiftBreaks?.map(br => br.durationMins) || []

    try {
      const res = await mutateCreateShift({
        variables: {
          input: {
            locationId,
            startAt,
            endAt,
            isUnavailability,
            employeeId: employee.id,
            shiftRoleId: shiftRole?.id || null,
            shiftBreaks: copyShiftBreaks,
            notes,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createShift: {
            id: tempId,
            __typename: 'Shift',
            startAt: startAt.toISO(),
            endAt: endAt.toISO(),
            displayHours: displayHoursForOptimisticResponse(startAt, endAt),
            hours: hoursForOptimisticResponse(startAt, endAt, excludeFromLabor),
            employee: serializeISOStrings(employee),
            shiftRole,
            location,
            isUnavailability,
            shiftSwapRequest,
            shiftBreaks,
            notes,
            wage,
          },
        },
      })

      const createShift = res?.data?.createShift
      const shift = createShift ? deserializeISOStrings(createShift) : null
      updateCachedPlan(shift, 'create', tempId, plan)
      if (createShift) await resetAndRefetchAll()
    } catch {
      window.alert('There was an error creating the shift. Please try again.')
    }
  }
}

export const useUpdateShift = (
  plan: PlanFragment,
  isScheduleByRole: boolean
) => {
  const [mutateUpdateShift] = useUpdateShiftMutation()
  const { updateCachedPlan } = useUpdatePlanCache()
  const { resetAndRefetchAll } = useResetAndRefetchAll(plan, isScheduleByRole)

  return async ({
    id,
    startAt,
    endAt,
    employee,
    shiftRole,
    location,
    isUnavailability,
    shiftSwapRequest,
    shiftBreaks,
    notes,
    wage,
  }: DraggedShiftFragment & { id: string }) => {
    const excludeFromLabor =
      employee.employeeLocations?.find(el => el.locationId === location.id)
        ?.excludeFromLabor || false
    const copyShiftBreaks = shiftBreaks?.map(br => br.durationMins) || []

    try {
      const res = await mutateUpdateShift({
        variables: {
          input: {
            id,
            startAt,
            endAt,
            isUnavailability,
            employeeId: employee.id,
            shiftRoleId: shiftRole?.id ?? null,
            shiftBreaks: copyShiftBreaks,
            notes,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateShift: {
            id,
            __typename: 'Shift',
            startAt: startAt.toISO(),
            endAt: endAt.toISO(),
            displayHours: displayHoursForOptimisticResponse(startAt, endAt),
            hours: hoursForOptimisticResponse(startAt, endAt, excludeFromLabor),
            employee: serializeISOStrings(employee),
            shiftRole,
            location,
            isUnavailability,
            shiftSwapRequest,
            shiftBreaks,
            notes,
            wage,
          },
        },
      })
      const updateShift = res?.data?.updateShift
      const shift = updateShift ? deserializeISOStrings(updateShift) : null
      updateCachedPlan(shift, 'update', undefined, plan)
      if (updateShift) await resetAndRefetchAll()
    } catch {
      window.alert('There was an error updating the shift. Please try again.')
    }
  }
}
