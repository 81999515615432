import { useGetDailyLaborHoursBreakDownLazyData } from '../../queries/useGetDailyLaborHoursBreakDown'
import { useGetHoursDateRangeBreakDownLazyData } from '../../queries/useGetHoursDateRangeBreakDown'
import { DateTime } from 'luxon'

export const useGetLaborHoursBreakDownData = (
  isWeekView: boolean,
  day: DateTime,
  startOfWeek: DateTime,
  locationId: string
) => {
  const endOfWeek = startOfWeek.plus({ days: 7 }).minus({ milliseconds: 1 })

  const hoursDateRangeBreakDownQuery = useGetHoursDateRangeBreakDownLazyData(
    locationId,
    startOfWeek.toString(),
    endOfWeek.toString()
  )

  const dailyLaborHoursBreakDownQuery = useGetDailyLaborHoursBreakDownLazyData(
    locationId,
    day.toString()
  )

  return isWeekView
    ? hoursDateRangeBreakDownQuery
    : dailyLaborHoursBreakDownQuery
}
