import styled from 'styled-components'
import { H3Text, LargeHeaderText } from 'components/Text'
import Button, { StyledButton } from 'components/Button'
import { EmployeeSearchInput } from 'pages/management/employees/EmployeeSearchInput'
import { Row, Badge } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import { sectionBorder } from '../../config/styles'
import Container from 'react-bootstrap/Container'

export const LeftCol = styled(Col)({
  backgroundColor: 'var(--gray7)',
  paddingLeft: '40px !important',
  '@media only screen and (min-width: 768px)': {
    minHeight: '100vh',
    borderRight: sectionBorder,
    paddingTop: 30,
    paddingBottom: 30,
  },
  '@media only screen and (max-width: 768px)': {
    borderBottom: sectionBorder,
    paddingTop: 20,
    paddingBottom: 20,
  },
})

export const RightCol = styled(Col)({
  paddingTop: 28,
  paddingBottom: 28,
  paddingLeft: '40px !important',
  paddingRight: '40px !important',
})

export const LeftColTitleText = styled(H3Text)({
  color: 'var(--gray4)',
  '@media only screen and (min-width: 768px)': {
    marginBottom: 30,
  },
  '@media only screen and (max-width: 767px)': {
    marginBottom: 25,
  },
  '@media only screen and (max-width: 1140px)': {
    fontSize: '1.1rem',
    fontWeight: 800,
  },
})

export const LeftColSubTitleText = styled(LeftColTitleText)({
  '@media only screen and (min-width: 768px)': {
    marginTop: 60,
  },
  '@media only screen and (max-width: 767px)': {
    marginTop: 25,
  },
})

export const StyledContainer = styled(Container)({
  paddingLeft: 0,
  paddingRight: 0,
})

export const ManagementHeader = styled(LargeHeaderText)({
  color: 'var(--gray2)',
})

export const ManagementSubheader = styled.span`
  color: var(--gray1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

export const ManagementHeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 4,
})

export const EmployeePosWarning = styled.p({
  marginTop: 10,
})

export const EditEmployeeButton = styled(Button)({
  maxWidth: '80%',
  minWidth: '80px',
})

export const DeleteEmployeeButton = styled(Button)({
  height: 'fit-content',
  minWidth: '120px',
  margin: 10,
})

export const AddNewEmployeeButton = styled(Button)({
  height: 'fit-content',
  minWidth: '120px',
  marginLeft: 15,
  margin: 10,
})

export const AddNewMessageButton = styled(Button)({
  height: 'fit-content',
  minWidth: '170px',
  marginLeft: 15,
  margin: 10,
})

export const AddMessageIcon = styled.img({
  marginRight: 5,
  width: 10,
})

export const MessageFormLabel = styled.div({
  fontWeight: '800',
  fontSize: '14px',
  paddingBottom: '5px',
})

export const EmployeesTableHeaderRow = styled(Row)({})

export const StyledTableHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'wrap',
  justifyContent: 'flex-end',
  '@media (max-width: 641px)': {
    flexDirection: 'column',
    alignItems: 'start',
  },
})

export const EmployeeHeaderContainer = styled(StyledTableHeader)({})

export const AvailabilityRequestsCounterContainer = styled.div({
  display: 'flex',
})

export const ManagementTextContainer = styled.div({
  marginRight: 5,
})

export const PendingRequestsCounterBadge = styled(Badge)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 20,
  width: 20,
  borderRadius: '100%',
})

export const PendingRequestsCounterWrapper = styled.span({
  color: 'white',
  fontSize: 10,
})

export const ManagementContainer = styled.div({
  paddingTop: 24,
})

export const MessagesStyledContainer = styled.div({
  paddingTop: 20,
  '& td': {
    verticalAlign: 'middle',
  },
})

export const DeleteButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export const FormWrapper = styled.div({
  maxWidth: 400,
})

export const ButtonsWrapper = styled.div({
  display: 'flex',
})

export const CancelButton = styled(StyledButton)({
  marginTop: '0.5rem',
  marginRight: 13,
})

export const MessageSearchInputWrapper = styled(EmployeeSearchInput)({
  borderRadius: 7,
  marginLeft: 15,
  margin: 10,
})

export const CenterTexVerticallyWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  minHeight: '65px',
})

export const MessageHour = styled.i({
  display: 'block',
  fontSize: '12px',
  lineHeight: '15px',
})

export const NoMessagesMessage = styled.div({
  padding: '30px',
  textAlign: 'center',
})

export const SearchInputWrapper = (input: any) =>
  styled(input)({
    borderRadius: 7,
    marginLeft: 15,
    margin: 10,
  })

export const AvailabilityInputWrapper = styled.div({
  minWidth: 120,
})

export const AvailabilityCheckWrapper = styled.div({
  marginLeft: 15,
  marginBottom: 15,
})

export const DayWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  justifyContent: 'space-between',
})

export const Divider = styled.div({
  color: 'var(--gray5)',
})

export const DailyLabel = styled.div({
  marginBottom: 5,
  letterSpacing: '-0.02em',
  fontSize: '0.75rem',
  lineHeight: '1.25',
  fontWeight: '700',
})

export const EmployeeInfoLabel = styled.p({
  fontWeight: 'bold',
  fontSize: 12,
  marginBottom: 0,
})

export const EmployeeLocationsTh = styled.th<{
  center: boolean
}>(({ center }) => ({
  paddingBottom: 2,
  fontSize: 12,
  textAlign: center ? 'center' : 'left',
  paddingLeft: center ? '0.75rem' : '0 !important',
}))

export const EmployeeLocationsTd = styled.td<{
  center: boolean
}>(({ center }) => ({
  textAlign: center ? 'center' : 'left',
}))

export const EmployeeLocationsThead = styled.thead({
  borderBottom: '1px solid var(--gray5)',
})

export const ManageTime = styled.p({
  fontSize: 12,
  width: 200,
  marginLeft: 10,
  img: {
    margin: 5,
  },
  cursor: 'pointer',
})

export const ManagementSectionDivider = styled.hr`
  background: var(--gray5);
  height: 0.01rem;
  width: 900px;
`
