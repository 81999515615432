import { useIntercom } from 'react-use-intercom'

import * as Analytics from 'config/analytics'
import { apolloLogOut } from 'config/apolloClient'
import { useLocalStorage } from './useLocalStorage'
import { LOGGED_IN_USER_KEY } from '../pages/Login'

export const useLogout = () => {
  const { hardShutdown: resetIntercom } = useIntercom()
  const { removeItem: removeStoredUser } = useLocalStorage(LOGGED_IN_USER_KEY)

  return async () => {
    Analytics.track('Logout', {})
    Analytics.reset()

    resetIntercom()
    removeStoredUser()

    await apolloLogOut()
  }
}
