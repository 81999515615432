import { Col, Row } from 'react-bootstrap'
import { SearchInputWrapper, StyledTableHeader } from '../styles'
import React, { FC, ReactNode } from 'react'
import { BootstrapTableProps, SearchProps } from 'react-bootstrap-table-next'
import { SettingsSearchInput } from './SettingsSearchInput'

type Props = {
  searchProps: SearchProps<BootstrapTableProps>
  children?: ReactNode
}

const SearchInput = SearchInputWrapper(SettingsSearchInput)

export const SettingsTableHeader: FC<Props> = ({ searchProps, children }) => {
  return (
    <Row>
      <Col xs={12} md={12}>
        <StyledTableHeader>
          {children}
          <SearchInput {...searchProps} delay={500} />
        </StyledTableHeader>
      </Col>
    </Row>
  )
}
