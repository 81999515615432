import { SelectDropdownOption } from 'components/SelectDropdown'
import { ExportScheduleOptionsSelect } from './ExportScheduleOptionsSelect'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type PageSizeType = 'letter' | 'legal'

export type ExportSchedulePageSizeOption = {
  value: PageSizeType
  label: string
}

interface Props {
  onSelectPageSize: (pageSize: ExportSchedulePageSizeOption) => void
}

export const ExportSchedulePageSizeSelect: FC<Props> = ({
  onSelectPageSize,
}) => {
  const { t } = useTranslation()

  const pageSizes = [
    {
      value: 'letter',
      label: t('planning.exportScheduleModal.exportOptions.pageSize.letter'),
    },
    {
      value: 'legal',
      label: t('planning.exportScheduleModal.exportOptions.pageSize.legal'),
    },
  ]

  const [selectedOption, setSelectedOption] = useState<SelectDropdownOption>(
    pageSizes[0]
  )

  return (
    <ExportScheduleOptionsSelect
      singleSelect
      label={t('planning.exportScheduleModal.exportOptions.pageSize.label')}
      onSelectOptions={option => {
        const newOption = option as ExportSchedulePageSizeOption
        if (newOption.value !== selectedOption.value) {
          onSelectPageSize(newOption)
          setSelectedOption(newOption)
        }
      }}
      options={pageSizes}
      testId={'exportSchedulePageSizeSelect'}
    />
  )
}
