import SelectDropdown, { SelectDropdownOption } from 'components/SelectDropdown'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import styled from 'styled-components'

interface Props {
  days: DateTime[]
  onChange: (days: string[]) => void
}

const DaySelectControl = ({ onChange, days }: Props) => {
  const { t } = useTranslation()

  const options: MultiValue<SelectDropdownOption> = days.map(value => {
    const label = t('misc.fullDaysOfWeek.' + value.weekdayLong.toLowerCase())
    return { value: value.toString(), label }
  })
  const selectAllLabel = t('planning.clearSchedule.allDaysLabel')
  const [value, setValue] = useState(options)

  const update = (values: MultiValue<SelectDropdownOption>) => {
    setValue(values)
    onChange(values.map(x => x.value))
  }

  return (
    <Container>
      <Label>{t('planning.clearSchedule.daySelectLabel')}</Label>
      <SelectDropdown
        isMulti
        allowSelectAll
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        options={options}
        onChange={update}
        value={value}
        controlStyles={controlStyles}
        menuStyles={menuStyles}
        optionStyles={optionStyles}
        selectAllOptionTagLabel={selectAllLabel}
        selectAllOptionLabel={selectAllLabel}
      />
    </Container>
  )
}

const FONT_SIZE = 14

const controlStyles = {
  borderRadius: 25,
  fontSize: FONT_SIZE,
  border: '1px solid var(--gray5)',
  borderColor: 'var(--gray5)',
  paddingLeft: 12,
  color: 'var(--gray3)',
  boxShadow: 'none',
  '&:hover': {
    border: '1px solid var(--gray5)',
  },
}

const menuStyles = {
  fontSize: FONT_SIZE,
}

const optionStyles = {
  fontSize: FONT_SIZE,
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  marginTop: '0px',
  marginBottom: '30px',
})

const Label = styled.div({
  fontSize: FONT_SIZE,
  fontWeight: 700,
  marginBottom: '10px',
})

export default DaySelectControl
