import React, { useState } from 'react'
import AuthenticatedPage from 'pages/AuthenticatedPage'
import { ManagementWrapper } from '../../ManagementWrapper'
import { ManagementHeader } from '../../styles'
import { LocationForm, SubmitInput } from './LocationForm'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import TabsSelect from 'components/TabsSelect'
import { Tab } from 'react-bootstrap'
import { useUpdateLocationMutation } from 'config/graphqlTypesRaw'
import { OperatingHours } from './OperatingHours'
import { useCurrentLocationId } from '../../../../queries/useCurrentLocation'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import {
  getHasEditLaborAllowancePermission,
  getHasUpdateSalesDayPartsPermission,
} from 'utils/permissions'
import styled from 'styled-components'
import {
  LocationFragment,
  SalesDayPartFragment,
  useDeleteSalesDayPartsMutation,
  useGetSalesDayPartsQuery,
  useUpsertSalesDayPartsMutation,
} from '../../../../config/graphqlTypes'
import { Loading } from '../../../../components/Loading'
import _ from 'lodash'

//HACK: this is a hack to get around startAt and endAt being DateTime in SalesDayPartFragment
export type SalesDayPartType = Omit<
  SalesDayPartFragment,
  'startAt' | 'endAt'
> & {
  startAt: string
  endAt: string
}

export const EditLocation = () => {
  const { t } = useTranslation()
  const locationId = useCurrentLocationId()
  const { locations, permissions } = useLoggedInUserOrRedirect()
  const [locationFormKey, setLocationFormKey] = useState(Date.now())
  const allowedToEdit = getHasEditLaborAllowancePermission(permissions)
  const hasSalesDayPartsPerm = getHasUpdateSalesDayPartsPermission(permissions)
  const {
    data: salesDayPartsData,
    loading: salesDayPartsLoading,
    refetch: refetchSalesDayParts,
  } = useGetSalesDayPartsQuery({
    variables: { locationId },
  })

  const [
    createSalesDayParts,
    { error: createSalesDayPartsError },
  ] = useUpsertSalesDayPartsMutation()

  const [
    deleteSalesDayParts,
    { error: deleteSalesDayPartsError },
  ] = useDeleteSalesDayPartsMutation()

  const updateLocationMutationInput = {
    locationId,
    laborPercentOfRevenue: null,
  }

  const [
    updateLocation,
    { data: locationData, error: updateLocationError },
  ] = useUpdateLocationMutation()

  const updatedLocation = locationData?.updateLocation.location

  const location = updatedLocation
    ? updatedLocation
    : locations?.find(loc => loc.id === locationId)

  const onSubmit = async (input: SubmitInput) => {
    const { laborPercentOfRevenue, salesDayParts } = input

    const partsToDelete = _.differenceBy(
      salesDayPartsData?.salesDayParts,
      (salesDayParts as unknown) as SalesDayPartFragment[],
      'id'
    )

    try {
      await updateLocation({
        variables: {
          input: {
            ...updateLocationMutationInput,
            laborPercentOfRevenue: laborPercentOfRevenue,
          },
        },
      })

      await createSalesDayParts({
        variables: {
          input: {
            locationId,
            salesDayParts: (salesDayParts as unknown) as SalesDayPartFragment[],
          },
        },
      })

      if (partsToDelete.length) {
        await deleteSalesDayParts({
          variables: {
            input: {
              ids: partsToDelete.map(part => part.id),
            },
          },
        })
      }

      const isAllSuccess =
        !updateLocationError &&
        !createSalesDayPartsError &&
        !deleteSalesDayPartsError

      if (isAllSuccess) {
        toast.success(t('management.settings.locations.updateLocation'), {
          toastId: 'success',
          position: toast.POSITION.BOTTOM_LEFT,
        })
        await refetchSalesDayParts()
        setLocationFormKey(Date.now())
      }
    } catch (error) {
      toast.error(t('management.settings.genericError'), {
        toastId: 'error',
        position: toast.POSITION.BOTTOM_LEFT,
      })
    }
  }

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeader>{location?.name}</ManagementHeader>

          <LocationsStyledContainer>
            <TabsSelect defaultActiveKey="location-details" className="mb-3">
              <Tab
                eventKey="location-details"
                title="General Information"
                mountOnEnter
              >
                {salesDayPartsLoading ? (
                  <Loading />
                ) : (
                  <LocationForm
                    key={locationFormKey}
                    allowedToEdit={allowedToEdit}
                    location={location as LocationFragment}
                    hasSalesDayPartsPerm={hasSalesDayPartsPerm}
                    salesDayParts={
                      ((salesDayPartsData?.salesDayParts as unknown) as SalesDayPartType[]) ??
                      []
                    }
                    onSubmit={onSubmit}
                    submitText={'Save'}
                  />
                )}
              </Tab>
              <Tab
                eventKey="operating-hours"
                title="Operating Hours"
                mountOnEnter
              >
                <OperatingHours />
              </Tab>
            </TabsSelect>
          </LocationsStyledContainer>
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}

const LocationsStyledContainer = styled.div`
  padding-top: 20px;
`
