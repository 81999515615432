import styled from 'styled-components'
import Button from 'components/Button'

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '780px',
  marginTop: 25,
})

export const BorderContainer = styled(Container)({
  borderStyle: 'solid',
  borderColor: 'var(--gray5)',
  borderRadius: '10px',
  borderWidth: '1px',
  padding: '20px',
})

export const Title = styled.div({
  fontSize: '16px',
  fontWeight: 700,
})

export const PageDescription = styled.div({
  color: 'var(--gray2)',
  fontSize: '14px',
  fontWeight: 500,
  marginTop: 10,
})

export const Description = styled.div({
  width: '372px',
  color: 'var(--gray3)',
  fontSize: '14px',
  fontWeight: 400,
  marginTop: 15,
})

export const LastUpdated = styled.div({
  color: 'var(--gray3)',
  fontSize: '14px',
  fontWeight: 400,
  fontStyle: 'italic',
  marginTop: 10,
})

export const Body = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const ControlsContainer = styled.div({
  width: '160px',
})

export const Divider = styled.hr({
  background: 'var(--gray5)',
  height: '0.01rem',
  width: '730px',
})

export const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginTop: 20,
})

export const FormButton = styled(Button)({
  fontSize: '14px',
  fontWeight: 800,
  marginRight: 20,
  marginTop: 8,
  width: 188,
  height: 41,
})
