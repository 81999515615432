import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiPill } from 'components/MultiPill'
import {
  BreakSelectRow,
  LabelWithDescription,
  LabelDescription,
} from './styles'
import { Col, Row } from 'react-bootstrap'
import { Label } from '../../../components/Form'

interface ShiftBreakOption {
  duration: number
  selected: boolean
  title: string
}

interface Props {
  shiftBreaks: number[] | undefined
  setShiftBreaks: (breaks: number[]) => void
}

export const ShiftBreakOptions: FC<Props> = ({
  shiftBreaks,
  setShiftBreaks,
}) => {
  const { t } = useTranslation()
  const defaultShiftBreakOptions = [
    {
      duration: 0,
      title: t('schedule.shiftModal.breakDurationNone'),
    },
    {
      duration: 20,
      title: t('schedule.shiftModal.breakDurationMin', { count: 20 }),
    },
    {
      duration: 30,
      title: t('schedule.shiftModal.breakDurationMin', { count: 30 }),
    },
    {
      duration: 45,
      title: t('schedule.shiftModal.breakDurationMin', { count: 45 }),
    },
    {
      duration: 60,
      title: t('schedule.shiftModal.breakDurationHour', { count: 1 }),
    },
  ].map((option, i) => ({ ...option, selected: i === 0 }))

  const [shiftBreakOptions, setShiftBreak] = useState<ShiftBreakOption[]>(
    defaultShiftBreakOptions
  )

  useEffect(() => {
    setShiftBreak(
      defaultShiftBreakOptions.map((option: ShiftBreakOption, i: number) => ({
        ...option,
        selected: shiftBreaks?.length
          ? shiftBreaks.indexOf(option.duration) > -1
          : i === 0,
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftBreaks])

  const selectBreakOption = (index: number) => {
    setShiftBreaks([shiftBreakOptions[index].duration])
  }

  return (
    <Row>
      <Col>
        <LabelWithDescription>
          <Label label={t('schedule.shiftModal.shiftBreak')} />
          <LabelDescription>
            {t('schedule.shiftModal.shiftBreakDescription')}
          </LabelDescription>
        </LabelWithDescription>
      </Col>
      <Col xs="12">
        <BreakSelectRow>
          <MultiPill
            options={shiftBreakOptions.map((o: any) => o.title)}
            activeIndex={shiftBreakOptions.findIndex((o: any) => o.selected)}
            testIds={shiftBreakOptions.map(
              (o: any) => `shift-break-${o.duration}`
            )}
            onChange={selectBreakOption}
          />
        </BreakSelectRow>
      </Col>
    </Row>
  )
}
