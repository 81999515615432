import React from 'react'
import { Interval } from 'luxon'
import { round, filter } from 'lodash'

import {
  ShiftRoleFragment,
  ShiftFragment,
  IntervalPredictionSumFragment,
  SalesAndLaborSummaryFragment,
  ShiftRoleCategoryFragment,
  ActiveLocationShiftRoleFragment,
} from 'config/graphqlTypes'
import { useDailyTimeline } from 'pages/plan/dailyViewModal/useDailyTimeline'
import { VerticalLines } from 'pages/plan/dailyViewModal/schedule/VerticalLines'
import { xAxisScale } from 'pages/plan/dailyViewModal/DailyViewChart'
import {
  ScheduleWrapper,
  DividerRowPlaceholder,
} from 'pages/plan/dailyViewModal/schedule/styles'
import { DailyTimelineRow } from 'pages/plan/dailyViewModal/DailyTimelineRow'
import { BottomChartLine } from 'pages/plan/dailyViewModal/styles'
import { getGraphTimelineHelpers } from 'pages/plan/dailyViewModal/schedule/getGraphTimelineHelpers'
import { DayTotalHours } from 'pages/plan/dailyViewModal/schedule/DayTotalHours'
import { DailyViewCollapsableCategory } from 'pages/plan/dailyViewModal/schedule/DailyViewCollapsableCategory'
import {
  makeShiftRoleCategoryBuckets,
  shiftRoleMatchesCategory,
} from 'pages/plan/shiftRoleCategoryHelpers'
import { DailyViewAllShiftsDisplay } from './DailyViewAllShiftsDisplay'

interface Props {
  groupByRoles: boolean
  day: Interval
  days: Interval[]
  shiftRoles: ShiftRoleFragment[]
  predictionsForDay: IntervalPredictionSumFragment[]
  shifts: ShiftFragment[]
  dailySalesAndLaborSummary: SalesAndLaborSummaryFragment
  timelineWidth: number
  isEditable: boolean
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export const Schedule = ({
  day,
  days,
  predictionsForDay,
  groupByRoles,
  shifts,
  shiftRoles: allShiftRoles,
  dailySalesAndLaborSummary,
  timelineWidth,
  isEditable,
  activeLocationShiftRoles,
}: Props) => {
  const graphTimeline = useDailyTimeline(shifts, day)

  const timelineHelpers = getGraphTimelineHelpers(graphTimeline, timelineWidth)

  const RenderedVerticalLines = (
    <VerticalLines
      graphTimeline={graphTimeline}
      xAxisScale={xAxisScale}
      getPercentFromLeft={timelineHelpers.getPercentFromLeft}
    />
  )

  const hours = dailySalesAndLaborSummary.preScheduledLabor
  const optimalLaborHours =
    dailySalesAndLaborSummary.optimalLaborForPlannedSales
  const diff = round(
    dailySalesAndLaborSummary.optimalLaborForPlannedSales - hours,
    1
  )

  const rolesForCategory = (shiftRoleCategory: ShiftRoleCategoryFragment) =>
    filter(
      allShiftRoles,
      shiftRole =>
        shiftRoleMatchesCategory(shiftRole, shiftRoleCategory) &&
        shifts.some(shift => shiftRole.id === shift.shiftRole?.id)
    )

  const roleCategoriesToRoles = makeShiftRoleCategoryBuckets(
    allShiftRoles,
    rolesForCategory
  )

  return (
    <ScheduleWrapper>
      {!groupByRoles ? (
        <DailyViewAllShiftsDisplay
          days={days}
          day={day}
          shiftRoles={allShiftRoles}
          shifts={shifts}
          VerticalLines={RenderedVerticalLines}
          timelineHelpers={timelineHelpers}
          isEditable={isEditable}
          activeLocationShiftRoles={activeLocationShiftRoles}
        />
      ) : (
        roleCategoriesToRoles.map(
          ({ shiftRoleCategory, categoryMembers: shiftRoles }) => (
            <DailyViewCollapsableCategory
              days={days}
              day={day}
              shiftRoleCategory={shiftRoleCategory}
              shiftRoles={shiftRoles}
              shifts={shifts}
              VerticalLines={RenderedVerticalLines}
              timelineHelpers={timelineHelpers}
              isEditable={isEditable}
              key={shiftRoleCategory.name}
              activeLocationShiftRoles={activeLocationShiftRoles}
            />
          )
        )
      )}
      <DailyTimelineRow
        RightSection={<DividerRowPlaceholder />}
        MiddleSection={RenderedVerticalLines}
        BottomSection={<BottomChartLine />}
      />
      <DailyTimelineRow
        RightSection={
          <DayTotalHours
            hours={hours}
            optimalHours={optimalLaborHours}
            diff={diff}
            isRoleTotal
          />
        }
      />
    </ScheduleWrapper>
  )
}
