import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import positiveAlertIcon from 'assets/images/positive-alert-icon.svg'
import {
  useUpsertInsightMutation,
  InsightFragment,
  Reason,
  WillHappenAgain,
  GetInsightDocument,
  AffectedChannel,
} from 'config/graphqlTypes'
import * as Analytics from 'config/analytics'
import { asDollars } from 'utils/currency'
import { YesNoToggle } from 'pages/tracking/insight/YesNoToggle'
import { Details } from 'pages/tracking/insight/Details'
import { serializeISOStrings } from 'utils/dateSerialization'
import {
  EditorBox,
  Header,
  Icon,
  Title,
  BodyText,
  WasUnusual,
  SubmitFeedback,
  Disclosure,
} from 'pages/tracking/insight/styles'

export const calcCanSubmit = ({
  wasUnusual,
  reasons,
  channelsAffected,
  other,
  willHappenAgain,
}: {
  wasUnusual: boolean | null
  reasons: Reason[] | null
  channelsAffected: AffectedChannel[] | null
  other: string | null
  willHappenAgain: WillHappenAgain | null
}) => {
  if (wasUnusual === null) {
    return false
  }

  if (wasUnusual === false) {
    return true
  }

  if (!reasons?.length) {
    return false
  }

  if (!channelsAffected?.length) {
    return false
  }

  if (willHappenAgain === null) {
    return false
  }

  if (reasons.includes(Reason.Other) && !other) {
    return false
  }

  return true
}

interface InsightEditorProps {
  date: DateTime
  forecastDifference: number
  insight: InsightFragment | null
  locationId: string
  onSubmit: () => void
}

export const InsightEditor: FC<InsightEditorProps> = ({
  date,
  forecastDifference,
  locationId,
  insight,
  onSubmit,
}) => {
  const [wasUnusual, setWasUnusual] = useState<boolean | null>(
    insight?.wasUnusual ?? null
  )
  const [reasons, setReasons] = useState<Reason[] | null>(
    insight?.reasons ?? null
  )
  const [channelsAffected, setChannelsAffected] = useState<
    AffectedChannel[] | null
  >(insight?.affectedChannels ?? null)
  const [other, setOther] = useState<string | null>(
    insight?.otherReasons ?? null
  )
  const [
    willHappenAgain,
    setWillHappenAgain,
  ] = useState<WillHappenAgain | null>(insight?.willHappenAgain ?? null)

  const [upsertInsightMutation] = useUpsertInsightMutation({
    variables: {
      input: {
        locationId,
        date,
        otherReasons: other,
        wasUnusual: wasUnusual!,
        willHappenAgain: willHappenAgain,
        reasons: reasons,
        affectedChannels: channelsAffected,
      },
    },
    update: (cache, result) => {
      const insight = result?.data?.upsertInsight?.insight

      if (insight) {
        cache.writeQuery({
          query: GetInsightDocument,
          data: serializeISOStrings({ insight }),
          variables: serializeISOStrings({ date, locationId }),
        })
      }
    },
  })

  const onChangeWasUnusual = (value: boolean | null) => {
    if (value !== null) {
      setReasons(null)
      setChannelsAffected(null)
      setOther(null)
      setWillHappenAgain(null)
    }

    setWasUnusual(value)
  }

  const onSaveInsight = async () => {
    Analytics.track('Submit Insight', { date, locationId })
    await upsertInsightMutation()
    onSubmit()
  }

  const amount = asDollars(Math.abs(forecastDifference))
  const { t } = useTranslation()
  const alert = t('tracking.insight.alert', { amount })

  const showDetails = wasUnusual === true
  const showSubmit = wasUnusual !== null
  const canSubmit = calcCanSubmit({
    wasUnusual,
    reasons,
    channelsAffected,
    other,
    willHappenAgain,
  })

  return (
    <EditorBox blue>
      <Header>
        <Icon src={positiveAlertIcon} />
        <Title>{alert}</Title>
        <BodyText>{t('tracking.insight.unusualPrompt')}</BodyText>
      </Header>
      <WasUnusual>
        <YesNoToggle value={wasUnusual} onChange={onChangeWasUnusual} />
      </WasUnusual>
      {showDetails && (
        <Details
          reasons={reasons}
          onReasonsChange={setReasons}
          other={other}
          onOtherChange={setOther}
          channelsAffected={channelsAffected}
          onChannelsAffectedChange={setChannelsAffected}
          willHappenAgain={willHappenAgain}
          onWillHappenAgainChange={setWillHappenAgain}
        />
      )}
      {showSubmit && (
        <SubmitFeedback onClick={onSaveInsight} disabled={!canSubmit} size="sm">
          {t('tracking.insight.submitFeedback')}
        </SubmitFeedback>
      )}
      <Disclosure>{t('tracking.insight.disclosure')}</Disclosure>
    </EditorBox>
  )
}
