import React, { FC } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button, { TextButton } from 'components/Button'
import StyledModalTitle from 'components/StyledModalTitle'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'

interface Props {
  show: boolean
  onHide: () => void
  title: string
  warning?: string | JSX.Element
  description?: string | JSX.Element
  confirmLabel: string
  onConfirm?: () => Promise<void>
  children?: JSX.Element
  showSpinner?: boolean
  cancelText?: string
  waitOnPromise?: boolean
  hideCancel?: boolean
  alternativeAction?: JSX.Element
  buttonVariant?: string
  bodyTextAlign?: 'left' | 'center'
}

const ConfirmationDialog: FC<Props> = ({
  show,
  onHide,
  title,
  warning,
  description,
  confirmLabel,
  onConfirm,
  cancelText,
  children,
  showSpinner = false,
  waitOnPromise = true,
  hideCancel = false,
  alternativeAction = false,
  buttonVariant = 'blue',
  bodyTextAlign = 'left',
}) => {
  const { t } = useTranslation()

  return (
    <Modal data-testid="submit-modal" show={show} onHide={onHide} centered>
      <Modal.Header closeButton style={{ border: 'none' }}>
        <StyledModalTitle>{title}</StyledModalTitle>
      </Modal.Header>
      {warning}
      <Modal.Body
        style={{
          paddingLeft: '65px',
          paddingRight: '65px',
          whiteSpace: 'pre-wrap',
          textAlign: bodyTextAlign,
        }}
      >
        {description}
      </Modal.Body>
      <Modal.Footer
        style={{
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {children}
        {onConfirm && (
          <Button
            style={{ minWidth: '220px', marginTop: '15px' }}
            size="md"
            variant={buttonVariant}
            onClick={onConfirm}
            data-testid="confirm-submit-modal"
            waitOnPromise={waitOnPromise}
          >
            <Spinner animation="border" size="sm" hidden={!showSpinner} />{' '}
            {confirmLabel}
          </Button>
        )}
        {alternativeAction}
        {!hideCancel && (
          <TextButton
            style={{ fontWeight: 500, color: 'var(--gray3)' }}
            onClick={onHide}
          >
            {cancelText ?? t('buttons.cancel')}
          </TextButton>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationDialog
