import { useGetViolationsPlanQuery } from 'config/graphqlTypes'
import { useCurrentLocation } from 'queries/useCurrentLocation'

export const useGetViolationsPlan = (planId: string) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()
  const { data, loading, refetch } = useGetViolationsPlanQuery({
    variables: {
      locationId,
      planId,
    },
  })

  return {
    data: data?.violationsPlan ?? [],
    loading,
    refetch,
  }
}
