import React, { useState } from 'react'
import AuthenticatedPage from 'pages/AuthenticatedPage'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Loading } from 'components/Loading'
import { ManagementWrapper } from '../ManagementWrapper'
import {
  ManagementContainer,
  ManagementHeader,
  EmployeePosWarning,
} from '../styles'
import { EmployeeForm, SubmitInput } from './EmployeeForm'
import { EmployeeAvailabilityForm } from './EmployeeAvailabilityForm'
import {
  useGetEmployeeForEditQuery,
  useUpdateEmployeeLocationsMutation,
  useUpdateEmployeeMutation,
} from 'config/graphqlTypes'
import { UpdateEmployeeMutationInput } from 'config/graphqlTypesRaw'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import Tab from 'react-bootstrap/Tab'
import TabsSelect from '../../../components/TabsSelect'
import { useFeatureToggles } from '../../../contexts/FeatureToggles'
import { useQueryParams } from 'utils/useQueryParams'
import { EmployeeAutoScheduleForm } from './EmployeeAutoScheduleForm'

interface Params {
  id: string
}

export const EditEmployee = () => {
  const id = useParams<Params>().id
  const { t } = useTranslation()
  const toggles = useFeatureToggles()
  const [selectedTab, setSelectedTab] = useState<string | null>(
    'employee-details'
  )
  const { loading, data, refetch } = useGetEmployeeForEditQuery({
    variables: { id },
  })

  const manageEmployeeInfoToggle = toggles.features.find(
    t => t.name === 'manageEmployeeInfo'
  )

  const minMaxHoursToggle = toggles.features.find(t => t.name === 'minMaxHours')

  const employee = data?.employee

  const currentPage = parseInt(useQueryParams().get('currentPage') || '1')

  const locationId = useCurrentLocationId()

  const [updateEmployee] = useUpdateEmployeeMutation()
  const [updateEmployeeLocation] = useUpdateEmployeeLocationsMutation()

  if (loading || !employee) {
    return <Loading />
  }

  const employeeLocation = employee?.employeeLocations?.find(
    el => el.locationId === locationId
  )

  const activeEmployeeLocations = employee?.employeeLocations?.filter(
    employeeLocation => employeeLocation.active
  )

  const activeLocationIds = activeEmployeeLocations?.map(
    employeeLocation => employeeLocation.locationId
  )

  const activeLocations = employee?.locations?.filter(location =>
    activeLocationIds?.includes(location.id)
  )

  const autoSchedulingEnabled =
    activeLocations?.some(location => location.autoSchedulingEnabled) || false

  const onSubmit = async (input: SubmitInput) => {
    const { name, email, phone, wage, employeeLocations } = input
    const fullInput: UpdateEmployeeMutationInput = {
      name,
      email,
      phone,
      wage,
      id: employee.id,

      // These are optional parameters to the mutation. It would be great and logical if we could just omit them.
      // But, for some reason we're using the 'avoidOptionals: true' option to the apollo type generator. This
      // causes all attributes of the mutation input type to be only nullable, but not optional. Thus, we need
      // to supply them here. Imo, we should change this option, but it breaks things in like 30 places.
      language: null,
      availabilityRequestNotifications: null,
      scheduleNotifications: null,
      shiftSwapNotifications: null,
      timeZone: null,
    }

    let errors = []

    const updateEmployeeResults = await updateEmployee({
      variables: { input: fullInput },
    })

    errors = updateEmployeeResults.data?.updateEmployee?.errors || []

    if (!manageEmployeeInfoToggle?.active) return errors

    if (employeeLocations.length) {
      const result = await updateEmployeeLocation({
        variables: {
          input: {
            employeeId: employee.id,
            employeeLocations: employeeLocations,
          },
        },
      })
      errors = [
        ...errors,
        ...(result.data?.updateEmployeeLocation.errors || []),
      ]
    }

    await refetch()
    return errors
  }

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeader>
            {t('settings.employeeSettings.employeeForm.edit')}
          </ManagementHeader>
          {manageEmployeeInfoToggle?.active ? (
            <>
              <EmployeePosWarning>
                {selectedTab === 'employee-details'
                  ? t('settings.employeeSettings.employeeForm.editPos')
                  : t('management.availability.description')}
              </EmployeePosWarning>
              <ManagementContainer>
                <TabsSelect
                  defaultActiveKey="employee-details"
                  className="mb-3"
                  onSelectTabKey={(tabKey: string | null) =>
                    setSelectedTab(tabKey)
                  }
                >
                  <Tab eventKey="employee-details" title="Details">
                    <EmployeeForm
                      employee={employee}
                      employeeLocation={employeeLocation}
                      submitForm={onSubmit}
                      submitText={t(
                        'settings.employeeSettings.employeeForm.update'
                      )}
                      currentPage={currentPage}
                    />
                  </Tab>
                  <Tab eventKey="availability" title="Availability">
                    <EmployeeAvailabilityForm
                      employee={employee}
                      submitText={t(
                        'settings.employeeSettings.employeeForm.update'
                      )}
                      currentPage={currentPage}
                    />
                  </Tab>
                  {autoSchedulingEnabled && minMaxHoursToggle?.active && (
                    <Tab eventKey="auto-schedule" title="Auto-schedule">
                      <EmployeeAutoScheduleForm
                        employee={employee}
                      ></EmployeeAutoScheduleForm>
                    </Tab>
                  )}
                </TabsSelect>
              </ManagementContainer>
            </>
          ) : (
            <EmployeeForm
              employee={employee}
              employeeLocation={employeeLocation}
              submitForm={onSubmit}
              submitText={t('settings.employeeSettings.employeeForm.update')}
              currentPage={currentPage}
            />
          )}
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}
