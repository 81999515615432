import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CustomLayerProps } from 'components/Chart/CustomLayer'
import { smallBodyBoldTextStyle } from 'config/styles'

const labelOffset = 7
const lineBreakOffset = 10

const Text = styled.text<{ light?: boolean }>(({ light }) => ({
  ...smallBodyBoldTextStyle,
  fill: light ? 'var(--teal-light)' : 'var(--teal)',
}))

const Line = styled.line({
  stroke: 'var(--gray5)',
})

const LaborLine = styled.line<{ light?: boolean }>(({ light }) => ({
  stroke: light ? 'var(--teal-light)' : 'var(--teal)',
}))

export const HorizontalGrid = (
  actualLaborInDollars: number,
  actualLaborHours: number,
  targetLaborInDollars: number,
  targetLaborHours: number,
  prefix: string
) =>
  function DefaultHorizontalGrid({
    axisLeft,
    gridYValues,
    height,
    margin,
    width,
    yScale,
  }: CustomLayerProps) {
    const { t } = useTranslation()

    const availableHeight = height - (margin?.top || 0) - (margin?.bottom || 0)

    const marginLeft = margin?.left || 0
    const marginRight = margin?.right || 0
    const end = width - marginLeft - marginRight

    const yTickCount = gridYValues as number

    const yTicks = Array.from(
      Array(yTickCount + 1),
      (_value, i) => i * (availableHeight / yTickCount)
    )

    const hasActualLabor = actualLaborInDollars !== 0
    const actualLine = yScale(actualLaborInDollars)

    const hasTargetLabor = targetLaborInDollars !== 0
    const targetLine = yScale(targetLaborInDollars)

    const areLinesSimilar = Math.abs(actualLine - targetLine) <= 30

    const textPos = (lineY: number) => {
      if (areLinesSimilar) {
        const min = Math.min(actualLine, targetLine)
        const max = Math.max(actualLine, targetLine)
        return min === lineY ? min - labelOffset * 2 : max + labelOffset * 1.5
      } else {
        return lineY - labelOffset * 2
      }
    }

    const padding = axisLeft?.tickPadding || 0

    return (
      <g>
        {yTicks.map((yTick, i) => {
          const isAxis = i === yTicks.length - 1

          if (
            (hasActualLabor && yTick === actualLine) ||
            (hasTargetLabor && yTick === targetLine)
          ) {
            return null
          }

          return (
            <Line
              key={i}
              strokeWidth={isAxis ? 2 : 1}
              x1={isAxis ? -marginLeft : -padding}
              x2={isAxis ? end + marginRight : end}
              y1={yTick}
              y2={yTick}
            />
          )
        })}
        {hasActualLabor && (
          <>
            <LaborLine
              x1={-padding}
              x2={end + marginRight}
              y1={actualLine}
              y2={actualLine}
            />
            <Text
              textAnchor="end"
              x={end + marginRight}
              y={textPos(actualLine)}
            >
              {t(`charts.${prefix}.actualLineLabel`)}
              <tspan dy={lineBreakOffset} x={end + marginRight}>
                {t('charts.labelHours', {
                  count: Math.round(actualLaborHours),
                })}
              </tspan>
            </Text>
          </>
        )}
        {hasTargetLabor && (
          <>
            <LaborLine
              light
              x1={-padding}
              x2={end + marginRight}
              y1={targetLine}
              y2={targetLine}
            />
            <Text
              light
              textAnchor="end"
              x={end + marginRight}
              y={textPos(targetLine)}
            >
              {t(`charts.${prefix}.targetLineLabel`)}
              <tspan dy={lineBreakOffset} x={end + marginRight}>
                {t('charts.labelHours', {
                  count: Math.round(targetLaborHours),
                })}
              </tspan>
            </Text>
          </>
        )}
      </g>
    )
  }
