import React, { FC } from 'react'
import { flowMax, addDisplayName, addProps } from 'ad-hok'

import {
  StyledScheduleDailyTotalBarTrack,
  StyledScheduleDailyTotalBarBorderBar,
  StyledScheduleDailyTotalBarInnerBar,
} from 'pages/plan/scheduling/styles'

interface Props {
  percent: number
  isOver: boolean
}

export const ScheduleDailyTotalBar: FC<Props> = flowMax(
  addDisplayName('ScheduleDailyTotalBar'),
  addProps(({ percent }) => ({
    barPercent:
      percent > 0 && percent < 8
        ? 8
        : percent < 100 && percent > 95
        ? 95
        : percent,
  })),
  addProps(({ barPercent }) => ({
    shouldShowBar: barPercent > 0,
  })),
  ({ isOver, barPercent, shouldShowBar }) => (
    <StyledScheduleDailyTotalBarTrack isOver={isOver}>
      {shouldShowBar && (
        <StyledScheduleDailyTotalBarBorderBar
          isOver={isOver}
          percent={barPercent}
          data-testid={`labor-allowance-${barPercent}`}
        >
          {isOver && <StyledScheduleDailyTotalBarInnerBar />}
        </StyledScheduleDailyTotalBarBorderBar>
      )}
    </StyledScheduleDailyTotalBarTrack>
  )
)
