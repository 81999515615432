import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { Banner } from './Banner'
import statusIconCheck from 'assets/images/status-icon-check.svg'
import statusIconCross from 'assets/images/status-icon-cross.svg'
import statusIconExclamation from 'assets/images/status-icon-exclamation.svg'
import { Colors } from './styles'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useIsClosedForDate } from '../useGetOperatingHoursTracking'

const AFFORDANCE = 0.05

interface ComparisonBannerProps {
  comparisonValue: number | null
  targetValue: number | null
  prefix: string
  submittedAt?: DateTime | null
}

export const ComparisonBanner = ({
  comparisonValue,
  targetValue,
  prefix,
  submittedAt,
}: ComparisonBannerProps) => {
  const { t } = useTranslation()
  const namespace = `tracking.banners.${prefix}.`

  const [comp, targ] = [comparisonValue || 0, targetValue || 0]
  const isHigherThanTarget = comp > targ * (1 + AFFORDANCE)
  const isLowerThanTarget = comp < targ * (1 - AFFORDANCE)
  const isSimilarToTarget = !isLowerThanTarget && !isHigherThanTarget

  const currentTime = useCurrentLocation().times.localTime
  const today = currentTime.startOf('day')
  const yesterday = today.minus({ days: 1 })
  const closedToday = useIsClosedForDate(today)
  const closedYesterday = useIsClosedForDate(yesterday)

  const config = (() => {
    if (closedToday && prefix !== 'previous')
      return {
        text: t(`${namespace}closedToday`),
        icon: statusIconExclamation,
        bgColor: Colors.gray,
      }
    if (closedYesterday && prefix !== 'current')
      return {
        text: t(`${namespace}closedYesterday`),
        icon: statusIconExclamation,
        bgColor: Colors.gray,
      }
    if (!submittedAt)
      return {
        text: t(`${namespace}noPlan`),
        icon: statusIconExclamation,
        bgColor: Colors.gray,
      }
    if (isSimilarToTarget)
      return {
        text: t(`${namespace}similar`),
        icon: statusIconCheck,
        bgColor: Colors.blue,
      }
    if (isHigherThanTarget)
      return {
        text: t(`${namespace}higher`),
        icon: statusIconExclamation,
        bgColor: Colors.red,
      }
    if (isLowerThanTarget && prefix === 'current')
      return {
        text: t(`${namespace}lower`),
        icon: statusIconExclamation,
        bgColor: Colors.red,
      }
    if (isLowerThanTarget && prefix === 'previous')
      return {
        text: t(`${namespace}lower`),
        icon: statusIconCheck,
        bgColor: Colors.blue,
      }
    return {
      text: t(`${namespace}missingData`),
      icon: statusIconCross,
      bgColor: Colors.red,
    }
  })()

  return <Banner {...config} />
}
