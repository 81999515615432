import React, { createContext, FC, ReactNode, useContext } from 'react'
import { useLoggedInUser } from '../queries/useLoggedInUser'
import { Loading } from '../components/Loading'
import { Features } from '../config/graphqlTypesRaw'
import { useGetFeatureTogglesQuery } from '../config/graphqlTypes'

type ContextProps = {
  features: Features['data'] | []
}

type Props = {
  children: ReactNode
}

const FeatureToggles = createContext<ContextProps>({ features: [] })

export const FeatureTogglesProvider: FC<Props> = ({ children }) => {
  const user = useLoggedInUser()
  const { data, loading } = useGetFeatureTogglesQuery({
    skip: !user,
    variables: {
      actorId: user?.organization.id!,
      actorType: 'Organization',
    },
  })

  return (
    <FeatureToggles.Provider value={{ features: data?.features?.data ?? [] }}>
      {loading ? <Loading /> : children}
    </FeatureToggles.Provider>
  )
}

export const useFeatureToggles = () => useContext(FeatureToggles)

export const useFeatureToggleEnabled = (name: string) => {
  const { features } = useFeatureToggles()
  return features.some(f => f.name === name && f.active)
}
