import { DateTime, Interval } from 'luxon'

import { IntervalPredictionSumFragment } from 'config/graphqlTypes'

const emptyPredictionThreshold = 100
export const getPredictionsForDay = (
  predictions: IntervalPredictionSumFragment[],
  openOrDefault: DateTime,
  closeOrDefault: DateTime,
  salesType: string
) => {
  const businessDay = Interval.fromDateTimes(
    openOrDefault,
    closeOrDefault.plus({ second: 1 })
  )

  const filteredPredictions = predictions.filter(
    ({ reportedSales, startAt }) =>
      businessDay.contains(startAt) ||
      Number(reportedSales) > emptyPredictionThreshold
  )

  return filteredPredictions
}
