import React, { FC } from 'react'
import { ViolationIcon } from 'components/ViolationIcon'
import {
  ShiftSwapMessageContainer,
  ShiftSwapMesage,
  ShiftSwapMessageHeader,
  ShiftSwapMessageText,
  ShiftSwapMessageLink,
} from './styles'
import {
  routeMap,
  useLocationNavigation,
  getLocationNavigationPath,
} from 'pages/Routes'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { useTranslation } from 'react-i18next'

interface Props {}

export const ShiftSwapInProgressMessage: FC<Props> = () => {
  const { locations } = useLoggedInUserOrRedirect()
  const { locationPath: currentLocationPath } = useLocationNavigation()
  const currentLocationId = useCurrentLocationId()
  const hasCurrentLocation = !!currentLocationId
  const resolvedLocationPath = hasCurrentLocation
    ? currentLocationPath
    : getLocationNavigationPath(locations[0]?.id)
  const { t } = useTranslation()

  return (
    <ShiftSwapMessageContainer>
      <ViolationIcon />
      <ShiftSwapMesage>
        <ShiftSwapMessageHeader>
          {t('schedule.shiftSwapInProgress.header')}
        </ShiftSwapMessageHeader>
        <ShiftSwapMessageText>
          {t('schedule.shiftSwapInProgress.message')}{' '}
          <ShiftSwapMessageLink
            to={resolvedLocationPath + routeMap.location.management.requests}
          >
            {t('schedule.shiftSwapInProgress.options')}
          </ShiftSwapMessageLink>{' '}
          {t('schedule.shiftSwapInProgress.link')}
        </ShiftSwapMessageText>
      </ShiftSwapMesage>
    </ShiftSwapMessageContainer>
  )
}
