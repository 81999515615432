import { CurrentUserInfoFragment } from 'config/graphqlTypes'
import { navigateToLogin } from 'pages/Routes'
import { useLocalStorage } from '../utils/useLocalStorage'
import { LOGGED_IN_USER_KEY } from '../pages/Login'

export const useLoggedInUser = () => {
  const { getItem: getStoredUser } = useLocalStorage<CurrentUserInfoFragment>(
    LOGGED_IN_USER_KEY
  )

  return getStoredUser()
}

export const useLoggedInUserOrRedirect = () => {
  const user = useLoggedInUser()

  if (!user) {
    navigateToLogin()
  }

  return user!
}
