import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button'
import { LoadMoreContainer } from 'pages/plans/styles'
import { LoadingImage } from 'components/Loading'

interface Props {
  onLoadMore: () => any
  isLoadingMore: boolean
}

export const LoadMorePlans = ({ onLoadMore, isLoadingMore }: Props) => {
  const { t } = useTranslation()

  return (
    <LoadMoreContainer>
      <div>
        {isLoadingMore ? (
          <LoadingImage />
        ) : (
          <Button variant="secondary" onClick={onLoadMore}>
            {t('plans.loadMore')}
          </Button>
        )}
      </div>
    </LoadMoreContainer>
  )
}
