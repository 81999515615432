import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { isEmailValidLength, isEmailValidFormat } from 'utils/validations'

const MIN_NAME_LENGTH = 1

export const useEmployeeValidations = () => {
  const { t } = useTranslation()

  const calculateNameErrors = (name: string) => {
    if (name.length < MIN_NAME_LENGTH) {
      return t('settings.employeeSettings.employeeForm.required')
    }
    return ''
  }

  const calculateEmailErrors = (email: string) => {
    if (!isEmailValidLength(email)) {
      return t('settings.employeeSettings.employeeForm.required')
    }
    if (!isEmailValidFormat(email)) {
      return t('settings.employeeSettings.employeeForm.invalidEmail')
    }
    return ''
  }

  const calculateWageErrors = (wage: string) => {
    if (isNaN(Number.parseInt(wage)) || Number.parseInt(wage) <= 0) {
      return t('settings.employeeSettings.employeeForm.invalidWage')
    }

    return ''
  }

  const calculatePhoneErrors = (phone: string) => {
    if (!phone || isValidPhoneNumber(phone)) {
      return ''
    }
    return t('settings.employeeSettings.employeeForm.invalidPhone')
  }

  return {
    calculateNameErrors,
    calculateEmailErrors,
    calculatePhoneErrors,
    calculateWageErrors,
  }
}
