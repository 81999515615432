import { formattedNumberWithFallback } from './formattedNumberWithFallback'
import { orEmDash } from 'utils/formattedNumberWithFallback'

const formatOptions = (showCents: boolean) => ({
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: showCents ? 2 : 0,
  maximumFractionDigits: showCents ? 2 : 0,
})

export const formattedDollars = (dollars: number, showCents: boolean = false) =>
  new Intl.NumberFormat('en-US', formatOptions(showCents)).format(dollars)

export const asDollars = (cents: number | null, showCents: boolean = false) =>
  formattedNumberWithFallback(cents, num =>
    formattedDollars(num / 100, showCents)
  )

export const asDollarsOrEmDash = (
  cents: number | null,
  showCents: boolean = false
) => orEmDash(cents, num => formattedDollars(num / 100, showCents))
