import React, { FC } from 'react'

import {
  TopNeighborhoodBusinessesWrapper,
  TopBusinessesHeader,
  TopBusinessesList,
  TopBusinessListing,
  TopBusinessListingName,
} from 'pages/covid/dashboard/addressRow/styles'

const maxCountToDisplay = 15

interface Props {
  title: string
  businessNames?: string[]
  dashboardSaved: boolean
}

export const TopNeighborhoodBusinesses: FC<Props> = ({
  title,
  businessNames,
  dashboardSaved,
}) => (
  <>
    {businessNames && businessNames.length > 0 && (
      <TopNeighborhoodBusinessesWrapper>
        <TopBusinessesHeader>{title}</TopBusinessesHeader>
        <TopBusinessesList blur={!dashboardSaved}>
          {businessNames.slice(0, maxCountToDisplay).map(name => (
            <TopBusinessListing key={name}>
              <TopBusinessListingName>{name}</TopBusinessListingName>
            </TopBusinessListing>
          ))}
        </TopBusinessesList>
      </TopNeighborhoodBusinessesWrapper>
    )}
  </>
)
