import React, { useState } from 'react'
import { map } from 'lodash'
import { Collapse } from 'react-bootstrap'
import { ForecastRow } from 'queries/useForecastedItems'

import {
  TableRow,
  ItemCell,
  CategoryCell,
  CategoryTitleCell,
  ItemTitleCell,
  ItemTotalCell,
  CategoryTotalCell,
  UpChevron,
  DownChevron,
} from 'pages/items/styles'

interface Props {
  categorySummary: ForecastRow
  items: { [item: string]: ForecastRow }
  formatter: (x: number) => string
}

interface ItemRowProps {
  name: string
  values: number[]
  total: number
  formatter: (x: number) => string
}

const ItemRow = ({ name, values, total, formatter }: ItemRowProps) => {
  return (
    <TableRow>
      <ItemTitleCell>{name}</ItemTitleCell>
      {map(values, (value, key) => (
        <ItemCell key={`${key}-${value}`}>{formatter(value)}</ItemCell>
      ))}
      <ItemTotalCell>{formatter(total)}</ItemTotalCell>
    </TableRow>
  )
}

interface AccordionButtonProps {
  title: string
  onClick: () => void
  isExpanded: boolean
}

const AccordionButton = ({
  title,
  onClick,
  isExpanded,
}: AccordionButtonProps) => {
  return (
    <CategoryTitleCell onClick={onClick}>
      {isExpanded ? <UpChevron /> : <DownChevron />}
      {title}
    </CategoryTitleCell>
  )
}

export const ItemCategoryAccordion = ({
  categorySummary,
  items,
  formatter,
}: Props) => {
  const [isToggle, setToggle] = useState(false)
  const onToggle = () => setToggle(!isToggle)
  const itemsSorted = Object.values(items).sort(
    (rowA, rowB) => rowB.total - rowA.total
  )

  return (
    <>
      <TableRow onClick={onToggle}>
        <AccordionButton
          title={categorySummary.name}
          onClick={onToggle}
          isExpanded={isToggle}
        />
        {map(categorySummary.values, (value, key) => (
          <CategoryCell key={`${key}-${value}`} color="var(--gray2)">
            {formatter(value)}
          </CategoryCell>
        ))}
        <CategoryTotalCell color="var(--gray2)">
          {formatter(categorySummary.total)}
        </CategoryTotalCell>
      </TableRow>
      <Collapse in={isToggle}>
        <>
          {isToggle ? (
            map(itemsSorted, (item_data, key) => (
              <ItemRow
                key={`${item_data.name}-${key}`}
                name={item_data.name}
                values={item_data.values}
                total={item_data.total}
                formatter={formatter}
              />
            ))
          ) : (
            <tr style={{ display: isToggle ? 'contents' : 'none' }}></tr>
          )}
        </>
      </Collapse>
    </>
  )
}
