import styled from 'styled-components'

import { smallBodyBoldTextStyle } from 'config/styles'

export const FlexCenter = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})

export const StyledPrediction = styled(FlexCenter)({
  maxWidth: 150,
})

export const DateAndResetWrapper = styled.div({
  marginBottom: 5,
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
})

export const ResetButton = styled.img<{ renderRightSide?: boolean }>(
  ({ renderRightSide }) => ({
    position: 'absolute',
    right: renderRightSide ? -25 : 0,
    bottom: renderRightSide ? -35 : 1,
    cursor: 'pointer',

    '@media print': {
      display: 'none',
    },
  })
)

export const PredictionDateText = styled.p<{
  lastRow?: boolean
  highlight?: boolean
}>(({ lastRow, highlight }) => ({
  color: highlight ? 'var(--blue)' : lastRow ? 'var(--gray1)' : 'var(--gray3)',
  fontWeight: 800,
  fontSize: 10,
  marginBottom: 0,
}))

export const PredictionInputWrapper = styled.div<{
  highlight: boolean
  noBorder?: boolean
  disabled?: boolean
}>(({ highlight, noBorder, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: disabled ? '' : '1px solid var(--gray5)',
  minWidth: 120,
  width: '100%',
  ...(highlight
    ? {
        backgroundColor: 'var(--blue-transparent)',
        borderColor: 'var(--blue)',
        color: 'var(--blue)',
      }
    : {}),
  ...(noBorder
    ? {
        border: 'none',
        backgroundColor: 'transparent',
      }
    : {}),

  '@media print': {
    width: 100,
    border: 'none',
    background: 'none',
  },
}))

export const basePredictionValueStyle = {
  color: 'inherit',
  fontSize: 24,
  '@media print': {
    fontSize: 16,
  },
  fontWeight: 800,
  lineHeight: 1.5,
}

export const StyledForecastInput = styled.input.attrs({ type: 'text' })({
  ...basePredictionValueStyle,
  border: 'transparent',
  background: 'transparent',
  padding: '0.3rem',
  textAlign: 'center',

  '&:focus': {
    outline: 'none',
  },
})

export const PredictionDifferenceText = styled.p({
  color: 'var(--blue)',
  marginTop: '0.5rem',
  textAlign: 'center',
  opacity: 0.75,
  minHeight: 12,
  ...smallBodyBoldTextStyle,
  fontSize: 9,
})
