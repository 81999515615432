import React, { useRef } from 'react'
import { useState, ChangeEvent, FC } from 'react'
import { isInteger } from 'lodash'
import { DateTime } from 'luxon'

import { formattedDollars } from 'utils/currency'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import {
  useUpsertForecastMutation,
  PlanFragment,
  PlanFragmentDoc,
  SalesAndLaborSummaryFragment,
} from 'config/graphqlTypes'
import * as Analytics from 'config/analytics'
import { serializeISOStrings } from 'utils/dateSerialization'
import { StyledForecastInput } from 'pages/plan/Prediction/styles'
import { usePlanPageContext } from '../planPageContext'

export const updatePlanAfterChangeInForecast = (
  cache: any,
  date: DateTime,
  plan: PlanFragment,
  dailySalesAndLaborSummaries: SalesAndLaborSummaryFragment[],
  updater: (
    previous: SalesAndLaborSummaryFragment
  ) => SalesAndLaborSummaryFragment
) => {
  const updatedDailySalesAndLaborSummaries = dailySalesAndLaborSummaries.map(
    s => (s.startOn.toISODate() === date.toISODate() ? updater(s) : s)
  )

  const cacheArgs = {
    fragment: PlanFragmentDoc,
    fragmentName: 'Plan',
    id: `Plan:${plan.id}`,
  }

  const updatedPlan = {
    ...plan,
    lastUpdatedAt: DateTime.local().toISO(),
    dailySalesAndLaborSummaries: updatedDailySalesAndLaborSummaries,
  }

  return cache.writeFragment({
    ...cacheArgs,
    data: serializeISOStrings(updatedPlan),
  })
}

interface ForecastInputProps {
  value: number
  predicted: number
  date: DateTime
  deleteForecast: () => void
  disable: boolean
}

export const ForecastInput: FC<ForecastInputProps> = ({
  value,
  predicted,
  date,
  deleteForecast,
  disable,
}) => {
  const valueInDollars = Math.round(value / 100)
  const predictedInDollars = Math.round(predicted / 100)

  const locationId = useCurrentLocationId()
  const { plan } = usePlanPageContext()
  const { dailySalesAndLaborSummaries } = plan

  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const [forecastValue, setForecastValue] = useState<number | null>(
    valueInDollars
  )

  const totalInCents = Math.round((forecastValue || 0) * 100)

  const [upsertForecastMutation] = useUpsertForecastMutation({
    variables: {
      input: {
        locationId,
        date,
        totalSales: totalInCents,
      },
    },
    update: (cache, { data }) => {
      if (!data) {
        return
      }

      const {
        forecast: { laborHours, totalSales },
      } = data.upsertForecast

      return updatePlanAfterChangeInForecast(
        cache,
        date,
        plan,
        dailySalesAndLaborSummaries,
        previousDaySalesAndLaborSummary => ({
          ...previousDaySalesAndLaborSummary,
          optimalLaborForPlannedSales: laborHours,
          plannedSales: totalSales,
        })
      )
    },
  })

  const onSubmit = () => {
    if (forecastValue === valueInDollars) {
      return
    }

    Analytics.track('Update Forecast', {
      forecastValue,
      predictedInDollars,
    })

    if (forecastValue === null) {
      deleteForecast()
    } else if (forecastValue === predictedInDollars) {
      deleteForecast()
    } else if (forecastValue !== predictedInDollars) {
      upsertForecastMutation()
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsed = parseInt(e.target.value.replace(/\$|,/g, ''), 10)
    setForecastValue(isInteger(parsed) ? parsed : null)
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toUpperCase() === 'ENTER') {
      onSubmit()
    }
  }

  const onFocus = () => {
    const end = inputRef.current?.value.length || 0
    inputRef.current?.setSelectionRange(end, end)
    setIsFocused(true)
  }

  const onBlur = () => {
    onSubmit()
    setIsFocused(false)
  }

  const displayedValue = (() => {
    if (isFocused && forecastValue === null) {
      return '$'
    }
    if (forecastValue === null) {
      return formattedDollars(predictedInDollars)
    }
    return formattedDollars(forecastValue)
  })()

  return (
    <StyledForecastInput
      disabled={disable}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      ref={inputRef}
      value={displayedValue}
      size={displayedValue.length}
      data-testid={'forecast-input'}
    />
  )
}
