import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledWestfieldLogo, LargeLogo } from 'pages/covid/styles'
import westfieldLogo from 'assets/images/westfield-logo-reversed.png'

interface Props {}

export const WestfieldLogo: FC<Props> = () => {
  const { t } = useTranslation()

  return (
    <StyledWestfieldLogo href="https://www.lineup.ai/" target="_blank">
      <LargeLogo src={westfieldLogo} alt={t('navbar.westfieldLogoAlt')} />
    </StyledWestfieldLogo>
  )
}
