import { groupBy } from 'lodash'
import { isNotOverSixDaysViolation } from './getViolationDetails'
import { ViolationFragment } from 'config/graphqlTypes'

export const getUniqueViolations = (violations: ViolationFragment[]) =>
  Object.values(groupBy(violations, v => v.name))
    .map(similarViolations => ({
      ...similarViolations[0],
      shifts: similarViolations.map(v => v.shifts).flat(),
    }))
    .filter(isNotOverSixDaysViolation)
