import React, { useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'

import AuthenticatedPage from 'pages/AuthenticatedPage'

import { H2Text } from 'components/Text'
import { ItemsTable } from './ItemsTable'
import { Pill } from 'components/Pill'

import {
  TableContainer,
  HeaderContainer,
  TableControls,
  ControlsContainer,
  ViewByLabel,
  SpinnerStyled,
  UpdatedAt,
} from 'pages/items/styles'
import { DatePicker } from 'pages/items/DatePicker'

import { ForecastedItemsValueBy } from 'config/graphqlTypesRaw'
import {
  useForecastedItemsCategories,
  useItemForecastLastUpdated,
} from 'queries/useForecastedItems'
import { DateTime, Interval } from 'luxon'
import { useFiscalWeekFinder } from 'utils/useGetWeekFromDate'

const lastImportedDaysAgo = (
  importedAt: DateTime,
  t: (path: string, obj: any) => string
) => {
  const daysAgo = Math.floor(Math.abs(importedAt.diffNow('day').days))
  const day = t('items.daysAgo', {
    postProcess: 'interval',
    count: daysAgo,
  })
  const time = importedAt.toLocaleString(DateTime.TIME_SIMPLE)

  return t('items.lastUpdated', { day, time })
}

export const Items = () => {
  const { t } = useTranslation()
  const findFiscalWeek = useFiscalWeekFinder()
  const defaultFiscalWeek = findFiscalWeek(DateTime.fromJSDate(new Date()))
  const [fiscalWeek, setFiscalWeek] = useState<Interval>(defaultFiscalWeek)

  const [valueBy, setValueBy] = useState<ForecastedItemsValueBy>(
    ForecastedItemsValueBy.Items
  )
  const [isPending, startTransition] = useTransition()
  const options = [t('items.viewBy.dollars'), t('items.viewBy.items')]
  const activeIndex = valueBy === ForecastedItemsValueBy.Dollars ? 0 : 1
  const onChange = (newIndex: number) => {
    const newValue: ForecastedItemsValueBy =
      newIndex === 0
        ? ForecastedItemsValueBy.Dollars
        : ForecastedItemsValueBy.Items
    startTransition(() => setValueBy(newValue))
  }
  const forecastedCategories = useForecastedItemsCategories(fiscalWeek, valueBy)
  const lastUpdated = useItemForecastLastUpdated(fiscalWeek, valueBy)
  const daysAgo = lastUpdated ? lastImportedDaysAgo(lastUpdated, t) : ''

  return (
    <AuthenticatedPage>
      <div>
        <HeaderContainer>
          <H2Text>{t('items.menuItemsForecast')}</H2Text>
          <UpdatedAt>{daysAgo}</UpdatedAt>
        </HeaderContainer>
        <TableContainer>
          <TableControls>
            <ControlsContainer>
              <ViewByLabel>{t('items.viewBy.title')}</ViewByLabel>
              <Pill
                options={options}
                activeIndex={activeIndex}
                onChange={onChange}
              />
              <SpinnerStyled
                animation="border"
                role="status"
                hidden={!isPending}
                size="sm"
              />
            </ControlsContainer>
            <ControlsContainer>
              <DatePicker onChange={setFiscalWeek} />
            </ControlsContainer>
          </TableControls>
          {forecastedCategories && (
            <ItemsTable
              valueBy={valueBy}
              fiscalWeek={fiscalWeek}
              total={forecastedCategories.total}
              categories={forecastedCategories.categories}
            />
          )}
        </TableContainer>
      </div>
    </AuthenticatedPage>
  )
}
