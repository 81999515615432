import React, { useLayoutEffect, useState } from 'react'
import AuthenticatedPage from 'pages/AuthenticatedPage'
import { useTranslation } from 'react-i18next'
import { ManagementWrapper } from '../../ManagementWrapper'
import { ManagementHeader } from '../../styles'
import { OrganizationForm, SubmitInput } from './OrganizationForm'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import TabsSelect from 'components/TabsSelect'
import { Tab } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { useUpdateOrganizationMutation } from 'config/graphqlTypes'
import { UpdateOrganizationMutationInput } from 'config/graphqlTypesRaw'
import { getHasManageOrganizationsPermission } from 'utils/permissions'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import TimeOffBlockedDatesDataTable from './timeOffBlockedDate/TimeOffBlockedDatesDataTable'
import { useFeatureToggleEnabled } from '../../../../contexts/FeatureToggles'
import UsersDataTable from './UsersDataTable'
import { useLocationNavigation } from '../../../Routes'

interface Params {
  organization_id: string
}

export const EditOrganization = () => {
  const { organization_id: organizationId } = useParams<Params>()
  const history = useHistory<{ isBlockedDatesTabActive: boolean }>()
  const { location } = history
  const isBlockedDatesTabActive = location?.state?.isBlockedDatesTabActive
  const { t } = useTranslation()
  const { locationPath } = useLocationNavigation()
  const blockedDatesToggleEnabled = useFeatureToggleEnabled(
    'timeOffBlockedDates'
  )
  const closedDaysToggleEnabled = useFeatureToggleEnabled(
    'capturingLocationClosedDays'
  )
  const [activeTabKey, setActiveTabKey] = useState('organizationsKey')

  useLayoutEffect(() => {
    if (isBlockedDatesTabActive) {
      setActiveTabKey('timeOffBlockedDatesKey')
      history.replace({
        pathname: `${locationPath}/management/organizations/${organizationId}`,
        state: { isBlockedDatesTabActive: false },
      })
    }
  }, [history, isBlockedDatesTabActive, locationPath, organizationId])

  let {
    organization,
    authorizedOrganizations,
    permissions,
  } = useLoggedInUserOrRedirect()
  organization =
    authorizedOrganizations.find(org => org.id === organizationId) ||
    organization
  const allowedToEdit = getHasManageOrganizationsPermission(permissions)
  const [updateOrganization, { error }] = useUpdateOrganizationMutation({
    variables: {
      input: {
        id: organizationId,
        laborPercentOfRevenue: organization?.laborPercentOfRevenue,
        overtimeHoursEmailOn: organization?.overtimeHoursEmailOn,
        overtimeHoursThreshold: organization?.overtimeHoursThreshold,
      },
    },
  })

  const handleSelectTab = (key: string | null) => {
    key && setActiveTabKey(key)
  }

  const onSubmit = async (input: SubmitInput) => {
    const {
      laborPercentOfRevenue,
      overtimeHoursEmailOn,
      overtimeHoursThreshold,
    } = input
    const fullInput: UpdateOrganizationMutationInput = {
      laborPercentOfRevenue,
      overtimeHoursEmailOn,
      overtimeHoursThreshold,
      id: organization.id,
    }

    try {
      await updateOrganization({
        variables: { input: fullInput },
      })

      !error &&
        toast.success(
          t(
            'management.settings.organizations.edit.toastMessages.updateOrganization'
          ),
          {
            toastId: 'success',
            position: toast.POSITION.BOTTOM_LEFT,
          }
        )
    } catch (e) {
      toast.error(t('management.settings.genericError'), {
        toastId: 'error',
        position: toast.POSITION.BOTTOM_LEFT,
      })
    }
  }

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeader>{organization.name}</ManagementHeader>

          <OrganizationsStyledContainer>
            <TabsSelect
              activeKey={activeTabKey}
              onSelectTabKey={handleSelectTab}
              className="mb-3"
            >
              <Tab
                eventKey="organizationsKey"
                title={t('management.settings.organizations.generalInfo.title')}
              >
                <OrganizationForm
                  allowedToEdit={allowedToEdit}
                  organization={organization}
                  onSubmit={onSubmit}
                  submitText={'Save'}
                />
              </Tab>
              <Tab
                eventKey="usersKey"
                title={t('management.settings.organizations.users.title')}
                mountOnEnter
              >
                <OrganizationsStyledContainer>
                  <UsersDataTable organizationId={organizationId} />
                </OrganizationsStyledContainer>
              </Tab>
              {blockedDatesToggleEnabled && (
                <Tab
                  eventKey="timeOffBlockedDatesKey"
                  title={
                    closedDaysToggleEnabled
                      ? t(
                          'management.settings.organizations.blockedDates.title'
                        )
                      : 'Blocked Dates'
                  }
                  mountOnEnter
                >
                  <OrganizationsStyledContainer>
                    <TimeOffBlockedDatesDataTable />
                  </OrganizationsStyledContainer>
                </Tab>
              )}
            </TabsSelect>
          </OrganizationsStyledContainer>
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}

const OrganizationsStyledContainer = styled.div`
  padding-top: 20px;
`
