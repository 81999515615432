import { DateTime, Interval } from 'luxon'
import { convertTo24HourFormat } from './timeFormats'

export interface TimeSlot {
  id: string
  startAt: string
  endAt: string
}

export const daysFromTimeZone = (
  timeZone: string,
  fiscalOffset: number = 0
) => {
  const localTime = DateTime.local().setZone(timeZone)
  const today = localTime.toISODate()
  const startOfToday = localTime.startOf('day')
  const endOfToday = localTime.endOf('day')
  const startOfYesterday = startOfToday.minus({ days: 1 })
  const endOfYesterday = endOfToday.minus({ days: 1 })
  const yesterday = startOfYesterday.toISODate()
  const startOfThisWeek = localTime
    .startOf('week')
    .plus({ days: fiscalOffset })
    .startOf('day')
  const endOfThisWeek = localTime
    .endOf('week')
    .plus({ days: fiscalOffset })
    .endOf('day')
  const startOfLastWeek = startOfThisWeek.minus({ weeks: 1 })
  const endOfLastWeek = endOfThisWeek.minus({ weeks: 1 })
  const startOfNextWeek = startOfThisWeek.plus({ weeks: 1 })
  const endOfNextWeek = endOfThisWeek.plus({ weeks: 1 })

  return {
    localTime,
    today,
    startOfToday,
    endOfToday,
    startOfYesterday,
    endOfYesterday,
    yesterday,
    startOfThisWeek,
    endOfThisWeek,
    startOfLastWeek,
    endOfLastWeek,
    startOfNextWeek,
    endOfNextWeek,
  }
}

export const isSameDate = (date1: DateTime, date2: DateTime) =>
  date1.hasSame(date2, 'year') &&
  date1.hasSame(date2, 'month') &&
  date1.hasSame(date2, 'day')

export const isAllDay = (startAt: DateTime, endAt: DateTime) =>
  startAt.hour === 0 && endAt.hour === 23

export const formatDayOfWeek = (date: DateTime) =>
  date.toFormat('ccc LLL d').toUpperCase()

export const formatLongFormDayOfWeek = (date: DateTime) =>
  date.toFormat('cccc, LLLL d')

export const simpleDateFormat = (date: DateTime) => date.toFormat('M/d/yy')

export const simpleTimeFormat = (date: DateTime) => date.toFormat('hh:mm a')

export const simpleDateTimeFormat = (date: DateTime) =>
  date.toFormat('M/d/yy hh:mm a')

export const overlappingTimeSlot = (timeSlots: TimeSlot[]): TimeSlot | null => {
  let overlappingTimeSlot = null

  const convertToDateTime = (time: string) =>
    DateTime.fromISO(convertTo24HourFormat(time))

  const isValidRange = (start: DateTime, end: DateTime) =>
    Interval.fromDateTimes(start, end).isValid

  const overlap = () =>
    timeSlots.some((timeSlot, i) => {
      const startTime = convertToDateTime(timeSlot.startAt)
      const endTime = convertToDateTime(timeSlot.endAt)

      const currentInterval = Interval.fromDateTimes(
        startTime,
        isValidRange(startTime, endTime) ? endTime : endTime.plus({ day: 1 })
      )

      for (let j = i + 1; j < timeSlots.length; j++) {
        const nextStartTime = convertToDateTime(timeSlots[j].startAt)
        const nextEndTime = convertToDateTime(timeSlots[j].endAt)

        const nextInterval = Interval.fromDateTimes(
          nextStartTime,
          isValidRange(nextStartTime, nextEndTime)
            ? nextEndTime
            : nextEndTime.plus({ day: 1 })
        )

        if (currentInterval.overlaps(nextInterval)) {
          overlappingTimeSlot = timeSlots[j]
          return true
        }
      }

      return false
    })

  return overlap() ? overlappingTimeSlot : null
}
