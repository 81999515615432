import React from 'react'
import Modal from 'react-bootstrap/Modal'
import DrawerModal from '../../../../../components/DrawerModal'
import { LocationUsers } from './LocationUsers'
import { LocationFragment } from '../../../../../config/graphqlTypes'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface Props {
  show: boolean
  toggleShow: () => void
  children: LocationFragment | null
}

export const LocationUsersModal = ({ show, toggleShow, children }: Props) => {
  const { t } = useTranslation()

  return (
    <DrawerModal show={show} onHide={toggleShow}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Title>
            {t('management.settings.locations.locationUsers.modal.title')}
          </Title>
          <Subtitle>{children?.name}</Subtitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children && <LocationUsers location={children} />}
      </Modal.Body>
    </DrawerModal>
  )
}

const Title = styled.span`
  display: block;
`

const Subtitle = styled.span`
  display: block;
  color: var(--gray4);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
