import React from 'react'

import { TopPanelMonthMetric, TopPanelWeekMetric } from 'pages/covid/types'
import { SummaryInteger } from 'pages/covid/dashboard/addressRow/SummaryInteger'
import { LocationChart } from 'pages/covid/dashboard/addressRow/LocationChart'
import { BenchmarkData } from 'pages/covid/dashboard/addressRow/BenchmarkData'
import { AddressCol } from 'pages/covid/dashboard/addressRow/AddressCol'
import { GraphSubtitle } from 'pages/covid/dashboard/addressRow/GraphSubtitle'
import {
  BenchmarkWrapper,
  BenchmarkDivider,
} from 'pages/covid/dashboard/addressRow/styles'
import { useIsColumnLayout } from 'pages/covid/dashboard/useIsColumnLayout'

interface Props {
  isExpanded: boolean
  alwaysShowTimeframes?: boolean
  metric: TopPanelMonthMetric | TopPanelWeekMetric
  translationKey: string
  header: JSX.Element
  dashboardSaved: boolean
}

export const LocationChartColumn = ({
  isExpanded,
  alwaysShowTimeframes,
  metric,
  translationKey,
  header,
  dashboardSaved,
}: Props) => {
  const { sentiment, last_year, CY2019 } = metric
  let timeframe: 'month' | 'week'
  let thisPeriod: number
  let lastPeriod: number

  if ('this_month' in metric) {
    timeframe = 'month'
    const mm = metric as TopPanelMonthMetric
    thisPeriod = mm.this_month
    lastPeriod = mm.last_month
  } else {
    timeframe = 'week'
    const wm = metric as TopPanelWeekMetric
    thisPeriod = wm.this_week
    lastPeriod = wm.last_week
  }

  const isColumnLayout = useIsColumnLayout()
  const showTimeframes = alwaysShowTimeframes || isColumnLayout

  return (
    <AddressCol header={header}>
      <>
        <SummaryInteger
          value={thisPeriod}
          translationKey={translationKey}
          roundPrecision={0}
        />
        <GraphSubtitle
          sentiment={sentiment}
          current={thisPeriod}
          past={lastPeriod}
          timeframe={timeframe}
        />
        <LocationChart metric={metric} timeframe={timeframe} />
        {isExpanded && (
          <BenchmarkWrapper blur={!dashboardSaved}>
            <BenchmarkData
              timeframe={showTimeframes ? timeframe : undefined}
              periods={showTimeframes ? 1 : undefined}
              metric={lastPeriod}
              translationKey={translationKey}
            />
            <BenchmarkDivider />
            <BenchmarkData
              timeframe={showTimeframes ? 'year' : undefined}
              periods={showTimeframes ? 1 : undefined}
              metric={last_year}
              translationKey={translationKey}
            />
            <BenchmarkDivider />
            <BenchmarkData
              timeframe={showTimeframes ? 'year' : undefined}
              periods={showTimeframes ? 2 : undefined}
              metric={CY2019}
              translationKey={translationKey}
            />
          </BenchmarkWrapper>
        )}
      </>
    </AddressCol>
  )
}
