import React, { useState, useEffect } from 'react'
import { sum } from 'lodash'
import { DateTime } from 'luxon'

import { useGetInsight } from 'queries/useGetInsight'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { InsightEditor } from './InsightEditor'
import {
  IntervalOrderSumFragment,
  IntervalPredictionSumFragment,
} from 'config/graphqlTypes'
import { SavedInsight } from 'pages/tracking/insight/SavedInsight'
import * as Analytics from 'config/analytics'
import { Backdrop } from 'pages/tracking/insight/styles'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'

const ALERT_DIFFERENCE_IN_DOLLARS = 1000
const ALERT_DIFFERENCE_IN_PERCENT = 0.2

interface InsightProps {
  date: DateTime
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  salesType: string
}

export const Insight: React.FC<InsightProps> = ({
  date,
  orderSums,
  predictionSums,
  salesType,
}) => {
  const { location } = useCurrentLocation()
  const insightData = useGetInsight(date)
  const actual = sum(orderSums.map(({ reportedSales }) => reportedSales))
  const predicted = sum(
    predictionSums.map(({ reportedSales }) => reportedSales)
  )

  const forecastDifference = actual - predicted
  const { organization } = useLoggedInUserOrRedirect()
  const locationId = location?.id
  const hideInsightsPopUp =
    organization.hideInsightsPopUp || location.hideInsightsPopUp
  const wasPredictionOff = hideInsightsPopUp
    ? false
    : Math.abs(forecastDifference / 100.0) >= ALERT_DIFFERENCE_IN_DOLLARS &&
      Math.abs(forecastDifference / predicted) >= ALERT_DIFFERENCE_IN_PERCENT
  const [isEditing, setEditing] = useState(false)
  const onEdit = () => setEditing(true)
  const onSubmit = () => setEditing(false)
  const isLoading = insightData.loading
  const insight = insightData.data?.insight ?? null

  useEffect(() => {
    if (wasPredictionOff && !isLoading && !insight) {
      Analytics.track('Prompt for Insight', { date, locationId })
    }
  }, [date, locationId, wasPredictionOff, isLoading, insight])

  if (isLoading) {
    return null
  }

  if (insight && !isEditing) {
    return <SavedInsight insight={insight} onEdit={onEdit} />
  } else if (wasPredictionOff || isEditing) {
    return (
      <Backdrop>
        <InsightEditor
          date={date}
          forecastDifference={forecastDifference}
          insight={insight}
          locationId={locationId}
          onSubmit={onSubmit}
        />
      </Backdrop>
    )
  } else {
    return null
  }
}
