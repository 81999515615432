import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { sortBy, uniqBy } from 'lodash'

import AuthenticatedPage from 'pages/AuthenticatedPage'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { Loading } from 'components/Loading'
import { PlansTable } from 'pages/plans/PlansTable'
import { H2Text } from 'components/Text'
import { HeaderContainer } from 'pages/plans/styles'
import { LoadMorePlans } from 'pages/plans/LoadMorePlans'
import { useGetWeeklyPlanSummariesQuery } from 'config/graphqlTypes'

const numWeeksPerFetch = 7

export const Plans = () => {
  const { t } = useTranslation()
  // TODO: move to the backend so the frontend simply queries/displays the list
  // of plans
  const {
    location: { id },
    times: { startOfThisWeek },
  } = useCurrentLocation()

  const startOn = startOfThisWeek.minus({ weeks: 3 })
  const endOn = startOfThisWeek.plus({ weeks: 4 }).endOf('week')

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [paginatedStartOn, setPaginatedStartOn] = useState<DateTime>(startOn)

  const { data, fetchMore } = useGetWeeklyPlanSummariesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      endOn,
      locationId: id,
      startOn,
    },
  })

  const onLoadMore = async () => {
    setIsLoadingMore(true)
    const newStartOn = paginatedStartOn.minus({ weeks: numWeeksPerFetch })
    const newEndOn = endOn.minus({ weeks: numWeeksPerFetch })

    await fetchMore({
      variables: { startOn: newStartOn, endOn: newEndOn },
      updateQuery: (prev, { fetchMoreResult }) => ({
        weeklyPlans: [...prev.weeklyPlans, ...fetchMoreResult.weeklyPlans],
      }),
    })

    setPaginatedStartOn(newStartOn)
    setIsLoadingMore(false)
  }

  const plans = sortBy(
    uniqBy(data?.weeklyPlans ?? [], 'id'),
    'startOn'
  ).reverse()

  return (
    <AuthenticatedPage>
      <div>
        <HeaderContainer>
          <H2Text>{t('plans.weeklyPlanning')}</H2Text>
        </HeaderContainer>
        {plans && plans.length >= numWeeksPerFetch ? (
          <>
            <PlansTable plans={plans} />
            <LoadMorePlans
              isLoadingMore={isLoadingMore}
              onLoadMore={onLoadMore}
            />
          </>
        ) : (
          <Loading />
        )}
      </div>
    </AuthenticatedPage>
  )
}
