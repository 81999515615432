import React, { FC } from 'react'
import { range } from 'lodash'

import {
  useGetPlanForReportingQuery,
  LocationForReportingFragment,
} from 'config/graphqlTypes'
import { daysFromTimeZone } from 'utils/daysAndTimes'
import { PredictedSalesRow } from 'pages/reporting/PredictedSalesRow'
import {
  StyledReportingCell,
  StyledReportingTableRow,
  StyledTopBorderWithLeftPadding,
  StyledDividerCell,
} from 'pages/reporting/styles'
import { ActualSalesRow } from 'pages/reporting/ActualSalesRow'
import { LaborActualRow } from 'pages/reporting/LaborActualRow'
import { LaborScheduledRow } from 'pages/reporting/LaborScheduledRow'
import { LaborBudgetRow } from 'pages/reporting/LaborBudgetRow'
import { ReportingIntervalProps } from 'pages/reporting'

const DividerRow: FC = () => (
  <StyledReportingTableRow>
    <StyledReportingCell>
      <StyledTopBorderWithLeftPadding />
    </StyledReportingCell>
    {range(9).map(columnNum => (
      <StyledDividerCell key={columnNum} />
    ))}
  </StyledReportingTableRow>
)

interface Props extends ReportingIntervalProps {
  location: LocationForReportingFragment
  isFirst: boolean
}

export const LocationReport: FC<Props> = ({
  location,
  isFirst,
  days,
  startDate,
  endDate,
}) => {
  const startOfToday = daysFromTimeZone(location.timeZone).startOfToday
  const isFutureWeek = startOfToday.toISODate() < days[0].start.toISODate()

  const plan = useGetPlanForReportingQuery({
    variables: {
      locationId: location.id,
      startOn: startDate,
      endOn: endDate,
    },
  }).data?.plan

  if (!plan) {
    return null
  }

  return (
    <>
      {!isFirst && <DividerRow />}
      <PredictedSalesRow
        plan={plan}
        location={location}
        isFutureWeek={isFutureWeek}
        startOfToday={startOfToday}
        days={days}
      />
      {!isFutureWeek && (
        <ActualSalesRow plan={plan} days={days} location={location} />
      )}
      <LaborBudgetRow plan={plan} />
      <LaborScheduledRow plan={plan} isLastRow={isFutureWeek} />
      {!isFutureWeek && <LaborActualRow plan={plan} locationId={location.id} />}
    </>
  )
}
