import React from 'react'
import { FC } from 'react'

import { PlanForReportingFragment } from 'config/graphqlTypes'
import { StyledSubmittedBadge } from 'pages/reporting/styles'
import { useTranslation } from 'react-i18next'

interface Props {
  plan: PlanForReportingFragment
}

export const SubmittedBadge: FC<Props> = ({ plan }) => {
  const isSubmitted = !!plan.submittedAt
  const formattedSubmitDate = plan.lastUpdatedAt
    ? plan.lastUpdatedAt.toFormat('M/d')
    : ''

  const { t } = useTranslation()

  return (
    <StyledSubmittedBadge isSubmitted={isSubmitted}>
      {isSubmitted
        ? t('reporting.planSubmitted', { date: formattedSubmitDate })
        : t('reporting.noPlanSubmitted', { date: formattedSubmitDate })}
    </StyledSubmittedBadge>
  )
}
