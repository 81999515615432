import React from 'react'
import capitalize from 'lodash/capitalize'
import { DateTime } from 'luxon'
import { PointTooltipProps, Serie } from '@nivo/line'
import { useTranslation } from 'react-i18next'

import { formattedDollars } from 'utils/currency'
import {
  getActuals,
  getFutureActuals,
  getPredictions,
  isActuals,
  isFutureActuals,
} from 'components/Chart/chartDataConfig'
import { ValueDescription } from 'components/Chart/Tooltip/ValueDescription'
import { Tooltip } from 'components/Chart/Tooltip/Tooltip'

export const SalesChartTooltip = (
  series: Serie[],
  isWeekView: boolean,
  getDisplayTimestamp: (time: DateTime) => string
) =>
  function DefaultTooltip({ point }: PointTooltipProps) {
    const { t } = useTranslation()

    const [seriesId, indexString] = point.id.split('.')
    const index = parseInt(indexString)

    const actualSeries = getActuals(series)!.data.map(a => ({
      ...a,
      epoch: DateTime.fromISO(a.endAtISO).valueOf(),
    }))
    const predictionSeries = getPredictions(series)!.data.map(a => ({
      ...a,
      epoch: DateTime.fromISO(a.endAtISO).valueOf(),
    }))
    const unfulfilledSeries = getFutureActuals(series)!.data.map(a => ({
      ...a,
      epoch: DateTime.fromISO(a.endAtISO).valueOf(),
    }))

    const dateTime = DateTime.fromISO((point.data as any).endAtISO! as string)

    const isLastActual = actualSeries.length - 1 === index
    // If there are actuals, then the first future series point is on top of
    // the last real time actual point.
    const isRealTime =
      dateTime > DateTime.local().startOf('day') &&
      ((isActuals(seriesId) && isLastActual) ||
        (isFutureActuals(seriesId) && index === 0 && actualSeries.length > 0))

    const pointEpoch = dateTime.valueOf()

    const actual = actualSeries.find(a => a.epoch === pointEpoch)?.y as number
    const unfulfilled = isRealTime
      ? 0
      : ((unfulfilledSeries.find(a => a.epoch === pointEpoch) as any)
          ?.value as number)

    const predicted = (() => {
      const nextOrCurrentPredictionData = predictionSeries.find(
        p => p.epoch >= pointEpoch
      )!
      const nextOrCurrentPrediction = nextOrCurrentPredictionData
        ? (nextOrCurrentPredictionData.y as number)
        : 0

      if (!isRealTime) {
        return nextOrCurrentPrediction
      }

      // Calculate the "prorated" prediction for the real time dot
      const previousPredictionData = predictionSeries
        .slice()
        .reverse()
        .find(p => p.epoch <= pointEpoch)!

      if (!previousPredictionData || !nextOrCurrentPredictionData) {
        return nextOrCurrentPrediction
      }

      const previousPrediction = previousPredictionData.y as number
      const intervalPrediction = nextOrCurrentPrediction - previousPrediction

      const durationOfInterval =
        nextOrCurrentPredictionData.epoch - previousPredictionData.epoch
      const durationThroughInterval = pointEpoch - previousPredictionData.epoch
      const proportionThroughInterval =
        durationThroughInterval / durationOfInterval

      const proratedAmount = intervalPrediction * proportionThroughInterval

      return previousPrediction + proratedAmount
    })()

    const time = getDisplayTimestamp(dateTime)
    const title = isWeekView
      ? time
      : t('charts.tooltip.hourlyTitle', {
          relativeUnit: capitalize(
            dateTime.toRelativeCalendar({
              unit: 'days',
            }) || ''
          ),
          time,
        })

    const showPredicted = typeof predicted === 'number'
    const showUnfulfilled = typeof unfulfilled === 'number' && unfulfilled > 0
    const showActual = !showUnfulfilled && typeof actual === 'number'

    return (
      <Tooltip title={title}>
        <>
          {showPredicted && (
            <ValueDescription
              transKey="charts.tooltip.predictions"
              formattedValue={formattedDollars(predicted)}
              valueColor="blue-light"
            />
          )}
          {showUnfulfilled && (
            <ValueDescription
              transKey="charts.tooltip.futureActuals"
              formattedValue={`+${formattedDollars(unfulfilled as number)}`}
              valueColor="blue"
            />
          )}
          {showActual && (
            <ValueDescription
              transKey="charts.tooltip.reported"
              formattedValue={formattedDollars(actual)}
              valueColor="blue"
            />
          )}
        </>
      </Tooltip>
    )
  }
