import React, { ChangeEvent, FormEvent, useState } from 'react'
import usePlacesAutocomplete, { Suggestion } from 'use-places-autocomplete'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import * as Analytics from 'config/analytics'
import { getAddress } from 'pages/covid/getAddress'
import {
  AddressFormRow,
  SuggestionsWrapper,
  AddressFormButton,
  StyledAddressInputCol,
  SuggestionDivider,
} from 'pages/covid/styles'
import { Form, StringInput } from 'components/Form'
import { Address } from 'pages/covid/types'

// Only show suggestions that can be pinned to an exact street address
const blacklistedTypes = [
  'locality',
  'country',
  'natural_feature',
  'political',
  'route',
  'intersection',
]

interface Props {
  addAddress: (address: Address) => void
  topbar?: boolean
}

export const AddressForm = ({ addAddress, topbar }: Props) => {
  const { t } = useTranslation()
  const location = useLocation()

  const {
    value,
    suggestions: { status, data: suggestions },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    cache: false, // TODO: Add back in after this has been out there for a few days
  })

  const [address, setAddress] = useState<Address | null>(null)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // TODO: Move code back into addAddress() as part of a later bugfix
    if (address) {
      Analytics.track(`[CRC]: Added new address - ${location.pathname}`, {
        fullAddress: address.fullAddress,
        source: location.pathname,
      })

      addAddress(address)
    }

    setValue('')
    setAddress(null)
  }

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)

    if (address) {
      setAddress(null)
    }
  }

  const handleSuggestionSelect = async ({ description }: Suggestion) => {
    setValue(description, false)
    clearSuggestions()

    setAddress(await getAddress(description))
  }

  const filteredSuggestions = suggestions.filter(
    ({ types, terms }) =>
      !types.find(t => blacklistedTypes.includes(t)) &&
      terms[terms.length - 1].value === 'USA'
    // Last term refers to the location's country
  )

  const isDisplayingSuggestions =
    status === 'OK' && filteredSuggestions.length > 0

  return (
    <Form onSubmit={onSubmit}>
      <AddressFormRow noGutters>
        <StyledAddressInputCol
          isDisplayingSuggestions={isDisplayingSuggestions}
        >
          <StringInput
            controlProps={{
              placeholder: t('covidDashboard.address'),
              value: value,
              onChange: onValueChange,
            }}
          />
          {isDisplayingSuggestions && (
            <SuggestionsWrapper>
              <SuggestionDivider />
              {filteredSuggestions.map(suggestion => (
                <p
                  key={suggestion.place_id}
                  onClick={() => handleSuggestionSelect(suggestion)}
                >
                  <strong>
                    {`${suggestion.structured_formatting.main_text} `}
                  </strong>
                  <small>
                    {suggestion.structured_formatting.secondary_text}
                  </small>
                </p>
              ))}
            </SuggestionsWrapper>
          )}
        </StyledAddressInputCol>
        <AddressFormButton disabled={!address} topbar={topbar} type="submit">
          {t('covidDashboard.submit')}
        </AddressFormButton>
      </AddressFormRow>
    </Form>
  )
}
