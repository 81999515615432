import styled from 'styled-components'
import Col from 'react-bootstrap/Col'

import {
  LargeBodyBoldText,
  MediumBodyText,
  TableLabelText,
  TableLabelBoldText,
  MediumBodyBoldText,
} from 'components/Text'
import { tableLabelTinyBoldTextStyle } from 'config/styles'

export const BannerWrapper = styled.div<{ bgColor: string }>(({ bgColor }) => ({
  display: 'flex',
  width: '100%',
  height: 40,
  backgroundColor: bgColor,
  borderRadius: 50,
  marginTop: 20,
  marginBottom: 8,
}))

export const StatusIcon = styled.img({
  width: 24,
  marginLeft: 9,
})

export const BannerText = styled(LargeBodyBoldText)({
  color: 'var(--white)',
  marginLeft: 12,
  lineHeight: '40px',
})

export const Colors = {
  blue: 'var(--blue)',
  gray: 'var(--gray4)',
  red: 'var(--bittersweet)',
}

export const NoWeather = styled(MediumBodyText)({
  color: 'var(--gray2)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  fontWeight: 400,
})

export const NoWeatherContainer = styled.div({
  display: 'flex',
  width: '12em',
})

export const Event = styled(TableLabelText)({
  maxWidth: 250,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export const Time = styled(TableLabelBoldText)({
  marginRight: 4,
})

export const NoEvents = styled(MediumBodyText)({
  color: 'var(--gray2)',
  display: 'flex',
  height: '100%',
  flexDirection: 'column-reverse',
})

export const MetricCol = styled(Col)({
  display: 'flex',
  flexDirection: 'column-reverse',
  marginTop: 20,
})

export const MetricTitle = styled(MediumBodyBoldText)<{
  isSmall?: boolean
}>(({ isSmall }) => ({
  color: 'var(--gray1)',
  marginBottom: 9,
  ...(isSmall ? { fontSize: '0.75rem' } : {}),
}))

export const MetricName = styled(TableLabelText)({
  ...tableLabelTinyBoldTextStyle,
  color: 'var(--gray4)',
  fontWeight: 800,
  position: 'relative',
})

export const SmallMetricValue = styled(LargeBodyBoldText)<any>(props => ({
  color: props.color ?? 'var(--gray2)',
  marginBottom: 0,
  whiteSpace: 'nowrap',
  cursor: props.cursor ?? '',
  textUnderlinePosition: props.underlined ? 'under' : '',
  textDecoration: props.underlined ? 'underline dotted' : '',
}))

export const LargeMetricValue = styled(SmallMetricValue)({
  fontSize: '1.375rem',
  lineHeight: '1.5rem',
  marginBottom: 0,
})

export const MetricNameValueContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  maxHeight: 70,
  minWidth: '4em',
})

export const MetricContainerWrapper = styled.div({
  display: 'flex',
})

export const MetricLine = styled.div<{
  color: string
  isSmall?: boolean
}>(({ color }) => ({
  height: 70,
  width: 0,
  border: `2px solid var(${color})`,
  borderRadius: 4,
  marginRight: 15,
}))

export const SmallMetricLine = styled(MetricLine)<{ isSmall?: boolean }>(
  ({ isSmall }) => ({
    height: 44,
    marginRight: isSmall ? 4 : 9,
  })
)

export const StyledTooltipIcon = styled.img({
  position: 'absolute',
  top: 0,
  right: -5,
})

export const WeatherIcon = styled.img({
  marginTop: 3,
  display: 'inline-block',
  height: 40,
  marginRight: 12,
  marginLeft: 12,
})
