import React, { CSSProperties, ReactNode, useState } from 'react'
import { Popover, OverlayTrigger, OverlayTriggerProps } from 'react-bootstrap'
import styled from 'styled-components'

type OverlayTriggerCustomType = Omit<OverlayTriggerProps, 'overlay'>

type Props = {
  id: string
  title?: ReactNode | string
  content?: ReactNode | string
  children?: ReactNode
  popoverStyles?: CSSProperties
} & OverlayTriggerCustomType

function CustomPopover({
  id,
  title,
  content,
  children,
  popoverStyles,
  ...props
}: Props) {
  const [show, setShow] = useState(false)

  function onToggleHandler(flag: boolean) {
    setShow(flag)
  }

  function onMouseLeaveHandler() {
    setShow(false)
  }

  function onMouseEnterHandler() {
    setShow(true)
  }

  return (
    <OverlayTrigger
      {...props}
      overlay={
        <StyledPopover
          id={id}
          style={popoverStyles}
          onMouseLeave={onMouseLeaveHandler}
          onMouseEnter={onMouseEnterHandler}
        >
          {title && <Popover.Title>{title}</Popover.Title>}
          {content && <Popover.Content>{content}</Popover.Content>}
        </StyledPopover>
      }
      onToggle={onToggleHandler}
      onExit={onMouseLeaveHandler}
      show={show}
      rootClose={true}
    >
      {children}
    </OverlayTrigger>
  )
}

const StyledPopover = styled(Popover)`
  fill: #fff;
  stroke-width: 1px;
  stroke: #eee;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-width: 300px;
`

export default CustomPopover
