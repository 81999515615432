import styled from 'styled-components'

export const Dot = styled.span<{ color: string }>(({ color }) => ({
  height: 10,
  width: 10,
  borderRadius: '50%',
  backgroundColor: color,
  display: 'inline-block',
  marginRight: 5,
}))
