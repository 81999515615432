import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'

import {
  H3Text,
  LargeBodyBoldText,
  RegularBodyText,
  RegularBoldBodyText,
  SmallBoldBodyText,
} from 'components/Text'
import {
  baseLabelStyle,
  h3Style,
  regularBodyTextStyle,
  smallBodyTextStyle,
  tableLabelBoldTextStyle,
  tableLabelTextStyle,
  tableLabelTinyTextStyle,
} from 'config/styles'
import { TextButton } from 'components/Button'
import { ReactComponent as LeftChevronRaw } from 'assets/images/left-chevron.svg'
import { ReactComponent as RightChevronRaw } from 'assets/images/right-chevron.svg'
import { ReactComponent as CalendarIconRaw } from 'assets/images/calendar.svg'
import { ReactComponent as UpChevronRaw } from 'assets/images/up-chevron-blue.svg'
import { ReactComponent as DownChevronRaw } from 'assets/images/down-chevron-blue.svg'
import {
  BottomChartBorderColor,
  BottomChartBorderWidth,
} from 'pages/plan/dailyViewModal/GridLines'
import { categoryRowStyle } from 'pages/plan/styles'
import { Dot } from 'components/Dot'

export const HorizontalChartMargin = 15
const BottomChartMargin = 36

export const smallScreenWidth = 550
export const fullWidth = 992

export const FULL_WIDTH = `@media only screen and (min-width: ${fullWidth}px)`
export const SMALL_WIDTH = `@media only screen and (max-width: ${fullWidth -
  1}px)`
export const VERY_SMALL_WIDTH = `@media only screen and (max-width: ${smallScreenWidth}px)`

const DailyViewRowPaddingBottom = 10

export const StyledModal = styled(Modal)({
  [FULL_WIDTH]: {
    '--font-downscale': '100%',
    '--grid-padding': DailyViewRowPaddingBottom,
    '--horizontal-padding': '21px',
  },
  [SMALL_WIDTH]: {
    '--font-downscale': '80%',
    '--grid-padding': DailyViewRowPaddingBottom * 0.8,
    '--horizontal-padding': '3%',
  },
  [VERY_SMALL_WIDTH]: {
    '--font-downscale': '60%',
    '--grid-padding': DailyViewRowPaddingBottom * 0.6,
    '--horizontal-padding': '3%',
  },
  width: '100vw',
  '.daily-view-modal': {
    width: '95vw',
    height: '95vh',
    maxWidth: 'none',
  },
  '.modal-content': {
    border: 'none',
    borderRadius: 0,
    width: '95vw',
    height: '95vh',
  },
})

export const DailyModalContainer = styled.div({
  height: '100%',
  overflowY: 'scroll',
})

export const DailyModalInner = styled.div({
  [FULL_WIDTH]: {
    position: 'relative',
    display: 'flex',
    minHeight: '100%',
  },
})

export const DailyTimelineContainer = styled.div({
  paddingLeft: 'var(--horizontal-padding)',
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  [SMALL_WIDTH]: {
    paddingLeft: '3%',
    paddingRight: '3%',
  },
})

export const DailyTimelineCategoryRow = styled.div({
  ...categoryRowStyle,
  borderStyle: 'solid',

  position: 'relative',
  left: 'calc(0px - var(--horizontal-padding))',
  width: 'calc(100% + var(--horizontal-padding))',
  paddingLeft: 'var(--horizontal-padding)',
  fontSize: 'var(--font-downscale)',

  [SMALL_WIDTH]: {
    left: 'calc(-3.2%)',
    width: '106.4%',
    paddingLeft: '3.2%',
    height: 27,
  },
})

export const DailyTimelineAllCategoriesRow = styled(DailyTimelineCategoryRow)({
  marginBottom: '10px',
})

export const DailyTimelineRowWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  // Ensure the last column takes up all remaining space
  '&:last-of-type': {
    height: '60px',
    '> div:last-of-type': {
      height: '100%',
      '> div:last-of-type': {
        height: '100%',
        '> div:last-of-type': {
          paddingTop: '0.5em',
          paddingBottom: '0.5em',
          height: '100%',
        },
      },
    },
  },
})

export const DailyTimelineRowInnerWrapper = styled.div({
  display: 'flex',
  maxWidth: '100%',
  '> *': {
    paddingBottom: 4,
  },
})

const LeftWidth = 180

export const DailyTimelineRowLeft = styled.div({
  [FULL_WIDTH]: {
    flex: `0 0 ${LeftWidth}px`,
    width: LeftWidth,
  },
  [SMALL_WIDTH]: {
    width: '12%',
    flex: `0 0 12%`,
  },
})

export const DailyTimelineRowMiddle = styled.div<{ noMiddlePadding?: boolean }>(
  ({ noMiddlePadding }) => ({
    position: 'relative',
    flex: 'auto',
    marginLeft: noMiddlePadding ? 0 : HorizontalChartMargin,
    marginRight: (noMiddlePadding ? 0 : HorizontalChartMargin) + 50,
    [SMALL_WIDTH]: {
      marginRight: noMiddlePadding ? 0 : HorizontalChartMargin,
    },
  })
)

export const DailyTimelineRowRight = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [FULL_WIDTH]: {
    background: 'var(--gray7)',
    flex: '0 1 130px',
  },
  [SMALL_WIDTH]: {
    width: '8%',
    flex: `0 0 8%`,
  },
})

export const DailySidebar = styled.div({
  [FULL_WIDTH]: {
    borderRight: 'solid 2px var(--gray5)',
  },
  backgroundColor: 'var(--gray7)',
  flex: '0 0 285px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '29px 25px',
  [SMALL_WIDTH]: {
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: 0,
  },
})

export const DailyBreakdownHeader = styled(RegularBoldBodyText)({
  color: 'var(--gray4)',
  marginBottom: 8,
})

export const DaySelectorContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 4,
})

export const DayHeading = styled.div({
  ...h3Style,
  width: 140,
  textAlign: 'center',
  whiteSpace: 'nowrap',
})

export const ChangeDayButton = styled(TextButton)({
  display: 'flex',
  '& path': {
    stroke: 'var(--gray4)',
  },
  '&:disabled path': {
    stroke: 'var(--gray5)',
  },
})

export const StatsContainer = styled.div({
  width: '100%',
  position: 'relative',
  [SMALL_WIDTH]: {
    display: 'flex',
    flexFlow: 'column wrap',
    borderColor: BottomChartBorderColor,
    borderWidth: BottomChartBorderWidth,
    borderStyle: 'solid none',
    margin: `10px ${HorizontalChartMargin}px`,
    padding: '1.5% 0% 1%',
  },
})

export const StatSection = styled.div({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  padding: '15px 0px 3px',
  borderBottom: 'solid 1px var(--gray6)',
  color: 'var(--gray3)',
  '&:last-child': {
    borderBottom: 'none',
  },
  [SMALL_WIDTH]: {
    width: '50%',
    borderBottom: 'none',
    padding: '2% 3% 0%',
    '&:last-child': {
      flexBasis: '100%',
    },
  },
})

export const StatSectionCol = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '15px 0px 3px',
  color: 'var(--gray3)',
  [SMALL_WIDTH]: {
    width: '50%',
    borderBottom: 'none',
    padding: '2% 3% 0%',
    '&:last-child': {
      flexBasis: '100%',
    },
  },
})

export const StatTableTitle = styled.div({
  ...baseLabelStyle,
  marginTop: 12,
})

export const StatTitle = styled.div({
  ...tableLabelBoldTextStyle,
  gridColumnStart: 1,
  marginBottom: 12,
  color: 'var(--gray2)',
})

export const OrdersTotalTitle = styled.div({
  ...tableLabelBoldTextStyle,
  gridColumnStart: 1,
  fontSize: '12px',
  fontWeight: 800,
  marginBottom: 12,
  color: 'var(--gray2)',
})

export const StatSubSubTitle = styled.div({
  fontSize: '0.7em',
})

export const StatSubtitle = styled.div({
  ...smallBodyTextStyle,
  paddingLeft: 15,
  color: 'var(--gray2)',
  fontSize: '12px',
  gridColumnStart: 1,
  marginBottom: 12,
  [VERY_SMALL_WIDTH]: {
    paddingLeft: 5,
  },
})

export const StatValue = styled.div({
  ...regularBodyTextStyle,
  fontSize: '0.75rem', // 12px
  textAlign: 'center',
  gridColumnStart: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 12,
  color: 'var(--gray1)',
})

export const StatValueRow = styled.div({
  ...regularBodyTextStyle,
  fontSize: '0.75rem', // 12px
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
  marginBottom: 12,
  marginLeft: 15,
  color: 'var(--gray1)',
})

export const CompoundStatTitle = styled.div({
  ...tableLabelBoldTextStyle,
  marginBottom: 3,
  marginLeft: 0,
  marginRight: 5,
  whiteSpace: 'nowrap',
})

export const CompoundStatBody = styled.div({
  ...tableLabelTextStyle,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

export const OrdersTotal = styled(StatValue)({
  fontWeight: 800,
})

export const LeftChevron = styled(LeftChevronRaw)({})
export const RightChevron = styled(RightChevronRaw)({})

const dragChevronStyle = {
  [SMALL_WIDTH]: {
    display: 'none',
  },
  '> path': {
    stroke: 'var(--gray2)',
  },
}

export const LeftDragChevron = styled(LeftChevronRaw)(dragChevronStyle)
export const RightDragChevron = styled(RightChevronRaw)(dragChevronStyle)

const closeButtonSize = {
  height: 45,
  width: 45,
}

export const DailyViewModalHeader = styled.div({
  height: 51,
  borderBottom: 'solid 1px var(--gray5)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&::before': {
    content: '""',
    ...closeButtonSize,
  },
})

export const DailyViewModalHeaderText = styled(H3Text)({
  [SMALL_WIDTH]: {
    fontSize: '21px',
  },
  [VERY_SMALL_WIDTH]: {
    fontSize: '16px',
  },
})

export const CloseButton = styled(TextButton)({
  ...closeButtonSize,
  padding: 0,
})

export const ChartWrapper = styled.div({
  height: 270,
  width: '100%',
  [VERY_SMALL_WIDTH]: {
    height: 180,
  },
})

// This fixes a bug where the chart wouldn't properly resize down with the flex settings. This seems to
// be a bug in the nivo ResponsiveLine component. By putting it in a container with absolute position,
// we allow the outer box to resize down, be measured properly, and allow the chart to resize.
export const ChartInnerWrapper = styled.div<{ width: number }>(({ width }) => ({
  zIndex: 1,
  position: 'absolute',
  height: '100%',
  width,
}))

export const ChartMargins = {
  left: HorizontalChartMargin,
  right: HorizontalChartMargin,
  bottom: BottomChartMargin,
  top: 25,
}

const HorizontalLineStyle = {
  height: BottomChartBorderWidth,
  background: BottomChartBorderColor,
  width: 'calc(100% - 15px)',
  position: 'absolute' as 'absolute',
  [SMALL_WIDTH]: {
    width: '100%',
  },
}

export const TopChartLine = styled.div({
  ...HorizontalLineStyle,
  bottom: BottomChartMargin - 1,
})

export const BottomChartLine = styled.div({
  ...HorizontalLineStyle,
  bottom: 0,
})

export const StyledDailyViewLegend = styled.div({
  paddingTop: 33,
  width: 140,
  [SMALL_WIDTH]: {
    display: 'none',
  },
})

export const StyledDailyViewMinimumLaborLegend = styled.div<{
  enable?: boolean
}>(({ enable = false }) => ({
  paddingTop: 20,
  width: 140,
  display: enable ? 'block' : 'none',
  [SMALL_WIDTH]: {
    display: 'none',
  },
}))

export const DailyViewLegendText = styled.div({
  color: 'var(--gray1)',
  borderLeft: 'solid 4px var(--blue)',
  paddingLeft: 6,
})

export const DailyViewMinimumLaborLegendText = styled.div({
  color: 'var(--gray1)',
  display: 'flex',
  gap: '6.95px',
  paddingLeft: 2,
})

export const DailyViewLegendTitle = styled(RegularBoldBodyText)({
  marginBottom: 20,
})

export const DailyViewLegendDescription = styled(SmallBoldBodyText)({
  lineHeight: '15px',
})

export const NoShiftsMessageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '50px 95px 50px 0',
  [SMALL_WIDTH]: {
    padding: '50px 50px 50px 0',
  },
  [VERY_SMALL_WIDTH]: {
    padding: '50px 0px',
  },
})

export const CalendarIcon = styled(CalendarIconRaw)({
  margin: '0 auto 16px',
})

export const NoShiftsTitle = styled(LargeBodyBoldText)({
  color: 'var(--gray3)',
  marginBottom: 5,
})

export const NoShiftsSubtitle = styled(RegularBodyText)({
  color: 'var(--gray4)',
})

export const LaborTooLowNote = styled.div({
  display: 'flex',
  alignItems: 'center',
  color: 'var(--bittersweet)',
})

export const LaborTooLowDot = styled(Dot)({
  height: 12,
  width: 12,
  fontSize: 10,
  lineHeight: '12px',
  fontWeight: 800,
  color: 'var(--white)',
})

export const LaborTooLowLabel = styled.span({
  ...tableLabelTinyTextStyle,
})

export const ForecastLabel = styled.div({
  width: '235px',
  fontWeight: 700,
  fontSize: '12px',
})

export const TimelineControls = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const StyledUpChevron = styled(UpChevronRaw)({
  width: 15,
})

export const StyledDownChevron = styled(DownChevronRaw)({
  top: -4,
  width: 15,
})
