import React from 'react'
import { useTranslation } from 'react-i18next'

import { YesNoLabel, YesNoText } from 'pages/tracking/insight/styles'

const YES = 'yes'
const NO = 'no'

interface ButtonProps {
  value: boolean
  currentValue: boolean | null
  onChange: () => void
}

const Button = ({ value, currentValue, onChange }: ButtonProps) => {
  const { t } = useTranslation()
  const title = value
    ? t('tracking.insight.wasUnusualYes')
    : t('tracking.insight.wasUnusualNo')

  const stringValue = value ? YES : NO

  const color = (() => {
    if (value === currentValue) {
      return 'var(--blue)'
    }
    if (currentValue) {
      return 'var(--gray3)'
    }
    return 'var(--gray2)'
  })()

  return (
    <YesNoLabel color={color}>
      <input
        type="radio"
        value={stringValue}
        checked={currentValue === value}
        onChange={onChange}
      />
      <YesNoText color={color}>{title}</YesNoText>
    </YesNoLabel>
  )
}

interface Props {
  value: boolean | null
  onChange: (value: boolean | null) => void
}

export const YesNoToggle = ({ value, onChange }: Props) => (
  <div data-testid="yes-no-toggle">
    <Button value={true} currentValue={value} onChange={() => onChange(true)} />
    <Button
      value={false}
      currentValue={value}
      onChange={() => onChange(false)}
    />
  </div>
)
