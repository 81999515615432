import React, { FC } from 'react'
import {
  ShiftSwapRequestFragment,
  usePreviewViolationsQuery,
  ViolationFragment,
} from 'config/graphqlTypes'
import { useTranslation } from 'react-i18next'
import { simpleDateFormat, simpleTimeFormat } from 'utils/daysAndTimes'
import {
  ShiftSwapDescriptionContainer,
  ShiftSwapDescriptionDetail,
  ViolationsIndicator,
  ViolationDetails,
  ViolationDetailsWrapper,
} from 'components/Management/styles'
import {
  getViolationDetails,
  isViolationForShiftSwap,
} from 'utils/getViolationDetails'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { getUniqueViolations } from 'utils/getUniqueViolations'

interface Props {
  shiftSwapRequest: ShiftSwapRequestFragment
}

export const ShiftSwapDescriptionCell: FC<Props> = ({
  shiftSwapRequest: { newEmployee, employee, shift },
}) => {
  const { t } = useTranslation()

  const { data } = usePreviewViolationsQuery({
    variables: {
      id: shift.id,
      locationId: shift.location.id,
      startAt: shift.startAt,
      endAt: shift.endAt,
      employeeId: newEmployee?.id || '-1',
      shiftRoleId: shift.shiftRole?.id ?? null,
      shiftBreaks: [],
      isUnavailability: false,
    },
    fetchPolicy: 'cache-and-network',
  })
  const previewViolations = data?.previewViolations as ViolationFragment[]
  const shiftSwapViolations = getUniqueViolations(previewViolations).filter(
    isViolationForShiftSwap
  )

  const locationId = useCurrentLocationId()
  const hasViolations = !!shiftSwapViolations?.length
  const violationDetails = hasViolations
    ? shiftSwapViolations?.map(violation =>
        getViolationDetails({
          employeeName: newEmployee!.name,
          currentShift: shift,
          violation: violation,
          t,
          locationId,
        })
      )
    : null

  return (
    <ShiftSwapDescriptionContainer>
      <ShiftSwapDescriptionDetail>
        {hasViolations && (
          <div>
            <ViolationsIndicator data-testid="management-violations-badge">
              !
            </ViolationsIndicator>
          </div>
        )}
        <div>
          <div>
            <b>{newEmployee?.name}</b>{' '}
            {t('management.requests.shift_swap_requesting')}{' '}
            <b>{employee?.name}</b>
          </div>
          <div>
            {simpleDateFormat(shift.startAt)} |{' '}
            {`${simpleTimeFormat(shift.startAt)} - ${simpleTimeFormat(
              shift.endAt
            )}`}
          </div>
          <div>{shift.shiftRole?.name}</div>

          <ViolationDetailsWrapper>
            {violationDetails?.map((details, index) => (
              <ViolationDetails key={index}>•{details}</ViolationDetails>
            ))}
          </ViolationDetailsWrapper>
        </div>
      </ShiftSwapDescriptionDetail>
    </ShiftSwapDescriptionContainer>
  )
}
