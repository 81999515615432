import React from 'react'
import { useTranslation } from 'react-i18next'

import { YourNeighborhoodHeader } from 'pages/covid/dashboard/HeaderRow'
import { TopNeighborhoodBusinesses } from 'pages/covid/dashboard/addressRow/TopNeighborhoodBusinesses'
import { AddressCol } from 'pages/covid/dashboard/addressRow/AddressCol'
import { GeographicData } from 'pages/covid/dashboard/addressRow/GeographicData'
import { Address, TopPanelData } from 'pages/covid/types'
import { ExpandDashboardRowButton } from 'pages/covid/dashboard/addressRow/ExpandDashboardRowButton'

interface Props {
  address: Address
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  topPanelData: TopPanelData | null
  dashboardSaved: boolean
}

export const FirstColumn = ({
  isExpanded,
  setIsExpanded,
  topPanelData,
  address,
  dashboardSaved,
}: Props) => {
  const { t } = useTranslation()

  const topRestaurants = topPanelData?.top_same_month_restaurants
  const businessNames = topRestaurants && Object.keys(topRestaurants)

  const topNonRestaurants = topPanelData?.top_same_month_nonrestaurants
  const nonRestaurantNames = topNonRestaurants && Object.keys(topNonRestaurants)

  return (
    <AddressCol isFirst header={<YourNeighborhoodHeader />}>
      <>
        <ExpandDashboardRowButton
          isExpanded={isExpanded}
          onToggle={() => setIsExpanded(!isExpanded)}
        />
        <GeographicData address={address} topPanelData={topPanelData} />
        {isExpanded && (
          <>
            <TopNeighborhoodBusinesses
              title={t('covidDashboard.topRestaurants')}
              businessNames={businessNames}
              dashboardSaved={dashboardSaved}
            />
            <TopNeighborhoodBusinesses
              title={t('covidDashboard.topNonRestaurants')}
              businessNames={nonRestaurantNames}
              dashboardSaved={dashboardSaved}
            />
          </>
        )}
      </>
    </AddressCol>
  )
}
