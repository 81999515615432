import React, { MouseEvent, forwardRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import ReactButton from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

export const StyledButton = styled.button({
  borderRadius: 25,
  color: 'var(--white)',
  fontWeight: 800,
})

interface Props extends React.ComponentProps<typeof ReactButton> {
  waitOnPromise?: boolean
  onClick: () => Promise<void> | void
}

const Button = forwardRef(
  ({ children, disabled, onClick, waitOnPromise, ...props }: Props, ref) => {
    const [mounted, setMounted] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => () => setMounted(false))

    const onClickPromiseHandler = async (e: MouseEvent) => {
      e.preventDefault()
      setLoading(true)

      try {
        await onClick()
      } finally {
        if (mounted) setLoading(false)
      }
    }

    return (
      <ReactButton
        as={StyledButton}
        disabled={disabled || loading}
        ref={ref}
        children={children}
        onClick={waitOnPromise ? onClickPromiseHandler : onClick}
        {...props}
      />
    )
  }
)

const NoHoverButton = styled(Button)({
  backgroundColor: 'transparent',
  color: 'inherit',

  '&:hover': {
    backgroundColor: 'transparent',
    color: 'inherit',
  },

  '&:focus': {
    backgroundColor: 'transparent',
    color: 'inherit',
  },

  '&:not(:disabled):not(.disabled):active': {
    backgroundColor: 'transparent',
    color: 'inherit',
  },
})

export const TextButton = forwardRef(
  (props: React.ComponentProps<typeof Button>, ref) => (
    <NoHoverButton ref={ref} variant="link" {...props} />
  )
)

export const ButtonLink = styled(Link)({
  background: 'var(--blue)',
  borderRadius: 25,
  color: 'var(--white)',
  fontSize: 18,
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '21px',
  paddingBottom: 14,
  paddingTop: 14,
  textAlign: 'center',

  '&:hover': {
    color: 'var(--white)',
    textDecoration: 'none',
  },
})

export default Button
