import React, { FC } from 'react'
import { LaborHourEmployee } from '../../config/graphqlTypesRaw'
import { Col, Row } from 'react-bootstrap'
import {
  StyledValueLi,
  StyledItemSpan,
  StyledNameLi,
  StyledNameSpan,
  StyledUl,
} from './styles'
import { round, size } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getFormattedSingleShiftTime } from '../../utils/getFormattedSingleShiftTime'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'
import { routeMap } from '../../pages/Routes'
import { useCurrentLocationId } from '../../queries/useCurrentLocation'

type LaborHoursBreakdownListProps = {
  isCurrentDayOrWeek: boolean
  laborHourEmployees: LaborHourEmployee[] | undefined
}

export const LaborHoursBreakdownList: FC<LaborHoursBreakdownListProps> = ({
  isCurrentDayOrWeek,
  laborHourEmployees,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const locationId = useCurrentLocationId()

  const goToEmployeeEditPage = (id: string) =>
    history.push(
      `${routeMap.locations}/${locationId}${routeMap.location.management.employees}/${id}/edit`
    )

  return (
    <StyledUl>
      {laborHourEmployees?.map(laborHour => (
        <Row key={laborHour.employee.id}>
          <Col xs={12} md={3}>
            <StyledNameLi>
              <StyledNameSpan
                onClick={() => goToEmployeeEditPage(laborHour.employee.id)}
              >
                {laborHour.employee.name}
              </StyledNameSpan>
            </StyledNameLi>
          </Col>
          <Col xs={12} md={3}>
            <StyledValueLi>
              {size(laborHour.shiftRoles)
                ? laborHour.shiftRoles.map(role => (
                    <StyledItemSpan key={role.id}>{role.name}</StyledItemSpan>
                  ))
                : '\u2013'}
            </StyledValueLi>
          </Col>
          <Col xs={12} md={3}>
            <StyledValueLi>
              {t('misc.hourAbbreviationWithCount', {
                count: round(laborHour.hours, 2),
              })}
            </StyledValueLi>
          </Col>
          <Col xs={12} md={3}>
            {isCurrentDayOrWeek ? (
              <StyledValueLi>
                {size(laborHour.shifts)
                  ? laborHour.shifts.map(shift => (
                      <StyledItemSpan key={shift.id}>
                        {`${getFormattedSingleShiftTime(
                          DateTime.fromISO(shift.startAt)
                        )}-${getFormattedSingleShiftTime(
                          DateTime.fromISO(shift.endAt)
                        )}`}
                      </StyledItemSpan>
                    ))
                  : '\u2013'}
              </StyledValueLi>
            ) : (
              <StyledValueLi>
                {t('misc.hourAbbreviationWithCount', {
                  count: round(laborHour.plannedHours, 2),
                })}
              </StyledValueLi>
            )}
          </Col>
        </Row>
      ))}
    </StyledUl>
  )
}
