import React, { forwardRef, ReactNode, useState } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CalendarIcon } from '../assets/images/calendar.svg'
import { ReactComponent as TriangleDownIcon } from '../assets/images/triangle-down.svg'
import styled from 'styled-components'
import Dropdown from 'react-bootstrap/Dropdown'
import ReactDatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

export type DatePickerRange =
  | 'yesterday'
  | 'lastWeek'
  | 'lastMonth'
  | 'customRange'
export type DatePickerDatesOrNull = [DateTime | null, DateTime | null]
export type DatePickerDates = [DateTime, DateTime]

interface DatePickerToggleProps {
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void
}

interface ForwardRefCustomProps {
  children: ReactNode
  className: string | undefined
}

interface Props {
  defaultRangeType?: DatePickerRange
  defaultDates?: DatePickerDatesOrNull
  onChangeRange?: (rangeType: DatePickerRange) => void
  onSelectDates: (dates: DatePickerDates) => void
}

/**
 * Default date range is last week if defaultDates are not provided.
 * If defaultDates are provided, defaultRangeType is ignored.
 *
 * @param defaultRangeType
 * @param defaultDates
 * @param onSelectDates
 * @param onChangeRange
 * @constructor
 */
export const DatePicker = ({
  defaultRangeType,
  defaultDates,
  onChangeRange,
  onSelectDates,
}: Props) => {
  const { t } = useTranslation()
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)

  const [selectedRangeType, setSelectedRangeType] = useState<DatePickerRange>(
    defaultDates
      ? 'customRange'
      : defaultRangeType
      ? defaultRangeType
      : 'lastWeek'
  )

  const [defaultStart, defaultEnd] = defaultDates ?? [null, null]

  const dates = getDateRangeDates(selectedRangeType)
  const [customDateRange, setCustomDateRange] = useState<DatePickerDatesOrNull>(
    [defaultStart, defaultEnd]
  )

  const getSelectedDateRange = (): DatePickerDates => {
    const today = DateTime.now()
    const [customStart, customEnd] = customDateRange
    const [start, end] = dates ?? [customStart ?? today, customEnd ?? today]
    return [start, end]
  }

  const [selectedDateRange, setSelectedDateRange] = useState<DatePickerDates>(
    getSelectedDateRange()
  )

  const DatePickerToggle = forwardRef<HTMLInputElement, DatePickerToggleProps>(
    ({ onClick }, ref) => (
      <div
        ref={ref}
        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        <DatePickerStyled>
          <CalendarIconStyled />
          <DateRangeLabel dates={selectedDateRange} />
          <TriangleDownIcon />
        </DatePickerStyled>
      </div>
    )
  )

  const onToggleHandler = (isOpen: boolean) => {
    if (selectedRangeType === 'customRange') {
      setShowDatePicker(isOpen)
    }
    setShowDropdownMenu(isOpen)
  }

  const onSelectDateRangeHandler = (rangeType: DatePickerRange) => {
    const dateRange = getSelectedDateRange()
    const dates = getDateRangeDates(rangeType)
    const selectedDates = dates ?? dateRange
    setSelectedRangeType(rangeType)
    setShowDatePicker(false)
    setSelectedDateRange(selectedDates)
    onSelectDates(selectedDates)
    onChangeRange?.(rangeType)
  }

  const onSelectCustomRangeHandler = (rangeType: DatePickerRange) => {
    setSelectedRangeType(rangeType)
    setShowDropdownMenu(true)
    setShowDatePicker(true)
  }

  const onSelectDatePickerDatesHandler = (dates: DatePickerDatesOrNull) => {
    const [start, end] = dates
    if (start && end) {
      setSelectedDateRange([start, end])
      setShowDropdownMenu(false)
      setShowDatePicker(false)
      onSelectDates([start, end])
      onChangeRange?.('customRange')
    }
    setCustomDateRange(dates)
  }

  const DropdownMenuItems = () => (
    <div>
      <DropdownItemStyled
        active={selectedRangeType === 'yesterday'}
        eventKey="yesterday"
        onSelect={onSelectDateRangeHandler}
      >
        {t('datePicker.dateRange.yesterday')}
      </DropdownItemStyled>
      <DropdownItemStyled
        active={selectedRangeType === 'lastWeek'}
        eventKey="lastWeek"
        onSelect={onSelectDateRangeHandler}
      >
        {t('datePicker.dateRange.lastWeek')}
      </DropdownItemStyled>
      <DropdownItemStyled
        active={selectedRangeType === 'lastMonth'}
        eventKey="lastMonth"
        onSelect={onSelectDateRangeHandler}
      >
        {t('datePicker.dateRange.lastMonth')}
      </DropdownItemStyled>
      <DropdownItemStyled
        active={selectedRangeType === 'customRange'}
        eventKey="customRange"
        onSelect={onSelectCustomRangeHandler}
      >
        {t('datePicker.dateRange.customRange')}
      </DropdownItemStyled>
    </div>
  )

  return (
    <Dropdown
      onToggle={onToggleHandler}
      show={showDropdownMenu}
      as={CustomDropdown}
    >
      <Dropdown.Toggle as={DatePickerToggle} id="datepicker-dropdown" />
      <Dropdown.Menu as={CustomMenu}>
        {showDatePicker ? (
          <DropdownMenuContainer>
            <DropdownMenuItems />
            <DatePickerHelperContainer>
              <DatePickerHelper
                dates={customDateRange}
                onSelectDates={onSelectDatePickerDatesHandler}
              />
            </DatePickerHelperContainer>
          </DropdownMenuContainer>
        ) : (
          <DropdownMenuItems />
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const DropdownMenuContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

const DatePickerHelperContainer = styled.div({
  padding: '0 12px',
})

const CalendarIconStyled = styled(CalendarIcon)({
  width: '10%',
})

const DatePickerStyled = styled.div({
  padding: '2px 8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const DateRangeLabelStyled = styled.span({
  fontSize: 14,
})

const DropdownItemStyled = styled(Dropdown.Item)({
  fontSize: 14,
  ':active': {
    color: 'var(--gray-dark)',
    backgroundColor: 'var(--gray-light)',
  },
})

const CustomMenuStyled = styled.div({
  right: 0,
  left: 'auto',
  position: 'absolute',
  inset: '0px 0px auto auto',
  margin: 0,
  transform: 'translate(0px, 42px)',
})

interface DateRangeLabelProps {
  dates: DatePickerDates
}

const CustomDropdown = forwardRef<HTMLDivElement, ForwardRefCustomProps>(
  ({ children, className }, ref) => {
    return (
      <div ref={ref} className={className}>
        {children}
      </div>
    )
  }
)

const CustomMenu = forwardRef<HTMLDivElement, ForwardRefCustomProps>(
  ({ children, className }, ref) => {
    return (
      <CustomMenuStyled ref={ref} className={className}>
        {children}
      </CustomMenuStyled>
    )
  }
)

const DateRangeLabel = ({ dates }: DateRangeLabelProps) => {
  const format = 'LLLL d, yyyy'
  const [start, end] = dates

  return (
    <DateRangeLabelStyled>
      {start.toFormat(format)} - {end.toFormat(format)}
    </DateRangeLabelStyled>
  )
}

interface DatePickerHelperProps {
  dates: DatePickerDatesOrNull
  onSelectDates: (dates: DatePickerDatesOrNull) => void
}

const DatePickerHelper = ({ dates, onSelectDates }: DatePickerHelperProps) => {
  const [start, end] = dates
  const [startDate, setStartDate] = useState<Date | null>(
    start ? start.toJSDate() : new Date()
  )
  const [endDate, setEndDate] = useState<Date | null>(
    end ? end.toJSDate() : null
  )

  const onChange = (dates: [Date | null, Date | null]) => {
    let [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    const startDateTime = start ? DateTime.fromJSDate(start) : null
    const endDateTime = end ? DateTime.fromJSDate(end) : null
    onSelectDates([startDateTime, endDateTime])
  }

  return (
    <ReactDatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline
    />
  )
}

const getDateRangeDates = (
  rangeType?: DatePickerRange
): DatePickerDates | null => {
  let start
  let end

  const now = DateTime.now()

  switch (rangeType) {
    case 'yesterday':
      start = now.plus({ days: -1 })
      end = now.plus({ days: -1 })
      break
    case 'lastWeek':
      start = now.minus({ weeks: 1 }).startOf('week')
      end = now.minus({ weeks: 1 }).endOf('week')
      break
    case 'lastMonth':
      start = now.minus({ months: 1 }).startOf('month')
      end = now.minus({ months: 1 }).endOf('month')
      break
    default:
      return null
  }

  return [start, end]
}

export const useDatePickerDates = (
  rangeType?: DatePickerRange
): DatePickerDates | null => getDateRangeDates(rangeType)
