import React from 'react'

import AuthenticatedPage from 'pages/AuthenticatedPage'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useGetWeekFromDate } from 'utils/useGetWeekFromDate'
import { PlanPage } from 'pages/plan/PlanPage'
import { useGetPlan } from 'queries/useGetPlan'
import useGetLocationForSchedule from 'queries/useGetLocationForSchedule'
import { Loading } from 'components/Loading'
import { PlanQueryVariablesProvider } from 'pages/plan/planQueryVariablesContext'

export default () => {
  const {
    times: { startOfNextWeek },
  } = useCurrentLocation()

  const { startDate, endDate } = useGetWeekFromDate({
    defaultStartDate: startOfNextWeek,
  })

  const { data: plan, loading: planLoading } = useGetPlan(startDate, endDate)
  const {
    data: locationData,
    loading: locationLoading,
  } = useGetLocationForSchedule(startDate, endDate)

  const IsPastSubmitDate = () => {
    return false
  }

  const isLoading = !locationData || locationLoading || !plan || planLoading

  return (
    <AuthenticatedPage>
      {isLoading ? (
        <Loading />
      ) : (
        <PlanQueryVariablesProvider
          planQueryVariables={{
            locationId: locationData?.location.id!,
            startOn: startDate,
            endOn: endDate,
            databaseId: plan?.databaseId ?? plan.id,
          }}
        >
          <PlanPage
            startDate={startDate}
            endDate={endDate}
            isPastSubmitDate={IsPastSubmitDate()}
            plan={plan!}
            location={locationData?.location!}
          />
        </PlanQueryVariablesProvider>
      )}
    </AuthenticatedPage>
  )
}
