import {
  TimeOffRequestFragment,
  usePreviewViolationsQuery,
  ViolationFragment,
} from 'config/graphqlTypes'
import React, { FC } from 'react'
import {
  simpleDateFormat,
  simpleDateTimeFormat,
  simpleTimeFormat,
  formatLongFormDayOfWeek,
} from 'utils/daysAndTimes'
import {
  ShiftSwapDescriptionContainer,
  ShiftSwapDescriptionDetail,
  ViolationsIndicator,
  ViolationDetailsWrapper,
  ViolationDetails,
} from './styles'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { isViolationForTimeOff } from 'utils/getViolationDetails'
import { useTranslation } from 'react-i18next'
import { getUniqueViolations } from 'utils/getUniqueViolations'

interface Props {
  timeOffRequest: TimeOffRequestFragment
}

export const TimeOffDescriptionCell: FC<Props> = ({
  timeOffRequest: { employee, startAt, endAt, isAllDay },
}) => {
  const { t } = useTranslation()
  const dateFormatter = isAllDay ? simpleDateFormat : simpleDateTimeFormat
  const startAtLabel = dateFormatter(startAt)
  const endAtLabel = dateFormatter(endAt)
  const locationId = useCurrentLocationId()

  const { data } = usePreviewViolationsQuery({
    variables: {
      id: null,
      locationId: locationId,
      startAt: startAt,
      endAt: endAt,
      employeeId: employee?.id || '-1',
      shiftRoleId: null,
      shiftBreaks: [],
      isUnavailability: false,
    },
    fetchPolicy: 'cache-and-network',
  })
  const previewViolations = data?.previewViolations as ViolationFragment[]
  const timeOffViolations = getUniqueViolations(previewViolations).filter(
    isViolationForTimeOff
  )

  const hasViolations = !!timeOffViolations?.length
  const shifts = hasViolations ? timeOffViolations[0]['shifts'] : null
  const unique_shifts = shifts
    ? shifts!.filter((a, i) => shifts.findIndex(s => a.id === s.id) === i)
    : null

  const getViolationDetailString = (shift: any) => {
    let shiftDay = formatLongFormDayOfWeek(shift!.startAt)
    let shiftStartTime = simpleTimeFormat(shift!.startAt)
    let shiftEndTime = simpleTimeFormat(shift!.endAt)
    let shiftLocation = shift!.location.name

    return `on ${shiftDay} from ${shiftStartTime} - ${shiftEndTime} at ${shiftLocation}`
  }

  return (
    <ShiftSwapDescriptionContainer>
      <ShiftSwapDescriptionDetail>
        {hasViolations && (
          <div>
            <ViolationsIndicator data-testid="management-violations-badge">
              !
            </ViolationsIndicator>
          </div>
        )}
        <div>
          <>
            <span>{startAtLabel} - </span>
            {isAllDay || <br />}
            <span>{endAtLabel}</span>
          </>

          {hasViolations && (
            <ViolationDetailsWrapper>
              <ViolationDetails>
                {unique_shifts?.map((shift, index) => (
                  <ViolationDetails key={index}>
                    •{employee.name}{' '}
                    {t('management.requests.time_off_requesting')}{' '}
                    {getViolationDetailString(shift)}
                  </ViolationDetails>
                ))}
              </ViolationDetails>
            </ViolationDetailsWrapper>
          )}
        </div>
      </ShiftSwapDescriptionDetail>
    </ShiftSwapDescriptionContainer>
  )
}
