import React, { FC } from 'react'
import { range } from 'lodash'

import {
  StyledReportingTableRow,
  StyledBottomSpacingCell,
} from 'pages/reporting/styles'

export const BottomSpacingRow: FC = () => (
  <StyledReportingTableRow>
    {range(10).map(columnNum => (
      <StyledBottomSpacingCell key={columnNum} />
    ))}
  </StyledReportingTableRow>
)
