import {
  getGeocode as getGeocodesFromAPI,
  getLatLng,
  GeocodeResult,
} from 'use-places-autocomplete'

import { Address } from 'pages/covid/types'

interface AddressComponents {
  streetNumber: string | null
  route: string | null
  city: string | null
  region: string | null
  postalCode: string | null
  isoCountryCode: string | null
}

const areValidAddressComponents = ({
  streetNumber,
  route,
  city,
  region,
  postalCode,
  isoCountryCode,
}: AddressComponents) =>
  streetNumber && route && city && region && postalCode && isoCountryCode

const getAddressComponentsFromGeocode = (
  geocode: GeocodeResult
): AddressComponents => {
  const addressComponents = geocode['address_components']

  const findComponent = (component: string) =>
    addressComponents.find(c => c.types.includes(component))

  const streetNumber = findComponent('street_number')?.long_name ?? null
  const route = findComponent('route')?.long_name ?? null
  const city = (() => {
    const givenCity =
      findComponent('locality')?.long_name ??
      // New York city addresses have sublocality_level_1 but no locality.
      // See https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
      findComponent('sublocality_level_1')?.long_name ??
      null

    if (givenCity === 'The Bronx') {
      return 'Bronx'
    }

    if (givenCity === 'Queens') {
      return 'New York'
    }
    return givenCity
  })()
  const region = findComponent('administrative_area_level_1')?.long_name ?? null
  const postalCode = findComponent('postal_code')?.short_name ?? null
  const isoCountryCode = findComponent('country')?.short_name ?? null

  return {
    streetNumber,
    route,
    city,
    region,
    postalCode,
    isoCountryCode,
  }
}

// If we still cannot get a full address after our initial fallback behavior,
// provide a valid address by "filling in the blanks." This address can be
// submitted but will receive a more graceful "no data found" error message.
const getFinalFallbackAddressInfoForGeocodes = async (
  geocodes: GeocodeResult[],
  fullAddress: string
) => {
  for (const geocode of geocodes) {
    const incompleteAddressComponents = getAddressComponentsFromGeocode(geocode)

    const addressComponents = {
      streetNumber:
        incompleteAddressComponents?.streetNumber ??
        fullAddress.split(',')[0] ??
        ' ',
      route: incompleteAddressComponents?.route ?? ' ',
      city: incompleteAddressComponents?.city ?? ' ',
      region: incompleteAddressComponents?.region ?? ' ',
      postalCode: incompleteAddressComponents?.postalCode ?? ' ',
      isoCountryCode: incompleteAddressComponents?.isoCountryCode ?? 'us',
    }

    const latlng = await getLatLng(geocode)

    if (latlng) {
      return {
        ...addressComponents,
        latitude: latlng.lat,
        longitude: latlng.lng,
      }
    }
  }

  // Final fallback
  const firstCommaSplit = fullAddress.split(',')[0] ?? ' '

  return {
    streetNumber: firstCommaSplit.split(' ')[0] ?? ' ',
    route: firstCommaSplit.split(' ')[1] ?? ' ',
    city: fullAddress.split(',')[1] ?? ' ',
    region: fullAddress.split(',')[2] ?? ' ',
    postalCode: fullAddress.split(',')[3] ?? ' ',
    isoCountryCode: 'us',
    latitude: 38.897675,
    longitude: -77.036443,
  }
}

// Sometimes we cannot get a valid address for any geocodes. For example,
// a geocode may include a route but no street number. In this case, iterate
// through the geocodes again but this time get their Lat/Lng and gather a new
// list of geocodes for *that*.
const getFallbackAddressInfoForGeocodes = async (geocodes: GeocodeResult[]) => {
  for (const geocode of geocodes) {
    const latLng = await getLatLng(geocode)

    if (latLng) {
      const latLngGeocodes = await getGeocodesFromAPI({ location: latLng })

      for (const latLngGeocode of latLngGeocodes) {
        const addressComponents = getAddressComponentsFromGeocode(latLngGeocode)

        if (areValidAddressComponents(addressComponents)) {
          return {
            ...addressComponents,
            latitude: latLng.lat,
            longitude: latLng.lng,
          }
        }
      }
    }
  }

  return null
}

const getAddressInfoForGeocodes = async (geocodes: GeocodeResult[]) => {
  for (const geocode of geocodes) {
    const addressComponents = getAddressComponentsFromGeocode(geocode)

    if (areValidAddressComponents(addressComponents)) {
      const latlng = await getLatLng(geocode)

      if (latlng) {
        return {
          ...addressComponents,
          latitude: latlng.lat,
          longitude: latlng.lng,
        }
      }
    }
  }

  return null
}

const getAddressInfo = async (fullAddress: string) => {
  // Restrict to US addresses here but some international results still appear
  // to come in. Therfore, we have additional filtering of suggestions
  // downstream as well...
  const geocodes = await getGeocodesFromAPI({
    address: fullAddress,
    componentRestrictions: { country: 'us' },
  })

  const addressInfo = await getAddressInfoForGeocodes(geocodes)

  if (addressInfo) {
    return addressInfo
  }

  console.log(
    'Unable to find complete address. Falling back to proxy lookup via lat/lng...',
    { fullAddress }
  )

  const fallback = await getFallbackAddressInfoForGeocodes(geocodes)

  if (fallback) {
    return fallback
  }

  console.log(
    'Still unable to find complete address after fallback. Providing empty address.'
  )

  return await getFinalFallbackAddressInfoForGeocodes(geocodes, fullAddress)
}

export const getAddress = async (fullAddress: string) => {
  const {
    latitude,
    longitude,
    streetNumber,
    route,
    city,
    region,
    postalCode,
    isoCountryCode,
  } = await getAddressInfo(fullAddress)

  return {
    latitude,
    longitude,
    city,
    region,
    postalCode,
    isoCountryCode,
    fullAddress,
    streetAddress: `${streetNumber} ${route}`,
    key: streetNumber! + route! + city,
  } as Address
}
