import React, { createContext, ReactNode, useContext } from 'react'
import {
  PlanFragment,
  TimeOffBlockedDateFragment,
} from '../../config/graphqlTypes'
import { Interval } from 'luxon'
import {
  DraggedShiftFragment,
  useCreateShift,
  useUpdateShift,
} from './scheduling/dragAndDropShiftMutations'

export type LaborDollars = {
  total: number
  daily: { [day: string]: number }
} | null

type TimeOffBlockedDateType = Omit<
  TimeOffBlockedDateFragment,
  'locations' | 'name' | 'timeOffBlocked'
>

type ContextProps = {
  plan: PlanFragment
  isScheduleByRole: boolean
  locationClosedDaysIndexes: number[]
  laborDollars: LaborDollars
  getTimeOffBlockedDateByDay: (
    day: Interval
  ) => TimeOffBlockedDateType | undefined
  overlapWithLocationClosedDays: (day: Interval) => boolean
  createDraggedShift: (shift: DraggedShiftFragment) => void
  updateDraggedShift: (shift: DraggedShiftFragment & { id: string }) => void
}

type Props = {
  plan: PlanFragment
  isScheduleByRole: boolean
  days: Interval[]
  children: ReactNode
  laborDollars: LaborDollars
}

const PlanPageContext = createContext<ContextProps>({
  plan: null as any,
  laborDollars: null as any,
  isScheduleByRole: true,
  locationClosedDaysIndexes: [],
  getTimeOffBlockedDateByDay: () => {
    throw new Error(
      'getTimeOffBlockedDateByDay not implemented! Are you inside PlanPageContext Provider?'
    )
  },
  overlapWithLocationClosedDays: () => {
    throw new Error(
      'overlapWithLocationClosedDays not implemented! Are you inside PlanPageContext Provider?'
    )
  },
  createDraggedShift: () => {
    throw new Error(
      'createDraggedShift not implemented! Are you inside PlanPageContext Provider?'
    )
  },
  updateDraggedShift: () => {
    throw new Error(
      'updateDraggedShift not implemented! Are you inside PlanPageContext Provider?'
    )
  },
})

export const PlanPageProvider = ({
  plan,
  isScheduleByRole,
  days,
  laborDollars,
  children,
}: Props) => {
  const createDraggedShift = useCreateShift(plan, isScheduleByRole)
  const updateDraggedShift = useUpdateShift(plan, isScheduleByRole)
  const locationClosedDaysIndexes = getLocationClosedDaysIndexes(plan, days)

  const handleOverlapWithLocationClosedDays = (day: Interval) =>
    isOverlapping(plan, day)
  const handleTimeOffBlockedDateByDay = (day: Interval) =>
    getTimeOffBlockedDateByDay(plan, day)

  return (
    <PlanPageContext.Provider
      value={{
        plan,
        laborDollars,
        isScheduleByRole,
        locationClosedDaysIndexes,
        getTimeOffBlockedDateByDay: handleTimeOffBlockedDateByDay,
        overlapWithLocationClosedDays: handleOverlapWithLocationClosedDays,
        createDraggedShift,
        updateDraggedShift,
      }}
    >
      {plan ? children : null}
    </PlanPageContext.Provider>
  )
}

const isOverlapping = (plan: PlanFragment, day: Interval) => {
  if (!plan || !day) return false
  return plan.locationClosedDates.some(
    item =>
      item.locationClosed &&
      day.overlaps(
        Interval.fromDateTimes(
          item.startAt.startOf('day'),
          item.endAt.endOf('day')
        )
      )
  )
}

const getTimeOffBlockedDateByDay = (plan: PlanFragment, day: Interval) => {
  return plan.locationClosedDates.find(
    item =>
      item.locationClosed &&
      day.overlaps(
        Interval.fromDateTimes(
          item.startAt.startOf('day'),
          item.endAt.endOf('day')
        )
      )
  )
}

const getLocationClosedDaysIndexes = (plan: PlanFragment, days: Interval[]) => {
  const indexes: number[] = []
  if (!plan || !days) return indexes
  for (let i = 0; i < days.length; i++) {
    const day = days[i]
    if (
      plan.locationClosedDates.some(
        item =>
          item.locationClosed &&
          day.overlaps(
            Interval.fromDateTimes(
              item.startAt.startOf('day'),
              item.endAt.endOf('day')
            )
          )
      )
    ) {
      indexes.push(i + 2)
    }
  }

  return indexes
}

export const usePlanPageContext = () => useContext(PlanPageContext)
