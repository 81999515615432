import React, { FormEvent, forwardRef, useState } from 'react'
import styled from 'styled-components'
import ReactDatePicker from 'react-datepicker'
import SelectDropdown, { SelectDropdownOption } from 'components/SelectDropdown'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { MultiValue } from 'react-select'
import {
  TimeOffBlockedDateForFormFragment,
  useDeleteTimeOffBlockedDateMutation,
  useGetTimeOffBlockedDateQuery,
  useGetTimeOffBlockedDatesQuery,
  useUpsertLocationTimeOffBlockedDatesMutation,
  useUpsertTimeOffBlockedDateMutation,
} from 'config/graphqlTypes'
import {
  ButtonsWrapper,
  CancelButton,
  FormWrapper,
  ManagementSectionDivider,
} from '../../../styles'
import Form from 'react-bootstrap/Form'
import { FormGroup, StringInputGroup } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { Spinner } from 'react-bootstrap'
import {
  baseInputStyle,
  largeBodyTextStyle,
} from '../../../../../config/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useLocationNavigation } from '../../../../Routes'
import { ReactComponent as DateIcon } from '../../../../../assets/images/date-icon.svg'
import BootstrapForm from 'react-bootstrap/Form'
import { DateTime } from 'luxon'
import { toast } from 'react-toastify'
import { differenceBy } from 'lodash'
import { ReactComponent as TrashIcon } from '../../../../../assets/images/trash.svg'
import { useTranslation } from 'react-i18next'
import SettingsSection from '../../SettingsSection'
import { useFeatureToggleEnabled } from '../../../../../contexts/FeatureToggles'
import {
  useGetPlanLazyQuery,
  useGetShiftsPerLocationsLazyQuery,
} from '../../../../../config/graphqlTypesRaw'
import { useCurrentLocation } from '../../../../../queries/useCurrentLocation'
import ClosedDaysConfirmationDialog from './ClosedDaysConfirmationDialog'

const TRANSLATE_PREFIX = 'management.settings.organizations.blockedDates.form'

interface Props {
  data: TimeOffBlockedDateForFormFragment | undefined | null
}

export const AddEditTimeOffBlockedDateForm = ({ data }: Props) => {
  const { organization_id: organizationId } = useParams<{
    organization_id: string
  }>()
  const history = useHistory()
  const { t } = useTranslation()
  const { locationPath } = useLocationNavigation()
  const { locations } = useLoggedInUserOrRedirect()
  const locationOptions = locations
    .filter(location => location.organization.id === organizationId)
    .map(location => ({
      label: location.name,
      value: location.id,
    }))

  const [
    upsertTimeOffBlockedDate,
    { loading: upsertBlockedDateLoading },
  ] = useUpsertTimeOffBlockedDateMutation()
  const [
    upsertLocationTimeOffBlockedDate,
    { loading: upsertLocationBlockedDateLoading },
  ] = useUpsertLocationTimeOffBlockedDatesMutation()
  const [
    deleteTimeOffBlockedDate,
    { loading: deleteBlockedDateLoading },
  ] = useDeleteTimeOffBlockedDateMutation()

  const {
    refetch: refetchBlockedDate,
    loading: loadingBlockedDate,
  } = useGetTimeOffBlockedDateQuery({
    variables: { id: data?.id! },
    skip: true,
  })

  const {
    refetch: refetchBlockedDates,
    loading: loadingBlockedDates,
  } = useGetTimeOffBlockedDatesQuery({
    variables: { organizationId },
    skip: true,
  })

  const {
    location: { id: locationId },
  } = useCurrentLocation()

  const [, { refetch }] = useGetPlanLazyQuery()
  const [
    getShifts,
    { loading: loadingShifts },
  ] = useGetShiftsPerLocationsLazyQuery()

  const closedDaysToggleEnabled = useFeatureToggleEnabled(
    'capturingLocationClosedDays'
  )

  const [timeOffBlockedDate, setTimeOffBlockedDate] = useState(
    data ?? {
      name: '',
      startAt: null,
      endAt: null,
      locationClosed: false,
      timeOffBlocked: true,
    }
  )

  const isEdit = !!data?.id

  const {
    name,
    startAt,
    endAt,
    locationClosed,
    timeOffBlocked,
  } = timeOffBlockedDate

  const [startDate, setStartDate] = useState<Date | null>(
    startAt ? startAt.toJSDate() : null
  )
  const [endDate, setEndDate] = useState<Date | null>(
    endAt ? endAt.toJSDate() : null
  )
  const [locationClosedToggle, setLocationClosedToggle] = useState(
    locationClosed
  )
  const [timeOffBlockedToggle, setTimeOffBlockedToggle] = useState(
    timeOffBlocked
  )
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const initialSelectedLocations = data?.locationTimeOffBlockedDates.length
    ? locationOptions.filter(option =>
        data?.locationTimeOffBlockedDates.find(
          l => l.locationId === option.value
        )
      )
    : []

  const [selectedLocationOptions, setSelectedLocationOptions] = useState<
    MultiValue<SelectDropdownOption | null>
  >(initialSelectedLocations)

  const handleChangeDate = (dates: [Date, Date]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleOnBlur = () => {
    if (startDate && !endDate) {
      setEndDate(startDate)
    }
  }

  const displayToast = (msg: string, type: 'success' | 'error') =>
    toast[type](msg, {
      toastId: type,
      position: toast.POSITION.BOTTOM_LEFT,
    })

  const getDifferenceBy = () =>
    differenceBy(
      data?.locationTimeOffBlockedDates.map(l => ({ id: l?.locationId })),
      selectedLocationOptions.map(l => ({ id: l?.value })),
      'id'
    ).map(l => l.id)

  const getStartOnAndEndOn = (startAt: DateTime) => {
    const startOn = startAt.startOf('week').toFormat('yyyy-MM-dd')
    const endOn = startAt.endOf('week').toFormat('yyyy-MM-dd')
    return { startOn, endOn }
  }

  const refetchPlan = async (startAt: DateTime) => {
    if (!closedDaysToggleEnabled) return
    const { startOn, endOn } = getStartOnAndEndOn(startAt)

    await refetch({
      locationId,
      startOn,
      endOn,
    })
  }

  const getFormattedDate = (date: Date, format = 'M/d/yyyy') =>
    DateTime.fromFormat(date.toLocaleDateString('en-US'), format)

  const confirmSubmit = async () => {
    const handleError = () =>
      displayToast(
        t(
          `${TRANSLATE_PREFIX}.operationErrorMessages.${
            isEdit ? 'update' : 'add'
          }`
        ),
        'error'
      )

    setShowConfirmationDialog(false)

    try {
      const startAt = getFormattedDate(startDate!)
      const endAt = getFormattedDate(endDate!)

      const blockedDateRes = await upsertTimeOffBlockedDate({
        variables: {
          input: {
            id: data?.id ?? null,
            organizationId,
            name,
            startAt,
            endAt,
            timeOffBlocked: closedDaysToggleEnabled
              ? timeOffBlockedToggle
              : true,
            locationClosed: closedDaysToggleEnabled
              ? locationClosedToggle
              : false,
          },
        },
      })

      const blockedDateSuccess =
        blockedDateRes.data?.upsertTimeOffBlockedDate.success
      const blockedDateId =
        blockedDateRes.data?.upsertTimeOffBlockedDate.blockedDate?.id

      const locationBlockedDateRes = await upsertLocationTimeOffBlockedDate({
        variables: {
          input: {
            timeOffBlockedDateId: blockedDateId!,
            locationIdsToAdd: selectedLocationOptions.map(l => l?.value!),
            locationIdsToRemove: isEdit ? getDifferenceBy() : [],
          },
        },
      })

      const locationBlockedDateSuccess =
        locationBlockedDateRes.data?.upsertLocationTimeOffBlockedDates.success

      if (blockedDateSuccess && locationBlockedDateSuccess) {
        displayToast(
          t(
            `${TRANSLATE_PREFIX}.operationSuccessMessages.${
              isEdit ? 'update' : 'add'
            }`
          ),
          'success'
        )
        isEdit && (await refetchBlockedDate())
        await refetchBlockedDates()
        goToTimeOffBlockedDatesTab()
        await refetchPlan(startAt)
      } else {
        handleError()
      }
    } catch (error) {
      handleError()
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!locationClosedToggle) return await confirmSubmit()

    const shiftsRes = await getShifts({
      variables: {
        locationIds: selectedLocationOptions.map(l => l?.value!),
        startAt: getFormattedDate(startDate!).toFormat('yyyy-MM-dd'),
        endAt: getFormattedDate(endDate!).toFormat('yyyy-MM-dd'),
      },
      fetchPolicy: 'no-cache',
    })

    const shifts = shiftsRes.data?.shiftsPerLocations

    if (shifts?.length) setShowConfirmationDialog(true)
    else await confirmSubmit()
  }

  const handleCancel = () => goToTimeOffBlockedDatesTab()

  const handleDelete = async () => {
    const handleError = () =>
      displayToast(
        t(`${TRANSLATE_PREFIX}.operationErrorMessages.delete`),
        'error'
      )

    try {
      const res = await deleteTimeOffBlockedDate({
        variables: { input: { id: data?.id! } },
      })

      const success = res.data?.deleteTimeOffBlockedDate.success

      if (success) {
        displayToast(
          t(`${TRANSLATE_PREFIX}.operationSuccessMessages.delete`),
          'success'
        )
        await refetchBlockedDates()
        goToTimeOffBlockedDatesTab()
        await refetchPlan(DateTime.fromJSDate(startDate!))
      } else {
        handleError()
      }
    } catch (error) {
      handleError()
    }
  }

  const handleLocationClosedToggle = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked
    setLocationClosedToggle(checked)
    checked && setTimeOffBlockedToggle(true)
  }

  const goToTimeOffBlockedDatesTab = () =>
    history.push({
      pathname: `${locationPath}/management/organizations/${organizationId}`,
      state: {
        isBlockedDatesTabActive: true,
      },
    })

  const today = DateTime.now().toJSDate()

  const invalidForm =
    !name?.trim() ||
    !startDate ||
    !endDate ||
    (locationOptions.length && !selectedLocationOptions.length)

  const isBusy =
    upsertBlockedDateLoading ||
    upsertLocationBlockedDateLoading ||
    deleteBlockedDateLoading ||
    loadingBlockedDate ||
    loadingBlockedDates ||
    loadingShifts

  const hideSpinner =
    !upsertBlockedDateLoading &&
    !upsertLocationBlockedDateLoading &&
    !loadingBlockedDates &&
    !loadingBlockedDate

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        <StringInputGroup
          labelProps={{
            label: t(`${TRANSLATE_PREFIX}.name`),
          }}
          controlProps={{
            required: true,
            placeholder: t(`${TRANSLATE_PREFIX}.namePlaceholder`),
            value: name,
            onChange: e =>
              setTimeOffBlockedDate(current => ({
                ...current,
                name: e.target.value,
              })),
          }}
        />
        <FormGroup
          testId="timeOffBlockedDateTestId"
          labelProps={{
            label: t(`${TRANSLATE_PREFIX}.date`),
          }}
        >
          <DatePickerContainer>
            <ReactDatePicker
              isClearable
              selectsRange
              minDate={today}
              dateFormat={'MMM dd, yyyy'}
              placeholderText={t(`${TRANSLATE_PREFIX}.datePlaceholder`)}
              customInput={<CustomInput />}
              onChange={handleChangeDate}
              startDate={startDate}
              endDate={endDate}
              onBlur={handleOnBlur}
            />
          </DatePickerContainer>
        </FormGroup>
        <FormGroup
          testId="timeOffBlockedLocationTestId"
          labelProps={{
            label: t(`${TRANSLATE_PREFIX}.location`),
          }}
        >
          <SelectDropdown
            isMulti
            allowSelectAll
            options={locationOptions}
            controlStyles={controlCustomStyles}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            placeholder={t(`${TRANSLATE_PREFIX}.locationPlaceholder`)}
            selectAllOptionTagLabel={t(`${TRANSLATE_PREFIX}.locationAll`)}
            selectAllOptionLabel={t(`${TRANSLATE_PREFIX}.locationSelectAll`)}
            onChange={setSelectedLocationOptions}
            value={selectedLocationOptions}
          />
        </FormGroup>
        {closedDaysToggleEnabled && (
          <BusinessEditSection>
            <SettingsSection
              noControlsGroup
              descriptionStyle={{ width: 650 }}
              title={t(`${TRANSLATE_PREFIX}.locationClosed.title`)}
              description={t(`${TRANSLATE_PREFIX}.locationClosed.description`)}
              innerSection={
                <>
                  <ManagementSectionDivider />
                  <SettingsSection
                    borderless
                    noControlsGroup
                    title={t(`${TRANSLATE_PREFIX}.timeOffBlocked.title`)}
                    description={t(
                      `${TRANSLATE_PREFIX}.timeOffBlocked.description`
                    )}
                  >
                    <Form.Check
                      type="switch"
                      id="timeOffBlockedSwitch"
                      checked={timeOffBlockedToggle}
                      disabled={locationClosedToggle}
                      onChange={e => setTimeOffBlockedToggle(e.target.checked)}
                    />
                  </SettingsSection>
                </>
              }
            >
              <Form.Check
                type="switch"
                id="locationClosedSwitch"
                checked={locationClosedToggle}
                onChange={handleLocationClosedToggle}
              />
            </SettingsSection>
          </BusinessEditSection>
        )}
        <ActionButtons>
          <Button
            onClick={handleCancel}
            block
            disabled={isBusy}
            size="lg"
            type="button"
            variant="secondary"
            as={CancelButton}
          >
            {t(`${TRANSLATE_PREFIX}.cancel`)}
          </Button>
          <Button
            block
            disabled={invalidForm || isBusy}
            size="lg"
            type="submit"
            variant="blue"
            onClick={handleSubmit}
          >
            <Spinner animation={'border'} size={'sm'} hidden={hideSpinner} />{' '}
            {t(`${TRANSLATE_PREFIX}.save`)}
          </Button>
        </ActionButtons>
        {isEdit && (
          <DeleteButton type="button" onClick={handleDelete} disabled={isBusy}>
            <DeleteBtnContainer>
              <TrashIcon />
              {t(`${TRANSLATE_PREFIX}.delete`)}
            </DeleteBtnContainer>
          </DeleteButton>
        )}
      </Form>
      <ClosedDaysConfirmationDialog
        onConfirm={confirmSubmit}
        show={showConfirmationDialog}
        onHide={() => setShowConfirmationDialog(false)}
      />
    </FormWrapper>
  )
}

const ActionButtons = styled(ButtonsWrapper)({
  paddingTop: 18,
})

const DeleteButton = styled.button<{ disabled: boolean }>`
  margin-top: 32px;
  background: none;
  color: ${({ disabled }) => (disabled ? 'var(--gray)' : 'var(--bittersweet)')};
  border: none;
  padding: 0;
  font: inherit;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: inherit;
  &:hover {
    color: ${({ disabled }) =>
      disabled ? 'var(--gray)' : 'var(--bittersweet2)'};
  }

  path {
    stroke: ${({ disabled }) =>
      disabled ? 'var(--gray)' : 'var(--bittersweet)'};

    &:hover {
      stroke: ${({ disabled }) =>
        disabled ? 'var(--gray)' : 'var(--bittersweet2)'};
    }
  }
`

const DeleteBtnContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`

const controlCustomStyles = {
  ...largeBodyTextStyle,
  border: '1px solid',
  borderColor: '#bbc1ff',
  boxShadow: '0 0 0 0.2rem rgba(59, 79, 255, 0.25)',
  borderRadius: 25,
  color: 'var(--gray5)',
  paddingLeft: 13,
}

const DatePickerInput = styled(BootstrapForm.Control)({
  ...baseInputStyle,
  paddingLeft: 50,
})

const CustomInput = forwardRef((props: any, ref) => (
  <DatePickerInputWrapper>
    <DatePickerIcon />
    <DatePickerInput {...props} ref={ref} />
  </DatePickerInputWrapper>
))

const DatePickerContainer = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
  }
`

const DatePickerInputWrapper = styled.div`
  position: relative;
`

const DatePickerIcon = styled(DateIcon)`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  left: 23px;
  transform: translateY(-50%);
`

const BusinessEditSection = styled.div`
  margin-bottom: 20px;
  margin-top: 2rem;
`
