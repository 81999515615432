import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime, Interval } from 'luxon'

import {
  NUM_EVENT_ROWS,
  useDailyLocationEvents,
} from 'queries/useDailyLocationEvents'
import { DailyDetailsPlanRow } from 'pages/plan/rows/PlanRow'

interface Props {
  days: Interval[]
  endDate: DateTime
  startDate: DateTime
}

export const ExternalEventsRow: FC<Props> = ({ days, endDate, startDate }) => {
  const { t } = useTranslation()

  const dailyEvents = useDailyLocationEvents(startDate, endDate)

  const getDailyDetails = (day: Interval) => {
    const events = dailyEvents
      .filter(e => e.startAt.toISODate() === day.start.toISODate())
      .slice(0, NUM_EVENT_ROWS)

    return events.map(event => [event.timeOrHoliday, event.name])
  }

  return (
    <DailyDetailsPlanRow
      days={days}
      title={t('planning.externalEvents')}
      getDailyDetails={getDailyDetails}
      noDetailsMsg={t('events.unavailable')}
    />
  )
}
