import React, { FC } from 'react'
import {
  ExportScheduleOptionsSelect,
  SelectMultiOrSingleValueDropdown,
} from './ExportScheduleOptionsSelect'
import { useTranslation } from 'react-i18next'
import { SelectDropdownOption } from '../../../../components/SelectDropdown'

interface Props {
  employees: SelectDropdownOption[]
  onSelectEmployees: (employees: SelectMultiOrSingleValueDropdown) => void
}

export const ExportScheduleEmployeeSelect: FC<Props> = ({
  employees,
  onSelectEmployees,
}) => {
  const { t } = useTranslation()

  return (
    <ExportScheduleOptionsSelect
      label={t('planning.exportScheduleModal.exportOptions.employee.label')}
      onSelectOptions={onSelectEmployees}
      options={employees}
      selectAllOptionLabel={t(
        'planning.exportScheduleModal.exportOptions.employee.selectAllEmployees'
      )}
      testId={'exportScheduleEmployeeSelect'}
    />
  )
}
