import React from 'react'
import styled from 'styled-components'
import { FC } from 'react'
import { flowMax, addDisplayName } from 'ad-hok'

import exclamationPointIcon from 'assets/images/exclamation-point-blue.svg'
import addTranslationHelpers from 'utils/addTranslationHelpers'
import Button from 'components/Button'

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 100,
})

const StyledHeader = styled.h1({
  fontSize: '2rem',
  fontWeight: 900,
  color: 'var(--blue)',
  marginTop: '0.25rem',
})

const StyledParagraph = styled.p({
  fontSize: '1.375rem',
  lineHeight: '1.875rem',
  color: 'var(--gray1)',
  marginBottom: 0,
})

const StyledButton = styled(Button)({
  minWidth: 160,
  marginTop: '1.375rem',
})

interface Props {
  className?: string
}

export const ErrorPage: FC<Props> = flowMax(
  addDisplayName('ErrorPage'),
  addTranslationHelpers,
  ({ className, t }) => (
    <StyledContainer className={className}>
      <img src={exclamationPointIcon} alt="" />
      <StyledHeader>{t('errorPage.header')}</StyledHeader>
      <StyledParagraph>{t('errorPage.issueLoading')}</StyledParagraph>
      <StyledParagraph>{t('errorPage.tryReloading')}</StyledParagraph>
      <StyledButton onClick={() => window.location.reload()}>
        {t('errorPage.reload')}
      </StyledButton>
    </StyledContainer>
  )
)
