import React, { useRef, FC, useState } from 'react'
import {
  EmployeeFragment,
  ShiftRoleFragment,
  ShiftFragment,
  ActiveLocationShiftRoleFragment,
} from 'config/graphqlTypes'
import { ShiftPill } from 'pages/plan/dailyViewModal/schedule/ShiftPill'
import {
  getShiftSegments,
  ShiftSegment,
} from 'pages/plan/dailyViewModal/schedule/getShiftSegments'
import { TimelineHelpers } from 'pages/plan/dailyViewModal/schedule/getGraphTimelineHelpers'
import { EmployeeScheduleContainer } from 'pages/plan/dailyViewModal/schedule/styles'
import { useShiftPillDrop } from 'pages/plan/dailyViewModal/schedule/shiftPillDragDrop'
import { ShiftPillDragType } from 'pages/plan/dailyViewModal/schedule/DragPill'
import {
  ShiftPillDropPreview,
  Props as DropPreviewProps,
} from 'pages/plan/dailyViewModal/schedule/ShiftPillDropPreview'
import { ShiftModal } from 'pages/plan/scheduling/ShiftModal'
import { useShiftModal } from 'pages/plan/scheduling/useShiftModal'
import { Interval } from 'luxon'

interface Props {
  day: Interval
  days: Interval[]
  employee: EmployeeFragment
  role: ShiftRoleFragment
  roleShifts: ShiftFragment[]
  otherShifts: ShiftFragment[]
  VerticalLines: JSX.Element
  timelineHelpers: TimelineHelpers
  isEditable: boolean
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export const EmployeeSchedule: FC<Props> = ({
  day,
  days,
  employee,
  role,
  roleShifts,
  otherShifts,
  VerticalLines,
  timelineHelpers,
  isEditable,
  activeLocationShiftRoles,
}) => {
  const [
    dropPreviewProps,
    setDropPreviewProps,
  ] = useState<DropPreviewProps | null>(null)
  const onHoverShiftSegment = (
    shiftSegment: ShiftSegment,
    xOffsetRaw: number,
    dragType: ShiftPillDragType
  ) => {
    // Since we're snapping to the 15 minute grid, we only want to update
    // the drop preview if the grid position has actually changed. This
    // makes the drag much more smooth
    const xOffset = timelineHelpers.roundOffsetToFifteen(xOffsetRaw)
    if (xOffset !== dropPreviewProps?.xOffset) {
      setDropPreviewProps({
        shiftSegment,
        xOffset,
        dragType,
        timelineHelpers,
      })
    }
  }

  const pillRefs = useRef<HTMLElement[]>([])
  const shiftSegments = getShiftSegments(roleShifts, otherShifts)

  const [{ isHovering }, dropRef] = useShiftPillDrop(
    timelineHelpers,
    employee,
    role,
    onHoverShiftSegment
  )

  if (!isHovering && dropPreviewProps) {
    // The timeout here is a hack to prevent a flicker. It would be better to explicitly wait for
    // the request to complete before hiding the drop preview, but it would require more complicated
    // plumbing. This accomplishes 90% the same with much less fuss.
    setTimeout(() => setDropPreviewProps(null), 10)
  }

  // Do not pass the list of pills to each child until they all have mounted
  const pills =
    pillRefs.current.length >= shiftSegments.length ? pillRefs.current : []

  const key = `${employee.name}-${role.name}`

  const createSegmentKey = (
    { other, conflict, labels }: ShiftSegment,
    i: number
  ) => {
    const type = (other && 'other') || (conflict && 'conflict') || 'shift'
    return `${key}-${type}-${labels.toString()}-${i}`
  }

  const {
    selectedShift,
    onClickShift,
    onHideShiftModal,
    showShiftModal,
  } = useShiftModal()

  return (
    <>
      <EmployeeScheduleContainer ref={dropRef} key={key}>
        {dropPreviewProps && <ShiftPillDropPreview {...dropPreviewProps} />}
        {shiftSegments.map((shiftSegment, i) => (
          <ShiftPill
            key={createSegmentKey(shiftSegment, i)}
            isEditable={isEditable}
            draggingShiftId={dropPreviewProps?.shiftSegment.shift?.id}
            shiftSegment={shiftSegment}
            timelineHelpers={timelineHelpers}
            pills={pills}
            onClick={() => onClickShift(shiftSegment.shift as ShiftFragment)}
            appendPillRef={(ref: HTMLElement | null) => {
              if (!ref) return
              pillRefs.current[i] = ref
            }}
          />
        ))}
        {VerticalLines}
      </EmployeeScheduleContainer>
      {showShiftModal && (
        <ShiftModal
          show={showShiftModal}
          onHide={onHideShiftModal}
          selectedDate={day.start}
          selectedShift={selectedShift}
          days={days}
          hideDateSelect
          activeLocationShiftRoles={activeLocationShiftRoles}
        />
      )}
    </>
  )
}
