import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Tooltip from 'react-bootstrap/Tooltip'

import { tableLabelBoldTextStyle } from 'config/styles'
import { H2Text, LargeBodyBoldText, LargeBodyText } from 'components/Text'
import Button from 'components/Button'

export const DashboardFooterContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  width: '100vw',
  bottom: 0,
  left: 0,
  backgroundColor: 'var(--gray7)',
  height: 130,
})

export const CopyMyLinkButton = styled(Button)({
  color: 'var(--blue)',
  backgroundColor: 'transparent',
  width: 191,
  borderWidth: 2,
})

export const EmailALinkButton = styled(Button)({
  width: 250,
})

export const EmailALinkLabel = styled(LargeBodyBoldText)({
  color: 'var(--gray3)',
  marginBottom: 10,
})

export const DashboardModalContainer = styled(Modal)({
  filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
  '@media only screen and (max-width: 768px)': {
    width: '100vw',
  },
})

export const DashboardModalIcon = styled.img({
  height: 127,
  width: 127,
  marginBottom: 20,
  marginTop: -15,
})

export const DashboardModalHeader = styled(Modal.Header)({
  borderTop: 'solid 5px var(--blue)',
  borderBottom: 0,
  button: {
    padding: '.6rem 1rem !important',
  },
})

export const DashboardModalBody = styled(Modal.Body)({
  textAlign: 'center',
  alignItems: 'center',
  padding: '0 40px 50px 40px',
  '@media only screen and (max-width: 768px)': {
    padding: '0 25px 40px 25px',
  },
})

export const DashboardModalTitle = styled(H2Text)({
  marginBottom: 12,
})

export const DashboardModalDetail = styled(LargeBodyText)({
  color: 'var(--gray2)',
})

export const DashboardModalOtherContent = styled.div({
  marginTop: 20,
})

export const SaveThisViewFormWrapper = styled.div({
  label: {
    ...tableLabelBoldTextStyle,
    color: 'var(--gray4)',
    textAlign: 'left',
    width: '87%',
  },
})

export const EmailMyDashboardButton = styled(Button)({
  marginTop: 10,
  width: '100%',
})

export const CopyToClipboardTooltip = styled(Tooltip)({
  bottom: '2px !important',
})
