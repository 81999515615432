import { DateTime, Interval } from 'luxon'
import { useGetOperatingHoursData } from '../../queries/useGetOperatingHours'
import { OperatingHoursForDateFragment } from '../../config/graphqlTypes'
import { operatingHoursForDay } from '../../utils/operatingHoursForDay'
import { useCurrentLocation } from '../../queries/useCurrentLocation'
import { useEffect } from 'react'

const useGetOperatingHoursForDate = (date: DateTime) => {
  const { data, loading, refetch } = useGetOperatingHoursData({
    startOn: date,
    endOn: date,
  })
  const getClosingHour = (operatingHours: OperatingHoursForDateFragment[]) => {
    const [openAt, closeAt] = operatingHoursForDay(operatingHours)

    return { openAt, closeAt }
  }

  useEffect(() => {
    //this is needed to reset the cache on initial load
    refetch()
  }, [refetch])

  if (loading) {
    return null
  }

  //no idea why mush added this, it makes no sense to give a location default operating hours when they have none, this is dumb
  const getDefaultOpenAndCloseDate = () => {
    return {
      openAt: date,
      closeAt: date,
    }
  }

  return data?.operatingHoursForDate?.length
    ? getClosingHour(data?.operatingHoursForDate)
    : getDefaultOpenAndCloseDate()
}

export const useIsClosedForDate = (date: DateTime) => {
  const { data, loading } = useGetOperatingHoursData({
    startOn: date,
    endOn: date,
  })

  if (loading) {
    return null
  }

  return !data?.operatingHoursForDate?.length
}

export const useGetOperatingHoursTracking = () => {
  const currentTime = useCurrentLocation().times.localTime
  const today = currentTime.startOf('day')
  const yesterday = today.minus({ days: 1 })

  const operatingHoursToday = useGetOperatingHoursForDate(today)
  const operatingHoursYesterday = useGetOperatingHoursForDate(yesterday)

  if (!operatingHoursToday || !operatingHoursYesterday) {
    return null
  }

  const currentDayInterval = Interval.fromDateTimes(
    operatingHoursToday.openAt!,
    operatingHoursToday.closeAt!
  )

  const previousDayInterval = Interval.fromDateTimes(
    operatingHoursYesterday.openAt!,
    operatingHoursYesterday.closeAt!
  )

  return {
    currentDayInterval,
    previousDayInterval,
  }
}
