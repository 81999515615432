import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

import { getColorForTrend } from 'pages/covid/dashboard/addressRow/getColorForTrend'
import { Sentiment } from 'pages/covid/types'
import { CovidBarChartWrapper } from 'pages/covid/dashboard/addressRow/styles'

export interface Datum {
  value: number
  key: string
}

interface Props {
  data: Datum[]
  sentiment: Sentiment
}

export const CovidBarChart = ({ data, sentiment }: Props) => (
  <CovidBarChartWrapper>
    <ResponsiveBar
      data={data}
      keys={['value']}
      indexBy="key"
      margin={{ top: 0, right: -10, bottom: 0, left: -10 }}
      padding={0.45}
      colors={getColorForTrend(sentiment)}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      isInteractive={false}
      enableLabel={false}
      enableGridY={false}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  </CovidBarChartWrapper>
)
