import React, { ReactElement } from 'react'
import { flowMax, branch, returns, renderNothing } from 'ad-hok'
import { some } from 'lodash/fp'
import { ApolloError } from '@apollo/client'

import { Loading } from 'components/Loading'

const DATA_LOADING_COLD = 'loadingCold'
const DATA_LOADING = 'loading'
const DATA_LOADED = 'data'
const DATA_ERROR_COLD = 'errorCold'
const DATA_ERROR = 'error'
type DataLoadingCold = {
  dataLoadingState: 'loadingCold'
}
type DataLoading<TData> = {
  dataLoadingState: 'loading'
  data: TData
}
type DataLoaded<TData> = {
  dataLoadingState: 'data'
  data: TData
}
export type DataErrorCold = {
  dataLoadingState: 'errorCold'
  error: ApolloError
}
type DataError<TData> = {
  dataLoadingState: 'error'
  error: ApolloError
  data: TData
}

export type DataLoadingStateDataPresent<TData> =
  | DataLoading<TData>
  | DataLoaded<TData>
  | DataError<TData>
export type DataLoadingStateNoColdError<TData> =
  | DataLoadingStateDataPresent<TData>
  | DataLoadingCold
export type DataLoadingState<TData> =
  | DataLoadingStateNoColdError<TData>
  | DataErrorCold

type MakeDataLoadedType = <TData>(data: TData) => DataLoaded<TData>

export const makeDataLoaded: MakeDataLoadedType = data => ({
  dataLoadingState: DATA_LOADED,
  data,
})

type MakeDataLoadingColdType = () => DataLoadingCold

export const makeDataLoadingCold: MakeDataLoadingColdType = () => ({
  dataLoadingState: DATA_LOADING_COLD,
})

type MakeDataLoadingType = <TData>(data: TData) => DataLoading<TData>

export const makeDataLoading: MakeDataLoadingType = data => ({
  dataLoadingState: DATA_LOADING,
  data,
})

type MakeDataErrorColdType = (error: ApolloError) => DataErrorCold

export const makeDataErrorCold: MakeDataErrorColdType = error => ({
  dataLoadingState: DATA_ERROR_COLD,
  error,
})

type MakeDataErrorType = <TData>(
  error: ApolloError,
  data: TData
) => DataError<TData>

export const makeDataError: MakeDataErrorType = (error, data) => ({
  dataLoadingState: DATA_ERROR,
  error,
  data,
})

export const makeDataLoadingState = <TData,>({
  data,
  error,
  loading,
}: {
  data: TData | undefined
  error?: ApolloError
  loading: boolean
}): DataLoadingState<TData> => {
  if (loading && !data) return makeDataLoadingCold()
  if (error && !data) return makeDataErrorCold(error)
  if (error) return makeDataError<TData>(error, data!)
  if (loading) return makeDataLoading<TData>(data!)
  return makeDataLoaded<TData>(data!)
}

export const isDataLoadingCold = (
  propValue: any
): propValue is DataLoadingCold =>
  propValue?.dataLoadingState === DATA_LOADING_COLD
export const isDataLoading = (propValue: any): propValue is DataLoading<any> =>
  propValue?.dataLoadingState === DATA_LOADING
export const isDataLoaded = (propValue: any): propValue is DataLoaded<any> =>
  propValue?.dataLoadingState === DATA_LOADED
export const isDataErrorCold = (propValue: any): propValue is DataErrorCold =>
  propValue?.dataLoadingState === DATA_ERROR_COLD
export const isDataError = (propValue: any): propValue is DataError<any> =>
  propValue?.dataLoadingState === DATA_ERROR

type BranchIfAnyQueryIsColdLoading = <TProps extends {}>(opts?: {
  returns?: (props: TProps) => ReactElement<any, any> | null
}) => (
  props: TProps
) => {
  [key in keyof TProps]: TProps[key] extends DataLoadingState<any>
    ? Exclude<TProps[key], DataLoadingCold>
    : TProps[key]
}

export const branchIfAnyQueryIsColdLoading: BranchIfAnyQueryIsColdLoading = opts =>
  flowMax(
    branch(
      (props: {}) => some(isDataLoadingCold)(props),
      opts?.returns ? returns(opts.returns) : renderNothing()
    ) as any
  )

type AddLoadingIndicatorIfAnyQueryIsColdLoading = <TProps extends {}>(
  props: TProps
) => {
  [key in keyof TProps]: TProps[key] extends DataLoadingState<any>
    ? Exclude<TProps[key], DataLoadingCold>
    : TProps[key]
}

export const addLoadingIndicatorIfAnyQueryIsColdLoading: AddLoadingIndicatorIfAnyQueryIsColdLoading = branchIfAnyQueryIsColdLoading(
  { returns: () => <Loading /> }
)

export const wrapDataLoadingHelperThrowColdErrors = <
  THelper extends (...args: any) => DataLoadingState<any>
>(
  helper: THelper
) => (
  ...args: Parameters<THelper>
): Exclude<ReturnType<THelper>, DataErrorCold> => {
  const dataLoadingState = helper(...args)
  if (isDataErrorCold(dataLoadingState)) throw dataLoadingState.error

  return dataLoadingState as any
}
