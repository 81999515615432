import React, { FC, useEffect } from 'react'
import { DateTime } from 'luxon'
import { LaborHoursBreakdownModal } from './LaborHoursBreakdownModal'
import { useGetLaborHoursBreakDownData } from './useGetLaborHoursBreakDownData'
import {
  GetDailyLaborHoursBreakDownQuery,
  GetHoursDateRangeBreakDownQuery,
  LaborHoursDaily,
  LaborHoursDateRange,
} from '../../config/graphqlTypesRaw'

type LaborHoursBreakdownProps = {
  showModal: boolean
  isWeekView: boolean
  isCurrentDayOrWeek: boolean
  day: DateTime
  startOfWeek: DateTime
  locationId: string
  toggleShowModal: () => void
}

export const LaborHoursBreakdown: FC<LaborHoursBreakdownProps> = ({
  showModal,
  isWeekView,
  isCurrentDayOrWeek,
  day,
  startOfWeek,
  locationId,
  toggleShowModal,
}) => {
  const [getData, { loading, data }] = useGetLaborHoursBreakDownData(
    isWeekView,
    day,
    startOfWeek,
    locationId
  )

  useEffect(() => {
    if (showModal) {
      getData()
    }
  }, [showModal, getData])

  return (
    <LaborHoursBreakdownModal
      isWeekView={isWeekView}
      day={day}
      startOfWeek={startOfWeek}
      loading={loading}
      show={showModal}
      toggleShow={toggleShowModal}
      isCurrentDayOrWeek={isCurrentDayOrWeek}
      laborHoursDaily={
        (data as GetDailyLaborHoursBreakDownQuery)
          ?.laborHoursDailyBreakdown as LaborHoursDaily
      }
      laborHoursDateRange={
        (data as GetHoursDateRangeBreakDownQuery)
          ?.laborHoursDateRangeBreakdown as LaborHoursDateRange
      }
    />
  )
}
