import React, { FC, useState } from 'react'
import { FormGroup } from '../../../../components/Form'
import SelectDropdown, {
  SelectDropdownOption,
} from '../../../../components/SelectDropdown'
import { MultiValue, SingleValue } from 'react-select'
import { useTranslation } from 'react-i18next'

export type SelectMultiOrSingleValueDropdown =
  | MultiValue<SelectDropdownOption | null>
  | SingleValue<SelectDropdownOption | null>

interface Props {
  placeholder?: string
  selectAllOptionTagLabel?: string
  selectAllOptionLabel?: string
  testId: string
  label: string
  singleSelect?: boolean
  options: SelectDropdownOption[]
  onSelectOptions: (options: SelectMultiOrSingleValueDropdown) => void
}

export const ExportScheduleOptionsSelect: FC<Props> = ({
  placeholder,
  selectAllOptionTagLabel,
  selectAllOptionLabel,
  options,
  testId,
  label,
  singleSelect,
  onSelectOptions,
}) => {
  const { t } = useTranslation()

  const [selectedOptions, setSelectedOptions] = useState<
    SelectMultiOrSingleValueDropdown
  >([])
  const handleSelectOptions = (
    selectedOptions: SelectMultiOrSingleValueDropdown
  ) => {
    setSelectedOptions(selectedOptions)
    onSelectOptions(selectedOptions)
  }
  return (
    <FormGroup testId={testId} labelProps={{ label }}>
      <SelectDropdown
        aria-label={testId}
        isDisabled={!options.length}
        isMulti={!singleSelect}
        allowSelectAll={!singleSelect}
        options={options}
        controlStyles={controlStyles}
        menuStyles={menuStyles}
        optionStyles={optionStyles}
        closeMenuOnSelect={!!singleSelect}
        hideSelectedOptions={false}
        blurInputOnSelect={!!singleSelect}
        placeholder={
          placeholder ??
          t('planning.exportScheduleModal.exportOptions.placeholder')
        }
        selectAllOptionTagLabel={
          selectAllOptionTagLabel ??
          t('planning.exportScheduleModal.exportOptions.all')
        }
        selectAllOptionLabel={
          selectAllOptionLabel ??
          t('planning.exportScheduleModal.exportOptions.selectAll')
        }
        onChange={handleSelectOptions}
        value={selectedOptions}
      />
    </FormGroup>
  )
}

const FONT_SIZE = 14

const controlStyles = {
  borderRadius: 25,
  fontSize: FONT_SIZE,
  border: '1px solid var(--gray5)',
  borderColor: 'var(--gray5)',
  paddingLeft: 12,
  color: 'var(--gray3)',
  boxShadow: 'none',
  '&:hover': {
    border: '1px solid var(--gray5)',
  },
}

const menuStyles = {
  fontSize: FONT_SIZE,
}

const optionStyles = {
  fontSize: FONT_SIZE,
}
