import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  DateAndResetWrapper,
  PredictionDateText,
  PredictionInputWrapper,
  FlexCenter,
} from 'pages/plan/Prediction/styles'
import { PredictionDifference } from 'pages/plan/Prediction/PredictionDifference'
import { asDollars } from 'utils/currency'
import { SalesAndLaborSummaryFragment } from 'config/graphqlTypes'
import { StyledPredictionCell, WeeklyTotalAmount } from 'pages/plan/rows/styles'
import { PredictionByCategory } from '../Prediction/index'

interface Props {
  weekSalesAndLaborSummary: SalesAndLaborSummaryFragment
  showCategories?: boolean
  isShowingSchedule: boolean
  categories?: string[][]
  expand?: boolean
}

export const PredictionsTotal = ({
  weekSalesAndLaborSummary,
  showCategories,
  isShowingSchedule,
  categories,
  expand,
}: Props) => {
  const { t } = useTranslation()
  const diff =
    weekSalesAndLaborSummary.plannedSales -
    weekSalesAndLaborSummary.originallyPredictedSales
  const highlight = diff !== 0

  return (
    <StyledPredictionCell isShowingSchedule={isShowingSchedule}>
      <FlexCenter>
        <DateAndResetWrapper>
          <PredictionDateText highlight={highlight} lastRow>
            {t('planning.weeklyTotal')}
          </PredictionDateText>
        </DateAndResetWrapper>
        <PredictionInputWrapper highlight={highlight} noBorder>
          <WeeklyTotalAmount>
            {asDollars(weekSalesAndLaborSummary.plannedSales)}
          </WeeklyTotalAmount>
        </PredictionInputWrapper>
        <PredictionDifference difference={diff} />
        {showCategories && (
          <div className="mt-2">
            <PredictionByCategory
              salesSummary={weekSalesAndLaborSummary}
              categories={categories}
              expand={expand}
            ></PredictionByCategory>
          </div>
        )}
      </FlexCenter>
    </StyledPredictionCell>
  )
}
