import React, { useEffect, useState } from 'react'
import { LocationType } from './LocationType'
import { routeMap } from 'pages/Routes'
import {
  useSquareHistoricImportMutation,
  LocationFragment,
  useGetMeQuery,
} from 'config/graphqlTypes'
import styled from 'styled-components'
import Button from 'components/Button'
import DataTable from 'components/DataTable/DataTable'
import { filter, includes, remove, size, uniqBy } from 'lodash'
import { Col, Row, Spinner } from 'react-bootstrap'
import ConfirmationDialog from 'components/ConfirmationDialog'
import posSuccessImage from 'assets/images/pos-success.svg'
import { notify } from '../../../config/errorReporting'
import { useHistory } from 'react-router-dom'

export const PosSuccessImage = styled.img({
  width: '280px',
  height: '96px',
  resizeMode: 'contain',
})

const AddLocationButton = styled(Button)({
  maxWidth: '160px',
  minWidth: '120px',
  whiteSpace: 'nowrap',
})

const AddAllLocationsButton = styled(Button)({
  minWidth: '180px',
  whiteSpace: 'nowrap',
})

const Title = styled.p({
  color: 'var(--gray2)',
  fontWeight: 800,
  fontSize: '1.125rem',
})

export interface LocationsTableProps {
  locations: LocationType[]
}

export const redirectHome = (history: any) => {
  history.push(routeMap.login)
}

export const LocationsTable = ({ locations }: LocationsTableProps) => {
  const [locationsToSubmit, setLocationsToSubmit] = useState<
    LocationFragment[]
  >([])
  const [locationsData, setLocationsData] = useState<any[]>([])
  const [showAddAllLocationsBtn, setShowAddAllLocationsBtn] = useState<boolean>(
    false
  )
  const [submitAllLoading, setSubmitAllLoading] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [unSelectableRows, setUnSelectableRows] = useState<string[]>([])
  const [showModal, setShowModal] = useState<boolean>(true)
  const history = useHistory()
  const { refetch } = useGetMeQuery({})

  const [
    historicImportMutation,
    { loading },
  ] = useSquareHistoricImportMutation()

  useEffect(() => {
    if (locations) {
      const unAwaitingSubmissionLocations = filter(
        locations,
        location => location.status !== 'awaiting_submission'
      ).map(location => location.id)
      setLocationsData(locations)
      setUnSelectableRows(unAwaitingSubmissionLocations)
    }
  }, [locations])

  const refetchUser = async (locationId: string) => {
    await refetch()
    history.push(
      `${routeMap.locations}/${locationId}${routeMap.location.management.integrationSettings}`
    )
  }

  const actionsFormatter = (_: any, location: LocationFragment) => {
    return location.status === 'awaiting_submission' ? (
      <AddLocationButton
        onClick={(e: any) => {
          e.stopPropagation()
          historicImportMutation({
            variables: { locationId: location.id },
          })
            .then(res => {
              const location = res?.data?.squareHistoricImport.location
              const locations = [...locationsData]
              const locationId = location?.id ?? ''

              remove(locations, l => l.id === locationId)

              const updatedSelectedRows = selectedRows.filter(
                x => x !== locationId
              )
              setSelectedRows(updatedSelectedRows)

              setUnSelectableRows([...unSelectableRows, locationId])

              setLocationsData([...locations, location])

              refetchUser(locationId)
            })
            .catch(error => handleError(error))
        }}
        block
        size="sm"
        type="button"
      >
        {loading && <Spinner animation="border" variant="light" size="sm" />}{' '}
        Add location
      </AddLocationButton>
    ) : null
  }

  const statusFormatter = (status: string) => {
    let readableStatus = ''

    switch (status) {
      case 'awaiting_submission':
        readableStatus = 'Ready to submit'
        break
      case 'pending_historic_import':
        readableStatus = 'Importing historical data'
        break
      case 'pending_activation':
        readableStatus = 'Activating location'
        break
      case 'active':
        readableStatus = 'Active'
        break
      case 'disabled':
        readableStatus = 'Inactive'
        break
    }

    const StyledReadableStatus = styled.span({
      fontStyle: 'italic',
      color: 'var(--gray3)',
    })

    return status !== 'awaiting_submission' ? (
      <StyledReadableStatus>{readableStatus}</StyledReadableStatus>
    ) : (
      readableStatus
    )
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Location Name',
    },
    {
      dataField: 'address',
      text: 'Location Address',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: statusFormatter,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: actionsFormatter,
    },
  ]

  const handleFilteredLocations = (locations: LocationFragment[]) => {
    const filteredLocations = filter(locations, {
      status: 'awaiting_submission',
    })
    setLocationsToSubmit(filteredLocations)
  }

  useEffect(() => {
    setShowAddAllLocationsBtn(!!size(selectedRows))
  }, [selectedRows])

  const selectRow: any = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: selectedRows,
    nonSelectable: unSelectableRows,
    onSelect: (row: LocationFragment, isSelect: boolean) => {
      if (isSelect) {
        const locations = uniqBy([...locationsToSubmit, row], 'id')
        setSelectedRows([...selectedRows, row.id])
        handleFilteredLocations(locations)
      } else {
        const locations = [...locationsToSubmit]
        remove(locations, location => location.id === row.id)
        setLocationsToSubmit(locations)

        const updatedSelectedRows = selectedRows.filter(x => x !== row.id)
        setSelectedRows(updatedSelectedRows)
      }
    },
    onSelectAll: (isSelect: boolean, rows: LocationFragment[]) => {
      if (isSelect) {
        const locations = uniqBy([...locationsToSubmit, ...rows], 'id')
        setSelectedRows(rows.map(row => row.id))
        handleFilteredLocations(locations)
      } else {
        setLocationsToSubmit([])
        setSelectedRows([])
        setShowAddAllLocationsBtn(false)
      }
    },
  }

  const handleError = (error: any) => {
    notify(
      `Failed to add location(s):
          ${error?.message || error}`
    )
    throw new Error('Oops! Something went wrong. Please try again.')
  }

  const handleSubmitAllLocations = async () => {
    setSubmitAllLoading(true)
    const locationPromises = locationsToSubmit.map(location =>
      historicImportMutation({
        variables: {
          locationId: location.id,
        },
      })
    )

    try {
      const updatedLocationResponses = await Promise.all(locationPromises)
      const updatedLocationIds = updatedLocationResponses.map(
        res => res?.data?.squareHistoricImport.location.id || ''
      )
      const filteredLocations = locationsData.filter(
        location => !includes(updatedLocationIds, location.id)
      )

      const updatedLocations = updatedLocationResponses.map(
        res => res.data?.squareHistoricImport.location
      )

      setLocationsData([...filteredLocations, ...updatedLocations])
      setSelectedRows([])
      setUnSelectableRows(updatedLocationIds)

      refetchUser(updatedLocationIds[0])
    } catch (error) {
      handleError(error)
    }

    setSubmitAllLoading(false)
  }

  return (
    <>
      <ConfirmationDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        title={'Success!'}
        cancelText="Close"
        description={
          'Lineup.ai has successfully connected to your Square account.'
        }
        children={<PosSuccessImage src={posSuccessImage} alt={'Success'} />}
        confirmLabel={'Confirm'}
      />
      <Row
        style={{
          marginTop: 30,
          marginBottom: 25,
        }}
      >
        <Col xs={12} md={8}>
          <Title>
            Next, select which locations you want to add to Lineup.ai
          </Title>
        </Col>
        <Col xs={12} md={4}>
          {showAddAllLocationsBtn && (
            <AddAllLocationsButton
              onClick={handleSubmitAllLocations}
              className="float-right"
            >
              {submitAllLoading && (
                <Spinner animation="border" variant="light" size="sm" />
              )}{' '}
              Add locations
            </AddAllLocationsButton>
          )}
        </Col>
      </Row>
      <DataTable
        keyField="id"
        hover
        data={locationsData}
        columns={columns}
        selectRow={selectRow}
      />
    </>
  )
}
