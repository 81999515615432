import { DateTime } from 'luxon'

import {
  useCurrentLocation,
  useCurrentLocationId,
} from 'queries/useCurrentLocation'
import {
  useGetIntervalPredictionSumsQuery,
  GetIntervalPredictionSumsQuery,
} from 'config/graphqlTypes'
import { useGetPollingInterval } from 'queries/useGetPollingInterval'
import {
  makeDataLoadingState,
  wrapDataLoadingHelperThrowColdErrors,
} from 'utils/dataLoading'

export const useGetIntervalPredictionHourlySums = (
  startAt: DateTime,
  endAt: DateTime
) => {
  const locationId = useCurrentLocationId()
  return useGetIntervalPredictionSumsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      locationId,
      startAt,
      endAt,
      intervalMinutes: 60,
    },
  })
}

const useGetIntervalPredictionSumsDataLoadingWithColdError = (
  startAt: DateTime,
  endAt: DateTime,
  intervalMinutes: number
) => {
  const pollInterval = useGetPollingInterval()
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  return makeDataLoadingState<GetIntervalPredictionSumsQuery>(
    useGetIntervalPredictionSumsQuery({
      fetchPolicy: 'cache-and-network',
      pollInterval,
      variables: {
        locationId,
        startAt,
        endAt,
        intervalMinutes,
      },
    })
  )
}

export const useGetIntervalPredictionSumsDataLoading = wrapDataLoadingHelperThrowColdErrors(
  useGetIntervalPredictionSumsDataLoadingWithColdError
)
