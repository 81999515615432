import { DateTime, Interval } from 'luxon'
import {
  ShiftFragment,
  MinimumLaborBaselineFragment,
} from 'config/graphqlTypes'
import { useDailyTimeline } from 'pages/plan/dailyViewModal/useDailyTimeline'
import { getEmployeeCountForHour } from 'pages/plan/dailyViewModal/useMakeChartData'

export type MinimumLaborBaselineViolationType = {
  date: DateTime
  employeesRequired: number
  start: DateTime
  end: DateTime
}

export const getLaborCountForHour = (
  hour: DateTime,
  minimumLaborBaselines: MinimumLaborBaselineFragment[]
) =>
  minimumLaborBaselines.find(
    ({ startAt, endAt }) =>
      endAt.toLocal().hour > hour.hour && hour.hour >= startAt.toLocal().hour
  )?.laborCount ?? 0

export const useUnderMinimumLaborBaseline = (
  day: Interval,
  shiftsForDay: ShiftFragment[],
  minimumLaborBaseline: MinimumLaborBaselineFragment[]
) => {
  const timeline = useDailyTimeline(shiftsForDay, day)
  if (minimumLaborBaseline.length === 0) return false

  return timeline.some(hour => {
    const employeeCount = getEmployeeCountForHour(shiftsForDay, hour)
    const laborCount = getLaborCountForHour(hour, minimumLaborBaseline)

    if (laborCount === 0) return false

    return employeeCount < laborCount
  })
}

const setFromGivenDate = (fromDate: DateTime, toDate: DateTime) =>
  toDate.set({
    year: fromDate.year,
    month: fromDate.month,
    day: fromDate.day,
  })

export const getUnderMinimumBaselineDays = (
  shifts: ShiftFragment[],
  minimumLaborBaselines: MinimumLaborBaselineFragment[],
  days: Interval[]
) =>
  minimumLaborBaselines
    .map(({ startAt, endAt, laborCount, dayOfWeek }) => {
      const baselineInterval = Interval.fromDateTimes(startAt, endAt).splitBy({
        minutes: 60,
      })
      const shiftsForDay = shifts.filter(
        s =>
          s.startAt.weekdayLong.toLowerCase() === dayOfWeek &&
          !s.isUnavailability
      )
      const date = days.find(
        day => day.start.weekdayLong.toLowerCase() === dayOfWeek
      )?.start

      if (!date) return null

      const laborCountNeeded = laborCount ?? 0

      const callback = (hour: Interval) => {
        const hourUsingDate = setFromGivenDate(date, hour.start)
        const employeeCount = getEmployeeCountForHour(
          shiftsForDay,
          hourUsingDate
        )

        if (employeeCount < laborCountNeeded)
          return {
            date,
            employeesRequired: laborCountNeeded - employeeCount,
            start: hour.start,
            end: hour.end,
          }
      }
      return baselineInterval.map(callback).filter(violation => !!violation)
    })
    .flat() as MinimumLaborBaselineViolationType[]
