import React, { FC, useState } from 'react'
import { Pill } from '../../../../components/Pill'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FormGroup } from '../../../../components/Form'

export type ExportScheduleViewType = 'role' | 'person'

interface Props {
  isScheduleByRole: boolean
  onSelectView: (view: ExportScheduleViewType) => void
}

export const ExportScheduleViewToggle: FC<Props> = ({
  isScheduleByRole,
  onSelectView,
}) => {
  const { t } = useTranslation()
  const options = [t('planning.role'), t('planning.person')]
  const [activeIndex, setActiveIndex] = useState(isScheduleByRole ? 0 : 1)
  const onChange = (newIndex: number) => {
    if (newIndex !== activeIndex) {
      setActiveIndex(newIndex)
      onSelectView(newIndex === 0 ? 'role' : 'person')
    }
  }

  return (
    <FormGroup
      testId="exportScheduleViewToggle"
      labelProps={{
        label: t('planning.exportScheduleModal.exportOptions.view'),
      }}
    >
      <Container>
        <StyledPill
          type="button"
          options={options}
          activeIndex={activeIndex}
          onChange={onChange}
        />
      </Container>
    </FormGroup>
  )
}

const StyledPill = styled(Pill)`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  min-height: 2.3rem;
  line-height: 1.111;
  font-weight: 800;
`

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
})
