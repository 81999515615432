// Used in some Nivo charts which do not support var colors

import { Sentiment } from 'pages/covid/types'

export const getColorForTrend = (sentiment: Sentiment) => {
  const sentimentToColor = {
    good: '#79dc83', // lineup-green
    bad: '#f5635b', // bittersweet
    neutral: '#949494', // gray4
  }

  return sentimentToColor[sentiment]
}
