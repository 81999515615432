import React, { forwardRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'
import { ReactComponent as CalendarIconRaw } from 'assets/images/calendar-datepicker.svg'
import { ReactComponent as DownChevronRaw } from 'assets/images/down-chevron.svg'
import { DateTime, Interval } from 'luxon'
import { useFiscalWeekFinder } from 'utils/useGetWeekFromDate'
import { useCurrentLocation } from 'queries/useCurrentLocation'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '42px',
  borderWidth: '1px',
  borderColor: 'var(--gray5)',
  borderStyle: 'solid solid',
  borderRadius: '5px',
  fontWeight: 700,
  fontSize: '14px',
})

const CalendarIcon = styled(CalendarIconRaw)({
  width: '18px',
  height: '20px',
  marginLeft: '12px',
  marginRight: '12px',
})

const DownChevron = styled(DownChevronRaw)({
  width: '12px',
  marginRight: '12px',
})

const WeekBullet = styled.div({
  height: '27px',
  minWidth: '130px',
  borderRadius: '5px',
  backgroundColor: '#3B4EFF1A',
  color: '#3B4EFF',
  padding: '4px 12px 4px 12px',
  fontWeight: 700,
  fontSize: '12px',
  marginLeft: '12px',
  marginRight: '12px',
})

interface DatePickerToggleProps {
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void
}
interface Props {
  onChange: (fiscalWeek: Interval) => void
}

export const DatePicker = ({ onChange }: Props) => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const findFiscalWeek = useFiscalWeekFinder()
  const ExampleCustomInput = forwardRef<
    HTMLInputElement,
    DatePickerToggleProps
  >(({ onClick }, ref) => {
    console.log(selectedDate)
    const fiscalWeek = findFiscalWeek(
      DateTime.local(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        selectedDate.getDate()
      )
    )
    const text = `${fiscalWeek.start.toFormat(
      'dd'
    )} - ${fiscalWeek.end.toFormat('dd MMM yyyy')}`
    return (
      <Container onClick={onClick} ref={ref}>
        <CalendarIcon />
        Date
        <WeekBullet> {text} </WeekBullet>
        <DownChevron />
      </Container>
    )
  })

  const {
    times: { startOfThisWeek },
  } = useCurrentLocation()
  const minDate = startOfThisWeek.minus({ weeks: 4 })
  const maxDate = startOfThisWeek.plus({ weeks: 4 }).endOf('week')

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={date => {
        date && setSelectedDate(date)
        date && onChange(findFiscalWeek(DateTime.fromJSDate(date)))
      }}
      minDate={minDate.toJSDate()}
      maxDate={maxDate.toJSDate()}
      customInput={
        <ExampleCustomInput
          onClick={() => {
            return
          }}
        />
      }
    />
  )
}
