import React, { FC } from 'react'
import { DateTime } from 'luxon'
import {
  StyledDailyBreakdown,
  StyledDailyBreakdownDateCell,
} from '../scheduling/styles'

interface DailyBreakdownCellProps {
  date: DateTime
  disabled: boolean
  onClick: () => void
}

export const DailyBreakdownCell: FC<DailyBreakdownCellProps> = ({
  date,
  disabled,
  onClick,
}) => {
  const formattedDate = `${date.toFormat('ccc')} ${date.toFormat(
    'LLL d'
  )}`.toUpperCase()

  return (
    <StyledDailyBreakdownDateCell onClick={onClick} disabled={disabled}>
      <StyledDailyBreakdown>{formattedDate}</StyledDailyBreakdown>
    </StyledDailyBreakdownDateCell>
  )
}
