import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import apolloClient from 'config/apolloClient'

import { ViolationIcon } from 'components/ViolationIcon'
import {
  PreviewViolationsDocument,
  ShiftFragment,
  ViolationFragment,
} from 'config/graphqlTypes'
import { getUniqueViolations } from 'utils/getUniqueViolations'
import { Violations } from 'pages/plan/scheduling/Violations'
import {
  ShiftModalViolationsWrapper,
  ViolationBanner,
  ShiftModalViolationsTitle,
  ModalViolationsListWrapper,
} from 'pages/plan/scheduling/styles'
import { DateTime } from 'luxon'
import {
  deserializeISOStrings,
  serializeISOStrings,
} from 'utils/dateSerialization'
import { getFirstNameAndLastInitial } from 'utils/getFirstNameAndLastInitial'
import { EmployeeForModifyShift } from 'pages/plan/scheduling/SchedulingForm'

interface Props {
  locationId: string | null
  startTime: DateTime | null
  endTime: DateTime | null
  employee: EmployeeForModifyShift | null
  shiftRoleId: string | null
  shiftBreaks: number[] | null
  roleSelectValue: string | null
  dateInput: DateTime
  selectedShift: ShiftFragment | null
}

export const ShiftModalViolations: FC<Props> = ({
  locationId,
  startTime,
  endTime,
  employee,
  shiftRoleId,
  shiftBreaks,
  roleSelectValue,
  dateInput,
  selectedShift,
}) => {
  const { t } = useTranslation()
  const [uniqueViolations, setUniqueViolations] = useState<ViolationFragment[]>(
    []
  )

  useEffect(() => {
    let isMounted = true

    if (locationId && startTime && endTime && employee && roleSelectValue) {
      apolloClient
        .query({
          query: PreviewViolationsDocument,
          fetchPolicy: 'no-cache',
          variables: serializeISOStrings({
            weekday: dateInput.weekdayLong.toLowerCase(),
            locationId,
            startAt: startTime.toISO(),
            endAt: endTime.toISO(),
            isUnavailability: false,
            employeeId: employee.id,
            shiftRoleId,
            shiftBreaks,
            id: selectedShift?.id,
          }),
        })
        .then(result => {
          if (isMounted) {
            const violations = result?.data
              ? deserializeISOStrings(result.data).previewViolations
              : []

            setUniqueViolations(getUniqueViolations(violations))
          }
        })
    }
    return () => {
      isMounted = false
    }
  }, [
    locationId,
    startTime,
    endTime,
    employee,
    roleSelectValue,
    dateInput,
    selectedShift,
    shiftRoleId,
    shiftBreaks,
  ])

  const employeeName = employee ? getFirstNameAndLastInitial(employee) : ''

  const selectedOrPreviewShift = (() => {
    if (selectedShift) {
      return {
        endAt: selectedShift.endAt,
        startAt: selectedShift.startAt,
        locationId: selectedShift.location?.id,
        shiftId: selectedShift.id,
      }
    } else if (endTime && startTime) {
      return {
        endAt: endTime,
        startAt: startTime,
        locationId,
      }
    }
  })()

  if (uniqueViolations.length === 0) return null

  if (!selectedOrPreviewShift) return null

  return (
    <ShiftModalViolationsWrapper>
      <ViolationBanner>
        <ViolationIcon />
        <ShiftModalViolationsTitle>
          {t('schedule.violations.violationsCount', {
            count: uniqueViolations.length,
          })}
        </ShiftModalViolationsTitle>
      </ViolationBanner>
      <ModalViolationsListWrapper>
        <Violations
          employeeName={employeeName}
          currentShift={selectedOrPreviewShift}
          violations={uniqueViolations}
          date={dateInput}
        />
      </ModalViolationsListWrapper>
    </ShiftModalViolationsWrapper>
  )
}
