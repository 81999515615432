import React from 'react'
import { round, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'

import {
  formattedNumberWithFallback,
  EM_DASH,
} from 'utils/formattedNumberWithFallback'
import { asDollars, asDollarsOrEmDash } from 'utils/currency'
import getFormattedHours from 'utils/getFormattedHours'
import { SmallSpan, BigSpan, Col } from 'pages/plans/styles'
import styled from 'styled-components'

const dollarFormatter = asDollarsOrEmDash
const numberFormatter = (num: number | null) =>
  formattedNumberWithFallback(num, n => `${round(n || 0, 2)}`)

interface Props {
  sales: number | null
  salesBenchmark: number | null
  salesDiffKey: string
  labor: number | null
  laborBenchmark: number | null
  laborDollars: number | null
  laborDiffKey: string
  prefersEntreesPerLaborHourMetric: boolean
  entreesPerHour: number | null
  salesPerHour: number | null
  isPast?: boolean | null
  testidPrefix: string
}

export const PlansTableMetrics = ({
  sales,
  salesBenchmark,
  salesDiffKey,
  labor,
  laborBenchmark,
  laborDollars,
  laborDiffKey,
  prefersEntreesPerLaborHourMetric,
  entreesPerHour,
  salesPerHour,
  isPast,
  testidPrefix,
}: Props) => {
  const { t } = useTranslation()

  const formattedDiff = (
    metric: number | null,
    benchmark: number | null,
    key: string,
    diffFormatter: (diff: number) => string
  ) => {
    if (isNil(metric) || isNil(benchmark) || metric === benchmark) {
      return ''
    }

    const diff = metric - benchmark
    const diffString = diffFormatter(Math.abs(diff))
    const posOrNegKey = diff > 0 ? `plans.${key}PosDiff` : `plans.${key}NegDiff`

    return t(posOrNegKey, { diff: diffString, count: diff })
  }

  const formattedSales = dollarFormatter(sales)
  const formattedLabor = getFormattedHours({ t, hours: labor })
  const formattedSalesDiff = formattedDiff(
    sales,
    salesBenchmark,
    salesDiffKey,
    dollarFormatter
  )
  const formattedLaborDiff = formattedDiff(
    labor,
    laborBenchmark,
    laborDiffKey,
    numberFormatter
  )

  const perLaborHour = prefersEntreesPerLaborHourMetric
    ? numberFormatter(entreesPerHour)
    : dollarFormatter(salesPerHour)

  return (
    <>
      <Col isPast={isPast}>
        <BigSpan data-testid={testidPrefix + '-sales'}>
          {formattedSales}
        </BigSpan>
        <br />
        <SmallSpan data-testid={testidPrefix + '-sales-diff'}>
          {formattedSalesDiff}
        </SmallSpan>
      </Col>
      <Col isPast={isPast}>
        <BigSpan data-testid={testidPrefix + '-labor'}>
          {formattedLabor}
        </BigSpan>
        {formattedLabor === EM_DASH ? (
          <></>
        ) : (
          <>
            <Divider>{'|'}</Divider>
            <BigSpan data-testid={testidPrefix + '-labor-dollars'}>
              {asDollars(laborDollars)}
            </BigSpan>
          </>
        )}
        <br />
        <SmallSpan data-testid={testidPrefix + '-labor-diff'}>
          {formattedLaborDiff}
        </SmallSpan>
      </Col>
      <Col isPast={isPast}>
        <BigSpan data-testid={testidPrefix + '-per-labor'}>
          {perLaborHour}
        </BigSpan>
      </Col>
    </>
  )
}

const Divider = styled.span({
  marginLeft: 5,
  marginRight: 5,
})
