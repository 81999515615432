import { flowMax } from 'ad-hok'

import { addOptionalQueryParam } from 'utils/addOptionalQueryParam'
import { tap } from 'utils/fp'

export const addErrorTriggering = (queryParamName: string) =>
  flowMax(
    addOptionalQueryParam(queryParamName),
    tap(({ [queryParamName]: shouldTriggerError }) => {
      if (!shouldTriggerError) return

      throw new Error(`Test error via ${queryParamName}`)
    })
  )
