import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PersonIcon } from 'assets/images/person.svg'
import { ReactComponent as TimeIcon } from 'assets/images/time.svg'
import { InternalEvent } from '../../config/graphqlTypesRaw'
import CustomPopover from '../../components/CustomPopover'

export type InternalEventType = Omit<
  InternalEvent,
  | 'createdAt'
  | 'updatedAt'
  | 'eventEnd'
  | 'externalLocationId'
  | 'guaranteedGuestCount'
  | 'name'
>

interface Props {
  event: InternalEventType
}

export const InternalEventCard = ({ event }: Props) => {
  const {
    rooms,
    guestCount,
    foodAndBeverageMinDollars,
    eventStartTime,
    eventEndTime,
    status,
    externalId,
    startAtTimeWithSetupTime,
    endAtTimeWithTeardownTime,
  } = event

  const { t } = useTranslation()
  const eventLinkHref = `https://leye.tripleseat.com/events/${externalId}`
  const setupTime = startAtTimeWithSetupTime
  const teardownTime = endAtTimeWithTeardownTime

  const statusColor = getStatusColor(status)

  return (
    <CustomPopover
      id={event.id}
      trigger={['hover', 'focus']}
      content={
        <StyledCard>
          <EventTitleContainer>
            <EventTitle>{rooms}</EventTitle>
            <EventStatus statusColor={statusColor}>
              <EventStatusText>{status}</EventStatusText>
            </EventStatus>
          </EventTitleContainer>
          <EventSection>
            <PersonIconStyled />
            <Text>{guestCount} guests</Text>
            <Divider>|</Divider>
            <Text>${foodAndBeverageMinDollars} spend</Text>
          </EventSection>
          <EventSection>
            <TimeIconStyled />
            <Text>
              {eventStartTime} - {eventEndTime}
            </Text>
          </EventSection>
          <FooterContainer>
            <EventSection>
              <SetupTime>
                {t('planning.eventCard.setup', { mins: setupTime })}
              </SetupTime>
              <Divider>|</Divider>
              <SetupTime>
                {t('planning.eventCard.teardown', { mins: teardownTime })}
              </SetupTime>
            </EventSection>
            <EventLink href={eventLinkHref} target="_blank">
              {t('planning.eventCard.fullDetails')}
            </EventLink>
          </FooterContainer>
        </StyledCard>
      }
    >
      <EventLabel>{event.rooms}</EventLabel>
    </CustomPopover>
  )
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'DEFINITE':
      return 'var(--green)'
    case 'TENTATIVE':
      return 'var(--red)'
    case 'PROSPECT':
      return 'var(--gray3)'
    default:
      return 'var(--gray3)'
  }
}

const EventTitle = styled.span({
  fontSize: 16,
  fontWeight: 800,
  color: 'var(--black)',
  wordBreak: 'break-word',
})

const EventTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 4px;
`

const Text = styled.span`
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
`

const Divider = styled.span`
  color: var(--gray-lighter);
  font-size: 14px;
  font-weight: 500;
`

const SetupTime = styled.span`
  color: var(--gray4);
  font-size: 12px;
  font-weight: 500;
`

const PersonIconStyled = styled(PersonIcon)`
  width: 14px;
  height: 14px;
`

const TimeIconStyled = styled(TimeIcon)`
  width: 14px;
  height: 14px;
`

const EventLabel = styled.span({
  marginLeft: 0,
  marginRight: 5,
  cursor: 'pointer',
  color: 'var(--lineup-blue)',
})

const StyledCard = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 130,
})

const EventSection = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: 8,
})

const EventLink = styled.a({
  color: 'var(--blue)',
  fontSize: 12,
  fontWeight: 500,
  cursor: 'pointer',
})

const EventStatus = styled.div<{ statusColor: string }>(({ statusColor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: statusColor,
  borderRadius: 4,
  height: 16,
}))

const EventStatusText = styled.span`
  padding: 0 8px;
  color: var(--white);
  font-size: 10px;
`

const FooterContainer = styled.div`
  margin-left: 25px;
`
