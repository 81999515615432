import React, { useEffect, useState } from 'react'
import SelectDropdown, {
  SelectDropdownOption,
  SingleValueOption,
} from './SelectDropdown'
import { largeBodyTextStyle } from '../config/styles'
import { DateTime, Interval } from 'luxon'
import { getStringFromTime, Time } from './TimeInput'

interface Props {
  time: SingleValueOption
  startDateTime?: DateTime | null
  dateTime?: DateTime | null
  placeholder?: string
  disabled?: boolean
  onSelect: (time: SingleValueOption) => void
}

function TimePicker({
  time,
  startDateTime,
  dateTime,
  placeholder,
  disabled,
  onSelect,
  ...props
}: Props) {
  const [times, setTimes] = useState<SelectDropdownOption[]>(() =>
    getTimes(dateTime)
  )
  const [selectedTime, setSelectedTime] = useState<SingleValueOption>(time)

  function handleSelectTime(time: SingleValueOption) {
    setSelectedTime(time)
    setTimes([...getTimes(dateTime, time)])
    onSelect(time)
  }

  useEffect(() => {
    if (time) {
      setSelectedTime(time)
      setTimes([...getTimes(dateTime, time)])
    }
  }, [dateTime, time])

  useEffect(() => {
    if (startDateTime) {
      setTimes([...getTimes(dateTime, null, startDateTime)])
    }
  }, [dateTime, startDateTime])

  return (
    <SelectDropdown
      {...props}
      hideIndicatorSeparator
      hideClearIndicator
      isClearable
      isDisabled={disabled}
      placeholder={placeholder}
      controlStyles={controlStyles}
      singleValueStyles={singleValueStyles}
      options={times}
      onChange={handleSelectTime}
      value={selectedTime}
    />
  )
}

const getTimes = (
  dateTime?: DateTime | null,
  selectedTime?: SelectDropdownOption | null,
  startDateTime?: DateTime | null
) => {
  const date = dateTime ?? DateTime.now()
  let start = startDateTime ? startDateTime : date.startOf('day')

  if (selectedTime) {
    const startTime = DateTime.fromMillis(Number(selectedTime.value))
    const { hour, minute } = startTime
    start = start.set({ hour, minute })
  }

  const end = start.plus({ hours: 24 })

  const interval = Interval.fromDateTimes(start, end)

  return interval.splitBy({ minutes: 15 }).map(dateTime => ({
    label: dateTime.start.toFormat('h:mm a'),
    value: dateTime.start.valueOf().toString(),
  }))
}

export const convertTimeToSingleValueOption = (
  time: Time | null,
  dateTime: DateTime | null
) => {
  const dt = dateTime || DateTime.local()
  return time
    ? {
        label: getStringFromTime(dt, time),
        value: dt
          .set(time)
          .valueOf()
          .toString(),
      }
    : null
}

export const convertSingleValueOptionToTime = (time: SingleValueOption) => {
  if (!time) return null

  const { hour, minute } = DateTime.fromMillis(Number(time.value))

  return {
    hour,
    minute,
  }
}

const controlStyles = {
  ...largeBodyTextStyle,
  border: '1px solid',
  borderColor: '#bbc1ff',
  boxShadow: '0 0 0 0.2rem rgba(59, 79, 255, 0.25)',
  borderRadius: 25,
  color: 'var(--gray5)',
  paddingLeft: 13,
}

const singleValueStyles = {
  color: 'var(--gray3)',
}

export default TimePicker
