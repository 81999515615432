import React, { FC } from 'react'
import { Interval, DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { useWeatherDaily, WeatherDisplayProps } from 'queries/useWeather'
import { DailyDetailsPlanRow } from 'pages/plan/rows/PlanRow'

interface Props {
  days: Interval[]
  startDate: DateTime
  endDate: DateTime
}

export const temperatureDisplay = (
  t: TFunction,
  weather: WeatherDisplayProps
) =>
  t('weather.temperatureFormat', {
    temperature: weather.temperature,
  })

export const WeatherRow: FC<Props> = ({ days, startDate, endDate }) => {
  const { t } = useTranslation()

  const dailyWeather = useWeatherDaily(startDate, endDate)

  const getDailyDetails = (day: Interval) => {
    const daysWeather = dailyWeather.find(weather =>
      weather?.date?.equals(day.start)
    )

    if (!daysWeather) {
      return []
    }

    return [[temperatureDisplay(t, daysWeather), daysWeather.summary]]
  }

  return (
    <DailyDetailsPlanRow
      days={days}
      title={t('planning.weather')}
      getDailyDetails={getDailyDetails}
      noDetailsMsg={t('weather.unavailable')}
    />
  )
}
