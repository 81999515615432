import React from 'react'
import styled from 'styled-components'

import { CustomLayerProps } from 'components/Chart/CustomLayer'
import { getActuals } from 'components/Chart/chartDataConfig'

const Rect = styled.rect<{ color: string }>(({ color }) => ({
  fill: `var(--${color})`,
}))

const BackgroundLayer: React.FC<CustomLayerProps> = ({
  height,
  margin,
  series,
}) => {
  const actuals = getActuals(series)

  if (!actuals || actuals.data.length === 0) {
    return null
  }

  const mostRecentActual = actuals.data[actuals.data.length - 1]
  if (!mostRecentActual) {
    return null
  }

  const marginLeft = margin?.left || 0
  const marginTop = margin?.top || 0

  const width = mostRecentActual.position.x + marginLeft

  return (
    <Rect
      color="blue-transparent"
      height={height}
      width={width}
      x={-marginLeft}
      y={-marginTop}
    />
  )
}

export const MostRecentActualBackground = (
  highlightMostRecentActual: boolean
) => (props: CustomLayerProps) => {
  if (!highlightMostRecentActual) {
    return null
  }

  return <BackgroundLayer {...props} />
}
