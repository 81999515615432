import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'

import { Address, TopPanelData } from 'pages/covid/types'
import { MapPin } from 'pages/covid/dashboard/addressRow/styles'
import mapPin from 'assets/images/map-pin.svg'

interface MapPinWrapperProps {
  lat: number
  lng: number
}

export const MapPinWrapper = (_: MapPinWrapperProps) => (
  <MapPin src={mapPin} alt="map-pin" />
)

interface Props {
  address: Address
  topPanelData: TopPanelData | null
}

export const Map = ({
  topPanelData,
  address: { latitude, longitude },
}: Props) => {
  const [map, setMap] = useState<any>()
  const [maps, setMaps] = useState<any>()

  const cbGCoordinates = topPanelData?.geography?.coordinates

  useEffect(() => {
    if (!cbGCoordinates || !map || !maps) {
      return
    }

    const polygonCoordinates = cbGCoordinates.map(([lng, lat]) => ({
      lat,
      lng,
    }))

    const cbgPolygon = new maps.Polygon({
      paths: polygonCoordinates,
      strokeColor: '#3B4FFF',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#3B4FFF',
      fillOpacity: 0.35,
    })

    cbgPolygon.setMap(map)
  }, [map, maps, cbGCoordinates])

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY! }}
      defaultCenter={{ lat: latitude, lng: longitude }}
      defaultZoom={13}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMap(map)
        setMaps(maps)
      }}
      options={{
        maxZoom: 13,
        minZoom: 13,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        scaleControl: false,
      }}
    >
      <MapPinWrapper lat={latitude} lng={longitude} />
    </GoogleMapReact>
  )
}
