import { ReactComponent as ClearScheduleIcon } from 'assets/images/clearschedule.svg'
import ConfirmationDialog from 'components/ConfirmationDialog'
import { PlanFragment, useClearPlanShiftsMutation } from 'config/graphqlTypes'
import { usePlanQueryVariables } from 'pages/plan/planQueryVariablesContext'
import { ClearScheduleButtonElement } from 'pages/plan/styles'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useGetPlan } from '../../../queries/useGetPlan'
import DaySelectControl from './DaySelectControl'

interface Props {
  databaseId: PlanFragment['databaseId']
  hideSchedule: () => void
}

export const ClearScheduleButton: FC<Props> = ({
  databaseId,
  hideSchedule,
}) => {
  const { t } = useTranslation()
  const planQueryVariables = usePlanQueryVariables()
  const { startOn, endOn } = planQueryVariables
  const { data: plan, refetch: refetchPlan } = useGetPlan(startOn, endOn)
  const [selectedDays, setSelectedDays] = useState<string[]>([])

  const daysWithShifts =
    plan?.dailySalesAndLaborSummaries
      .filter(day => day.preScheduledLabor > 0)
      .map(day => day.startOn) ?? []

  const [
    isShowingConfirmationDialog,
    setIsShowingConfirmationDialog,
  ] = useState(false)

  const showConfirmation = () => {
    setIsShowingConfirmationDialog(true)
  }

  const hideConfirmation = () => {
    setIsShowingConfirmationDialog(false)
    setSelectedDays([])
  }

  const onConfirm = async () => {
    const result = await mutateClearPlanShifts()
    const toastMessage = !!result.errors
      ? t('planning.clearSchedule.errorToast')
      : t('planning.clearSchedule.successToast')

    hideConfirmation()

    toast.success(toastMessage, {
      position: toast.POSITION.BOTTOM_LEFT,
      style: { whiteSpace: 'nowrap', width: 'fit-content' },
    })

    if (!result.errors) {
      refetchPlan()
      hideSchedule()
    }
  }

  const [
    mutateClearPlanShifts,
    { loading: clearPlanLoading },
  ] = useClearPlanShiftsMutation({
    // The API is really broken in that plan.id isn't the actual id of the plan in the data. Since we
    // need the plan id from the database to delete all associated shifts, we plumb through and use
    // a separate "databaseId" here. This is gross and should be refactored on the backend eventually
    variables: {
      input: {
        planId: databaseId ?? '',
        days: selectedDays,
        clearAll: selectedDays.length === 0,
      },
    },
  })

  return (
    <>
      <ClearScheduleButtonElement
        onClick={showConfirmation}
        data-testid="clear-schedule"
      >
        <ClearScheduleIcon />
        {t('planning.clearSchedule.buttonLabel')}
      </ClearScheduleButtonElement>

      <ConfirmationDialog
        show={isShowingConfirmationDialog}
        onHide={hideConfirmation}
        title={t('planning.clearSchedule.confirmTitle')}
        description={t('planning.clearSchedule.confirmDescription')}
        confirmLabel={t('planning.clearSchedule.buttonLabel')}
        onConfirm={onConfirm}
        buttonVariant="red"
        bodyTextAlign="center"
        showSpinner={clearPlanLoading}
      >
        <DaySelectControl days={daysWithShifts} onChange={setSelectedDays} />
      </ConfirmationDialog>
    </>
  )
}
