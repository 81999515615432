import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Modal, { ModalProps } from 'react-bootstrap/Modal'

type Props = {
  children: ReactNode
} & ModalProps

const DrawerModal = ({ children, ...props }: Props) => (
  <StyledModal dialogClassName="modal-width" {...props}>
    {children}
  </StyledModal>
)

const transitions = ` 
      -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
      -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
      -o-transition: opacity 0.3s linear, right 0.3s ease-out;
      transition: opacity 0.3s linear, right 0.3s ease-out;
`

const StyledModal = styled(Modal)`
  display: flex !important;
  flex-direction: row-reverse;

  .modal-width {
    min-width: 620px;
  }

  .modal-dialog {
    margin: 0;
    display: flex;
    flex: auto;
    right: -50%;
    ${transitions};
    .modal-content {
      border: none;
      border-radius: 0;
      .modal-body {
        overflow: auto;
      }
    }
  }

  &.show {
    .modal-dialog {
      right: 0;
      ${transitions};
    }
  }

  &:not(.show) {
    .modal-dialog {
      right: -50%;
      ${transitions};
    }
  }

  .modal-header {
    border-bottom: none;

    .h4 {
      color: var(--gray1);
      font-size: 2rem;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
  }
`

export default DrawerModal
