import React from 'react'
import {
  TimeSlotFragment,
  ShiftFragment,
  EmployeeShiftsFragment,
  TimeOffRequestSummaryFragment,
} from '../../../config/graphqlTypes'
import {
  getTimeFromString,
  isAfterOrEqualTime,
  isLessOrEqualTime,
  Time,
} from '../../../components/TimeInput'
import { DateTime } from 'luxon'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getFirstNameAndLastInitial } from '../../../utils/getFirstNameAndLastInitial'
import { isSameDate } from 'utils/daysAndTimes'
import { sortTimeSlots } from '../../../utils/timeSlots'
import { EmployeeForModifyShift } from 'pages/plan/scheduling/SchedulingForm'

export const isEmployeeAvailable = (
  employee: EmployeeForModifyShift,
  weekday: String,
  startTime: Time | null,
  endTime: Time | null,
  date: DateTime,
  locationId: String | null,
  employeeShifts: ShiftFragment[]
): Boolean => {
  const submittedAvailability = !!employee.availability
  const availableHoursForWeekday = employee?.availability?.daysAvailable?.find(
    (h: { dayOfWeek: string }) => h.dayOfWeek === weekday
  )

  if (availableHoursForWeekday?.unavailable) return false

  const timesArePresent = startTime && endTime

  const endsBeforeScheduledShift = (startAt: DateTime) =>
    endTime !== null
      ? endTime?.hour < startAt.toLocal().hour ||
        (endTime?.hour <= startAt.toLocal().hour &&
          endTime?.minute <= startAt.toLocal().minute)
      : false

  const startAfterScheduledShift = (endAt: DateTime) =>
    startTime !== null
      ? startTime?.hour > endAt.toLocal().hour ||
        (startTime?.hour >= endAt.toLocal().hour &&
          startTime?.minute >= endAt.toLocal().minute)
      : false

  const shiftFullyOverlaps =
    timesArePresent &&
    employeeShifts?.some(({ startAt, endAt, location }) => {
      if (startTime === null || endTime === null) return false
      return (
        !endsBeforeScheduledShift(startAt) &&
        !startAfterScheduledShift(endAt) &&
        isSameDate(startAt, date) &&
        isAfterOrEqualTime(startTime, startAt) &&
        isLessOrEqualTime(endTime, endAt) &&
        location.id !== locationId
      )
    })

  const shiftPartiallyOverlaps =
    timesArePresent &&
    employeeShifts?.some(({ startAt, endAt, location }) => {
      if (startTime === null || endTime === null) return false

      const shiftOverlaps =
        isDateWithinRange(startAt, endAt, startTime) ||
        isDateWithinRange(startAt, endAt, endTime)
      const scheduledShiftOverlaps: Boolean =
        isDateWithinRange(startTime, endTime, startAt) ||
        isDateWithinRange(startTime, endTime, endAt)
      return (
        isSameDate(startAt, date) &&
        (scheduledShiftOverlaps || shiftOverlaps) &&
        location.id !== locationId &&
        !endsBeforeScheduledShift(startAt) &&
        !startAfterScheduledShift(endAt)
      )
    })

  const shiftPartiallyOverlapsAfterMidnight =
    timesArePresent &&
    employeeShifts?.some(({ startAt, endAt, location }) => {
      let endTimeOverlaps = false
      let startTimeOverlaps = false

      if (startTime === null || endTime === null) return false

      const scheduledShiftEndsNextDay = shiftsEndsNextDay(startAt, endAt)
      const shiftEndsNextDay = shiftsEndsNextDay(startTime, endTime)
      if (shiftEndsNextDay && scheduledShiftEndsNextDay) {
        endTimeOverlaps =
          isAfterOrEqualTime(endAt, endTime) ||
          isAfterOrEqualTime(endTime, endAt)

        startTimeOverlaps =
          isLessOrEqualTime(startAt, startTime) ||
          isLessOrEqualTime(startTime, startAt)
      }
      if (shiftEndsNextDay && !scheduledShiftEndsNextDay) {
        startTimeOverlaps =
          isDateWithinRange(startAt, endAt, startTime) &&
          !startAfterScheduledShift(endAt)
      }
      if (!shiftEndsNextDay && scheduledShiftEndsNextDay) {
        endTimeOverlaps = !endsBeforeScheduledShift(startAt)
        startTimeOverlaps = !startAfterScheduledShift(endAt)
      }
      return (
        isSameDate(startAt, date) &&
        (endTimeOverlaps || startTimeOverlaps) &&
        location.id !== locationId
      )
    })
  const shiftsOverlapping =
    shiftFullyOverlaps ||
    shiftPartiallyOverlaps ||
    shiftPartiallyOverlapsAfterMidnight
  let hasAvailableHours = true

  if (
    startTime &&
    endTime &&
    submittedAvailability &&
    !availableHoursForWeekday?.unavailable
  ) {
    if (submittedAvailability) hasAvailableHours = false

    availableHoursForWeekday?.timeSlots?.forEach((hours: any) => {
      const employeeStartAtAvailability = getTimeFromString(hours.startTime)
      const employeeEndAtAvailability = getTimeFromString(hours.endTime)

      if (
        employeeStartAtAvailability &&
        employeeEndAtAvailability &&
        isAfterOrEqualTime(startTime, employeeStartAtAvailability) &&
        isAfterOrEqualTime(employeeEndAtAvailability, endTime)
      ) {
        hasAvailableHours = true
      }
    })

    if (hasAvailableHours && date) {
      const timeOffRequests = employee.timeOffRequests

      //we should never use 'every' just to find and set a variable - use .find instead
      timeOffRequests?.every((timeOffRequest: any) => {
        if (
          isSameDate(timeOffRequest.startAt, date) &&
          timeOffRequest.state === 'approved'
        ) {
          if (!timeOffRequest.isAllDay) {
            const timeOffStart = timeOffRequest.startAt
            const timeOffEnd = timeOffRequest.endAt
            const shiftStartAt = date
              ?.set({
                hour: startTime.hour,
                minute: startTime.minute,
              })
              .plus({ minute: 1 }) as DateTime
            const shiftEndAt = date
              ?.set({
                hour: endTime.hour,
                minute: endTime.minute,
              })
              .minus({ minute: 1 }) as DateTime
            if (
              timeOffStart &&
              timeOffEnd &&
              isTimeOffOverlapping(
                timeOffStart,
                shiftStartAt,
                timeOffEnd,
                shiftEndAt
              )
            ) {
              return (hasAvailableHours = false)
            }
          } else {
            return (hasAvailableHours = false)
          }
        }

        return true
      })
    }
  }

  if (shiftsOverlapping) return false

  return !submittedAvailability || hasAvailableHours
}

export const useIsEmployeeUnavailable = ({
  selectedShift,
  locationId,
  employeeShifts,
}: {
  selectedShift: ShiftFragment | null
  locationId: string
  employeeShifts: EmployeeShiftsFragment[]
}) => {
  if (!selectedShift) return false

  const { employee, startAt, endAt } = selectedShift ?? {}
  const weekday = startAt?.weekdayLong.toLowerCase()

  return !isEmployeeAvailable(
    employee,
    weekday,
    startAt,
    endAt,
    startAt,
    locationId,
    getEmployeeShifts(employee?.id, employeeShifts)
  )
}

const getFormattedNameWithAvailabilityStatus = (
  employee: EmployeeFragmentAvailability,
  startTime: Time | null,
  endTime: Time | null,
  date: DateTime | null,
  addAvailabilityStatus: boolean
): string => {
  let formattedName = getFirstNameAndLastInitial(employee) + ' '
  if (employee.unavailableAllDay) {
    formattedName += addAvailabilityStatus
      ? ` (Unavailable - ${dayOfWeekToString(
          date?.weekday && date?.weekday - 1
        )})`
      : ''
  }

  const timeOffRequests = employee.timeOffRequests
  let currentTimeOffRequest: TimeOffRequestSummaryFragment | null = null

  if (date) {
    currentTimeOffRequest =
      timeOffRequests?.find(timeOffRequest => {
        if (timeOffRequest.state !== 'approved') return false

        let startTimeCurrent = startTime as Time
        let endTimeCurrent = endTime as Time

        if (timeOffRequest.isAllDay || !startTime || !endTime) {
          startTimeCurrent = date.set({ hour: 0, minute: 1 }) as Time
          endTimeCurrent = date.set({ hour: 23, minute: 59 }) as Time
        }

        if (
          isTimeOffOverlapping(
            timeOffRequest.startAt,
            date.set({
              hour: startTimeCurrent.hour,
              minute: startTimeCurrent.minute,
            }) as DateTime,
            timeOffRequest.endAt,
            date.set({
              hour: endTimeCurrent.hour,
              minute: endTimeCurrent.minute,
            }) as DateTime
          )
        ) {
          return true
        }

        return false
      }) || null
  }

  const timeOffStart = (currentTimeOffRequest as any)?.startAt.toLocaleString({
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
  const timeOffEnd = (currentTimeOffRequest as any)?.endAt.toLocaleString({
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  const timeOffAllDay = (currentTimeOffRequest as any)?.isAllDay
  const timeOffString = timeOffAllDay
    ? '(Unavailable - Time Off All Day)'
    : `(Unavailable - Time Off ${timeOffStart} - ${timeOffEnd})`

  return (
    `${formattedName}` +
    (addAvailabilityStatus
      ? `${
          employee.timeSlotsLabel && !currentTimeOffRequest
            ? employee.timeSlotsLabel
            : ''
        } ${currentTimeOffRequest ? timeOffString : ''}`
      : '')
  )
}

const getEmployeesByShiftRoleName = (
  employees: EmployeeFragmentAvailability[],
  title: string,
  titleKey: string,
  sectionKey: string,
  addAvailabilityStatusLabel: boolean,
  startTime: Time | null,
  endTime: Time | null,
  date: DateTime | null
): JSX.Element[] => [
  (employees.length && (
    <option key={titleKey} disabled>
      {title}
    </option>
  )) || (
    <React.Fragment key={'defaultEmployeeKey-' + startTime}></React.Fragment>
  ),
  ...employees
    .sort((e1, e2) => e1.name?.localeCompare(e2.name))
    .map(e => (
      <option key={`${e.id}_${sectionKey}`} value={e.id}>
        {addAvailabilityStatusLabel
          ? getFormattedNameWithAvailabilityStatus(
              e,
              startTime,
              endTime,
              date,
              addAvailabilityStatusLabel
            )
          : e.name}
      </option>
    )),
]

const getEmployeesByAvailabilityStatus = (
  employees: EmployeeFragmentAvailability[],
  title: string,
  key: string,
  startTime: Time | null,
  endTime: Time | null,
  date: DateTime | null,
  addAvailabilityStatusLabel: boolean
): JSX.Element[] => [
  (employees.length && (
    <option key={key + '' + startTime} disabled>
      {title}
    </option>
  )) || <React.Fragment key={key + '' + startTime}></React.Fragment>,
  ...employees
    .sort((e1, e2) => e1.name?.localeCompare(e2.name))
    .map(e => (
      <option key={`${e.id}_${key}`} value={e.id}>
        {getFormattedNameWithAvailabilityStatus(
          e,
          startTime,
          endTime,
          date,
          addAvailabilityStatusLabel
        )}
      </option>
    )),
]

const dayOfWeekToString = (dayOfWeek: number | undefined): string => {
  if (dayOfWeek === undefined) return ''

  return [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ][dayOfWeek]
}

interface EmployeeFragmentAvailability extends EmployeeForModifyShift {
  isAvailable: boolean
  unavailableAllDay: boolean
  timeSlotsLabel: string
}

export const FilterEmployees = (
  employees: EmployeeForModifyShift[],
  date: DateTime | null,
  startTime: Time | null,
  endTime: Time | null,
  selectedShiftRole: string[] | null | undefined,
  showAllEmployees: boolean,
  locationId: string | null,
  employeeShiftsForWeek: EmployeeShiftsFragment[]
): JSX.Element[] => {
  const addTimeSlotsLabel = (
    employee: EmployeeFragmentAvailability,
    weekday: string,
    date: DateTime
  ): EmployeeFragmentAvailability => {
    const hasAvailability = isEmployeeAvailable(
      employee,
      weekday,
      startTime,
      endTime,
      date,
      locationId,
      getEmployeeShifts(employee.id, employeeShiftsForWeek)
    )
    const availableHoursForWeekday = employee?.availability?.daysAvailable?.find(
      (h: { dayOfWeek: string; unavailable: boolean }) =>
        h.dayOfWeek === weekday && !h.unavailable
    )

    const timeSlotsLabel =
      availableHoursForWeekday?.timeSlots !== undefined
        ? sortTimeSlots(availableHoursForWeekday?.timeSlots)
            ?.map(
              (slot: TimeSlotFragment) => `${slot.startTime} - ${slot.endTime}`
            )
            .join(' & ') +
          ' ' +
          capitalize(availableHoursForWeekday?.dayOfWeek)
        : ''

    return {
      ...employee,
      timeSlotsLabel: `${
        !hasAvailability
          ? timeSlotsLabel && timeSlotsLabel.length
            ? '(Only avail ' + timeSlotsLabel + ')'
            : ''
          : ''
      }`,
    }
  }
  const { t } = useTranslation()
  if (date) {
    const [selectedShiftRoleId, selectedShiftRoleName] = selectedShiftRole ?? [
      null,
      null,
    ]
    const weekday = date.weekdayLong.toLowerCase()

    const availableEmployees: EmployeeFragmentAvailability[] = employees
      .filter(
        e =>
          e.id !== '-1' &&
          isEmployeeAvailable(
            e,
            weekday,
            startTime,
            endTime,
            date,
            locationId,
            getEmployeeShifts(e.id, employeeShiftsForWeek)
          )
      )
      .map(e => ({
        ...e,
        isAvailable: true,
        unavailableAllDay: false,
        timeSlotsLabel: '',
      }))

    const unavailableEmployees: EmployeeFragmentAvailability[] = employees
      .filter(
        e =>
          e.id !== '-1' &&
          !isEmployeeAvailable(
            e,
            weekday,
            startTime,
            endTime,
            date,
            locationId,
            getEmployeeShifts(e.id, employeeShiftsForWeek)
          )
      )
      .map(e => {
        const availableHoursForWeekday = e?.availability?.daysAvailable?.find(
          (h: { dayOfWeek: string }) => h.dayOfWeek === weekday
        )

        const unavailableAllDay =
          (e?.availability && !availableHoursForWeekday) ||
          availableHoursForWeekday?.unavailable ||
          false

        return {
          ...e,
          isAvailable: false,
          unavailableAllDay,
          timeSlotsLabel: '',
        }
      })

    const filterShiftRolePredicate = (e: EmployeeFragmentAvailability) =>
      e.shiftRoles &&
      e.shiftRoles.length > 0 &&
      e.shiftRoles.find(s => s.id === selectedShiftRoleId) !== undefined

    const employeesForShiftRole: EmployeeFragmentAvailability[] = showAllEmployees
      ? [...availableEmployees, ...unavailableEmployees]
          .filter(filterShiftRolePredicate)
          .map(e => addTimeSlotsLabel(e, weekday, date))
      : availableEmployees.filter(filterShiftRolePredicate)
    const allEmployees = showAllEmployees
      ? [...availableEmployees, ...unavailableEmployees].map(e =>
          addTimeSlotsLabel(e, weekday, date)
        )
      : availableEmployees

    const showAvailabilityStatus = showAllEmployees
    const employeeLabelsByRole = getEmployeesByShiftRoleName(
      employeesForShiftRole,
      t('schedule.shiftModal.employeesDropDown.employeesByShiftRole', {
        selectedShiftRoleName,
      }),
      'employeesByShiftRoleAvailable',
      'employeesByShiftRole',
      showAvailabilityStatus,
      startTime,
      endTime,
      date
    )
    const employeeLabelsAll = getEmployeesByAvailabilityStatus(
      allEmployees.filter(
        e => !employeesForShiftRole.find(e2 => e2.id === e.id)
      ),
      t('schedule.shiftModal.employeesDropDown.allEmployees'),
      'allEmployees',
      startTime,
      endTime,
      date,
      showAvailabilityStatus
    )

    return [...employeeLabelsByRole, ...employeeLabelsAll]
  }

  return employees.map(e => {
    return (
      <option key={e.id} value={e.id}>
        {e.name}
      </option>
    )
  })
}

function isTimeOffOverlapping(
  timeOffStart: DateTime,
  startTime: DateTime,
  timeOffEnd: DateTime,
  endTime: DateTime
): boolean {
  return (
    isDateWithinRangeFixed(startTime, endTime, timeOffStart) ||
    isDateWithinRangeFixed(startTime, endTime, timeOffEnd) ||
    isDateWithinRangeFixed(timeOffStart, timeOffEnd, startTime) ||
    isDateWithinRangeFixed(timeOffStart, timeOffEnd, endTime)
  )
}

//I am creating this function to isolate the issue with the isDateWithinRange function
//If I fixed the original function, there is a chance I would break other things considering how many times it is used
//I am going to create a jira ticket suggesting possible refactor
function isDateWithinRangeFixed(
  startRange: DateTime | Time,
  endRange: DateTime | Time,
  date: DateTime | Time
): boolean {
  if (
    startRange instanceof DateTime &&
    endRange instanceof DateTime &&
    date instanceof DateTime
  ) {
    return date >= startRange && date <= endRange
  } else {
    return (
      (date.hour > startRange.hour ||
        (date.hour === startRange.hour && date.minute >= startRange.minute)) &&
      (date.hour < endRange.hour ||
        (date.hour === endRange.hour && date.minute <= endRange.minute))
    )
  }
}

//this function is either completely broken or not named correctly - this needs to be refactored
//I am not going to fix this yet because nothing appears to be broken
function isDateWithinRange(
  startRange: DateTime | Time,
  endRange: DateTime | Time,
  date: DateTime | Time
): boolean {
  return (
    isAfterOrEqualTime(date, startRange) && isLessOrEqualTime(date, endRange)
  )
}

export function getEmployeeShifts(
  employeeId: String,
  list: EmployeeShiftsFragment[]
): ShiftFragment[] {
  return list.find(({ id }) => id === employeeId)?.shifts as ShiftFragment[]
}

function shiftsEndsNextDay(
  startTime: DateTime | Time,
  endTime: DateTime | Time
): Boolean {
  return startTime.hour > endTime.hour
}
