import React, { FC } from 'react'
import { LaborHrsDayHeading } from './styles'

type LaborHoursBreakdownHeadingProps = {
  heading: string
}
export const LaborHoursBreakdownHeading: FC<LaborHoursBreakdownHeadingProps> = ({
  heading,
}) => {
  return <LaborHrsDayHeading>{heading}</LaborHrsDayHeading>
}
