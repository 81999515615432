import React, { useRef, useState, useEffect, FC } from 'react'
import { ConnectDragSource } from 'react-dnd'

import {
  ShiftPillDragHandle,
  ShiftText,
  ShiftPillEnd,
} from 'pages/plan/dailyViewModal/schedule/styles'
import { ShiftSegment } from 'pages/plan/dailyViewModal/schedule/getShiftSegments'
import {
  LeftDragChevron,
  RightDragChevron,
} from 'pages/plan/dailyViewModal/styles'

const getNewWidth = (pills: HTMLElement[], left: number, right: number) => {
  for (const pill of pills) {
    const { left: pillLeft, right: pillRight } = pill.getBoundingClientRect()

    // If the pill's left is after the target's left but before the target's right
    // then it is cutting off the right side of a left-oriented label
    if (pillLeft > left && pillLeft < right) {
      return pillLeft - left
    }

    // If the pill's right is before the target's right but after the target's left
    // then it is cutting off the left side of a right-oriented label
    if (pillRight < right && pillRight > left) {
      return right - pillRight
    }
  }
}

interface Props {
  label: string
  pills?: HTMLElement[]
  position: 'start' | 'end'
  dragRef?: ConnectDragSource
  shiftSegment?: ShiftSegment
  isEditable?: boolean
}

export const ShiftLabel: FC<Props> = ({
  label,
  pills,
  position,
  dragRef,
  shiftSegment,
}) => {
  const other = shiftSegment?.other
  const conflict = shiftSegment?.conflict

  const ref = useRef<HTMLParagraphElement>(null)
  const [width, setWidth] = useState<undefined | number>(undefined)

  useEffect(() => {
    if (ref.current && pills?.length) {
      // Because the pills have absolute positioning, their labels can be cutoff
      // by other pills. To prevent this, look for any pills that cover the text
      // and reduce the text width so avoid the overlapping pill. Perform this
      // after everything has mounted.
      const { left, right } = ref.current.getBoundingClientRect()

      const newWidth = getNewWidth(pills, left, right)

      if (newWidth) {
        setWidth(newWidth)
      }
    }
  }, [label, ref, pills])

  if (!label) {
    return <ShiftText>&nbsp;</ShiftText>
  }

  const isActualShift = !(other || conflict)

  return (
    <ShiftPillEnd other={other} conflict={conflict}>
      {isActualShift && position === 'start' && (
        <ShiftPillDragHandle ref={dragRef} className="drag-handle">
          <LeftDragChevron />
        </ShiftPillDragHandle>
      )}
      <ShiftText
        conflict={conflict}
        ref={ref}
        width={width}
        className="label-text"
      >
        {label}
      </ShiftText>
      {isActualShift && position === 'end' && (
        <ShiftPillDragHandle ref={dragRef} className="drag-handle">
          <RightDragChevron />
        </ShiftPillDragHandle>
      )}
    </ShiftPillEnd>
  )
}
