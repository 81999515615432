import React from 'react'

import {
  Box,
  Content,
  DownArrow,
  UpArrow,
  Title,
} from 'components/Chart/Tooltip/styles'

interface Props {
  title: string
  upsideDown?: boolean
  hasErrorNote?: boolean
  children: JSX.Element
}

export const Tooltip = ({
  title,
  upsideDown,
  hasErrorNote,
  children,
}: Props) => (
  <Box
    data-testid={`${title}-tooltip`}
    arrowProps={{ ref: null, style: { display: 'none' } }}
    id="tooltip"
    $upsideDown={upsideDown}
    $hasErrorNote={hasErrorNote}
  >
    {upsideDown && <UpArrow className="up-arrow" />}
    <Title>{title}</Title>
    <Content>{children}</Content>
    {upsideDown || <DownArrow className="down-arrow" />}
  </Box>
)
