import React, { FC, useState } from 'react'
import { DeleteEmployeeButton } from '../styles'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import { toast } from 'react-toastify'
import {
  useDeleteEmployeeLocationsMutation,
  EmployeeListingFragment,
} from 'config/graphqlTypes'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { EmployeeForEditFragment } from 'config/graphqlTypesRaw'

interface Props {
  employees: EmployeeListingFragment[] | EmployeeForEditFragment[]
  onDelete?: () => void
  size?: string
}

export const ENABLE_EMPLOYEE_DELETE =
  process.env.REACT_APP_ENABLE_EMPLOYEE_DELETE === 'true' ? true : false

export const DeleteEmployee: FC<Props> = ({ employees, onDelete, size }) => {
  const { t } = useTranslation()
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
    false
  )

  const locationId = useCurrentLocationId()

  const [deleteEmployee, { loading }] = useDeleteEmployeeLocationsMutation()

  const onDeleteEmployeesHandler = async () => {
    setShowConfirmationModal(false)

    const employeeDeletes: [Promise<any>] = [] as any

    employees.forEach(employee => {
      employeeDeletes.push(
        deleteEmployee({
          variables: {
            input: {
              employeeId: employee.id,
              locationIds: [locationId],
            },
          },
        })
      )
    })
    Promise.all(employeeDeletes)
      .then(() => {
        onDelete && onDelete()
        toast.success(
          t(
            employees.length > 1
              ? 'management.toastMessages.deleteEmployees'
              : 'management.toastMessages.deleteEmployee'
          ),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        )
      })
      .catch(() => {
        throw new Error(t('settings.employeeSettings.employeeForm.oops'))
      })
  }

  if (!ENABLE_EMPLOYEE_DELETE) return null

  return (
    <>
      <ConfirmationDialog
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        title={t('management.deleteEmployeeModal.title')}
        description={t('management.deleteEmployeeModal.description')}
        confirmLabel={t('management.deleteEmployeeModal.deleteBtn')}
        onConfirm={onDeleteEmployeesHandler}
      />
      <DeleteEmployeeButton
        size={size}
        variant="danger"
        disabled={loading}
        onClick={() => {
          toast.dismiss()
          setShowConfirmationModal(true)
        }}
      >
        {loading && <Spinner animation="border" variant="light" size="sm" />}{' '}
        {t('management.deleteEmployeeModal.deleteBtn')}
      </DeleteEmployeeButton>
    </>
  )
}
