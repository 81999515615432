/* eslint-disable */

/* This file is automatically generated by graphqlWrappersPlugin.js. */

import { ShiftBreakFragment as ShiftBreakFragmentOrig } from 'config/graphqlTypesRaw'
export { ShiftBreakFragmentDoc } from 'config/graphqlTypesRaw'
import { EmployeeLocationFragment as EmployeeLocationFragmentOrig } from 'config/graphqlTypesRaw'
export { EmployeeLocationFragmentDoc } from 'config/graphqlTypesRaw'
import { EmployeeForEditFragment as EmployeeForEditFragmentOrig } from 'config/graphqlTypesRaw'
export { EmployeeForEditFragmentDoc } from 'config/graphqlTypesRaw'
import { EmployeeForScheduleFragment as EmployeeForScheduleFragmentOrig } from 'config/graphqlTypesRaw'
export { EmployeeForScheduleFragmentDoc } from 'config/graphqlTypesRaw'
import { EmployeeFragment as EmployeeFragmentOrig } from 'config/graphqlTypesRaw'
export { EmployeeFragmentDoc } from 'config/graphqlTypesRaw'
import { EmployeeWithLocationStatusFragment as EmployeeWithLocationStatusFragmentOrig } from 'config/graphqlTypesRaw'
export { EmployeeWithLocationStatusFragmentDoc } from 'config/graphqlTypesRaw'
import { EmployeeShiftsFragment as EmployeeShiftsFragmentOrig } from 'config/graphqlTypesRaw'
export { EmployeeShiftsFragmentDoc } from 'config/graphqlTypesRaw'
import { EmployeeListingFragment as EmployeeListingFragmentOrig } from 'config/graphqlTypesRaw'
export { EmployeeListingFragmentDoc } from 'config/graphqlTypesRaw'
import { ForecastFragment as ForecastFragmentOrig } from 'config/graphqlTypesRaw'
export { ForecastFragmentDoc } from 'config/graphqlTypesRaw'
import { ItemForecastDayFragment as ItemForecastDayFragmentOrig } from 'config/graphqlTypesRaw'
export { ItemForecastDayFragmentDoc } from 'config/graphqlTypesRaw'
import { ItemsForecastFragment as ItemsForecastFragmentOrig } from 'config/graphqlTypesRaw'
export { ItemsForecastFragmentDoc } from 'config/graphqlTypesRaw'
import { LaborDollarsDailyFragment as LaborDollarsDailyFragmentOrig } from 'config/graphqlTypesRaw'
export { LaborDollarsDailyFragmentDoc } from 'config/graphqlTypesRaw'
import { ImportEventFragment as ImportEventFragmentOrig } from 'config/graphqlTypesRaw'
export { ImportEventFragmentDoc } from 'config/graphqlTypesRaw'
import { InsightFragment as InsightFragmentOrig } from 'config/graphqlTypesRaw'
export { InsightFragmentDoc } from 'config/graphqlTypesRaw'
import { IntervalOrderSumFragment as IntervalOrderSumFragmentOrig } from 'config/graphqlTypesRaw'
export { IntervalOrderSumFragmentDoc } from 'config/graphqlTypesRaw'
import { IntervalPredictionSumFragment as IntervalPredictionSumFragmentOrig } from 'config/graphqlTypesRaw'
export { IntervalPredictionSumFragmentDoc } from 'config/graphqlTypesRaw'
import { LocationManagementRequestsFragment as LocationManagementRequestsFragmentOrig } from 'config/graphqlTypesRaw'
export { LocationManagementRequestsFragmentDoc } from 'config/graphqlTypesRaw'
import { LocationShiftRolesFragment as LocationShiftRolesFragmentOrig } from 'config/graphqlTypesRaw'
export { LocationShiftRolesFragmentDoc } from 'config/graphqlTypesRaw'
import { LocationSummaryFragment as LocationSummaryFragmentOrig } from 'config/graphqlTypesRaw'
export { LocationSummaryFragmentDoc } from 'config/graphqlTypesRaw'
import { LocationUserSummaryFragment as LocationUserSummaryFragmentOrig } from 'config/graphqlTypesRaw'
export { LocationUserSummaryFragmentDoc } from 'config/graphqlTypesRaw'
import { LocationForReportingFragment as LocationForReportingFragmentOrig } from 'config/graphqlTypesRaw'
export { LocationForReportingFragmentDoc } from 'config/graphqlTypesRaw'
import { LocationForModifyShiftFragment as LocationForModifyShiftFragmentOrig } from 'config/graphqlTypesRaw'
export { LocationForModifyShiftFragmentDoc } from 'config/graphqlTypesRaw'
import { LocationFragment as LocationFragmentOrig } from 'config/graphqlTypesRaw'
export { LocationFragmentDoc } from 'config/graphqlTypesRaw'
import { MassNotificationsFragment as MassNotificationsFragmentOrig } from 'config/graphqlTypesRaw'
export { MassNotificationsFragmentDoc } from 'config/graphqlTypesRaw'
import { OperatingHoursFragment as OperatingHoursFragmentOrig } from 'config/graphqlTypesRaw'
export { OperatingHoursFragmentDoc } from 'config/graphqlTypesRaw'
import { OperatingHoursForDateFragment as OperatingHoursForDateFragmentOrig } from 'config/graphqlTypesRaw'
export { OperatingHoursForDateFragmentDoc } from 'config/graphqlTypesRaw'
import { OrganizationFragment as OrganizationFragmentOrig } from 'config/graphqlTypesRaw'
export { OrganizationFragmentDoc } from 'config/graphqlTypesRaw'
import { PermissionFragment as PermissionFragmentOrig } from 'config/graphqlTypesRaw'
export { PermissionFragmentDoc } from 'config/graphqlTypesRaw'
import { LocationTimeOffBlockedDateFragment as LocationTimeOffBlockedDateFragmentOrig } from 'config/graphqlTypesRaw'
export { LocationTimeOffBlockedDateFragmentDoc } from 'config/graphqlTypesRaw'
import { TimeOffBlockedDateFragment as TimeOffBlockedDateFragmentOrig } from 'config/graphqlTypesRaw'
export { TimeOffBlockedDateFragmentDoc } from 'config/graphqlTypesRaw'
import { TimeOffBlockedDateForFormFragment as TimeOffBlockedDateForFormFragmentOrig } from 'config/graphqlTypesRaw'
export { TimeOffBlockedDateForFormFragmentDoc } from 'config/graphqlTypesRaw'
import { PlanSummaryFragment as PlanSummaryFragmentOrig } from 'config/graphqlTypesRaw'
export { PlanSummaryFragmentDoc } from 'config/graphqlTypesRaw'
import { PlanSummaryLightFragment as PlanSummaryLightFragmentOrig } from 'config/graphqlTypesRaw'
export { PlanSummaryLightFragmentDoc } from 'config/graphqlTypesRaw'
import { PlanSummaryWithDailySummariesFragment as PlanSummaryWithDailySummariesFragmentOrig } from 'config/graphqlTypesRaw'
export { PlanSummaryWithDailySummariesFragmentDoc } from 'config/graphqlTypesRaw'
import { PlanForReportingFragment as PlanForReportingFragmentOrig } from 'config/graphqlTypesRaw'
export { PlanForReportingFragmentDoc } from 'config/graphqlTypesRaw'
import { ToucanAuthFragment as ToucanAuthFragmentOrig } from 'config/graphqlTypesRaw'
export { ToucanAuthFragmentDoc } from 'config/graphqlTypesRaw'
import { PlanFragment as PlanFragmentOrig } from 'config/graphqlTypesRaw'
export { PlanFragmentDoc } from 'config/graphqlTypesRaw'
import { RoleFragment as RoleFragmentOrig } from 'config/graphqlTypesRaw'
export { RoleFragmentDoc } from 'config/graphqlTypesRaw'
import { ShiftRoleCategoryFragment as ShiftRoleCategoryFragmentOrig } from 'config/graphqlTypesRaw'
export { ShiftRoleCategoryFragmentDoc } from 'config/graphqlTypesRaw'
import { ShiftRoleFragment as ShiftRoleFragmentOrig } from 'config/graphqlTypesRaw'
export { ShiftRoleFragmentDoc } from 'config/graphqlTypesRaw'
import { ShiftSummaryFragment as ShiftSummaryFragmentOrig } from 'config/graphqlTypesRaw'
export { ShiftSummaryFragmentDoc } from 'config/graphqlTypesRaw'
import { ShiftFragment as ShiftFragmentOrig } from 'config/graphqlTypesRaw'
export { ShiftFragmentDoc } from 'config/graphqlTypesRaw'
import { SalesAndLaborSummaryFragment as SalesAndLaborSummaryFragmentOrig } from 'config/graphqlTypesRaw'
export { SalesAndLaborSummaryFragmentDoc } from 'config/graphqlTypesRaw'
import { TopEventFragment as TopEventFragmentOrig } from 'config/graphqlTypesRaw'
export { TopEventFragmentDoc } from 'config/graphqlTypesRaw'
import { CurrentUserInfoFragment as CurrentUserInfoFragmentOrig } from 'config/graphqlTypesRaw'
export { CurrentUserInfoFragmentDoc } from 'config/graphqlTypesRaw'
import { ViolationFragment as ViolationFragmentOrig } from 'config/graphqlTypesRaw'
export { ViolationFragmentDoc } from 'config/graphqlTypesRaw'
import { CovidDashboardFragment as CovidDashboardFragmentOrig } from 'config/graphqlTypesRaw'
export { CovidDashboardFragmentDoc } from 'config/graphqlTypesRaw'
import { PlanTemplateFragment as PlanTemplateFragmentOrig } from 'config/graphqlTypesRaw'
export { PlanTemplateFragmentDoc } from 'config/graphqlTypesRaw'
import { DaysAvailableFragment as DaysAvailableFragmentOrig } from 'config/graphqlTypesRaw'
export { DaysAvailableFragmentDoc } from 'config/graphqlTypesRaw'
import { TimeSlotFragment as TimeSlotFragmentOrig } from 'config/graphqlTypesRaw'
export { TimeSlotFragmentDoc } from 'config/graphqlTypesRaw'
import { EmployeeAvailabilityFragment as EmployeeAvailabilityFragmentOrig } from 'config/graphqlTypesRaw'
export { EmployeeAvailabilityFragmentDoc } from 'config/graphqlTypesRaw'
import { EmployeeAvailabilitySummaryFragment as EmployeeAvailabilitySummaryFragmentOrig } from 'config/graphqlTypesRaw'
export { EmployeeAvailabilitySummaryFragmentDoc } from 'config/graphqlTypesRaw'
import { TimeOffRequestFragment as TimeOffRequestFragmentOrig } from 'config/graphqlTypesRaw'
export { TimeOffRequestFragmentDoc } from 'config/graphqlTypesRaw'
import { TimeOffRequestSummaryFragment as TimeOffRequestSummaryFragmentOrig } from 'config/graphqlTypesRaw'
export { TimeOffRequestSummaryFragmentDoc } from 'config/graphqlTypesRaw'
import { ShiftSwapRequestFragment as ShiftSwapRequestFragmentOrig } from 'config/graphqlTypesRaw'
export { ShiftSwapRequestFragmentDoc } from 'config/graphqlTypesRaw'
import { MinimumLaborBaselineFragment as MinimumLaborBaselineFragmentOrig } from 'config/graphqlTypesRaw'
export { MinimumLaborBaselineFragmentDoc } from 'config/graphqlTypesRaw'
import { OrganizationManagementRequestsFragment as OrganizationManagementRequestsFragmentOrig } from 'config/graphqlTypesRaw'
export { OrganizationManagementRequestsFragmentDoc } from 'config/graphqlTypesRaw'
import { OrganizationSettingsFragment as OrganizationSettingsFragmentOrig } from 'config/graphqlTypesRaw'
export { OrganizationSettingsFragmentDoc } from 'config/graphqlTypesRaw'
import { UserForOrganizationSettingsFragment as UserForOrganizationSettingsFragmentOrig } from 'config/graphqlTypesRaw'
export { UserForOrganizationSettingsFragmentDoc } from 'config/graphqlTypesRaw'
import { ActiveLocationShiftRoleFragment as ActiveLocationShiftRoleFragmentOrig } from 'config/graphqlTypesRaw'
export { ActiveLocationShiftRoleFragmentDoc } from 'config/graphqlTypesRaw'
import { SalesDayPartFragment as SalesDayPartFragmentOrig } from 'config/graphqlTypesRaw'
export { SalesDayPartFragmentDoc } from 'config/graphqlTypesRaw'

import { DeserializeISOStrings } from 'utils/dateSerialization'

export type ShiftBreakFragment = DeserializeISOStrings<ShiftBreakFragmentOrig>
export type EmployeeLocationFragment = DeserializeISOStrings<EmployeeLocationFragmentOrig>
export type EmployeeForEditFragment = DeserializeISOStrings<EmployeeForEditFragmentOrig>
export type EmployeeForScheduleFragment = DeserializeISOStrings<EmployeeForScheduleFragmentOrig>
export type EmployeeFragment = DeserializeISOStrings<EmployeeFragmentOrig>
export type EmployeeWithLocationStatusFragment = DeserializeISOStrings<EmployeeWithLocationStatusFragmentOrig>
export type EmployeeShiftsFragment = DeserializeISOStrings<EmployeeShiftsFragmentOrig>
export type EmployeeListingFragment = DeserializeISOStrings<EmployeeListingFragmentOrig>
export type ForecastFragment = DeserializeISOStrings<ForecastFragmentOrig>
export type ItemForecastDayFragment = DeserializeISOStrings<ItemForecastDayFragmentOrig>
export type ItemsForecastFragment = DeserializeISOStrings<ItemsForecastFragmentOrig>
export type LaborDollarsDailyFragment = DeserializeISOStrings<LaborDollarsDailyFragmentOrig>
export type ImportEventFragment = DeserializeISOStrings<ImportEventFragmentOrig>
export type InsightFragment = DeserializeISOStrings<InsightFragmentOrig>
export type IntervalOrderSumFragment = DeserializeISOStrings<IntervalOrderSumFragmentOrig>
export type IntervalPredictionSumFragment = DeserializeISOStrings<IntervalPredictionSumFragmentOrig>
export type LocationManagementRequestsFragment = DeserializeISOStrings<LocationManagementRequestsFragmentOrig>
export type LocationShiftRolesFragment = DeserializeISOStrings<LocationShiftRolesFragmentOrig>
export type LocationSummaryFragment = DeserializeISOStrings<LocationSummaryFragmentOrig>
export type LocationUserSummaryFragment = DeserializeISOStrings<LocationUserSummaryFragmentOrig>
export type LocationForReportingFragment = DeserializeISOStrings<LocationForReportingFragmentOrig>
export type LocationForModifyShiftFragment = DeserializeISOStrings<LocationForModifyShiftFragmentOrig>
export type LocationFragment = DeserializeISOStrings<LocationFragmentOrig>
export type MassNotificationsFragment = DeserializeISOStrings<MassNotificationsFragmentOrig>
export type OperatingHoursFragment = DeserializeISOStrings<OperatingHoursFragmentOrig>
export type OperatingHoursForDateFragment = DeserializeISOStrings<OperatingHoursForDateFragmentOrig>
export type OrganizationFragment = DeserializeISOStrings<OrganizationFragmentOrig>
export type PermissionFragment = DeserializeISOStrings<PermissionFragmentOrig>
export type LocationTimeOffBlockedDateFragment = DeserializeISOStrings<LocationTimeOffBlockedDateFragmentOrig>
export type TimeOffBlockedDateFragment = DeserializeISOStrings<TimeOffBlockedDateFragmentOrig>
export type TimeOffBlockedDateForFormFragment = DeserializeISOStrings<TimeOffBlockedDateForFormFragmentOrig>
export type PlanSummaryFragment = DeserializeISOStrings<PlanSummaryFragmentOrig>
export type PlanSummaryLightFragment = DeserializeISOStrings<PlanSummaryLightFragmentOrig>
export type PlanSummaryWithDailySummariesFragment = DeserializeISOStrings<PlanSummaryWithDailySummariesFragmentOrig>
export type PlanForReportingFragment = DeserializeISOStrings<PlanForReportingFragmentOrig>
export type ToucanAuthFragment = DeserializeISOStrings<ToucanAuthFragmentOrig>
export type PlanFragment = DeserializeISOStrings<PlanFragmentOrig>
export type RoleFragment = DeserializeISOStrings<RoleFragmentOrig>
export type ShiftRoleCategoryFragment = DeserializeISOStrings<ShiftRoleCategoryFragmentOrig>
export type ShiftRoleFragment = DeserializeISOStrings<ShiftRoleFragmentOrig>
export type ShiftSummaryFragment = DeserializeISOStrings<ShiftSummaryFragmentOrig>
export type ShiftFragment = DeserializeISOStrings<ShiftFragmentOrig>
export type SalesAndLaborSummaryFragment = DeserializeISOStrings<SalesAndLaborSummaryFragmentOrig>
export type TopEventFragment = DeserializeISOStrings<TopEventFragmentOrig>
export type CurrentUserInfoFragment = DeserializeISOStrings<CurrentUserInfoFragmentOrig>
export type ViolationFragment = DeserializeISOStrings<ViolationFragmentOrig>
export type CovidDashboardFragment = DeserializeISOStrings<CovidDashboardFragmentOrig>
export type PlanTemplateFragment = DeserializeISOStrings<PlanTemplateFragmentOrig>
export type DaysAvailableFragment = DeserializeISOStrings<DaysAvailableFragmentOrig>
export type TimeSlotFragment = DeserializeISOStrings<TimeSlotFragmentOrig>
export type EmployeeAvailabilityFragment = DeserializeISOStrings<EmployeeAvailabilityFragmentOrig>
export type EmployeeAvailabilitySummaryFragment = DeserializeISOStrings<EmployeeAvailabilitySummaryFragmentOrig>
export type TimeOffRequestFragment = DeserializeISOStrings<TimeOffRequestFragmentOrig>
export type TimeOffRequestSummaryFragment = DeserializeISOStrings<TimeOffRequestSummaryFragmentOrig>
export type ShiftSwapRequestFragment = DeserializeISOStrings<ShiftSwapRequestFragmentOrig>
export type MinimumLaborBaselineFragment = DeserializeISOStrings<MinimumLaborBaselineFragmentOrig>
export type OrganizationManagementRequestsFragment = DeserializeISOStrings<OrganizationManagementRequestsFragmentOrig>
export type OrganizationSettingsFragment = DeserializeISOStrings<OrganizationSettingsFragmentOrig>
export type UserForOrganizationSettingsFragment = DeserializeISOStrings<UserForOrganizationSettingsFragmentOrig>
export type ActiveLocationShiftRoleFragment = DeserializeISOStrings<ActiveLocationShiftRoleFragmentOrig>
export type SalesDayPartFragment = DeserializeISOStrings<SalesDayPartFragmentOrig>

import { useGetLocationMessagesQuery as useGetLocationMessagesQueryOrig } from 'config/graphqlTypesRaw'
import { GetLocationMessagesQueryVariables as GetLocationMessagesQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLocationMessagesQuery as GetLocationMessagesQueryOrig } from 'config/graphqlTypesRaw'
export { GetLocationMessagesDocument } from 'config/graphqlTypesRaw'
import { useGetTimeOffBlockedDateQuery as useGetTimeOffBlockedDateQueryOrig } from 'config/graphqlTypesRaw'
import { GetTimeOffBlockedDateQueryVariables as GetTimeOffBlockedDateQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetTimeOffBlockedDateQuery as GetTimeOffBlockedDateQueryOrig } from 'config/graphqlTypesRaw'
export { GetTimeOffBlockedDateDocument } from 'config/graphqlTypesRaw'
import { useGetTimeOffBlockedDatesQuery as useGetTimeOffBlockedDatesQueryOrig } from 'config/graphqlTypesRaw'
import { GetTimeOffBlockedDatesQueryVariables as GetTimeOffBlockedDatesQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetTimeOffBlockedDatesQuery as GetTimeOffBlockedDatesQueryOrig } from 'config/graphqlTypesRaw'
export { GetTimeOffBlockedDatesDocument } from 'config/graphqlTypesRaw'
import { useGetEmployeeLocationsListingQuery as useGetEmployeeLocationsListingQueryOrig } from 'config/graphqlTypesRaw'
import { GetEmployeeLocationsListingQueryVariables as GetEmployeeLocationsListingQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetEmployeeLocationsListingQuery as GetEmployeeLocationsListingQueryOrig } from 'config/graphqlTypesRaw'
export { GetEmployeeLocationsListingDocument } from 'config/graphqlTypesRaw'
import { useGetEmployeeLocationQuery as useGetEmployeeLocationQueryOrig } from 'config/graphqlTypesRaw'
import { GetEmployeeLocationQueryVariables as GetEmployeeLocationQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetEmployeeLocationQuery as GetEmployeeLocationQueryOrig } from 'config/graphqlTypesRaw'
export { GetEmployeeLocationDocument } from 'config/graphqlTypesRaw'
import { useGetEmployeeForEditQuery as useGetEmployeeForEditQueryOrig } from 'config/graphqlTypesRaw'
import { GetEmployeeForEditQueryVariables as GetEmployeeForEditQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetEmployeeForEditQuery as GetEmployeeForEditQueryOrig } from 'config/graphqlTypesRaw'
export { GetEmployeeForEditDocument } from 'config/graphqlTypesRaw'
import { useGetFeatureTogglesQuery as useGetFeatureTogglesQueryOrig } from 'config/graphqlTypesRaw'
import { GetFeatureTogglesQueryVariables as GetFeatureTogglesQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetFeatureTogglesQuery as GetFeatureTogglesQueryOrig } from 'config/graphqlTypesRaw'
export { GetFeatureTogglesDocument } from 'config/graphqlTypesRaw'
import { useGetLatestSuccessfulImportEventQuery as useGetLatestSuccessfulImportEventQueryOrig } from 'config/graphqlTypesRaw'
import { GetLatestSuccessfulImportEventQueryVariables as GetLatestSuccessfulImportEventQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLatestSuccessfulImportEventQuery as GetLatestSuccessfulImportEventQueryOrig } from 'config/graphqlTypesRaw'
export { GetLatestSuccessfulImportEventDocument } from 'config/graphqlTypesRaw'
import { useGetInsightQuery as useGetInsightQueryOrig } from 'config/graphqlTypesRaw'
import { GetInsightQueryVariables as GetInsightQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetInsightQuery as GetInsightQueryOrig } from 'config/graphqlTypesRaw'
export { GetInsightDocument } from 'config/graphqlTypesRaw'
import { useGetIntervalOrderSumsQuery as useGetIntervalOrderSumsQueryOrig } from 'config/graphqlTypesRaw'
import { GetIntervalOrderSumsQueryVariables as GetIntervalOrderSumsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetIntervalOrderSumsQuery as GetIntervalOrderSumsQueryOrig } from 'config/graphqlTypesRaw'
export { GetIntervalOrderSumsDocument } from 'config/graphqlTypesRaw'
import { useGetIntervalPredictionSumsQuery as useGetIntervalPredictionSumsQueryOrig } from 'config/graphqlTypesRaw'
import { GetIntervalPredictionSumsQueryVariables as GetIntervalPredictionSumsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetIntervalPredictionSumsQuery as GetIntervalPredictionSumsQueryOrig } from 'config/graphqlTypesRaw'
export { GetIntervalPredictionSumsDocument } from 'config/graphqlTypesRaw'
import { useGetDailyHistoricalPredictionsQuery as useGetDailyHistoricalPredictionsQueryOrig } from 'config/graphqlTypesRaw'
import { GetDailyHistoricalPredictionsQueryVariables as GetDailyHistoricalPredictionsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetDailyHistoricalPredictionsQuery as GetDailyHistoricalPredictionsQueryOrig } from 'config/graphqlTypesRaw'
export { GetDailyHistoricalPredictionsDocument } from 'config/graphqlTypesRaw'
import { useGetLocationForScheduleQuery as useGetLocationForScheduleQueryOrig } from 'config/graphqlTypesRaw'
import { GetLocationForScheduleQueryVariables as GetLocationForScheduleQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLocationForScheduleQuery as GetLocationForScheduleQueryOrig } from 'config/graphqlTypesRaw'
export { GetLocationForScheduleDocument } from 'config/graphqlTypesRaw'
import { useGetLocationForModifyShiftQuery as useGetLocationForModifyShiftQueryOrig } from 'config/graphqlTypesRaw'
import { GetLocationForModifyShiftQueryVariables as GetLocationForModifyShiftQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLocationForModifyShiftQuery as GetLocationForModifyShiftQueryOrig } from 'config/graphqlTypesRaw'
export { GetLocationForModifyShiftDocument } from 'config/graphqlTypesRaw'
import { useGetLocationManagementRequestsQuery as useGetLocationManagementRequestsQueryOrig } from 'config/graphqlTypesRaw'
import { GetLocationManagementRequestsQueryVariables as GetLocationManagementRequestsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLocationManagementRequestsQuery as GetLocationManagementRequestsQueryOrig } from 'config/graphqlTypesRaw'
export { GetLocationManagementRequestsDocument } from 'config/graphqlTypesRaw'
import { useGetLocationShiftRolesQuery as useGetLocationShiftRolesQueryOrig } from 'config/graphqlTypesRaw'
import { GetLocationShiftRolesQueryVariables as GetLocationShiftRolesQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLocationShiftRolesQuery as GetLocationShiftRolesQueryOrig } from 'config/graphqlTypesRaw'
export { GetLocationShiftRolesDocument } from 'config/graphqlTypesRaw'
import { useGetMeQuery as useGetMeQueryOrig } from 'config/graphqlTypesRaw'
import { GetMeQueryVariables as GetMeQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetMeQuery as GetMeQueryOrig } from 'config/graphqlTypesRaw'
export { GetMeDocument } from 'config/graphqlTypesRaw'
import { useGetUsersForLocationQuery as useGetUsersForLocationQueryOrig } from 'config/graphqlTypesRaw'
import { GetUsersForLocationQueryVariables as GetUsersForLocationQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetUsersForLocationQuery as GetUsersForLocationQueryOrig } from 'config/graphqlTypesRaw'
export { GetUsersForLocationDocument } from 'config/graphqlTypesRaw'
import { useGetOperatingHoursQuery as useGetOperatingHoursQueryOrig } from 'config/graphqlTypesRaw'
import { GetOperatingHoursQueryVariables as GetOperatingHoursQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetOperatingHoursQuery as GetOperatingHoursQueryOrig } from 'config/graphqlTypesRaw'
export { GetOperatingHoursDocument } from 'config/graphqlTypesRaw'
import { useGetOperatingHoursForDateQuery as useGetOperatingHoursForDateQueryOrig } from 'config/graphqlTypesRaw'
import { GetOperatingHoursForDateQueryVariables as GetOperatingHoursForDateQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetOperatingHoursForDateQuery as GetOperatingHoursForDateQueryOrig } from 'config/graphqlTypesRaw'
export { GetOperatingHoursForDateDocument } from 'config/graphqlTypesRaw'
import { useGetLocationShiftRoleActiveQuery as useGetLocationShiftRoleActiveQueryOrig } from 'config/graphqlTypesRaw'
import { GetLocationShiftRoleActiveQueryVariables as GetLocationShiftRoleActiveQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLocationShiftRoleActiveQuery as GetLocationShiftRoleActiveQueryOrig } from 'config/graphqlTypesRaw'
export { GetLocationShiftRoleActiveDocument } from 'config/graphqlTypesRaw'
import { useGetLocationShiftRolesActiveQuery as useGetLocationShiftRolesActiveQueryOrig } from 'config/graphqlTypesRaw'
import { GetLocationShiftRolesActiveQueryVariables as GetLocationShiftRolesActiveQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLocationShiftRolesActiveQuery as GetLocationShiftRolesActiveQueryOrig } from 'config/graphqlTypesRaw'
export { GetLocationShiftRolesActiveDocument } from 'config/graphqlTypesRaw'
import { useGetToucanAuthQuery as useGetToucanAuthQueryOrig } from 'config/graphqlTypesRaw'
import { GetToucanAuthQueryVariables as GetToucanAuthQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetToucanAuthQuery as GetToucanAuthQueryOrig } from 'config/graphqlTypesRaw'
export { GetToucanAuthDocument } from 'config/graphqlTypesRaw'
import { useGetPlanQuery as useGetPlanQueryOrig } from 'config/graphqlTypesRaw'
import { GetPlanQueryVariables as GetPlanQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetPlanQuery as GetPlanQueryOrig } from 'config/graphqlTypesRaw'
export { GetPlanDocument } from 'config/graphqlTypesRaw'
import { useGetPlanForReportingQuery as useGetPlanForReportingQueryOrig } from 'config/graphqlTypesRaw'
import { GetPlanForReportingQueryVariables as GetPlanForReportingQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetPlanForReportingQuery as GetPlanForReportingQueryOrig } from 'config/graphqlTypesRaw'
export { GetPlanForReportingDocument } from 'config/graphqlTypesRaw'
import { useGetLaborDollarsQuery as useGetLaborDollarsQueryOrig } from 'config/graphqlTypesRaw'
import { GetLaborDollarsQueryVariables as GetLaborDollarsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLaborDollarsQuery as GetLaborDollarsQueryOrig } from 'config/graphqlTypesRaw'
export { GetLaborDollarsDocument } from 'config/graphqlTypesRaw'
import { useGetTopEventsQuery as useGetTopEventsQueryOrig } from 'config/graphqlTypesRaw'
import { GetTopEventsQueryVariables as GetTopEventsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetTopEventsQuery as GetTopEventsQueryOrig } from 'config/graphqlTypesRaw'
export { GetTopEventsDocument } from 'config/graphqlTypesRaw'
import { useGetWeeklyPlansQuery as useGetWeeklyPlansQueryOrig } from 'config/graphqlTypesRaw'
import { GetWeeklyPlansQueryVariables as GetWeeklyPlansQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetWeeklyPlansQuery as GetWeeklyPlansQueryOrig } from 'config/graphqlTypesRaw'
export { GetWeeklyPlansDocument } from 'config/graphqlTypesRaw'
import { useGetForecastedItemsQuery as useGetForecastedItemsQueryOrig } from 'config/graphqlTypesRaw'
import { GetForecastedItemsQueryVariables as GetForecastedItemsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetForecastedItemsQuery as GetForecastedItemsQueryOrig } from 'config/graphqlTypesRaw'
export { GetForecastedItemsDocument } from 'config/graphqlTypesRaw'
import { useGetWeeklyPlanSummariesQuery as useGetWeeklyPlanSummariesQueryOrig } from 'config/graphqlTypesRaw'
import { GetWeeklyPlanSummariesQueryVariables as GetWeeklyPlanSummariesQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetWeeklyPlanSummariesQuery as GetWeeklyPlanSummariesQueryOrig } from 'config/graphqlTypesRaw'
export { GetWeeklyPlanSummariesDocument } from 'config/graphqlTypesRaw'
import { useGetWeeklyPlanSummariesWithDailySummariesQuery as useGetWeeklyPlanSummariesWithDailySummariesQueryOrig } from 'config/graphqlTypesRaw'
import { GetWeeklyPlanSummariesWithDailySummariesQueryVariables as GetWeeklyPlanSummariesWithDailySummariesQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetWeeklyPlanSummariesWithDailySummariesQuery as GetWeeklyPlanSummariesWithDailySummariesQueryOrig } from 'config/graphqlTypesRaw'
export { GetWeeklyPlanSummariesWithDailySummariesDocument } from 'config/graphqlTypesRaw'
import { useGetWeatherCurrentQuery as useGetWeatherCurrentQueryOrig } from 'config/graphqlTypesRaw'
import { GetWeatherCurrentQueryVariables as GetWeatherCurrentQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetWeatherCurrentQuery as GetWeatherCurrentQueryOrig } from 'config/graphqlTypesRaw'
export { GetWeatherCurrentDocument } from 'config/graphqlTypesRaw'
import { useGetWeatherDailyQuery as useGetWeatherDailyQueryOrig } from 'config/graphqlTypesRaw'
import { GetWeatherDailyQueryVariables as GetWeatherDailyQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetWeatherDailyQuery as GetWeatherDailyQueryOrig } from 'config/graphqlTypesRaw'
export { GetWeatherDailyDocument } from 'config/graphqlTypesRaw'
import { useGetLocationsForReportingQuery as useGetLocationsForReportingQueryOrig } from 'config/graphqlTypesRaw'
import { GetLocationsForReportingQueryVariables as GetLocationsForReportingQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetLocationsForReportingQuery as GetLocationsForReportingQueryOrig } from 'config/graphqlTypesRaw'
export { GetLocationsForReportingDocument } from 'config/graphqlTypesRaw'
import { usePreviewViolationsQuery as usePreviewViolationsQueryOrig } from 'config/graphqlTypesRaw'
import { PreviewViolationsQueryVariables as PreviewViolationsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { PreviewViolationsQuery as PreviewViolationsQueryOrig } from 'config/graphqlTypesRaw'
export { PreviewViolationsDocument } from 'config/graphqlTypesRaw'
import { useGetViolationsPlanQuery as useGetViolationsPlanQueryOrig } from 'config/graphqlTypesRaw'
import { GetViolationsPlanQueryVariables as GetViolationsPlanQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetViolationsPlanQuery as GetViolationsPlanQueryOrig } from 'config/graphqlTypesRaw'
export { GetViolationsPlanDocument } from 'config/graphqlTypesRaw'
import { useGetCovidDashboardQuery as useGetCovidDashboardQueryOrig } from 'config/graphqlTypesRaw'
import { GetCovidDashboardQueryVariables as GetCovidDashboardQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetCovidDashboardQuery as GetCovidDashboardQueryOrig } from 'config/graphqlTypesRaw'
export { GetCovidDashboardDocument } from 'config/graphqlTypesRaw'
import { useGetPlanTemplatesQuery as useGetPlanTemplatesQueryOrig } from 'config/graphqlTypesRaw'
import { GetPlanTemplatesQueryVariables as GetPlanTemplatesQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetPlanTemplatesQuery as GetPlanTemplatesQueryOrig } from 'config/graphqlTypesRaw'
export { GetPlanTemplatesDocument } from 'config/graphqlTypesRaw'
import { useGetOAuthQuery as useGetOAuthQueryOrig } from 'config/graphqlTypesRaw'
import { GetOAuthQueryVariables as GetOAuthQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetOAuthQuery as GetOAuthQueryOrig } from 'config/graphqlTypesRaw'
export { GetOAuthDocument } from 'config/graphqlTypesRaw'
import { useGetDailyLaborHoursBreakDownQuery as useGetDailyLaborHoursBreakDownQueryOrig } from 'config/graphqlTypesRaw'
import { GetDailyLaborHoursBreakDownQueryVariables as GetDailyLaborHoursBreakDownQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetDailyLaborHoursBreakDownQuery as GetDailyLaborHoursBreakDownQueryOrig } from 'config/graphqlTypesRaw'
export { GetDailyLaborHoursBreakDownDocument } from 'config/graphqlTypesRaw'
import { useGetHoursDateRangeBreakDownQuery as useGetHoursDateRangeBreakDownQueryOrig } from 'config/graphqlTypesRaw'
import { GetHoursDateRangeBreakDownQueryVariables as GetHoursDateRangeBreakDownQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetHoursDateRangeBreakDownQuery as GetHoursDateRangeBreakDownQueryOrig } from 'config/graphqlTypesRaw'
export { GetHoursDateRangeBreakDownDocument } from 'config/graphqlTypesRaw'
import { useGetRelevantShiftsEmployeeQuery as useGetRelevantShiftsEmployeeQueryOrig } from 'config/graphqlTypesRaw'
import { GetRelevantShiftsEmployeeQueryVariables as GetRelevantShiftsEmployeeQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetRelevantShiftsEmployeeQuery as GetRelevantShiftsEmployeeQueryOrig } from 'config/graphqlTypesRaw'
export { GetRelevantShiftsEmployeeDocument } from 'config/graphqlTypesRaw'
import { useGetInternalEventsQuery as useGetInternalEventsQueryOrig } from 'config/graphqlTypesRaw'
import { GetInternalEventsQueryVariables as GetInternalEventsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetInternalEventsQuery as GetInternalEventsQueryOrig } from 'config/graphqlTypesRaw'
export { GetInternalEventsDocument } from 'config/graphqlTypesRaw'
import { useGetSalesDayPartsQuery as useGetSalesDayPartsQueryOrig } from 'config/graphqlTypesRaw'
import { GetSalesDayPartsQueryVariables as GetSalesDayPartsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetSalesDayPartsQuery as GetSalesDayPartsQueryOrig } from 'config/graphqlTypesRaw'
export { GetSalesDayPartsDocument } from 'config/graphqlTypesRaw'
import { useGetReservationsQuery as useGetReservationsQueryOrig } from 'config/graphqlTypesRaw'
import { GetReservationsQueryVariables as GetReservationsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetReservationsQuery as GetReservationsQueryOrig } from 'config/graphqlTypesRaw'
export { GetReservationsDocument } from 'config/graphqlTypesRaw'
import { useGetShiftsPerLocationsQuery as useGetShiftsPerLocationsQueryOrig } from 'config/graphqlTypesRaw'
import { GetShiftsPerLocationsQueryVariables as GetShiftsPerLocationsQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetShiftsPerLocationsQuery as GetShiftsPerLocationsQueryOrig } from 'config/graphqlTypesRaw'
export { GetShiftsPerLocationsDocument } from 'config/graphqlTypesRaw'
import { useGetUsersForOrganizationQuery as useGetUsersForOrganizationQueryOrig } from 'config/graphqlTypesRaw'
import { GetUsersForOrganizationQueryVariables as GetUsersForOrganizationQueryVariablesOrig } from 'config/graphqlTypesRaw'
import { GetUsersForOrganizationQuery as GetUsersForOrganizationQueryOrig } from 'config/graphqlTypesRaw'
export { GetUsersForOrganizationDocument } from 'config/graphqlTypesRaw'

import { wrapQuery } from 'utils/dateSerialization'

export const useGetLocationMessagesQuery = wrapQuery(useGetLocationMessagesQueryOrig)
export type GetLocationMessagesQueryVariables = DeserializeISOStrings<GetLocationMessagesQueryVariablesOrig>
export type GetLocationMessagesQuery = DeserializeISOStrings<GetLocationMessagesQueryOrig>
export const useGetTimeOffBlockedDateQuery = wrapQuery(useGetTimeOffBlockedDateQueryOrig)
export type GetTimeOffBlockedDateQueryVariables = DeserializeISOStrings<GetTimeOffBlockedDateQueryVariablesOrig>
export type GetTimeOffBlockedDateQuery = DeserializeISOStrings<GetTimeOffBlockedDateQueryOrig>
export const useGetTimeOffBlockedDatesQuery = wrapQuery(useGetTimeOffBlockedDatesQueryOrig)
export type GetTimeOffBlockedDatesQueryVariables = DeserializeISOStrings<GetTimeOffBlockedDatesQueryVariablesOrig>
export type GetTimeOffBlockedDatesQuery = DeserializeISOStrings<GetTimeOffBlockedDatesQueryOrig>
export const useGetEmployeeLocationsListingQuery = wrapQuery(useGetEmployeeLocationsListingQueryOrig)
export type GetEmployeeLocationsListingQueryVariables = DeserializeISOStrings<GetEmployeeLocationsListingQueryVariablesOrig>
export type GetEmployeeLocationsListingQuery = DeserializeISOStrings<GetEmployeeLocationsListingQueryOrig>
export const useGetEmployeeLocationQuery = wrapQuery(useGetEmployeeLocationQueryOrig)
export type GetEmployeeLocationQueryVariables = DeserializeISOStrings<GetEmployeeLocationQueryVariablesOrig>
export type GetEmployeeLocationQuery = DeserializeISOStrings<GetEmployeeLocationQueryOrig>
export const useGetEmployeeForEditQuery = wrapQuery(useGetEmployeeForEditQueryOrig)
export type GetEmployeeForEditQueryVariables = DeserializeISOStrings<GetEmployeeForEditQueryVariablesOrig>
export type GetEmployeeForEditQuery = DeserializeISOStrings<GetEmployeeForEditQueryOrig>
export const useGetFeatureTogglesQuery = wrapQuery(useGetFeatureTogglesQueryOrig)
export type GetFeatureTogglesQueryVariables = DeserializeISOStrings<GetFeatureTogglesQueryVariablesOrig>
export type GetFeatureTogglesQuery = DeserializeISOStrings<GetFeatureTogglesQueryOrig>
export const useGetLatestSuccessfulImportEventQuery = wrapQuery(useGetLatestSuccessfulImportEventQueryOrig)
export type GetLatestSuccessfulImportEventQueryVariables = DeserializeISOStrings<GetLatestSuccessfulImportEventQueryVariablesOrig>
export type GetLatestSuccessfulImportEventQuery = DeserializeISOStrings<GetLatestSuccessfulImportEventQueryOrig>
export const useGetInsightQuery = wrapQuery(useGetInsightQueryOrig)
export type GetInsightQueryVariables = DeserializeISOStrings<GetInsightQueryVariablesOrig>
export type GetInsightQuery = DeserializeISOStrings<GetInsightQueryOrig>
export const useGetIntervalOrderSumsQuery = wrapQuery(useGetIntervalOrderSumsQueryOrig)
export type GetIntervalOrderSumsQueryVariables = DeserializeISOStrings<GetIntervalOrderSumsQueryVariablesOrig>
export type GetIntervalOrderSumsQuery = DeserializeISOStrings<GetIntervalOrderSumsQueryOrig>
export const useGetIntervalPredictionSumsQuery = wrapQuery(useGetIntervalPredictionSumsQueryOrig)
export type GetIntervalPredictionSumsQueryVariables = DeserializeISOStrings<GetIntervalPredictionSumsQueryVariablesOrig>
export type GetIntervalPredictionSumsQuery = DeserializeISOStrings<GetIntervalPredictionSumsQueryOrig>
export const useGetDailyHistoricalPredictionsQuery = wrapQuery(useGetDailyHistoricalPredictionsQueryOrig)
export type GetDailyHistoricalPredictionsQueryVariables = DeserializeISOStrings<GetDailyHistoricalPredictionsQueryVariablesOrig>
export type GetDailyHistoricalPredictionsQuery = DeserializeISOStrings<GetDailyHistoricalPredictionsQueryOrig>
export const useGetLocationForScheduleQuery = wrapQuery(useGetLocationForScheduleQueryOrig)
export type GetLocationForScheduleQueryVariables = DeserializeISOStrings<GetLocationForScheduleQueryVariablesOrig>
export type GetLocationForScheduleQuery = DeserializeISOStrings<GetLocationForScheduleQueryOrig>
export const useGetLocationForModifyShiftQuery = wrapQuery(useGetLocationForModifyShiftQueryOrig)
export type GetLocationForModifyShiftQueryVariables = DeserializeISOStrings<GetLocationForModifyShiftQueryVariablesOrig>
export type GetLocationForModifyShiftQuery = DeserializeISOStrings<GetLocationForModifyShiftQueryOrig>
export const useGetLocationManagementRequestsQuery = wrapQuery(useGetLocationManagementRequestsQueryOrig)
export type GetLocationManagementRequestsQueryVariables = DeserializeISOStrings<GetLocationManagementRequestsQueryVariablesOrig>
export type GetLocationManagementRequestsQuery = DeserializeISOStrings<GetLocationManagementRequestsQueryOrig>
export const useGetLocationShiftRolesQuery = wrapQuery(useGetLocationShiftRolesQueryOrig)
export type GetLocationShiftRolesQueryVariables = DeserializeISOStrings<GetLocationShiftRolesQueryVariablesOrig>
export type GetLocationShiftRolesQuery = DeserializeISOStrings<GetLocationShiftRolesQueryOrig>
export const useGetMeQuery = wrapQuery(useGetMeQueryOrig)
export type GetMeQueryVariables = DeserializeISOStrings<GetMeQueryVariablesOrig>
export type GetMeQuery = DeserializeISOStrings<GetMeQueryOrig>
export const useGetUsersForLocationQuery = wrapQuery(useGetUsersForLocationQueryOrig)
export type GetUsersForLocationQueryVariables = DeserializeISOStrings<GetUsersForLocationQueryVariablesOrig>
export type GetUsersForLocationQuery = DeserializeISOStrings<GetUsersForLocationQueryOrig>
export const useGetOperatingHoursQuery = wrapQuery(useGetOperatingHoursQueryOrig)
export type GetOperatingHoursQueryVariables = DeserializeISOStrings<GetOperatingHoursQueryVariablesOrig>
export type GetOperatingHoursQuery = DeserializeISOStrings<GetOperatingHoursQueryOrig>
export const useGetOperatingHoursForDateQuery = wrapQuery(useGetOperatingHoursForDateQueryOrig)
export type GetOperatingHoursForDateQueryVariables = DeserializeISOStrings<GetOperatingHoursForDateQueryVariablesOrig>
export type GetOperatingHoursForDateQuery = DeserializeISOStrings<GetOperatingHoursForDateQueryOrig>
export const useGetLocationShiftRoleActiveQuery = wrapQuery(useGetLocationShiftRoleActiveQueryOrig)
export type GetLocationShiftRoleActiveQueryVariables = DeserializeISOStrings<GetLocationShiftRoleActiveQueryVariablesOrig>
export type GetLocationShiftRoleActiveQuery = DeserializeISOStrings<GetLocationShiftRoleActiveQueryOrig>
export const useGetLocationShiftRolesActiveQuery = wrapQuery(useGetLocationShiftRolesActiveQueryOrig)
export type GetLocationShiftRolesActiveQueryVariables = DeserializeISOStrings<GetLocationShiftRolesActiveQueryVariablesOrig>
export type GetLocationShiftRolesActiveQuery = DeserializeISOStrings<GetLocationShiftRolesActiveQueryOrig>
export const useGetToucanAuthQuery = wrapQuery(useGetToucanAuthQueryOrig)
export type GetToucanAuthQueryVariables = DeserializeISOStrings<GetToucanAuthQueryVariablesOrig>
export type GetToucanAuthQuery = DeserializeISOStrings<GetToucanAuthQueryOrig>
export const useGetPlanQuery = wrapQuery(useGetPlanQueryOrig)
export type GetPlanQueryVariables = DeserializeISOStrings<GetPlanQueryVariablesOrig>
export type GetPlanQuery = DeserializeISOStrings<GetPlanQueryOrig>
export const useGetPlanForReportingQuery = wrapQuery(useGetPlanForReportingQueryOrig)
export type GetPlanForReportingQueryVariables = DeserializeISOStrings<GetPlanForReportingQueryVariablesOrig>
export type GetPlanForReportingQuery = DeserializeISOStrings<GetPlanForReportingQueryOrig>
export const useGetLaborDollarsQuery = wrapQuery(useGetLaborDollarsQueryOrig)
export type GetLaborDollarsQueryVariables = DeserializeISOStrings<GetLaborDollarsQueryVariablesOrig>
export type GetLaborDollarsQuery = DeserializeISOStrings<GetLaborDollarsQueryOrig>
export const useGetTopEventsQuery = wrapQuery(useGetTopEventsQueryOrig)
export type GetTopEventsQueryVariables = DeserializeISOStrings<GetTopEventsQueryVariablesOrig>
export type GetTopEventsQuery = DeserializeISOStrings<GetTopEventsQueryOrig>
export const useGetWeeklyPlansQuery = wrapQuery(useGetWeeklyPlansQueryOrig)
export type GetWeeklyPlansQueryVariables = DeserializeISOStrings<GetWeeklyPlansQueryVariablesOrig>
export type GetWeeklyPlansQuery = DeserializeISOStrings<GetWeeklyPlansQueryOrig>
export const useGetForecastedItemsQuery = wrapQuery(useGetForecastedItemsQueryOrig)
export type GetForecastedItemsQueryVariables = DeserializeISOStrings<GetForecastedItemsQueryVariablesOrig>
export type GetForecastedItemsQuery = DeserializeISOStrings<GetForecastedItemsQueryOrig>
export const useGetWeeklyPlanSummariesQuery = wrapQuery(useGetWeeklyPlanSummariesQueryOrig)
export type GetWeeklyPlanSummariesQueryVariables = DeserializeISOStrings<GetWeeklyPlanSummariesQueryVariablesOrig>
export type GetWeeklyPlanSummariesQuery = DeserializeISOStrings<GetWeeklyPlanSummariesQueryOrig>
export const useGetWeeklyPlanSummariesWithDailySummariesQuery = wrapQuery(useGetWeeklyPlanSummariesWithDailySummariesQueryOrig)
export type GetWeeklyPlanSummariesWithDailySummariesQueryVariables = DeserializeISOStrings<GetWeeklyPlanSummariesWithDailySummariesQueryVariablesOrig>
export type GetWeeklyPlanSummariesWithDailySummariesQuery = DeserializeISOStrings<GetWeeklyPlanSummariesWithDailySummariesQueryOrig>
export const useGetWeatherCurrentQuery = wrapQuery(useGetWeatherCurrentQueryOrig)
export type GetWeatherCurrentQueryVariables = DeserializeISOStrings<GetWeatherCurrentQueryVariablesOrig>
export type GetWeatherCurrentQuery = DeserializeISOStrings<GetWeatherCurrentQueryOrig>
export const useGetWeatherDailyQuery = wrapQuery(useGetWeatherDailyQueryOrig)
export type GetWeatherDailyQueryVariables = DeserializeISOStrings<GetWeatherDailyQueryVariablesOrig>
export type GetWeatherDailyQuery = DeserializeISOStrings<GetWeatherDailyQueryOrig>
export const useGetLocationsForReportingQuery = wrapQuery(useGetLocationsForReportingQueryOrig)
export type GetLocationsForReportingQueryVariables = DeserializeISOStrings<GetLocationsForReportingQueryVariablesOrig>
export type GetLocationsForReportingQuery = DeserializeISOStrings<GetLocationsForReportingQueryOrig>
export const usePreviewViolationsQuery = wrapQuery(usePreviewViolationsQueryOrig)
export type PreviewViolationsQueryVariables = DeserializeISOStrings<PreviewViolationsQueryVariablesOrig>
export type PreviewViolationsQuery = DeserializeISOStrings<PreviewViolationsQueryOrig>
export const useGetViolationsPlanQuery = wrapQuery(useGetViolationsPlanQueryOrig)
export type GetViolationsPlanQueryVariables = DeserializeISOStrings<GetViolationsPlanQueryVariablesOrig>
export type GetViolationsPlanQuery = DeserializeISOStrings<GetViolationsPlanQueryOrig>
export const useGetCovidDashboardQuery = wrapQuery(useGetCovidDashboardQueryOrig)
export type GetCovidDashboardQueryVariables = DeserializeISOStrings<GetCovidDashboardQueryVariablesOrig>
export type GetCovidDashboardQuery = DeserializeISOStrings<GetCovidDashboardQueryOrig>
export const useGetPlanTemplatesQuery = wrapQuery(useGetPlanTemplatesQueryOrig)
export type GetPlanTemplatesQueryVariables = DeserializeISOStrings<GetPlanTemplatesQueryVariablesOrig>
export type GetPlanTemplatesQuery = DeserializeISOStrings<GetPlanTemplatesQueryOrig>
export const useGetOAuthQuery = wrapQuery(useGetOAuthQueryOrig)
export type GetOAuthQueryVariables = DeserializeISOStrings<GetOAuthQueryVariablesOrig>
export type GetOAuthQuery = DeserializeISOStrings<GetOAuthQueryOrig>
export const useGetDailyLaborHoursBreakDownQuery = wrapQuery(useGetDailyLaborHoursBreakDownQueryOrig)
export type GetDailyLaborHoursBreakDownQueryVariables = DeserializeISOStrings<GetDailyLaborHoursBreakDownQueryVariablesOrig>
export type GetDailyLaborHoursBreakDownQuery = DeserializeISOStrings<GetDailyLaborHoursBreakDownQueryOrig>
export const useGetHoursDateRangeBreakDownQuery = wrapQuery(useGetHoursDateRangeBreakDownQueryOrig)
export type GetHoursDateRangeBreakDownQueryVariables = DeserializeISOStrings<GetHoursDateRangeBreakDownQueryVariablesOrig>
export type GetHoursDateRangeBreakDownQuery = DeserializeISOStrings<GetHoursDateRangeBreakDownQueryOrig>
export const useGetRelevantShiftsEmployeeQuery = wrapQuery(useGetRelevantShiftsEmployeeQueryOrig)
export type GetRelevantShiftsEmployeeQueryVariables = DeserializeISOStrings<GetRelevantShiftsEmployeeQueryVariablesOrig>
export type GetRelevantShiftsEmployeeQuery = DeserializeISOStrings<GetRelevantShiftsEmployeeQueryOrig>
export const useGetInternalEventsQuery = wrapQuery(useGetInternalEventsQueryOrig)
export type GetInternalEventsQueryVariables = DeserializeISOStrings<GetInternalEventsQueryVariablesOrig>
export type GetInternalEventsQuery = DeserializeISOStrings<GetInternalEventsQueryOrig>
export const useGetSalesDayPartsQuery = wrapQuery(useGetSalesDayPartsQueryOrig)
export type GetSalesDayPartsQueryVariables = DeserializeISOStrings<GetSalesDayPartsQueryVariablesOrig>
export type GetSalesDayPartsQuery = DeserializeISOStrings<GetSalesDayPartsQueryOrig>
export const useGetReservationsQuery = wrapQuery(useGetReservationsQueryOrig)
export type GetReservationsQueryVariables = DeserializeISOStrings<GetReservationsQueryVariablesOrig>
export type GetReservationsQuery = DeserializeISOStrings<GetReservationsQueryOrig>
export const useGetShiftsPerLocationsQuery = wrapQuery(useGetShiftsPerLocationsQueryOrig)
export type GetShiftsPerLocationsQueryVariables = DeserializeISOStrings<GetShiftsPerLocationsQueryVariablesOrig>
export type GetShiftsPerLocationsQuery = DeserializeISOStrings<GetShiftsPerLocationsQueryOrig>
export const useGetUsersForOrganizationQuery = wrapQuery(useGetUsersForOrganizationQueryOrig)
export type GetUsersForOrganizationQueryVariables = DeserializeISOStrings<GetUsersForOrganizationQueryVariablesOrig>
export type GetUsersForOrganizationQuery = DeserializeISOStrings<GetUsersForOrganizationQueryOrig>

import { useUpsertTimeOffBlockedDateMutation as useUpsertTimeOffBlockedDateMutationOrig } from 'config/graphqlTypesRaw'
import { UpsertTimeOffBlockedDateMutationVariables as UpsertTimeOffBlockedDateMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpsertTimeOffBlockedDateDocument } from 'config/graphqlTypesRaw'
import { useDeleteTimeOffBlockedDateMutation as useDeleteTimeOffBlockedDateMutationOrig } from 'config/graphqlTypesRaw'
import { DeleteTimeOffBlockedDateMutationVariables as DeleteTimeOffBlockedDateMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { DeleteTimeOffBlockedDateDocument } from 'config/graphqlTypesRaw'
import { useUpsertLocationTimeOffBlockedDatesMutation as useUpsertLocationTimeOffBlockedDatesMutationOrig } from 'config/graphqlTypesRaw'
import { UpsertLocationTimeOffBlockedDatesMutationVariables as UpsertLocationTimeOffBlockedDatesMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpsertLocationTimeOffBlockedDatesDocument } from 'config/graphqlTypesRaw'
import { useUpsertSalesDayPartsMutation as useUpsertSalesDayPartsMutationOrig } from 'config/graphqlTypesRaw'
import { UpsertSalesDayPartsMutationVariables as UpsertSalesDayPartsMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpsertSalesDayPartsDocument } from 'config/graphqlTypesRaw'
import { useDeleteSalesDayPartsMutation as useDeleteSalesDayPartsMutationOrig } from 'config/graphqlTypesRaw'
import { DeleteSalesDayPartsMutationVariables as DeleteSalesDayPartsMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { DeleteSalesDayPartsDocument } from 'config/graphqlTypesRaw'
import { useCreateEmployeeMutation as useCreateEmployeeMutationOrig } from 'config/graphqlTypesRaw'
import { CreateEmployeeMutationVariables as CreateEmployeeMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CreateEmployeeDocument } from 'config/graphqlTypesRaw'
import { useUpdateEmployeeMutation as useUpdateEmployeeMutationOrig } from 'config/graphqlTypesRaw'
import { UpdateEmployeeMutationVariables as UpdateEmployeeMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpdateEmployeeDocument } from 'config/graphqlTypesRaw'
import { useUpdateEmployeeMinMaxHoursMutation as useUpdateEmployeeMinMaxHoursMutationOrig } from 'config/graphqlTypesRaw'
import { UpdateEmployeeMinMaxHoursMutationVariables as UpdateEmployeeMinMaxHoursMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpdateEmployeeMinMaxHoursDocument } from 'config/graphqlTypesRaw'
import { useUpdateOrganizationMutation as useUpdateOrganizationMutationOrig } from 'config/graphqlTypesRaw'
import { UpdateOrganizationMutationVariables as UpdateOrganizationMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpdateOrganizationDocument } from 'config/graphqlTypesRaw'
import { useUpdateLocationMutation as useUpdateLocationMutationOrig } from 'config/graphqlTypesRaw'
import { UpdateLocationMutationVariables as UpdateLocationMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpdateLocationDocument } from 'config/graphqlTypesRaw'
import { useUpdateEmployeeLocationsMutation as useUpdateEmployeeLocationsMutationOrig } from 'config/graphqlTypesRaw'
import { UpdateEmployeeLocationsMutationVariables as UpdateEmployeeLocationsMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpdateEmployeeLocationsDocument } from 'config/graphqlTypesRaw'
import { useDeleteEmployeeLocationsMutation as useDeleteEmployeeLocationsMutationOrig } from 'config/graphqlTypesRaw'
import { DeleteEmployeeLocationsMutationVariables as DeleteEmployeeLocationsMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { DeleteEmployeeLocationsDocument } from 'config/graphqlTypesRaw'
import { useSquareRevokeAuthorizationMutation as useSquareRevokeAuthorizationMutationOrig } from 'config/graphqlTypesRaw'
import { SquareRevokeAuthorizationMutationVariables as SquareRevokeAuthorizationMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { SquareRevokeAuthorizationDocument } from 'config/graphqlTypesRaw'
import { useSquareHistoricImportMutation as useSquareHistoricImportMutationOrig } from 'config/graphqlTypesRaw'
import { SquareHistoricImportMutationVariables as SquareHistoricImportMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { SquareHistoricImportDocument } from 'config/graphqlTypesRaw'
import { useCreateSessionMutation as useCreateSessionMutationOrig } from 'config/graphqlTypesRaw'
import { CreateSessionMutationVariables as CreateSessionMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CreateSessionDocument } from 'config/graphqlTypesRaw'
import { useDeleteForecastMutation as useDeleteForecastMutationOrig } from 'config/graphqlTypesRaw'
import { DeleteForecastMutationVariables as DeleteForecastMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { DeleteForecastDocument } from 'config/graphqlTypesRaw'
import { useDeleteSessionMutation as useDeleteSessionMutationOrig } from 'config/graphqlTypesRaw'
import { DeleteSessionMutationVariables as DeleteSessionMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { DeleteSessionDocument } from 'config/graphqlTypesRaw'
import { useRequestPasswordResetMutation as useRequestPasswordResetMutationOrig } from 'config/graphqlTypesRaw'
import { RequestPasswordResetMutationVariables as RequestPasswordResetMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { RequestPasswordResetDocument } from 'config/graphqlTypesRaw'
import { useResetPasswordMutation as useResetPasswordMutationOrig } from 'config/graphqlTypesRaw'
import { ResetPasswordMutationVariables as ResetPasswordMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { ResetPasswordDocument } from 'config/graphqlTypesRaw'
import { useUpsertForecastMutation as useUpsertForecastMutationOrig } from 'config/graphqlTypesRaw'
import { UpsertForecastMutationVariables as UpsertForecastMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpsertForecastDocument } from 'config/graphqlTypesRaw'
import { useUpsertInsightMutation as useUpsertInsightMutationOrig } from 'config/graphqlTypesRaw'
import { UpsertInsightMutationVariables as UpsertInsightMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpsertInsightDocument } from 'config/graphqlTypesRaw'
import { useUpdatePlanMutation as useUpdatePlanMutationOrig } from 'config/graphqlTypesRaw'
import { UpdatePlanMutationVariables as UpdatePlanMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpdatePlanDocument } from 'config/graphqlTypesRaw'
import { useClearPlanShiftsMutation as useClearPlanShiftsMutationOrig } from 'config/graphqlTypesRaw'
import { ClearPlanShiftsMutationVariables as ClearPlanShiftsMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { ClearPlanShiftsDocument } from 'config/graphqlTypesRaw'
import { useResetPlanStateMutation as useResetPlanStateMutationOrig } from 'config/graphqlTypesRaw'
import { ResetPlanStateMutationVariables as ResetPlanStateMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { ResetPlanStateDocument } from 'config/graphqlTypesRaw'
import { useCreateShiftMutation as useCreateShiftMutationOrig } from 'config/graphqlTypesRaw'
import { CreateShiftMutationVariables as CreateShiftMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CreateShiftDocument } from 'config/graphqlTypesRaw'
import { useUpdateShiftMutation as useUpdateShiftMutationOrig } from 'config/graphqlTypesRaw'
import { UpdateShiftMutationVariables as UpdateShiftMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpdateShiftDocument } from 'config/graphqlTypesRaw'
import { useDeleteShiftMutation as useDeleteShiftMutationOrig } from 'config/graphqlTypesRaw'
import { DeleteShiftMutationVariables as DeleteShiftMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { DeleteShiftDocument } from 'config/graphqlTypesRaw'
import { useCopyLastWeekShiftsMutation as useCopyLastWeekShiftsMutationOrig } from 'config/graphqlTypesRaw'
import { CopyLastWeekShiftsMutationVariables as CopyLastWeekShiftsMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CopyLastWeekShiftsDocument } from 'config/graphqlTypesRaw'
import { useCreateAutoScheduleMutation as useCreateAutoScheduleMutationOrig } from 'config/graphqlTypesRaw'
import { CreateAutoScheduleMutationVariables as CreateAutoScheduleMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CreateAutoScheduleDocument } from 'config/graphqlTypesRaw'
import { useCreateCovidDashboardMutation as useCreateCovidDashboardMutationOrig } from 'config/graphqlTypesRaw'
import { CreateCovidDashboardMutationVariables as CreateCovidDashboardMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CreateCovidDashboardDocument } from 'config/graphqlTypesRaw'
import { useUpdateCovidDashboardMutation as useUpdateCovidDashboardMutationOrig } from 'config/graphqlTypesRaw'
import { UpdateCovidDashboardMutationVariables as UpdateCovidDashboardMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpdateCovidDashboardDocument } from 'config/graphqlTypesRaw'
import { useCreatePlanTemplateMutation as useCreatePlanTemplateMutationOrig } from 'config/graphqlTypesRaw'
import { CreatePlanTemplateMutationVariables as CreatePlanTemplateMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CreatePlanTemplateDocument } from 'config/graphqlTypesRaw'
import { useCreateWeeklyPlanExportMutation as useCreateWeeklyPlanExportMutationOrig } from 'config/graphqlTypesRaw'
import { CreateWeeklyPlanExportMutationVariables as CreateWeeklyPlanExportMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CreateWeeklyPlanExportDocument } from 'config/graphqlTypesRaw'
import { useOnboardingOAuthMutation as useOnboardingOAuthMutationOrig } from 'config/graphqlTypesRaw'
import { OnboardingOAuthMutationVariables as OnboardingOAuthMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { OnboardingOAuthDocument } from 'config/graphqlTypesRaw'
import { useCopyShiftsFromPlanTemplateMutation as useCopyShiftsFromPlanTemplateMutationOrig } from 'config/graphqlTypesRaw'
import { CopyShiftsFromPlanTemplateMutationVariables as CopyShiftsFromPlanTemplateMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CopyShiftsFromPlanTemplateDocument } from 'config/graphqlTypesRaw'
import { useApproveManagementRequestMutation as useApproveManagementRequestMutationOrig } from 'config/graphqlTypesRaw'
import { ApproveManagementRequestMutationVariables as ApproveManagementRequestMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { ApproveManagementRequestDocument } from 'config/graphqlTypesRaw'
import { useSignupMutation as useSignupMutationOrig } from 'config/graphqlTypesRaw'
import { SignupMutationVariables as SignupMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { SignupDocument } from 'config/graphqlTypesRaw'
import { useCreateMassNotificationMutation as useCreateMassNotificationMutationOrig } from 'config/graphqlTypesRaw'
import { CreateMassNotificationMutationVariables as CreateMassNotificationMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { CreateMassNotificationDocument } from 'config/graphqlTypesRaw'
import { useUpsertEmployeeAvailabilityMutation as useUpsertEmployeeAvailabilityMutationOrig } from 'config/graphqlTypesRaw'
import { UpsertEmployeeAvailabilityMutationVariables as UpsertEmployeeAvailabilityMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpsertEmployeeAvailabilityDocument } from 'config/graphqlTypesRaw'
import { useUpdateOperatingHoursMutation as useUpdateOperatingHoursMutationOrig } from 'config/graphqlTypesRaw'
import { UpdateOperatingHoursMutationVariables as UpdateOperatingHoursMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { UpdateOperatingHoursDocument } from 'config/graphqlTypesRaw'
import { useResetDemoLocationMutation as useResetDemoLocationMutationOrig } from 'config/graphqlTypesRaw'
import { ResetDemoLocationMutationVariables as ResetDemoLocationMutationVariablesOrig } from 'config/graphqlTypesRaw'
export { ResetDemoLocationDocument } from 'config/graphqlTypesRaw'

import { wrapMutation } from 'utils/dateSerialization'

export const useUpsertTimeOffBlockedDateMutation = wrapMutation(useUpsertTimeOffBlockedDateMutationOrig)
export type UpsertTimeOffBlockedDateMutationVariables = DeserializeISOStrings<UpsertTimeOffBlockedDateMutationVariablesOrig>
export const useDeleteTimeOffBlockedDateMutation = wrapMutation(useDeleteTimeOffBlockedDateMutationOrig)
export type DeleteTimeOffBlockedDateMutationVariables = DeserializeISOStrings<DeleteTimeOffBlockedDateMutationVariablesOrig>
export const useUpsertLocationTimeOffBlockedDatesMutation = wrapMutation(useUpsertLocationTimeOffBlockedDatesMutationOrig)
export type UpsertLocationTimeOffBlockedDatesMutationVariables = DeserializeISOStrings<UpsertLocationTimeOffBlockedDatesMutationVariablesOrig>
export const useUpsertSalesDayPartsMutation = wrapMutation(useUpsertSalesDayPartsMutationOrig)
export type UpsertSalesDayPartsMutationVariables = DeserializeISOStrings<UpsertSalesDayPartsMutationVariablesOrig>
export const useDeleteSalesDayPartsMutation = wrapMutation(useDeleteSalesDayPartsMutationOrig)
export type DeleteSalesDayPartsMutationVariables = DeserializeISOStrings<DeleteSalesDayPartsMutationVariablesOrig>
export const useCreateEmployeeMutation = wrapMutation(useCreateEmployeeMutationOrig)
export type CreateEmployeeMutationVariables = DeserializeISOStrings<CreateEmployeeMutationVariablesOrig>
export const useUpdateEmployeeMutation = wrapMutation(useUpdateEmployeeMutationOrig)
export type UpdateEmployeeMutationVariables = DeserializeISOStrings<UpdateEmployeeMutationVariablesOrig>
export const useUpdateEmployeeMinMaxHoursMutation = wrapMutation(useUpdateEmployeeMinMaxHoursMutationOrig)
export type UpdateEmployeeMinMaxHoursMutationVariables = DeserializeISOStrings<UpdateEmployeeMinMaxHoursMutationVariablesOrig>
export const useUpdateOrganizationMutation = wrapMutation(useUpdateOrganizationMutationOrig)
export type UpdateOrganizationMutationVariables = DeserializeISOStrings<UpdateOrganizationMutationVariablesOrig>
export const useUpdateLocationMutation = wrapMutation(useUpdateLocationMutationOrig)
export type UpdateLocationMutationVariables = DeserializeISOStrings<UpdateLocationMutationVariablesOrig>
export const useUpdateEmployeeLocationsMutation = wrapMutation(useUpdateEmployeeLocationsMutationOrig)
export type UpdateEmployeeLocationsMutationVariables = DeserializeISOStrings<UpdateEmployeeLocationsMutationVariablesOrig>
export const useDeleteEmployeeLocationsMutation = wrapMutation(useDeleteEmployeeLocationsMutationOrig)
export type DeleteEmployeeLocationsMutationVariables = DeserializeISOStrings<DeleteEmployeeLocationsMutationVariablesOrig>
export const useSquareRevokeAuthorizationMutation = wrapMutation(useSquareRevokeAuthorizationMutationOrig)
export type SquareRevokeAuthorizationMutationVariables = DeserializeISOStrings<SquareRevokeAuthorizationMutationVariablesOrig>
export const useSquareHistoricImportMutation = wrapMutation(useSquareHistoricImportMutationOrig)
export type SquareHistoricImportMutationVariables = DeserializeISOStrings<SquareHistoricImportMutationVariablesOrig>
export const useCreateSessionMutation = wrapMutation(useCreateSessionMutationOrig)
export type CreateSessionMutationVariables = DeserializeISOStrings<CreateSessionMutationVariablesOrig>
export const useDeleteForecastMutation = wrapMutation(useDeleteForecastMutationOrig)
export type DeleteForecastMutationVariables = DeserializeISOStrings<DeleteForecastMutationVariablesOrig>
export const useDeleteSessionMutation = wrapMutation(useDeleteSessionMutationOrig)
export type DeleteSessionMutationVariables = DeserializeISOStrings<DeleteSessionMutationVariablesOrig>
export const useRequestPasswordResetMutation = wrapMutation(useRequestPasswordResetMutationOrig)
export type RequestPasswordResetMutationVariables = DeserializeISOStrings<RequestPasswordResetMutationVariablesOrig>
export const useResetPasswordMutation = wrapMutation(useResetPasswordMutationOrig)
export type ResetPasswordMutationVariables = DeserializeISOStrings<ResetPasswordMutationVariablesOrig>
export const useUpsertForecastMutation = wrapMutation(useUpsertForecastMutationOrig)
export type UpsertForecastMutationVariables = DeserializeISOStrings<UpsertForecastMutationVariablesOrig>
export const useUpsertInsightMutation = wrapMutation(useUpsertInsightMutationOrig)
export type UpsertInsightMutationVariables = DeserializeISOStrings<UpsertInsightMutationVariablesOrig>
export const useUpdatePlanMutation = wrapMutation(useUpdatePlanMutationOrig)
export type UpdatePlanMutationVariables = DeserializeISOStrings<UpdatePlanMutationVariablesOrig>
export const useClearPlanShiftsMutation = wrapMutation(useClearPlanShiftsMutationOrig)
export type ClearPlanShiftsMutationVariables = DeserializeISOStrings<ClearPlanShiftsMutationVariablesOrig>
export const useResetPlanStateMutation = wrapMutation(useResetPlanStateMutationOrig)
export type ResetPlanStateMutationVariables = DeserializeISOStrings<ResetPlanStateMutationVariablesOrig>
export const useCreateShiftMutation = wrapMutation(useCreateShiftMutationOrig)
export type CreateShiftMutationVariables = DeserializeISOStrings<CreateShiftMutationVariablesOrig>
export const useUpdateShiftMutation = wrapMutation(useUpdateShiftMutationOrig)
export type UpdateShiftMutationVariables = DeserializeISOStrings<UpdateShiftMutationVariablesOrig>
export const useDeleteShiftMutation = wrapMutation(useDeleteShiftMutationOrig)
export type DeleteShiftMutationVariables = DeserializeISOStrings<DeleteShiftMutationVariablesOrig>
export const useCopyLastWeekShiftsMutation = wrapMutation(useCopyLastWeekShiftsMutationOrig)
export type CopyLastWeekShiftsMutationVariables = DeserializeISOStrings<CopyLastWeekShiftsMutationVariablesOrig>
export const useCreateAutoScheduleMutation = wrapMutation(useCreateAutoScheduleMutationOrig)
export type CreateAutoScheduleMutationVariables = DeserializeISOStrings<CreateAutoScheduleMutationVariablesOrig>
export const useCreateCovidDashboardMutation = wrapMutation(useCreateCovidDashboardMutationOrig)
export type CreateCovidDashboardMutationVariables = DeserializeISOStrings<CreateCovidDashboardMutationVariablesOrig>
export const useUpdateCovidDashboardMutation = wrapMutation(useUpdateCovidDashboardMutationOrig)
export type UpdateCovidDashboardMutationVariables = DeserializeISOStrings<UpdateCovidDashboardMutationVariablesOrig>
export const useCreatePlanTemplateMutation = wrapMutation(useCreatePlanTemplateMutationOrig)
export type CreatePlanTemplateMutationVariables = DeserializeISOStrings<CreatePlanTemplateMutationVariablesOrig>
export const useCreateWeeklyPlanExportMutation = wrapMutation(useCreateWeeklyPlanExportMutationOrig)
export type CreateWeeklyPlanExportMutationVariables = DeserializeISOStrings<CreateWeeklyPlanExportMutationVariablesOrig>
export const useOnboardingOAuthMutation = wrapMutation(useOnboardingOAuthMutationOrig)
export type OnboardingOAuthMutationVariables = DeserializeISOStrings<OnboardingOAuthMutationVariablesOrig>
export const useCopyShiftsFromPlanTemplateMutation = wrapMutation(useCopyShiftsFromPlanTemplateMutationOrig)
export type CopyShiftsFromPlanTemplateMutationVariables = DeserializeISOStrings<CopyShiftsFromPlanTemplateMutationVariablesOrig>
export const useApproveManagementRequestMutation = wrapMutation(useApproveManagementRequestMutationOrig)
export type ApproveManagementRequestMutationVariables = DeserializeISOStrings<ApproveManagementRequestMutationVariablesOrig>
export const useSignupMutation = wrapMutation(useSignupMutationOrig)
export type SignupMutationVariables = DeserializeISOStrings<SignupMutationVariablesOrig>
export const useCreateMassNotificationMutation = wrapMutation(useCreateMassNotificationMutationOrig)
export type CreateMassNotificationMutationVariables = DeserializeISOStrings<CreateMassNotificationMutationVariablesOrig>
export const useUpsertEmployeeAvailabilityMutation = wrapMutation(useUpsertEmployeeAvailabilityMutationOrig)
export type UpsertEmployeeAvailabilityMutationVariables = DeserializeISOStrings<UpsertEmployeeAvailabilityMutationVariablesOrig>
export const useUpdateOperatingHoursMutation = wrapMutation(useUpdateOperatingHoursMutationOrig)
export type UpdateOperatingHoursMutationVariables = DeserializeISOStrings<UpdateOperatingHoursMutationVariablesOrig>
export const useResetDemoLocationMutation = wrapMutation(useResetDemoLocationMutationOrig)
export type ResetDemoLocationMutationVariables = DeserializeISOStrings<ResetDemoLocationMutationVariablesOrig>

export { AffectedChannel } from 'config/graphqlTypesRaw'
export { ForecastedItemsValueBy } from 'config/graphqlTypesRaw'
export { LaborCalculation } from 'config/graphqlTypesRaw'
export { PermissionName } from 'config/graphqlTypesRaw'
export { Reason } from 'config/graphqlTypesRaw'
export { ReservationsImporter } from 'config/graphqlTypesRaw'
export { ViolationName } from 'config/graphqlTypesRaw'
export { WillHappenAgain } from 'config/graphqlTypesRaw'