import React from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { DateTime, Interval } from 'luxon'

import { LargeHeaderText } from 'components/Text'
import {
  IntervalPredictionSumFragment,
  IntervalOrderSumFragment,
} from 'config/graphqlTypes'
import { useGetLatestSuccessfulImportEvent } from 'queries/useGetLatestSuccessfulImportEvent'
import { SalesMetricList } from 'pages/tracking/metrics/MetricList'
import { WeatherMetric, EventsMetric } from 'pages/tracking/metrics/Events'
import { ComparisonBanner } from 'pages/tracking/metrics/ComparisonBanner'
import { Pill } from 'components/Pill'
import {
  DateOrWeekText,
  ProgessHeaderRow,
  UpdatedAt,
  RightCol,
  RecapHeader,
  MetricsRow,
  SummaryRow,
} from 'pages/tracking/styles'
import sumOrNull from 'utils/sumOrNull'

interface CurrentSummaryProps {
  scheduledLabor: number | null
  laborPredicted: number | null
  isWeekView: boolean
  setIsWeekView: (isDay: boolean) => void
  laborActual: number | null
  predictionSums: IntervalPredictionSumFragment[]
  orderSums: IntervalOrderSumFragment[]
  latestPredictedSales: number
  interval: Interval
  submittedAt?: DateTime | null
  salesType: string
  onClickLaborActual: () => void
}

interface DateOrWeekProps {
  isWeekView: boolean
  interval: Interval
}

const DateOrWeek = ({ isWeekView, interval }: DateOrWeekProps) => {
  const format = 'EEE, MMM d'
  const dateOrWeek = isWeekView
    ? `${interval.start.toFormat(format)} - ${interval.end.toFormat(format)}`
    : interval.start.toFormat(format)

  return <DateOrWeekText>{dateOrWeek}</DateOrWeekText>
}

const lastImportedDaysAgo = (
  importedAt: DateTime,
  t: (path: string, obj: any) => string
) => {
  const daysAgo = Math.floor(Math.abs(importedAt.diffNow('day').days))
  const day = t('tracking.todaysProgress.daysAgo', {
    postProcess: 'interval',
    count: daysAgo,
  })
  const time = importedAt.toLocaleString(DateTime.TIME_SIMPLE)

  return t('tracking.todaysProgress.lastUpdated', { day, time })
}

export const CurrentSummary = ({
  isWeekView,
  interval,
  setIsWeekView,
  laborActual,
  predictionSums,
  orderSums,
  latestPredictedSales,
  scheduledLabor,
  laborPredicted,
  submittedAt,
  salesType,
  onClickLaborActual,
}: CurrentSummaryProps) => {
  const { t } = useTranslation()
  const lastImport = useGetLatestSuccessfulImportEvent()
  const lastUpdated = lastImport
    ? lastImportedDaysAgo(lastImport.completedAt, t)
    : ''
  const options = [
    t('tracking.todaysProgress.day'),
    t('tracking.todaysProgress.week'),
  ]
  const activeIndex = isWeekView ? 1 : 0
  const onChange = (newIndex: number) => setIsWeekView(newIndex === 1)

  const reportedSales = sumOrNull(orderSums.map(o => o.reportedSales))

  return (
    <div data-testid={t('tracking.todaysProgress.todaysProgress')}>
      <ProgessHeaderRow noGutters>
        <Col md="auto">
          <LargeHeaderText>
            {t(
              `tracking.todaysProgress.${
                isWeekView ? 'thisWeeksProgress' : 'todaysProgress'
              }`
            )}
            <UpdatedAt>{lastUpdated}</UpdatedAt>
          </LargeHeaderText>
          <DateOrWeek isWeekView={isWeekView} interval={interval} />
        </Col>
        <RightCol>
          <Pill
            options={options}
            activeIndex={activeIndex}
            onChange={onChange}
          />
        </RightCol>
      </ProgessHeaderRow>
      <Row>
        <SalesMetricList
          column="current"
          scheduledLabor={scheduledLabor}
          laborPredicted={laborPredicted}
          reportedSales={reportedSales}
          laborActual={laborActual}
          latestPredictedSales={latestPredictedSales}
          salesType={salesType}
          onClickLaborActual={onClickLaborActual}
        />
        {isWeekView || (
          <>
            <WeatherMetric />
            <EventsMetric />
          </>
        )}
      </Row>
      <ComparisonBanner
        comparisonValue={scheduledLabor}
        targetValue={laborPredicted}
        prefix="current"
        submittedAt={submittedAt}
      />
    </div>
  )
}

interface PreviousSummaryProps {
  laborActual: number | null
  scheduledLabor: number | null
  laborPredicted: number | null
  laborOptimal: number | null
  isWeekView: boolean
  orderSums: IntervalOrderSumFragment[]
  latestPredictedSales: number
  interval: Interval
  submittedAt?: DateTime | null
  salesType: string
  onClickLaborActual: () => void
}

export const PreviousSummary = ({
  orderSums,
  latestPredictedSales,
  isWeekView,
  laborActual,
  scheduledLabor,
  laborPredicted,
  laborOptimal,
  interval,
  submittedAt,
  salesType,
  onClickLaborActual,
}: PreviousSummaryProps) => {
  const { t } = useTranslation()
  const reportedSales = sumOrNull(orderSums.map(o => o.reportedSales))

  return (
    <SummaryRow
      data-testid={t('tracking.yesterdaysRecap.yesterdaysRecap') + '-inner'}
    >
      <Col>
        <Row>
          <Col>
            <RecapHeader>
              {t(
                `tracking.yesterdaysRecap.${
                  isWeekView ? 'lastWeeksRecap' : 'yesterdaysRecap'
                }`
              )}
            </RecapHeader>
            <DateOrWeek isWeekView={isWeekView} interval={interval} />
          </Col>
        </Row>
        <MetricsRow>
          <SalesMetricList
            column="previous"
            laborActual={laborActual}
            scheduledLabor={scheduledLabor}
            laborPredicted={laborPredicted}
            laborOptimal={laborOptimal}
            reportedSales={reportedSales}
            latestPredictedSales={latestPredictedSales}
            salesType={salesType}
            onClickLaborActual={onClickLaborActual}
          />
        </MetricsRow>
        <ComparisonBanner
          comparisonValue={laborActual}
          targetValue={laborOptimal}
          prefix="previous"
          submittedAt={submittedAt}
        />
      </Col>
    </SummaryRow>
  )
}
