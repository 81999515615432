import { Duration } from 'luxon'

import { ShiftSegment } from 'pages/plan/dailyViewModal/schedule/getShiftSegments'

export const ShiftPillDragOptions = [
  'move',
  'changeStart',
  'changeEnd',
] as const

export type ShiftPillDragType = typeof ShiftPillDragOptions[number]

export const changePillStart = (dragType: ShiftPillDragType) =>
  dragType === 'changeStart' || dragType === 'move'

export const changePillEnd = (dragType: ShiftPillDragType) =>
  dragType === 'changeEnd' || dragType === 'move'

export interface DragPill {
  type: ShiftPillDragType
  shiftSegment: ShiftSegment
}

export interface DragPillDropResult {
  offsetDuration: Duration
}
