import { DateTime } from 'luxon'

import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useGetLocationForScheduleQuery } from 'config/graphqlTypes'

const useGetLocationForSchedule = (startOn: DateTime, endOn: DateTime) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  const { data, loading } = useGetLocationForScheduleQuery({
    variables: {
      id: locationId,
      startOnLastWeek: startOn.minus({ weeks: 1 }),
      endOnLastWeek: endOn.minus({ weeks: 1 }),
    },
    fetchPolicy: 'no-cache',
  })

  return { data, loading }
}

export default useGetLocationForSchedule
