import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { LaborSettings } from 'pages/plan/header/LaborSettings'
import { LaborCalculation } from 'config/graphqlTypes'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { ScheduleByToggle } from 'pages/plan/scheduling/ScheduleByToggle'
import {
  HeaderRow,
  HeaderWrapper,
  FarRightCol,
  FarRightRow,
  PlanStatusWrapper,
  SaveTemplateButton,
  PlanTemplateName,
} from 'pages/plan/header/styles'
import { Divider } from 'components/Navbar/styles'
import { WeekHeader } from 'components/WeekHeader'
import { BackButton } from 'pages/plan/header/BackButton'
import { PlanStatus } from 'components/PlanStatus'
import { SaveTemplateModal } from 'pages/plan/header/SaveTemplateModal'
import {
  getHasEditLaborAllowancePermission,
  getHasSettingsPermission,
} from 'utils/permissions'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { usePlanPageContext } from '../planPageContext'

interface Props {
  isPastSubmitDate: boolean
  disabled: boolean
  isScheduleByRole: boolean
  isShowingSchedule: boolean
  setIsScheduleByRole: (is: boolean) => void
  onExportSchedule: () => void
  startDate: DateTime
}

export const Header = ({
  disabled,
  isShowingSchedule,
  isScheduleByRole,
  setIsScheduleByRole,
  onExportSchedule,
  startDate,
}: Props) => {
  const {
    location: { id: locationId, laborCalculationType },
  } = useCurrentLocation()
  const { t } = useTranslation()
  const hasLaborSettings = laborCalculationType === LaborCalculation.Default
  const [openTemplateModal, setOpenTemplateModal] = useState(false)
  const { permissions } = useLoggedInUserOrRedirect()
  const { plan } = usePlanPageContext()
  const hasSettingsPermission = getHasSettingsPermission(permissions)
  const hasEditLaborAllowancePermissions = getHasEditLaborAllowancePermission(
    permissions
  )
  const {
    id,
    startOn,
    planTemplate,
    state,
    submittedAt,
    submitter,
    laborPercentOfRevenue,
    averageWage,
    shifts,
    autoGenerated,
    lastUpdatedAt,
    updatedBy,
  } = plan

  return (
    <>
      <BackButton />
      <HeaderRow noGutters>
        <Col xs={12} className="col-xxl-7">
          <Row noGutters>
            <WeekHeader
              startDate={startOn}
              labelTranslationKey="planning.planForWeek"
            />
            <PlanStatusWrapper>
              <PlanStatus
                submitter={submitter}
                submittedAt={submittedAt}
                autoGenerated={autoGenerated}
                state={state}
                lastUpdatedAt={lastUpdatedAt}
                updatedBy={updatedBy}
              />
            </PlanStatusWrapper>
          </Row>
        </Col>
        <FarRightCol>
          <FarRightRow noGutters>
            {hasLaborSettings && (
              <LaborSettings
                partialPlan={{ laborPercentOfRevenue, averageWage }}
                key={id}
                startDate={startDate}
                disableSettings={disabled || !hasSettingsPermission}
                disableLaborSettings={
                  disabled || !hasEditLaborAllowancePermissions
                }
              />
            )}
            <HeaderWrapper>
              {planTemplate ? (
                <PlanTemplateName>
                  {t('planning.saveTemplateModal.savedAsName', {
                    name: planTemplate.name,
                  })}
                </PlanTemplateName>
              ) : (
                state !== 'in_progress' && (
                  <>
                    <SaveTemplateButton
                      disabled={false}
                      onClick={() => setOpenTemplateModal(true)}
                    >
                      {t('planning.saveTemplateModal.saveTemplate')}
                    </SaveTemplateButton>
                    <SaveTemplateModal
                      open={openTemplateModal}
                      setOpen={setOpenTemplateModal}
                      startDate={startOn}
                      locationId={locationId}
                    />
                  </>
                )
              )}
              {(isShowingSchedule || submittedAt || planTemplate) && (
                <Divider />
              )}
              {isShowingSchedule && (
                <ScheduleByToggle
                  hideExportScheduleBtn={!shifts.length}
                  isScheduleByRole={isScheduleByRole}
                  setIsScheduleByRole={setIsScheduleByRole}
                  onExportSchedule={onExportSchedule}
                />
              )}
            </HeaderWrapper>
          </FarRightRow>
        </FarRightCol>
      </HeaderRow>
    </>
  )
}
