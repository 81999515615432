import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { asDollars } from 'utils/currency'
import { PredictionDifferenceText } from 'pages/plan/Prediction/styles'

interface PredictionDifferenceProps {
  difference: number
}

export const PredictionDifference: FC<PredictionDifferenceProps> = ({
  difference,
}) => {
  const { t } = useTranslation()

  if (difference === 0) {
    return <PredictionDifferenceText>&nbsp;</PredictionDifferenceText>
  }

  const i18nKey =
    difference > 0
      ? 'planning.forecastDifferenceAbove'
      : 'planning.forecastDifferenceBelow'

  return (
    <PredictionDifferenceText>
      {t(i18nKey, {
        difference: asDollars(Math.abs(difference)),
      }).toUpperCase()}
    </PredictionDifferenceText>
  )
}
