import React, { useState } from 'react'
import { FC } from 'react'
import { useDrag } from 'react-dnd'
import { TFunction } from 'i18next'

import { ShiftFragment, ViolationFragment } from 'config/graphqlTypes'
import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { moveShift } from 'pages/plan/scheduling/dragAndDropShiftMutations'
import { DragShift, DragShiftResult } from 'pages/plan/scheduling/dragShift'
import { getFirstNameAndLastInitial } from 'utils/getFirstNameAndLastInitial'
import { ViolationPopover } from 'pages/plan/scheduling/ViolationPopover'
import {
  StyledShiftCardButton,
  StyledShiftCard,
  ShiftCardIcon,
  ShiftCardText,
  NotesIconImg,
} from 'pages/plan/scheduling/styles'
import { isAllDay } from 'utils/daysAndTimes'
import { ViolationIcon } from 'components/ViolationIcon'
import { TableLabelBoldText, GraphLabelText } from 'components/Text'
import { Avatar } from 'components/Avatar'
import NotesIcon from 'assets/images/notesicon.svg'
import { getFormattedSingleShiftTime } from 'utils/getFormattedSingleShiftTime'
import { isUnassignedShift } from 'utils/isShiftUnassigned'
import { useTranslation } from 'react-i18next'
import { isNotOverSixDaysViolation } from 'utils/getViolationDetails'
import { usePlanPageContext } from '../planPageContext'
import { useFeatureToggles } from '../../../contexts/FeatureToggles'
import { DateTime, Interval } from 'luxon'

const getShiftTimeLabel = (
  { startAt, endAt }: ShiftFragment,
  allDay: boolean,
  t: TFunction
) =>
  allDay
    ? t('schedule.unavailabilityCard.allDay')
    : `${getFormattedSingleShiftTime(startAt)}-${getFormattedSingleShiftTime(
        endAt
      )}`

const getShiftViolations = (violations: ViolationFragment[], shiftId: string) =>
  violations
    ?.filter(({ shifts }) => shifts.map(({ id }) => id).includes(shiftId))
    ?.filter(isNotOverSixDaysViolation)

// In the design, there are 6 distinct card styles. Transforming the booleans that specify which card this
// particular card is into an enum makes all the downstream code much easier to understand
export enum ShiftCardType {
  Role,
  Person,
  Error,
  Unavailable,
  UnavailablePerson,
  UnavailableError,
  Unassigned,
}

const getShiftCardType = (
  isPerson: boolean,
  isUnavilability: boolean,
  isError: boolean,
  isUnassigned: boolean
) => {
  if (isError && isUnavilability) {
    return ShiftCardType.UnavailableError
  }
  if (isError) {
    return ShiftCardType.Error
  }
  if (isUnassigned) {
    return ShiftCardType.Unassigned
  }
  if (isPerson && isUnavilability) {
    return ShiftCardType.UnavailablePerson
  }
  if (isPerson) {
    return ShiftCardType.Person
  }
  if (isUnavilability) {
    return ShiftCardType.Unavailable
  }

  return ShiftCardType.Role
}

const getShiftIcon = (cardType: ShiftCardType, shift: ShiftFragment) => {
  switch (cardType) {
    case ShiftCardType.Error:
    case ShiftCardType.UnavailableError:
      return <ViolationIcon />
    case ShiftCardType.Person:
    case ShiftCardType.Unassigned:
    case ShiftCardType.UnavailablePerson:
      return (
        <Avatar
          name={shift.employee.name}
          avatarUrl={shift.employee.avatarUrl}
        />
      )
  }
}

const getShiftLabel = (
  cardType: ShiftCardType,
  isScheduleByRole: boolean,
  shift: ShiftFragment,
  t: TFunction
) => {
  if (isScheduleByRole) {
    return getFirstNameAndLastInitial(shift.employee)
  }

  switch (cardType) {
    case ShiftCardType.Role:
    case ShiftCardType.Error:
    case ShiftCardType.Unassigned:
      return shift.shiftRole?.name
    case ShiftCardType.Unavailable:
    case ShiftCardType.UnavailableError:
      return t('schedule.unavailabilityCard.label')
  }
}

interface Props {
  shift: ShiftFragment
  violations: ViolationFragment[]
  onClick: () => void
}

export const ShiftCard: FC<Props> = ({ shift, violations, onClick }) => {
  const { t } = useTranslation()
  const [hidePopover, setHidePopover] = useState(false)
  const {
    isScheduleByRole,
    createDraggedShift,
    updateDraggedShift,
    overlapWithLocationClosedDays,
  } = usePlanPageContext()
  const [{ isDragging }, dragRef] = useDrag<
    DragShift,
    DragShiftResult,
    { isDragging: boolean }
  >({
    type: 'shift',
    item: { type: 'shift', shift },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: async (item, monitor) => {
      setHidePopover(false)

      const result: any = monitor.getDropResult()

      if (!item || !result) {
        return
      }

      const dateTime = result.date as DateTime
      const overlapsWithClosedDays = overlapWithLocationClosedDays(
        Interval.fromDateTimes(dateTime.startOf('day'), dateTime.endOf('day'))
      )

      if (overlapsWithClosedDays) {
        return
      }

      const { date, rowEntity } = result

      const newShift = moveShift(item.shift, date, rowEntity)

      if (result.dropEffect === 'copy') {
        createDraggedShift(newShift)
      } else {
        const updatedShift = {
          ...newShift,
          id: item.shift.id,
        }
        updateDraggedShift(updatedShift)
      }
    },
  })

  const onClickShift = (event: any) => {
    event.stopPropagation()
    onClick()
  }

  const shiftViolations = getShiftViolations(violations, shift.id)

  const isError = shiftViolations.length > 0
  const allDay = isAllDay(shift.startAt, shift.endAt)
  const isUnassigned = isUnassignedShift(shift)

  const cardType = getShiftCardType(
    isScheduleByRole,
    shift.isUnavailability,
    isError,
    isUnassigned
  )

  const shiftIcon = getShiftIcon(cardType, shift)
  const shiftLabel = getShiftLabel(cardType, isScheduleByRole, shift, t)
  const shiftTimeLabel = getShiftTimeLabel(shift, allDay, t)
  const hasPendingShiftSwap = shift?.shiftSwapRequest?.state === 'swap_pending'

  const onDrag = (e: any) => {
    if (hasPendingShiftSwap) {
      e.preventDefault()
      ;(e.target as any).style.cursor = 'default'
    }
    setHidePopover(true)
  }

  const toggles = useFeatureToggles()
  const shiftNotesToggle = toggles.features.find(
    t => t.name === 'shiftNotesFeature'
  )

  return (
    <ConditionalWrapper
      condition={isError}
      wrapper={children => (
        <ViolationPopover
          currentShift={shift}
          violations={shiftViolations}
          hidePopover={hidePopover}
        >
          {children}
        </ViolationPopover>
      )}
    >
      <StyledShiftCardButton
        onClick={onClickShift}
        ref={dragRef}
        dragging={isDragging}
        isAllDay={allDay}
        data-testid={'shift-card-' + shiftTimeLabel}
        onDragStart={onDrag}
      >
        <StyledShiftCard cardType={cardType}>
          {shiftIcon && <ShiftCardIcon>{shiftIcon}</ShiftCardIcon>}
          <ShiftCardText>
            <TableLabelBoldText className="shift-label">
              {shiftLabel}
            </TableLabelBoldText>
            <GraphLabelText>{shiftTimeLabel}</GraphLabelText>
          </ShiftCardText>
          {shiftNotesToggle?.active && shift.notes && (
            <NotesIconImg src={NotesIcon} alt="" />
          )}
        </StyledShiftCard>
      </StyledShiftCardButton>
    </ConditionalWrapper>
  )
}
