import styled from 'styled-components'

import { ReactComponent as UpChevronRaw } from 'assets/images/up-chevron.svg'
import { ReactComponent as DownChevronRaw } from 'assets/images/down-chevron.svg'
import { Spinner } from 'react-bootstrap'

export const HeaderContainer = styled.div({
  display: 'flex',
  fontWeight: 800,
  borderTop: '0',
  padding: '0.75rem 40px',
  alignItems: 'flex-end',
  marginTop: '20px',
  marginBottom: '20px',
})

export const UpdatedAt = styled.span({
  fontSize: '0.875rem', // 14px
  lineHeight: 1.07,
  fontWeight: 500,
  display: 'inline',
  marginLeft: '0.5rem',
  fontStyle: 'italic',
  color: 'var(--gray4)',
})

export const TableContainer = styled.div({
  margin: '0.75rem 40px',
  borderWidth: '1px',
  borderColor: 'var(--gray5)',
  borderStyle: 'solid solid',
  borderRadius: '5px',
  overflow: 'clip',
})

export const TableControls = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: '66px',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const ControlsContainer = styled.div({
  maxHeight: 38,
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '0 16px',
})

export const SpinnerStyled = styled(Spinner)({
  marginLeft: '.3rem',
})

export const ViewByLabel = styled.div({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '20px',
  marginRight: '16px',
})

const DataCellSize = { flexGrow: 0, width: '95px' }
const TotalCellSize = { flexGrow: 0, width: '130px' }

export const UpChevron = styled(UpChevronRaw)({
  width: 12,
  marginLeft: 7,
  marginRight: 7,
})

export const DownChevron = styled(DownChevronRaw)({
  top: -2,
  width: 12,
  marginLeft: 7,
  marginRight: 7,
})

export const TableHeaderRow = styled.tr({
  borderColor: 'var(--gray5)',
  borderWidth: 1,
  borderStyle: 'solid none',
  display: 'flex',
  alignItems: 'center',
  alignContent: 'stretch',
})

export const TableHeader = styled.th({
  ...DataCellSize,
  height: '72px',
  padding: '16px 24px !important',

  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',

  textTransform: 'uppercase' as const,
  fontSize: '12px',
  fontWeight: 500,
  color: 'var(--gray3)',
  lineHeight: '18px',
})

export const TableHeaderTitle = styled(TableHeader)({
  flexGrow: 1,
  width: 'auto',
  fontWeight: 700,
  color: 'var(--gray1)',
})

export const TableHeaderTotal = styled(TableHeader)({
  ...TotalCellSize,
  justifyContent: 'center',
  color: 'var(--gray1)',
  fontWeight: 700,
})

export const TableRow = styled.tr({
  borderColor: 'var(--gray5)',
  borderWidth: '0 0 1px 0',
  borderStyle: 'solid none',

  display: 'flex',
  alignItems: 'center',
  alignContent: 'stretch',
})

export const Cell = styled.td({
  ...DataCellSize,
  padding: '16px 24px !important',
  height: '52px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
})

export const ItemCell = styled(Cell)({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  color: 'var(--gray1)',
  backgroundColor: 'var(--white)',
})

export const ItemTotalCell = styled(ItemCell)({
  ...TotalCellSize,
  fontWeight: 700,
  backgroundColor: 'var(--gray6)',
  fontSize: '14px',
  justifyContent: 'center',
})

export const ItemTitleCell = styled(ItemCell)({
  flexGrow: 1,
  width: 'auto',
  paddingLeft: '48px !important',
  whiteSpace: 'nowrap',
})

export const CategoryCell = styled(Cell)({
  textTransform: 'uppercase' as const,
  backgroundColor: 'var(--gray6)',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  color: 'var(--gray1)',
})

export const CategoryTotalCell = styled(CategoryCell)({
  ...TotalCellSize,
  backgroundColor: 'var(--gray5)',
  borderColor: 'var(--gray6)',
  fontSize: '16px',
  justifyContent: 'center',
})

export const CategoryTitleCell = styled(CategoryCell)({
  flexGrow: 1,
  width: 'auto',
  whiteSpace: 'nowrap',
})

export const GrandTotalCell = styled(CategoryTotalCell)({
  ...TotalCellSize,
  backgroundColor: 'var(--gray1)',
  color: 'var(--white)',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 0px !important',
  div: {
    fontSize: '14px',
  },
})
