import { useGetHoursDateRangeBreakDownLazyQuery } from '../config/graphqlTypesRaw'

export const useGetHoursDateRangeBreakDownLazyData = (
  locationId: string,
  startAt: string,
  endAt: string
) =>
  useGetHoursDateRangeBreakDownLazyQuery({
    variables: {
      locationId,
      startAt,
      endAt,
    },
    fetchPolicy: 'cache-and-network',
  })
