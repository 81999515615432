import { DateTime } from 'luxon'

import {
  getUpdatedDateTimeFromTime,
  isAfterTime,
  Time,
} from '../components/TimeInput'

export const getStartTimeEndTime = (
  dateInput: DateTime | null,
  startTimeInput: Time | null,
  endTimeInput: Time | null
) => {
  const isEndTimeNextDay =
    !!startTimeInput &&
    !!endTimeInput &&
    !isAfterTime(endTimeInput, startTimeInput)

  const startTime =
    dateInput && startTimeInput
      ? getUpdatedDateTimeFromTime(dateInput, startTimeInput)
      : null

  const endTime =
    dateInput && endTimeInput
      ? getUpdatedDateTimeFromTime(dateInput, endTimeInput, isEndTimeNextDay)
      : null

  return { startTime, endTime }
}
