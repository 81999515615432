import styled from 'styled-components'

export const BootstrapTableWrapper = styled.div.attrs(
  (props: { minHeight: string | number; rowHeight: string | number }) => props
)`
  .table {
    overflow: auto;
    table-layout: auto !important;
  }

  .table th {
    border-top: none !important;
  }
  .table th,
  .table td {
    vertical-align: middle !important;
    padding: ${props => (props.rowHeight ? props.rowHeight : '0.75rem')};
  }

  .react-bootstrap-table {
    min-height: ${props => (props.minHeight ? props.minHeight : '300px')};
  }
`
