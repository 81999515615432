import React, { FC } from 'react'
import {
  StyledCategoryRowChevron,
  StyledDownChevron,
  StyledUpChevron,
} from 'pages/plan/scheduling/styles'

interface Props {
  name: string
  collapsed: boolean
}

export const CollapsableCategoryName: FC<Props> = ({ name, collapsed }) => (
  <>
    <div>{name}</div>
    <StyledCategoryRowChevron>
      {collapsed ? <StyledDownChevron /> : <StyledUpChevron />}
    </StyledCategoryRowChevron>
  </>
)
