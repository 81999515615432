import { Col } from 'react-bootstrap'
import {
  AddNewMessageButton,
  EmployeeHeaderContainer as MessagesHeaderContainer,
  MessageSearchInputWrapper,
  EmployeesTableHeaderRow as MessagesTableHeaderRow,
  AddMessageIcon,
} from '../styles'
import React, { FC, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { BootstrapTableProps, SearchProps } from 'react-bootstrap-table-next'
import plusIconWhite from 'assets/images/plus-white.svg'
import { toast } from 'react-toastify'
import { useCurrentLocationId } from 'queries/useCurrentLocation'
import { useCreateMassNotificationMutation } from 'config/graphqlTypes'
import MassNotificationModal, {
  SelectedRoles,
} from 'pages/management/messages/MassNotificationModal'
import { GetLocationMessagesDocument } from 'config/graphqlTypesRaw'
import ConfirmationDialog from 'components/ConfirmationDialog'

type EmployeesHeaderProps = {
  searchProps: SearchProps<BootstrapTableProps>
}

export const MessagesHeader: FC<EmployeesHeaderProps> = ({ searchProps }) => {
  const [showTextInputModal, setShowTextInputModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [messageText, setMessageText] = useState('')
  const [shiftRoleCategoryIds, setShiftRoleCategoryIds] = useState<
    number[] | null
  >(null)
  const [shiftRoleIds, setShiftRoleIds] = useState<number[] | null>(null)

  const [errorSubmissionMessage, setErrorSubmissionMessage] = useState('')

  const { t } = useTranslation()

  const locationId = useCurrentLocationId()

  const requestInput = {
    locationId,
    messageText,
    shiftRoleCategoryIds,
    shiftRoleIds,
  }

  const [createMassNotification] = useCreateMassNotificationMutation({
    variables: { input: requestInput },
    refetchQueries: [
      {
        query: GetLocationMessagesDocument,
        variables: { locationId },
      },
    ],
  })

  const executeMutation = async () => {
    let errors: string[] = []

    const createMassNotificationResults = await createMassNotification({
      variables: { input: requestInput },
    })

    errors = errors.concat(
      createMassNotificationResults.data?.createMassNotification?.errors || []
    )

    return errors || []
  }

  const onSelectRoles = (selectedRoles: SelectedRoles) => {
    setShiftRoleCategoryIds(selectedRoles.departments)
    setShiftRoleIds(selectedRoles.roles)
  }

  const onSubmit = async () => {
    setShowTextInputModal(false)
    setMessageText('')
    setShiftRoleCategoryIds(null)
    setShiftRoleIds(null)

    const result = await executeMutation()

    if (result.length === 0) {
      showToast()
    } else if (result.toString().includes('employees')) {
      setShowErrorModal(true)
      setErrorSubmissionMessage(
        'This location has no employees to receive the message.'
      )
    } else {
      setShowErrorModal(true)
      setErrorSubmissionMessage(`${result}`)
    }
  }

  const onHide = () => {
    setShowTextInputModal(false)
    setShowErrorModal(false)
  }

  const onNewMassNotification = () => {
    setShowTextInputModal(true)
  }

  const showToast = () => {
    toast.success(t('management.toastMessages.massNotificationSent'), {
      position: toast.POSITION.BOTTOM_LEFT,
      style: { whiteSpace: 'nowrap', width: 'fit-content' },
    })
  }

  return (
    <MessagesTableHeaderRow>
      <Col xs={12} md={12}>
        <MessagesHeaderContainer>
          <AddNewMessageButton
            variant="primary"
            onClick={onNewMassNotification}
          >
            <AddMessageIcon src={plusIconWhite} alt="" />
            {t('management.newMessage')}
          </AddNewMessageButton>
          <MassNotificationModal
            show={showTextInputModal}
            textBox={
              <>
                <p>
                  <textarea
                    className="form-control"
                    rows={6}
                    id="submitMessageId"
                    data-testid="rejection-message"
                    placeholder={t(
                      'management.massNotification.textPlaceholder'
                    )}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      setMessageText(e.target.value)
                    }}
                    maxLength={500}
                  />
                </p>
              </>
            }
            inputText={messageText}
            onConfirm={onSubmit}
            onHide={onHide}
            onSelectRoles={onSelectRoles}
            disable={
              messageText.length <= 0 ||
              (shiftRoleCategoryIds
                ? shiftRoleCategoryIds.length <= 0
                : false) ||
              (shiftRoleIds ? shiftRoleIds.length <= 0 : false)
            }
          />

          <ConfirmationDialog
            show={showErrorModal}
            title={'Failed to Send Message'}
            description={errorSubmissionMessage}
            onHide={onHide}
            hideCancel={true}
            confirmLabel={''}
          />
          <MessageSearchInputWrapper {...searchProps} delay={500} />
        </MessagesHeaderContainer>
      </Col>
    </MessagesTableHeaderRow>
  )
}
