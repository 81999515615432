import React from 'react'
import { useTranslation } from 'react-i18next'

import { asDollars } from 'utils/currency'
import {
  ForecastInput,
  updatePlanAfterChangeInForecast,
} from 'pages/plan/Prediction/ForecastInput'
import {
  SalesAndLaborSummaryFragment,
  useDeleteForecastMutation,
} from 'config/graphqlTypes'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import * as Analytics from 'config/analytics'
import refreshIcon from 'assets/images/refresh.svg'
import {
  ResetButton,
  DateAndResetWrapper,
  PredictionDateText,
  PredictionInputWrapper,
  StyledPrediction,
} from 'pages/plan/Prediction/styles'
import { PredictionDifference } from 'pages/plan/Prediction/PredictionDifference'
import { formatDayOfWeek } from 'utils/daysAndTimes'
import { usePlanPageContext } from '../planPageContext'
import { PredictionSubTitleCell } from 'pages/plan/rows/styles'
import { Interval } from 'luxon'
import { Link } from 'react-router-dom'
import { ReactComponent as WarningIcon } from '../../../assets/images/error-status-icon.svg'
import styled from 'styled-components'

interface PredictionByCategoryProps {
  salesSummary: SalesAndLaborSummaryFragment
  expand?: boolean
  categories?: string[][]
}

export const PredictionByCategory = ({
  salesSummary,
  expand,
  categories = [[]],
}: PredictionByCategoryProps) => {
  const categoriresToDisplay = categories
    .slice(0, expand ? categories.length : 3)
    .map((categoryKeys: string[]) => {
      const totalValue = categoryKeys.reduce(
        (acc, curr) => acc + salesSummary.latestPredictedCategorySales[curr],
        0
      )
      return { categoryKeys, totalValue }
    })
  return (
    <div>
      {categoriresToDisplay.map(({ categoryKeys, totalValue }) => {
        return (
          <PredictionSubTitleCell key={`prediction-${categoryKeys.join('-')}`}>
            {asDollars(totalValue)}
          </PredictionSubTitleCell>
        )
      })}
    </div>
  )
}

interface PredictionProps {
  salesAndLaborSummary: SalesAndLaborSummaryFragment
  isDailyView?: boolean
  showCategories?: boolean
  categories?: string[][]
  expand?: boolean
  disableEdit: boolean
}

export const Prediction = ({
  salesAndLaborSummary,
  isDailyView,
  showCategories,
  disableEdit,
  categories,
  expand,
}: PredictionProps) => {
  const { t } = useTranslation()
  const {
    location: { id: locationId },
  } = useCurrentLocation()
  const {
    plan,
    overlapWithLocationClosedDays,
    getTimeOffBlockedDateByDay,
  } = usePlanPageContext()
  const { dailySalesAndLaborSummaries } = plan

  const { plannedSales, originallyPredictedSales } = salesAndLaborSummary
  const diff = plannedSales - originallyPredictedSales
  const date = salesAndLaborSummary.startOn
  const dateInterval = Interval.fromDateTimes(
    date.startOf('day'),
    date.endOf('day')
  )
  const timeOffBlockedDate = getTimeOffBlockedDateByDay(dateInterval)
  const overlapsWithClosedDays = overlapWithLocationClosedDays(dateInterval)

  const [deleteForecast] = useDeleteForecastMutation({
    variables: {
      input: {
        locationId,
        date,
      },
    },
    update: cache =>
      updatePlanAfterChangeInForecast(
        cache,
        date,
        plan,
        dailySalesAndLaborSummaries,
        previousDaySalesAndLaborSummary => ({
          ...previousDaySalesAndLaborSummary,
          // Reset the planned sales/optimal labor for planned sales to be the
          // prediction
          plannedSales:
            previousDaySalesAndLaborSummary.originallyPredictedSales,
          optimalLaborForPlannedSales:
            previousDaySalesAndLaborSummary.optimalLaborForOriginallyPredictedSales,
        })
      ),
  })

  const { id: blockedDateId, organizationId } = timeOffBlockedDate ?? {}
  const disallowEdit = disableEdit || overlapsWithClosedDays
  const allowDeletion = diff !== 0

  return (
    <StyledPrediction>
      <div>
        <DateAndResetWrapper>
          <PredictionDateText highlight={allowDeletion}>
            {isDailyView ? '\u00A0' : formatDayOfWeek(date)}
          </PredictionDateText>
          {allowDeletion && (
            <ResetButton
              data-testid="clear-forecast"
              onClick={() => {
                Analytics.track('Delete Forecast', {})
                deleteForecast()
              }}
              alt={t('tracking.insight.closeInsightsAltText')}
              src={refreshIcon}
              renderRightSide={isDailyView}
            />
          )}
        </DateAndResetWrapper>
        <PredictionInputWrapper
          highlight={allowDeletion}
          disabled={disallowEdit}
        >
          <ForecastInput
            key={`${date} + ${plannedSales}`}
            predicted={originallyPredictedSales}
            value={plannedSales}
            date={date}
            deleteForecast={deleteForecast}
            disable={disallowEdit}
          />
        </PredictionInputWrapper>
        {overlapsWithClosedDays ? (
          <LocationClosedLink>
            <WarningIcon />
            <StyledLink
              to={`/locations/${locationId}/management/organizations/${organizationId}/blocked-pto-dates/${blockedDateId}`}
            >
              {t('plans.locationClosed')}
            </StyledLink>
          </LocationClosedLink>
        ) : (
          <PredictionDifference difference={diff} />
        )}
      </div>
      {showCategories && (
        <PredictionByCategory
          salesSummary={salesAndLaborSummary}
          categories={categories}
          expand={expand}
        ></PredictionByCategory>
      )}
    </StyledPrediction>
  )
}

const LocationClosedLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  margin-top: 0.5rem;
  text-align: center;
  min-height: 12px;
`

const StyledLink = styled(Link)`
  color: var(--bittersweet);
  font-size: 9px;
  font-weight: 800;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  text-transform: uppercase;

  &:hover {
    color: var(--bittersweet-tint);
  }
`
