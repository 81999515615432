import React, { useState } from 'react'
import numeral from 'numeral'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { EM_DASH } from 'utils/formattedNumberWithFallback'
import { PlanForReportingFragment } from 'config/graphqlTypes'
import { ReportingDataCell } from 'pages/reporting/ReportingDataCell'
import { ReportingDataValue } from 'pages/reporting/ReportingDataValue'
import { LaborHoursBreakdown } from 'components/LaborActualHours'
import { LaborHoursDataValue } from 'pages/reporting/styles'

interface Props {
  salesAndLaborSummary: PlanForReportingFragment['weekSalesAndLaborSummary']
  locationId: string
  className: string
  labelTranslationKey?: string
  isWeekTotal?: boolean
  children?: JSX.Element
}

export const LaborHoursTotalCell = ({
  salesAndLaborSummary: { actualLabor, startOn },
  locationId,
  className,
  labelTranslationKey,
  isWeekTotal,
  children,
}: Props) => {
  const { t } = useTranslation()

  const [showLaborHoursModal, setShowLaborHoursModal] = useState(false)
  const toggleShowModal = () => setShowLaborHoursModal(!showLaborHoursModal)

  return (
    <>
      <ReportingDataCell
        labelTranslationKey={labelTranslationKey}
        className={cx(className, 'last-row')}
      >
        <>
          <ReportingDataValue>
            {actualLabor ? (
              <LaborHoursDataValue onClick={toggleShowModal}>
                {t('misc.hourAbbreviationWithCountFormatted', {
                  hours: numeral(actualLabor).format('0,0.0'),
                  count: Number(numeral(actualLabor).format('0,0.0')),
                })}
              </LaborHoursDataValue>
            ) : (
              EM_DASH
            )}
          </ReportingDataValue>
          {children}
        </>
      </ReportingDataCell>
      <LaborHoursBreakdown
        isWeekView={!!isWeekTotal}
        day={startOn}
        startOfWeek={startOn}
        isCurrentDayOrWeek={false}
        showModal={showLaborHoursModal}
        toggleShowModal={toggleShowModal}
        locationId={locationId}
      />
    </>
  )
}
