import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Address, TopPanelData } from 'pages/covid/types'
import {
  MapWrapper,
  AddressTextCol,
  AddressSecondLine,
} from 'pages/covid/dashboard/addressRow/styles'
import { H3Text } from 'components/Text'
import { Map } from 'pages/covid/dashboard/addressRow/Map'

interface Props {
  address: Address
  topPanelData: TopPanelData | null
}

export const GeographicData = ({
  topPanelData,
  address,
  address: { streetAddress, city, region, postalCode },
}: Props) => (
  <Row>
    <Col xs={12} lg={12} xl={5} className="col-xxl-5 col-xxxl-4">
      <MapWrapper>
        <Map address={address} topPanelData={topPanelData} />
      </MapWrapper>
    </Col>
    <AddressTextCol>
      <H3Text>{streetAddress}</H3Text>
      <AddressSecondLine>
        {city}, {region} {postalCode}
      </AddressSecondLine>
    </AddressTextCol>
  </Row>
)
