import { useDrag, useDrop } from 'react-dnd'

import {
  changePillStart,
  ShiftPillDragType,
  changePillEnd,
  ShiftPillDragOptions,
} from 'pages/plan/dailyViewModal/schedule/DragPill'
import { DragPillDropResult } from 'pages/plan/dailyViewModal/schedule/DragPill'
import { DragPill } from 'pages/plan/dailyViewModal/schedule/DragPill'
import { ShiftSegment } from 'pages/plan/dailyViewModal/schedule/getShiftSegments'
import { TimelineHelpers } from 'pages/plan/dailyViewModal/schedule/getGraphTimelineHelpers'
import {
  ShiftFragment,
  ShiftRoleFragment,
  EmployeeFragment,
} from 'config/graphqlTypes'
import useWindowWidth from 'utils/useWindowWidth'
import { fullWidth } from 'pages/plan/dailyViewModal/styles'
import { usePlanPageContext } from '../../planPageContext'

// Note to future refactorers -
// There's a lot of complicated interdependency between the draggable items, ShiftPill/ShiftPillDragHandle
// and the droppable, EmployeeSchedule. This is because only the 'hover' callback on the useDrop hook
// provides real time coordinate information. We need this to render the drag/drop preview. It would be much cleaner
// if we could get this from the useDrag hook and have it all live in the draggable items. Sadly, the monitor in
// useDrag doesn't update in real time.
//
// This also creates a situation where the draggable and the droppable components need to be closely coordinated
// to avoid flickering when switching from the actual pill to the drag/drop preview. This creates some complicated
// plumbing that seems more complicated than it needs to be.

const dropPositionIsValid = (
  oldShift: ShiftFragment,
  newShift: ShiftFragment
) => {
  const { startAt, endAt } = newShift
  const day = oldShift.startAt.day

  return (
    startAt.diff(endAt, 'minutes').minutes <= 15 &&
    startAt.day === day &&
    endAt.day === day
  )
}

export const useShiftPillDrag = (
  shiftSegment: ShiftSegment,
  isEditable: boolean,
  dragType: ShiftPillDragType
) => {
  const { shift, other } = shiftSegment
  const { updateDraggedShift } = usePlanPageContext()
  const width = useWindowWidth()

  return useDrag<DragPill, DragPillDropResult, {}>({
    type: dragType,
    item: { type: dragType, shiftSegment },
    canDrag: !!shift && !other && width >= fullWidth && isEditable,
    //TODO: this seems to break changeStart and changeEnd draggability.
    // It can be removed when regression testing is done.
    //options: { dropEffect: 'move' },
    end: (_, monitor) => {
      if (!shift) return

      const offsetDuration = monitor.getDropResult()?.offsetDuration
      if (!offsetDuration) return

      const { startAt, endAt } = shift
      const newStartAt = changePillStart(dragType)
        ? startAt.plus(offsetDuration)
        : startAt
      const newEndAt = changePillEnd(dragType)
        ? endAt.plus(offsetDuration)
        : endAt

      const newShift = {
        ...shift,
        startAt: newStartAt,
        endAt: newEndAt,
      }

      if (dropPositionIsValid(shift, newShift)) {
        updateDraggedShift(newShift)
      }
    },
  })
}

export const useShiftPillDrop = (
  timelineHelpers: TimelineHelpers,
  employee: EmployeeFragment,
  role: ShiftRoleFragment,
  onHoverShiftSegment: (
    shiftSegment: ShiftSegment,
    xOffset: number,
    type: ShiftPillDragType
  ) => void
) =>
  useDrop<DragPill, DragPillDropResult, { isHovering: boolean }>({
    accept: [...ShiftPillDragOptions],
    canDrop: ({ shiftSegment: { shift } }) =>
      shift?.employee.id === employee.id && shift?.shiftRole?.id === role.id,
    collect: monitor => ({
      isHovering: monitor.canDrop() && monitor.isOver(),
    }),
    hover: (item, monitor) => {
      if (monitor.canDrop()) {
        onHoverShiftSegment(
          item.shiftSegment,
          monitor.getDifferenceFromInitialOffset()?.x ?? 0,
          item.type
        )
      }
    },
    drop: (_, monitor) => ({
      offsetDuration: timelineHelpers.getDurationFromOffset(
        monitor.getDifferenceFromInitialOffset()?.x ?? 0
      ),
    }),
  })
