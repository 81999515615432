import React, { useState, ChangeEvent } from 'react'
import * as chrono from 'chrono-node'
import { DateTime } from 'luxon'

import { StringInputGroup } from 'components/Form'

const ROUND_TO_MINUTES = 15

export interface Time {
  hour: number
  minute: number
}

export const isAfterTime = (time1: Time, time2: Time): boolean => {
  if (time1.hour > time2.hour) {
    return true
  } else if (time1.hour === time2.hour) {
    return time1.minute > time2.minute
  } else {
    return false
  }
}

export const isAfterOrEqualTime = (time1: Time, time2: Time): boolean => {
  if (time1.hour > time2.hour) {
    return true
  } else if (time1.hour === time2.hour) {
    return time1.minute >= time2.minute
  } else {
    return false
  }
}

export const isLessOrEqualTime = (time1: Time, time2: Time): boolean => {
  if (time1.hour < time2.hour) {
    return true
  } else if (time1.hour === time2.hour) {
    return time1.minute <= time2.minute
  } else {
    return false
  }
}

export const getUpdatedDateTimeFromTime = (
  dateTime: DateTime,
  { hour, minute }: Time,
  isEndTimeNextDay?: boolean
): DateTime =>
  isEndTimeNextDay
    ? dateTime.plus({ days: 1 }).set({ hour, minute })
    : dateTime.set({ hour, minute })

export const getTimeFromDateTime = (dateTime: DateTime): Time => ({
  hour: dateTime.hour,
  minute: dateTime.minute,
})

export const getTimeFromString = (string: string): Time | null => {
  const components = chrono.parse(string)[0]?.start

  if (!components) {
    return null
  }

  const hour = components.get('hour')
  const minute = components.get('minute')

  if (hour === null || minute === null) {
    console.warn('unable to parse time from string', string)
    return null
  }

  const roundedMinute = Math.round(minute / ROUND_TO_MINUTES) * ROUND_TO_MINUTES
  const roundedHour = roundedMinute === 60 ? hour + 1 : hour

  const time = {
    hour: roundedHour,
    minute: roundedMinute === 60 ? 0 : roundedMinute,
  }

  return time
}

export const getStringFromTime = (dateTime: DateTime, time: Time): string =>
  getUpdatedDateTimeFromTime(dateTime, time).toFormat('h:mm a')

interface Props {
  controlId: string
  placeholder?: string
  time: Time | null
  after: Time | null
  dateTime: DateTime | null
  disabled?: boolean
  onChange: (time: Time | null) => void
}

export const TimeInput = ({
  controlId,
  placeholder,
  time,
  dateTime,
  disabled,
  onChange: parentOnChange,
}: Props) => {
  const displayValue = time
    ? getStringFromTime(dateTime || DateTime.local(), time)
    : ''

  const [editing, setEditing] = useState(false)
  const [editingValue, setEditingValue] = useState(displayValue)

  const onFocus = () => {
    setEditing(true)
    setEditingValue(displayValue)
  }
  const onBlur = () => {
    const newValue = getTimeFromString(editingValue)
    parentOnChange(newValue)

    setEditing(false)
    setEditingValue('')
  }
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditingValue(e.target.value)

    const newValue = getTimeFromString(editingValue)
    parentOnChange(newValue)
  }

  const value = editing ? editingValue : disabled ? '' : displayValue

  return (
    <StringInputGroup
      controlId={controlId}
      controlProps={{
        placeholder,
        value,
        onFocus,
        onBlur,
        onChange: onChange,
        disabled,
      }}
    />
  )
}
