import { TFunction } from 'i18next'
import { sortBy, uniq, uniqBy } from 'lodash'
import { DateTime } from 'luxon'

import {
  ViolationFragment,
  ShiftSummaryFragment,
  ViolationName,
  TimeSlotFragment,
} from 'config/graphqlTypes'
import { ViolationShiftDetails } from 'pages/plan/scheduling/Violations'

import {
  isSameDate,
  simpleDateTimeFormat,
  simpleTimeFormat,
} from './daysAndTimes'

import { sortTimeSlots } from './timeSlots'

interface ViolationProps {
  employeeName: string
  currentShift: ViolationShiftDetails
  violation: ViolationFragment
  t: TFunction
  locationId: string
  date?: DateTime
}

export interface PreviewShift {
  endAt: DateTime
  startAt: DateTime
  location: {
    id: string
  }
}

const underHoursViolationDetails = (
  t: TFunction,
  violation: ViolationFragment,
  otherShifts: ShiftSummaryFragment[],
  currentShift: ViolationShiftDetails
) =>
  otherShifts
    .map(shift => {
      const closingOrOpeningDateWithLocation = `schedule.violations.validations.${ViolationName.UnderHoursBetweenShifts}.closingOrOpeningDateWithLocation`
      const detailsKey = `schedule.violations.validations.${ViolationName.UnderHoursBetweenShifts}.details`

      const withLocationDetailsObject = (startOrEnd?: DateTime) => ({
        startOrEnd: startOrEnd?.toFormat('MM/d'),
        location: shift.location.name,
      })

      const [firstShift, secondShift] = sortBy(
        [shift, currentShift],
        s => s?.startAt
      )

      const sameLocation = shift.location.id === currentShift.locationId

      let openingDate, closingDate

      if (sameLocation) {
        openingDate = secondShift?.startAt?.toFormat('MM/d')
        closingDate = firstShift?.endAt?.toFormat('MM/d')
      } else {
        openingDate = t(
          closingOrOpeningDateWithLocation,
          withLocationDetailsObject(secondShift?.startAt)
        )
        closingDate = t(
          closingOrOpeningDateWithLocation,
          withLocationDetailsObject(firstShift?.endAt)
        )
      }

      return t(detailsKey, {
        count: Math.round(violation.actual),
        closingDate,
        openingDate,
      })
    })
    .join(', ')

const overlappingUnavailabilityDetails = (
  t: TFunction,
  currentLocationId: string,
  violation: ViolationFragment
) => {
  let errors = []

  if (violation.shifts.length > 0) {
    errors.push(
      t('schedule.violations.validations.overlappingUnavailability.explanation')
    )
  }

  return errors.join(', ')
}

const overlappingShiftDetails = (
  t: TFunction,
  currentLocationId: string,
  violation: ViolationFragment,
  otherShifts: ShiftSummaryFragment[],
  employeeName: string
) => {
  const detailsKey = `schedule.violations.validations.${ViolationName.OverlappingShift}.details`
  const detailsWithLocationKey = `schedule.violations.validations.${ViolationName.OverlappingShift}.detailsWithLocation`

  return uniqBy(otherShifts, 'id')
    .map(shift => {
      const weekDay = shift.startAt.toFormat('EEEE')
      const day = shift.startAt.toFormat('d')
      const time = shift.startAt.toFormat('hh:mm a')
      const month = shift.startAt.toFormat('MMMM')

      const sameLocation = shift.location.id === currentLocationId

      const details = t(detailsKey, {
        count: Math.round(violation.actual * 100) / 100,
        day,
        weekDay,
        time,
        employeeName,
        month,
      })

      const detailsWithLocation = t(detailsWithLocationKey, {
        location: shift.location.name,
      })

      return sameLocation ? details : `${details}${detailsWithLocation}`
    })
    .join(', ')
}

const overHoursViolationDetails = (
  t: TFunction,
  currentLocationId: String,
  violation: ViolationFragment,
  otherShifts: ShiftSummaryFragment[],
  employeeName: string
) => {
  const details = t(
    `schedule.violations.validations.${violation.name}.details`,
    {
      count: Math.round((violation.actual - violation.limit) * 100) / 100,
      employeeName: employeeName,
      limit: violation.limit,
    }
  )

  const foreignLocations = uniq(
    otherShifts
      .filter(s => s.location.id !== currentLocationId)
      .map(s => s.location.name)
  )

  if (foreignLocations.length === 0) {
    return details
  }

  return `${details}${t('schedule.violations.shiftsAt', {
    locations: foreignLocations.join(', '),
  })}`
}

const shiftDuringEmployeeUnavailabilityDetails = (
  t: TFunction,
  violation: ViolationFragment,
  currentShift: ViolationShiftDetails
) => {
  const weekday = currentShift.startAt?.weekdayLong.toLowerCase()

  const details = t(
    `schedule.violations.validations.${violation.name}.details`,
    {
      timeSlots: (() => {
        const availableHoursForWeekday = violation.availability?.daysAvailable?.find(
          (day: { dayOfWeek: string }) => day.dayOfWeek === weekday
        )

        const timeSlotsLabel = sortTimeSlots(
          availableHoursForWeekday?.timeSlots
        )
          ?.map(
            (slot: TimeSlotFragment) => `${slot.startTime} - ${slot.endTime}`
          )
          .join(' & ')

        return timeSlotsLabel
      })(),
    }
  )

  return details
}

const shiftDuringTimeOffDetails = (
  t: TFunction,
  violation: ViolationFragment,
  date: DateTime | undefined
) => {
  const timeOffRequest = violation.timeOffRequests[0]
  const timeOff = {
    timeSlot: '',
  }

  if (timeOffRequest && timeOffRequest.startAt && timeOffRequest.endAt) {
    if (
      date &&
      isSameDate(timeOffRequest.startAt, date) &&
      isSameDate(timeOffRequest.endAt, date)
    ) {
      timeOff.timeSlot = timeOffRequest.isAllDay
        ? ' - All Day'
        : simpleTimeFormat(timeOffRequest.startAt) +
          ' - ' +
          simpleTimeFormat(timeOffRequest.endAt)
    } else {
      timeOff.timeSlot =
        simpleDateTimeFormat(timeOffRequest.startAt) +
        ' - ' +
        simpleDateTimeFormat(timeOffRequest.endAt)
    }
  }

  const details = t(
    `schedule.violations.validations.${violation.name}.details`,
    timeOff
  )
  return details
}

const needsAvailabilitySubmittedDetails = (
  t: TFunction,
  violation: ViolationFragment,
  employeeName: string
) => {
  const details = t(
    `schedule.violations.validations.${violation.name}.details`,
    {
      employeeName,
    }
  )

  return details
}

const shiftDuringUnavailableDayDetails = (
  t: TFunction,
  violation: ViolationFragment
) => {
  const details = t(`schedule.violations.validations.${violation.name}.details`)

  return details
}

export const getViolationDetails = ({
  employeeName,
  currentShift,
  violation,
  t,
  locationId,
  date,
}: ViolationProps) => {
  const otherShifts = violation.shifts.filter(
    ({ id }) => id && id !== currentShift.shiftId
  )

  if (violation.name === ViolationName.UnderHoursBetweenShifts) {
    return underHoursViolationDetails(t, violation, otherShifts, currentShift)
  }

  if (violation.name === ViolationName.OverlappingShift) {
    return overlappingShiftDetails(
      t,
      locationId,
      violation,
      otherShifts,
      employeeName
    )
  }

  if (violation.name === ViolationName.OverlappingUnavailability) {
    return overlappingUnavailabilityDetails(t, locationId, violation)
  }

  if (violation.name === ViolationName.ShiftDuringUnavailableHours) {
    return shiftDuringEmployeeUnavailabilityDetails(t, violation, currentShift)
  }

  if (violation.name === ViolationName.NeedsAvailabilitySubmitted) {
    return needsAvailabilitySubmittedDetails(t, violation, employeeName)
  }

  if (violation.name === ViolationName.ShiftDuringUnavailableDay) {
    return shiftDuringUnavailableDayDetails(t, violation)
  }

  if (violation.name === ViolationName.ShiftDuringTimeOff) {
    return shiftDuringTimeOffDetails(t, violation, date)
  }

  return overHoursViolationDetails(
    t,
    locationId,
    violation,
    otherShifts,
    employeeName
  )
}

const shiftSwapViolations = [
  ViolationName.OverHoursDay,
  ViolationName.OverHoursWeek,
  ViolationName.OverlappingShift,
]

const timeOffViolations = [ViolationName.OverlappingShift]

export const isViolationForShiftSwap = ({ name }: ViolationFragment) =>
  shiftSwapViolations.includes(name)

export const isViolationForTimeOff = ({ name }: ViolationFragment) =>
  timeOffViolations.includes(name)
// This violation is not an active need, and it will be revisited when it's on a settings page
export const isNotOverSixDaysViolation = ({ name }: ViolationFragment) =>
  name !== ViolationName.OverDaysWeek
