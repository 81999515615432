import { OperationVariables, QueryHookOptions } from '@apollo/client'

import { useCurrentLocation } from 'queries/useCurrentLocation'
import {
  useGetOperatingHoursForDateQuery,
  GetOperatingHoursForDateQueryVariables,
} from 'config/graphqlTypes'

type Variables = Omit<GetOperatingHoursForDateQueryVariables, 'locationId'>

const useFetchOperatingHoursForDate = (
  variables: Variables,
  givenOptions: OperationVariables = {}
) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  return useGetOperatingHoursForDateQuery({
    ...givenOptions,
    variables: { locationId, ...variables },
  })
}

export const useGetOperatingHoursForDate = (
  variables: Variables,
  givenOptions: QueryHookOptions = {}
) =>
  useFetchOperatingHoursForDate(variables, givenOptions).data
    ?.operatingHoursForDate || []

export const useGetOperatingHoursData = (
  variables: Variables,
  givenOptions: QueryHookOptions = {}
) => useFetchOperatingHoursForDate(variables, givenOptions)
