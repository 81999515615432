import React, { FC, useState } from 'react'
import { sortBy } from 'lodash'

import {
  StyledReportingTableRow,
  StyledMetrogroupNameCell,
  StyledMetrogroupNameButton,
  StyledExpandCollapseIcon,
} from 'pages/reporting/styles'
import { LocationForReportingFragment } from 'config/graphqlTypes'
import { LocationReport } from 'pages/reporting/LocationReport'
import { ReportingIntervalProps } from 'pages/reporting'

interface MetrogroupNameRowProps {
  metrogroup: string
  isExpanded: boolean
  toggleExpanded: () => void
}

const MetrogroupNameRow: FC<MetrogroupNameRowProps> = ({
  metrogroup,
  toggleExpanded,
  isExpanded,
}) => (
  <StyledReportingTableRow>
    <StyledMetrogroupNameCell>
      <StyledMetrogroupNameButton onClick={toggleExpanded}>
        <StyledExpandCollapseIcon isExpanded={isExpanded} />
        {metrogroup}
      </StyledMetrogroupNameButton>
    </StyledMetrogroupNameCell>
  </StyledReportingTableRow>
)

interface MetrogroupReportProps extends ReportingIntervalProps {
  metrogroup: string
  locations: LocationForReportingFragment[]
}

export const MetrogroupReport: FC<MetrogroupReportProps> = ({
  metrogroup,
  locations,
  days,
  startDate,
  endDate,
}) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const toggleExpanded = () => setIsExpanded(!isExpanded)

  return (
    <>
      <MetrogroupNameRow
        metrogroup={metrogroup}
        isExpanded={isExpanded}
        toggleExpanded={toggleExpanded}
      />
      {isExpanded &&
        sortBy(locations, 'name').map((location, index) => (
          <LocationReport
            location={location}
            isFirst={index === 0}
            days={days}
            startDate={startDate}
            endDate={endDate}
            key={location.id}
          />
        ))}
    </>
  )
}
