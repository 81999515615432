import React from 'react'
import { useTranslation } from 'react-i18next'
import { round } from 'lodash'

import { TableLabelBoldText, H3Text } from 'components/Text'
import { NormalWeightSpan } from 'pages/covid/dashboard/addressRow/styles'
import { formatLargeNumber } from 'utils/formattedNumberWithFallback'

interface Props {
  value: number
  translationKey: string
  roundPrecision: number
  small?: boolean
}

export const SummaryInteger = ({
  value,
  translationKey,
  roundPrecision,
  small,
}: Props) => {
  const { t } = useTranslation()
  const roundedValue = formatLargeNumber(round(value, roundPrecision))
  const TextComponent = small ? TableLabelBoldText : H3Text
  const SpanComponent = small ? TableLabelBoldText : NormalWeightSpan
  const metricText = t(`covidDashboard.${translationKey}`)

  return (
    <TextComponent>
      {roundedValue}{' '}
      {small ? metricText : <SpanComponent>{metricText}</SpanComponent>}
    </TextComponent>
  )
}
