import React, { FC } from 'react'
import { Interval } from 'luxon'

import {
  DaySelectorContainer,
  DayHeading,
  ChangeDayButton,
  LeftChevron,
  RightChevron,
} from 'pages/plan/dailyViewModal/styles'

interface Props {
  day: Interval
  onClickPrev?: () => void
  onClickNext?: () => void
}

export const DaySelector: FC<Props> = ({ day, onClickPrev, onClickNext }) => {
  const displayDay = day.start.toFormat('ccc, LLL d')

  return (
    <DaySelectorContainer>
      <ChangeDayButton
        onClick={onClickPrev}
        disabled={!onClickPrev}
        data-testid="prev-day"
      >
        <LeftChevron />
      </ChangeDayButton>
      <DayHeading>{displayDay}</DayHeading>
      <ChangeDayButton
        onClick={onClickNext}
        disabled={!onClickNext}
        data-testid="next-day"
      >
        <RightChevron />
      </ChangeDayButton>
    </DaySelectorContainer>
  )
}
