import React from 'react'
import {
  EmployeeHoursScheduled,
  StyledUnorderedList,
} from 'pages/plan/scheduling/styles'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { useTranslation, Trans } from 'react-i18next'
import { EmployeeSpecificHours } from './ScheduleRow'

type Props = {
  employeeTotalHours: number | undefined
  employeeSpecificHours: EmployeeSpecificHours | undefined
}

type EmployeeScheduleProps = {
  employeeSpecificHours: EmployeeSpecificHours | undefined
  locationsKeys: string[]
}

const ShiftsByRole = ({ shifts }: { shifts: { [index: string]: number } }) => (
  <StyledUnorderedList>
    {Object.keys(shifts).map(shiftRole => {
      if (Number(shiftRole) === 0) return null
      return (
        <li key={`${shiftRole}-${shifts[shiftRole]}`}>
          <Trans
            i18nKey="schedule.employeeHoursScheduled.totalHoursByRole"
            values={{
              role: shifts[shiftRole],
              hours: shiftRole,
            }}
          />
        </li>
      )
    })}
  </StyledUnorderedList>
)

const EmployeeScheduleOnMultipleLocations = ({
  locationsKeys,
  employeeSpecificHours,
}: EmployeeScheduleProps) => (
  <StyledUnorderedList>
    {locationsKeys.map(locationKey => {
      const shifts = employeeSpecificHours?.[locationKey].shifts ?? {}

      return (
        <li>
          {locationKey}:
          <StyledUnorderedList>
            <ShiftsByRole shifts={shifts} />
          </StyledUnorderedList>
        </li>
      )
    })}
  </StyledUnorderedList>
)

const EmployeeScheduleOnSingleLocation = ({
  locationsKeys,
  employeeSpecificHours,
}: EmployeeScheduleProps) => {
  return (
    <>
      {locationsKeys.map(key => {
        const shifts = employeeSpecificHours?.[key].shifts ?? {}
        return (
          <ShiftsByRole
            key={`${key}-${employeeSpecificHours?.[key]?.shifts?.length}`}
            shifts={shifts}
          />
        )
      })}
    </>
  )
}

const EmployeeScheduleTooltip = ({
  employeeTotalHours,
  employeeSpecificHours,
}: Props) => {
  const { t } = useTranslation()
  const locationsKeys = Object.keys(employeeSpecificHours ?? {})
  const isOnSingleLocation = locationsKeys.length === 1

  const popOverLabel = isOnSingleLocation
    ? t('schedule.employeeHoursScheduled.totalHoursScheduledAt', {
        location: locationsKeys[0],
      })
    : t('schedule.employeeHoursScheduled.totalHoursScheduled')

  const popover = (props: any) => (
    <Popover {...props} id="id">
      <Popover.Content style={{ width: '150px' }}>
        <b>{employeeTotalHours}</b>
        {popOverLabel}
        {isOnSingleLocation && (
          <EmployeeScheduleOnSingleLocation
            locationsKeys={locationsKeys}
            employeeSpecificHours={employeeSpecificHours}
          />
        )}
        {!isOnSingleLocation && (
          <EmployeeScheduleOnMultipleLocations
            locationsKeys={locationsKeys}
            employeeSpecificHours={employeeSpecificHours}
          />
        )}
      </Popover.Content>
    </Popover>
  )
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 100, hide: 0 }}
      overlay={popover}
    >
      <EmployeeHoursScheduled hide={!employeeTotalHours}>
        {t('schedule.employeeHoursScheduled.employeeTotalHours', {
          hours: employeeTotalHours,
        })}
      </EmployeeHoursScheduled>
    </OverlayTrigger>
  )
}

export default EmployeeScheduleTooltip
