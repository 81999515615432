import React from 'react'
import styled from 'styled-components'

interface PillProps {
  isActive: boolean
}

const Pill = styled.button(({ isActive }: PillProps) => ({
  width: '17%',
  fontSize: '1rem',
  lineHeight: 2.2,
  fontWeight: isActive ? 800 : 400,
  color: isActive ? 'var(--white)' : 'var(--gray4)',
  backgroundColor: isActive ? 'var(--blue-light)' : 'var(--white)',
  border: isActive ? 'none' : `1px solid var(--gray4)`,
  borderRadius: '2.8rem',
  '&:hover': {
    'background-color': isActive ? 'var(--blue-light)' : 'var(--gray7)',
  },
  '&:focus': {
    outline: 'none',
  },
}))

interface MultiPillProps {
  options: string[]
  activeIndex: number
  testIds: string[]
  onChange: (newActiveIndex: number) => void
}

export const MultiPill = ({
  options,
  activeIndex,
  testIds,
  onChange,
}: MultiPillProps) => (
  <>
    {options.map((option, index) => (
      <Pill
        key={option}
        data-testid={testIds[index]}
        isActive={activeIndex === index}
        onClick={() => onChange(index)}
      >
        {option}
      </Pill>
    ))}
  </>
)
