import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import * as Analytics from 'config/analytics'
import { Loading } from 'components/Loading'
import { getTopPanelData } from 'pages/covid/dashboard/addressRow/getTopPanelData'
import { Address, TopPanelData } from 'pages/covid/types'
import { FirstColumn } from 'pages/covid/dashboard/addressRow/FirstColumn'
import { LocationChartColumn } from 'pages/covid/dashboard/addressRow/LocationChartColumn'
import { CovidCard } from 'pages/covid/dashboard/addressRow/CovidCard'
import closeIcon from 'assets/images/close-icon.svg'
import {
  DeleteLocationButtonWrapper,
  DeleteLocationButton,
  ErrorLoadingCol,
  TryAgainButton,
  StyledAddressRow,
  MainMetricsRow,
  EmailDataButton,
} from 'pages/covid/dashboard/addressRow/styles'
import {
  MonthlyDistanceTravelledHeader,
  MonthlyVisitLengthHeader,
  MonthlyVisitorsHeader,
  WeeklyVisitsHeader,
} from 'pages/covid/dashboard/HeaderRow'
import { notify } from 'config/errorReporting'
import { SaveThisViewModal } from 'pages/covid/dashboard/footer/SaveThisViewModal'

interface Props {
  address: Address
  onRemoveAddress: (address: Address) => void
  dashboardSaved: boolean
  saveNewDashboard: (name: string, email: string) => void
}

const loadData = (
  address: Address,
  setTopPanelData: (topPanelData: TopPanelData) => void,
  setErrorLoading: (errorLoading: boolean) => void,
  setIsLoading: (isLoading: boolean) => void
) => {
  setErrorLoading(false)
  setIsLoading(true)
  getTopPanelData(address)
    .then(data => {
      if (data) {
        setTopPanelData(data)
      } else {
        Analytics.track('[CRC]: Address not supported by backend', {
          fullAddress: address.fullAddress,
        })
        notify(`Address not supported by backend: ${address.fullAddress}`)
      }
    })
    .catch(() => {
      setErrorLoading(true)
    })
    .finally(() => {
      setIsLoading(false)
    })
}

export const AddressRow = ({
  address,
  onRemoveAddress,
  dashboardSaved,
  saveNewDashboard,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorLoading, setErrorLoading] = useState<boolean>(false)
  const [topPanelData, setTopPanelData] = useState<TopPanelData | null>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const { t } = useTranslation()

  const reloadData = () => {
    loadData(address, setTopPanelData, setErrorLoading, setIsLoading)
  }

  useEffect(() => {
    loadData(address, setTopPanelData, setErrorLoading, setIsLoading)
  }, [address, setTopPanelData, setErrorLoading, setIsLoading])

  const noData = !isLoading && !errorLoading && !topPanelData

  return (
    <StyledAddressRow noGutters>
      <>
        <FirstColumn
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          topPanelData={topPanelData}
          address={address}
          dashboardSaved={dashboardSaved}
        />
        <Col>
          {isLoading && <Loading height="172px" />}
          {errorLoading && (
            <ErrorLoadingCol>
              {t('covidDashboard.dashboardErrorLoading')}
              <TryAgainButton onClick={reloadData}>
                {t('covidDashboard.tryAgain')}
              </TryAgainButton>
            </ErrorLoadingCol>
          )}
          {noData && (
            <ErrorLoadingCol>
              {t('covidDashboard.noDataForLocation')}
            </ErrorLoadingCol>
          )}

          {topPanelData && (
            <>
              <MainMetricsRow noGutters>
                <LocationChartColumn
                  isExpanded={isExpanded}
                  alwaysShowTimeframes
                  metric={topPanelData.weekly_visits}
                  translationKey="visits"
                  header={<WeeklyVisitsHeader />}
                  dashboardSaved={dashboardSaved}
                />
                <LocationChartColumn
                  isExpanded={isExpanded}
                  alwaysShowTimeframes
                  metric={topPanelData.dwell_time}
                  translationKey="min"
                  header={<MonthlyVisitLengthHeader />}
                  dashboardSaved={dashboardSaved}
                />
                <LocationChartColumn
                  isExpanded={isExpanded}
                  metric={topPanelData.monthly_visitors}
                  translationKey="visitors"
                  header={<MonthlyVisitorsHeader />}
                  dashboardSaved={dashboardSaved}
                />
                <LocationChartColumn
                  isExpanded={isExpanded}
                  metric={topPanelData.distance_traveled}
                  translationKey="miles"
                  header={<MonthlyDistanceTravelledHeader />}
                  dashboardSaved={dashboardSaved}
                />
              </MainMetricsRow>
              {isExpanded && (
                <Row noGutters>
                  <Col>
                    <CovidCard
                      covidTrends={topPanelData.covid}
                      dashboardSaved={dashboardSaved}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}

          <DeleteLocationButtonWrapper>
            <DeleteLocationButton
              src={closeIcon}
              onClick={() => onRemoveAddress(address)}
            />
          </DeleteLocationButtonWrapper>
        </Col>
        {!dashboardSaved && topPanelData && (
          <EmailDataButton onClick={() => setModalOpen(true)}>
            {t('covidDashboard.emailForData')}
          </EmailDataButton>
        )}
        <SaveThisViewModal
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          saveNewDashboard={saveNewDashboard}
        />
      </>
    </StyledAddressRow>
  )
}
