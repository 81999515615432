import { SelectDropdownOption } from 'components/SelectDropdown'
import { ExportScheduleOptionsSelect } from './ExportScheduleOptionsSelect'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type OrientationType = 'portrait' | 'landscape'

export type ExportScheduleOrientationOption = {
  value: OrientationType
  label: string
}

interface Props {
  onSelectOrientation: (orientation: ExportScheduleOrientationOption) => void
}

export const ExportScheduleOrientationSelect: FC<Props> = ({
  onSelectOrientation,
}) => {
  const { t } = useTranslation()

  const orientations = [
    {
      value: 'portrait',
      label: t(
        'planning.exportScheduleModal.exportOptions.orientation.portrait'
      ),
    },
    {
      value: 'landscape',
      label: t(
        'planning.exportScheduleModal.exportOptions.orientation.landscape'
      ),
    },
  ]

  const [selectedOption, setSelectedOption] = useState<SelectDropdownOption>(
    orientations[0]
  )

  return (
    <ExportScheduleOptionsSelect
      singleSelect
      label={t('planning.exportScheduleModal.exportOptions.orientation.label')}
      onSelectOptions={option => {
        const newOption = option as ExportScheduleOrientationOption
        if (newOption.value !== selectedOption.value) {
          onSelectOrientation(newOption)
          setSelectedOption(newOption)
        }
      }}
      options={orientations}
      testId={'exportScheduleOrientationSelect'}
    />
  )
}
