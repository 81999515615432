import { DateTime, Interval } from 'luxon'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { ForecastedItemsValueBy } from 'config/graphqlTypes'
import { useGetForecastedItemsQuery } from 'config/graphqlTypes'

export type ForecastRow = {
  name: string
  values: number[]
  total: number
}

export type ForecastedCategories = {
  total: number
  categories: { [category: string]: ForecastRow }
}

export type ItemForecastsPerCategory = {
  [category: string]: { [item: string]: ForecastRow }
}

const emptyForecastRow: (name: string) => ForecastRow = (name: string) =>
  Object({ name: name, total: 0, values: new Array(7).fill(0) })

const getWeekDays = (fiscalWeek: Interval) => {
  return fiscalWeek
    .splitBy({ day: 1 })
    .map(interval => interval.start.startOf('day').toFormat('MMM dd'))
}

export const useForecastedItemsCategories = (
  fiscalWeek: Interval,
  valueBy: ForecastedItemsValueBy
) => {
  const {
    location: { id },
  } = useCurrentLocation()

  const { data } = useGetForecastedItemsQuery({
    variables: {
      locationId: id,
      valueBy,
      startOn: fiscalWeek.start,
    },
  })

  const weekDays = getWeekDays(fiscalWeek)

  return data?.forecastedItems.items.reduce(
    (acc: ForecastedCategories, itemForecast) => {
      const { category, date, value } = itemForecast
      const dayIndex = weekDays.indexOf(date.startOf('day').toFormat('MMM dd'))
      acc.categories = {
        [category]: emptyForecastRow(category),
        ...acc.categories,
      }
      acc.total += value
      acc.categories[category].total += value
      acc.categories[category].values[dayIndex] += value
      return acc
    },
    { total: 0, categories: {} }
  )
}

export const useItemForecastLastUpdated = (
  fiscalWeek: Interval,
  valueBy: ForecastedItemsValueBy
) => {
  const {
    location: { id },
  } = useCurrentLocation()

  const { data } = useGetForecastedItemsQuery({
    variables: {
      locationId: id,
      valueBy,
      startOn: fiscalWeek.start,
    },
  })
  const updatedAt =
    data?.forecastedItems.updatedAt === undefined
      ? null
      : data?.forecastedItems.updatedAt
  return updatedAt ? DateTime.fromISO(updatedAt) : null
}

export const useForecastedItems = (
  fiscalWeek: Interval,
  categories: string[],
  valueBy: ForecastedItemsValueBy
) => {
  const {
    location: { id },
  } = useCurrentLocation()

  const { data } = useGetForecastedItemsQuery({
    variables: {
      locationId: id,
      valueBy,
      startOn: fiscalWeek.start,
    },
  })

  const weekDays = getWeekDays(fiscalWeek)

  return data?.forecastedItems.items.reduce(
    (acc: ItemForecastsPerCategory, itemForecast) => {
      const { category, name, date, value } = itemForecast
      const dayIndex = weekDays.indexOf(date.startOf('day').toFormat('MMM dd'))

      acc[category] = { [name]: emptyForecastRow(name), ...acc[category] }
      acc[category][name].total += value
      acc[category][name].values[dayIndex] += value
      return acc
    },
    Object.fromEntries(categories.map(c => [c, {}]))
  )
}
