import styled from 'styled-components'
import { Container, Modal, Row } from 'react-bootstrap'
import { TextButton } from '../Button'
import { RegularBoldBodyText } from '../Text'
import { h3Style, regularBodyBoldTextStyle } from '../../config/styles'

export const AnimatedLoading = styled.div`
  height: 20rem;

  .loading-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .loading-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  .loading-exit {
    opacity: 1;
  }
  .loading-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms, transform 500ms;
  }
`

export const LaborHrsBreakdownStyledModal = styled(Modal)({
  '.modal-body': {
    padding: '2rem',
  },
})

export const CloseLaborHrsBreakdownButton = styled(TextButton)({
  position: 'absolute',
  height: 30,
  width: 30,
  padding: 0,
  top: 0,
  right: 0,
  zIndex: 1,
})

export const LaborHrsBreakdownAccordionCollapseButton = styled(TextButton)({
  paddingLeft: 0,
  position: 'absolute' as 'absolute',
  left: -8,
})

export const LaborHrsBreakdownAccordionToggleTitle = styled.span({
  ...regularBodyBoldTextStyle,
  color: 'var(--gray1)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: 1,
})

export const LaborHrsBreakdownAccordionContainer = styled.div({
  paddingTop: 5,
})

export const StyledLaborHrsBreakdownModalContainer = styled(Container)({
  minHeight: '20rem',
  marginTop: 20,
  marginBottom: 20,
})

export const LaborHrsBreakdownHeader = styled(RegularBoldBodyText)({
  color: 'var(--gray4)',
  fontSize: 20,
  marginBottom: 10,
})

export const LaborHrsDayHeading = styled.div({
  ...h3Style,
  whiteSpace: 'nowrap',
})

export const StyledRow = styled(Row)({
  marginBottom: 15,
})

export const AccordionExcludeFromLaborWrapper = styled.div({
  paddingTop: 20,
})

export const AccordionWeeklyViewWrapper = styled.div({
  paddingBottom: 10,
})

export const StyledLabel = styled.label({
  color: 'var(--gray2)',
  fontSize: 14,
  fontWeight: 700,
})

export const StyledAccordionHeader = styled.div({
  paddingTop: 5,
})

export const StyledNameSpan = styled.span({
  cursor: 'pointer',
})

export const StyledItemSpan = styled.span({
  display: 'block',
})

export const WeeklyTotalHrsWrapper = styled.div({
  paddingTop: 5,
})

export const StyledNameLi = styled.li({
  textDecoration: 'underline solid 2px',
  textUnderlinePosition: 'under',
  marginBottom: 10,
})

export const StyledValueLi = styled.li({
  marginBottom: 10,
})

export const ExcludedFromLaborTotalHrsWeeklyWrapper = styled.div({
  paddingTop: 20,
})

export const ListBottomLine = styled.hr({
  border: '1px solid var(--gray5)',
  marginTop: 0,
  marginBottom: 5,
})

export const BottomLineRow = styled(Row)({
  marginTop: 10,
})

export const TotalHrsWrapper = styled.div({
  fontWeight: 800,
  fontSize: 15,
  color: 'var(--gray1)',
})

export const StyledUl = styled.ul({
  listStyleType: 'none',
  padding: 0,
  marginBottom: 0,
})
