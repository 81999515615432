import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DashboardModalContainer,
  DashboardModalHeader,
  DashboardModalBody,
  DashboardModalIcon,
  DashboardModalTitle,
  DashboardModalDetail,
  DashboardModalOtherContent,
} from 'pages/covid/dashboard/footer/styles'

interface Props {
  show: boolean
  onHide: () => void
  icon: string
  titleTransKey: string
  detailTransKey: string
  children?: JSX.Element
}

export const DashboardModal: FC<Props> = ({
  show,
  onHide,
  icon,
  titleTransKey,
  detailTransKey,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <DashboardModalContainer show={show} onHide={onHide} centered>
      <DashboardModalHeader closeButton />
      <DashboardModalBody>
        <DashboardModalIcon src={icon} alt={t(titleTransKey)} />
        <DashboardModalTitle>{t(titleTransKey)}</DashboardModalTitle>
        <DashboardModalDetail>{t(detailTransKey)}</DashboardModalDetail>
        {children && (
          <DashboardModalOtherContent>{children}</DashboardModalOtherContent>
        )}
      </DashboardModalBody>
    </DashboardModalContainer>
  )
}
