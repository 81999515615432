import React from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Interval } from 'luxon'

import { H2Text } from 'components/Text'
import {
  IntervalOrderSumFragment,
  IntervalPredictionSumFragment,
  PlanSummaryWithDailySummariesFragment,
  SalesDayPartFragment,
} from 'config/graphqlTypes'
import { CurrentDayTable } from 'pages/tracking/breakdown/CurrentDayTable'
import { CurrentWeekTable } from 'pages/tracking/breakdown/CurrentWeekTable'
import { PreviousTable } from 'pages/tracking/breakdown/PreviousTable'
import {
  FullTableContainer,
  HeaderRow,
  PreviousTableContainer,
  YesterdayBreakdown,
} from 'pages/tracking/breakdown/styles'

interface Props {
  interval: Interval
  isWeekView: boolean
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  plan: PlanSummaryWithDailySummariesFragment
  salesType: string
  locationSalesDayParts: SalesDayPartFragment[]
}

export const Breakdown = ({
  interval,
  isWeekView,
  orderSums,
  predictionSums,
  plan,
  salesType,
  locationSalesDayParts,
}: Props) => {
  const { t } = useTranslation()

  return (
    <FullTableContainer data-testid={t('tracking.todaysProgress.breakdown')}>
      <HeaderRow noGutters>
        <Col>
          <H2Text>{t('tracking.todaysProgress.breakdown')}</H2Text>
        </Col>
      </HeaderRow>
      <Row>
        <Col>
          {isWeekView ? (
            <CurrentWeekTable
              salesType={salesType}
              interval={interval}
              orderSums={orderSums}
              predictionSums={predictionSums}
              plan={plan}
            />
          ) : (
            <CurrentDayTable
              salesType={salesType}
              orderSums={orderSums}
              predictionSums={predictionSums}
              locationSalesDayParts={locationSalesDayParts}
            />
          )}
        </Col>
      </Row>
    </FullTableContainer>
  )
}

interface PreviousBreakdownProps {
  isWeekView: boolean
  orderSums: IntervalOrderSumFragment[]
  predictionSums: IntervalPredictionSumFragment[]
  salesType: string
}

export const PreviousBreakdown = ({
  orderSums,
  predictionSums,
  isWeekView,
  salesType,
}: PreviousBreakdownProps) => {
  const { t } = useTranslation()

  return isWeekView ? null : (
    <PreviousTableContainer
      data-testid={t('tracking.todaysProgress.breakdownSales')}
    >
      <HeaderRow noGutters>
        <Col>
          <YesterdayBreakdown>
            {t('tracking.todaysProgress.breakdown')}
          </YesterdayBreakdown>
        </Col>
      </HeaderRow>
      <Row>
        <Col>
          <PreviousTable
            orderSums={orderSums}
            predictionSums={predictionSums}
            salesType={salesType}
          />
        </Col>
      </Row>
    </PreviousTableContainer>
  )
}
