import React, { FC, useState } from 'react'
import { DateTime, Interval } from 'luxon'
import { isInteger, round } from 'lodash'

import {
  Check,
  Label,
  SelectInputGroup,
  FormGroup,
} from '../../../components/Form'
import {
  EmployeeShiftsFragment,
  LocationForModifyShiftFragment,
} from '../../../config/graphqlTypes'
import { StyledDuration, TimeInputContainer, TextAreaContainer } from './styles'
import {
  getUpdatedDateTimeFromTime,
  isAfterTime,
  Time,
} from '../../../components/TimeInput'
import { useFeatureToggles } from '../../../contexts/FeatureToggles'
import { ShiftBreakOptions } from './ShiftBreakOptions'
import { FilterEmployees } from './SchedulingFormHelpers'
import { Col, Row, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { getStartTimeEndTime } from '../../../utils/getStartTimeEndTime'
import TimePicker, {
  convertSingleValueOptionToTime,
  convertTimeToSingleValueOption,
} from '../../../components/TimePicker'
import { CharacterCountText } from 'components/Text'
import { SingleValueOption } from '../../../components/SelectDropdown'
import styled from 'styled-components'
import { usePlanPageContext } from '../planPageContext'

const getRoundedDurationHours = (
  startTime: DateTime,
  endTime: DateTime
): number => {
  const hours = endTime.diff(startTime, ['hours']).toObject().hours!
  if (isInteger(hours)) return hours
  return round(hours, 1)
}

export type EmployeeForModifyShift = LocationForModifyShiftFragment['employees'][0]

interface Props {
  roleSelectValue: string | null
  roleSelectOptions: string[][]
  onRoleSelectChange: (e: any) => void

  employeeId: string | null
  onEmployeeChange: (e: any) => void
  employees: EmployeeForModifyShift[]

  dateInput: DateTime | null
  onDateChange: (e: any) => void
  days: Interval[]

  startTimeInput: Time | null
  onStartTimeChange: (time: Time | null) => void

  endTimeInput: Time | null
  onEndTimeChange: (time: Time | null) => void
  hideDateSelect: boolean | undefined

  locationId: string
  employeeShiftsForWeek: EmployeeShiftsFragment[]
  showAllEmployees: boolean
  setShowAllEmployees: (status: boolean) => void
  hasPendingShiftSwap: boolean | undefined

  shiftBreaks: number[] | undefined
  setShiftBreaks: (breaks: number[]) => void

  shiftNotes: string | null
  setShiftNotes: (note: string | null) => void
}

export const SchedulingForm: FC<Props> = ({
  roleSelectValue,
  roleSelectOptions,
  onRoleSelectChange,
  employeeId,
  onEmployeeChange,
  employees,
  dateInput,
  onDateChange,
  days,
  startTimeInput,
  onStartTimeChange,
  endTimeInput,
  onEndTimeChange,
  hideDateSelect,
  showAllEmployees,
  setShowAllEmployees,
  locationId,
  employeeShiftsForWeek,
  hasPendingShiftSwap,
  shiftBreaks,
  setShiftBreaks,
  shiftNotes,
  setShiftNotes,
}) => {
  const { t } = useTranslation()
  const [
    timesDropdownStartTime,
    setTimesDropdownStartTime,
  ] = useState<DateTime | null>(() => {
    const dt = dateInput ?? DateTime.local()
    return startTimeInput && !endTimeInput
      ? getUpdatedDateTimeFromTime(dt, startTimeInput).plus({ minutes: 15 })
      : null
  })

  const { overlapWithLocationClosedDays } = usePlanPageContext()

  const isEndTimeNextDay =
    !!startTimeInput &&
    !!endTimeInput &&
    !isAfterTime(endTimeInput, startTimeInput)

  const { startTime, endTime } = getStartTimeEndTime(
    dateInput,
    startTimeInput,
    endTimeInput
  )

  const hourCount =
    startTime && endTime
      ? t('schedule.shiftModal.duration', {
          count: getRoundedDurationHours(startTime, endTime),
        })
      : null

  const handleStartTimeChange = (time: SingleValueOption) => {
    onStartTimeChange(convertSingleValueOptionToTime(time))
    if (!endTimeInput && time) {
      const endTime = DateTime.fromMillis(Number(time.value))
      setTimesDropdownStartTime(endTime.plus({ minutes: 15 }))
    }
  }

  const handleEndTimeChange = (time: SingleValueOption) => {
    onEndTimeChange(convertSingleValueOptionToTime(time))
  }

  const toggles = useFeatureToggles()
  const shiftNotesToggle = toggles.features.find(
    t => t.name === 'shiftNotesFeature'
  )

  return (
    <>
      <SelectInputGroup
        controlId="shiftModalForm.shiftRoleSelect"
        labelProps={{
          label: t('schedule.shiftModal.shiftRole'),
        }}
        controlProps={{
          value: roleSelectValue || undefined,
          disabled: hasPendingShiftSwap,
          onChange: (event: any) => {
            event.persist()
            onRoleSelectChange(event)
          },
          children: (
            <>
              {roleSelectOptions.map(([value, display]) => (
                <option value={value} key={value}>
                  {display}
                </option>
              ))}
            </>
          ),
        }}
      />

      <Row noGutters>
        <Col md={6}>
          <TimeInputContainer>
            <Label label={t('schedule.shiftModal.startTime')} />
            <FormGroup
              testId="shiftModalForm.startTimeInput"
              controlId="shiftModalForm.startTimeInput"
            >
              <TimePicker
                placeholder={t('schedule.shiftModal.startTime')}
                disabled={hasPendingShiftSwap}
                dateTime={dateInput}
                time={convertTimeToSingleValueOption(startTimeInput, dateInput)}
                onSelect={handleStartTimeChange}
              />
            </FormGroup>
          </TimeInputContainer>
        </Col>
        <Col md={6}>
          <EndTimeDropdownContainer>
            <TimeInputContainer style={{ flexGrow: 1 }}>
              <Label label={t('schedule.shiftModal.endTime')} />
              <FormGroup
                testId="shiftModalForm.endTimeInput"
                controlId="shiftModalForm.endTimeInput"
              >
                <TimePicker
                  placeholder={t('schedule.shiftModal.endTime')}
                  disabled={hasPendingShiftSwap}
                  dateTime={dateInput}
                  startDateTime={timesDropdownStartTime}
                  time={convertTimeToSingleValueOption(endTimeInput, dateInput)}
                  onSelect={handleEndTimeChange}
                />
              </FormGroup>
            </TimeInputContainer>
            <StyledDuration isEndTimeNextDay={isEndTimeNextDay}>
              {hourCount}
            </StyledDuration>
          </EndTimeDropdownContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label label={t('schedule.shiftModal.employee')} />
        </Col>
        <Col>
          <Check
            id={'toggleAllEmployees'}
            label={t('schedule.shiftModal.showAllEmployees')}
            inputProps={{
              checked: showAllEmployees,
              disabled: hasPendingShiftSwap,
              onChange: () => setShowAllEmployees(!showAllEmployees),
            }}
          />
        </Col>
      </Row>
      <SelectInputGroup
        controlId="shiftModalForm.employeeSelect"
        testId="Employee"
        controlProps={{
          value: employeeId || undefined,
          disabled: hasPendingShiftSwap,
          onChange: (event: any) => {
            event.persist()
            onEmployeeChange(event)
          },
          children: (
            <>
              <option label="Unassigned" value=""></option>
              {FilterEmployees(
                employees,
                dateInput,
                startTimeInput,
                endTimeInput,
                roleSelectOptions.find(o => o[0] === roleSelectValue),
                showAllEmployees,
                locationId,
                employeeShiftsForWeek
              )}
            </>
          ),
        }}
      />

      {!hideDateSelect && (
        <SelectInputGroup
          controlId="shiftModalForm.dateSelect"
          labelProps={{
            label: t('schedule.shiftModal.date'),
          }}
          controlProps={{
            value: dateInput?.toISODate(),
            disabled: hasPendingShiftSwap,
            onChange: (event: any) => {
              event.persist()
              onDateChange(event)
            },
            children: (
              <>
                {days.map(day => (
                  <option
                    value={day.start.toISODate()}
                    key={day.start.toISODate()}
                    disabled={overlapWithLocationClosedDays(day)}
                  >
                    {day.start.toFormat('EEE, MMM d')}
                  </option>
                ))}
              </>
            ),
          }}
        />
      )}
      {
        <ShiftBreakOptions
          shiftBreaks={shiftBreaks}
          setShiftBreaks={setShiftBreaks}
        />
      }

      {shiftNotesToggle?.active && (
        <TextAreaContainer>
          <Label label={t('schedule.shiftModal.shiftNotes')} />
          <FormGroup
            testId="shiftModalForm.shiftNotes"
            controlId="shiftModalForm.shiftNotes"
          >
            <Form.Control
              as="textarea"
              rows={4}
              maxLength={500}
              data-testid="shift-note"
              defaultValue={shiftNotes as string}
              placeholder={t('schedule.shiftModal.shiftNotesPlaceholder')}
              onChange={e => setShiftNotes(e.target.value)}
            />
          </FormGroup>
          <CharacterCountText>
            Characters Left: {500 - (shiftNotes?.length || 0)}
          </CharacterCountText>
        </TextAreaContainer>
      )}
    </>
  )
}

const EndTimeDropdownContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
})
