import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'

import { TableLabelBoldText, H2Text } from 'components/Text'
import {
  tableLabelTextStyle,
  regularBodyTextStyle,
  h3Style,
  tableAnnotationTextStyle,
} from 'config/styles'

export const FullTableContainer = styled.div({
  paddingTop: 41,
})

export const HeaderRow = styled(Row)({
  alignItems: 'flex-end',
  height: 30,
  paddingBottom: 5,
})

export const PreviousTableContainer = styled.div({
  marginTop: 0,
})

export const YesterdayBreakdown = styled(H2Text)({
  color: 'var(--gray4)',
})

export const TinyLine = ({ color }: { color: string }) => (
  <svg
    width="6"
    height="28"
    viewBox="0 0 6 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="3"
      y1="3"
      x2="3"
      y2="25"
      stroke={color}
      strokeWidth="6"
      strokeLinecap="round"
    />
  </svg>
)

export const TitleText = styled(TableLabelBoldText)({
  paddingLeft: 7,
})

interface CellProps {
  category?: boolean
  total?: boolean
  actual?: boolean
}

export const Cell = styled.td<CellProps>(props => ({
  ...(props.category ? tableLabelTextStyle : regularBodyTextStyle),
  ...(props.total ? { fontWeight: 800 } : ''),
  color: `var(${props.actual ? '--gray1' : '--gray3'})`,

  '&:nth-child(1)': {
    paddingLeft: 25,
  },
  height: 50,
  verticalAlign: 'middle !important',
}))

export const TitleCell = styled(Cell)(
  ({ color, actual }: { color: string; actual?: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    color,
    opacity: actual ? '1' : '0.5',
    minWidth: '83px',
  })
)

export const DailyCell = styled(Cell)({
  width: '10%',
  maxWidth: '10%',
  minWidth: '10%',
})

export const TotalCell = styled(Cell)({
  width: '15%',
  maxWidth: '15%',
  minWidth: '15%',
})

export const TableHead = styled.thead(tableLabelTextStyle)

export const TableBody = styled.tbody(regularBodyTextStyle)

export const TitleTableHeader = styled.th({
  ...h3Style,
  color: 'var(--gray4)',
  position: 'relative',
  paddingLeft: '0 !important',
  minWidth: '165px',
})

export const TableHeader = styled.th({
  ...tableLabelTextStyle,
  color: 'var(--gray3)',
  verticalAlign: 'middle !important',
  paddingBottom: '0px !important',
})

interface LabelProps {
  actual?: boolean
}

export const Label = styled.span<LabelProps>(props => ({
  ...tableAnnotationTextStyle,
  display: 'block',
  color: `var(${props.actual ? '--blue' : '--blue-light'})`,
  textTransform: 'uppercase',
}))

export const CategoryValues = styled(Cell)(({ color }) => ({
  fontSize: '14px',
  fontWeight: color ? 'bold' : 500,
  lineHeight: '15px',
  color: color ?? 'var(--gray3)',
  backgroundColor: 'transparent',
}))

export const CategorySalesTitle = styled(Cell)(() => ({
  fontSize: '14px',
  fontWeight: 800,
  lineHeight: '15px',
  color: 'var(--gray1)',
  width: '115px',
  backgroundColor: 'transparent',
  marginLeft: '12px',
  '&:nth-child(1)': {
    paddingLeft: '35px',
  },
}))

export const CollapseIcon = styled.img<{ isExpanded: boolean }>(
  ({ isExpanded }) => ({
    transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: '8px',
    marginBottom: isExpanded ? '0px' : '3px',
  })
)

export const StyledTr = styled.tr<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    backgroundColor,
    '&:nth-of-type(odd), &:nth-of-type(even)': {
      backgroundColor: `${backgroundColor} !important`,
    },
  })
)
