import React, { ChangeEvent, FC, SyntheticEvent, useState } from 'react'
import { DateTime } from 'luxon'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import {
  useCopyLastWeekShiftsMutation,
  useCopyShiftsFromPlanTemplateMutation,
  GetLocationForScheduleQuery,
  useCreateAutoScheduleMutation,
} from 'config/graphqlTypes'
import {
  StyledModalTitle,
  StyledModalTitleAutoSchedule,
} from 'components/StyledModalTitle'
import {
  StyledModalBody,
  StyledTemplateButton,
  NoScheduleText,
  BeginTemplates,
  StyledNoScheduleText,
  AutoScheduleText,
  AutoScheduleBtnText,
} from 'pages/plan/scheduling/styles'
import { SelectInputGroup } from 'components/Form'
import { useGetPlanTemplates } from 'queries/useGetPlanTemplates'
import { usePlanQueryVariables } from 'pages/plan/planQueryVariablesContext'
import { useTranslation } from 'react-i18next'
import { useGetPlan } from '../../../queries/useGetPlan'
import { usePlanPageContext } from '../planPageContext'
import { useGetEmployeesShiftsForWeek } from '../../../queries/useGetEmployeeShiftsForWeek'
import { useFeatureToggles } from '../../../contexts/FeatureToggles'
import styled, { css } from 'styled-components'
import Button from '../../../components/Button'
import { ReactComponent as SparkleIcon } from '../../../assets/images/sparkle.svg'
import { ReactComponent as SmallSparkleIcon } from '../../../assets/images/small-sparkle.svg'
import { ReactComponent as CopyIcon } from '../../../assets/images/copy.svg'
import { ReactComponent as TemplateIcon } from '../../../assets/images/template.svg'
import {
  LoadingImage,
  LoadingAutoSchedulingAnimation,
} from '../../../components/Loading'
import { toast } from 'react-toastify'
import { useLoggedInUser } from '../../../queries/useLoggedInUser'
import bulletPoint from 'assets/images/markdown-bullet-point.svg'
import { useGetViolationsPlan } from '../../../queries/useGetViolationsPlan'

type LocationType = GetLocationForScheduleQuery['location']

interface Props {
  location: LocationType
  hideSchedule: () => void
  startDate: DateTime
}

const extractTitleAndText = (
  md_text: string
): { title: string; text: string }[] => {
  const regex = /\*\*(.*?)\*\*:\s*(.*?)(?=\n\*\*|$)/gs
  let matches
  const results: { title: string; text: string }[] = []

  while ((matches = regex.exec(md_text)) !== null) {
    const title = matches[1].trim()
    const text = matches[2].trim()
    results.push({ title, text })
  }

  return results
}

export const TemplateSelectionModal: FC<Props> = ({
  location,
  hideSchedule,
  startDate,
}) => {
  const { t } = useTranslation()
  const { plan } = usePlanPageContext()
  const { id: planId, shifts, planExplanation } = plan
  const { organization } = useLoggedInUser()
  const {
    id: locationId,
    autoSchedulingEnabled,
    planForecastEnabled,
  } = location

  const planExplanationLines = planExplanation
    ? extractTitleAndText(planExplanation)
    : []

  //TODO: remove this when auto scheduling feature is released
  const toggles = useFeatureToggles()
  const autoSchedulingToggle = toggles.features.find(
    t => t.name === 'autoScheduling'
  )

  const displayAutoScheduleBtn =
    autoSchedulingEnabled ?? organization.autoSchedulingEnabled

  const { data, loading: planTemplatesLoading } = useGetPlanTemplates(
    locationId
  )
  const planTemplates = data?.planTemplates ?? null
  const hasShiftsLastWeek = !!location.shiftsLastWeek.length

  const [showModal, setShowModal] = useState(!shifts.length)
  const [isLoading, setIsLoading] = useState(false)
  const [showPlanExplanation, setShowPlanExplanation] = useState(false)
  const [isCreatingAutoSchedule, setIsCreatingAutoSchedule] = useState(false)

  const [
    selectedPlanTemplateId,
    setSelectedPlanTemplateId,
  ] = useState<Number | null>(null)

  const handleStartFromScratch = (e: SyntheticEvent) => {
    e.preventDefault()
    setShowModal(false)
  }

  const onChangeTemplate = (templateId: Number) =>
    setSelectedPlanTemplateId(templateId)

  const planQueryVariables = usePlanQueryVariables()
  const { startOn, endOn } = planQueryVariables
  const { refetch: refetchPlan } = useGetPlan(startOn, endOn)
  const { refetch: refetchViolations } = useGetViolationsPlan(planId)
  const {
    refetch: refetchEmployeesShiftsForWeek,
  } = useGetEmployeesShiftsForWeek(planId)

  const [createAutoSchedule] = useCreateAutoScheduleMutation()
  const [
    copyLastWeekSchedule,
    { loading: copyLastScheduleLoading },
  ] = useCopyLastWeekShiftsMutation({
    variables: {
      input: {
        locationId,
        startDate,
      },
    },
  })
  const [
    loadTemplate,
    { loading: loadTemplateLoading },
  ] = useCopyShiftsFromPlanTemplateMutation()

  const displayToast = (msg: string, type: 'success' | 'error') => {
    toast[type](msg, {
      toastId: type,
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }

  const handleSuccess = async (msg: string) => {
    await refetchPlan()
    setIsLoading(false)
    setShowModal(false)
    displayToast(msg, 'success')
    refetchEmployeesShiftsForWeek()
    refetchViolations()
  }

  const handleAutoSchedulingSuccess = async (msg: string) => {
    await refetchPlan()
    setIsLoading(false)
    planForecastEnabled ? setShowPlanExplanation(true) : setShowModal(false)
    displayToast(msg, 'success')
    refetchEmployeesShiftsForWeek()
    refetchViolations()
  }

  const hideExplanation = () => {
    setShowPlanExplanation(false)
    setShowModal(false)
  }

  const handleError = (msg: string) => {
    setIsLoading(false)
    displayToast(msg, 'error')
  }

  const handleCreateAutoSchedule = async () => {
    setIsLoading(true)
    setIsCreatingAutoSchedule(true)
    try {
      await createAutoSchedule({
        variables: {
          input: {
            locationId,
            startDate,
          },
        },
      })

      handleAutoSchedulingSuccess(
        t('schedule.createSchedule.successCreateAutoSchedule')
      )
    } catch (e) {
      handleError(t('schedule.createSchedule.failedToCreateAutoSchedule'))
    }
  }

  const handleCopyLastWeekSchedule = async () => {
    setIsLoading(true)
    try {
      await copyLastWeekSchedule()
      handleSuccess(t('schedule.createSchedule.successCopyLastWeek'))
    } catch (e) {
      handleError(t('schedule.createSchedule.failedToCopy'))
    }
  }

  const handleLoadTemplate = async (id: Number | null) => {
    try {
      await loadTemplate({
        variables: {
          input: {
            planTemplateId: String(id),
            startOn: startDate,
            locationId,
          },
        },
      })

      handleSuccess(t('schedule.createSchedule.successLoadTemplate'))
    } catch (e) {
      handleError(t('schedule.createSchedule.failedToLoadTemplate'))
    }
  }

  return (
    <>
      {autoSchedulingToggle?.active ? (
        showPlanExplanation ? (
          <StyledModal
            show={showModal}
            onHide={hideExplanation}
            dialogClassName="auto-schedule-modal"
            contentClassName="modal-height"
            aria-labelledby="auto-schedule-modal"
            width={'50vw'}
            borderBottom={'1px solid var(--gray5)'}
          >
            <Modal.Header closeButton={!isLoading}>
              <StyledModalTitleAutoSchedule>
                {t('schedule.createSchedule.autoScheduleExplanationTitle')}
              </StyledModalTitleAutoSchedule>
            </Modal.Header>
            <Modal.Body>
              <StyledContainer fluid>
                <MainRowTwo>
                  <GradientTextTitle>
                    {t(
                      'schedule.createSchedule.autoScheduleReadyForReviewHeader'
                    )}
                  </GradientTextTitle>
                  <GradientText>
                    {t(
                      'schedule.createSchedule.autoScheduleReadyForReviewText'
                    )}
                  </GradientText>
                </MainRowTwo>
                {planExplanation && (
                  <MainRowThree>
                    <MainRowThreeNote>
                      {t('schedule.createSchedule.autoScheduleThingsToNote')}
                    </MainRowThreeNote>
                    <ul>
                      {planExplanationLines.map(({ title, text }, i) => (
                        <li key={i}>
                          <b>{title}</b>
                          <div>{text}</div>
                        </li>
                      ))}
                    </ul>
                  </MainRowThree>
                )}
                <FooterRow>
                  <Button
                    size="lg"
                    onClick={hideExplanation}
                    disabled={loadTemplateLoading}
                  >
                    {t('schedule.createSchedule.autoScheduleViewBtn')}
                  </Button>
                </FooterRow>
              </StyledContainer>
            </Modal.Body>
          </StyledModal>
        ) : (
          <StyledModal
            show={showModal}
            onHide={hideSchedule}
            dialogClassName="auto-schedule-modal"
            contentClassName="modal-height"
            aria-labelledby="auto-schedule-modal"
            width={'50vw'}
            borderBottom={'1px solid var(--gray5)'}
          >
            <Modal.Header closeButton={!isLoading}>
              <StyledModalTitle>
                {isLoading && isCreatingAutoSchedule
                  ? t('schedule.createSchedule.autoScheduleLoadingTitle')
                  : t('schedule.createSchedule.title')}
              </StyledModalTitle>
            </Modal.Header>
            <Modal.Body>
              <StyledContainer fluid>
                {isLoading ? (
                  isCreatingAutoSchedule ? (
                    <LoaderContainer>
                      <LoadingAutoSchedulingAnimation />
                      <LoaderText>
                        {t(
                          'schedule.selectTemplateModal.autoScheduleDisclaimer'
                        )}
                      </LoaderText>
                    </LoaderContainer>
                  ) : (
                    <LoaderContainer>
                      <LoadingImage />
                      {
                        <LoaderText>
                          {t('schedule.selectTemplateModal.copyingLastWeek')}
                        </LoaderText>
                      }
                    </LoaderContainer>
                  )
                ) : (
                  <>
                    <MainRow>
                      {displayAutoScheduleBtn && (
                        <StyledCol>
                          <SparkleIcon />
                          <Section>
                            <TitleWithTag>
                              {t('schedule.createSchedule.autoScheduleTitle')}
                              <BetaTag>BETA</BetaTag>
                            </TitleWithTag>
                            <Description>
                              {t(
                                'schedule.createSchedule.autoScheduleDescription'
                              )}
                            </Description>
                          </Section>
                          <AutoScheduleButton
                            block
                            size="lg"
                            variant="light"
                            type="button"
                            onClick={handleCreateAutoSchedule}
                            disabled={loadTemplateLoading}
                          >
                            <AutoScheduleText>
                              <SmallSparkleIcon />
                              <AutoScheduleBtnText>
                                {t('schedule.createSchedule.autoScheduleBtn')}
                              </AutoScheduleBtnText>
                            </AutoScheduleText>
                          </AutoScheduleButton>
                        </StyledCol>
                      )}
                      <StyledCol>
                        <CopyIcon />
                        <Section>
                          <Title>
                            {t('schedule.createSchedule.copyLastWeekTitle')}
                          </Title>
                          <Description>
                            {t(
                              'schedule.createSchedule.copyLastWeekDescription'
                            )}
                          </Description>
                        </Section>
                        {hasShiftsLastWeek ? (
                          <StyledButton
                            block
                            size="lg"
                            variant="light"
                            type="button"
                            onClick={handleCopyLastWeekSchedule}
                            disabled={copyLastScheduleLoading}
                            waitOnPromise
                          >
                            {t('schedule.createSchedule.copyLastWeekBtn')}
                          </StyledButton>
                        ) : (
                          <TextContainer>
                            <StyledNoScheduleText>
                              {t('schedule.createSchedule.noShiftsLastWeek')}
                            </StyledNoScheduleText>
                          </TextContainer>
                        )}
                      </StyledCol>
                      <StyledCol>
                        <TemplateIcon />
                        <Section>
                          <Title>
                            {t(
                              'schedule.createSchedule.startWithTemplateTitle'
                            )}
                          </Title>
                          <Description>
                            {t(
                              planTemplates?.length
                                ? 'schedule.createSchedule.startWithTemplateDescription'
                                : 'schedule.createSchedule.beginTemplates'
                            )}
                          </Description>
                        </Section>

                        <>
                          {!planTemplatesLoading && !planTemplates?.length ? (
                            <TextContainer>
                              <StyledNoScheduleText>
                                {t('schedule.createSchedule.noSavedTemplates')}
                              </StyledNoScheduleText>
                            </TextContainer>
                          ) : (
                            <>
                              <SelectInputGroup
                                controlId="shiftModalForm.dateSelect"
                                controlProps={{
                                  value: undefined,
                                  disabled:
                                    loadTemplateLoading || planTemplatesLoading,
                                  onChange: (
                                    event: ChangeEvent<HTMLSelectElement>
                                  ) => {
                                    onChangeTemplate(Number(event.target.value))
                                  },
                                  children: (
                                    <>
                                      <option value="">
                                        {t(
                                          'schedule.createSchedule.templatePlaceholder'
                                        )}
                                      </option>
                                      {planTemplates?.map(template => (
                                        <option
                                          value={template.id}
                                          key={template.id}
                                        >
                                          {template.name}
                                        </option>
                                      ))}
                                    </>
                                  ),
                                }}
                              />
                              <StyledButton
                                block
                                size="lg"
                                variant="light"
                                type="button"
                                onClick={() =>
                                  handleLoadTemplate(selectedPlanTemplateId)
                                }
                                disabled={
                                  !selectedPlanTemplateId ||
                                  loadTemplateLoading ||
                                  planTemplatesLoading ||
                                  !planTemplates?.length
                                }
                                waitOnPromise
                              >
                                {t(
                                  'schedule.createSchedule.startWithTemplateBtn'
                                )}
                              </StyledButton>
                            </>
                          )}
                        </>
                      </StyledCol>
                    </MainRow>
                    <FooterRow>
                      <CreateScheduleFromScratchButton
                        onClick={handleStartFromScratch}
                        disabled={loadTemplateLoading}
                      >
                        {t('schedule.createSchedule.startFromScratch')}
                      </CreateScheduleFromScratchButton>
                    </FooterRow>
                  </>
                )}
              </StyledContainer>
            </Modal.Body>
          </StyledModal>
        )
      ) : (
        // TODO: remove this when auto scheduling feature is released
        <Modal show={showModal} centered onHide={hideSchedule}>
          <Modal.Header closeButton>
            <StyledModalTitle>
              {t('schedule.selectTemplateModal.title')}
            </StyledModalTitle>
          </Modal.Header>
          <StyledModalBody>
            <StyledTemplateButton
              variant="blue"
              size="lg"
              onClick={handleStartFromScratch}
              disabled={copyLastScheduleLoading}
            >
              {t('schedule.selectTemplateModal.startFromScratch')}
            </StyledTemplateButton>
            <StyledTemplateButton
              variant="blue"
              size="lg"
              onClick={handleCopyLastWeekSchedule}
              disabled={copyLastScheduleLoading || !hasShiftsLastWeek}
            >
              {t('schedule.selectTemplateModal.copyLastWeek')}
            </StyledTemplateButton>
            {!hasShiftsLastWeek && (
              <NoScheduleText>
                {t('schedule.selectTemplateModal.noShiftsLastWeek')}
              </NoScheduleText>
            )}
            <SelectInputGroup
              controlId="shiftModalForm.dateSelect"
              controlProps={{
                value: undefined,
                onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                  onChangeTemplate(Number(event.target.value))
                },
                children: (
                  <>
                    <option value="">
                      {t('schedule.selectTemplateModal.templatePlaceholder')}
                    </option>
                    {planTemplates?.map(template => (
                      <option value={template.id} key={template.id}>
                        {template.name}
                      </option>
                    ))}
                  </>
                ),
              }}
            />
            {!!planTemplates?.length ? (
              <StyledTemplateButton
                onClick={() => handleLoadTemplate(selectedPlanTemplateId)}
                disabled={!selectedPlanTemplateId}
                waitOnPromise
              >
                {t('schedule.selectTemplateModal.selectTemplate')}
              </StyledTemplateButton>
            ) : (
              <>
                <NoScheduleText>
                  {t('schedule.selectTemplateModal.noSavedTemplates')}
                </NoScheduleText>
                <BeginTemplates>
                  {t('schedule.selectTemplateModal.beginTemplates')}
                </BeginTemplates>
              </>
            )}
          </StyledModalBody>
        </Modal>
      )}
    </>
  )
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LoaderText = styled.span`
  text-align: center;
  font-size: 18px;
`

const CreateScheduleFromScratchButton = styled.a<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? 'var(--gray4)' : 'var(--primary)')};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;

  &:hover {
    color: ${({ disabled }) => (disabled ? 'var(--gray4)' : 'var(--primary)')};
  }
`

const autoScheduleStyles = css`
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      101deg,
      #07ccd4 21.78%,
      #3b4fff 47.12%,
      #bb59e0 71.64%,
      #f6726a 95.76%
    );
`

const GradientText = styled.span`
  ${autoScheduleStyles};
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`

const GradientTextTitle = styled(GradientText)`
  font-size: 18px;
  font-weight: 700;
`

const StyledModal = styled(Modal)<{
  width: string
  borderBottom: string
}>`
  .auto-schedule-modal {
    width: ${({ width }) => width};
    max-width: ${({ width }) => width};
  }

  .modal-header {
    border-bottom: ${({ borderBottom }) => borderBottom};
  }

  .modal-body {
    display: flex;
    padding: 0;
  }

  .modal-content {
    padding-bottom: 30px;
  }
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  text-align: center;
  justify-content: center;
`
const MainRow = styled(Row)`
  padding-top: 20px;
  row-gap: 24px;

  .form-group {
    margin: 0;
    padding: 0;
  }

  .form-control {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 16px;
    margin-right: 16px;
    color: var(--gray4);
  }
`

const MainRowTwo = styled(Row)`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--gray5);
`
const MainRowThree = styled(MainRowTwo)`
  white-space: pre-wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  ul {
    list-style-image: url(${bulletPoint});
    margin-top: 10px;
  }
  ul > li {
    margin-bottom: 10px;
  }
  font-size: 0.875rem;
  font-color: var(--gray2);
`

const MainRowThreeNote = styled.span`
  font-size: 0.875rem;
  font-style: italic;
  color: var(--gray1);
  padding-left: 30px;
`

const FooterRow = styled(Row)`
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 0px
  padding-left: 0px;
  padding-right: 0px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
`

const Title = styled.span`
  color: var(--gray1);
  font-size: 18px;
  font-weight: 800;
  text-align: center;
`

const Description = styled.span`
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`

const StyledButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 185px;
  min-width: 140px;
  max-height: 50px;
  min-height: 50px;
  margin-top: auto;

  white-space: nowrap;
  color: var(--lineup-blue);
  outline: ${({ disabled }) =>
    disabled ? '1px solid var(--gray4)' : '1px solid var(--lineup-blue)'};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${({ disabled }) =>
      disabled ? 'var(--gray2)' : 'var(--lineup-blue)'};
    background-color: var(--gray8);
  }

  &:focus {
    background: transparent !important;
  }

  &:active {
    background: transparent !important;
  }
`

const AutoScheduleButton = styled(StyledButton)<{ disabled: boolean }>`
  outline: none;
  border: solid 2px transparent !important;

  ${autoScheduleStyles};

  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  &:hover {
    border: solid 2px transparent;
  }

  &:focus {
    outline: solid 2px #07ccd4 !important;
  }

  &:active {
    outline: solid 2px #07ccd4;
  }
`

const TitleWithTag = styled(Title)`
  position: relative;
  display: flex;
`

const BetaTag = styled.div`
  background-color: var(--teal-light);
  color: var(--white);
  border-radius: 15px;
  padding: 3px;
  font-size: 7px;
  height: 60%;
  display: flex;
  align-items: center;
  margin-left: 3px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  height: 50px;
`
