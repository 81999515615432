import { DateTime } from 'luxon'

import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useGetPlanQuery } from 'config/graphqlTypes'

export const useGetPlan = (startOn: DateTime, endOn: DateTime) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  const { data, loading, refetch } = useGetPlanQuery({
    variables: {
      endOn,
      locationId,
      startOn,
    },
    nextFetchPolicy: 'cache-first',
  })

  return { data: data?.plan ?? null, loading, refetch }
}
