import React from 'react'
import { FC } from 'react'
import cx from 'classnames'
import { DateTime, Interval } from 'luxon'

import {
  LocationForReportingFragment,
  PlanForReportingFragment,
} from 'config/graphqlTypes'
import {
  StyledReportingTableRow,
  StyledReportingSubheaderCell,
  StyledLocationNameCell,
  StyledLocationName,
} from 'pages/reporting/styles'
import { ReportingDataCell } from 'pages/reporting/ReportingDataCell'
import { SubmittedBadge } from 'pages/reporting/SubmittedBadge'
import { PredictedSalesTooltip } from 'pages/reporting/PredictedSalesTooltip'
import { ForecastAndDiff } from 'pages/reporting/ForecastAndDiff'
import { useTranslation } from 'react-i18next'

interface Props {
  plan: PlanForReportingFragment
  location: LocationForReportingFragment
  isFutureWeek: boolean
  startOfToday: DateTime
  days: Interval[]
}

export const PredictedSalesRow: FC<Props> = ({
  plan,
  location,
  isFutureWeek,
  startOfToday,
  days,
}) => {
  const { t } = useTranslation()

  return (
    <StyledReportingTableRow>
      <StyledLocationNameCell isFutureWeek={isFutureWeek}>
        <StyledLocationName>{location.name}</StyledLocationName>
        <SubmittedBadge plan={plan} />
      </StyledLocationNameCell>
      <StyledReportingSubheaderCell
        className={cx(isFutureWeek && 'bottom-border')}
      >
        {t('reporting.sales')}
      </StyledReportingSubheaderCell>
      {plan.dailySalesAndLaborSummaries.map(
        (dailySalesAndLaborSummary, index) => (
          <ReportingDataCell
            labelTranslationKey={
              index === 0 ? 'reporting.predicted' : undefined
            }
            className={cx(
              isFutureWeek && 'bottom-border',
              index % 2 === 0 && 'stripe'
            )}
            additionalChildren={
              <PredictedSalesTooltip
                dailySalesAndLaborSummary={dailySalesAndLaborSummary}
                location={location}
                day={days[index]}
                startOfToday={startOfToday}
                planSubmittedDate={plan.lastUpdatedAt ?? null}
              />
            }
            key={index}
          >
            <ForecastAndDiff salesAndLaborSummary={dailySalesAndLaborSummary} />
          </ReportingDataCell>
        )
      )}
      <ReportingDataCell
        className={cx(isFutureWeek && 'bottom-border', 'bold')}
      >
        <ForecastAndDiff salesAndLaborSummary={plan.weekSalesAndLaborSummary} />
      </ReportingDataCell>
    </StyledReportingTableRow>
  )
}
