import React, { useTransition } from 'react'
import { useTranslation } from 'react-i18next'

import { Pill } from 'components/Pill'
import {
  ScheduleByToggleWrapper,
  ScheduledByText,
} from 'pages/plan/scheduling/styles'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { sectionBorder } from '../../../config/styles'

interface Props {
  hideExportScheduleBtn: boolean
  isScheduleByRole: boolean
  setIsScheduleByRole: (is: boolean) => void
  onExportSchedule: () => void
}

export const ScheduleByToggle = ({
  hideExportScheduleBtn,
  isScheduleByRole,
  setIsScheduleByRole,
  onExportSchedule,
}: Props) => {
  const { t } = useTranslation()
  const [isPending, startTransition] = useTransition()
  const options = [t('planning.role'), t('planning.person')]
  const activeIndex = isScheduleByRole ? 0 : 1
  const onChange = (newIndex: number) => {
    startTransition(() => setIsScheduleByRole(newIndex === 0))
  }

  return (
    <ScheduleByToggleWrapper>
      <ScheduledByText>{t('planning.scheduleBy')}</ScheduledByText>
      <PillContainer>
        <Pill options={options} activeIndex={activeIndex} onChange={onChange} />
        <SpinnerStyled
          animation="border"
          role="status"
          hidden={!isPending}
          size="sm"
        />
      </PillContainer>
      {!hideExportScheduleBtn && (
        <ExportScheduleBtn onClick={onExportSchedule}>
          {t('planning.exportSchedule')}
        </ExportScheduleBtn>
      )}
    </ScheduleByToggleWrapper>
  )
}

const ExportScheduleBtn = styled.button({
  width: 120,
  fontSize: '0.75rem',
  lineHeight: 1.8,
  fontWeight: 800,
  marginLeft: '.8rem',
  color: 'var(--gray1)',
  backgroundColor: 'var(--gray7)',
  border: sectionBorder,
  borderRadius: '18px',
  whiteSpace: 'nowrap',
  '&:hover': {
    'background-color': 'var(--gray6)',
  },
})

const PillContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const SpinnerStyled = styled(Spinner)({
  marginLeft: '.3rem',
})
