import React, { Fragment } from 'react'
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit'
import { SettingsTableHeader } from '../../SettingsTableHeader'
import DataTable from '../../../../../components/DataTable/DataTable'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { DATA_TABLE_PAGE_SIZE_OPTIONS } from '../../../../../components/DataTable/dataTableUtils'
import {
  TimeOffBlockedDateFragment,
  useGetTimeOffBlockedDatesQuery,
} from '../../../../../config/graphqlTypes'
import Button from '../../../../../components/Button'
import mapMarkerIcon from '../../../../../assets/images/marker-pin-01-icon.svg'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import { Loading } from '../../../../../components/Loading'
import { useLoggedInUserOrRedirect } from '../../../../../queries/useLoggedInUser'
import { useFeatureToggleEnabled } from '../../../../../contexts/FeatureToggles'

const TRANSLATE_PREFIX =
  'management.settings.organizations.blockedDates.dataTable'

interface TimeOffBlockedDateName {
  id: string
  name: string
}

interface TimeOffBlockedDatesDate {
  id: string
  startAt: DateTime
  endAt: DateTime
}

function TimeOffBlockedDatesDataTable() {
  const { organization_id } = useParams<{ organization_id: string }>()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { locations } = useLoggedInUserOrRedirect()
  const closedDaysToggleEnabled = useFeatureToggleEnabled(
    'capturingLocationClosedDays'
  )

  const locationsPerOrg = locations.filter(
    location => location.organization.id === organization_id
  )

  const { data, loading } = useGetTimeOffBlockedDatesQuery({
    variables: { organizationId: organization_id },
  })

  const sortByStartAt = (a: DateTime, b: DateTime) => {
    return a.toMillis() - b.toMillis()
  }

  const formattedTimeOffBlockedDates =
    data?.timeOffBlockedDates
      .sort((a, b) => sortByStartAt(a.startAt, b.startAt))
      .map((timeOffBlockedDate: TimeOffBlockedDateFragment) => {
        const {
          id,
          name,
          startAt,
          endAt,
          locations,
          locationClosed,
          timeOffBlocked,
        } = timeOffBlockedDate
        return {
          id,
          nameBlock: {
            id,
            name,
          },
          dateBlock: {
            id,
            startAt,
            endAt,
          },
          locationNames:
            locationsPerOrg.length === locations.length
              ? [t(`${TRANSLATE_PREFIX}.allLocations`)]
              : locations.map(location => location.name),
          locationClosed,
          timeOffBlocked,
          blockedDateId: id,
        }
      }) || []

  const editBtnFormatter = (blockedDateId: string) => (
    <Button
      style={{ width: 95 }}
      onClick={() =>
        history.push(`${location.pathname}/blocked-pto-dates/${blockedDateId}`)
      }
      block
      size="sm"
      type="submit"
      variant="blue"
    >
      {t('management.actions.editBtn')}
    </Button>
  )

  const dateFormatter = (dateProp: TimeOffBlockedDatesDate) => (
    <FormatterContainer key={dateProp.id}>
      <span>
        {dateProp.startAt.toLocaleString()} - {dateProp.endAt.toLocaleString()}
      </span>
    </FormatterContainer>
  )

  const nameFormatter = (nameProp: TimeOffBlockedDateName) => {
    return (
      <FormatterContainer key={nameProp.id}>
        <b>{nameProp.name}</b>
      </FormatterContainer>
    )
  }

  const booleanFormatter = (value: boolean) => {
    return (
      <FormatterContainer>
        {t(`${TRANSLATE_PREFIX}.${value ? 'yes' : 'no'}`)}
      </FormatterContainer>
    )
  }

  const locationFormatter = (locationNames: string[]) => {
    return (
      <LocationsContainer>
        {locationNames.map((name, i) => {
          return (
            <Fragment key={`${i}_${name}`}>
              <LocationRow>
                {i === 0 && <MarkerIcon alt="" src={mapMarkerIcon} />}
                <span style={{ paddingLeft: i === 0 ? 4 : 20 }}>{name}</span>
              </LocationRow>
            </Fragment>
          )
        })}
      </LocationsContainer>
    )
  }

  const columns = [
    {
      dataField: 'nameBlock',
      text: t(`${TRANSLATE_PREFIX}.columns.name`),
      sort: true,
      formatter: nameFormatter,
      filterValue: (cell: TimeOffBlockedDateName) => cell.name,
    },
    {
      dataField: 'dateBlock',
      text: t(`${TRANSLATE_PREFIX}.columns.date`),
      sort: true,
      formatter: dateFormatter,
      filterValue: (cell: TimeOffBlockedDatesDate) =>
        `${cell.startAt.toLocaleString()} - ${cell.endAt.toLocaleString()}`,
    },
    {
      dataField: 'locationNames',
      text: t(`${TRANSLATE_PREFIX}.columns.location`),
      sort: true,
      formatter: locationFormatter,
      filterValue: (cell: string[]) => cell.join(', '),
    },
    {
      dataField: 'locationClosed',
      text: t(`${TRANSLATE_PREFIX}.columns.locationClosed`),
      sort: true,
      formatter: booleanFormatter,
      filterValue: (cell: boolean) =>
        t(`${TRANSLATE_PREFIX}.${cell ? 'yes' : 'no'}`),
      hidden: !closedDaysToggleEnabled,
    },
    {
      dataField: 'timeOffBlocked',
      text: t(`${TRANSLATE_PREFIX}.columns.timeOffBlocked`),
      sort: true,
      formatter: booleanFormatter,
      filterValue: (cell: boolean) =>
        t(`${TRANSLATE_PREFIX}.${cell ? 'yes' : 'no'}`),
      hidden: !closedDaysToggleEnabled,
    },
    {
      dataField: 'blockedDateId',
      text: '',
      formatter: editBtnFormatter,
    },
  ]

  if (loading) return <Loading />

  return (
    <ToolkitProvider
      keyField="id"
      data={formattedTimeOffBlockedDates}
      columns={columns}
      search
    >
      {(props: ToolkitContextType) => (
        <>
          <SettingsTableHeader searchProps={props.searchProps}>
            <AddNewButton
              onClick={() =>
                history.push(`${location.pathname}/blocked-pto-dates/new`)
              }
              type="button"
              variant="blue"
            >
              {t('settings.addNew')}
            </AddNewButton>
          </SettingsTableHeader>
          <DataTable
            {...props.baseProps}
            keyField="id"
            striped
            hover
            minHeight={'600px'}
            rowHeight={'10px'}
            filter={filterFactory()}
            pagination={paginationFactory({
              sizePerPageList: DATA_TABLE_PAGE_SIZE_OPTIONS,
            })}
          />
        </>
      )}
    </ToolkitProvider>
  )
}

const FormatterContainer = styled.div`
  display: flex;
  align-items: center;
`

const LocationsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const LocationRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
`

const MarkerIcon = styled.img`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`

const AddNewButton = styled(Button)({
  height: 'fit-content',
  minWidth: '120px',
  marginLeft: 15,
  margin: 10,
})

export default TimeOffBlockedDatesDataTable
