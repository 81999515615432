import React, { useState, FormEvent } from 'react'
import 'react-phone-number-input/style.css'
import Form from 'react-bootstrap/Form'
import Button from 'components/Button'
import {
  FormWrapper,
  ButtonsWrapper,
  ManagementSectionDivider,
} from '../../styles'
import { InputGroup } from 'react-bootstrap'
import styled from 'styled-components'
import SettingsSection from '../SettingsSection'
import { useTranslation } from 'react-i18next'

export interface SubmitInput {
  laborPercentOfRevenue: number
  overtimeHoursThreshold: number
  overtimeHoursEmailOn: boolean
}

interface Props {
  submitText: string
  onSubmit: (input: SubmitInput) => void
  organization: any
  allowedToEdit: boolean
}

export const OrganizationForm = ({
  submitText,
  onSubmit,
  organization,
  allowedToEdit,
}: Props) => {
  const { t } = useTranslation()

  const disabled = !allowedToEdit
  const [overtimeHoursThreshold, setOvertimeHoursThreshold] = useState(
    organization?.overtimeHoursThreshold ?? ''
  )
  const [overtimeHoursEmailOn, setOvertimeHoursEmailOn] = useState(
    organization?.overtimeHoursEmailOn ?? ''
  )
  const [laborPercentOfRevenue, setLaborPercentOfRevenue] = useState(
    organization?.laborPercentOfRevenue ?? ''
  )

  const onLaborPercentOfRevenueChange = (percent: number) => {
    if (percent === 0) {
      setLaborPercentOfRevenue(1)
    } else if (percent > 100) {
      setLaborPercentOfRevenue(100)
    } else {
      setLaborPercentOfRevenue(percent)
    }
  }

  const onOvertimeHoursThresholdChange = (hours: number) => {
    setOvertimeHoursThreshold(hours)
  }

  const onOvertimeHoursEmailOnChange = (overtimeHoursEmailOn: boolean) => {
    setOvertimeHoursEmailOn(overtimeHoursEmailOn)
  }

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onSubmit({
      laborPercentOfRevenue: laborPercentOfRevenue!,
      overtimeHoursThreshold: overtimeHoursThreshold!,
      overtimeHoursEmailOn: overtimeHoursEmailOn!,
    })
  }

  const translationPrefix = 'management.settings.organizations.generalInfo'

  return (
    <FormWrapper>
      <Form>
        <EditOrganizationFormContainer>
          <OrganizationHeader>
            {t(`${translationPrefix}.label`)}
          </OrganizationHeader>
          <span>{organization.name}</span>
          <SettingsSection
            header={t(`${translationPrefix}.general.header`)}
            title={t(`${translationPrefix}.general.title`)}
            description={t(`${translationPrefix}.general.description`)}
          >
            <Form.Control
              required={true}
              readOnly={disabled}
              type="number"
              value={laborPercentOfRevenue}
              onChange={e =>
                onLaborPercentOfRevenueChange(Number(e.target.value))
              }
            />
            <InputGroup.Text>%</InputGroup.Text>
          </SettingsSection>
          <SettingsSection
            noControlsGroup
            header={t(`${translationPrefix}.overTimeEmail.header`)}
            title={t(`${translationPrefix}.overTimeEmail.title`)}
            description={t(`${translationPrefix}.overTimeEmail.description`)}
            innerSection={
              <>
                <ManagementSectionDivider />
                <SettingsSection
                  borderless
                  title={t(`${translationPrefix}.overTimeHours.title`)}
                  description={t(
                    `${translationPrefix}.overTimeHours.description`
                  )}
                >
                  <Form.Control
                    required={true}
                    value={overtimeHoursThreshold}
                    disabled={!overtimeHoursEmailOn}
                    readOnly={disabled}
                    onChange={e =>
                      onOvertimeHoursThresholdChange(Number(e.target.value))
                    }
                  />
                  <InputGroup.Text>hours</InputGroup.Text>
                </SettingsSection>
              </>
            }
          >
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={overtimeHoursEmailOn}
              value={overtimeHoursEmailOn}
              disabled={disabled}
              onChange={e => onOvertimeHoursEmailOnChange(e.target.checked)}
            />
          </SettingsSection>
        </EditOrganizationFormContainer>
        <div style={{ width: 220 }}>
          <ButtonsWrapper>
            <Button
              onClick={handleOnSubmit}
              block
              disabled={disabled}
              size="lg"
              type="submit"
              variant="blue"
            >
              {submitText}
            </Button>
          </ButtonsWrapper>
        </div>
      </Form>
    </FormWrapper>
  )
}

const OrganizationHeader = styled.div`
  font-weight: bold;
`

const EditOrganizationFormContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`
