import React from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import {
  useLocationNavigation,
  routeMap,
  getLocationNavigationPath,
} from 'pages/Routes'
import logo from 'assets/images/logos/lineup-ai-blue-black.png'
import mobileLogo from 'assets/images/logos/mobile-logo.svg'
import logoutIcon from 'assets/images/logout-icon.svg'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import {
  useCurrentLocationId,
  useTryCurrentLocation,
} from 'queries/useCurrentLocation'
import { Avatar } from 'components/Avatar'
import {
  getHasViewReportingPermission,
  getHasResetDemoLocationsPermission,
} from 'utils/permissions'
import {
  StyledNavbar,
  LeftContainer,
  Logo,
  OrganizationName,
  NavbarLink,
  RightContainer,
  Divider,
  StyledDropdown,
  StyledDropdownButton,
  DropdownItemButton,
  DropdownIcon,
} from 'components/Navbar/styles'
import { LocationSelector } from 'components/Navbar/LocationSelector'
import { useLogout } from 'utils/useLogout'
import isMobile from 'utils/isMobile'
import { Row } from 'react-bootstrap'
import { AvailabilityRequestsCounter } from 'pages/management/availabilityRequests/availabilityRequestsCounter'
import {
  AvailabilityRequestsCounterContainer,
  ManagementTextContainer,
} from 'pages/management/styles'
import { useGetManagementRequestsCount } from 'pages/management/useGetManagementRequests'
import Button from 'components/Button'
import { useResetDemoLocationMutation } from '../../config/graphqlTypes'
import { toast } from 'react-toastify'
import { useLocalStorage } from 'utils/useLocalStorage'

export const LAST_VISITED_LOCATIONID_KEY = 'lineup_last_visited_location_id'

export default () => {
  const { t } = useTranslation()

  const {
    name,
    avatarUrl,
    organization,
    locations,
    permissions,
  } = useLoggedInUserOrRedirect()

  const currentPath = useLocation().pathname

  const managementRequestCount = useGetManagementRequestsCount()

  const { locationPath: currentLocationPath } = useLocationNavigation()
  const currentLocation = useTryCurrentLocation()
  const currentLocationId = useCurrentLocationId()
  const hasCurrentLocation = !!currentLocationId

  const {
    setItem: storeLastLocationId,
    getItem: getLastLocationId,
  } = useLocalStorage<string>(LAST_VISITED_LOCATIONID_KEY)

  const lastSelectedLocationId = getLastLocationId()
  if (hasCurrentLocation && currentLocationId !== lastSelectedLocationId)
    storeLastLocationId(currentLocationId)
  const lastSelectedLocation = locations.find(
    (location: any) => location.id === lastSelectedLocationId
  )

  const resolvedLocationPath = hasCurrentLocation
    ? currentLocationPath
    : getLocationNavigationPath(lastSelectedLocationId)

  const navToggleName = 'navbar'
  const hideTabs = !hasCurrentLocation && !lastSelectedLocationId

  const isTrackingActive =
    hasCurrentLocation &&
    !!useRouteMatch(currentLocationPath + routeMap.location.tracking)
  const isInsightsActive = !!useRouteMatch(routeMap.insights.insightsRoot)
  const isReportingActive =
    !!useRouteMatch(currentLocationPath + routeMap.location.reporting) ||
    !!useRouteMatch(currentLocationPath + routeMap.location.reportingRoot)
  const isPlanningActive =
    !!useRouteMatch(currentLocationPath + routeMap.location.plans) ||
    !!useRouteMatch(currentLocationPath + routeMap.location.plan)
  const isItemsActive = !!useRouteMatch(
    resolvedLocationPath + routeMap.location.items
  )

  const isManagementActive =
    !!useRouteMatch(
      currentLocationPath + routeMap.location.management.management
    ) &&
    !useRouteMatch(currentLocationPath + routeMap.management.locations) &&
    !useRouteMatch(currentLocationPath + routeMap.management.organizations)

  const getLocationPathForCurrentPage = (locationId: string) =>
    currentPath.replace(
      currentLocationPath,
      getLocationNavigationPath(locationId)
    )

  const hasReportingPermission = getHasViewReportingPermission(permissions)
  const hasResetDemoLocationsPermission = getHasResetDemoLocationsPermission(
    permissions
  )

  const currentLocationIsDemo = currentLocation?.demoLocation === true

  const showLocationSelector =
    currentLocationId &&
    (isTrackingActive ||
      isPlanningActive ||
      isManagementActive ||
      isItemsActive)

  const showItemLevelForecast =
    currentLocation?.itemLevelForecastEnabled ||
    lastSelectedLocation?.itemLevelForecastEnabled

  const logOut = useLogout()

  const [resetDemoLocation, { called }] = useResetDemoLocationMutation({
    update: () => {
      toast.success(t('misc.resetDemoLocationSuccess'), {
        position: toast.POSITION.TOP_CENTER,
        style: { whiteSpace: 'nowrap', width: 'fit-content' },
      })
    },
    variables: {
      input: { locationId: currentLocationIsDemo ? currentLocationId : '' },
    },
  })

  return (
    <StyledNavbar expand="sm">
      <LeftContainer>
        <Logo src={isMobile ? mobileLogo : logo} alt={t('navbar.logoAlt')} />
        <OrganizationName>{organization.name}</OrganizationName>
        {showLocationSelector && (
          <LocationSelector
            locations={locations}
            getPath={getLocationPathForCurrentPage}
          />
        )}
        {hasResetDemoLocationsPermission && currentLocationIsDemo && (
          <Button onClick={resetDemoLocation} disabled={called}>
            Reset Demo Location
          </Button>
        )}
      </LeftContainer>
      <Navbar.Toggle
        data-testid="nav-hamburger"
        aria-controls={navToggleName}
      />
      <Navbar.Collapse id={navToggleName}>
        <Nav className="ml-auto">
          {!hideTabs && (
            <>
              <Nav.Link
                as={NavbarLink}
                to={resolvedLocationPath + routeMap.location.tracking}
                active={isTrackingActive}
              >
                {t('navbar.tracking')}
              </Nav.Link>
              <Nav.Link
                as={NavbarLink}
                to={resolvedLocationPath + routeMap.location.plans}
                active={isPlanningActive}
              >
                {t('navbar.planning')}
              </Nav.Link>
              {hasReportingPermission && (
                <Nav.Link
                  as={NavbarLink}
                  to={routeMap.insights.insightsRoot}
                  active={isInsightsActive}
                >
                  {t('navbar.insights')}
                </Nav.Link>
              )}
              {showItemLevelForecast && (
                <Nav.Link
                  as={NavbarLink}
                  to={resolvedLocationPath + routeMap.location.items}
                  active={isItemsActive}
                >
                  {t('navbar.items')}
                </Nav.Link>
              )}
              {hasReportingPermission &&
                organization.universalId.includes('carrot_express') && (
                  <Nav.Link
                    as={NavbarLink}
                    to={resolvedLocationPath + routeMap.location.reportingRoot}
                    active={isReportingActive}
                  >
                    {t('navbar.reporting')}
                  </Nav.Link>
                )}
              <Row noGutters>
                <Nav.Link
                  as={NavbarLink}
                  to={
                    managementRequestCount === 0
                      ? resolvedLocationPath +
                        routeMap.location.management.employees
                      : resolvedLocationPath +
                        routeMap.location.management.requests
                  }
                  active={isManagementActive}
                >
                  <AvailabilityRequestsCounterContainer>
                    <ManagementTextContainer>
                      {t('navbar.management')}
                    </ManagementTextContainer>
                    <AvailabilityRequestsCounter />
                  </AvailabilityRequestsCounterContainer>
                </Nav.Link>
              </Row>
            </>
          )}
        </Nav>
        <RightContainer>
          <Divider />
          <Avatar name={name} avatarUrl={avatarUrl} fontSize="1rem" />
          <StyledDropdown>
            <Dropdown.Toggle as={StyledDropdownButton} id="nav-dropdown">
              {name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={DropdownItemButton} onClick={logOut}>
                <DropdownIcon src={logoutIcon} alt={t('navbar.logout')} />
                {t('navbar.logout')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </StyledDropdown>
        </RightContainer>
      </Navbar.Collapse>
    </StyledNavbar>
  )
}
