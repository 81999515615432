import React, { FC } from 'react'
import { AccordionWeeklyViewWrapper } from './styles'
import { LaborHoursBreakdownAccordion } from './LaborHoursBreakdownAccordion'
import { DateTime } from 'luxon'
import { LaborHoursDateRange } from '../../config/graphqlTypesRaw'
import { useTranslation } from 'react-i18next'

type LaborHoursBreakdownWeeklyAccordionProps = {
  isCurrentDayOrWeek: boolean
  laborHoursDateRange: LaborHoursDateRange | undefined
  headerTitles: string[]
}

export const LaborHoursBreakdownWeekdayAccordions: FC<LaborHoursBreakdownWeeklyAccordionProps> = ({
  isCurrentDayOrWeek,
  laborHoursDateRange,
  headerTitles,
}) => {
  const { t } = useTranslation()
  const dayFormat = 'EEEE'

  return (
    <>
      {laborHoursDateRange?.dailyBreakDowns.map((weekDay, i) => (
        <AccordionWeeklyViewWrapper key={i}>
          <LaborHoursBreakdownAccordion
            showHeader
            totalHrsTitle={t('tracking.laborActualHours.weekDayTotalHrsLabel', {
              day: DateTime.fromISO(weekDay.day).toFormat(dayFormat),
            })}
            eventKey={i.toString()}
            headerTitles={headerTitles}
            title={DateTime.fromISO(weekDay.day).toFormat(dayFormat)}
            isCurrentDayOrWeek={isCurrentDayOrWeek}
            laborHourEmployees={weekDay?.dailyBreakDown.laborHourEmployees}
            totalHrs={weekDay.dailyBreakDown.totalLaborHours}
            totalPlannedHrs={weekDay.dailyBreakDown.totalPlannedHours}
          />
        </AccordionWeeklyViewWrapper>
      ))}
    </>
  )
}
