import React, { useState } from 'react'
import AuthenticatedPage from 'pages/AuthenticatedPage'
import { useTranslation } from 'react-i18next'
import { ManagementWrapper } from 'pages/management/ManagementWrapper'
import { ManagementContainer, ManagementHeader } from 'pages/management/styles'
import { useHistory, useLocation } from 'react-router-dom'
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import { ToolkitContextType } from 'react-bootstrap-table2-toolkit'
import { toast } from 'react-toastify'
import buildingIcon from 'assets/images/building-02.svg'
import userIcon from 'assets/images/user-03-icon.svg'

import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { Location, LocationSummaryFragment } from 'config/graphqlTypesRaw'
import _ from 'lodash'
import { LocationUsersModal } from './usersView/LocationUsersModal'
import { SettingsTableHeader } from '../SettingsTableHeader'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import DataTable from '../../../../components/DataTable/DataTable'
import { DATA_TABLE_PAGE_SIZE_OPTIONS } from '../../../../components/DataTable/dataTableUtils'
import styled from 'styled-components'
import Button from '../../../../components/Button'

export const Locations = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { locations } = useLoggedInUserOrRedirect()
  const activeLocations = locations.filter(
    (location: LocationSummaryFragment) => location.status === 'active'
  )
  const [showUsersModal, setShowUsersModal] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  )

  function handleToggleUsersModal() {
    setShowUsersModal(show => !show)
  }

  function handleSelectLocation(location: Location) {
    setSelectedLocation(location)
    handleToggleUsersModal()
  }

  const actionsFormatter = (_: any, row: Location) => (
    <ViewLocationButton
      onClick={() => {
        toast.dismiss()
        history.push(`${location.pathname}/${row.id}`)
      }}
      block
      size="sm"
      variant="primary"
      type="button"
    >
      <ButtonText>{t('management.settings.actions.viewBtn')}</ButtonText>
    </ViewLocationButton>
  )

  const namesFormatter = (name: string) => {
    return (
      <NameContainer>
        <LocationIcon alt="" src={buildingIcon} />
        <LocationName>{name}</LocationName>
      </NameContainer>
    )
  }

  const locationsFormatter = (_cell: any, row: Location) => (
    <UsersCountBlock onClick={() => handleSelectLocation(row)}>
      <UserIcon alt="" src={userIcon} />
      <UserCount>{row.userCount}</UserCount>
    </UsersCountBlock>
  )

  const organizationNameFormatter = (_cell: any, row: Location) => (
    <StyledText>{row.organization.name}</StyledText>
  )

  const statusFormatter = (_cell: any, row: Location) => {
    const status = row.status || ''
    return <Status>{_.capitalize(status)}</Status>
  }

  const columns = [
    {
      dataField: 'name',
      text: t('management.settings.locations.name'),
      sort: true,
      formatter: namesFormatter,
    },
    {
      dataField: 'organizationName',
      text: t('management.settings.locations.organization'),
      formatter: organizationNameFormatter,
      sort: true,
      sortValue: (_: any, row: any) => row.organization.name,
    },
    {
      dataField: 'address',
      text: t('management.settings.locations.address'),
      headerStyle: () => {
        return { minWidth: 150, maxWidth: 150, width: '25%' }
      },
      formatter: (_: any, row: Location) => (
        <StyledText>{row.address}</StyledText>
      ),
    },
    {
      dataField: 'userCount',
      text: t('management.settings.locations.users'),
      formatter: locationsFormatter,
    },
    {
      dataField: 'status',
      text: t('management.settings.locations.status'),
      formatter: statusFormatter,
    },
    {
      dataField: 'actions',
      text: t('management.settings.actions.label'),
      sort: false,
      formatter: actionsFormatter,
    },
  ]

  return (
    <>
      <LocationUsersModal
        show={showUsersModal}
        toggleShow={handleToggleUsersModal}
      >
        {selectedLocation}
      </LocationUsersModal>
      <AuthenticatedPage>
        <ManagementWrapper>
          <>
            <ManagementHeader>
              {t('management.settings.locations.header')}
            </ManagementHeader>
            <ManagementContainer>
              <ToolkitProvider
                keyField="id"
                data={activeLocations}
                columns={columns}
                search
              >
                {(props: ToolkitContextType) => (
                  <>
                    <SettingsTableHeader searchProps={props.searchProps} />
                    <DataTable
                      {...props.baseProps}
                      keyField="id"
                      striped
                      hover
                      minHeight={'600px'}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        sizePerPageList: DATA_TABLE_PAGE_SIZE_OPTIONS,
                      })}
                    />
                  </>
                )}
              </ToolkitProvider>
            </ManagementContainer>
          </>
        </ManagementWrapper>
      </AuthenticatedPage>
    </>
  )
}

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const LocationName = styled.span`
  display: block;
  color: var(--gray1);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 15px;
`

const StyledText = styled.span`
  display: block;
  color: var(--gray1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const UserIcon = styled.img`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`

const UserCount = styled.span`
  color: var(--gray1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`

const Status = styled.span`
  color: var(--green-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const ButtonText = styled.span`
  color: var(--white);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 15px;
`

const LocationIcon = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`

const ViewLocationButton = styled(Button)({
  maxWidth: '80%',
  minWidth: '80px',
})

const UsersCountBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 26px;
  border: 1px solid var(--gray5);
  cursor: pointer;
  width: 75px;
  height: 32px;
  flex-shrink: 0;

  &:hover {
    border: 1px solid var(--gray3);
    background: var(--gray7);
  }
`
