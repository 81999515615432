import React from 'react'
import { useHistory } from 'react-router-dom'

import Page from 'pages/Page'
import { HeroBlock } from 'pages/covid/welcome/HeroBlock'
import { Welcome } from 'pages/covid/welcome/Welcome'
import { useQueryParams } from 'utils/useQueryParams'
import { routeMap } from 'pages/Routes'

export const CovidDashboardWelcome = () => {
  const legacyDashId = useQueryParams().get('dashboard_id')
  const history = useHistory()

  // Support URLs from the early app before we had a separate path for welcome vs. dashboard
  if (legacyDashId) {
    history.push(`${routeMap.covidDashboard}?dashboard_id=${legacyDashId}`)
  }

  return (
    <Page>
      <HeroBlock />
      <Welcome />
    </Page>
  )
}
