import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Label, Value } from 'components/Chart/Tooltip/styles'

interface ValueDescriptionProps {
  transKey: string
  formattedValue: string
  valueColor: string
  labelColor?: string
}

export const ValueDescription = ({
  transKey,
  formattedValue,
  valueColor,
  labelColor = 'gray2',
}: ValueDescriptionProps) => {
  const { t } = useTranslation()
  const valueColorVar = `var(--${valueColor})`
  const labelColorVar = `var(--${labelColor})`

  return (
    <Container data-testid={t(transKey)}>
      <Label color={labelColorVar}>{t(transKey)}</Label>
      <Value color={valueColorVar}>{formattedValue}</Value>
    </Container>
  )
}
