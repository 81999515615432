import React, { FC } from 'react'
import { CustomLayerProps } from '@nivo/line'

import { getSerieConfig } from 'components/Chart/chartDataConfig'

export const StaticLineLayer: FC<CustomLayerProps> = ({
  lineGenerator,
  lineWidth,
  series,
}) => {
  return (
    <>
      {series.map(({ id, data }) => {
        const points = data.map(d => d.position)
        const { color } = getSerieConfig(id)

        return (
          <path
            key={id}
            d={lineGenerator(points)}
            fill="none"
            strokeWidth={lineWidth}
            style={{ stroke: color }}
          />
        )
      })}
    </>
  )
}
