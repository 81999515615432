import React from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { SummaryInteger } from 'pages/covid/dashboard/addressRow/SummaryInteger'
import {
  BenchmarkMetric,
  BenchmarkTimeFrame,
  DateDisplay,
  BenchmarkTimeWrapper,
} from 'pages/covid/dashboard/addressRow/styles'

interface Props {
  metric: number
  translationKey: string
  timeframe?: string
  periods?: number
}

export const BenchmarkData = ({
  metric,
  translationKey,
  timeframe,
  periods,
}: Props) => {
  const { t } = useTranslation()
  let display
  if (timeframe) {
    const today = DateTime.local().minus({ [`${timeframe}s`]: periods })
    display = `${today.monthLong} ${today.year}`
  }

  let timeFrameDisplay
  if (timeframe === 'year' && periods === 2) {
    timeFrameDisplay = t('covidDashboard.cy2019')
  } else {
    timeFrameDisplay = t('covidDashboard.lastTimeframe', { timeframe })
  }

  return (
    <BenchmarkMetric>
      <BenchmarkTimeWrapper>
        {timeframe && (
          <>
            <BenchmarkTimeFrame>{timeFrameDisplay}</BenchmarkTimeFrame>
            <DateDisplay>{display}</DateDisplay>
          </>
        )}
      </BenchmarkTimeWrapper>
      <SummaryInteger
        value={metric}
        translationKey={translationKey}
        roundPrecision={0}
        small
      />
    </BenchmarkMetric>
  )
}
