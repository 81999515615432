import React, { FC, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'
import { ExportScheduleOptionsForm } from './ExportScheduleOptionsForm'
import { useTranslation } from 'react-i18next'
import {
  PlanFragment,
  ShiftFragment,
  useCreateWeeklyPlanExportMutation,
} from '../../../config/graphqlTypes'
import { groupBy, uniqBy } from 'lodash'
import { PdfFileViewer } from '../../../components/PdfFileViewer/PdfFileViewer'
import {
  CreateWeeklyPlanExportMutationInput,
  PdfContentOptions,
} from 'config/graphqlTypesRaw'
import { SelectMultiOrSingleValueDropdown } from './exportOptions/ExportScheduleOptionsSelect'
import { SelectDropdownOption } from 'components/SelectDropdown'

type ExportScheduleInputKeys = keyof CreateWeeklyPlanExportMutationInput
type ExportScheduleInputValue = string | string[] | PdfContentOptions

interface FilterTriple {
  employee: string
  role: string
  department: string
}

interface FiltersRefinement {
  employees: Record<string, Record<string, FilterTriple[]>>
  departments: Record<string, Record<string, FilterTriple[]>>
}

interface Props {
  planId: PlanFragment['id']
  planStartOn: PlanFragment['startOn']
  isScheduleByRole: boolean
  shifts: ShiftFragment[]
  show: boolean
  toggleShow: () => void
}

export const ExportScheduleModal: FC<Props> = ({
  planId,
  planStartOn,
  shifts,
  show,
  isScheduleByRole,
  toggleShow,
}) => {
  const { t } = useTranslation()
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined)

  const departments = uniqBy(
    shifts.filter(shift => shift.shiftRole?.shiftRoleCategory),
    shift => shift.shiftRole?.shiftRoleCategory?.id
  ).map(shift => ({
    value: shift.shiftRole?.shiftRoleCategory?.id!,
    label: shift.shiftRole?.shiftRoleCategory?.name!,
  }))

  const employees = uniqBy(shifts, shift => shift.employee.id).map(shift => ({
    value: shift.employee.id,
    label: shift.employee.name,
  }))

  const roles = uniqBy(shifts, shift => shift.shiftRole?.id).map(shift => ({
    value: shift.shiftRole?.id ?? 'unavailable',
    label:
      shift.shiftRole?.name ??
      t('planning.exportScheduleModal.exportOptions.role.unavailability'),
  }))

  const filtersTriples: FilterTriple[] = shifts.map(shift => ({
    employee: String(shift.employee.id),
    role: String(shift.shiftRole?.id),
    department: String(shift.shiftRole?.shiftRoleCategoryId),
  }))

  const filtersRefinements: FiltersRefinement = {
    employees: Object.fromEntries(
      Object.entries(
        groupBy(filtersTriples, x => x.employee)
      ).map(([key, value]) => [key, groupBy(value, x => x.department)])
    ),
    departments: Object.fromEntries(
      Object.entries(
        groupBy(filtersTriples, x => x.department)
      ).map(([key, value]) => [key, groupBy(value, x => x.employee)])
    ),
  }

  const [availableDepartments, setAvailableDepartments] = useState<
    SelectDropdownOption[]
  >(departments)
  const [availableEmployees, setAvailableEmployees] = useState<
    SelectDropdownOption[]
  >(employees)
  const [availableRoles, setAvailableRoles] = useState<SelectDropdownOption[]>(
    roles
  )

  const defaultCreateWeeklyPlanExportInput: CreateWeeklyPlanExportMutationInput = {
    planId,
    exportType: 'pdf',
    orientation: 'portrait',
    pageSize: 'letter',
    type: isScheduleByRole ? 'role' : 'person',
    employees: employees.map(employee => employee.value),
    shiftRoleCategories: departments.length
      ? departments.map(department => department.value)
      : [],
    shiftRoles: roles.map(role => role.value),
    contentOptions: {
      totalHours: false,
      roles: false,
      salesData: false,
      externalEvents: false,
      internalEvents: false,
      reservations: false,
      weather: false,
    },
  }

  const [
    createWeeklyPlanExportInput,
    setCreateWeeklyPlanExportInput,
  ] = useState<CreateWeeklyPlanExportMutationInput>(
    defaultCreateWeeklyPlanExportInput
  )

  const updateAvailableFilters = (
    exportInput: CreateWeeklyPlanExportMutationInput
  ) => {
    let availableDepartmentIds: FilterTriple[] | null = null
    let availableEmployeesIds: FilterTriple[] | null = null
    if (exportInput.employees?.length) {
      availableDepartmentIds = exportInput.employees
        .map((employeeId: string) =>
          Object.values(filtersRefinements.employees[employeeId]).flat()
        )
        .flat()
      setAvailableDepartments(
        departments.filter(
          d =>
            availableDepartmentIds ||
            [].map((f: FilterTriple) => f.department).indexOf(d.value) > -1
        )
      )
    } else {
      setAvailableDepartments(departments)
    }
    if (exportInput.shiftRoleCategories?.length) {
      availableEmployeesIds = exportInput.shiftRoleCategories
        .map((departmentId: string) =>
          Object.values(filtersRefinements.departments[departmentId]).flat()
        )
        .flat()
      setAvailableEmployees(
        employees.filter(
          (d: SelectDropdownOption) =>
            (availableEmployeesIds || [])
              .map((f: FilterTriple) => f.employee)
              .indexOf(d.value) > -1
        )
      )
    } else {
      setAvailableEmployees(employees)
    }
    if (
      exportInput.type !== 'role' &&
      (exportInput.shiftRoleCategories?.length || exportInput.employees?.length)
    ) {
      const allRoles = roles.map((f: SelectDropdownOption) => f?.value)
      const departmentRoles = availableDepartmentIds
        ? availableDepartmentIds.map((f: FilterTriple) => f.role)
        : allRoles
      const employeeRoles = new Set(
        availableEmployeesIds
          ? availableEmployeesIds.map((f: FilterTriple) => f.role)
          : allRoles
      )
      const availableRoles = new Set(
        [...departmentRoles].filter(r => employeeRoles.has(r))
      )
      setAvailableRoles(roles.filter(d => availableRoles.has(d.value)))
    } else {
      setAvailableRoles(roles)
    }
  }

  const [
    createWeeklyPlanExport,
    { loading, error },
  ] = useCreateWeeklyPlanExportMutation({
    variables: { input: createWeeklyPlanExportInput },
  })

  useEffect(() => {
    createWeeklyPlanExport().then(result => {
      setPdfUrl(result.data?.createWeeklyPlanExportMutation?.url)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const convertToStringArray = (options: SelectMultiOrSingleValueDropdown) =>
    (options as SelectDropdownOption[]).map(option => option.value)

  const handleExportOptionsChange = (
    key: ExportScheduleInputKeys,
    value: ExportScheduleInputValue
  ) => {
    const newCreateWeeklyPlanExportInput = {
      ...createWeeklyPlanExportInput,
      [key]: value,
    }
    setCreateWeeklyPlanExportInput(newCreateWeeklyPlanExportInput)
    updateAvailableFilters(newCreateWeeklyPlanExportInput)
    createWeeklyPlanExport({
      variables: {
        input: { ...createWeeklyPlanExportInput, [key]: value },
      },
    }).then(result => {
      const url = result.data?.createWeeklyPlanExportMutation?.url
      setPdfUrl(url)
    })
  }

  const handleExportShiftRolesChange = (
    key: ExportScheduleInputKeys,
    value: string[]
  ) => {
    if (!value.length) {
      value = roles.map(role => role.value)
    }
    handleExportOptionsChange(key, value)
  }

  const exportFile = (url: string, type: 'pdf' | 'xlsx') => {
    fetch(url, {
      method: 'GET',
    })
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `plan_for_week_of_${planStartOn.toFormat(
          'MM_dd_yyyy'
        )}.${type}`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
  }

  function handleExportToPdf() {
    pdfUrl && exportFile(pdfUrl, 'pdf')
  }

  async function handleExportToExcel() {
    const res = await createWeeklyPlanExport({
      variables: {
        input: { ...createWeeklyPlanExportInput, exportType: 'excel' },
      },
    })
    const url = res.data?.createWeeklyPlanExportMutation?.url
    url && exportFile(url, 'xlsx')
  }

  return (
    <StyledModal
      show={show}
      onHide={toggleShow}
      dialogClassName="export-schedule-modal"
      contentClassName="modal-height"
      aria-labelledby="export-schedule-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('planning.exportScheduleModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col xs={12} lg={8}>
              <PdfFileViewer
                file={pdfUrl}
                isLoading={loading}
                serverError={error}
              />
            </Col>
            <Col xs={12} lg={4} style={{ zIndex: 2 }}>
              <ExportOptionsTitle>
                {t('planning.exportScheduleModal.exportOptions.title')}
              </ExportOptionsTitle>
              <ExportScheduleOptionsForm
                isScheduleByRole={createWeeklyPlanExportInput.type === 'role'}
                departments={availableDepartments}
                employees={availableEmployees}
                roles={availableRoles}
                fileUrl={pdfUrl}
                isLoading={loading}
                onSelectView={view => handleExportOptionsChange('type', view)}
                onSelectEmployees={employees =>
                  handleExportOptionsChange(
                    'employees',
                    convertToStringArray(employees)
                  )
                }
                onSelectDepartments={departments =>
                  handleExportOptionsChange(
                    'shiftRoleCategories',
                    convertToStringArray(departments)
                  )
                }
                onSelectRoles={roles =>
                  handleExportShiftRolesChange(
                    'shiftRoles',
                    convertToStringArray(roles)
                  )
                }
                onSelectOrientation={orientation =>
                  handleExportOptionsChange('orientation', orientation.value)
                }
                onSelectPageSize={pageSize =>
                  handleExportOptionsChange('pageSize', pageSize.value)
                }
                onSelectTotalHoursCheckbox={totalHours =>
                  handleExportOptionsChange('contentOptions', {
                    ...createWeeklyPlanExportInput.contentOptions,
                    totalHours,
                  } as PdfContentOptions)
                }
                onSelectRolesCheckbox={roles =>
                  handleExportOptionsChange('contentOptions', {
                    ...createWeeklyPlanExportInput.contentOptions,
                    roles,
                  } as PdfContentOptions)
                }
                onSelectSalesDataCheckbox={salesData =>
                  handleExportOptionsChange('contentOptions', {
                    ...createWeeklyPlanExportInput.contentOptions,
                    salesData,
                  } as PdfContentOptions)
                }
                onSelectExternalEventsCheckbox={externalEvents =>
                  handleExportOptionsChange('contentOptions', {
                    ...createWeeklyPlanExportInput.contentOptions,
                    externalEvents,
                  } as PdfContentOptions)
                }
                onSelectInternalEventsCheckbox={internalEvents =>
                  handleExportOptionsChange('contentOptions', {
                    ...createWeeklyPlanExportInput.contentOptions,
                    internalEvents,
                  } as PdfContentOptions)
                }
                onSelectWeatherCheckbox={weather =>
                  handleExportOptionsChange('contentOptions', {
                    ...createWeeklyPlanExportInput.contentOptions,
                    weather,
                  } as PdfContentOptions)
                }
                onSelectReservationsCheckbox={reservations =>
                  handleExportOptionsChange('contentOptions', {
                    ...createWeeklyPlanExportInput.contentOptions,
                    reservations,
                  } as PdfContentOptions)
                }
                onExportToPdf={handleExportToPdf}
                onExportToExcel={handleExportToExcel}
                defaultCreateWeeklyPlanExportInput={
                  defaultCreateWeeklyPlanExportInput
                }
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .export-schedule-modal {
    width: 65vw;
    max-width: 65vw;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-title {
    font-weight: 800;
  }
`

const ExportOptionsTitle = styled.span({
  display: 'block',
  fontSize: '1.3rem',
  fontWeight: 800,
  paddingBottom: '.8rem',
  lineHeight: 1,
  margin: 0,
})
