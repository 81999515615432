import styled from 'styled-components'

import { TableLabelBoldText, RegularBoldBodyText } from 'components/Text'
import {
  ChartVerticalLineWidth,
  ChartVerticalLineColor,
} from 'pages/plan/dailyViewModal/GridLines'
import {
  SMALL_WIDTH,
  VERY_SMALL_WIDTH,
  HorizontalChartMargin,
  FULL_WIDTH,
} from 'pages/plan/dailyViewModal/styles'
import {
  regularBodyBoldTextStyle,
  tableLabelBoldTextStyle,
  tableLabelTextStyle,
} from 'config/styles'
import { TextButton } from 'components/Button'

export const ScheduleWrapper = styled.div({
  height: '100%',
})

const downScaleFontCss = {
  [SMALL_WIDTH]: {
    fontSize: 'var(--font-downscale)',
  },
  [VERY_SMALL_WIDTH]: {
    fontWeight: 500,
  },
}

export const RoleHeading = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: '0.5em',
})

export const ExpandCollapseButton = styled(TextButton)({
  paddingLeft: 0,
  [SMALL_WIDTH]: {
    paddingRight: 10,
  },
  [VERY_SMALL_WIDTH]: {
    paddingRight: 5,
  },
})

export const ShiftRoleName = styled.div({
  ...regularBodyBoldTextStyle,
  ...downScaleFontCss,
  color: 'var(--gray1)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: 1,
})

export const EmployeeNameAvatarWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: `calc(100% + ${HorizontalChartMargin}px - 5px)`,
  paddingLeft: 24,
  [SMALL_WIDTH]: {
    paddingLeft: 14,
    '& .avatar': {
      width: 20,
      height: 20,
      fontSize: 'var(--font-downscale)',
    },
  },
  [VERY_SMALL_WIDTH]: {
    paddingLeft: 0,
    '& .avatar': {
      display: 'none',
    },
  },
})

export const EmployeeName = styled(RegularBoldBodyText)({
  ...downScaleFontCss,
  marginLeft: '.5em',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})

export const ScheduleVerticalLine = styled.div<{
  left: number
}>(({ left }) => ({
  position: 'absolute',
  height: '100%',
  left: `${left}%`,
  width: ChartVerticalLineWidth,
  backgroundColor: ChartVerticalLineColor,
}))

export const EmployeeScheduleContainer = styled.div({
  height: '100%',
  width: '100%',
})

// We need to trick the browser into thinking that there's a visible drag preview here.
// Otherwise, chrome will automatically add a little globe icon
export const InvisibleDragPreview = styled.div({
  height: 1,
  width: 1,
  position: 'absolute',
})

export const ShiftPillContainer = styled.div<{
  left: number
  right: number
  other?: boolean
  conflict?: boolean
  isEditable?: boolean
}>(({ left, right, other, conflict, isEditable }) => ({
  borderRadius: '15px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 'calc(100% - 6px)',
  position: 'absolute',
  outline: '1px solid var(--gray2)',
  left: `${left}%`,
  right: `${right}%`,
  zIndex: conflict ? 3 : other ? 1 : 2,
  background: other
    ? 'var(--gray6)'
    : conflict
    ? 'var(--bittersweet)'
    : 'var(--teal-lighter)',
  color: other ? 'var(--gray4)' : 'var(--white)',
  [FULL_WIDTH]: {
    cursor: isEditable && !(other || conflict) ? 'grab' : 'default',
  },
}))

export const ShiftPillEnd = styled.div<{
  other?: boolean
  conflict?: boolean
}>(({ conflict, other }) => ({
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textOverflow: conflict ? 'ellipsis' : 'none',
  whiteSpace: 'nowrap',
  overflow: conflict ? 'hidden' : 'visible',
  padding: other ? '0 10px' : '',
}))

export const ShiftPillDragHandle = styled.div({
  ...tableLabelBoldTextStyle,
  ...downScaleFontCss,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 30,
  lineHeight: '30px',
  width: 24,
  [SMALL_WIDTH]: {
    width: 10,
  },
})

export const ShiftText = styled(TableLabelBoldText)<{
  width?: number
  conflict?: boolean
}>(({ width, conflict }) => ({
  ...downScaleFontCss,
  paddingLeft: conflict ? 8 : 0,
  paddingRight: conflict ? 8 : 0,
  width: width ?? 'initial',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: 'var(--gray2)',
  overflow: conflict ? 'hidden' : 'visible',
  [VERY_SMALL_WIDTH]: {
    display: 'none',
  },
}))

export const ShiftTotalWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  [SMALL_WIDTH]: {
    alignItems: 'flex-end',
  },
})

export const ShiftTotal = styled.div<{ isRoleTotal?: boolean }>(
  ({ isRoleTotal }) => ({
    ...(isRoleTotal ? regularBodyBoldTextStyle : tableLabelTextStyle),
    ...downScaleFontCss,
    color: 'var(--gray2)',
  })
)

export const ShiftTotalDiff = styled(TableLabelBoldText)({
  ...downScaleFontCss,
  marginTop: 5,
  color: 'var(--gray4)',
})

export const DividerRowPlaceholder = styled.div({
  height: 10,
})

export const DailyViewAddShiftIcon = styled.img({
  marginRight: 5,
  width: 10,
})

export const DailyViewAddShiftButtonWrapper = styled.div({
  whiteSpace: 'nowrap',
  marginBottom: 12,
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
  '& button': {
    width: 119,
    backgroundColor: 'var(--blue)',
  },
})
