import styled from 'styled-components'
import Tooltip from 'react-bootstrap/Tooltip'

import { smallBodyTextStyle } from 'config/styles'

export const InfoTooltip = styled(Tooltip)({
  opacity: '1 !important',
  backgroundColor: 'transparent',

  '& .tooltip-inner': {
    backgroundColor: 'var(--white)',
    color: 'var(--gray1)',
    border: '1px solid var(--gray5)',
    filter: 'drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px)',
    padding: 6,
    marginTop: -12,
    ...smallBodyTextStyle,
    lineHeight: '130%',
  },

  '& .arrow': {
    display: 'none !important',
  },

  '& .tooltip-inner::before, .tooltip-inner::after': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: '100%',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },

  '& .tooltip-inner::after': {
    right: 'calc(50% - 7px)',
    borderColor: 'var(--white) transparent transparent transparent',
    borderWidth: 7,
  },

  '& .tooltip-inner::before': {
    right: 'calc(50% - 9px)',
    borderColor: 'var(--gray5) transparent transparent transparent',
    borderWidth: 9,
  },
})
