import { useTranslation } from 'react-i18next'
import { addProps } from 'ad-hok'
import { TFunction } from 'i18next'

type AddTranslationHelpersType = <SimplePropsAdder extends {}>(
  addProps: SimplePropsAdder
) => SimplePropsAdder & {
  t: TFunction
}

const addTranslationHelpers: AddTranslationHelpersType = addProps(() => {
  const { t } = useTranslation()

  return { t }
})

export default addTranslationHelpers
