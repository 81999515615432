import React, { useState, ChangeEvent, FormEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { routeMap } from './Routes'
import { Form, StringInputGroup } from 'components/Form'
import { useRequestPasswordResetMutation } from 'config/graphqlTypes'
import Button, { ButtonLink } from 'components/Button'
import { UnauthenticatedPage, FormContainer } from 'pages/Page'
import { regularBodyBoldTextStyle } from 'config/styles'

const ReturnToLoginLink = styled(Link)({
  ...regularBodyBoldTextStyle,
  color: 'var(--gray3)',
  marginBottom: 28,
  textAlign: 'center',
})

const HeaderText = styled.p({
  fontSize: '1.625rem',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '1.625rem',
  marginBottom: 12,
  textAlign: 'center',
})

const BodyText = styled.p({
  fontSize: '1.125rem',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '24px',
  marginBottom: 30,
  textAlign: 'center',
})

export const RequestPasswordReset = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const [requestPasswordResetMutation] = useRequestPasswordResetMutation({
    update: (_cache, result) => {
      const success: boolean =
        result?.data?.requestPasswordReset?.success || false

      if (success) {
        history.push(routeMap.requestPasswordResetSuccess)
      } else {
        setError(t('requestPasswordReset.passwordResetError'))
      }
    },
    variables: { email },
  })

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('')
    setEmail(e.target.value)
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError('')
    requestPasswordResetMutation()
  }

  return (
    <UnauthenticatedPage>
      <FormContainer>
        <ReturnToLoginLink
          data-testid={t('requestPasswordReset.returnToLogin')}
          to={routeMap.login}
        >
          {'<< ' + t('requestPasswordReset.returnToLogin')}
        </ReturnToLoginLink>
        <HeaderText>{t('requestPasswordReset.troubleLoggingIn')}</HeaderText>
        <BodyText>{t('requestPasswordReset.instructions')}</BodyText>
        <Form onSubmit={onSubmit} error={error}>
          <StringInputGroup
            controlProps={{
              placeholder: t('requestPasswordReset.email'),
              value: email,
              onChange: onEmailChange,
              type: 'email',
            }}
          />
          <Button
            block
            disabled={!email}
            size="lg"
            type="submit"
            variant="primary"
          >
            {t('requestPasswordReset.sendResetLink')}
          </Button>
        </Form>
      </FormContainer>
    </UnauthenticatedPage>
  )
}

export const RequestPasswordResetSuccess = () => {
  const { t } = useTranslation()

  return (
    <UnauthenticatedPage>
      <FormContainer>
        <HeaderText>{t('requestPasswordReset.success')}</HeaderText>
        <BodyText>{t('requestPasswordReset.confirmation')}</BodyText>
        <ButtonLink
          data-testid={t('requestPasswordReset.returnToLogin')}
          to={routeMap.login}
        >
          {t('requestPasswordReset.returnToLogin')}
        </ButtonLink>
      </FormContainer>
    </UnauthenticatedPage>
  )
}
