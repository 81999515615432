import React from 'react'
import { useHistory } from 'react-router-dom'
import { useLocationNavigation, routeMap } from 'pages/Routes'
import {
  PlanSummaryLightFragment,
  useGetLaborDollarsQuery,
} from 'config/graphqlTypes'
import {
  Row,
  Col,
  SmallBlueSpan,
  StatusDot,
  LargeBodySpan,
  LargeBodyBoldSpan,
} from 'pages/plans/styles'
import { useCurrentLocation } from 'queries/useCurrentLocation'
import { PlansTableMetrics } from 'pages/plans/PlansTableMetrics'
import { usePlanStatus, PlanStatusBadgeColumn } from 'components/PlanStatus'

interface Props {
  plan: PlanSummaryLightFragment
  isPast?: boolean | null
  index: number
}

export const PlanRow = ({ plan, isPast, index }: Props) => {
  const { locationPath } = useLocationNavigation()
  const {
    location: { id: locationId, prefersEntreesPerLaborHourMetric },
  } = useCurrentLocation()
  const history = useHistory()
  const planningRoot = locationPath + routeMap.location.plans + '/'

  const onRowClick = (plan: PlanSummaryLightFragment) => () =>
    history.push(planningRoot + plan.startOn.toISODate())

  const {
    plannedEntreesPerScheduledLaborHour,
    plannedSalesPerScheduledLaborHour,
    originallyPredictedSales,
    submittedPlannedSales,
    optimalLaborForPlannedSales,
    scheduledLabor,
    actualSales,
    actualLabor,
    actualLaborDollars,
    actualEntreesPerActualLaborHour,
    actualSalesPerActualLaborHour,
  } = plan.weekSalesAndLaborSummary
  const statusStyle = usePlanStatus(plan)

  const { data: laborDollarsData } = scheduledLabor
    ? useGetLaborDollarsQuery({
        variables: {
          locationId,
          planId: plan.id,
        },
      })
    : { data: undefined }

  const planTitle = `${plan.startOn.toFormat('LLL d')} - ${plan.endOn.toFormat(
    'LLL d'
  )}`
  const testid = `plans-row-${planTitle}`

  const isFirstPlan = index === 0 && !isPast
  const isSecondPlan = index === 1 && !isPast
  const CurrentWeekLabel = () => {
    if (isFirstPlan) {
      return <SmallBlueSpan>Current Week</SmallBlueSpan>
    }
    if (isSecondPlan) {
      return <SmallBlueSpan>Next Week</SmallBlueSpan>
    }
    return null
  }

  return (
    <Row
      style={{
        backgroundColor: isFirstPlan || isSecondPlan ? '#3b4eff1a' : '#FFFFFF',
      }}
      key={plan.id}
      data-testid={testid}
      onClick={onRowClick(plan)}
    >
      <Col size={1.4}>
        {statusStyle.backgroundColor ? (
          <div>
            <StatusDot color={statusStyle.backgroundColor} />
            <LargeBodyBoldSpan>{planTitle}</LargeBodyBoldSpan>
            <CurrentWeekLabel />
          </div>
        ) : (
          <>
            <LargeBodySpan>{planTitle}</LargeBodySpan>
            <CurrentWeekLabel />
          </>
        )}
      </Col>
      <PlansTableMetrics
        prefersEntreesPerLaborHourMetric={prefersEntreesPerLaborHourMetric}
        sales={submittedPlannedSales ?? null}
        salesBenchmark={originallyPredictedSales}
        salesDiffKey="predictedSales"
        labor={scheduledLabor ?? null}
        laborBenchmark={optimalLaborForPlannedSales}
        laborDollars={laborDollarsData?.laborDollars?.totalCents ?? null}
        laborDiffKey="scheduledHours"
        entreesPerHour={plannedEntreesPerScheduledLaborHour ?? null}
        salesPerHour={plannedSalesPerScheduledLaborHour ?? null}
        isPast={isPast}
        testidPrefix="planned"
      />
      <PlansTableMetrics
        prefersEntreesPerLaborHourMetric={prefersEntreesPerLaborHourMetric}
        sales={actualSales ?? null}
        salesBenchmark={submittedPlannedSales ?? null}
        salesDiffKey="actualSales"
        labor={actualLabor ?? null}
        laborBenchmark={scheduledLabor ?? null}
        laborDollars={actualLaborDollars ?? null}
        laborDiffKey="actualHours"
        entreesPerHour={actualEntreesPerActualLaborHour ?? null}
        salesPerHour={actualSalesPerActualLaborHour ?? null}
        testidPrefix="actual"
      />
      <Col>
        <PlanStatusBadgeColumn
          submittedAt={statusStyle.submittedAt}
          submittedBy={statusStyle.submittedBy}
          autoGenerated={plan.autoGenerated}
          backgroundColor={statusStyle.backgroundColor}
          textColor={statusStyle.textColor}
          statusText={statusStyle.statusText}
          state={plan.state}
          lastUpdatedAt={statusStyle.lastUpdatedAt}
          updatedBy={plan.updatedBy || ''}
        />
      </Col>
    </Row>
  )
}
