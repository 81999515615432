import { useRouteMatch } from 'react-router-dom'
import { uniq } from 'lodash'
import { daysFromTimeZone } from 'utils/daysAndTimes'
import { useLoggedInUserOrRedirect } from 'queries/useLoggedInUser'
import { LocationSummaryFragment } from 'config/graphqlTypes'

export const useCurrentLocationId = (): string => {
  const {
    params: { location_id: locationId },
  } = useRouteMatch<{ location_id: string }>()
  return locationId
}

export const useTryCurrentLocation = ():
  | LocationSummaryFragment
  | undefined => {
  const id = useCurrentLocationId()
  const { locations } = useLoggedInUserOrRedirect()
  return locations.find((location: any) => location.id === id)
}

export const useCurrentLocation = () => {
  const location = useTryCurrentLocation()!
  const { organization } = useLoggedInUserOrRedirect()

  return {
    location,
    times: daysFromTimeZone(
      location.timeZone,
      location.fiscalWeekOffsetFromMonday ||
        organization.fiscalWeekOffsetFromMonday ||
        0
    ),
  }
}

interface LocationCategories {
  categories: { [key: string]: string[] }
  categoryNames: string[]
}

export const useCurrentLocationCategories = (): LocationCategories => {
  const { location } = useCurrentLocation()
  return {
    categories: location.categories.reduce((acc: any, curr: any) => {
      acc[curr.name] =
        curr.name in acc ? [...acc[curr.name], curr.key] : [curr.key]
      return acc
    }, {}),
    categoryNames: uniq(
      location.categories
        .filter((category: any) => !!category.name)
        .sort((a: any, b: any) => a.order - b.order)
        .map((category: any) => category.name)
    ),
  }
}
