import { useGetDailyLaborHoursBreakDownLazyQuery } from '../config/graphqlTypesRaw'

export const useGetDailyLaborHoursBreakDownLazyData = (
  locationId: string,
  day: string
) =>
  useGetDailyLaborHoursBreakDownLazyQuery({
    variables: {
      locationId,
      day,
    },
    fetchPolicy: 'cache-and-network',
  })
