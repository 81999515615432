import { DataProxy } from '@apollo/client'
import * as H from 'history'

import { CurrentUserInfoFragment, GetMeDocument } from 'config/graphqlTypes'
import { defaultLoggedInPath } from 'pages/Routes'

export const cacheUserAndLogin = (
  cache: DataProxy,
  user: CurrentUserInfoFragment,
  history: H.History<H.LocationState>
) => {
  cache.writeQuery({
    query: GetMeDocument,
    data: { me: user },
  })
  history.push(defaultLoggedInPath(user))
}
