import { useCurrentLocation } from 'queries/useCurrentLocation'
import { useGetInternalEventsQuery } from 'config/graphqlTypesRaw'
import { DateTime } from 'luxon'

export const useGetInternalEventDetails = (
  startAt: DateTime,
  endAt: DateTime
) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()

  const events =
    useGetInternalEventsQuery({
      variables: {
        startOn: startAt.toISODate(),
        endOn: endAt.toISODate(),
        locationId,
      },
    }).data?.internalEvents || []

  return events
}
