import React, { FC } from 'react'
import {
  DaysAvailableFragment,
  EmployeeAvailabilityFragment,
} from 'config/graphqlTypes'
import { AvailabilityScheduleEntry } from 'components/Management/styles'
import { useTranslation } from 'react-i18next'
import { sortTimeSlots } from '../../utils/timeSlots'

const dayHasAvailability = (dayAvail: DaysAvailableFragment | null) =>
  dayAvail &&
  dayAvail.timeSlots &&
  dayAvail.timeSlots.length > 0 &&
  !dayAvail.unavailable

interface Props {
  employeeAvailability: EmployeeAvailabilityFragment
}

export const AvailabilityDescriptionCell: FC<Props> = ({
  employeeAvailability: { daysAvailable, pendingDaysAvailable },
}) => {
  const schedule: {
    [key: string]: DaysAvailableFragment | null
  } = {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  }

  const days = pendingDaysAvailable ?? daysAvailable

  days?.forEach(day => {
    schedule[day.dayOfWeek] = day
  })

  const { t } = useTranslation()
  return (
    <>
      {Object.entries(schedule).map(([dayOfWeek, day]) => (
        <AvailabilityScheduleEntry key={dayOfWeek}>
          {t(`management.daysOfWeek.${dayOfWeek}`)}
          <div className={'ml-2'}>
            {dayHasAvailability(day) ? (
              sortTimeSlots(day!.timeSlots).map(shift => (
                <div key={shift.id}>
                  {shift.startTime} - {shift.endTime}
                </div>
              ))
            ) : (
              <div>{t('management.requests.unavailable')}</div>
            )}
          </div>
        </AvailabilityScheduleEntry>
      ))}
    </>
  )
}
