import { useGetOAuthQuery as getOAuth } from 'config/graphqlTypes'

export const useGetOAuth = (posType: string) => {
  return (
    getOAuth({
      variables: { posType },
      fetchPolicy: 'cache-and-network',
    }).data ?? null
  )
}
