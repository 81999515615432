import React from 'react'

import Page from 'pages/Page'
import { AddressRow } from 'pages/covid/dashboard/addressRow'
import { HeaderRow } from 'pages/covid/dashboard/HeaderRow'
import { NoLocationsAdded } from 'pages/covid/dashboard/NoLocationsAdded'
import { DashboardFooter } from 'pages/covid/dashboard/footer'
import {
  DashboardCenterCol,
  DashboardCenterRow,
  DashboardContainer,
} from 'pages/covid/dashboard/styles'
import { TopBarBlock } from 'pages/covid/TopbarBlock'
import { useDashboard } from 'pages/covid/useDashboard'
import { Loading } from 'components/Loading'

export const CovidDashboard = () => {
  const {
    dashboard,
    addAddress,
    removeAddress,
    saveNewDashboard,
  } = useDashboard()

  const dashboardSaved = !!dashboard?.id

  return (
    <Page>
      <TopBarBlock addAddress={addAddress} />
      <DashboardContainer fluid>
        <HeaderRow />
        {dashboard ? (
          dashboard.addresses.length > 0 ? (
            dashboard.addresses.map(address => (
              <AddressRow
                key={address.key}
                address={address}
                onRemoveAddress={removeAddress}
                dashboardSaved={dashboardSaved}
                saveNewDashboard={saveNewDashboard}
              />
            ))
          ) : (
            <NoLocationsAdded />
          )
        ) : (
          <DashboardCenterRow noGutters>
            <DashboardCenterCol>
              <Loading />
            </DashboardCenterCol>
          </DashboardCenterRow>
        )}
        <DashboardFooter
          dashboard={dashboard}
          saveNewDashboard={saveNewDashboard}
        />
      </DashboardContainer>
    </Page>
  )
}
