import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime, Interval } from 'luxon'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {
  StyledModal,
  DailyModalContainer,
  DailyModalInner,
  DailySidebar,
  DailyTimelineContainer,
  DailyBreakdownHeader,
  CloseButton,
  DailyViewModalHeaderText,
  DailyViewModalHeader,
  ForecastLabel,
} from 'pages/plan/dailyViewModal/styles'
import {
  useCurrentLocation,
  useCurrentLocationCategories,
} from 'queries/useCurrentLocation'
import { DaySelector } from 'pages/plan/dailyViewModal/DaySelector'
import {
  ShiftRoleFragment,
  GetLocationForScheduleQuery,
  ActiveLocationShiftRoleFragment,
  PlanFragment,
} from 'config/graphqlTypes'
import CloseIcon from 'assets/images/close-icon.svg'
import { useGetIntervalPredictionHourlySums } from 'queries/useGetIntervalPredictionSums'
import { Loading } from 'components/Loading'
import { DailyTimelineView } from 'pages/plan/dailyViewModal/DailyTimelineView'
import { useGetOperatingHoursOrDefaults } from 'pages/plan/dailyViewModal/useGetOperatingHoursOrDefaults'
import { getPredictionsForDay } from 'pages/plan/dailyViewModal/getPredictionsForDay'
import { Prediction, PredictionByCategory } from 'pages/plan/Prediction'
import { getDailySummary } from 'utils/getDailySummary'
import { DailyViewStats } from 'pages/plan/dailyViewModal/DailyViewStats'
import { usePlanPageContext } from '../planPageContext'
import { PredictionSubTitleWrapper } from '../rows/PredictionSubTitleWrapper'
import {
  ExpandChevronContainer,
  ExpandChevronLabel,
} from 'pages/plan/rows/styles'
import { StyledDownChevron, StyledUpChevron } from './styles'

interface Props {
  onHide: () => void
  day: Interval
  days: Interval[]
  shiftRoles: ShiftRoleFragment[]
  onClickPrev?: () => void
  onClickNext?: () => void
  location: GetLocationForScheduleQuery['location']
  disableEditPrediction: boolean
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export const DailyViewModal: FC<Props> = ({
  onHide,
  day,
  days,
  shiftRoles,
  onClickPrev,
  onClickNext,
  location,
  disableEditPrediction,
  activeLocationShiftRoles,
}) => {
  const { t } = useTranslation()
  const { plan } = usePlanPageContext()
  const { categories, categoryNames } = useCurrentLocationCategories()
  const [expandCategories, setExpandCategories] = useState(false)
  const predictionSums = useGetIntervalPredictionHourlySums(day.start, day.end)

  if (predictionSums.error) {
    throw predictionSums.error
  }

  if (!predictionSums.loading && !predictionSums.data) {
    throw new Error('No data returned from getIntervalPredictionSums!')
  }
  const dateMinusDays = (date: DateTime, days: number): DateTime => {
    return date.minus({ days })
  }
  const fiscalWeekOffsetFromMonday = location.fiscalWeekOffsetFromMonday ?? 0
  const { openOrDefault, closeOrDefault } = useGetOperatingHoursOrDefaults(day)
  const salesType = useCurrentLocation().location.salesType
  const predictionsForDay = !predictionSums.data
    ? []
    : getPredictionsForDay(
        predictionSums.data!.intervalPredictionSums,
        openOrDefault,
        closeOrDefault,
        salesType
      )
  const dailySummary = getDailySummary(day, plan, fiscalWeekOffsetFromMonday)
  const dayLastWeek = dateMinusDays(day.start, 7)
  const dayLastMonth = dateMinusDays(day.start, 28)
  const dayLastYear = dateMinusDays(day.start, 364)

  type DayReportedSales = PlanFragment[
    | 'lastWeekSameDayReportedSales'
    | 'lastMonthSameDayReportedSales'
    | 'lastYearSameDayReportedSales']
  const getReportedSales = (
    dayReportedSales: DayReportedSales,
    day: DateTime
  ) => {
    return (
      dayReportedSales.find(d => {
        return d.date.equals(day)
      })?.reportedSales || 0
    )
  }
  const lastWeekSameDayReportedSales = getReportedSales(
    plan.lastWeekSameDayReportedSales,
    dayLastWeek
  )
  const lastMonthSameDayReportedSales = getReportedSales(
    plan.lastMonthSameDayReportedSales,
    dayLastMonth
  )
  const lastYearSameDayReportedSales = getReportedSales(
    plan.lastYearSameDayReportedSales,
    dayLastYear
  )

  return (
    <StyledModal
      show
      onHide={onHide}
      centered
      dialogClassName="daily-view-modal"
      data-testid="daily-view-modal"
    >
      <DailyViewModalHeader>
        <DailyViewModalHeaderText>
          {t('planning.dailyViewModal.header')}
        </DailyViewModalHeaderText>
        <CloseButton data-testid="daily-close-button" onClick={onHide}>
          <img src={CloseIcon} alt="close daily view" />
        </CloseButton>
      </DailyViewModalHeader>
      <DailyModalContainer>
        <DailyModalInner>
          <DailySidebar>
            <DailyBreakdownHeader>
              {t('planning.dailyBreakdown')}
            </DailyBreakdownHeader>
            <DaySelector
              day={day}
              onClickPrev={onClickPrev}
              onClickNext={onClickNext}
            />
            <Prediction
              salesAndLaborSummary={dailySummary}
              isDailyView
              disableEdit={disableEditPrediction}
            />
            <ForecastLabel>{t('planning.forecasts')}</ForecastLabel>
            <Row>
              <Col md={6}>
                <PredictionSubTitleWrapper
                  categories={categoryNames}
                  expand={expandCategories}
                  fontSize={'16px'}
                ></PredictionSubTitleWrapper>
                {categoryNames.length > 3 && (
                  <ExpandChevronContainer
                    onClick={() => setExpandCategories(!expandCategories)}
                  >
                    <ExpandChevronLabel>
                      {t('planning.viewall')}
                    </ExpandChevronLabel>
                    {expandCategories ? (
                      <StyledUpChevron />
                    ) : (
                      <StyledDownChevron />
                    )}
                  </ExpandChevronContainer>
                )}
              </Col>
              <Col md={6}>
                <PredictionByCategory
                  salesSummary={dailySummary}
                  categories={categoryNames.map(
                    (category: string) => categories[category]
                  )}
                  expand={expandCategories}
                ></PredictionByCategory>
              </Col>
            </Row>
            <DailyViewStats
              day={day}
              lastWeekSameDayBenchmark={lastWeekSameDayReportedSales}
              lastMonthSameDayBenchmark={lastMonthSameDayReportedSales}
              lastYearSameDayBenchmark={lastYearSameDayReportedSales}
            />
          </DailySidebar>
          <DailyTimelineContainer>
            {!predictionSums.data ? (
              <Loading />
            ) : (
              <DailyTimelineView
                day={day}
                days={days}
                location={location}
                shiftRoles={shiftRoles}
                predictionsForDay={predictionsForDay}
                activeLocationShiftRoles={activeLocationShiftRoles}
              />
            )}
          </DailyTimelineContainer>
        </DailyModalInner>
      </DailyModalContainer>
    </StyledModal>
  )
}
