import React, { FC } from 'react'
import { Form } from '../../../components/Form'
import {
  ExportScheduleViewToggle,
  ExportScheduleViewType,
} from './exportOptions/ExportScheduleViewToggle'
import { ExportScheduleDepartmentSelect } from './exportOptions/ExportScheduleDepartmentSelect'
import { ExportScheduleEmployeeSelect } from './exportOptions/ExportScheduleEmployeeSelect'
import { ExportScheduleRoleSelect } from './exportOptions/ExportScheduleRoleSelect'
import styled from 'styled-components'
import {
  ExportScheduleOrientationOption,
  ExportScheduleOrientationSelect,
} from './exportOptions/ExportScheduleOrientationSelect'
import {
  ExportSchedulePageSizeOption,
  ExportSchedulePageSizeSelect,
} from './exportOptions/ExportSchedulePageSizeSelect'
import Button from '../../../components/Button'
import { ExportScheduleOptionCheckboxes } from './exportOptions/ExportScheduleOptionCheckboxes'
import { useTranslation } from 'react-i18next'
import { SelectDropdownOption } from '../../../components/SelectDropdown'
import { SelectMultiOrSingleValueDropdown } from './exportOptions/ExportScheduleOptionsSelect'
import { CreateWeeklyPlanExportMutationInput } from 'config/graphqlTypesRaw'

interface Props {
  isScheduleByRole: boolean
  departments: SelectDropdownOption[]
  employees: SelectDropdownOption[]
  roles: SelectDropdownOption[]
  fileUrl: string | undefined
  isLoading: boolean
  onSelectView: (view: ExportScheduleViewType) => void
  onSelectDepartments: (departments: SelectMultiOrSingleValueDropdown) => void
  onSelectEmployees: (employees: SelectMultiOrSingleValueDropdown) => void
  onSelectRoles: (roles: SelectMultiOrSingleValueDropdown) => void
  onSelectOrientation: (orientation: ExportScheduleOrientationOption) => void
  onSelectPageSize: (pageSize: ExportSchedulePageSizeOption) => void
  onSelectTotalHoursCheckbox: (totalHours: boolean) => void
  onSelectRolesCheckbox: (roles: boolean) => void
  onSelectSalesDataCheckbox: (salesData: boolean) => void
  onSelectWeatherCheckbox: (weather: boolean) => void
  onSelectExternalEventsCheckbox: (events: boolean) => void
  onSelectInternalEventsCheckbox: (events: boolean) => void
  onSelectReservationsCheckbox: (reservations: boolean) => void
  onExportToPdf: () => void
  onExportToExcel: () => void
  defaultCreateWeeklyPlanExportInput: CreateWeeklyPlanExportMutationInput
}

export const ExportScheduleOptionsForm: FC<Props> = ({
  isScheduleByRole,
  departments,
  employees,
  roles,
  fileUrl,
  isLoading,
  onSelectView,
  onSelectDepartments,
  onSelectEmployees,
  onSelectRoles,
  onSelectOrientation,
  onSelectPageSize,
  onSelectTotalHoursCheckbox,
  onSelectRolesCheckbox,
  onSelectSalesDataCheckbox,
  onExportToPdf,
  onExportToExcel,
  onSelectWeatherCheckbox,
  onSelectExternalEventsCheckbox,
  onSelectInternalEventsCheckbox,
  onSelectReservationsCheckbox,
  defaultCreateWeeklyPlanExportInput,
}) => {
  const { t } = useTranslation()
  return (
    <Form>
      <ExportScheduleViewToggle
        isScheduleByRole={isScheduleByRole}
        onSelectView={onSelectView}
      />
      <ExportScheduleDepartmentSelect
        departments={departments}
        onSelectDepartments={onSelectDepartments}
      />
      <StyledRowContainer>
        <StyledColumn>
          <ExportScheduleEmployeeSelect
            employees={employees}
            onSelectEmployees={onSelectEmployees}
          />
        </StyledColumn>
        {!isScheduleByRole && (
          <StyledColumn>
            <ExportScheduleRoleSelect
              roles={roles}
              onSelectRoles={onSelectRoles}
            />
          </StyledColumn>
        )}
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledColumn>
          <ExportScheduleOrientationSelect
            onSelectOrientation={onSelectOrientation}
          />
        </StyledColumn>
        <StyledColumn>
          <ExportSchedulePageSizeSelect onSelectPageSize={onSelectPageSize} />
        </StyledColumn>
      </StyledRowContainer>
      <ExportScheduleOptionCheckboxes
        isScheduleByRole={isScheduleByRole}
        onSelectTotalHoursCheck={onSelectTotalHoursCheckbox}
        onSelectRolesCheck={onSelectRolesCheckbox}
        onSelectSalesDataCheck={onSelectSalesDataCheckbox}
        onSelectExternalEventsCheck={onSelectExternalEventsCheckbox}
        onSelectInternalEventsCheck={onSelectInternalEventsCheckbox}
        onSelectWeatherCheck={onSelectWeatherCheckbox}
        onSelectReservationsCheck={onSelectReservationsCheckbox}
        defaultCreateWeeklyPlanExportInput={defaultCreateWeeklyPlanExportInput}
      />

      <ExportToPdfButton
        onClick={onExportToPdf}
        block
        disabled={isLoading || !fileUrl}
        size="md"
        type="button"
        variant="blue"
      >
        {t('planning.exportScheduleModal.exportOptions.exportToPdf')}
      </ExportToPdfButton>
      <ExportToExcelButton
        onClick={onExportToExcel}
        block
        disabled={isLoading || !fileUrl}
        size="md"
        variant="light"
        type="button"
      >
        Export to Excel
      </ExportToExcelButton>
    </Form>
  )
}

const StyledRowContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  columnGap: '.8rem',
})

const StyledColumn = styled.div({
  flexGrow: 1,
})

const ExportToPdfButton = styled(Button)({
  marginTop: 24,
  marginBottom: 16,
  whiteSpace: 'nowrap',
})

const ExportToExcelButton = styled(Button)`
  color: var(--primary);
  outline: 1px solid var(--primary);
  background-color: var(--white);
  &:hover {
    background-color: var(--gray8);
  }
`
