import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeeForm, SubmitInput } from './EmployeeForm'
import { ManagementWrapper } from '../ManagementWrapper'
import { ManagementContainer, ManagementHeader } from '../styles'
import AuthenticatedPage from '../../AuthenticatedPage'
import { useCreateEmployeeMutation } from 'config/graphqlTypes'

export const AddEmployee = () => {
  const { t } = useTranslation()

  const [createEmployee] = useCreateEmployeeMutation()

  const onSubmit = async (submitInput: SubmitInput) => {
    const result = (
      await createEmployee({
        variables: {
          input: {
            ...submitInput,
          },
        },
      })
    ).data?.createEmployee

    return result?.errors || []
  }

  return (
    <AuthenticatedPage>
      <ManagementWrapper>
        <>
          <ManagementHeader>
            {t('settings.employeeSettings.employeeForm.addNew')}
          </ManagementHeader>

          <ManagementContainer>
            <EmployeeForm
              submitForm={onSubmit}
              submitText={t('settings.employeeSettings.employeeForm.add')}
            />
          </ManagementContainer>
        </>
      </ManagementWrapper>
    </AuthenticatedPage>
  )
}
