import React from 'react'

import {
  DailyTimelineRowWrapper,
  DailyTimelineRowLeft,
  DailyTimelineRowMiddle,
  DailyTimelineRowRight,
  DailyTimelineRowInnerWrapper,
} from 'pages/plan/dailyViewModal/styles'

interface Props {
  LeftSection?: JSX.Element
  MiddleSection?: JSX.Element
  RightSection?: JSX.Element
  noMiddlePadding?: boolean
  BottomSection?: JSX.Element
}

export const DailyTimelineRow = ({
  LeftSection,
  MiddleSection,
  RightSection,
  BottomSection,
  noMiddlePadding,
}: Props) => (
  <DailyTimelineRowWrapper>
    <DailyTimelineRowInnerWrapper>
      <DailyTimelineRowLeft data-testid="left">
        {LeftSection}
      </DailyTimelineRowLeft>
      <DailyTimelineRowMiddle
        noMiddlePadding={noMiddlePadding}
        data-testid="middle"
      >
        {MiddleSection}
      </DailyTimelineRowMiddle>
      {RightSection && (
        <DailyTimelineRowRight data-testid="right">
          {RightSection}
        </DailyTimelineRowRight>
      )}
    </DailyTimelineRowInnerWrapper>
    {BottomSection}
  </DailyTimelineRowWrapper>
)
