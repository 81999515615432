import { Serie } from '@nivo/line'

export const chartDataConfig = {
  actuals: {
    id: 'actuals',
    color: 'var(--blue)',
    stroke: 'var(--white)',
    testIdSlug: 'actual',
  },
  futureActuals: {
    id: 'futureActuals',
    color: 'var(--white)',
    stroke: 'var(--blue)',
    testIdSlug: 'future-actual',
  },
  plannedLabor: {
    id: 'plannedLabor',
    color: 'var(--iris-blue)',
    stroke: 'var(--white)',
    testIdSlug: 'planned-labor',
  },
  predictions: {
    id: 'predictions',
    color: 'var(--blue-light)',
    stroke: 'var(--white)',
    testIdSlug: 'prediction',
  },
} as const

type SeriesId = keyof typeof chartDataConfig

const validateId = (id: string | number) => {
  if (!Object.keys(chartDataConfig).includes(id.toString())) {
    throw new Error(`Missing chart data id in ChartDataConfig: ${id}`)
  }

  return id as SeriesId
}

export const getSerieConfig = (id: string | number) => {
  const serieId = validateId(id)
  return chartDataConfig[serieId]
}

const getSerie = (id: SeriesId) => (series: Serie[]) => {
  const serieId = validateId(id)
  return series.find(s => s.id === serieId)
}

export const getActuals = getSerie(chartDataConfig.actuals.id)
export const getFutureActuals = getSerie(chartDataConfig.futureActuals.id)
export const getPlannedLabor = getSerie(chartDataConfig.plannedLabor.id)
export const getPredictions = getSerie(chartDataConfig.predictions.id)

const isSerie = (id: SeriesId) => (serieId: string | number) =>
  id === validateId(serieId.toString())

export const isActuals = isSerie(chartDataConfig.actuals.id)
export const isFutureActuals = isSerie(chartDataConfig.futureActuals.id)
export const isPlannedLabor = isSerie(chartDataConfig.plannedLabor.id)
export const isPredictions = isSerie(chartDataConfig.predictions.id)
