import React from 'react'
import styled from 'styled-components'

import { sectionBorder } from 'config/styles'

interface PillTabProps {
  isActive: boolean
  first: boolean
}

const PillTab = styled.button(({ isActive, first }: PillTabProps) => ({
  width: 60,
  fontSize: '0.75rem',
  lineHeight: 1.8,
  fontWeight: 800,
  color: isActive ? 'var(--gray1)' : 'var(--gray4)',
  backgroundColor: isActive ? 'var(--gray5)' : 'var(--gray7)',
  border: sectionBorder,
  borderRadius: first ? '18px 0 0 18px' : '0 18px 18px 0',
  '&:hover': {
    'background-color': isActive ? 'var(--gray5)' : 'var(--gray7)',
  },
  '&:focus': {
    outline: 'none',
  },
}))

interface PillProps {
  options: string[]
  activeIndex: number
  type?: 'button' | 'submit' | 'reset'
  onChange: (newActiveIndex: number) => void
}

export const Pill = ({
  options,
  activeIndex,
  onChange,
  ...props
}: PillProps) => (
  <>
    {options.map((option, index) => (
      <PillTab
        {...props}
        key={option}
        isActive={activeIndex === index}
        first={index === 0}
        onClick={() => {
          onChange(index)
        }}
      >
        {option}
      </PillTab>
    ))}
  </>
)
