import { Address } from 'pages/covid/types'
import { covidBackendFetch } from 'pages/covid/dashboard/addressRow/covidBackendFetch'
import { notify } from 'config/errorReporting'

interface PlaceKeyFetchResponse {
  placekey: string | null
}

const placekeyFromGeoData = async ({
  latitude,
  longitude,
  streetAddress,
  city,
  region,
  postalCode,
  isoCountryCode,
}: Address) => {
  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Content-Type', 'application/json')
  requestHeaders.set(
    'apikey',
    process.env.REACT_APP_PLACE_KEY_API_KEY as string
  )

  // For best query results, pass in the Google-provided GPS coordinates *and*
  // the address components.
  const placeKeyResponse = await window.fetch(
    'https://api.placekey.io/v1/placekey',
    {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify({
        query: {
          latitude,
          longitude,
          street_address: streetAddress,
          city,
          region,
          postal_code: postalCode,
          iso_country_code: isoCountryCode,
        },
      }),
    }
  )

  if (!placeKeyResponse.ok) {
    const responseText = await placeKeyResponse.text()
    throw new Error(`Failed response from placekey API: ${responseText}`)
  }

  const { placekey } = (await placeKeyResponse.json()) as PlaceKeyFetchResponse

  return placekey
}

interface CBGFromPlacekeyFetchResponse {
  cbg: string | null
}

const cbgFromPlacekey = async (
  placekey: string,
  { streetAddress, city, region, latitude, longitude }: Address
) => {
  const path = `cbg_from_placekey?place_key=${placekey}&address=${streetAddress}&city=${city}&state=${region}&lat=${latitude}&long=${longitude}`
  const { cbg } = (await covidBackendFetch(
    path
  )) as CBGFromPlacekeyFetchResponse

  return cbg
}

export const getCBG = async (address: Address) => {
  const placekey = await placekeyFromGeoData(address)

  if (!placekey) {
    notify(`Unable to find placekey for address: ${JSON.stringify(address)}`)

    return null
  }

  const cbg = await cbgFromPlacekey(placekey, address)

  if (!cbg) {
    notify(
      `Unable to find cbg for placekey: ${placekey} for address: ${JSON.stringify(
        address
      )}`
    )

    return null
  }

  return cbg
}
