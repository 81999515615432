import React from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { H3Text } from 'components/Text'

const StyledWeekHeader = styled(H3Text)({
  color: 'var(--gray2)',
  paddingRight: 10,
  lineHeight: 'inherit',
  fontSize: 32,
})

interface Props {
  startDate: DateTime
  labelTranslationKey: string
}

export const WeekHeader = ({ startDate, labelTranslationKey }: Props) => {
  const { t } = useTranslation()
  const label = t(labelTranslationKey, { day: startDate.toFormat('L/d') })

  return <StyledWeekHeader>{label}</StyledWeekHeader>
}
