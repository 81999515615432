import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Alert } from 'components/AppAlert'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  largeBodyBoldTextStyle,
  largeBodyTextStyle,
  h3Style,
  mediumBodyTextStyle,
} from 'config/styles'
import { useDismissActiveLearningNotice } from 'components/useActiveLearningNotice'

const ActiveLearningHeading = styled.span({
  ...largeBodyBoldTextStyle,
  marginRight: 5,
  color: 'var(--teal-dark)',
})

const ActiveLearningDetail = styled.span({
  ...largeBodyTextStyle,
  marginRight: 5,
  color: 'var(--teal-dark)',
})

const LearnMore = styled.a({
  ...largeBodyBoldTextStyle,
  textDecoration: 'underline',
  color: 'var(--teal-dark)',
})

const ActiveLearningModalHeader = styled(Modal.Header)({
  ...h3Style,
  color: 'var(--gray2)',
  borderBottom: 0,
  justifyContent: 'space-between',
  '&::before': {
    content: '""',
    width: 45,
  },
  '& .close': {
    marginLeft: '0px !important',
  },
})

const ActiveLearningModalBody = styled(Modal.Body)({
  ...mediumBodyTextStyle,
  color: 'var(--gray2)',
  whiteSpace: 'pre-line',
  textAlign: 'center',
  marginBottom: 30,
})

export const ActiveLearningNotice = () => {
  const { t } = useTranslation()

  const [showAlert, setShowAlert] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const [dismissActiveLearningNotice] = useDismissActiveLearningNotice()

  const hideAlert = () => {
    setShowModal(false)
    setShowAlert(false)
    dismissActiveLearningNotice()
  }

  return (
    <>
      {showAlert && (
        <Alert
          data-testid="active-learning-alert"
          variant="info"
          onClose={hideAlert}
          dismissible
        >
          <ActiveLearningHeading>
            {t('activeLearning.alertHeading')}
          </ActiveLearningHeading>
          <ActiveLearningDetail>
            {t('activeLearning.alertDetail')}
          </ActiveLearningDetail>
          <LearnMore onClick={() => setShowModal(true)}>
            {t('activeLearning.learnMore')}
          </LearnMore>
        </Alert>
      )}

      <Modal
        data-testid="active-learning-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <ActiveLearningModalHeader closeButton>
          <span>{t('activeLearning.modalTitle')}</span>
        </ActiveLearningModalHeader>
        <ActiveLearningModalBody>
          {t('activeLearning.modalDetail')}
        </ActiveLearningModalBody>
      </Modal>
    </>
  )
}
