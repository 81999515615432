import React, { CSSProperties, forwardRef, ReactNode } from 'react'
import BootstrapForm, { FormProps } from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import styled from 'styled-components'

import {
  baseInputStyle,
  baseLabelStyle,
  baseFeedbackStyle,
  baseSelectStyle,
  baseHelperTextStyle,
  checkboxLabelStyle,
} from 'config/styles'

interface Props extends React.ComponentProps<typeof BootstrapForm> {
  error?: string
  successMsg?: string
}

export const Form = ({ children, error, successMsg, ...formProps }: Props) => (
  <BootstrapForm {...(formProps as FormProps)}>
    {!!error && <Alert variant="danger">{error}</Alert>}
    {!!successMsg && <Alert variant="success">{successMsg}</Alert>}
    {children}
  </BootstrapForm>
)

export const BaseStyledLabel = styled.label(baseLabelStyle)
export const BaseStyledCheckLabel = styled(BootstrapForm.Check.Label)(
  checkboxLabelStyle
)
export const BaseStyledFeedback = styled.label(baseFeedbackStyle)
export const BaseStyledInput = styled.input(baseInputStyle)
export const BaseStyledSelectInput = styled.select(baseSelectStyle)
export const BaseHelperText = styled(BootstrapForm.Text)(baseHelperTextStyle)

interface LabelProps {
  label: string
  labelProps?: React.ComponentProps<typeof BootstrapForm.Label>
}

export const Label = ({ label, labelProps }: LabelProps) => (
  <BootstrapForm.Label as={BaseStyledLabel} {...labelProps}>
    {label}
  </BootstrapForm.Label>
)

interface ErrorFeedbackProps {
  error: string | ReactNode
  testId?: string
  feedbackProps?: React.ComponentProps<typeof BootstrapForm.Control.Feedback>
}

export const ErrorFeedback = ({
  error,
  testId,
  feedbackProps,
}: ErrorFeedbackProps) => (
  <BootstrapForm.Control.Feedback
    as={BaseStyledFeedback}
    data-testid={`${testId} input-error`}
    type="invalid"
    {...feedbackProps}
  >
    {error}
  </BootstrapForm.Control.Feedback>
)

// BootstrapForm.Control components cannot be styled like
// `styled(StringInput)` but instead require custom `as` components to be
// passed in. Eg <StringInput controlProps={{ as: MyStyledInput }} />
// See https://github.com/react-bootstrap/react-bootstrap/issues/5403

interface InputProps {
  testId?: string
  controlProps?: React.ComponentProps<typeof BootstrapForm.Control>
}

export const StringInput = forwardRef(
  ({ testId, controlProps }: InputProps, ref) => (
    <BootstrapForm.Control
      ref={ref}
      as={BaseStyledInput}
      data-testid={`${testId} input`}
      {...(controlProps || {})}
    />
  )
)

export const SelectInput = forwardRef(
  ({ testId, controlProps }: InputProps, ref) => (
    <BootstrapForm.Control
      ref={ref}
      as={BaseStyledSelectInput}
      data-testid={`${testId} input`}
      disabled={controlProps?.disabled}
      {...(controlProps || {})}
    />
  )
)

interface CheckInputProps {
  testId?: string
  inputProps?: React.ComponentProps<typeof BootstrapForm.Check.Input>
}

export const CheckInput = forwardRef(
  ({ testId, inputProps }: CheckInputProps, ref) => (
    <BootstrapForm.Check.Input
      ref={ref}
      data-testid={testId}
      type="checkbox"
      {...inputProps}
    />
  )
)

interface CheckProps {
  id: string
  label: string
  style?: any
  styleContainer?: any
  inputProps?: React.ComponentProps<typeof BootstrapForm.Check.Input>
}

export const Check = ({
  id,
  label,
  style,
  styleContainer,
  inputProps,
}: CheckProps) => (
  <BootstrapForm.Check inline style={{ ...styleContainer }} id={id}>
    <CheckInput testId={label} inputProps={inputProps} />
    <BaseStyledCheckLabel style={{ ...style }}>{label}</BaseStyledCheckLabel>
  </BootstrapForm.Check>
)

interface FormGroupProps {
  children: JSX.Element
  labelProps?: LabelProps
  errorFeedbackProps?: ErrorFeedbackProps
  controlId?: string
  testId: string
  helperText?: string
  containerStyle?: CSSProperties
}

export const FormGroup = ({
  testId,
  controlId,
  labelProps,
  children,
  errorFeedbackProps,
  helperText,
  containerStyle,
}: FormGroupProps) => {
  return (
    <BootstrapForm.Group controlId={controlId} style={containerStyle}>
      {!!labelProps && <Label {...labelProps} />}
      {children}
      {!!errorFeedbackProps && (
        <ErrorFeedback testId={testId} {...errorFeedbackProps} />
      )}
      {helperText && <BaseHelperText>{helperText}</BaseHelperText>}
    </BootstrapForm.Group>
  )
}

interface InputGroupProps {
  labelProps?: LabelProps
  errorFeedbackProps?: ErrorFeedbackProps
  controlId?: string
  testId?: string
  controlProps?: React.ComponentProps<typeof BootstrapForm.Control>
  helperText?: string
  containerStyle?: CSSProperties
  children?: ReactNode
}

export const StringInputGroup = ({
  testId,
  controlId,
  controlProps,
  labelProps,
  errorFeedbackProps,
  helperText,
  containerStyle,
  children,
}: InputGroupProps) => {
  const tId = `${testId || labelProps?.label || controlProps?.placeholder}`

  return (
    <FormGroup
      testId={tId}
      controlId={controlId}
      labelProps={labelProps}
      errorFeedbackProps={errorFeedbackProps}
      helperText={helperText}
      containerStyle={containerStyle}
    >
      {children ? (
        <StringInputContainer>
          <StringInput controlProps={controlProps} testId={tId} />
          {children}
        </StringInputContainer>
      ) : (
        <StringInput controlProps={controlProps} testId={tId} />
      )}
    </FormGroup>
  )
}

export const SelectInputGroup = ({
  testId,
  controlId,
  controlProps,
  labelProps,
  errorFeedbackProps,
  helperText,
}: InputGroupProps) => {
  const tId = `${testId || labelProps?.label || controlProps?.placeholder}`

  return (
    <FormGroup
      testId={tId}
      controlId={controlId}
      labelProps={labelProps}
      errorFeedbackProps={errorFeedbackProps}
      helperText={helperText}
    >
      <SelectInput controlProps={controlProps} testId={tId} />
    </FormGroup>
  )
}

const StringInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`
