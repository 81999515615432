import { Interval } from 'luxon'
import { sortBy } from 'lodash/fp'

import { ShiftFragment } from 'config/graphqlTypes'

export const getShiftsPerDay = ({
  days,
  shifts,
}: {
  days: Interval[]
  shifts: ShiftFragment[]
}): ShiftFragment[][] =>
  days.map(day =>
    sortBy(
      'startAt',
      shifts.filter(shift => day.start.hasSame(shift.startAt, 'day'))
    )
  )
