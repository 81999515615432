import { useCurrentLocationId } from 'queries/useCurrentLocation'
import {
  useGetLocationShiftRolesQuery,
  useGetLocationShiftRolesActiveQuery,
} from 'config/graphqlTypes'

export type Role = { id: string; name: string }
export type Department = { id: string; name: string; roles: string[] }

export type LocationShiftRoles = {
  roles: { [id: string]: Role }
  departments: { [id: string]: Department }
}

export const useCurrentLocationShiftRoles: () => LocationShiftRoles = () => {
  const locationId = useCurrentLocationId()
  const { data } = useGetLocationShiftRolesQuery({
    variables: { locationId },
  })
  const { data: activeShiftRoles } = useGetLocationShiftRolesActiveQuery({
    variables: { locationId },
  })

  const activeShiftRoleIds = (activeShiftRoles?.locationShiftRoles ?? [])
    .filter(role => role.active)
    .map(role => role.shiftRoleId)

  const shiftRoleCategories =
    data?.location.organization.shiftRoleCategories ?? []
  const shiftRoles = data?.location.shiftRoles ?? []

  const roles = shiftRoles.reduce((acc, role) => {
    if (!activeShiftRoleIds.includes(role.id)) return acc
    return { ...acc, [role.id]: { id: role.id, name: role.name } }
  }, {})

  return {
    roles,
    departments: shiftRoleCategories.reduce(
      (acc, department) => ({
        ...acc,
        [department.id]: {
          id: department.id,
          name: department.name,
          roles: department.shiftRoles
            .map(role => role.id)
            .filter(id => id in roles),
        },
      }),
      {}
    ),
  }
}
