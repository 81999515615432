import {
  ActiveLocationShiftRoleFragment,
  ShiftFragment,
  ShiftRoleCategoryFragment,
  ShiftRoleFragment,
} from 'config/graphqlTypes'
import { Interval } from 'luxon'
import { CollapsableCategoryName } from 'pages/plan/CollapsableCategoryName'
import { TimelineHelpers } from 'pages/plan/dailyViewModal/schedule/getGraphTimelineHelpers'
import { ShiftRoleSchedule } from 'pages/plan/dailyViewModal/schedule/ShiftRoleSchedule'
import { DailyTimelineCategoryRow } from 'pages/plan/dailyViewModal/styles'
import React, { FC, useState } from 'react'

interface Props {
  shifts: ShiftFragment[]
  shiftRoles: ShiftRoleFragment[]
  shiftRoleCategory: ShiftRoleCategoryFragment
  VerticalLines: JSX.Element
  timelineHelpers: TimelineHelpers
  isEditable: boolean
  day: Interval
  days: Interval[]
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export const DailyViewCollapsableCategory: FC<Props> = ({
  day,
  days,
  shifts,
  shiftRoles,
  shiftRoleCategory,
  VerticalLines,
  timelineHelpers,
  isEditable,
  activeLocationShiftRoles,
}) => {
  const [collapsed, setCollapsed] = useState(false)
  const toggleCollapsed = () => setCollapsed(!collapsed)

  return (
    <>
      <DailyTimelineCategoryRow onClick={toggleCollapsed}>
        <CollapsableCategoryName
          name={shiftRoleCategory.name}
          collapsed={collapsed}
        />
      </DailyTimelineCategoryRow>

      {collapsed ||
        shiftRoles.map(shiftRole => (
          <ShiftRoleSchedule
            key={shiftRole.id}
            day={day}
            days={days}
            shiftRole={shiftRole}
            shifts={shifts}
            VerticalLines={VerticalLines}
            timelineHelpers={timelineHelpers}
            isEditable={isEditable}
            activeLocationShiftRoles={activeLocationShiftRoles}
          />
        ))}
    </>
  )
}
