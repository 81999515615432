import React, { useState } from 'react'
import { Interval } from 'luxon'
import { DailyViewAddShiftButton } from 'pages/plan/dailyViewModal/DailyViewAddShiftButton'
import { DailyViewChart } from 'pages/plan/dailyViewModal/DailyViewChart'
import {
  ShiftRoleFragment,
  IntervalPredictionSumFragment,
  GetLocationForScheduleQuery,
  MinimumLaborBaselineFragment,
  ActiveLocationShiftRoleFragment,
} from 'config/graphqlTypes'
import { DailyTimelineRow } from 'pages/plan/dailyViewModal/DailyTimelineRow'
import { getDailySummary } from 'utils/getDailySummary'
import {
  HorizontalChartMargin,
  TimelineControls,
  TopChartLine,
} from 'pages/plan/dailyViewModal/styles'
import { Schedule } from 'pages/plan/dailyViewModal/schedule'
import { DailyViewLegend } from 'pages/plan/dailyViewModal/DailyViewLegend'
import { useShiftModal } from 'pages/plan/scheduling/useShiftModal'
import { ShiftModal } from 'pages/plan/scheduling/ShiftModal'
import { NoShiftsDefaultScreen } from 'pages/plan/dailyViewModal/NoShiftsDefaultScreen'
import { useUnderMinimumLaborBaseline } from 'pages/plan/dailyViewModal/useUnderMinimumLaborBaseline'
import { usePlanPageContext } from '../planPageContext'
import { ChartLegend } from 'components/Chart/ChartLegend'
import DailyTimelineViewByToggle, {
  DailyViewToggleByType,
} from './DailyTimelineViewByToggle'

interface Props {
  day: Interval
  days: Interval[]
  shiftRoles: ShiftRoleFragment[]
  predictionsForDay: IntervalPredictionSumFragment[]
  location: GetLocationForScheduleQuery['location']
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export const DailyTimelineView = ({
  day,
  days,
  predictionsForDay,
  shiftRoles,
  location,
  activeLocationShiftRoles,
}: Props) => {
  const { plan } = usePlanPageContext()
  const { shifts } = plan
  const [timelineWidth, setTimelineWidth] = useState(0)
  const [viewBy, setViewBy] = useState<DailyViewToggleByType>('all')
  const onChangeChartWidth = (width: number) => {
    setTimelineWidth(width - 2 * HorizontalChartMargin)
  }
  const shiftsForDay = shifts.filter(
    s => day.contains(s.startAt) && !s.isUnavailability
  )

  const fiscalWeekOffsetFromMonday = location.fiscalWeekOffsetFromMonday ?? 0

  const dailySalesAndLaborSummary = getDailySummary(
    day,
    plan,
    fiscalWeekOffsetFromMonday
  )

  const { showShiftModal, onClickAddShift, onHideShiftModal } = useShiftModal()
  const dayName = day.start.weekdayLong.toLowerCase()
  const { minimumLaborBaselines } = location

  const minimumLaborBaseline: MinimumLaborBaselineFragment[] =
    minimumLaborBaselines?.filter(({ dayOfWeek }) => dayOfWeek === dayName) ??
    []
  const isUnderMinimumLaborBaseline = useUnderMinimumLaborBaseline(
    day,
    shiftsForDay,
    minimumLaborBaseline
  )

  return (
    <>
      <ChartLegend />
      <DailyTimelineRow
        noMiddlePadding
        LeftSection={
          <DailyViewLegend
            chartHasShifts={shiftsForDay.length > 0}
            isUnderMinimumLaborBaseline={isUnderMinimumLaborBaseline}
          />
        }
        MiddleSection={
          <DailyViewChart
            day={day}
            shiftsForDay={shiftsForDay}
            predictionsForDay={predictionsForDay}
            dailySalesAndLaborSummary={dailySalesAndLaborSummary}
            onChangeWidth={onChangeChartWidth}
            minimumLaborBaseline={minimumLaborBaseline}
          />
        }
        BottomSection={<TopChartLine />}
      />
      <TimelineControls>
        <DailyViewAddShiftButton onClickAddShift={onClickAddShift} />
        <DailyTimelineViewByToggle value={viewBy} onToggle={setViewBy} />
      </TimelineControls>
      {shiftsForDay.length > 0 ? (
        <Schedule
          day={day}
          days={days}
          groupByRoles={viewBy === 'role'}
          isEditable={true}
          predictionsForDay={predictionsForDay}
          shiftRoles={shiftRoles}
          shifts={shiftsForDay}
          dailySalesAndLaborSummary={dailySalesAndLaborSummary}
          timelineWidth={timelineWidth}
          activeLocationShiftRoles={activeLocationShiftRoles}
        />
      ) : (
        <DailyTimelineRow
          MiddleSection={
            <NoShiftsDefaultScreen onClickAddShift={onClickAddShift} />
          }
        />
      )}
      {showShiftModal && (
        <ShiftModal
          show={showShiftModal}
          onHide={onHideShiftModal}
          selectedDate={day.start}
          days={days}
          hideDateSelect
          activeLocationShiftRoles={activeLocationShiftRoles}
        />
      )}
    </>
  )
}
