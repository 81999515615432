import { DateTime } from 'luxon'
import { sumBy, uniqBy } from 'lodash'

import {
  IntervalOrderSumFragment,
  IntervalPredictionSumFragment,
} from 'config/graphqlTypes'

export interface SeriesPoint {
  time: DateTime
  value: number
}

export const buildSeries = (
  orderSums: IntervalOrderSumFragment[],
  predictionSums: IntervalPredictionSumFragment[],
  isWeekView: boolean,
  salesType: string,
  now: DateTime
) => {
  const seriesTimes = uniqBy(
    // Use endAt so that orders from 10:00-11:00am show up at the 11am dot
    orderSums.map(o => o.endAt),
    time => time.toISO()
  )

  const predicted = seriesTimes.map(time => ({
    time,
    value: sumBy(
      predictionSums.filter(s => s.endAt.equals(time)),
      s => s.reportedSales || 0
    ),
  }))

  // For the hourly view, there is an additional real-time dot that is
  // displayed between the last hour and the next hour. Therefore, append `now`
  //
  // For the week view, there is no additional real time dot. However, the dot
  // for today should show real-time data and even show the current time in
  // the tooltip. For example, if it is 11:05am, show the order total as of 11:05am
  // and even show 11:05am in the tooltip. To do that, make today's `time` be
  // `now` instead of the end of the day, which it is for the other daily points.
  const orderTimes = isWeekView
    ? seriesTimes.map(t => (t > now && t.startOf('day') < now ? now : t))
    : seriesTimes.concat(now).sort()

  const fulfilled = [] as SeriesPoint[]
  const unfulfilled = [] as SeriesPoint[]

  orderTimes.forEach(time => {
    const orderSumsForTime = orderSums.filter(s => s.endAt.equals(time))

    if (time.equals(now)) {
      const currentOrderSums = orderSums.filter(
        s => s.startAt <= now && s.endAt >= now
      )
      fulfilled.push({
        time,
        value: sumBy(currentOrderSums, s => s.reportedSales || 0),
      })
    } else if (time < (isWeekView ? now.plus({ days: 1 }) : now)) {
      fulfilled.push({
        time,
        value: sumBy(orderSumsForTime, s => s.reportedSales || 0),
      })
    } else {
      unfulfilled.push({
        time,
        value: sumBy(orderSumsForTime, s => s.futureReportedSales || 0),
      })
    }
  })

  return { fulfilled, unfulfilled, predicted }
}
