import React from 'react'
import { Interval } from 'luxon'
import { useTranslation } from 'react-i18next'
import { filter } from 'lodash'

import { ViolationFragment } from 'config/graphqlTypes'
import {
  FirstLaborPercentageCell,
  FirstTotalCell,
  FirstCellsContainer,
  StyledFirstFooterCell,
  StyledFooterCell,
  StyledDailyBreakdownDateCell,
  StyledDailyBreakdown,
} from 'pages/plan/scheduling/styles'
import { SpanDivider, UnprintablePlanningTableRow } from 'pages/plan/styles'
import { TotalCell } from 'pages/plan/laborAllowanceRow/TotalCell'
import { LaborPercentageDayCell } from 'pages/plan/laborPercentageRow/LaborPercentageDayCell'
import { getShiftsPerDay } from 'utils/getShiftsPerDay'
import { DailyBreakdownCell } from '../dailyBreakdownRow/DailyBreakdownCell'
import { LaborPercentageWeekCell } from '../laborPercentageRow/LaborPercentageWeekCell'
import { usePlanPageContext } from '../planPageContext'

interface Props {
  violations: ViolationFragment[]
  onClickCell: (cellIndex: number) => void
  days: Interval[]
  locationId: string
}

export const FooterRow = ({
  violations,
  onClickCell,
  days,
  locationId,
}: Props) => {
  const { t } = useTranslation()
  const {
    plan,
    laborDollars,
    overlapWithLocationClosedDays,
  } = usePlanPageContext()
  const overHoursWeekViolations = filter(violations, {
    name: 'overHoursWeek',
  }) as ViolationFragment[]

  const { dailySalesAndLaborSummaries, weekSalesAndLaborSummary, shifts } = plan
  const laborDollarsDaily = laborDollars?.daily || {}
  const laborDollarsTotal = laborDollars?.total || 0

  const shiftsPerDay = getShiftsPerDay({ days, shifts })

  return (
    <UnprintablePlanningTableRow>
      <StyledFirstFooterCell>
        <FirstCellsContainer>
          <FirstTotalCell>{t('planning.laborAllowance')}</FirstTotalCell>

          <FirstLaborPercentageCell>
            {t('planning.laborPercentage')}
            <SpanDivider>{'|'}</SpanDivider>
            {t('planning.laborCost')}
          </FirstLaborPercentageCell>
        </FirstCellsContainer>
      </StyledFirstFooterCell>
      {dailySalesAndLaborSummaries.map((dailySalesAndLaborSummary, i) => (
        <StyledFooterCell key={dailySalesAndLaborSummary.id}>
          <DailyBreakdownCell
            date={days[i].start}
            disabled={overlapWithLocationClosedDays(days[i])}
            onClick={() => onClickCell(i)}
          />

          <TotalCell
            testId={`labor-allowance-cell-${i}`}
            disabled={overlapWithLocationClosedDays(days[i])}
            salesAndLaborSummary={dailySalesAndLaborSummary}
            onClick={() => onClickCell(i)}
          />

          <LaborPercentageDayCell
            id={dailySalesAndLaborSummary.id}
            testId={`labor-percentage-cell-${i}`}
            shifts={shiftsPerDay[i]}
            overHoursWeekViolations={overHoursWeekViolations}
            dailyPredictedSales={dailySalesAndLaborSummary.plannedSales}
            laborDollars={
              laborDollarsDaily[days[i].start.toISODate().toString()] || 0
            }
            locationId={locationId}
          />
        </StyledFooterCell>
      ))}
      <StyledFooterCell>
        <StyledDailyBreakdownDateCell>
          <StyledDailyBreakdown />
        </StyledDailyBreakdownDateCell>
        <TotalCell
          testId={`labor-allowance-cell-total`}
          isLastCell={true}
          salesAndLaborSummary={weekSalesAndLaborSummary}
        />

        <LaborPercentageWeekCell
          testId={`labor-percentage-cell-total`}
          weeklyTotalDollarsScheduled={laborDollarsTotal}
          weeklyPredictedSales={weekSalesAndLaborSummary.plannedSales}
        />
      </StyledFooterCell>
    </UnprintablePlanningTableRow>
  )
}
