import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { TFunction } from 'i18next'

import { useCurrentLocation } from 'queries/useCurrentLocation'
import {
  useGetWeatherCurrentQuery,
  useGetWeatherDailyQuery,
} from 'config/graphqlTypes'
import { weatherConditionIcon } from 'components/Weather/weatherConditionIcon'

export const useWeatherDaily = (startDate: DateTime, endDate: DateTime) => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()
  const { t } = useTranslation()

  const weatherArray = useGetWeatherDailyQuery({
    variables: {
      locationId: locationId!,
      startOn: startDate,
      endOn: endDate,
    },
  })?.data?.weatherDaily

  return weatherArray ? weatherArray.map(weatherDisplayCalc(t)) : []
}

export const useWeatherCurrent = () => {
  const {
    location: { id: locationId },
  } = useCurrentLocation()
  const { t } = useTranslation()

  const weatherCurrent = useGetWeatherCurrentQuery({
    variables: {
      locationId,
    },
  })?.data?.weatherCurrent

  return weatherDisplayCalc(t)(weatherCurrent)
}

interface WeatherInput {
  conditionCode: number
  conditionName: string
  temperatureFahrenheit: number
  iconCode: string
  date?: DateTime
}

export type WeatherDisplayProps = {
  code: number
  name: string
  icon: string
  summary: string
  temperature: string | number
  date?: DateTime
}

const weatherDisplayCalc = (t: TFunction) => (
  weather?: WeatherInput | null
): WeatherDisplayProps | null => {
  if (!weather) {
    return null
  }

  const {
    conditionCode: code,
    conditionName: name,
    temperatureFahrenheit,
    date,
    iconCode,
  } = weather

  const summary = name
  const temperature = Math.round(temperatureFahrenheit)

  return {
    code,
    icon: weatherConditionIcon(iconCode),
    name,
    summary,
    temperature,
    date,
  }
}
