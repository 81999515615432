import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import heroBackground from 'assets/images/crc-hero-dimmed.jpg'
import { LargeHeaderText } from 'components/Text'
import { FlexCol, standardSidePadding } from 'pages/covid/styles'
import { regularBodyTextStyle } from 'config/styles'

export const Hero = styled.div({
  width: '100vw',
  paddingLeft: standardSidePadding,
  paddingRight: standardSidePadding,
  paddingTop: '7vh',
  paddingBottom: '7vh',
  height: '100vh',
  backgroundImage: `url(${heroBackground})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})

export const LogoRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
})

export const TryItToday = styled.p({
  marginTop: 40,
  fontSize: 38,
  color: 'var(--white)',
  lineHeight: '58px',
  fontWeight: 900,
})

export const Title = styled.p({
  marginTop: '8vh',
  fontWeight: 900,
  fontSize: 60,
  color: 'var(--white)',
})

export const SubTitle = styled.p({
  fontSize: 28,
  color: 'var(--white)',
  maxWidth: 1080,
})

export const AboveAddressBar = styled.p({
  margin: '6vh 0% 5vh 0%',
  fontSize: 28,
  color: 'var(--white)',
  maxWidth: 820,
  fontStyle: 'italic',
})

export const AddressFormRow = styled(Row)({
  justifyContent: 'center',
})

export const LearnMoreWrapper = styled.div({
  marginTop: '18vh',
  display: 'flex',
  flexDirection: 'column',
})

export const LearnMoreChevron = styled.img({
  marginTop: 10,
  height: 10,
})

export const HereToHelpRow = styled(Row)({
  color: 'var(--white)',
  backgroundColor: 'var(--blue)',
  marginTop: 125,
  padding: '117px 14%',
  '@media only screen and (max-width: 768px)': {
    padding: '75px 14%',
  },
})

export const welcomeBigFontSize = {
  fontSize: 48,
  lineHeight: '58px',
  fontWeight: 900,
}

export const HereToHelpTitleCol = styled(FlexCol)({
  ...welcomeBigFontSize,
  '@media only screen and (max-width: 568px)': {
    fontSize: 38,
    lineHeight: '46px',
  },
})

export const HereToHelpDetailCol = styled(FlexCol)({
  fontSize: 28,
  lineHeight: '33px',
  fontWeight: 400,
  whiteSpace: 'pre-line',
})

export const HowItWorksTitleRow = styled(Row)({
  ...welcomeBigFontSize,
  color: 'var(--blue)',
  textAlign: 'center',
  paddingTop: 125,
  '@media only screen and (max-width: 768px)': {
    paddingTop: 75,
  },
})

export const HowItWorksRow = styled(Row)({
  justifyContent: 'center',
  padding: '0 5% 175px 5%',
  '@media only screen and (max-width: 768px)': {
    paddingBottom: 125,
  },
})

export const HowItWorksIcon = styled.img({
  height: 334,
  width: 334,
  marginBottom: 40,
  '@media only screen and (max-width: 568px)': {
    height: 300,
    width: 300,
  },
})

export const HowItWorksCol = styled(Col)({
  textAlign: 'center',
  padding: '50px 10px 0 10px',
})

export const HowItWorksItemNumber = styled.p({
  fontSize: 28,
  lineHeight: '33px',
  fontWeight: 900,
  color: 'var(--gray5)',
})

export const HowItWorksItemTitle = styled(LargeHeaderText)({
  lineHeight: '38px',
  color: 'var(--gray1)',
  marginBottom: 16,
})

export const HowItWorksItemDetail = styled.p({
  fontSize: 22,
  lineHeight: '30px',
  fontWeight: 500,
  color: 'var(--gray2)',
})

export const WelcomeBlockText = styled.p({
  fontSize: 28,
  textAlign: 'center',
  justifyContent: 'center',
  padding: '75px 10% 0 10%',
  '@media only screen and (max-width: 768px)': {
    paddingTop: 75,
  },
})

export const WelcomeFooter = styled(Row)({
  backgroundColor: 'var(--gray7)',
  padding: '65px 5% 35px 5%',
  '@media only screen and (max-width: 768px)': {
    padding: '50px 5%',
  },
})

export const WelcomeSubtitle = styled(Row)({
  ...welcomeBigFontSize,
  color: 'var(--blue)',
  textAlign: 'center',
  paddingTop: 125,
  '@media only screen and (max-width: 768px)': {
    paddingTop: 75,
  },
})

export const FooterColumn = styled(FlexCol)({
  alignItems: 'center',
  textAlign: 'center',
})

export const FooterLinksBig = styled.div({
  margin: '38px auto 46px auto',
  '@media only screen and (max-width: 768px)': {
    margin: '28px auto 34px auto',
  },
})

export const FooterLinkBig = styled.a({
  color: 'var(--gray1)',
  display: 'block',
  fontSize: 18,
  lineHeight: '32px',
  fontWeight: 800,
})

export const FooterLinkSmall = styled.a({
  ...regularBodyTextStyle,
  color: 'var(--gray1)',
  marginTop: 30,
})
