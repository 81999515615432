import numeral from 'numeral'

export const EM_DASH = '\u2013'

export const formattedNumberWithFallback = (
  number: number | null,
  formatter: (num: number) => string
) => (number === null ? EM_DASH : formatter(number))

export const orEmDash = <T>(
  value: T | null,
  formatter: (value: T) => string
): string => (!value ? EM_DASH : formatter(value))

export const formatLargeNumber = (num: number, cutoffNumber = 100000) =>
  numeral(num).format(num >= cutoffNumber ? '0a' : '0,0')
