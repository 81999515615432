import React, { FC } from 'react'
import { DateTime, Interval } from 'luxon'

import {
  GetLocationForScheduleQuery,
  ShiftRoleFragment,
  ShiftRoleCategoryFragment,
  EmployeeForScheduleFragment,
  ViolationFragment,
  EmployeeShiftsFragment,
  ShiftFragment,
  ActiveLocationShiftRoleFragment,
} from 'config/graphqlTypes'
import {
  RowEntity,
  isRowEntityUnavilability,
  isRowEntityEmployee,
} from 'pages/plan/scheduling/RowEntity'
import { DailyViewModal } from '../dailyViewModal'
import { TemplateSelectionModal } from './TemplateSelectionModal'
import _ from 'lodash'
import { ScheduleCollapsableCategory } from 'pages/plan/scheduling/ScheduleCollapsableCategory'
import {
  isTheNoCategory,
  makeShiftRoleCategoryBuckets,
  shiftRoleMatchesCategory,
  getShiftsForRow,
} from 'pages/plan/shiftRoleCategoryHelpers'
import { usePlanPageContext } from '../planPageContext'

const includeRowInCategory = (
  shifts: ShiftFragment[],
  shiftRoleCategory: ShiftRoleCategoryFragment,
  locationId: string,
  activeShiftRoles: ActiveLocationShiftRoleFragment[]
) => (rowEntity: RowEntity) => {
  const rowHasShifts =
    getShiftsForRow(shifts, rowEntity, shiftRoleCategory).length > 0

  const rowIsEmployee = isRowEntityEmployee(rowEntity)

  const entityIsCategoryMatch = rowIsEmployee
    ? // If it's an employee row, then we're on the schedule "by Person" view
      // Include row if:
      // * The row already has shifts OR
      // * (The employee is active AND
      // * They are assigned >= 1 active shift roles which match the current category)
      rowHasShifts ||
      ((rowEntity as EmployeeForScheduleFragment).shiftRoles ?? []).some(
        shiftRole =>
          activeShiftRoles?.some(lsr => {
            return lsr.shiftRoleId === shiftRole.id && lsr.active
          }) && shiftRoleMatchesCategory(shiftRole, shiftRoleCategory)
      )
    : shiftRoleMatchesCategory(
        rowEntity as ShiftRoleFragment,
        shiftRoleCategory
      )

  const active = rowIsEmployee
    ? (rowEntity as EmployeeForScheduleFragment)?.employeeLocations?.find(
        el => el.locationId === locationId
      )?.active
    : rowEntity.name === 'Unavailability'
    ? false
    : activeShiftRoles?.some(lsr => {
        return lsr.shiftRoleId === rowEntity.id && lsr.active
      })

  return isRowEntityUnavilability(rowEntity)
    ? isTheNoCategory(shiftRoleCategory)
    : entityIsCategoryMatch && (active || rowHasShifts)
}

const makeCategorizedRowEntities = (
  isScheduleByRole: boolean,
  location: GetLocationForScheduleQuery['location'],
  shifts: ShiftFragment[],
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
) => {
  const { id, employees, shiftRoles } = location
  const unassignedShifts = shifts
    .filter(shift => shift.employee.id === '-1')
    .map(shift => {
      return {
        ...shift,
        employee: { ...shift.employee, shiftRoles: [shift.shiftRole] },
      }
    })
  const shiftEmployees = unassignedShifts.map(shift => shift.employee)
  const mergedEmployees = _.uniqBy([...employees, ...shiftEmployees], 'id')

  const allRowEntities = (isScheduleByRole
    ? [...shiftRoles]
    : mergedEmployees) as RowEntity[]
  const getRowEntitiesForCategory = (category: ShiftRoleCategoryFragment) =>
    _(allRowEntities)
      .filter(
        includeRowInCategory(shifts, category, id, activeLocationShiftRoles)
      )
      .sortBy('name')
      .value()

  return makeShiftRoleCategoryBuckets(shiftRoles, getRowEntitiesForCategory)
}

interface Props {
  days: Interval[]
  startDate: DateTime
  violations: ViolationFragment[]
  employeeShiftsForWeek: EmployeeShiftsFragment[]
  hideSchedule: () => void
  location: GetLocationForScheduleQuery['location']
  day: Interval | null
  dailyViewOnPrev: (() => void) | undefined
  dailyViewOnNext: (() => void) | undefined
  setShowDailyViewIndex: (cellIndex: number | null) => void
  isShowingSchedule: boolean
  disableEditPrediction: boolean
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

const ScheduleRows: FC<Props> = ({
  days,
  startDate,
  hideSchedule,
  location,
  day,
  isShowingSchedule,
  violations,
  employeeShiftsForWeek,
  dailyViewOnPrev,
  dailyViewOnNext,
  setShowDailyViewIndex,
  disableEditPrediction,
  activeLocationShiftRoles,
}) => {
  const { shiftRoles } = location
  const { plan, isScheduleByRole } = usePlanPageContext()
  const { shifts } = plan
  const isShowingShifts = isShowingSchedule || shifts.length > 0
  const categorizedRowEntities = makeCategorizedRowEntities(
    isScheduleByRole,
    location,
    shifts,
    activeLocationShiftRoles
  )

  return (
    <>
      {isShowingShifts && (
        <TemplateSelectionModal
          location={location}
          hideSchedule={hideSchedule}
          startDate={startDate}
        />
      )}
      {isShowingShifts &&
        categorizedRowEntities.map(
          ({ shiftRoleCategory, categoryMembers: rowEntities }) => (
            <ScheduleCollapsableCategory
              days={days}
              violations={violations}
              employeeShiftsForWeek={employeeShiftsForWeek}
              shiftRoleCategory={shiftRoleCategory}
              rowEntities={rowEntities}
              key={shiftRoleCategory.name}
              activeLocationShiftRoles={activeLocationShiftRoles}
            />
          )
        )}
      {day && (
        <DailyViewModal
          onHide={() => setShowDailyViewIndex(null)}
          day={day}
          days={days}
          shiftRoles={shiftRoles}
          onClickPrev={dailyViewOnPrev}
          onClickNext={dailyViewOnNext}
          location={location}
          disableEditPrediction={disableEditPrediction}
          activeLocationShiftRoles={activeLocationShiftRoles}
        />
      )}
    </>
  )
}

export default ScheduleRows
