import React, { FC, useState } from 'react'
import { UnprintablePlanningTableRow } from 'pages/plan/styles'
import {
  ActiveLocationShiftRoleFragment,
  EmployeeShiftsFragment,
  ShiftRoleCategoryFragment,
  ViolationFragment,
} from 'config/graphqlTypes'
import { Interval } from 'luxon'
import { isRowEntityEmployee, RowEntity } from 'pages/plan/scheduling/RowEntity'
import { ScheduleRow } from 'pages/plan/scheduling/ScheduleRow'
import { StyledCategoryRowCell } from 'pages/plan/scheduling/styles'
import { CollapsableCategoryName } from 'pages/plan/CollapsableCategoryName'
import { getShiftsForRow } from 'pages/plan/shiftRoleCategoryHelpers'
import styled from 'styled-components'
import { usePlanPageContext } from '../planPageContext'

const StyledBottomPageMargin = styled.tr({
  //only show on print
  display: 'none',
  '@media print': {
    display: 'block',
    marginBottom: '5rem',
  },
})

interface Props {
  days: Interval[]
  violations: ViolationFragment[]
  employeeShiftsForWeek: EmployeeShiftsFragment[]
  rowEntities: RowEntity[]
  shiftRoleCategory: ShiftRoleCategoryFragment
  activeLocationShiftRoles: ActiveLocationShiftRoleFragment[]
}

export const ScheduleCollapsableCategory: FC<Props> = ({
  days,
  violations,
  employeeShiftsForWeek,
  rowEntities,
  shiftRoleCategory,
  activeLocationShiftRoles,
}) => {
  const [collapsed, setCollapsed] = useState(false)
  const toggleCollapsed = () => setCollapsed(!collapsed)
  const isPersonView =
    rowEntities.length > 0 && isRowEntityEmployee(rowEntities[0])
  const { plan, locationClosedDaysIndexes } = usePlanPageContext()
  const { shifts } = plan

  return (
    <>
      {rowEntities.length > 0 && (
        <>
          <UnprintablePlanningTableRow
            onClick={toggleCollapsed}
            nthChildren={locationClosedDaysIndexes}
          >
            <StyledCategoryRowCell>
              <CollapsableCategoryName
                name={shiftRoleCategory.name}
                collapsed={collapsed}
              />
            </StyledCategoryRowCell>
            {days.map((_, index) => (
              <StyledCategoryRowCell key={index} />
            ))}
            <StyledCategoryRowCell />
          </UnprintablePlanningTableRow>

          {rowEntities.map((rowEntity, index) => (
            <ScheduleRow
              rowEntity={rowEntity}
              days={days}
              violations={violations}
              employeeShiftsForWeek={employeeShiftsForWeek}
              isFirst={index === 0}
              shiftRoleCategory={shiftRoleCategory}
              key={rowEntity.id}
              hide={collapsed}
              activeLocationShiftRoles={activeLocationShiftRoles}
              hideOnPrint={
                isPersonView
                  ? getShiftsForRow(shifts, rowEntity, shiftRoleCategory)
                      .length === 0
                  : false
              }
            />
          ))}
          <StyledBottomPageMargin />
        </>
      )}
    </>
  )
}
